export default {
    login: {
        login: '登录',
        userName: '用户名',
        password: '密码',
        name: '用户名或单位名称',
    },
    captcha: '验证码',
    forgetPassword: '忘记密码',
    loginTip: '当前登录结果随机。验证码随便填',
    editpassword: '修改密码',
    errMsg: {
        inputRequired: '请输入{cont}',
        selectRequired: '请选择{cont}',
    },
    uernameTips: '个人用户请填写用户名，单位用户请填写单位名称',

    title: '映像青岛',
    logo_small_text:'青岛国际新闻中心运维',
    hello: '您好，',
    loginOrRegister: '登录 / 注册',
    anonymity: '匿名用户',
    personCenter: '个人中心',
    logout:'退出登录',
    toLogin: '请先登录',
    mobileTip: '手机号',
    submitText: '提交',
    accountTip: '用户名/手机号',
    password: '密码',
    newPassword: '新密码',
    confirmPassword: '确认新密码',
    noAccount: '还没有账号？',
    hasAccount: '已有账号？',
    register: '注册',
    phoneNumber: '手机号',
    email: '邮箱',
    organizationName: '单位名称',
    loginNow:'立即登录',
    accountLogin: '账号登录',
    loginSuccess: '登录成功',
    loginFailed: '登录失败',
    registerSuccess: '注册成功',
    registerFailed: '注册失败',
    enterUsername: '请输入用户名',
    enterPhoneNumber: '请输入手机号',
    enterRealname: '请填写真实姓名',
    enterCorrectUnitName: '请填写正确单位名称',
    enterCorrectIdcard: '请填写正确身份证号',
    enterCorrectInstitutionName: '请填写正确机构编号',
    enterCorrectPhoneNumber: '请输入正确手机号',
    enterCorrectEmail: '请输入正确邮箱',
    enterCorrectUnitName: '单位名称可为汉字或字母或数字的组合',
    enterCorrectUsernameAndPhone: '请输入用户名/手机号',
    nameContain: '用户名必须包含字母和数字',
    passwordContain: '密码需要至少8位数字、字母、特殊符号（!#$%^&*?-_）组合',
    passwordContain2: '密码需要至少8位数字、字母、特殊符号组合',
    enterPassword: '请输入密码',
    passwordLength: '密码长度至少8位',
    qrlogin: '扫码登录',
    updateSuccess: '更新成功',
    retrievePassword: '找回密码',
    pageUpdated: '页面数据更新完毕了',
    cart1: '购物车',
    onlineService: '在线客服',
    newsCenter: '青岛国际新闻中心运维',
    qdnews: '青岛国际新闻中心',
    hostUnit: '主办：',
    contactPhone: '联系电话',
    workTime: '工作时间',
    footerText1: '海量高清素材',
    footerText2: '有版权商用',
    aboutus: '关于我们',
    copyrightNotice: '版权声明',
    questions: '常见问题',
    terms: '网站条款',
    selfRegulatory: '网站平台自律公约',
    contractUs: '联系我们',
    friendLink:'友情链接',
    followUs: '关注我们',
    copyright:'映像青岛版权所有',
    ICP: '鲁ICP备2023012351号-1',
    category:'分类',
    pv:'总浏览量：',
    uv:'总注册人数：',
    qrcode:'扫码登录',
    loadmore: '加载更多',
    theEnd: '结束',
    codeLogin:'验证码登录',
    getCode:'获取验证码',
    enterCode:'请输入验证码',
    codetip:'验证码',
    viewmore:'查看更多',

    menus: {
        home: '首页',
        news: '视讯',
        pic: '图片',
        video: '视频',
        vr: '数字博物馆',
        grapher: '摄影师',
        active: '活动',
        more: '更多服务',
        package: '价格套餐',
        subject: '专题',
    },
    contract:{
        tip1:'补充合同资料',
        tip2:'合同签字',
        tip3:'合同下载',
        tip4:'为了保证合同的有效性及后续保障，请填写真实的个人资料，后期购买将自动使用本次填写的资料。',
        tip5:'订单ID',
        tip6:'请输入订单ID',
        tip7:'资料类型',
        tip8:'个人用户',
        tip9:'企业机构',
        tip10:'真实姓名',
        tip11:'请输入真实姓名',
        tip12:'身份证号',
        tip13:'请输入身份证号',
        tip14:'组织机构',
        tip15:'请输入组织机构',
        tip16:'机构编号',
        tip17:'请输入机构编号',
        tip18:'邮箱',
        tip19:'请输入邮箱',
        tip20:'生成合同模板',
        tip21:'请在下边红色边框区域按住鼠标左键手写您的签名',
        tip22:'生成签名',
        tip23:'清空画板',
        tip24:'下载合同',
        tip25:'请选择用户类型',
        tip26:'请输入真实姓名',
        tip27:'请输入身份证号',
        tip28:'请输入机构名称',
        tip29:'请输入机构编号',
        tip30:'请输入邮箱',
        tip31:'合同资料保存失败',
        tip32:'获取合同资料失败',
        tip33:'上传失败',

    },
    recharge:{
        tip1:'收益概况',
        tip2:'收入概览',
        tip3:'未结算金额（元）',
        tip4:'已结算金额（元）',
        tip5:'申请结算',
        tip6:'订单明细',
        tip7:'作品名称',
        tip8:'支付时间',
        tip9:'订单金额',
        tip10:'分成收益',
        tip11:'订单编号：',
        tip12:'结算记录',
        tip13:'申请结算时间',
        tip14:'结算进度',
        tip15:'入账时间',
        tip16:'结算金额',
        tip17:'待结算',
        tip18:'结算中',
        tip19:'已结算',
        
    },

    search: {
        search: '搜索',
        hotSearch: '热门搜索',
        takePic: '拍图片搜索',
        dragPic: '拖放图片在此区域的任何位置',
        or: '或',
        clickUpload: '点击上传',
        sizeLimit: '使用尺寸小于50mb的jpg/png文件',
        searchPic: '搜图片',
        searchVideo: '搜视频',
        pic: '图片',
        video: '视频',
        trySearch:'可以尝试搜索关键词',
        scenery: '风光',
        building: '建筑',
        culture: '文化',
        humanities: '人文',
        educate: '教育',
        isJPG: '上传图片只能是 JPG、PNG 格式!',
    },
    sideMenu: {
        apply: '摄影师供稿',
        account: '账户管理',
        wallet: '我的钱包',
        cart: '购物车',
        authRecords: '授权记录',
        orderlist:'订单列表',
        collectManage: '活动征集',
        collect: '活动作品',
        folder: '活动合辑',
        personCenter: '摄影师中心',
        applyInfo: '入驻信息',
        workManage: '我的作品',
        recharge: '收益中心',
    },
    person: {
        personCenter: '创作者中心',

    },
    application: {
        settled: '摄影师入驻',
        process: '入驻流程',
        plain: '入驻说明',
        apply: '提交申请',
        result: '提交结果',
        tipText1: '欢迎申请成为映像青岛创作摄影师，为了提升您入驻的成功率，请正确填写入驻信息。',
        tipText2: '我们将会3个工作日内回复您的申请，您可以在此查看入驻进度',
        apply_enter: '申请入驻',
        base_info: '请填写基本信息',
    },
    form: {
        user_type: '用户类型',
        license: '营业执照',
        licenseTip: '单位营业执照盖章电子版（或组织机构代码证盖章电子版）',
        uploadLicense: '上传营业执照',
        type1: '个人',
        type2: '单位组织',
        unitName:'单位名称',
        unitTip:'请输入单位名称',
        realname: '真实姓名',
        enterRealname: '请输入真实姓名',
        gender: '性别',
        male: '男',
        female: '女',
        photo: '个人照片',
        enterPhoto: '请上传个人照片',
        uploadPhoto: '上传照片',
        biography: '简介',
        enterBiography: '请填写简介',
        mobile: '手机号',
        enterPhone: '请输入手机号',
        email: '电子邮箱',
        enterEmail: '请输入邮箱',
        company: '单位名称',
        enterCompany: '请输入单位名称',
        enterFeedType: '请选择供稿类型',
        ctype: '从业类型',
        media: '媒体',
        non_media: '非媒体',
        worktype: '供稿类型',
        image: '图片',
        video: '视频',
        workPic: '图片代表作品',
        workPicTip: '（代表作品仅作入驻审核参考，上传优质原创代表作品有利于提高入驻成功率）',
        uploadPic: '上传图片',
        againUploadPic: '继续上传图片',
        againUploadVideo: '继续上传视频',
        limit_pic_quantity: '（请提供10张原创图片）',
        workVideo: '视频代表作品',
        uploadVideo: '上传视频',
        limit_video_quantity: '（请提供3个原创视频）',
        submit_apply: '提交申请',
        tip_text_1: '你的入驻信息审核中...',
        tip_text_2: '我们将在3个工作日内对您的入驻信息进行审核(如遇国定节假日将顺延时间)',
        tip_text_3: '您可以在该页面查看审核结果!',
        uploadPicWork: '请上传图片作品',
        uploadVideoWork: '请上传视频作品',
        enter_limit_pic: '请上传10张图片',
        enter_limit_video: '请上传3个视频',
        submited: '申请已提交',
        only_ten_pic: '只能上传10张图片',
        only_three_video: '只能上传3个视频',
        idcard: '身份证号',
        idcard_photo: '身份证照片',
        enter_idcard: '请输入身份证号',
        idcard_tip: '（请上传身份证头像面）',
        bankcard: '银行卡号',
        enter_bankcard: '请输入银行卡号',
        bankcard_photo:'银行卡照片',
        bankcard_tip:'（请上传带有银行卡号面的照片）',
        preview:'提交并预览合同',
        enterCorrectbankcardNumber: '请填写正确银行卡号',
        enterIdcardPhoto: '请上传身份证正面',
        enterBankcardPhoto: '请上传银行卡正面',
    },
    apply_contract:{
        info: '合同信息',
        sign: '合同签名',
        tip1: '为保证双方合法权利，请录入身份信息和银行卡信息以便生成电子合同，签署完成后即签约成功。',
        tip2: '*以下信息仅用于合同签署和收益结算，合同签署后无法更改，请认真填写!',
        tip3: '为保证双方合法权利，请您仔细阅读合同内容，签署完成后将无法修改',
        tip4: '请填写合同信息',
        tip5: '合同预览',
        tip6: '请在下边红色边框区域按住鼠标左键手写您的签名',
        tip7: '生成签名',
        tip8: '清空画板',
        signSuccess:'签名成功',
        noMatch:'身份信息不匹配',
        submit_success:'提交成功',
        idcard_face:'请上传（或者重新上传）身份证正面照片',
        upload_fail:'上传失败',

    },
    apply_info: {
        tip1:'欢迎您入驻映像青岛，您可以上传您的作品，审核通过后即有机会赚取收益哦~',
        tip2:'入驻信息',
        tip3:'点击查看合同',
        tip4: '电子合同',
        
    },

    apply_nopass: {
        tip1:'你的入驻申请审核未通过...',
        tip2:'原因:经专家对您提交的作品进行综合评估，您暂不符合我们的入驻要求!',
        tip3:'您可以尝试提交更加优质的代表作品，我们将再次进行综合评估~',
        tip4:'重新提交',
    },
    apply_pass: {
        tip1: '恭喜，您的入驻申请已通过!',
        tip2: '完善信息后就可以上传作品，有机会赚取稿费啦~',
        tip3: '签约投稿',
    },
    apply_passing: {
        tip1:'你的入驻信息审核中...',
        tip2:'我们将在3个工作日内对您的入驻信息进行审核(如遇国定节假日将顺延时间)',
        tip3:'您可以在该页面查看审核结果',
    },
    apply_common: {
        del:'删除此项',
        addimg:'添加图片',
        addvideo:'添加视频',
        batch_pics:'批量图片统一信息填写',
        batch_videos:'批量视频统一信息填写',
        search_subject:'专题名称搜索',
        upload_success:'上传成功，等待审核',
        upload_pic_eight:'一次最多上传8张图片',
        upload_video_eight:'一次最多上传8个视频',
        size_exceed:'大小不能超过50M',
        submit_success:'提交成功',
        agreement:'我已阅读并同意',
        protocols:'《映像青岛内容授权许可协议》',
        read:'请先阅读并同意《映像青岛内容授权许可协议》',

        selectAll: '全选',
        off_pic: '点击下架后图片将从网站自动删除，确定下架？',
        del_pic: '点击下架后图片将从网站自动删除，确定删除？',
        batch_off_pic: '批量下架',
        batch_del_pic: '批量删除',
        season: '原因：',
        edit: '编辑',
        off: '下架',
        del: '删除',
        off_video: '点击下架后视频将从网站自动删除，确定下架？',
        del_video: '点击删除后视频将从网站删除，确定删除？',
        off_item: '请选择要下架的选项',
        had_off: '作品已下架',
        del_item: '请选择要删除的选项',
        had_del: '作品已删除',
        del_tip: '点击删除后不可恢复，确定删除？',
        edit_folder:'编辑合辑',
        add_folder:'添加合辑',
        enter_folder_title: '请输入合辑标题',
        enter_keyword_tip:'每输入一个关键词，按一次回车键',
        add_to_folder:'加入合辑',
        select_folder:'请选择合辑',
        author:'作者',
        enter_author:'请输入作者',
        create_date:'创作日期',

    },
    apply_work: {
        year_limit: '年份只能是数字',
        tip0:'图片供稿',
        tip1:'抱歉，您未申请图片权限',
        tip2:'温馨提示：如需图片投稿，请重新申请签约',
        tip3:'重新签约',
        tip4:'请选择一张或多张图片，支持多种图片格式',
        tip5:'图片名称',
        tip6:'请输入图片名称',
        tip7:'图片说明',
        tip8:'请输入图片说明',
        tip9:'关键词',
        tip10:'请输入关键词',
        tip11:'专题名称',
        tip12:'请选择专题',
        tip13:'新增专题',
        tip14:'小图状态',
        tip15:'小图价格',
        tip16:'请输入小图价格',
        tip17:'原图状态',
        tip18:'原图价格',
        tip19:'请输入原图价格',
        tip20:'大图状态',
        tip21:'大图价格',
        tip22:'请输入大图价格',
        tip23:'启用',
        tip24:'禁用',
        tip25:'提交审核',

        tip26:'视频供稿',
        tip27:'抱歉，您未申请视频权限',
        tip28:'温馨提示：如需视频投稿，请重新申请签约',
        tip29:'请选择一个或多个视频，支持多种视频格式',
        tip30:'视频名称',
        tip31:'请输入视频名称',
        tip32:'视频说明',
        tip33:'请输入视频说明',
        tip34:'专题名称',
        tip35:'请输入专题名称',
        tip36:'专题分类',
        tip37:'请选择专题分类',
        tip38:'专题封面',
        tip39:'专题描述',
        tip40:'请输入专题描述',
        tip41:'提交',
        tip42:'请上传专题封面',

        more:'加载更多',

        price_pack: '价格套餐包',
        select_price_pack: '请选择价格套餐包',
        pic_limit: '最多上传8张图片',
        video_limit: '最多上传8个视频',
        topic_price_pack: '专题封面价格套餐包',
        year:'年份',
        yearTip:'请输入年份',
        coverTip: '这只是封面图片，需要在图片供稿再重新传一次',
        has_similar: '请勿重复上传图片作品',
        similar: '图片重复',
    },
    no_settled: {
        tip1:'抱歉，入驻成功后才能投稿哦~',
        tip2:'您还未认证为官方摄影师',
        tip3:'摄影师入驻',
    },
    index: {
        hot: '热门推荐图片',
        grapher:'优秀摄影师',
        image: '图片',
        video: '视频',
    },
    news: {
        news:'视讯',
        publish_time: '发布时间：',
    },
    package: {
        name: '套餐名称',
        content: '专属内容权益',
        expire: '期限',
        day: '天',
        buy: '购买套餐',
        between: '权限范围',
        min: '小图',
        original: '原图',
        max: '大图',
        tip1: '1500元/支',
        tip2:'2200元/支',
        tip3: '扫一扫支付',
        order_sn: '订单编号：',
        price:'订单金额：',
        open:'打开',
        scan: '扫码支付',
        wechatPay: '微信支付',
        alipay: '支付宝支付',
        weixin: '微信',
        ali:'支付宝',
        select:'请选择套餐',
        tip4: '图片',
        tip5: '单支视频',
        tip6: '视频套餐',
    },
    grapher: {
        title: '摄影师',
        home:'首页',
        grapher:'优秀摄影师',
        tip1:'（该排名为姓氏排名不分先后）',
        tip2:'映像青岛签约摄影师',

    },
    search_pic:{
        tip1:'相似图片',
        tip2:'找到约',
        tip3:'条记录',
    },
    topics:{
        tip1:'相关专题',
        home:"首页",
    },
    pic:{
        tip1:'当前位置：',
        tip2:'说明：',
        tip3:'特定使用范围（Royalty-Free，简称“RF”）授权，非特定使用范围许可内容可以无限次和永久性使用，根据文件尺寸定价，下载后无水印。详情参考：',
        tip4:'映像青岛内容授权协议',
        tip5:'加入购物车',
        tip6:'单张购买',
        tip7:'如需询价购买请拨打0532-82825531 或咨询',
        tip8:'在线客服',
        tip9:'图片ID：',
        tip10:'图片标题：',
        tip11:'图片规格：',
        tip12:'图片尺寸：',
        tip13:'图片类型：',
        tip14:'图片大小：',
        tip15:'图片价格：',
        tip16:'图片年份：',
        tip17:'图片说明：',
        tip18:'关键词',
        tip19:'相似图片推荐',

    },
    video: {
        text1: '视频ID：',
        text2: '视频标题：',
        text3: '视频类型：',
        text4: '视频时长：',
        text5: '视频大小：',
        tip1: '关键词',
        tip2: '相似视频推荐',
        desc: '简介',
    },
    vr: {
        text1: 'VR展厅',
        text2: '详情',
    },
    order_detail: {
        text1: '订单详情',
        text2: '服务说明',
        text3: '如需开具发票，请联系客服：',
        text4: '订单编号：',
        text5: '订单金额：',
        text6: '支付金额：',
        text7: '支付状态：',
        text8: '待支付',
        text9: '已支付',
        text10: '支付方式：',
        text11: '套餐包',
        text12: '微信支付',
        text13: '支付宝支付',
        text14: '余额',
        text15: '支付时间：',
        text16: '下单时间：',
        text17: '图片信息',
        text18: '应付合计：',
        text19: '图片预览',
        text20: '图片名称',
        text21: '图片规格',
        text22: '图片价格',
        text23: '选择支付方式',
        text24: '选择套餐包支付',
        text25: '实付金额：',
        text26: '提交订单',

        text27: '账户余额支付',
        // text28: '账户可用余额 <span style="color:rgb(230, 0, 18);font-weight:bold;">0.00</span>元。如果您已在【价格套餐】页面购买了套餐包，请优先选择套餐包支付。',
        text29: '微信支付',
        text30: '支持 微信 快捷支付',
        text31: '支付宝支付',
        text32: '支持 支付宝 快捷支付',

        text33: '账户可用余额',
        text34: '如果您已在【价格套餐】页面购买了套餐包，请优先选择套餐包支付。',
        text35: '支付完成',
        text36: '支付失败',
        origin_1:'原图：',
        small_1:'小图：',
        big_1:'大图：',
        origin:'原图',
        small:'小图',
        big:'大图',
        maohao:'：',
    },
    order_pay: {
        text1: '订单信息',
        text2: '预支付订单',
        tip1: '请选择图片价格',
        tip2: '只能选择一项',
    },
    order_list: {
        text0: '订单列表',
        text1: '图片名称：',
        text2: '下单时间：',
        text3: '搜索',
        text4: '订单ID：',
        text5: '订单编号：',
        text6: '下单时间：',
        text7: '分辨率：',
        text8: '订单详情',
        text9: '下载合同',
        text10: '价格：',
        text11:'开始时间',
        text12:'结束时间',
        text13:'至',
        wait_pay:'待支付',
        pay_success:'支付成功',
        paid:'已支付',
        package:'套餐包',
        wechatPay:'微信支付',
        alipay:'支付宝支付',
        balance:'余额',
    },
    setting: {
        text1:'修改密码',
        text2:'用户信息',
        text3:'手机号',

    },
    auth: {
        text1: '授权列表',
        text2: '图片名称：',
        text3: '授权时间：',
        text4: '图片预览',
        text5: '图片标题',
        text6: '图片规格',
        text7: '图片分辨率',
        text8: '操作',
        text9: '下载',


    },
    wallet: {
        text1: '我的钱包',
        text2: '可用余额',
        text3: '余额记录',
        text4: '类型',
        text5: '金额',
        text6: '时间',
        text7: '暂无余额记录',
    },
    cart: {
        text1: '购物车',
        text2: '（共',
        text3: '件商品）',
        text4: '操作',
        text5: '图片预览',
        text6: '图片标题',
        text7: '图片规格',
        text8: '价格',
        text9: '删除',
        text10: '我已阅读并同意',
        text11: '《映像青岛授权协议》',
        text12: '《映像青岛隐私声明》',
        text13: '已选商品',
        text14: '总计',
        text15: '结算',
        text16: '件',
        clearCart:'清空购物车',
        batchDel:'批量删除',

    },
    activity: {
        actName: '参加活动',
        selectAct: '请选择征集活动',
        selectActName: '活动名称搜索',
        category: '分类',
        select_cate:'选择分类',
    },
    active: {
        upload: '上传作品',
        pic:'图片',
        video: '视频',
        recommend: '推荐',
        tip7:'图片说明',
        tip9:'关键词',
        folder_title: '标题',
        enter_title: '请输入标题',
        folder_desc: '描述',
        enter_desc: '合辑描述',
        cover_type: '封面类型',
        folder_cover: '封面',
        cover_type_1:'最新作品',
        cover_type_1_tip:'自动将合辑中的最新作品作为合辑封面',
        cover_type_2:'固定封面',
        cover_type_2_tip:'本地上传图片作为固定封面',
        operate_success: '操作成功',

    },
    status: {
        all:'全部',
        reviewing: '审核中',
        reviewed: '审核通过',
        not_review: '审核未通过',
    }


}