<template>
	<PageLoading :is-loading="isLoading"></PageLoading>
	<SideComponent @scrollto="scroll"></SideComponent>
	<div class="container" ref="viewBox">
		<!-- 以下代码与index-pic是一套 -->
		<div class="header-container">
			<Header2 />			
		</div>
		
		<div class="content">
			<div class="index-pic">				
				<!-- <div class="pic-wrapper">
					<search-bar-pic :isNeedRedirect="false" @searchKeywords="searchNews"></search-bar-pic>
				</div> -->
			</div>
			<div class="news-container">

				<section id="news-wrapper" class="news-wrapper">					
					<div class="img-table">
						<div class="table-body">
							<el-steps :active="active" finish-status="success" align-center style="width:100%;margin-bottom: 30px;">
							  <el-step :title="$t('contract.tip1')"></el-step>							  
							  <el-step :title="$t('contract.tip2')"></el-step>
							  <el-step :title="$t('contract.tip3')"></el-step>
							</el-steps>						
							<div class="body-item" v-if="showForm">
								<div class="contract-tip-outer">
									<div class="contract-tip"><i class="iconfont icon-tishi4" style="color:green;"></i>{{$t('contract.tip4')}}</div>
								</div>
								<el-form ref="ruleRef" :model="ruleUser" :rules="_rules" label-width="80px">
									<el-form-item prop="order_id" :label="$t('contract.tip5')">
									    <el-input v-model="ruleUser.order_id" autocomplete="off" :placeholder="$t('contract.tip6')" readonly></el-input>
									</el-form-item>
									<el-form-item :label="$t('contract.tip7')" prop="user_type">									    
									    <el-radio v-model="ruleUser.user_type" label="1">{{$t('contract.tip8')}}</el-radio>
									    <el-radio v-model="ruleUser.user_type" label="2">{{$t('contract.tip9')}}</el-radio>			
									</el-form-item>
									<el-form-item prop="realname" :label="$t('contract.tip10')" v-if="ruleUser.user_type==1">
									    <el-input v-model="ruleUser.realname" autocomplete="off" :placeholder="$t('contract.tip11')"></el-input>
									</el-form-item>
									<el-form-item prop="idcard" :label="$t('contract.tip12')" v-if="ruleUser.user_type==1">
									    <el-input v-model="ruleUser.idcard" autocomplete="off" :placeholder="$t('contract.tip13')"></el-input>
									</el-form-item>
									<el-form-item prop="organize" :label="$t('contract.tip14')" v-if="ruleUser.user_type==2">
									    <el-input v-model="ruleUser.organize" autocomplete="off" :placeholder="$t('contract.tip15')"></el-input>
									</el-form-item>
									<el-form-item prop="organize_sn" :label="$t('contract.tip16')" v-if="ruleUser.user_type==2">
									    <el-input v-model="ruleUser.organize_sn" autocomplete="off" :placeholder="$t('contract.tip17')"></el-input>
									</el-form-item>
									<el-form-item prop="email" :label="$t('contract.tip18')">
									    <el-input v-model="ruleUser.email" autocomplete="off" :placeholder="$t('contract.tip19')"></el-input>
									</el-form-item>
									<el-form-item>
									    <el-button type="primary" @click="submitForm(ruleRef)">{{$t('contract.tip20')}}</el-button>
									   
									</el-form-item>
								</el-form>
								
							</div>

							<div class="pdf" v-if="showPdf">
								<Loading :is-loading="isPdfLoading"></Loading>
								<div class="pdf-outer" v-if="showIframe">
									<iframe :src="iframeUrl" style="width:100%;height:1200px;"></iframe>
									<div class="esign-outer">
										<div style="padding:20px 0;width:100%;">
											<span style="color:red;">*</span><span style="color:green;">{{$t('contract.tip21')}}</span>
										</div>
										<vue-esign ref="esign" :width="800" :height="300" :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor" v-model:bgColor="bgColor" style="border:1px solid rgba(230,0,15,1);"/>
										<div class="esign-btn">
											<button class="el-button el-button--primary" @click="handleGenerate">{{$t('contract.tip22')}}</button>
											<button class="el-button el-button--info is-plain" @click="handleReset">{{$t('contract.tip23')}}</button>										
										</div>
									</div>
								</div>
								<!-- <div class="esigh-result">
							      <img v-if="resultImg" :src="resultImg" alt="">
							    </div> -->
							</div>
							<div v-if="showDownload" class="download">
								<Loading :is-loading="isDownLoading"></Loading>
								<a v-if="showDownloadBtn" class="el-button el-button--primary" style="text-decoration: none;" :href="contractUrl">{{$t('contract.tip24')}}</a>
							</div>
						</div>
						
					</div>
				</section>

			</div>

		</div>
		<Footer/>
	</div>
</template>

<script>
	import contract from './contract_controller';
	export default contract;
</script>

<style src="@/assets/css/my/contract.css" scoped></style>