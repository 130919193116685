// import Search from '@/components/Search.vue'
import { onMounted,nextTick,ref, provide, reactive, toRefs, getCurrentInstance } from 'vue';
import { useStore, createNamespacedHelpers } from "vuex";
import PageLoading from '@/components/common/PageLoading.vue';
import Swiper from '@/components/layout/Swiper.vue';
import SearchBarPic from '@/components/layout/SearchBarPic.vue';
import Header2 from '@/components/layout/header2.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';
import axios from 'axios';
import bus from 'vue3-eventbus'
import SideComponent from '@/components/common/SideComponent.vue';
import Loading from '@/components/common/Loading.vue';

export default {
	name: 'index',
	components: {
		PageLoading,
		Header2,
		Menu,
	    Swiper,
	    SearchBarPic,
	    Footer,
	    SideComponent,
	    Loading,
	},
	setup(){	    
	    // 写法一 用了reactive，就不需要写ref
	    // const data = reactive({
	    // 	checkList: ['selected and disabled', 'Option A']
	    // })
	    provide('logoColor', 'white');
	    let {ctx, proxy} = getCurrentInstance();
	    const data = reactive({
	    	isLoading:true,
	    	active_menuid: 0,
			activeClass: 'active',
			isMobile: false,
      		show_menu: false,
      		active_tagid: 'pic',
      		ispic: true,
      		banners:[

      		],
      		picCats:[
      			
      		],
      		isNeedRedirect:true,
      		subjectCats:[],
      		isPicLoading: true,
	    });
	    

		const showMenus = ref(proxy.$menus);
		provide('showMenu', showMenus);

		const getImageUrl = (name) => {
		    return proxy.$utils.getImageUrl(name);
		};

		const store = useStore();
        
        const viewBox = ref(null);
       	const scroll = (val) => {
			if(val == 'top'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		       
			}else if(val == 'bottom'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		        	
		        	
		        
			}
		}

        onMounted(async()=>{
        	store.dispatch("common/fetchSiteInfo").then((res) => {
	            data.banners = res.sliders;   
	            bus.emit('isLoading',false);       
	        }).catch(err => {
	            console.error(err)
	        })
	        
	        // store.dispatch("common/getPicCats").then((res) => {
	        // 	data.picCats = res
	        // })
	         store.dispatch("common/getSubjectCats",{pid:0}).then((res) => {
	        	data.subjectCats = res
	        	data.isPicLoading = false;
	        	bus.emit('showFooter',true);
	        }).catch(err=>{
	        	data.isPicLoading = false;
	        })
        	await nextTick()
        	setTimeout(() => {
		        data.isLoading = false;
		        viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		    }, 600);
		    console.log("页面数据更新完毕了")
        })

        const refData = toRefs(data);
	    return {
	        ...refData,
	        getImageUrl,
	        viewBox,
	        scroll,
	    }
	},
	computed: {

	},
	watch(){

	},
	beforeCreate() {

	},
	created() {
		let that = this;
		this.$nextTick(() => {
			// SiteInfo().then((data)=>{
			// 	// console.log(data, "siteinfo");	
			// 	that.banners = data.sliders;
			// 	// that.siteInfo = data.map((item)=>{
					
			// 	// 	return item;
			// 	// });
			// 	// that.banners = that.siteInfo.sliders			
				
			// }).catch((err)=>{

			// });
	    });
	},
	beforeMount() {

	},
	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	},
	beforeUpdate() {

	},
	updated() {

	},
	beforeUnmount() {

	},
	unmounted() {

	},
	methods: {
		init() {

		},

		toSubjectCategory(id){
			this.$router.push({ path:'/special_topics_category',query:{catid:id} });
		},
		toPicCategory(id){
			this.$router.push({ path:'/topic_pic_category',query:{catid:id} });
		},
	    clickTag(data){
	  		this.active_tagid = data.name;
	  		if(this.active_tagid == 'pic'){
	  			this.ispic = true;
	  			// this.placeholder = '搜图片';
	  		}else if(this.active_tagid == 'video'){
	  			this.ispic = false;
	  			// this.placeholder = '搜视频';
	  		}
	  	},
	    generateTagClass(data){
		    if(this.active_tagid == data.name){
		        return ['tabitem','active-tag'];
		    }
		    return ['tabitem'];
	    },
	 //    getImageUrl(name) {
		//     return new URL(`../assets/${name}`, import.meta.url).href;
		// },
	},
	beforeDestory() {

	},
	destoryed() {

	}
}