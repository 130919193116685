<template>
<SideComponent @scrollto="scroll"></SideComponent>
	<div class="container" ref="viewBox">
		<Header2 :show-search-bar-pic="showHeader" login-style="loginStyle" :mobile-icon-style="mobileIconStyle" :show-list-icon="showListIcon"/>
		<div class="content">
			<div style="height:80px;"></div>
			<div class="enclosure">
				<div class="inner">
					<div class="left">
						<SideMenu />
					</div>
					<div class="right">
						<div class="caption">
							<span>账号设置</span><span class="arrow">></span><span>修改密码</span>
						</div>
						<div class="tag-outer">
							
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer/>
	</div>
</template>

<script>
	import favorites from './favorites_controller';
	export default favorites;
</script>

<style src="@/assets/css/my/favorites.css" scoped>
	/*@import url("@/assets/css/style.css");全局引用css*/
	/*@import '@/assets/css/style.css';*/
</style>