<template>
	<PageLoading :is-loading="isLoading"></PageLoading>
	<SideComponent @scrollto="scroll"></SideComponent>
	<div class="container" ref="viewBox">
		<div class="header-container">
			<Header2 />			
			<div class="search-swiper-container">
			</div>
		</div>
		<div class="content">
			<div class="index-pic">				
				<!-- <div class="pic-wrapper">
					<search-bar-pic @refresh="getData"></search-bar-pic>
				</div> -->
			</div>
			<div class="pay-container">
				<div class="title">
					<span>{{payTypeName}}{{$t('package.tip3')}}</span>
				</div>	
				<!-- <div class="pay-type">
					<div class="pay-type-item" v-for="item in payType">
						<span style="padding:5px 5px;">
							<input type="radio" :value="item.id" v-model="checkedPaytypeId" />
						</span>
						<span style="padding:5px 5px;">{{item.title}}</span>
					</div>
				</div> -->
				<div class="pay-info">
					<div class="info-wrapper">
						<div class="item">
							<span>{{$t('package.order_sn')}}</span>
							<span>{{orderInfo.order_sn}}</span>
						</div>
						<div class="item">
							<span>{{$t('order_detail.text6')}}</span>
							<span class="char">￥</span>
							<span class="amount">{{orderInfo.total}}</span>
						</div>
						<div class="item hr"></div>
						<div class="item">
							<img v-if="qrCodeImgUrl.length<=0" class="qrcode" src="@/assets/img/default.png" />
							<img v-if="qrCodeImgUrl.length>0" class="qrcode" :src="qrCodeImgUrl"/>
						</div>
					</div>
				</div>			
			</div>			
		</div>
		
		<Footer/>
	</div>
</template>

<script>
	import pay_qrcode from './pay_qrcode_controller';
	export default pay_qrcode;
</script>

<style src="@/assets/css/my/order/pay_qrcode.css" scoped>
	/*@import url("@/assets/css/style.css");全局引用css*/
	/*@import '@/assets/css/style.css';*/
</style>