<template>
<SideComponent @scrollto="scroll"></SideComponent>
	<div class="container" ref="viewBox">
		<Header2 :show-search-bar-pic="showHeader" login-style="loginStyle" :mobile-icon-style="mobileIconStyle" :show-list-icon="showListIcon"/>
		<div class="content">
			<div style="height:80px;"></div>
			<div class="enclosure">
				<div class="inner">
					<div class="left">
						<SideMenu />
					</div>
					<div class="right">
						
						<div class="tag-outer">
							<el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
								<el-tab-pane :label="$t('active.pic')" name="first">
									<div class="work-status">
										<span class="work-status-span" :class="activeClass(item.id)" v-for="item in statusList" @click="GetListByStatus(item.id)">{{item.name}}<span style="margin-left:5px;">({{item.total}})</span></span>
										<!-- <span class="work-status-span">审核中</span>
										<span class="work-status-span">审核通过</span>
										<span class="work-status-span">审核未通过</span> -->
									</div>
									<div class="operations">
										<div class="item">
											<input type="checkbox" v-model="checkedAllPics" @change="changeAllChecked()" />{{$t('apply_common.selectAll')}}
										</div>
										<div class="item">
											<!-- <span class="info-border-status info-border-green" @click="OffSale()">批量下架</span>
											<span class="info-border-status info-border-green" @click="DeleteWork()">批量删除</span> -->
											<el-popconfirm
							    				class="info-border-status info-border-green"
											    confirm-button-text="Yes"
											    cancel-button-text="No"
											    :icon="InfoFilled"
											    icon-color="#626AEF"
											    :title="$t('apply_common.off_pic')"
											    @confirm="OffSale()"
											    @cancel="cancelEvent"
											>
											    <template #reference>
											      <el-button>{{$t('apply_common.batch_off_pic')}}</el-button>
											    </template>
											</el-popconfirm>
											
											<el-popconfirm
							    				class="info-border-status info-border-green"
											    confirm-button-text="Yes"
											    cancel-button-text="No"
											    :icon="InfoFilled"
											    icon-color="#626AEF"
											    :title="$t('apply_common.del_pic')"
											    @confirm="DeleteWork()"
											    @cancel="cancelEvent"
											>
											    <template #reference>
											      <el-button>{{$t('apply_common.batch_del_pic')}}</el-button>
											    </template>
											</el-popconfirm>
										</div>
									</div>
									<div class="work-list">
								    	<div class="work-list-items" v-for="(item,index) in worklist">
								    		<div class="work-list-item">
								    			<input type="checkbox" :value="item.id" v-model="checkedItem"/>
								    		</div>
								    		<div class="work-list-item">
								    			<div class="img-outer">
								    				<img v-if="!item.thumb" src="@/assets/img/default.png" />
								    				<img v-if="item.thumb" :src="item.thumb" />
								    			</div>
								    		</div>
								    		<div class="work-list-item">
								    			<div class="work-info-title">{{item.title}}</div>
								    			<div class="work-info">
								    				
								    			</div>
								    		</div>
								    		<div class="work-list-item">
								    			<span v-if="item.status==0" class="info-status info-warning">{{$t('status.reviewing')}}</span>
								    			<span v-if="item.status==1" class="info-status info-success">{{$t('status.reviewed')}}</span>
								    			<span v-if="item.status==2" class="info-status info-error">{{$t('status.not_review')}}</span>
								    			<span v-if="item.status==2" class="info-status-error">{{$t('apply_common.season')}}{{item.message}}</span>
								    		</div>
								    		<div class="work-list-item">
								    			<span v-if="item.status==2" class="info-status info-primary" @click="Edit(item.id)">{{$t('apply_common.edit')}}</span>
								    			<!-- <span v-if="item.status==1&&item.onsale==1" class="info-border-status info-border-green" @click="OffSale(item.id)">下架</span>
								    			<span class="info-border-status info-border-red" @click="DeleteWork(item.id)">删除</span> -->
								    			<el-popconfirm
								    				v-if="item.status==1&&item.onsale==1"
								    				class="info-border-status info-border-green"
												    confirm-button-text="Yes"
												    cancel-button-text="No"
												    :icon="InfoFilled"
												    icon-color="#626AEF"
												    :title="$t('apply_common.off_pic')"
												    @confirm="OffSale(item.id)"
												    @cancel="cancelEvent"
												>
												    <template #reference>
												      <el-button>{{$t('apply_common.off')}}</el-button>
												    </template>
												</el-popconfirm>
								    			
								    			<el-popconfirm
								    				class="info-border-status info-border-green"
												    confirm-button-text="Yes"
												    cancel-button-text="No"
												    :icon="InfoFilled"
												    icon-color="#626AEF"
												    :title="$t('apply_common.del_pic')"
												    @confirm="DeleteWork(item.id)"
												    @cancel="cancelEvent"
												>
												    <template #reference>
												      <el-button>{{$t('apply_common.del')}}</el-button>
												    </template>
												</el-popconfirm>
								    		</div>
								    	</div>

							    	</div>
							    	<div class="page-wrapper" v-if="Object.keys(pagination).length > 0">
										<el-pagination 
										    background 
										    layout="prev, pager, next" 
										    :total="pagination.total" 
										    v-model:current-page="pagination.current"
										    v-model:page-size="pagination.pageSize"	
										    @current-change="handlePageChange"			    
										/>

									</div>
							    </el-tab-pane>
							    <el-tab-pane :label="$t('active.video')" name="second">
							    	<div class="work-status">
										<span class="work-status-span" :class="activeClass(item.id)" v-for="item in statusList" @click="GetListByStatus(item.id)">{{item.name}}<span style="margin-left:5px;">({{item.total}})</span></span>
										<!-- <span class="work-status-span">审核中</span>
										<span class="work-status-span">审核通过</span>
										<span class="work-status-span">审核未通过</span> -->
									</div>
							    	<div class="operations">
										<div class="item">
											<input type="checkbox" v-model="checkedAllVideos" @change="changeVideoAllChecked()" />{{$t('apply_common.selectAll')}}
										</div>
										<div class="item">
											<!-- <span class="info-border-status info-border-green" @click="OffSale()">批量下架</span>
											<span class="info-border-status info-border-green" @click="DeleteWork()">批量删除</span> -->
											<el-popconfirm
							    				class="info-border-status info-border-green"
											    confirm-button-text="Yes"
											    cancel-button-text="No"
											    :icon="InfoFilled"
											    icon-color="#626AEF"
											    :title="$t('apply_common.off_video')"
											    @confirm="OffSale()"
											    @cancel="cancelEvent"
											>
											    <template #reference>
											      <el-button>{{$t('apply_common.batch_off_pic')}}</el-button>
											    </template>
											</el-popconfirm>
											
											<el-popconfirm
							    				class="info-border-status info-border-green"
											    confirm-button-text="Yes"
											    cancel-button-text="No"
											    :icon="InfoFilled"
											    icon-color="#626AEF"
											    :title="$t('apply_common.del_video')"
											    @confirm="DeleteWork()"
											    @cancel="cancelEvent"
											>
											    <template #reference>
											      <el-button>{{$t('apply_common.batch_del_pic')}}</el-button>
											    </template>
											</el-popconfirm>
										</div>
									</div>
							    	<div class="work-list">
								    	<div class="work-list-items" v-for="(item,index) in workVideoList">
								    		<div class="work-list-item">
								    			<input type="checkbox" :value="item.id" v-model="checkedItem"/>
								    		</div>
								    		<div class="work-list-item">
								    			<div class="img-outer">
								    				<img v-if="!item.url" src="@/assets/img/default.png" />
								    				<video v-if="item.url" id="video+'0'"
														controls				    
													    preload="none" 
													    muted 
													    style="object-fit:contain;width:100%;height:100%;" 
													    controlslist="nodownload" 
													    oncontextmenu="return false"
													    :poster="item.thumb"
													    :src="item.url" >
														<track kind="captions" />
														
													</video>
								    			</div>
								    		</div>
								    		<div class="work-list-item">
								    			<div class="work-info-title">{{item.title}}</div>
								    			
								    		</div>
								    		<div class="work-list-item">
								    			<span v-if="item.status==0" class="info-status info-warning">{{item.status_text}}</span>
								    			<span v-if="item.status==1" class="info-status info-success">{{item.status_text}}</span>
								    			<span v-if="item.status==2" class="info-status info-error">{{item.status_text}}</span>
								    			<span v-if="item.status==2" class="info-status-error">{{$t('apply_common.season')}}{{item.message}}</span>
								    		</div>
								    		<div class="work-list-item">
								    			<span v-if="item.status==2" class="info-status info-primary" @click="Edit(item.id)">{{$t('apply_common.edit')}}</span>
								    			<!-- <span v-if="item.status==1&&item.onsale==1" class="info-border-status info-border-green" @click="OffSale(item.id)">下架</span>
								    			<span class="info-border-status info-border-red" @click="DeleteWork(item.id)">删除</span> -->
								    			<el-popconfirm
								    				v-if="item.status==1&&item.onsale==1"
								    				class="info-border-status info-border-green"
												    confirm-button-text="Yes"
												    cancel-button-text="No"
												    :icon="InfoFilled"
												    icon-color="#626AEF"
												    :title="$t('apply_common.off_video')"
												    @confirm="OffSale(item.id)"
												    @cancel="cancelEvent"
												>
												    <template #reference>
												      <el-button>{{$t('apply_common.off')}}</el-button>
												    </template>
												</el-popconfirm>
								    			
								    			<el-popconfirm
								    				class="info-border-status info-border-green"
												    confirm-button-text="Yes"
												    cancel-button-text="No"
												    :icon="InfoFilled"
												    icon-color="#626AEF"
												    :title="$t('apply_common.del_video')"
												    @confirm="DeleteWork(item.id)"
												    @cancel="cancelEvent"
												>
												    <template #reference>
												      <el-button>{{$t('apply_common.del')}}</el-button>
												    </template>
												</el-popconfirm>
								    		</div>
								    	</div>

							    	</div>
							    	<div class="page-wrapper" v-if="Object.keys(pagination).length > 0">
										<el-pagination 
										    background 
										    layout="prev, pager, next" 
										    :total="pagination.total" 
										    v-model:current-page="pagination.current"
										    v-model:page-size="pagination.pageSize"	
										    @current-change="handlePageChange"			    
										/>

									</div>
							    </el-tab-pane>

							</el-tabs>
							
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer/>
	</div>
</template>

<script>
	import work_manage from './work_manage_controller';
	export default work_manage;
</script>

<style src="@/assets/css/my/grapher/work_manage.css" scoped></style>