<template>
<SideComponent @scrollto="scroll"></SideComponent>
	<div class="container" ref="viewBox">
		<Header2 :show-search-bar-pic="showHeader" login-style="loginStyle" :mobile-icon-style="mobileIconStyle" :show-list-icon="showListIcon"/>
		<div class="content">
			<div style="height:80px;" v-if="!isMobile"></div>
			<div style="height:60px;" v-if="isMobile"></div>
			<div class="enclosure">
				<div class="inner">
					<div class="left">
						<SideMenu />
					</div>
					<div class="right">
						<div class="caption">
							<span>{{$t('personCenter')}}</span><span class="arrow"><i class="iconfont icon-jiantou2"></i></span><span>{{$t('auth.text1')}}</span>
						</div>
						<div class="cart-outer">
							<div class="cart-info">
								<div class="item">
									<div class="subitem">
										<span style="color:#606266;padding:10px 0;">{{$t('auth.text2')}}</span>
										<el-input v-model="keyword" style="width: 240px" :size="size" />
									</div>
									<div class="subitem">
										<span style="color:#606266;padding:10px 0;">{{$t('auth.text3')}}</span>
										<el-date-picker
									        v-model="date"
									        type="daterange"
									        :range-separator="$t('order_list.text13')"
									        :start-placeholder="$t('order_list.text11')"
									        :end-placeholder="$t('order_list.text12')"
									        :size="size"
									        :shortcuts="shortcuts"
									        :editable="false"
									        format="YYYY-MM-DD"
									        value-format="YYYY-MM-DD"
									    />
									</div>
								</div>
								<div class="item">
									<span class="search btn" @click="search()">{{$t('order_list.text3')}}</span>

								</div>
							</div>
							<div class="cart-table">
								<div class="table-head">
									<div class="item"><!-- <input type="checkbox" name="selectall"/> --></div>
									<div class="item">{{$t('auth.text4')}}</div>
									<div class="item">{{$t('auth.text5')}}</div>
									<div class="item">{{$t('auth.text6')}}</div>
									<div class="item">{{$t('auth.text7')}}</div>
									<div class="item">{{$t('auth.text8')}}</div>
									
								</div>
								<div class="table-body">
									<div class="item" v-for="item in authList" v-if="!isMobile">
										<div class="subitem"><!-- <input type="checkbox" /> --></div>
										<div class="subitem">
											<img :src="item.image.preview" style="width:200px;height:100px;object-fit:cover;"/>
										</div>
										<div class="subitem">{{item.image.title}}</div>
										<div class="subitem">{{item.image.norms}}</div>
										<div class="subitem price">
											<div style="width:100%;">{{item.option==0?$t('order_detail.origin_1'):(item.option==1?$t('order_detail.small_1'):(item.option==2?$t('order_detail.big_1'):''))}}（<span v-if="item.option==0">{{item.image.ratio_w}} x {{item.image.ratio_h}}</span><span v-if="item.option==1">{{item.image.min_w}} x {{item.image.min_h}}</span><span v-if="item.option==2">{{item.image.max_w}} x {{item.image.max_h}}</span>）px</div>
										</div>
										<div class="subitem operation">
											<!-- <span class="del-btn" @click="del(item.id)">删除</span> -->
											<a class="del-btn blue" :href="item.download_link" download>{{$t('auth.text9')}}</a>
											<!-- item.option==0?item.image.watermark:(item.option==1?item.image.min_thumb:(item.option==2?item.image.max_thumb:'')) -->
										</div>
									</div>
									
									<div class="item" v-for="item in authList" v-if="isMobile">
										<div class="subitem"><input type="checkbox" /></div>
										<div class="subitem">
											<img :src="item.image.preview" style="width:100px;height:80px;object-fit:cover;"/>
										</div>
										<div class="subitem combine">
											<div class="child">{{item.image.title}}</div>
											<div class="child dpi">{{item.image.norms}}</div>
											<div class="child price">
												<div style="width:100%;">{{item.option==0?$t('order_detail.origin_1'):(item.option==1?$t('order_detail.small_1'):(item.option==2?$t('order_detail.big_1'):''))}}<span style="color:rgb(255,149,0);">￥{{item.image_price}}</span>（<span v-if="item.option==0">{{item.image.ratio_w}} x {{item.image.ratio_h}} px</span><span v-if="item.option==1">{{item.image.min_w}} x {{item.image.min_h}} px</span><span v-if="item.option==2">{{item.image.max_w}} x {{item.image.max_h}} px</span>）</div>
											</div>
										</div>
										
										<div class="subitem operation">
											<!-- <span class="del-btn" @click="del(item.id)">删除</span> -->
											<a class="del-btn blue" :href="item.image.watermark" download>{{$t('auth.text9')}}</a>
										</div>
									</div>
									
								</div>
								<div class="page-wrapper">
									<Loading :is-loading="isPicLoading"></Loading>
									<el-pagination 
									    background 
									    layout="prev, pager, next" 
									    :total="pagination.total" 
									    v-model:current-page="pagination.current"
									    v-model:page-size="pagination.pageSize"	
									    @current-change="handlePageChange"				    
									/>

								</div>
							</div>

						</div>
						
					</div>
				</div>
			</div>
		</div>
		<Footer/>
	</div>
</template>

<script>
	import auth_records from './auth_records_controller';
	export default auth_records;
</script>

<style src="@/assets/css/my/auth_records.css" scoped></style>