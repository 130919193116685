<template>
    <div class="login-mark" v-if="showDialog">
		<div class="login-bg">
			<div class="login-container">
				<!-- <div class="left"></div> -->
				<div class="right">
					<span role="button" tabindex="0" aria-hidden="true" class="close" @click="handleClose">
						<i class="iconfont icon-chahao1"></i>
					</span>
					<h4 class="PY8Y7">{{loginTip}}</h4>
					
					<div class="qrcode-outer" v-if="data.showQrcode">
						<Loading :is-loading="data.isQrcodeLoading"></Loading>
						<img :src="qrcode" style="width:200px;height:200px;" v-if="data.loadedQrcode"/>
						<!-- <span style="padding:5px 10px;" ref="expireTip" v-if="data.loadedQrcode">{{data.expireTip}}</span> -->
						<div class="register-wrapper">
					    	<div class="regtip">
					    		<a href="javascript:void(0);" @click="toLogin" style="margin-left:10px;text-decoration: none;color:rgb(64,158,255);">{{$t('accountLogin')}}</a>
					    		<a href="javascript:void(0);" @click="toCodeLogin" style="margin-left:10px;text-decoration: none;color:rgb(64,158,255);">{{$t('codeLogin')}}</a>
					    		<a href="javascript:void(0);" @click="toRegister" style="margin-left:10px;text-decoration: none;color:rgb(64,158,255);">{{$t('register')}}</a>
					    	</div>
					    	
					    </div>
					</div>
					<div v-if="data.showMobile">
						<el-form ref="mobileformRef" :model="data.mobileform" class="register-form" :rules="data.mobileform_rules">
						    <el-form-item prop="mobile" class="form-mobile">
						        <el-input v-model="data.mobileform.mobile" :size="size" autocomplete="off" :placeholder="$t('mobileTip')" @change="handleChange"/>
						    </el-form-item>	
						    <el-form-item prop="code" class="form-code">
						        <el-input v-model="data.mobileform.code" autocomplete="off" :placeholder="$t('codetip')">
						        	<template #append>
								        <el-button @click="getCode(data.mobileform.mobile)" :disabled="data.disabled">{{$t('getCode')}}</el-button>
								    </template>
						        </el-input>
						    </el-form-item>				    
						    <el-form-item>
						     <el-button type="primary" @click="updateMobile(mobileformRef)" class="login-submit">{{$t('submitText')}}</el-button>
						    </el-form-item>						    
					    </el-form>
					    <!-- <div class="register-wrapper">
					    	<div class="regtip">
					    		<a href="javascript:void(0);" @click="toLogin" style="margin-left:10px;text-decoration: none;color:rgb(64,158,255);">账号登录</a>
					    	</div>
					    	
					    </div> -->
					</div>
					<div v-if="data.showLogin">
						<el-form ref="formRef" :model="data.form" class="login-form" :rules="data.form_rules">							
						    <el-form-item prop="mobile" class="form-mobile">
						        <el-input v-model="data.form.mobile" autocomplete="off" :placeholder="$t('accountTip')" @change="handleChange"/>
						    </el-form-item>
						    <el-form-item prop="password" class="form-password">
						       <el-input v-model="data.form.password" 
						                 autocomplete="off" 
						                 type="password" 
						                 :placeholder="$t('password')" 
						                 show-password/>
						    </el-form-item>
						    <el-form-item>
						     <el-button type="primary" @click.prevent="onLogin(formRef)" class="login-submit">{{$t('login.login')}}</el-button>
						    </el-form-item>
						    <el-form-item>
						    	<!-- <a href="javascript:void(0);" @click.stop="toForget" style="margin-left:10px;text-decoration: none;color:rgb(64,158,255);">{{$t('forgetPassword')}}</a> -->
						    	<a href="javascript:void(0);" @click.stop="toQrcode" style="margin-left:10px;text-decoration: none;color:rgb(64,158,255);">{{$t('qrlogin')}}</a>
					    		<a href="javascript:void(0);" @click="toCodeLogin" style="margin-left:10px;text-decoration: none;color:rgb(64,158,255);">{{$t('codeLogin')}}</a>
						    </el-form-item>
					    </el-form>
					    <div class="register-wrapper">
					    	<div class="regtip">
					    		<a style="text-decoration: none;cursor: default;color:rgb(50,50,50);" href="javascript:void(0);">{{$t('noAccount')}}</a>
					    	</div>
					    	<div class="regtip">
					    		<el-button plain @click="toRegister" class="login-submit">{{$t('register')}}</el-button>
					    	</div>
					    </div>
					</div>

					<div v-if="data.showCodeLogin">
						<el-form ref="formRef" :model="data.codeform" class="login-form" :rules="data.codeform_rules">							
						    <el-form-item prop="mobile" class="form-mobile">
						        <el-input v-model="data.codeform.mobile" autocomplete="off" :placeholder="$t('accountTip')" @change="handleChange"/>
						    </el-form-item>
						    <el-form-item prop="code" class="form-code">
						        <el-input v-model="data.codeform.code" autocomplete="off" :placeholder="$t('codetip')">
						        	<template #append>
								        <el-button @click="getCode(data.codeform.mobile)" :disabled="data.disabled">{{$t('getCode')}}</el-button>
								    </template>
						        </el-input>
						    </el-form-item>
						    <el-form-item>
						     <el-button type="primary" @click.prevent="codeLogin(formRef)" class="login-submit">{{$t('login.login')}}</el-button>
						    </el-form-item>
						    <el-form-item>
						    	<a href="javascript:void(0);" @click.stop="toQrcode" style="margin-left:10px;text-decoration: none;color:rgb(64,158,255);">{{$t('qrlogin')}}</a>
					    		<a href="javascript:void(0);" @click="toLogin" style="margin-left:10px;text-decoration: none;color:rgb(64,158,255);">{{$t('accountLogin')}}</a>
					    		<a href="javascript:void(0);" @click="toRegister" style="margin-left:10px;text-decoration: none;color:rgb(64,158,255);">{{$t('register')}}</a>
						    </el-form-item>
					    </el-form>
					    <!-- <div class="register-wrapper">
					    	<div class="regtip">
					    		<a style="text-decoration: none;cursor: default;color:rgb(50,50,50);" href="javascript:void(0);">{{$t('noAccount')}}</a>
					    	</div>
					    	<div class="regtip">
					    		<el-button type="primary" @click="toRegister" class="login-submit">{{$t('register')}}</el-button>
					    	</div>
					    </div> -->
					</div>
					
					<div v-if="data.showReg">
						<el-form ref="regformRef" :model="data.regform" class="register-form" :rules="data.regform_rules">
							<el-form-item label=" " prop="username" class="form-mobile form-item">
						        <el-input v-model="data.regform.username" :size="size" autocomplete="off" :placeholder="$t('login.name')"/>
						    </el-form-item>
						    <el-form-item label=" " prop="mobile" class="form-mobile form-item">
						        <el-input v-model="data.regform.mobile" :size="size" autocomplete="off" :placeholder="$t('phoneNumber')" @change="handleChange"/>
						    </el-form-item>
						    <el-form-item label=" " prop="password" class="form-password">
						       <el-input v-model="data.regform.password" 
						                 autocomplete="off" 
						                 :size="size"
						                 type="password" 
						                 :placeholder="$t('password')"
						                 show-password/>
						        <!-- <span style="color:rgba(200,200,200,1);">{{$t('passwordContain')}}!@#$%^&*?-_</span> -->
						    </el-form-item>
						    <el-form-item label="- " prop="email" class="form-mobile form-item">
						        <el-input v-model="data.regform.email" :size="size" autocomplete="off" :placeholder="$t('email')"/>
						    </el-form-item>
						    <!-- <el-form-item label="- " prop="department" class="form-mobile form-item">
						        <el-input v-model="data.regform.department" :size="size" autocomplete="off" :placeholder="$t('organizationName')"/>
						    </el-form-item> -->
						    <el-form-item label=" " prop="code" class="form-code form-item">
						        <el-input v-model="data.regform.code" autocomplete="off" :placeholder="$t('codetip')">
						        	<template #append>
								        <el-button @click="getCode(data.regform.mobile)" :disabled="data.disabled">{{$t('getCode')}}</el-button>
								    </template>
						        </el-input>
						    </el-form-item>
						    <el-form-item>
						     <el-button type="primary" @click="onRegister(regformRef)" class="login-submit">{{$t('register')}}</el-button>
						    </el-form-item>						    
					    </el-form>
					    <div class="register-wrapper">
					    	<div class="regtip">
					    		<a href="javascript:void(0);" @click="toLogin" style="margin-left:10px;text-decoration: none;color:rgb(64,158,255);">{{$t('accountLogin')}}</a>
					    		<a href="javascript:void(0);" @click.stop="toQrcode" style="margin-left:10px;text-decoration: none;color:rgb(64,158,255);">{{$t('qrlogin')}}</a>
					    		<a href="javascript:void(0);" @click="toCodeLogin" style="margin-left:10px;text-decoration: none;color:rgb(64,158,255);">{{$t('codeLogin')}}</a>
					    	</div>
					    	
					    </div>
					</div>
					<div v-if="data.forgetPass">
						<el-form ref="forgetRef" :model="data.forget_form" class="login-form" :rules="data.forget_rules">							
						    <el-form-item prop="mobile" class="form-mobile">
						        <el-input v-model="data.forget_form.mobile" autocomplete="off" :placeholder="$t('accountTip')"/>
						    </el-form-item>
						    <el-form-item prop="code" class="form-code">
						        <el-input v-model="data.forget_form.code" autocomplete="off" :placeholder="$t('codetip')">
						        	<template #append>
								        <el-button @click="getCode(data.forget_form.mobile)" :disabled="data.disabled">{{$t('getCode')}}</el-button>
								    </template>
						        </el-input>
						    </el-form-item>
						    <el-form-item prop="password" class="form-password">
						       <el-input v-model="data.forget_form.password" 
						                 autocomplete="off" 
						                 type="password" 
						                 :placeholder="$t('password')" 
						                 show-password/>
						    </el-form-item>
						    <el-form-item prop="password_confirm" class="form-password">
						       <el-input v-model="data.forget_form.password_confirm" 
						                 autocomplete="off" 
						                 type="password" 
						                 placeholder="确认密码" 
						                 show-password/>
						    </el-form-item>
						    <el-form-item>
						     <el-button type="primary" @click.prevent="onModify(forgetRef)" class="login-submit">{{$t('login.login')}}</el-button>
						    </el-form-item>
						    <el-form-item>
						    	<a href="javascript:void(0);" @click="toLogin" style="margin-left:10px;text-decoration: none;color:rgb(64,158,255);">{{$t('accountLogin')}}</a>
						    	<a href="javascript:void(0);" @click.stop="toQrcode" style="margin-left:10px;text-decoration: none;color:rgb(64,158,255);">{{$t('qrlogin')}}</a>
					    		<a href="javascript:void(0);" @click="toCodeLogin" style="margin-left:10px;text-decoration: none;color:rgb(64,158,255);">{{$t('codeLogin')}}</a>
						    </el-form-item>
					    </el-form>
					    <!-- <div class="register-wrapper">
					    	<div class="regtip">
					    		<a style="text-decoration: none;cursor: default;color:rgb(50,50,50);" href="javascript:void(0);">{{$t('noAccount')}}</a>
					    	</div>
					    	<div class="regtip">
					    		<el-button plain @click="toRegister" class="login-submit">{{$t('register')}}</el-button>
					    	</div>
					    </div> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { ref, provide, reactive, toRefs,inject,
		     onBeforeMount, onMounted, onBeforeUpdate, onUpdated, onBeforeUnmount,
		     onUnmounted, onRenderTracked, onRenderTriggered, computed, 
		     getCurrentInstance, nextTick, watch, watchEffect
		   } from 'vue';
    import { useRouter, useRoute } from 'vue-router';
	import Menu from '@/components/layout/menu.vue';
	//引入接口模块
	import { login } from "@/api/user";
	import { useStore, mapState, mapGetters, mapActions, mapMutations } from "vuex";
	// import { isPhone, regEmail, regCompany } from '@/utils/utils';
	import axios from 'axios';
	import QRCode from 'qrcode';
	import Loading from '@/components/common/Loading.vue';

	import {
	  setToken,
	  setUsername,
	  getToken,
	  getUsername,
	  removeToken,
	  removeUsername,
	} from "@/utils/cookies";
	
	let {ctx, proxy} = getCurrentInstance();

	const props = defineProps({
	    showDialog: {
			type: Boolean,
			default: false
		},
		showMobile: {
			type: Boolean,
			default: false
		},
		showQrcode: {
			type: Boolean,
			default: false
		},
		showLogin: {
			type: Boolean,
			default: true
		},
	})
	// const { showDialog,showMobile,showQrcode } = toRefs(props);
	computed: mapGetters(['testInfo'])
	const size = "default";
	let tip = proxy.$t('login.login');
	let loginTip = ref(tip);
	let showLogin = ref(false);
	let showMobile = ref(false);
	let showQrcode = ref(false);
  	const store = useStore();
	const router = useRouter();

	const validate_mobile_rules = (rule, value, callback) => {
		let regMobile = proxy.$utils.isPhone(value);
	    if (value === "") {
	        callback(new Error(proxy.$t('enterPhoneNumber')));
	    } else if (!regMobile) {
	        callback(new Error(proxy.$t('enterCorrectPhoneNumber')));
	    } else {
	        callback();
	    }

	}
	const validate_email_rules = (rule, value, callback) => {
		if (value == "") return true;
		let email = proxy.$utils.regEmail(value);
	    if (!email) {
	        callback(new Error(proxy.$t('enterCorrectEmail')));
	    } else {
	        callback();
	    }
	    

	}
	const validate_username_rules = (rule, value, callback) => {
		let regMobile = proxy.$utils.regUsername(value);
	    if (value === "") {
	        callback(new Error( proxy.$t('enterUsername') ));
	    } else if (!regMobile) {
	        callback(new Error( proxy.$t('nameContain') ));
	    } else {
	        callback();
	    }

	}
	const validate_password_rules = (rule, value, callback) => {
		let reg = proxy.$utils.regPassword(value);
	    if (value === "") {
	        callback(new Error( proxy.$t('enterPassword') ));
	    } else if (!reg) {
	        callback(new Error( proxy.$t('passwordContain') ));
	    } else {
	        callback();
	    }

	}
	const validate_company_rules = (rule, value, callback) => {
		if (value == "") return true;
		let company = proxy.$utils.regCompany(value);
	    if (!company) {
	        callback(new Error( proxy.$t('enterCorrectUnitName') ));
	    } else {
	        callback();
	    }

	}
	const data = reactive({
		isMobile: false,
		showQrcode: false,
		showMobile: false,
		showReg: false,
		showLogin: false,
		showCodeLogin: false,
		forgetPass: false,
		globalShow: false,
		isQrcodeLoading: false,
		loadedQrcode:false,
		disabled: false,
		form: {
		  	mobile: '',
		  	password: ''
		},
		form_rules: {
			// mobile: [
			// 	{ required: true, message: proxy.$t('enterCorrectUsernameAndPhone'), trigger: 'blur' },
			// 	// { validator: validate_mobile_rules, trigger: "blur" },
			// 	{
			// 		pattern: /^[a-z0-9]{2,12}$/,
			// 		message: proxy.$t('nameContain'), 
			// 		trigger: 'blur'
			// 	}
			// ],
			// mobile: [
			// 	{ required: true, message: '请输入手机号', trigger: 'change' },
			// ]
		},
		mobileform: {
			mobile: '',
			code: ''
		},
		mobileform_rules: {
			mobile: [
				{ required: true, message: proxy.$t('enterPhoneNumber'), trigger: 'blur' },
				{ validator: validate_mobile_rules, trigger: "blur" },
				
			],
			code: [
				{ required: true, message: proxy.$t('enterCode'), trigger: 'blur' },		
			],
		},
		codeform: {
			mobile: '',
			code:''
		},

		codeform_rules: {
			mobile: [
				{ required: true, message: proxy.$t('enterPhoneNumber'), trigger: 'blur' },
				{ validator: validate_mobile_rules, trigger: "blur" },				
			],
			code: [
				{ required: true, message: proxy.$t('enterCode'), trigger: 'blur' },		
			],
		},
		
		regform: {
			username:'',
		  	mobile: '',
		  	password: '',
		  	email: '',
		  	department: '',
		  	code: '',
		},
		regform_rules: {
			username: [
				{ required: true, message: proxy.$t('uernameTips'), trigger: 'blur' },
				// { validator: validate_username_rules, trigger: "blur" },
				// {
				// 	pattern: /^\w{2,}$/,
				// 	message: proxy.$t('nameContain'), 
				// 	trigger: 'blur'
				// },
			],
			mobile: [
				{ required: true, message: proxy.$t('enterPhoneNumber'), trigger: 'blur' },
				{ validator: validate_mobile_rules, trigger: "blur" },
				
			],
			password: [
				{ required: true, message: proxy.$t('enterPassword'), trigger: 'blur' },
				{ validator: validate_password_rules, trigger: "blur" },
				// { min: 8, message: proxy.$t('passwordLength'), trigger: 'blur' },
				// { validator: validate_username_rules, trigger: "blur" },
			],
			email: [
				{ validator: validate_email_rules, trigger: "blur" },				
			],
			department: [
				{ validator: validate_company_rules, trigger: "blur" },
			],
			code: [
				{ required: true, message: proxy.$t('enterCode'), trigger: 'blur' },		
			],
		},
		forget_form: {
		  	mobile: '',
		  	code: '',
		  	password: '',
		  	password_confirm: ''
		},
		forget_rules: {
			mobile: [
				{ required: true, message: proxy.$t('enterPhoneNumber'), trigger: 'blur' },
				{ validator: validate_mobile_rules, trigger: "blur" },
				// {
				// 	pattern: /^[a-z0-9]{5,12}$/,
				// 	message: proxy.$t('nameContain'), 
				// 	trigger: 'blur'
				// }
			],
			code: [
				{ required: true, message: proxy.$t('enterCode'), trigger: 'blur' },		
			],
			password: [
				{ required: true, message: proxy.$t('enterPassword'), trigger: 'blur' },
				{ min: 8, message: proxy.$t('passwordLength'), trigger: 'blur' },
			],
			
		},
		expireTip: proxy.$t('qrlogin'),
	}); 

	let qrcode = ref('');
	let timerId = ref(0);

	const loginQrcode = () => {
		clearQrcodeCookie();
		store.dispatch("user/loginQrcode").then((res)=>{
			// console.log(res,"res")
			// data.expireTip = '等待扫码';
			qrcode.value = res.url;
			let img = QRCode.toDataURL(res.url).then(t => {
		      	qrcode.value = t
		      	data.isQrcodeLoading = false
		      	data.loadedQrcode = true

		      	timerId = setInterval(()=>{
					let expire_seconds = parseInt( localStorage.getItem('expire_seconds') );
		            let time = (new Date().getTime())/1000;
		            let scan_time = parseInt( localStorage.getItem('scan_time') );

		            if(expire_seconds && scan_time && scan_time+expire_seconds >= time){
		            	
		            	store.dispatch("user/QrcodeTicket",{ key: res.key }).then((res2)=>{
				    		console.log(res2,"res2");
				    		
				    		if(res2.status == 4){
								clearInterval(timerId);
								if(!res2.user.mobile){//号码为空，显示注册页
									toMobile();
								}else{
									ElMessage({
								        message: proxy.$t('loginSuccess'),
								        type: "success",
								        duration: 2000,
								    });
									handleClose();
									// 在登录成功后的处理逻辑中
					   				const redirectPath = sessionStorage.getItem('redirectPath');
									// console.log(redirectPath,"redirectPath");
								   	sessionStorage.removeItem('redirectPath');
								   	// router.push(redirectPath);
								   	location.reload();
								}
								
								clearQrcodeCookie();
							}else if(res2.status == 1){

							}else if(res2.status == 2){
								clearQrcodeCookie();
								
								ElMessage({
							        message: res2.msg,
							        type: "warning",
							        duration: 2000,
							    });
							}else if(res2.status == 3){
								clearQrcodeCookie();
								
								ElMessage({
							        message: res2.msg,
							        type: "warning",
							        duration: 2000,
							    });
							}
				    	});
		            }else{
		            	clearInterval(timerId);
		            	localStorage.setItem('expire_seconds',0);
	            		localStorage.setItem('scan_time', 0);
		            	loginQrcode();
		            	// data.expireTip = '二维码已过期，请刷新重试！';
		            	
		            }

			    },2000);
		    })

		    

		})
	}

	const clearQrcodeCookie = () => {
		proxy.$cookies.removeUrl();
		proxy.$cookies.removeExpireSeconds();
		proxy.$cookies.removeKey();
		proxy.$cookies.removeTicketKey();
	}

	const existsMobile = () => {
        store.dispatch("user/getPersonInfo",{}).then((res)=>{
            // console.log(res,"personInfo");          
            if(res.mobile){
                return true;
            }
            return false;
        }).catch(err => {
            return false;
        }) 
    }

	onMounted(async()=>{
    	await nextTick()
	    console.log( proxy.$t('pageUpdated') )
    })
 //    watch(
	//   () => [props.showDialog,props.showQrcode,props.showMobile],
	//   ([newVal,newQrcodeVal,newMobileVal], [oldVal,oldQrcodeVal,oldMobileVal]) => {
	//     console.log('new', newVal,newQrcodeVal,newMobileVal)
	//     if(newVal){
	//     	if(newQrcodeVal && !newMobileVal){
	//     		loginQrcode();
	//     	}	    	
	//     }else{
	//     	clearInterval(timerId);
	//     	clearQrcodeCookie();
	//     }
	//   }
	// )
	watch(
	  () => [ props.showDialog, props.showLogin, props.showMobile ],
	  ([newVal,newLoginVal,newMobileVal], [oldVal,oldLoginVal,oldMobileVal]) => {
	    console.log('new', newVal,newLoginVal,newMobileVal)
	    data.showLogin = newLoginVal;
	    data.showMobile = newMobileVal;
	  }
	)

	// watch(() => [props.showQrcode, props.showMobile],() => {
	// 	showQrcode.value = props.showQrcode;
	// 	data.showMobile = props.showMobile;
	// },{
	// 	immediate: true
	// })

	const getCode = (mobile) => {
		console.log(mobile,"mobile");
		if(!mobile){
			ElMessage({
		        message: proxy.$t('form.enterPhone'),
		        type: "warning",
		        
		    });
		    data.disabled = false;
		}else{
			store.dispatch('user/MobileCode',{
				mobile: mobile,
			}).then(res=>{
				
				console.log(res,"mobilecode-res");
				ElMessage({
			        message: res.message,
			        type: "success",
			        
			    });
			    data.disabled = true;
			}).catch(err=>{
				
				ElMessage({
			        message: err.message,
			        type: "error",
			        
			    });
			    data.disabled = false;
			})
		}
		
	}
	
	const handleChange = (event) => {
		console.log(event,"mobile change");
		data.disabled = false;
	}

	
	
	watch(
	  () => data.expireTip,
	  (newVal, oldVal) => {
	    // console.log('expireTip', newVal)
	    
	  }
	)

	const emit = defineEmits(['update:show-dialog'])
	watch(
        ()=>data.form.mobile,
        (val,preVal)=>{
            // val为修改后的值,preVal为修改前的值
            // console.log("mobile",val,preVal)
        },
        {
            //如果加了这个参数，值为true的话，就消除了惰性，watch会在创建后立即执行一次
            //那么首次执行，val为默认值,preVal为undefined
            immediate:true,
            //这个参数代表监听对象时，可以监听深度嵌套的对象属性
            //比如message是一个对象的话，可以监听到message.a.b.c，也就是message下的所有属性
            deep:true,
        }
    )

    const codeLogin = (formEl) => {
		if (!formEl) return
		formEl.validate((valid) => {
		    if (valid) {
		      // console.log('submit!',data.form)
		      store.dispatch("user/MobileCodeLogin",{
		    		mobile: data.codeform.mobile,
		    		code: data.codeform.code
		    	}).then((res) => {
					console.log(res,'login');
					ElMessage({
					    message: proxy.$t('loginSuccess'),
					    grouping: true,
					    type: 'success',
					})
					// 在登录成功后的处理逻辑中
				   	const redirectPath = sessionStorage.getItem('redirectPath');
				   	console.log(redirectPath,"redirectPath");
				   	sessionStorage.removeItem('redirectPath');
				   	// router.push(redirectPath);
				    location.reload();
					
				}).catch(err => {
		            console.error(err,"loginFailed")
		            ElMessage({
					    message: err.message||err.msg,
					    grouping: true,
					    type: 'error',
					})
					data.disabled = false;
		        })

		    } else {
		    	data.disabled = false;
		      	console.log('error submit!')
		      	return false
		    }
		})
	}
	// const reload = inject("reload");
	// reload();
	const handleClose = (event) => {
		clearInterval(timerId); 
		data.isQrcodeLoading = false;
		data.showQrcode = false;
		data.showMobile = false;
		data.showReg = false;
		data.showLogin = false;
		data.showCodeLogin = false;
		data.forgetPass = false;

		props.showDialog = ref(false);
        emit('update:show-dialog',props.showDialog)
    	  	
    }
    const mobileformRef = ref(null);
    const updateMobile = (formEl) => {
		if (!formEl) return
		formEl.validate((valid) => {
		    if (valid) {
		    	// console.log(valid,'submit!')
		    	store.dispatch("user/modifyPassword",{
		    		mobile: data.mobileform.mobile,
		    		code: data.mobileform.code,
		    	}).then((res) => {
					// console.log(res,'mobile');
					ElMessage({
					    message: proxy.$t('updateSuccess'),
					    grouping: true,
					    type: 'success',
					})
					handleClose();

					// 在登录成功后的处理逻辑中
	   				const redirectPath = sessionStorage.getItem('redirectPath');

					
				   	sessionStorage.removeItem('redirectPath');

				   	// router.push(redirectPath);
				   	location.reload();

				}).catch(err => {
		            // console.error(err)
		            ElMessage({
					    message: err.message,
					    grouping: true,
					    type: 'error',
					})
					data.disabled = false;
		        })
		        
		    } else {
		    	data.disabled = false;
		        console.log('error submit!')
		        return false
		    }
		})
		
	}
    const formRef = ref(null);
	const onLogin = (formEl) => {
		if (!formEl) return
		formEl.validate((valid) => {
		    if (valid) {
		      // console.log('submit!',data.form)
		      store.dispatch("user/login",{
		    		username: data.form.mobile,
		    		password: data.form.password
		    	}).then((res) => {
					console.log(res,'login');
					if(res.data.code<0){
						ElMessage({
						    message: res.data.msg,
						    grouping: true,
						    type: 'error',
						})
					}
					// else{
					// 	ElMessage({
					// 	    message: proxy.$t('loginSuccess'),
					// 	    grouping: true,
					// 	    type: 'success',
					// 	})
					// }
					
					// 在登录成功后的处理逻辑中
				   	const redirectPath = sessionStorage.getItem('redirectPath');
				   	console.log(redirectPath,"redirectPath");
				   	sessionStorage.removeItem('redirectPath');
				   	// router.push(redirectPath);
				   	if(res.data.code<0){
					   	setTimeout(()=>{
					   		location.reload();
					   	},3000);
					}else{
						location.reload();
					}
				    
					
					
					
				}).catch(err => {
		            console.error(err,"loginFailed")
		            ElMessage({
					    message: err.message||err.error,
					    grouping: true,
					    type: 'error',
					})
		        })
		    } else {
		      console.log('error submit!')
		      return false
		    }
		})
	  	// console.log("vuex的测试数据 :", testInfo);
	    // login(data.form).then((res) => {
	    //   console.log("axios的测试数据 :", res);
	      
	    // })
	    // .catch((err) => {
	    //   console.error(err);
	    // });
	}
	const forgetRef = ref(null);
	const onModify = (formEl) => {
		if (!formEl) return;
		formEl.validate((valid) => {
			if (valid) {

			} else {
		        console.log('error submit!')
		        return false
		    }
		})
	}
	const regformRef = ref(null);
	const onRegister = (formEl) => {
		if (!formEl) return
		formEl.validate((valid) => {
		    if (valid) {
		    	// console.log(valid,'submit!')
		    	store.dispatch("user/register",{
		    		username: data.regform.username,
		    		password: data.regform.password,
		    		mobile: data.regform.mobile,
		    		email: data.regform.email,
		    		department: data.regform.department,
		    		code: data.regform.code
		    	}).then((res) => {
					// console.log(res,'register');
					ElMessage({
					    message: proxy.$t('registerSuccess'),
					    grouping: true,
					    type: 'success',
					    customClass: 'layer-tip'
					})
					toLogin();
				}).catch(err => {
		            // console.error(err)
		            ElMessage({
					    message: err.message,
					    grouping: true,
					    type: 'error',
					    customClass: 'layer-tip'
					})
					data.disabled = false;
		        })
		        
		    } else {
		    	data.disabled = false;
		        console.log('error submit!')
		        return false
		    }
		})
		
	}
	
	const toQrcode = () => {
		data.isQrcodeLoading = true;
		data.showQrcode = true;
		data.showMobile = false;
		data.showReg = false;
		data.showLogin = false;
		data.showCodeLogin = false;
		data.forgetPass = false;
		let tip = proxy.$t('qrcode');
		loginTip = ref(tip);
		loginQrcode();
	}
	const toMobile = () => {
		data.isQrcodeLoading = false;
		console.log('tomobile');
		data.showQrcode = false;
		data.showMobile = true;
		data.showReg = false;
		data.showLogin = false;
		data.forgetPass = false;
		data.showCodeLogin = false;
		let tip = proxy.$t('phoneNumber');
		loginTip = ref(tip);
		clearInterval(timerId);
	}
	const toCodeLogin = () => {
		data.isQrcodeLoading = false;
		data.showQrcode = false;
		data.showMobile = false;
		data.showReg = false;
		data.showLogin = false;
		data.showCodeLogin = true;
		data.forgetPass = false;
		let tip = proxy.$t('codeLogin');
		loginTip = ref(tip);
		clearInterval(timerId);
	}
	
	const toForget = () => {
		data.showQrcode = false;
		data.showMobile = false;
		data.showReg = false;
		data.showLogin = false;
		data.forgetPass = true;
		let tip = proxy.$t('retrievePassword');
		loginTip = ref(tip);
		clearInterval(timerId);
	}
	const toRegister = () => {
		data.showReg = true;
		data.showLogin = false;
		data.showQrcode = false;
		data.showMobile = false;
		data.showCodeLogin = false;
		data.forgetPass = false;
		let tip = proxy.$t('register');
		loginTip = ref(tip);
		clearInterval(timerId);
	}
	const toLogin = () => {
		data.isQrcodeLoading = false;
		data.showReg = false;
		data.showLogin = true;
		data.showQrcode = false;
		data.showMobile = false;
		data.showCodeLogin = false;
		data.forgetPass = false;
		let tip = proxy.$t('login.login');
		loginTip = ref(tip);
		clearInterval(timerId);
	}
</script>
<!-- <script>
	import { ref, provide, reactive, getCurrentInstance, toRefs } from 'vue';
	import Menu from '@/components/layout/menu.vue';
	//引入接口模块
	import { login } from "@/api/user";
	import { mapGetters, useStore } from "vuex";
	import { useRouter } from "vue-router";
	import { isPhone } from '@/utils/utils';

	export default {
		name: 'Menu',
		props: {
			showDialog: {
				type: Boolean,
				default: false
			}
		},
		emits: ['getCode','update:show-dialog'],
		components: {
			Menu
		},
		// setup(props, context) {
		//     // setup() 接收 props 作为第一个参数
		//     // console.log(props.showDialog,context,"showDialog");
		//     // const handleClose = () => {
		//     //   props.showDialog = ref(false);
		//     //   context.emit('update:show-dialog',props.showDialog);
		//     // }
		// },
		setup (props, { emit }) {
			// const {showDialog} = toRefs(props)
			// // 获取实例上下文
   //  		const { proxy } = getCurrentInstance();
   //  		// router
   //  		const rotuer = useRouter();
    		const validate_mobile_rules = (rule, value, callback) => {
				// let regMobile = isPhone(value);
			 //    if (value === "") {
			 //        callback(new Error("请输入手机号"));
			 //    } else if (!regMobile) {
			 //        callback(new Error("手机号格式不正确"));
			 //    } else {
			 //        callback();
			 //    }

			};
    		const data = {
    			isMobile: ref(false),
				showReg: ref(false),
				showLogin: ref(true),
    			
    			form: {
				  mobile: '',
				  password: ''				  
				},
				regform: {
				  mobile: '',
				  password: '',
				  code: '',
				},
				form_rules: {
					mobile: [{ validator: validate_mobile_rules, trigger: "blur" }],
					// mobile: [
					// 	{ required: true, message: '请输入手机号', trigger: 'change' },
					// ]
				},
				regform_rules: {
					mobile: [
						{ validator: validate_mobile_rules, trigger: "blur" },			
					],
					password: [
						{ min: 6, max: 12, message: '密码长度在6-12位之间', trigger: 'blur' },
					],
				},
    		};

    		const refData = toRefs(data);
	        const handleClose = (event) => {
	        	console.log(event.target,"event.target.value");
	        	props.showDialog = ref(false);
	            // emit("handleClose", event.target.value)
	            emit('update:show-dialog',props.showDialog)
	        }

	        const formRef = ref(null);
			const onLogin = (formEl) => {
				if (!formEl) return
				formEl.validate((valid) => {
				    if (valid) {
				      console.log('submit!')
				    } else {
				      console.log('error submit!')
				      return false
				    }
				})
			}
			// const toRegister = () => {
			// 	data.showReg = ref(true);
			// 	data.showLogin = ref(false);
			// }
			// const toLogin = () => {
			// 	data.showReg = ref(false);
			// 	data.showLogin = ref(true);
			// }
	        return {
	        	...refData,
	            handleClose,
	            formRef,
	            onLogin,
	        }
	    },
		watch() {

		},
		mounted() {
			this.$nextTick(() => {
				this.init();
			});
		},
		computed: {
		    ...mapGetters(["testInfo"]),
		},
		methods: {
			// handleClose(props) {
			//     props.showDialog = ref(false);
			//     this.$emit('update:show-dialog',props.showDialog);
		 //    },
			init() {
				if(this.$utils.isMobile()){
		            this.isMobile = true;           
		        }else{
		            this.isMobile = false;
		        }
				
			},
			onRegister() {

			},
			// onLogin() {
			// 	console.log(data,"data");
			//   	console.log("vuex的测试数据 :", this.testInfo);
			//     login(data.form).then((res) => {
			//       console.log("axios的测试数据 :", res);
			      
			//     })
			//     .catch((err) => {
			//       console.error(err);
			//     });
			// },
			// onLogin(formEl) {
			// 	if (!this.formEl) return
			// 	this.formEl.validate((valid) => {
			// 	    if (valid) {
			// 	      console.log('submit!')
			// 	    } else {
			// 	      console.log('error submit!')
			// 	      return false
			// 	    }
			// 	})
			// },
			toRegister() {
				this.showReg = ref(true);
				this.showLogin = ref(false);
			},
			toLogin() {
				this.showReg = ref(false);
				this.showLogin = ref(true);
			},
			getCode() {

			},
			
		},
	}
</script> -->

<style src="@/assets/css/login.css" scoped></style>