<template>
	<PageLoading :is-loading="isLoading"></PageLoading>
	<SideComponent @scrollto="scroll"></SideComponent>
	<div class="container" ref="viewBox">
		<div class="header-container">
			<Header2 :show-search-bar-pic="showHeader" login-style="loginStyle" :mobile-icon-style="mobileIconStyle" :show-list-icon="showListIcon"/>	
		</div>
		<!-- <Header2 :show-search-bar-pic="showHeader" login-style="loginStyle" :mobile-icon-style="mobileIconStyle" :show-list-icon="showListIcon"/> -->
		<div class="content">
			<div style="height:60px;"></div>
			<div class="news-container">
				<section class="news-wrapper">
					<div class="news-left">
						<span class="text-style">{{$t('news.news')}}</span>
					</div>
					<div class="news-right">
						<div class="news-item">
							<div class="news_title">
								<span>{{newsDetail.title}}</span>
								<span>{{$t('news.publish_time')}}{{newsDetail.updated_at}}</span>
							</div>
							<div class="news_content">
								<div v-html="newsDetail.content" style="text-align: justify;"></div>
							</div>
							<div class="pic_and_video">
								<div class="section-inner3" v-if="imgcount==3">

									<div id="team1" class="team1" :style="{height:imgH+'px'}">  <!--  -->
										<div class="img-item" :style="imgStyle(item)" v-for="(item,index) in team1" >
											<img :src="item.thumb" alt=""  oncontextmenu="return false"/>
										</div>

									</div>
									
								</div>
								
								<div class="section-inner4" v-if="imgcount==4"> 
									<div id="team1" class="team1" > 
										<div class="img-item" :style="imgStyle(item)" v-for="(item,index) in team1" >
											<img :src="item.thumb" alt=""  oncontextmenu="return false"/>
										</div>

									</div>
								</div>
								<div class="section-inner5" v-if="imgcount==5">
									<div id="team1" class="team1">
										<div class="img-item" v-for="(item,index) in team1" >
											<img :src="item.thumb" alt="" oncontextmenu="return false" />
										</div>

									</div>
									<div class="team2">
										<div class="img-item" v-for="item in team2">
											<img :src="item.thumb" alt="" oncontextmenu="return false" />
										</div>

									</div>

								</div>

								<div class="section-inner" v-if="imgcount==6">  <!-- v-if="imgcount==3 || imgcount>=6" -->
									<div id="team1" class="team1">  <!-- :style="{height:imageHeight+'px'}" -->
										<div class="img-item"  v-for="(item,index) in team1" >
											<img :src="item.thumb" alt="" oncontextmenu="return false" />
										</div>

									</div>
									<div class="team2">
										<div class="img-item" v-for="item in team2">
											<img :src="item.thumb" alt="" oncontextmenu="return false" />
										</div>
									</div>

								</div>
								<div class="section-inner-more" v-if="imgList.length > 6">
									<div class="more-container">
										<div class="img-item" v-for="(item,index) in imgList" >  <!--  :style="activeStyle1(item)" -->
											<img :src="item.thumb" alt="" oncontextmenu="return false" />
										</div>
									</div>
									
								</div>

								<div class="loadmore">
									<Loading :is-loading="isMoreLoading"></Loading>
									<span class="more" @click="loadMore()" v-if="!nomore">{{$t('loadmore')}}</span>
									<span class="end" v-if="nomore">- {{$t('theEnd')}} -</span>
								</div>

							</div>
						</div>
						
					</div>
					<!-- <div class="page-wrapper">
						<el-pagination
						    background
						    layout="prev, pager, next" 
						    :total="pagination.total" 
						    v-model:current-page="pagination.current"
						    v-model:page-size="pagination.pageSize"
						    
						/>

					</div> -->

				</section>
			</div>

		</div>
		<Footer/>
	</div>
</template>

<script>
	import news_detail from './news_detail_controller';
	export default news_detail;
</script>

<style src="@/assets/css/news_detail.css" scoped></style>