<template>
	<PageLoading :is-loading="isLoading"></PageLoading>
	<SideComponent @scrollto="scroll"></SideComponent>
	<div class="container" ref="viewBox">		
		<div class="header-container">
			<HeaderVR />
			<!-- <div class="bigimg-container" v-if="banner.length>0">
				<Swiper :list="banner"/>
			</div> -->
		</div>
		<div id="content" class="content" ref="content">
			<Loading :is-loading="isListLoading"></Loading>
			<section id="arlist" class="section" v-if="type==2" :style="{height:maxHeight+'px'}"> 
				<div class="item" v-for="(item,index) in newslist" :key="'item' + index" @click="toDetail(item.id)" :ref="el => { divs[index] = el }"> 
					<div class="outer">
						<div class="item-title">
							<span>{{item.title}}</span>
						</div>
						<div class="item-text">
							<div v-html="item.desc"></div>
						</div>
						<div class="item-img">
							<img :src="item.thumb" oncontextmenu="return false" :alt="item.title"/>
						</div>
					</div>
				</div>

			</section>
			<section class="section" v-if="type==1">
				<div class="ar-content">
					
					<div class="html-content" id="html-content" v-html="arDetail.content"></div>
					
				</div>
			</section>
			<!-- <Footer/> -->
			
		</div>
		<div id="loadmore" class="loadmore" v-if="type==2">
			<span class="more" @click="loadMore()" v-if="!nomore">{{$t('loadmore')}}</span>
			<span class="end" v-if="nomore">- {{$t('theEnd')}} -</span>
		</div>
		<Footer/>
	</div>
	<!-- <Footer/> -->
</template>

<script>
	import article from './article_controller';
	export default article;
</script>

<style src="@/assets/css/article.css" scoped></style>