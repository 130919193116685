import { createApp, provide } from 'vue'
import './assets/css/style.css'
import App from './App.vue'
import Router from './router/index'
// import Antd from 'ant-design-vue'
// import 'ant-design-vue/dist/reset.css'
import { store } from './store'
import './assets/iconfont/iconfont.css'
// import Swiper from "swiper"
import 'swiper/css';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

// import infiniteScroll from "vue-infinite-scroll";

//引入elementplus icon
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { createPinia } from 'pinia'

// import 'font-awesome/css/font-awesome.min.css'
// import Layui from '@layui/layui-vue'
// import '@layui/layui-vue/lib/index.css'

// import 'vue-cropper/dist/index.css'
import 'animate.css/animate.min.css'
import eventBus from 'vue3-eventbus'
import { createMetaManager, defaultConfig, deepestResolver } from 'vue-meta'

import i18n from "./lang";

const t = i18n.global.t;

// import {zhCn,en} from "element-plus/lib/locale/lang";
// import en from "element-plus/lib/locale/lang";

window.global ||= window;
const app = createApp(App);
app.config.productionTip = false;

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}


import Login2 from '@/components/common/login2.vue';
app.component('Login2', Login2)

// document.getElementById('title').innerHTML = t('title');

//注册全局工具包
import * as utils from "/src/utils/utils";
app.config.globalProperties.$utils = utils;

import * as cookies from "/src/utils/cookies";
app.config.globalProperties.$cookies = cookies;
app.config.globalProperties.$menus = [0,10,1,2,7,8,5,6,11];
app.config.globalProperties.$t = t;
// vue3中用户登陆会把用户跳转到登录页面，如何让用户登录完成后，跳回原来的页面上
// https://blog.51cto.com/jackiehao/7338011
Router.beforeEach((to, from, next) => {
    // console.log(from, "from");
    if (to.meta.metaInfo) {
        store.commit("common/CHANGE_META_INFO", to.meta.metaInfo)
    }
    const isAuthenticated = cookies.getAuthKey();
    if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
        
        ElMessage({
            message: t('toLogin'),
            grouping: true,
            type: 'warning',
        })
        eventBus.emit('showLoginDialog', true);
        eventBus.emit('showLoginDialogMobile', false)
        eventBus.emit('showLoginDialogQrcode', false)
        eventBus.emit('showLoginDialogLogin', true)
        next({ path: from.path, query:from.query })

    } else if (to.matched.some(record => record.meta.requiresAuth) && isAuthenticated) { 
        store.dispatch("user/getPersonInfo",{}).then((res)=>{
            // console.log(res,"personInfo");            
            if(!res.mobile){
                eventBus.emit('showLoginDialog', true)
                eventBus.emit('showLoginDialogMobile', true)
                eventBus.emit('showLoginDialogQrcode', false)
                eventBus.emit('showLoginDialogLogin', false)
                next({ path: from.path, query:from.query })
            }else{
                next();
            }

        }).catch(err => {
            eventBus.emit('showLoginDialog', true);
            eventBus.emit('showLoginDialogMobile', false)
            eventBus.emit('showLoginDialogQrcode', false)
            eventBus.emit('showLoginDialogLogin', true)
        }) 
    } else {
        // document.body.scrollTop = 0
        // // firefox
        // document.documentElement.scrollTop = 0
        // // safari
        // window.pageYOffset = 0
        next();        
    }
});


// 回到页面顶部
// Router.afterEach((to,from,next)=>{
//   window.scrollTo(0,0)
// })

// app.provide(/* 注入名 */ 'message', /* 值 */ 'hello!')
// const components = [Router,Antd,store,ElementPlus,createPinia(),Layui,Cookies];

// app.use(ElementPlus, {
//     // locale: zhCn,
//     locale: i18n.global.locale.value == 'zh' ? zhCn : en,
// });

const metaManager = createMetaManager(false, {
    meta: { tag: 'meta', nameless: true },
});

// const metaManager = createMetaManager(defaultConfig, deepestResolver);
app.use(Router).use(store).use(eventBus).use(i18n).use(metaManager).mount('#app') //.use(infiniteScroll)

