import { onMounted,nextTick, defineComponent, ref, provide, reactive, toRefs, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import Swiper from '@/components/layout/Swiper.vue';
import SearchBar2 from '@/components/layout/SearchBar2.vue';
import Header2 from '@/components/layout/header2.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';
import SideMenu from '@/components/layout/SideMenu.vue';
import bus from 'vue3-eventbus'
import SideComponent from '@/components/common/SideComponent.vue';
import Loading from '@/components/common/Loading.vue';

export default defineComponent({
	components: {
		Header2,
		Menu,
	    Swiper,
	    SearchBar2,
	    Footer,
	    SideMenu,
	    SideComponent,
	    Loading,
	},
	props: {

	},
	setup() {
		let {ctx, proxy} = getCurrentInstance();
		const data = reactive({
			showHeader: true,
			loginStyle: "min-width:141px;",
			mobileIconStyle: "color:rgba(223, 58, 8, 1);",
			showListIcon: true,
			activeName:'first',
			size: 'default',
			date: [],
			input: '',
			authList:[],
			pagination:{},
			isMobile:false,
			keyword: '',
			start: '',
			end: '',
			shortcuts:[],
			isPicLoading: true,
		});
		const showMenu = ref(proxy.$menus);
		provide('showMenu', showMenu);

		const outerStyleStr = ref("position:relative;left:10%;top:40%;transform:translate(-10%,-40%);width:auto;");
		provide('outerStyleStr', outerStyleStr);

		const styleStr = ref("height:45px;");
		provide('styleStr', styleStr);

		const showSearchText = ref(false);
		provide('showSearchText', showSearchText);

		const getImageUrl = (name) => {
		    return proxy.$utils.getImageUrl(name);
		};

		const store = useStore();	

		const ruleFormRef = ref()

		const handleClick = (tab, event) => {
		  console.log(tab, event)
		}
		const getAuthList = (keyword='',start='',end='',page=1) => {
			store.dispatch("user/getAuthList",{
				search: keyword,
				start: start,
				end: end,
				page:page,
			}).then((res)=>{
				console.log(res,"authList");
				data.authList = res.data;
				data.pagination = res.pagination;
				data.isPicLoading = false;
				bus.emit('showFooter',true);
			}).catch(err=>{
				data.isPicLoading = false;
			})
		}
		
		
		data.shortcuts = [
		  {
		    text: '最近一个周',
		    value: () => {
		      const end = new Date()
		      const start = new Date()
		      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
		      return [start, end]
		    },
		  },
		  {
		    text: '最近一个月',
		    value: () => {
		      const end = new Date()
		      const start = new Date()
		      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
		      return [start, end]
		    },
		  },
		  {
		    text: '最近3个月',
		    value: () => {
		      const end = new Date()
		      const start = new Date()
		      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
		      return [start, end]
		    },
		  },
		];

		onMounted(async()=>{
        	getAuthList();
        	await nextTick()
        	setTimeout(() => {
		      data.isLoading = false;
		      viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		    }, 600);
		    console.log("页面数据更新完毕了")
        })

        const viewBox = ref(null);
        const scroll = (val) => {
			if(val == 'top'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		       
			}else if(val == 'bottom'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		        	
		        	
		        
			}
		}
        

		const refData = toRefs(data);

		return {
			...refData,
			getImageUrl,
			handleClick,
			getAuthList,
			viewBox,
			scroll,
		}
	},
	created() {

	},
	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	},
	methods: {
		init() {
			if(document.documentElement.clientWidth < 890){
				this.size = 'default';
				this.isMobile = true;
			}else{
				this.size = 'default';
				this.isMobile = false;
			}

	        window.addEventListener('resize', function(event){
				if(document.documentElement.clientWidth < 890){
					this.size = 'default';
					this.isMobile = true;
				}else{
					this.size = 'default';
					this.isMobile = false;
				}
				
			});
		},
		handleSizeChange(pageSize) {
	      this.pagination.pageSize = pageSize;
	      // 在此刷新数据
	    },
		handlePageChange(currentPage){
			this.pagination.current = currentPage;
			this.isPicLoading = true;
			this.search('','','',currentPage);
		},
		search(keyword='',start='',end='',page=1){
			console.log(this.date,"data");
			if(this.date.length > 0){
				this.start = this.date[0];
				this.end = this.date[1];
			}
			
			this.isPicLoading = true;
			this.getAuthList(this.keyword, this.start, this.end, page);
		},
	},
})