<template>
	<div class="header">
		<div class="logo-container">
			<div class="header-logo">
				<div class="logo-outer" @click="goHome()" :alt="siteInfo.title">
					<img v-if="logoColor=='black'" :src="siteInfo.logo" alt="logo">
					<img v-if="logoColor=='white'" :src="siteInfo.logo_white" alt="logo">
					<img v-if="logoColor=='red'" :src="siteInfo.logo_red" alt="logo">
					<!-- <div class="logo-text">
						<span class="header-big-text">青岛图片库</span>
						<span class="header-small-text">青岛国际新闻中心运维</span>
					</div> -->
				</div>
			</div>
			<div class="mobile-menu-icon" @click="showMobileMenu()">
				<i class="iconfont icon-listo list-iconstyle"></i>
			</div>
			<span class="space"></span>
			<Menu />
		</div>	
		<div style="display:flex;justify-content: flex-end;">
			<ChangeLang></ChangeLang>
			<div v-if="!isLogin" class="header-login" :style="loginStyle">
				<button :title="username" class="mobile-login" @click.stop="toUser()"><i class="iconfont icon-icontouxiang login-iconstyle" :style="mobileIconStyle"></i>{{username}}</button>
				<div class="popover-outer">
					<span role="button" tabindex="0" class="span-login"><i class="iconfont icon-icontouxiang login-iconstyle" :style="mobileIconStyle"></i>{{username}}</span>
					<div class="dropdown-menu">
						<div class="bottom"></div>
						<a class="dropdown-menu-item" v-for="child in popoverMenu" @click.stop="Exit(child)">
							<div class="border">
								<span>{{child.name}}</span>
							</div>
						</a>
					</div>
				</div>
				
			</div>	
			<div v-if="isLogin" class="header-login" @click="toLogin" :style="loginStyle">
				<button title="{{$t('loginOrRegister')}}" class="mobile-login"><i class="iconfont icon-icontouxiang login-iconstyle" :style="mobileIconStyle"></i></button>
				<span role="button" tabindex="0" class="span-login add-style">{{$t('loginOrRegister')}}</span>
			</div>
		</div>
	</div>	
	<Login2 :show-dialog="showDialog" :show-mobile="showMobile" :show-qrcode="showQrcode" ref="login" @update:show-dialog="childClick"/>
	
</template>

<script>
	import { onMounted, ref, provide, reactive, toRefs,inject,getCurrentInstance } from 'vue';
	import { useRouter, useRoute } from 'vue-router';
	import { useStore, mapGetters, createNamespacedHelpers  } from "vuex";
	import Menu from '@/components/layout/menu.vue';
	// import Login2 from '@/components/common/login2.vue';
	//引入接口模块
	import { login } from "@/api/user";
	import bus from 'vue3-eventbus'
	import ChangeLang from '@/components/common/ChangeLang.vue';
	

	export default {
		name: 'Menu',
		props: {
			showSearchBarPic: {
				type: Boolean,
				default: false,
			},
			loginStyle: {
				type: String,
				default: "",
			},
			mobileIconStyle: {
				type: String,
				default: "",
			},
			showListIcon: {
				type: Boolean,
				default: false,
			},
			imgsrc: {
				type: String,
				default: '',
			},
		},
		components: {
			Menu,
			ChangeLang,
			// Login2,
		},
		setup(props) {
			let {ctx, proxy} = getCurrentInstance();
			const data = reactive({
		        isMobile: false,// 这样创建的响应式数据就不用ref了，写起来是不是要方便点
		        showDialog: false,
		        showMobile: false,
		        showQrcode: false,
		        siteInfo: {},
		        isNeedRedirect:true,
		        siteInfo:{},
		        logoColor: inject('logoColor','white'),
		        isShow: inject('showHeader',true),
		        isLogin:true,
		        username: proxy.$t('anonymity'),
		        isMobileMenu: false,
		        popover: false,
		        popoverMenu:[
		        	{
			        	id:1,
			        	name: proxy.$t('personCenter'),
			        	type:'user',
			        },
			        {
			        	id:2,
			        	name: proxy.$t('logout'),
			        	type:'exit',
			        },
		        ],
		    })
			const childClick = (value) => {
				data.showDialog = value.value;
			}
			const toLogin = () => {
				data.showDialog = true;
				data.showMobile = false;
		        data.showQrcode = true;
			}

			const store = useStore();

			const propdata = toRefs(props);
			const getSiteInfo = () => {
				setTimeout(()=>{
					store.dispatch("common/fetchSiteInfo").then((res) => {
				        data.siteInfo = res;
				        
				    }).catch(err => {
				        
				    })
			    },200);

			}

	        onMounted(() => {
	        	bus.on('showLoginDialog', e => data.showDialog = e )
	            bus.on('showLoginDialogMobile', e => data.showMobile = e )
	            bus.on('showLoginDialogQrcode', e => data.showQrcode = e )
	            data.siteInfo = store.state.common.siteInfo;
	            if(Object.keys(data.siteInfo)<=0){
	            	getSiteInfo();
	            }
	            const isAuthenticated = proxy.$cookies.getAuthKey();
				if(isAuthenticated && 
					proxy.$cookies.getToken()!==undefined && 
					proxy.$cookies.getToken()!='' && 
					proxy.$cookies.getToken()!=null
				){
					data.isLogin = false;
					data.username = store.state.user.userInfo.username!="undefined" ? store.state.user.userInfo.username : store.state.user.userInfo.mobile;
					data.mobile = store.state.user.userInfo.mobile;
				}
	   //          const isAuthenticated = proxy.$cookies.getAuthKey();
    //         	if(isAuthenticated && 
    //         		proxy.$cookies.getToken()!==undefined && 
    //         		proxy.$cookies.getToken()!='' && 
    //         		proxy.$cookies.getToken()!=null
    //         	){
				// 	data.isLogin = false;
				// 	data.username = store.state.user.userInfo.username;
				// 	data.mobile = store.state.user.userInfo.mobile;
				// 	if(opened=='true'){
		  //           	data.open = false;
		  //           }else{
				// 		data.open = true;	
		  //           }
				// }
	        });
	        
			const refData = toRefs(data)
			return {
				childClick,
				toLogin,
				...refData,
				getSiteInfo,
			}
		},
		mounted() {
			this.$nextTick(() => {
				this.init();
			});
		},
		computed: {
		    ...mapGetters(["testInfo"]),
		},
		methods: {
			init() {
				sessionStorage.setItem('redirectPath', this.$route.fullPath);
				if(document.documentElement.clientWidth < 768){
					this.isMobile = true;
				}else{
					this.isMobile = false;
				}
				// if(this.$utils.isMobile()){
		  //         this.isMobile = true;           
		  //       } else {
		  //         this.isMobile = false;
		  //       }
				if(document.documentElement.clientWidth < 890){
					// document.querySelector(".menu-container").style.display = "none";
					document.querySelector(".mobile-menu-icon").style.display = "flex";
				} else {
					// document.querySelector(".menu-container").style.display = "flex";
					document.querySelector(".mobile-menu-icon").style.display = "none";
				}
				if(document.documentElement.clientWidth < 768){
					document.querySelector(".mobile-login").style.display = "block";
					document.querySelector(".span-login").style.display = "none";
				} else {
					document.querySelector(".mobile-login").style.display = "none";
					document.querySelector(".span-login").style.display = "block";
				}
		        window.addEventListener('resize', function(event){
					if(document.documentElement.clientWidth < 890){
						// document.querySelector(".menu-container").style.display = "none";
						document.querySelector(".mobile-menu-icon").style.display = "flex";
					} else {
						// document.querySelector(".menu-container").style.display = "flex";
						document.querySelector(".mobile-menu-icon").style.display = "none";
					}
					if(document.documentElement.clientWidth < 768){
						document.querySelector(".mobile-login").style.display = "block";
						document.querySelector(".span-login").style.display = "none";
					} else {
						document.querySelector(".mobile-login").style.display = "none";
						document.querySelector(".span-login").style.display = "block";
					}
					
				});
			},
			Exit(item){
				if(item.type=='exit'){
					this.$cookies.removeToken();
					this.$cookies.removeUsername();
					this.$cookies.removeMobile();
					this.$cookies.removeTokentype();
					this.$cookies.removeAuthKey();
					location.reload();
				} else if(item.type=='user'){
					this.$router.push('/my/setting');
				}
				
			},
			showMobileMenu() {
				bus.emit('showMobileMenu', true);
			},
			toUser(){
				if(this.isMobile) {
					this.$router.push('/my/home');
				}else{
					this.$router.push('/my/setting');
				}
				
			},
			goHome(){
				this.$router.push('/');
			},
		},
	}
</script>

<style src="@/assets/css/header_vr.css" scoped> </style>