<template>
<SideComponent @scrollto="scroll"></SideComponent>
	<div class="container" ref="viewBox">
		<Header2 :show-search-bar-pic="showHeader" login-style="loginStyle" :mobile-icon-style="mobileIconStyle" :show-list-icon="showListIcon"/>
		<div class="content">
			<div style="height:80px;"></div>
			<div class="enclosure">
				<div class="inner">
					<div class="left">
						<SideMenu />
					</div>
					<div class="right">
						
						<div class="tag-outer">
							<el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
								<el-tab-pane :label="$t('active.pic')" name="first">
									<div class="work-status">
										<span class="work-status-span" :class="activeClass(item.id)" v-for="item in statusList" @click="GetListByStatus(item.id)">{{item.name}}</span>
										
									</div>
									<div class="work-list">
								    	<div class="work-list-items" v-for="(item,index) in folderList">
								    		<div class="work-list-item" @click="toCollectWork(item.id)">
								    			<div class="img-outer">
								    				<img v-if="!item.thumb" src="@/assets/img/default.png" />
								    				<img v-if="item.thumb" :src="item.thumb" />
								    				<i class="iconfont icon-tuceng1 icon-pic" :disabled="pic_icon_disabled" ></i>
								    			</div>
								    		</div>
								    		<div class="work-list-item">
								    			<div class="work-info-title">{{item.title}}</div>
	
								    		</div>
								    		<div class="work-list-item">
								    			<span v-if="item.status==0" class="info-status info-warning">{{$t('status.reviewing')}}</span>
								    			<span v-if="item.status==1" class="info-status info-success">{{$t('status.reviewed')}}</span>
								    			<span v-if="item.status==2" class="info-status info-error">{{$t('status.not_review')}}</span>
								    			<span v-if="item.status==2" class="info-status-error">{{$t('apply_common.season')}}{{item.message}}</span>
								    		</div>
								    		<div class="work-list-item">
								    			<span  class="info-status info-primary" @click="editFolder(item.id)">{{$t('apply_common.edit')}}</span> <!-- v-if="item.status==2" -->
								    			<!-- <span v-if="item.status==1&&item.onsale==1" class="info-status info-primary" @click="OffSale(item.id)">下架</span>
								    			<span class="info-status info-primary" @click="DeleteWork(item.id)">删除</span> -->
								    			<el-popconfirm
								    				class="info-border-status info-border-green"
												    confirm-button-text="Yes"
												    cancel-button-text="No"
												    :icon="InfoFilled"
												    icon-color="#626AEF"
												    :title="$t('apply_common.del_tip')"
												    @confirm="delFolder(item.id)"
												    @cancel="cancelEvent"
												    v-if="item.status!=1"
												>
												    <template #reference>
												      <el-button>{{$t('apply_common.del')}}</el-button>
												    </template>
												</el-popconfirm>
								    		</div>
								    	</div>

							    	</div>
							    	<div class="page-wrapper">
							    		<Loading :is-loading="isPicLoading"></Loading>
										<el-pagination 
										    background 
										    layout="prev, pager, next" 
										    :total="pagination.total" 
										    v-model:current-page="pagination.current"
										    v-model:page-size="pagination.pageSize"	
										    @current-change="handlePageChange"			    
										/>

									</div>
							    </el-tab-pane>
							    <el-tab-pane :label="$t('active.video')" name="second">
							    	<div class="work-status">
										<span class="work-status-span" :class="activeClass(item.id)" v-for="item in statusList" @click="GetListByStatus(item.id)">{{item.name}}</span>
										
									</div>
							    	<div class="work-list">
								    	<div class="work-list-items" v-for="(item,index) in folderVideoList">
								    		<div class="work-list-item"  @click="toCollectWork(item.id)">
								    			<div class="img-outer">
								    				<img v-if="!item.url" src="@/assets/img/default.png" />
								    				<video v-if="item.url" id="video+'0'"
														controls				    
													    preload="none" 
													    muted 
													    style="object-fit:contain;width:100%;height:100%;" 
													    controlslist="nodownload" 
													    oncontextmenu="return false"
													    :poster="item.thumb"
													    :src="item.url" >
														<track kind="captions" />
														
													</video>
													<i class="iconfont icon-tuceng1 icon-pic" :disabled="video_icon_disabled"></i>
								    			</div>
								    		</div>
								    		<div class="work-list-item">
								    			<div class="work-info-title">{{item.title}}</div>

								    		</div>
								    		<div class="work-list-item">
								    			<span v-if="item.status==0" class="info-status info-warning">{{$t('status.reviewing')}}</span>
								    			<span v-if="item.status==1" class="info-status info-success">{{$t('status.reviewed')}}</span>
								    			<span v-if="item.status==2" class="info-status info-error">{{$t('status.not_review')}}</span>
								    			<span v-if="item.status==2" class="info-status-error">{{$t('apply_common.season')}}{{item.message}}</span>
								    		</div>
								    		<div class="work-list-item">
								    			<span class="info-status info-primary" @click="editFolder(item.id)">{{$t('apply_common.edit')}}</span>
								    		</div>
								    	</div>

							    	</div>
							    	<div class="page-wrapper">
							    		<Loading :is-loading="isPicLoading"></Loading>
										<el-pagination 
										    background 
										    layout="prev, pager, next" 
										    :total="pagination.total" 
										    v-model:current-page="pagination.current"
										    v-model:page-size="pagination.pageSize"	
										    @current-change="handlePageChange"			    
										/>

									</div>
							    </el-tab-pane>

							</el-tabs>
							
						</div>
						<!-- 编辑合辑 -->
						<div class="subject-wrapper" v-if="showFolder">
							<div class="head">
								<div class="head-item">
									<span>{{$t('apply_common.edit_folder')}}</span>
								</div>
								<div class="head-item">
									<span @click="closeFolder()"><i class="iconfont icon-fork"></i></span>
								</div>
							</div>
							<div class="body">
								<Loading :is-loading="folderFormLoading"></Loading>
								<el-form ref="folderRef" :model="folderForm" :rules="folder_rules" label-width="200px" :size="formSize" style="width:90%;">

									<el-form-item prop="title" :rules="folder_rules.title" :label="$t('active.folder_title')">
									    <el-input v-model="folderForm.title" :size="formSize" autocomplete="off" :placeholder="$t('active.enter_title')" ></el-input>
									</el-form-item>										
									<el-form-item prop="description" :label="$t('active.folder_desc')">
									    <el-input :rows="8"
												  type="textarea" 
												  v-model="folderForm.description" 
												  autocomplete="off" 
												  :placeholder="$t('active.enter_desc')">
												  	
										</el-input>
									</el-form-item>
									<el-form-item prop="cover_type" :label="$t('active.cover_type')">
										<div class="radio-outer" v-for="cover in coverType">
											<span style="padding:5px 5px;">
												<input type="radio" :value="cover.id" v-model="checkedCoverType" />
											</span>
											<span style="padding:5px 5px;">{{cover.name}}</span>
											<span style="padding:5px 5px;font-size:12px;">{{cover.tip}}</span>
										</div>
									</el-form-item>	

									<el-form-item prop="thumb" :label="$t('active.folder_cover')" style="display:flex;" v-if="checkedCoverType==2">
										<div class="images-item btn-pic common">
											<label for="thumb_upload" class="upload-btn">
												<input type="file" id="thumb_upload" @change="uploadThumb($event)" accept="image/*" style="display:none;" />									
												<p class="thumb-btn">{{$t('form.uploadPic')}}</p>								    
											</label>							
										</div>
										<div class="images-item common">
											<div class="img-outer">	
												<Loading :is-loading="folderThumbLoading"></Loading>												
												<div class="img-wrapper">
													<img v-if="!folderForm.thumb" src="@/assets/img/default.png" />
													<img v-if="folderForm.thumb" :src="folderForm.thumb" />

												</div>
											</div>
											
										</div>
									</el-form-item>

									<el-form-item style="padding:20px;display:flex;justify-content: center;">
									    <el-button type="primary" class="el-button-1" :disabled="folder_disabled" @click="submitFolderForm(folderRef)">{{$t('apply_work.tip41')}}</el-button>	   
									</el-form-item>
								</el-form>
								
							</div>
						</div>
						<!-- 编辑合辑 -->
					</div>
				</div>
			</div>
		</div>
		<Footer/>
	</div>
</template>

<script>
	import collect_folder from './collect_folder_controller';
	export default collect_folder;
</script>

<style src="@/assets/css/my/collect_folder.css" scoped></style>




