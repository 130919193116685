<template>
    <!-- <SeoPage></SeoPage> -->
    <metainfo>
      <template v-slot:title="{ content }">{{ content }}</template>
      <!-- <template v-slot:title="{ content,metadata }">{{ metadata.description }}</template> -->
    </metainfo>
    <!-- <SideComponent :cart-count="state.cartListCount"></SideComponent> -->
    <!-- <Login2 :show-dialog="showDialog" ref="login" @update:show-dialog="childClick"/> -->
    <router-view />
    <!-- <SideComponent></SideComponent> -->
</template>

<script setup>
  // import SideComponent from '@/components/common/SideComponent.vue';
  // import Login2 from '@/components/common/login2.vue';
	import { nextTick, reactive, getCurrentInstance, provide, ref,onMounted,watch,watchEffect} from 'vue'
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import bus from 'vue3-eventbus'
  import { useMeta, useActiveMeta } from 'vue-meta'
  import i18n from "@/lang";
  import SeoPage from '@/components/common/SeoPage.vue';
  // const isRouter = ref(true);
  // const reload = () => {
  //   isRouter.value = false;
  //   nextTick(() => {
  //     isRouter.value = true;
  //   });
  // };
  // provide("reload", reload);

  let {ctx, proxy} = getCurrentInstance();
  const store = useStore();
  let expires_in = store.state.user.userInfo.expires_in;
  let login_time = store.state.user.userInfo.login_time;

  // const data = reactive({
  //   title: proxy.$t('title'),
  //   seo_keywords: proxy.$t('title'),
  //   seo_description: proxy.$t('title'),
  // });
  let title = proxy.$t('title');
  const seo_keywords = ref(title);
  const seo_description = ref(title);

  // const { meta } = useMeta({
  //     // charset: 'utf8',
  //     title: title,
  //     // description: seo_description.value,
  //     // keywords: seo_keywords.value
  // })

  const { meta } = useMeta({
    title: seo_keywords.value,
    meta: [
      {
        name: 'keywords',
        content: seo_keywords.value
      },
      {
        name: 'description',
        content: seo_description.value
      },
      {
        property: 'og:image',
        content: 'https://www.qdpic.cn/obs/images/0fce2d2ae9ed87275131c9d9291c165f.png',
      },
      {
        property: 'og:title',
        content: seo_keywords.value,
      },
      {
        property: 'og:description',
        content: seo_description.value,
      },
      {
        property: 'og:author',
        content: seo_keywords.value,
      },
      {
        property: 'og:site_name',
        content: seo_keywords.value,
      },
    ],
    
    // og: {
    //   title: title,
    //   description: seo_description.value,
    //   image: [

    //   ]
    // },
    // twitter: {
    //   title: 'Twitter Title'
    // }
  })

  const getSiteInfo = () => {
    return new Promise((resolve, reject) => {
      store.dispatch("common/fetchSiteInfo").then((res) => {
        resolve(res);
      }).catch(err => {
        reject();
      })
    })
    
  }
  watchEffect(() => {
    const keywordValue = seo_keywords.value
    const descValue = seo_description.value
    
  })

  // // const { meta, onRemoved } = useMeta(computedMeta)

  // // onRemoved(() => {
  // //   // Do something as soon as this metadata is removed,
  // //   // eg because the component instance was destroyed
  // // })

  // Get the currently used metainfo
  const metadata = useActiveMeta()

  watch(metadata, (newValue) => {
    // metadata was updated, do something
    console.log(newValue,'newValue');
  })

  onMounted(async()=>{

    // useMeta({
    //    title: '',
    //    htmlAttrs: { 
    //      lang: i18n.global.locale.value, 
    //      amp: true,
    //    },
    //    meta: [
    //         {
    //            name: 'keywords',
    //            content: 'res.seo_keyword'
    //        },
    //        {
    //            name: 'description',
    //            content: 'res.seo_description'
    //        }
    //    ]
    
    getSiteInfo().then(res=>{
      console.log(res,'site info');
      meta.title = res.title;//'映像青岛（Hi!Qingdao）网站是以图片、视频及数字博物馆为主要内容板块的城市宣传网站，通过全新的视觉传播形式展现青岛城市风采。';//res.seo_keyword;
      meta.meta = meta.meta.map((item,index)=>{
        if(item.name && (item.name == 'keywords')){
          item.content = res.seo_keyword;
        }
        if(item.name && (item.name == 'description')){
          item.content = res.seo_description;
        }
        if(item.name && (item.name == 'og:author')){
          item.content = res.title;
        }
        
        if(item.property && (item.property == 'og:image') ){
          item.content = res.logo;
        }
        if(item.property && (item.property == 'og:title') ){
          item.content = res.title;
        }
        if(item.property && (item.property == 'og:description') ){
          item.content = res.seo_description;
        }
        if(item.property && (item.property == 'og:site_name') ){
          item.content = res.title;//res.seo_keyword;
        }
        return item;
      })
      // meta.og.title = res.title;
      // meta.og.description = res.seo_description;
      // meta.og.image.push(res.logo);
     //  useMeta({
     //   title: title,
     //   htmlAttrs: { 
     //     lang: i18n.global.locale.value, 
     //     amp: true,
     //   },
     //   meta: [
     //        {
     //           name: 'keywords',
     //           content: res.seo_keyword
     //       },
     //       {
     //           name: 'description',
     //           content: res.seo_description
     //       }
     //   ]
     // })
    })
    

    await nextTick(()=>{
      // getSiteInfo();
    });
    
  })

  

  let needRefresh = false;
  bus.on('needRefresh', e => needRefresh=e );
  console.log(needRefresh,"needRefresh");
  if(expires_in > 0) {
    var timestamp = new Date().getTime();
    let nowtime = Math.floor(timestamp / 1000);
    // console.log(nowtime,"nowtime");
    // console.log(expires_in,"expires_in");
    // console.log(nowtime - login_time,expires_in,"calc");
    if(nowtime - login_time > expires_in){
      if(needRefresh){
        store.commit("user/isRefreshToken",true);
      }
      
    }else{
      store.commit("user/isRefreshToken",false);
    }
  }

  if(store.state.user.tokenNeedRefresh){
    store.dispatch("user/refreshToken").then((token)=>{
      console.log(token,'refresh-token');
    });
  }

  // // 创建一个响应式的对象
  // const state = reactive({
  //   cartListCount: store.state.user.cartList
  // });

  // // 监听state.count的变化
  // watch(() => store.state.user.cartList, (newValue) => {
  //   state.cartListCount = newValue;
  // });

  // nextTick(()=>{
  //   // 1.禁用右键菜单
  //     document.oncontextmenu = new Function("event.returnValue=false");
  //     // 2.禁用鼠标选中
  //     document.onselectstart = new Function("event.returnValue=false");
  // })
            
</script>



<style scoped>
.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
  transition: filter 300ms;
}
.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}
.logo.vue:hover {
  filter: drop-shadow(0 0 2em #42b883aa);
}
</style>
