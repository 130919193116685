<template>
	<PageLoading :is-loading="isLoading"></PageLoading>
	<SideComponent @scrollto="scroll"></SideComponent>
	<div class="container" ref="viewBox">
		<div class="header-container">
			<div class="search-swiper-container">				
				<Swiper :list="banners"/>
				<search-bar-pic :is-need-redirect="isNeedRedirect"></search-bar-pic>
			</div>
		</div>
		<div class="pic-content">
			<div class="enclosure" style="">
				<section class="section">
					<Loading :is-loading="isPicLoading"></Loading>
					<div class="_1Aw4E" v-for="item in subjectCats">
						<a class="img">
							<img :src="item.thumb" @click.stop="toSubjectCategory(item.id)" oncontextmenu="return false"/>
							<span class="category-name" @click.stop="toSubjectCategory(item.id)">{{item.title}}</span>
						</a>
					</div>
				</section>
			</div>
		</div>

		<Footer/>
	</div>
</template>

<script>
	import topic_pic from './topic_pic_controller';
	export default topic_pic;
</script>

<style src="@/assets/css/topic_pic.css" scoped>
	/*@import url("@/assets/css/style.css");全局引用css*/
	/*@import '@/assets/css/style.css';*/
</style>