<template>
	<PageLoading :is-loading="isLoading"></PageLoading>
	<div class="container" ref="viewBox">
		<div class="header-container">
			<div class="search-swiper-container">				
				<Swiper :list="banners"/>
				<!-- <search-bar-pic :isNeedRedirect="false" @searchKeywords="fetchGrapher"></search-bar-pic> -->
			</div>
		</div>
		<div class="content">
			<div class="tag-outer">
				<el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
					<el-tab-pane :label="$t('apply_work.tip0')" name="first">
						
				    	<div class="upload-content" v-if="showImgUpload">	
				    		<div class="union_input">{{$t('apply_common.batch_pics')}}</div>
				    		<el-form ref="picOneRef" :model="picOneForm" label-width="200px" :size="formSize" style="width:90%;" label-position="top">
				    			<el-form-item prop="cate" :label="$t('activity.category')">
								    <el-select
									    v-model="picOneForm.cate"
									    filterable="true"
									    :placeholder="$t('activity.select_cate')"
									    style="width: 240px"
									  >
									    <!-- <template #header>
									      <el-input v-model="search" autocomplete="off" :placeholder="$t('activity.selectActName')" ></el-input>
									      <div class="act-search" @click="searchAct()">{{$t('search.search')}}</div>
									    </template> -->
									    
									    <el-option
									      v-for="val in picCate"
									      :key="val"
									      :label="val"
									      :value="val"
									    />

									    <!-- <template #footer>
									      <el-button text bg size="small" @click="loadMoreCate()">
									        {{$t('apply_work.more')}}
									      </el-button>
									      
									    </template> -->
									  </el-select>
								</el-form-item>
				    			<el-form-item prop="description" :label="$t('active.tip7')">
								    <el-input type="textarea" :rows="3" v-model="picOneForm.description" :size="formSize" autocomplete="off" :placeholder="$t('apply_work.tip8')" ></el-input>
								</el-form-item>
								<el-form-item  prop="keywords" :label="$t('active.tip9')">
								    <!-- <el-input v-model="picOneForm.keywords" :size="formSize" autocomplete="off" :placeholder="$t('apply_work.tip10')" ></el-input> -->
								    <label for="pic_keywords" class="keyword-label">
								    	<span v-for="(tag,index) in picOneForm.keywords" class="keyword-tag">{{tag}}<i class="iconfont icon-chahao4 chahao" @click.stop="delTag(index,'image')"></i></span>
								    	<input class="input" type="text" v-model="pic_keyword" id="pic_keywords" autocomplete="off" :placeholder="$t('apply_work.tip10')" @keyup.enter="handleKeyword('image')" @blur="handleKeyword('image')"/>
								    </label>
								    <div style="display:flex;padding:5px 0;">
								    	<span style="color:red;font-size:14px;margin-right:5px;">*</span>
								    	<span style="color:#aab2bd;">{{$t('apply_common.enter_keyword_tip')}}</span>
								    </div>
								</el-form-item>
								

								<el-form-item prop="author" :label="$t('apply_common.author')">
								    <el-input v-model="picOneForm.author" :size="formSize" autocomplete="off" :placeholder="$t('apply_common.enter_author')" ></el-input>
								</el-form-item>
								<el-form-item prop="create_date" :label="$t('apply_common.create_date')">
									<el-date-picker v-model="picOneForm.create_date" type="date" :placeholder="$t('apply_common.create_date')" size="default" />
								</el-form-item>
				    		</el-form>
							<label for="image_activity_upload" @drop="handleCameraDrop" @dragover="handleCameraDragOver" class="upload-label">
								<input type="file" id="image_activity_upload" multiple @change="uploadCameraFile($event,'image')" accept="image/*" style="display:none;" />
								
							    <i class="iconfont icon-shangchuan5 upicon"></i>
							    <p class="upload-tiptext">{{$t('apply_work.tip4')}}</p>
							    <p class="upload-tiptext">{{$t('apply_work.pic_limit')}}</p>
							</label>
							<Loading :is-loading="isPicLoading"></Loading>
						</div>
						<div class="pic-form" v-if="showPicForm">

							<el-form ref="ruleRef" :model="ruleForm" :rules="_rules" label-width="200px" :size="formSize" style="width:90%;" label-position="top">
								<div v-for="(item,index) in ruleForm.tableData" class="list-item">
									<div class="list-item-content">
										<div class="img-section" style="">
											<label :for="'image_single_upload_'+index" class="list-item-img-outer" >
												<Loading :is-loading="isImgSingleLoading"></Loading>
												<input type="file" :id="'image_single_upload_'+index" @change="uploadSingleFile($event,index,'image')" accept="image/*" style="display:none;" />
												<img v-if="!item.thumb" src="@/assets/img/default.png" />
												<img v-if="item.thumb" style="width:100%;height:100%;object-fit:contain;" :src="item.thumb"/>
												<!-- <span class="repeat" v-if="item.hasSimilar">图片重复</span> -->
												<!-- <div style="position:absolute;left:0;top:0;z-index:99;width:100%;height:100%;background-color:rgba(0,0,0,0.1);display:flex;justify-content: center;align-items: center;">
													<i class="iconfont icon-delete" style="color:white;font-size:18px;" @click.stop="del('image', index)"></i>
												</div> -->
											</label>
										</div>
										<div class="body-section" style="">
											<el-form-item :prop="'tableData.' + index + '.title'" :rules="_rules.title" :label="$t('apply_work.tip5')">
											    <el-input v-model="item.title" :size="formSize" autocomplete="off" :placeholder="$t('apply_work.tip6')" ></el-input>
											</el-form-item>
											
											<el-form-item :prop="'tableData.' + index + '.description'" :label="$t('active.tip7')">
											    <el-input type="textarea" :rows="3" v-model="item.description" autocomplete="off" :placeholder="$t('apply_work.tip8')" ></el-input>
											</el-form-item>
											<el-form-item  :prop="'tableData.' + index + '.keywords'" :label="$t('active.tip9')">
											    <!-- <el-input v-model="item.keywords" autocomplete="off" :placeholder="$t('apply_work.tip10')" ></el-input> -->
											    <label :for="'pic_keywords_'+index" class="keyword-label">
											    	<span v-for="(tag,idx) in item.keywords" class="keyword-tag">{{tag}}<i class="iconfont icon-chahao4 chahao" @click.stop="delTag(idx,'image',index)"></i></span>
											    	<input class="input" type="text" v-model="item.pic_keyword" :id="'pic_keywords_'+index" autocomplete="off" :placeholder="$t('apply_work.tip10')" @keyup.enter="handleKeyword('image',index)" @blur="handleKeyword('image',index)"/>
											    </label>
											    <div style="display:flex;padding:5px 0;">
											    	<span style="color:red;font-size:14px;margin-right:5px;">*</span>
											    	<span style="color:#aab2bd;">{{$t('apply_common.enter_keyword_tip')}}</span>
											    </div>
											</el-form-item>
											<!-- <el-form-item :prop="'tableData.' + index + '.actid'" :rules="_rules.actid" :label="$t('activity.actName')">
											    <el-select
												    v-model="item.actid"
												    filterable="true"
												    :placeholder="$t('activity.selectAct')"
												    style="width: 240px"
												    @change="getCategory('image',item.actid)"
												  >
												    <template #header>
												      <el-input v-model="search" autocomplete="off" :placeholder="$t('activity.selectActName')" ></el-input>
												      <div class="act-search" @click="searchAct()">{{$t('search.search')}}</div>
												    </template>
												    
												    <el-option
												      v-for="act in actList"
												      :key="act.id"
												      :label="act.title"
												      :value="act.id"
												    />

												    <template #footer>
												      <el-button text bg size="small" @click="loadMore()">
												        {{$t('apply_work.more')}}
												      </el-button>
												      
												    </template>
												  </el-select>
											</el-form-item> -->
											<el-form-item :prop="'tableData.' + index + '.cate'" :label="$t('activity.category')">
											    <el-select
												    v-model="item.cate"
												    filterable="true"
												    :placeholder="$t('activity.select_cate')"
												    style="width: 240px"
												  >
												    <!-- <template #header>
												      <el-input v-model="search" autocomplete="off" :placeholder="$t('activity.selectActName')" ></el-input>
												      <div class="act-search" @click="searchAct()">{{$t('search.search')}}</div>
												    </template> -->
												    
												    <el-option
												      v-for="val in picCate"
												      :key="val"
												      :label="val"
												      :value="val"
												    />

												    <!-- <template #footer>
												      <el-button text bg size="small" @click="loadMoreCate()">
												        {{$t('apply_work.more')}}
												      </el-button>
												      
												    </template> -->
												  </el-select>
											</el-form-item>
											<!-- <el-form-item :prop="'tableData.' + index + '.folder_id'" :label="$t('apply_common.add_to_folder')">
											    <el-select
												    v-model="item.folder_id"
												    filterable="true"
												    :placeholder="$t('apply_common.select_folder')"
												    style="width: 240px"
												  >
												    <template #header>
												      <div class="add-topic" @click="addFolder()">{{$t('apply_common.add_folder')}}</div>
												    </template>
												    
												    <el-option
												      v-for="folder in folder_list"
												      :key="folder.id"
												      :label="folder.title"
												      :value="folder.id"
												    />

												    <template #footer>
												      <el-button text bg size="small" @click="loadMoreFolder()">
												        {{$t('apply_work.more')}}
												      </el-button>
												      
												    </template>
												  </el-select>
											</el-form-item> -->
											<el-form-item :prop="'tableData.' + index + '.author'" :label="$t('apply_common.author')">
											    <el-input v-model="item.author" :size="formSize" autocomplete="off" :placeholder="$t('apply_common.enter_author')" ></el-input>
											</el-form-item>
											<el-form-item :prop="'tableData.' + index + '.create_date'" :label="$t('apply_common.create_date')">
												<el-date-picker v-model="item.create_date" type="date" :placeholder="$t('apply_common.create_date')" size="default" />
											</el-form-item>
										</div>
									</div>
									<div class="list-item-operate">
										<span class="item-del" @click="delItem(index)"><i class="iconfont icon-delete icon-dell"></i>{{$t('apply_common.del')}}</span>
									</div>
								</div>	
								<div class="protocol-outer">
									<label for="proto_label">
										<input id="proto_label" type="radio" v-model="isAgreementPic" value="1"/>{{$t('apply_common.agreement')}}<span @click="toProtocols" style="color:rgb(230,0,15);">{{$t('apply_common.protocols')}}</span>
									</label>
								</div>									
								<el-form-item class="form-button">
								    <el-button type="primary" class="el-button-1" @click="submitForm(ruleRef)" :disabled="pic_disabled">{{$t('apply_work.tip25')}}</el-button>	
								    <el-button type="primary" class="el-button-1" @click="addItem()">{{$t('apply_common.addimg')}}</el-button>   
								</el-form-item>
							</el-form>
						</div>
				    </el-tab-pane>
				    <el-tab-pane :label="$t('apply_work.tip26')" name="second">
				    	
				    	<div class="upload-content" v-if="showVideoUpload">
				    		<div class="union_input">{{$t('apply_common.batch_videos')}}</div>
				    		<el-form ref="videoOneRef" :model="videoOneForm" label-width="120px" :size="formSize" style="width:90%;" label-position="top">
				    			<el-form-item prop="cate" :label="$t('activity.category')">
								    <el-select
									    v-model="videoOneForm.cate"
									    filterable="true"
									    :placeholder="$t('activity.select_cate')"
									    style="width: 240px"
									  >
									    <!-- <template #header>
									      <el-input v-model="search" autocomplete="off" :placeholder="$t('activity.selectActName')" ></el-input>
									      <div class="act-search" @click="searchAct()">{{$t('search.search')}}</div>
									    </template> -->
									    
									    <el-option
									      v-for="val in videoCate"
									      :key="val"
									      :label="val"
									      :value="val"
									    />

									    <!-- <template #footer>
									      <el-button text bg size="small" @click="loadMoreCate()">
									        {{$t('apply_work.more')}}
									      </el-button>									      
									    </template> -->
									  </el-select>
								</el-form-item>
				    			<el-form-item prop="description" :label="$t('apply_work.tip32')">
								    <el-input type="textarea" :rows="3" v-model="videoOneForm.description" :size="formSize" autocomplete="off" :placeholder="$t('apply_work.tip33')" ></el-input>
								</el-form-item>
								<el-form-item prop="keywords" :label="$t('active.tip9')">
								    <!-- <el-input v-model="videoOneForm.keywords" :size="formSize" autocomplete="off" :placeholder="$t('apply_work.tip10')" ></el-input> -->
								    <label for="video_keywords" class="keyword-label">
								    	<span v-for="(tag,index) in videoOneForm.keywords" class="keyword-tag">{{tag}}<i class="iconfont icon-chahao4 chahao" @click.stop="delTag(index,'video')"></i></span>
								    	<input class="input" type="text" v-model="video_keyword" id="video_keywords" autocomplete="off" :placeholder="$t('apply_work.tip10')" @keyup.enter="handleKeyword('video')" @blur="handleKeyword('video')"/>
								    </label>
								    <div style="display:flex;padding:5px 0;">
								    	<span style="color:red;font-size:14px;margin-right:5px;">*</span>
								    	<span style="color:#aab2bd;">{{$t('apply_common.enter_keyword_tip')}}</span>
								    </div>
								</el-form-item>
								<!-- <el-form-item prop="actid" :label="$t('activity.actName')">
								    <el-select
									    v-model="videoOneForm.actid"
									    filterable="true"
									    :placeholder="$t('activity.selectAct')"
									    style="width: 240px"
									    @change="getCategory('video')"
									  >
									    <template #header>
									      <el-input v-model="search" autocomplete="off" :placeholder="$t('activity.selectActName')" ></el-input>
									      <div class="act-search" @click="searchAct()">{{$t('search.search')}}</div>
									    </template>
									    
									    <el-option
									      v-for="act in actList"
									      :key="act.id"
									      :label="act.title"
									      :value="act.id"
									    />

									    <template #footer>
									      <el-button text bg size="small" @click="loadMore()">
									        {{$t('apply_work.more')}}
									      </el-button>
									      
									    </template>
									  </el-select>
								</el-form-item> -->
								
								<!-- <el-form-item prop="folder_id" :label="$t('apply_common.add_to_folder')">
								    <el-select
									    v-model="videoOneForm.folder_id"
									    filterable="true"
									    :placeholder="$t('apply_common.select_folder')"
									    style="width: 240px"
									  >
									    <template #header>
									      <div class="add-topic" @click="addFolder()">{{$t('apply_common.add_folder')}}</div>
									    </template>
									    
									    <el-option
									      v-for="folder in video_folder_list"
									      :key="folder.id"
									      :label="folder.title"
									      :value="folder.id"
									    />

									    <template #footer>
									      <el-button text bg size="small" @click="loadMoreFolder()">
									        {{$t('apply_work.more')}}
									      </el-button>
									      
									    </template>
									  </el-select>
								</el-form-item> -->

								<el-form-item prop="author" :label="$t('apply_common.author')">
								    <el-input v-model="videoOneForm.author" :size="formSize" autocomplete="off" :placeholder="$t('apply_common.enter_author')" ></el-input>
								</el-form-item>
								<el-form-item prop="create_date" :label="$t('apply_common.create_date')">
									<el-date-picker v-model="videoOneForm.create_date" type="date" :placeholder="$t('apply_common.create_date')" size="default" />
								</el-form-item>
				    		</el-form>
							<label for="video_activity_upload" @drop="handleCameraDrop" @dragover="handleCameraDragOver" class="upload-label">
								<input type="file" id="video_activity_upload" multiple @change="uploadCameraFile($event,'video')" accept="video/*" style="display:none;" />
								
							    <i class="iconfont icon-shangchuan5 upicon"></i>
							    <p class="upload-tiptext">{{$t('apply_work.tip29')}}</p>
							    <p class="upload-tiptext">{{$t('apply_work.video_limit')}}</p>
							</label>
							<Loading :is-loading="isVideoLoading"></Loading>
						</div>
						<div class="pic-form" v-if="showVideoForm">

							<el-form ref="ruleVideoRef" :model="ruleVideoForm" :rules="video_rules" label-width="200px" :size="formSize" style="width:90%;"  label-position="top">

								<div v-for="(item,index) in ruleVideoForm.tableData" class="list-item">
									<div class="list-item-content">
										<div class="img-section">
											<label :for="'video_single_upload_'+index" class="list-item-img-outer" style="display:flex;justify-content: center;">
													<Loading :is-loading="isVideoSingleLoading"></Loading>
													<input type="file" :id="'video_single_upload_'+index" @change="uploadSingleFile($event,index,'video')" accept="video/*" style="display:none;" />
													<img v-if="!item.url" src="@/assets/img/default.png" />
													<video v-if="item.url" id="video+'0'"
														controls																			    
													    preload="none" 
													    muted 
													    style="object-fit:contain;width:100%;height:100%;" 
													    controlslist="nodownload" 
													    oncontextmenu="return false"
													    :src="item.url"
													    :poster="item.thumb"
													    >
														<track kind="captions" />
														<!--:poster="item.thumb" <source :src="item" type="video/mp4"> -->
													</video>
													<!-- <div style="position:absolute;left:0;top:0;z-index:99;width:100%;height:100%;background-color:rgba(0,0,0,0.1);display:flex;justify-content: center;align-items: center;">
														<i class="iconfont icon-delete" style="color:white;font-size:18px;" @click="del('video', index)"></i>
													</div> -->
												</label>
											
										</div>
										<div class="body-section">
											<el-form-item :prop="'tableData.' + index + '.title'" :rules="_rules.title" :label="$t('apply_work.tip30')">
											    <el-input v-model="item.title" :size="formSize" autocomplete="off" :placeholder="$t('apply_work.tip31')" ></el-input>
											</el-form-item>
											
											<el-form-item :prop="'tableData.' + index + '.description'" :label="$t('apply_work.tip32')">
											    <el-input type="textarea" :rows="3" v-model="item.description" autocomplete="off" :placeholder="$t('apply_work.tip33')" ></el-input>
											</el-form-item>
											<el-form-item  :prop="'tableData.' + index + '.keywords'" :label="$t('active.tip9')">
											    <!-- <el-input v-model="item.keywords" autocomplete="off" :placeholder="$t('apply_work.tip10')" ></el-input> -->
											    <label :for="'video_keywords_'+index" class="keyword-label">
											    	<span v-for="(tag,idx) in item.keywords" class="keyword-tag">{{tag}}<i class="iconfont icon-chahao4 chahao" @click.stop="delTag(idx,'video',index)"></i></span>
											    	<input class="input" type="text" v-model="item.video_keyword" :id="'video_keywords_'+index" autocomplete="off" :placeholder="$t('apply_work.tip10')" @keyup.enter="handleKeyword('video',index)" @blur="handleKeyword('video',index)"/>
											    </label>
											    <div style="display:flex;padding:5px 0;">
											    	<span style="color:red;font-size:14px;margin-right:5px;">*</span>
											    	<span style="color:#aab2bd;">{{$t('apply_common.enter_keyword_tip')}}</span>
											    </div>
											</el-form-item>
											<!-- <el-form-item :prop="'tableData.' + index + '.actid'" :rules="_rules.actid" :label="$t('activity.actName')">
											    <el-select
												    v-model="item.actid"
												    filterable="true"
												    :placeholder="$t('activity.selectAct')"
												    style="width: 240px"
												  >
												    <template #header>
												      <el-input v-model="search" autocomplete="off" :placeholder="$t('activity.selectActName')" ></el-input>
												      <div class="act-search" @click="searchAct()">{{$t('search.search')}}</div>
												    </template>
												    
												    <el-option
												      v-for="act in actList"
												      :key="act.id"
												      :label="act.title"
												      :value="act.id"
												    />

												    <template #footer>
												      <el-button text bg size="small" @click="loadMore()">
												        {{$t('apply_work.more')}}
												      </el-button>
												      
												    </template>
												  </el-select>
											</el-form-item> -->
											<el-form-item :prop="'tableData.' + index + '.cate'" :label="$t('activity.category')">
											    <el-select
												    v-model="item.cate"
												    filterable="true"
												    :placeholder="$t('activity.select_cate')"
												    style="width: 240px"
												  >
												    <!-- <template #header>
												      <el-input v-model="search" autocomplete="off" :placeholder="$t('activity.selectActName')" ></el-input>
												      <div class="act-search" @click="searchAct()">{{$t('search.search')}}</div>
												    </template> -->
												    
												    <el-option
												      v-for="val in videoCate"
												      :key="val"
												      :label="val"
												      :value="val"
												    />

												    <!-- <template #footer>
												      <el-button text bg size="small" @click="loadMoreCate()">
												        {{$t('apply_work.more')}}
												      </el-button>
												      
												    </template> -->
												  </el-select>
											</el-form-item>
											<!-- <el-form-item :prop="'tableData.' + index + '.folder_id'" :label="$t('apply_common.add_to_folder')">
											    <el-select
												    v-model="item.folder_id"
												    filterable="true"
												    :placeholder="$t('apply_common.select_folder')"
												    style="width: 240px"
												  >
												    <template #header>
												      <div class="add-topic" @click="addFolder()">{{$t('apply_common.add_folder')}}</div>
												    </template>
												    
												    <el-option
												      v-for="folder in video_folder_list"
												      :key="folder.id"
												      :label="folder.title"
												      :value="folder.id"
												    />

												    <template #footer>
												      <el-button text bg size="small" @click="loadMoreFolder()">
												        {{$t('apply_work.more')}}
												      </el-button>
												      
												    </template>
												  </el-select>
											</el-form-item> -->
											<el-form-item :prop="'tableData.' + index + '.author'" :label="$t('apply_common.author')">
											    <el-input v-model="item.author" :size="formSize" autocomplete="off" :placeholder="$t('apply_common.enter_author')" ></el-input>
											</el-form-item>
											<el-form-item :prop="'tableData.' + index + '.create_date'" :label="$t('apply_common.create_date')">
												<el-date-picker v-model="item.create_date" type="date" :placeholder="$t('apply_common.create_date')" size="default" />
											</el-form-item>
										</div>
									</div>
									<div class="list-item-operate">
										<span class="item-del" @click="delItem(index)"><i class="iconfont icon-delete icon-dell"></i>{{$t('apply_common.del')}}</span>
									</div>
								</div>
								<div class="protocol-outer">
									<label for="video_proto_label">
										<input id="video_proto_label" type="radio" v-model="isAgreementVideo" value="1"/>{{$t('apply_common.agreement')}}<span @click="toProtocols" style="color:rgb(230,0,15);">{{$t('apply_common.protocols')}}</span>
									</label>
								</div>		
								<el-form-item  class="form-button">
								    <el-button type="primary" class="el-button-1" @click="submitVideoForm(ruleVideoRef)" :disabled="video_disabled">{{$t('apply_work.tip25')}}</el-button>	 
								    <el-button type="primary" class="el-button-1" @click="addItem()">{{$t('apply_common.addvideo')}}</el-button>  
								</el-form-item>
							</el-form>
						</div>
				    </el-tab-pane>

				</el-tabs>
				
			</div>
			<div class="subject-wrapper" v-if="showFolder">
				<div class="head">
					<div class="head-item">
						<span>{{$t('apply_common.add_folder')}}</span>
					</div>
					<div class="head-item">
						<span @click="closeFolder()"><i class="iconfont icon-fork"></i></span>
					</div>
				</div>
				<div class="body">
					<Loading :is-loading="folderFormLoading"></Loading>
					<el-form ref="folderRef" :model="folderForm" :rules="folder_rules" label-width="200px" :size="formSize" style="width:90%;">

						<el-form-item prop="title" :rules="folder_rules.title" :label="$t('active.folder_title')">
						    <el-input v-model="folderForm.title" :size="formSize" autocomplete="off" :placeholder="$t('active.enter_title')" ></el-input>
						</el-form-item>										
						<el-form-item prop="description" :label="$t('active.folder_desc')">
						    <el-input :rows="8"
									  type="textarea" 
									  v-model="folderForm.description" 
									  autocomplete="off" 
									  :placeholder="$t('active.enter_desc')">
									  	
							</el-input>
						</el-form-item>
						<el-form-item prop="cover_type" :label="$t('active.cover_type')">
							<div class="radio-outer" v-for="cover in coverType">
								<span style="padding:5px 5px;">
									<input type="radio" :value="cover.id" v-model="checkedCoverType" />
								</span>
								<span style="padding:5px 5px;">{{cover.name}}</span>
								<span style="padding:5px 5px;font-size:12px;">{{cover.tip}}</span>
							</div>
						</el-form-item>	

						<el-form-item prop="thumb" :label="$t('active.folder_cover')" style="display:flex;" v-if="checkedCoverType==2">
							<div class="images-item btn-pic common">
								<label for="thumb_upload" class="upload-btn">
									<input type="file" id="thumb_upload" @change="uploadThumb($event)" accept="image/*" style="display:none;" />									
									<p class="thumb-btn">{{$t('form.uploadPic')}}</p>								    
								</label>							
							</div>
							<div class="images-item common">
								<div class="img-outer">	
									<Loading :is-loading="folderThumbLoading"></Loading>												
									<div class="img-wrapper">
										<img v-if="!folderForm.thumb" src="@/assets/img/default.png" />
										<img v-if="folderForm.thumb" :src="folderForm.thumb" />
									</div>
								</div>
								
							</div>
						</el-form-item>

						<el-form-item style="padding:20px;display:flex;justify-content: center;">
						    <el-button type="primary" class="el-button-1" :disabled="disabled" @click="submitFolderForm(folderRef)">{{$t('apply_work.tip41')}}</el-button>	   
						</el-form-item>
					</el-form>
					
				</div>
			</div>
		</div>
		<Footer/>
		
	</div>
	<div class="agreement" v-if="showDialog">
		<div class="agreement-title" @click.stop="close()"><i class="iconfont icon-chahao2"></i></div>
		<div class="agreement-wrapper">
			<div v-html="protocol"></div>
		</div>
	</div>
</template>

<script>
	import active_detail from './active_detail_controller';
	export default active_detail;
</script>

<style src="@/assets/css/active_detail.css" scoped></style>