export default {
    login: {
        login: '로그인',
        userName: '사용자 이름',
        password: '암호',
        name: '사용자 이름 또는 단위 이름',
    },
    captcha: '인증 코드',
    forgetPassword: '비밀번호를 잊었니?',
    loginTip: '현재 로그인 결과가 무작위입니다.인증 코드 마음대로 기입하세요',
    editpassword: '비밀번호 변경',
    errMsg: {
        inputRequired: '입력하십시오{cont}',
        selectRequired: '선택하십시오{cont}',
    },
    uernameTips: '개인 사용자는 사용자 이름을, 단위 사용자는 단위 이름을 입력하십시오.',

    title: '영상칭다오',
    logo_small_text:'칭다오국제뉴스센터 운영',
    hello: '안녕하세요.',
    loginOrRegister: '로그인',
    anonymity: '익명의 사용자',
    personCenter: '개인 센터',
    logout:'로그아웃',
    toLogin: '먼저 로그인하십시오',
    mobileTip: '핸드폰 번호',
    submitText: '제출',
    accountTip: '사용자 이름',
    password: '암호',
    newPassword: '새 암호',
    confirmPassword: '새 암호 확인',
    noAccount: '아직 계좌번호가 없으세요?',
    hasAccount: '계좌번호 있으세요?',
    register: '등록',
    phoneNumber: '핸드폰 번호',
    email: '메일 박스',
    organizationName: '단위 명칭',
    loginNow:'지금 로그인',
    accountLogin: '계정 등록',
    loginSuccess: '로그인 성공',
    loginFailed: '로그인 실패',
    registerSuccess: '등록에 성공했습니다',
    registerFailed: '등록 실패',
    enterUsername: '사용자 이름을 입력하십시오',
    enterPhoneNumber: '핸드폰 번호를 입력하세요',
    enterRealname: '본명을 기입해 주세요',
    enterCorrectUnitName: '단위명을 정확히 기입해 주세요',
    enterCorrectIdcard: '주민등록번호를 정확하게 기입해 주세요',
    enterCorrectInstitutionName: '정확한 기구 번호를 기입하시오',
    enterCorrectPhoneNumber: '정확한 휴대전화 번호를 입력해 주세요',
    enterCorrectEmail: '정확한 우편함을 입력해 주세요',
    enterCorrectUnitName: '단위명칭은 한자 또는 자모 또는 수자의 조합으로 할수 있다',
    enterCorrectUsernameAndPhone: '아이디/핸드폰 번호를 입력하세요',
    nameContain: '사용자 이름은 글자나 숫자를 포함해야 합니다',
    passwordContain: '암호는 최소 8자리 숫자, 문자, 특수 기호 (! # $% ^ & *? - _) 조합이 필요합니다.',
    passwordContain2: '密码需要至少8位数字、字母、特殊符号!@#$%^&*?-_组合',
    enterPassword: '비밀번호를 입력하십시오.',
    passwordLength: '암호 길이 최소 8비트',
    qrlogin: '스캔 로그인',
    updateSuccess: '업데이트 성공',
    retrievePassword: '비밀번호 찾기',
    pageUpdated: '페이지의 데이터 갱신이 완료되었습니다',

    cart1: '쇼핑 카트',
    onlineService: '온라인 고객서비스',
    newsCenter: '칭다오국제뉴스센터 운영',
    qdnews: '칭다오 국제 뉴스 센터',
    hostUnit: '주최자:',
    contactPhone: '연락 전화',
    workTime: '근무 시간',
    footerText1: '엄청난 양의 고화질 소재',
    footerText2: '저작권 상용',
    aboutus: '우리 자신에 대해',
    copyrightNotice: '저작권 선언',
    questions: '늘 보는 질문',
    terms: '사이트 약관',
    selfRegulatory: '사이트 플랫폼 자율공약',
    contractUs: '연락하기',
    friendLink:'사용자 정의 링크',
    followUs: '우리를 염려하다',
    copyright:'칭다오 판권 소유',
    ICP: 'icp 2023012351호-1',
    category:'분류',
    pv:'총 조회수:',
    uv:'총등록인원수:',
    qrcode:'스캔 로그인',
    loadmore: '추가 로드',
    theEnd: '끝',

    codeLogin:'인증 코드 로그인',
    getCode:'인증 코드 가져오기',
    enterCode:'인증 코드를 입력하십시오.',
    codetip:'인증 코드',
    viewmore:'자세히 보기',

    menus: {
        home: '첫 페이지',
        news: '비주 얼',
        pic: '사진',
        video: '동영상',
        vr: '디지털 박물관',
        grapher: '사진사',
        active: '활동',
        more: '더 많은 서비스',
        package: '프라이스 세트',
        subject: '특집',
    },
    contract:{
        tip1:'계약 자료를 보충하다.',
        tip2:'계약 서명',
        tip3:'계약 다운로드',
        tip4:'계약의 유효성과 그 이후의 보장을 위해서 진실한 개인 정보를 기입하십시오. 이후에 구매하는 경우에는 이번에 기입한 자료를 자동으로 사용합니다.',
        tip5:'주문 id',
        tip6:'주문서 id를 입력해주세요',
        tip7:'자료 형식',
        tip8:'개인 사용자',
        tip9:'기업 기구',
        tip10:'실명',
        tip11:'실명을 입력해 주십시오',
        tip12:'주민등록번호',
        tip13:'주민등록번호를 입력하십시오',
        tip14:'기구를 짜다',
        tip15:'조직을 입력하십시오',
        tip16:'기구 번호',
        tip17:'기구 번호를 입력하십시오',
        tip18:'메일 박스',
        tip19:'편지함을 입력하십시오',
        tip20:'계약 템플릿 생성',
        tip21:'빨간색 테두리 부분의 왼쪽 마우스 단추를 누르고 서명을 손으로 쓰십시오',
        tip22:'서명 생성',
        tip23:'화판을 비우다',
        tip24:'계약서를 다운로드하다',
        tip25:'사용자 종류를 선택하십시오',
        tip26:'실명을 입력해 주십시오',
        tip27:'주민등록번호를 입력하십시오',
        tip28:'기관명을 입력하십시오',
        tip29:'기구 번호를 입력하십시오',
        tip30:'편지함을 입력하십시오',
        tip31:'계약 데이터를 저장하는 데 실패했습니다',
        tip32:'계약 정보를 가져오는 데 실패했습니다',
        tip33:'업로드 실패',

    },
    recharge:{
        tip1:'수익 개요',
        tip2:'수입 개관',
        tip3:'미결산금액 (원)',
        tip4:'결제완료금액 (원)',
        tip5:'결산을 신청하다',
        tip6:'주문서 명세',
        tip7:'작품 제목',
        tip8:'지불 시간',
        tip9:'주문 금액',
        tip10:'배당수익',
        tip11:'주문서 번호:',
        tip12:'결산 기록',
        tip13:'결산을 신청하다',
        tip14:'결산 진행 상황',
        tip15:'기장 시간',
        tip16:'결제 금액',
        tip17:'결산을 기다리다',
        tip18:'결산 중',
        tip19:'결제완료',
        
    },

    search: {
        search: '검색',
        hotSearch: '인기 검색',
        takePic: '이미지 검색',
        dragPic: '이 지역의 아무 곳이나 그림을 끌어 놓으십시오',
        or: '또는',
        clickUpload: '클릭하여 업로드하기',
        sizeLimit: '크기가 50mb 이하인 jpg/png 파일을 사용합니다',
        searchPic: '그림 검색',
        searchVideo: '동영상 검색',
        pic: '사진',
        video: '동영상',
        trySearch:'검색어를 사용해 볼 수 있습니다',
        scenery: '경치',
        building: '건축',
        culture: '문화',
        humanities: '인문',
        educate: '교육',
        isJPG: '업로드는 jpg, png 형식만!',
    },
    sideMenu: {
        apply: '사진사가 원고를 제공하다.',
        account: '계정 관리',
        wallet: '내 지갑',
        cart: '쇼핑 카트',
        authRecords: '인증 기록',
        orderlist:'주문서 리스트',
        collectManage: '행사 모집',
        collect: '활동 작품',
        folder: '활동 합집',
        personCenter: '사진사 센터',
        applyInfo: '입주 정보',
        workManage: '내 작품',
        recharge: '수익센터',
    },
    person: {
        personCenter: '창작자 센터',

    },
    application: {
        settled: '사진사가 입주하다',
        process: '입점 절차',
        plain: '입주 설명',
        apply: '원서를 내다',
        result: '결과 제출',
        tipText1: '영상칭다오 창작사진사 신청을 환영합니다. 입주의 성공률을 높이기 위해서 정확하게 입주시기를 바랍니다.',
        tipText2: '우리는 3일 근무일 내에 당신의 신청을 회답할 것이며, 당신은 여기에서 입주 진행 상황을 확인하실 수 있습니다',
        apply_enter: '입주를 신청하다',
        base_info: '기본 정보를 기입해 주세요',
    },
    form: {
        user_type: '사용자 유형',
        license: '비즈니스 라이선스',
        licenseTip: '단위 사업자 등록증 스탬프가 찍힌 전자 버전(또는 조직 코드 인증서 스탬프가 찍힌 전자 버전)',
        type1: '사람',
        type2: '단위 조직',
        unitName:'단위 이름',
        unitTip:'단위 이름을 입력하세요.',
        realname: '실명',
        enterRealname: '실명을 입력해 주십시오',
        gender: '성별',
        male: '남자',
        female: '여자',
        photo: '개인 사진',
        enterPhoto: '개인 사진 올려주세요',
        uploadPhoto: '사진을 올리다',
        biography: '간단 한 소개',
        enterBiography: '간단한 설명을 기입해 주십시오.',
        mobile: '핸드폰 번호',
        enterPhone: '핸드폰 번호를 입력하세요',
        email: '전자 사서함',
        enterEmail: '편지함을 입력하십시오',
        company: '단위 이름',
        enterCompany: '단위 이름을 입력하세요.',
        enterFeedType: '제공 원고의 유형을 선택하세요',
        ctype: '취업 유형',
        media: '미디어',
        non_media: '비 미디어',
        worktype: '원고제공유형',
        image: '사진',
        video: '동영상',
        workPic: '그림 대표작입니다.',
        workPicTip: '(대표작품은 입점심사에만 참고, 양질의 원작 대표작품을 업로드 하면 입점성공률을 높이는데 유리함)',
        uploadPic: '그림 업로드',
        againUploadPic: '그림 계속 업로드',
        againUploadVideo: '동영상 계속 업로드',
        limit_pic_quantity: '(10장의 원본 사진을 제공해주세요.)',
        workVideo: '비디오 대표작',
        uploadVideo: '동영상 업로드',
        limit_video_quantity: '(3개의 오리지널 비디오를 제공해주세요.)',
        submit_apply: '원서를 내다',
        tip_text_1: '고객님의 입점 정보 심사중입니다...',
        tip_text_2: '저희는 3일 근무일 내에 귀하의 입주정보에 대하여 심사를 진행하겠습니다. (국정공휴일시 시간을 순연합니다.)',
        tip_text_3: '이 페이지에서 감사 결과를 볼 수 있습니다!',
        uploadPicWork: '그림 작품 올려주세요',
        uploadVideoWork: '영상물 올려주세요',
        enter_limit_pic: '사진 10장 올려주세요',
        enter_limit_video: '동영상 3개 올려주세요',
        submited: '지원서는 이미 제출되었다.',
        only_ten_pic: '이미지 10장만 업로드 가능합니다',
        only_three_video: '동영상은 3개만 올릴 수 있습니다',
        idcard: '주민등록번호',
        idcard_photo: '신분증 사진',
        enter_idcard: '주민등록번호를 입력하십시오',
        idcard_tip: '(주민등록증 프로필 사진 올려주세요)',
        bankcard: '은행 카드 번호',
        enter_bankcard: '카드번호를 입력하세요',
        bankcard_photo:'카드 사진',
        bankcard_tip:'(카드번호면이 있는 사진 올려주세요)',
        preview:'계약서를 제출하고 미리 보기',
        enterCorrectbankcardNumber: '정확한 카드 번호를 기입해 주세요',
        enterIdcardPhoto: '신분증 앞면을 업로드하십시오',
        enterBankcardPhoto: '카드 앞면을 올려주세요',
    },
    apply_contract:{
        info: '계약 정보',
        sign: '계약서 서명',
        tip1: '쌍방의 합법적인 권리를 보장하기 위하여 신분정보와 은행카드정보를 입력하여 전자계약을 생성하도록 하십시오. 서명 후 바로 체결됩니다.',
        tip2: '*아래의 정보는 계약서인과 수익결산에만 사용되며 계약서인은 변경할수 없습니다. 진지하게 작성하시기 바랍니다!',
        tip3: '쌍방의 합법적인 권리를 보장하기 위해서, 계약 내용을 자세히 보시고, 서명한 후에는 수정할 수 없습니다',
        tip4: '계약서 정보를 기재해주세요',
        tip5: '계약미리보기',
        tip6: '빨간색 테두리 부분의 왼쪽 마우스 단추를 누르고 서명을 손으로 쓰십시오',
        tip7: '서명 생성',
        tip8: '화판을 비우다',
        signSuccess:'서명 성공',
        noMatch:'아이디 정보 불일치',
        submit_success:'커밋 성공',
        idcard_face:'신분증 정면 사진을 올려주세요 (또는 다시 올려주세요).',
        upload_fail:'업로드 실패',

    },
    apply_info: {
        tip1:'영상 청도에 오신 것을 환영합니다, 당신은 당신의 작품을 올릴 수 있고, 심사 통과 후 곧 수익을 벌 수 있는 기회가 있습니다~',
        tip2:'입주 정보',
        tip3:'클릭해서 계약서를 살펴보다.',
        tip4: '전자계약',
        
    },

    apply_nopass: {
        tip1:'당신의 입점 신청은 심사가 통과되지 않았습니다...',
        tip2:'原因:전문가가 당신이 제출한 작품에 대하여 종합적인 평가를 한 결과, 당신은 우리의 입주 요구에 부합되지 않습니다!',
        tip3:'더 양질의 대표작을 제출해 보십시오. 저희가 다시 종합적으로 평가하겠습니다~',
        tip4:'다시 보내기',
    },
    apply_pass: {
        tip1: '가입 신청이 통과되었어요. 축하합니다!',
        tip2: '정보가 완비되면 작품을 올리고 원고료도 벌수 있을거예요~',
        tip3: '투고에 서명하다.',
    },
    apply_passing: {
        tip1:'고객님의 입점 정보 심사중입니다...',
        tip2:'저희는 3일 근무일 내에 귀하의 입주정보에 대하여 심사를 진행하겠습니다. (국정공휴일시 시간을 순연합니다.)',
        tip3:'이 페이지에서 감사 결과를 볼 수 있습니다',
    },
    apply_common: {
        del:'이 항목 삭제',
        addimg:'그림 추가',
        addvideo:'동영상 추가',
        batch_pics:'일괄 그림을 통일적으로 정보를 써 넣는다',
        batch_videos:'일괄 동영상 정보 입력',
        search_subject:'주제명 검색',
        upload_success:'업로드에 성공했습니다. 확인을 기다립니다',
        upload_pic_eight:'한 번에 최대 8개의 사진을 업로드할 수 있습니다',
        upload_video_eight:'한 번에 최대 8개의 동영상을 올릴 수 있다',
        size_exceed:'크기는 50m을 초과할 수 없다',
        submit_success:'커밋에 성공했습니다.',
        agreement:'나는 이미 읽었고 동의했다',
        protocols:'「 영상칭다오내용허가사용권협의 」',
        read:'먼저 읽고동의하십시오"영상칭다오내용허가사용권협의"',

        selectAll: '모두 선택',
        off_pic: '다운로드를 클릭한 후, 사진은 웹 사이트에서 자동으로 삭제됩니다. 다운로드를 확인하시겠습니까?',
        del_pic: '다운로드 버튼을 클릭하면 사진이 웹 사이트에서 자동으로 삭제됩니다. 삭제 확인?',
        batch_off_pic: '대량으로 진열대를 내려가다.',
        batch_del_pic: '일괄 삭제',
        season: '그 이유:',
        edit: '편집',
        off: '다음 대',
        del: '삭제',
        off_video: '다운로드를 클릭한 후 동영상이 웹 사이트에서 자동으로 삭제됩니다. 다운로드를 확인하시겠습니까?',
        del_video: '삭제 클릭 후 동영상은 웹 사이트에서 삭제됩니다. 삭제 확인?',
        off_item: '삭제할 항목을 선택하십시오',
        had_off: '작품은 이미 진열에서 내렸다',
        del_item: '삭제할 항목을 선택하십시오',
        had_del: '작업이 삭제됨',
        del_tip: '삭제 후 복구할 수 없음, 삭제하시겠습니까?',
        edit_folder:'합집을 편집하다.',
        add_folder:'앨범 추가',
        enter_folder_title: '앨범 제목을 입력하세요',
        enter_keyword_tip:'키워드를 입력할 때마다 엔터 (enter) 버튼을 한 번 누른다',
        add_to_folder:'앨범에 추가하다',
        select_folder:'합집을 고르세요',
        author:'저자',
        enter_author:'작성자를 입력하십시오',
        create_date:'만든 날짜',

    },
    apply_work: {
        year_limit: '연도는 숫자만 가능합니다.',
        tip0:'사진 제공 원고',
        tip1:'죄송합니다. 그림 권한을 신청할 수 없습니다',
        tip2:'따뜻한 안내:만약 사진 투고가 필요하다면, 계약을 다시 신청하십시오',
        tip3:'재계약',
        tip4:'하나 이상의 그림을 선택하십시오. 다양한 그림 형식을 지원합니다',
        tip5:'그림 이름',
        tip6:'그림 이름을 입력해 주세요',
        tip7:'전문 그림 설명.',
        tip8:'그림 설명을 입력해 주세요',
        tip9:'주제별 키워드',
        tip10:'키워드를 입력하십시오',
        tip11:'특별 제목',
        tip12:'테마를 선택하십시오',
        tip13:'특별 제목을 추가하다',
        tip14:'작은 그림 상태',
        tip15:'스몰 그래프 가격',
        tip16:'작은 그림 가격을 입력해주세요',
        tip17:'원본 상태',
        tip18:'원도표 가격',
        tip19:'원도면 가격을 입력하십시오',
        tip20:'큰 그림 상태',
        tip21:'빅 플랜 가격',
        tip22:'큰 그림 가격을 입력해주세요',
        tip23:'사용 하기',
        tip24:'사용 안 함',
        tip25:'심사에 회부하다.',

        tip26:'동영상 제공 원고',
        tip27:'죄송합니다. 비디오 권한을 신청하지 않았습니다',
        tip28:'따뜻한 안내:만약 비디오 투고가 필요하다면, 계약을 다시 신청하십시오',
        tip29:'다중 비디오 형식을 지원하는 하나 이상의 비디오를 선택하십시오',
        tip30:'비디오 이름',
        tip31:'비디오 이름을 입력해 주세요',
        tip32:'비디오 설명',
        tip33:'동영상 설명을 입력해 주세요',
        tip34:'특별 제목',
        tip35:'주제명을 입력하십시오',
        tip36:'주제별 분류',
        tip37:'주제별 분류를 선택하세요',
        tip38:'특집 표지',
        tip39:'특별 테마 서술',
        tip40:'특정 주제에 대한 설명을 입력하십시오',
        tip41:'제출',
        tip42:'특집 표지를 올려주세요',

        more:'더 불러오기',

        price_pack: '프라이스 세트',
        select_price_pack: '가격세트를 선택하세요',
        pic_limit: '최대 8개의 이미지 업로드',
        video_limit: '최대 8개의 동영상을 올릴 수 있습니다',
        topic_price_pack: '표지 가격 패키지',
        year:'연도',
        yearTip:'년도를 입력하십시오',
        coverTip: '이것은 표지 사진일 뿐이니, 사진 원고를 다시 한 번 보내야 한다.',
        has_similar: '그림 작품을 중복 업로드하지 마십시오.',
        similar: '그림 반복',
    },
    no_settled: {
        tip1:'죄송하지만 입점을 성공하신 후에야 투고하실 수 있습니다~',
        tip2:'당신은 아직 공식 사진사로 인증되지 않았습니다',
        tip3:'사진사가 입주하다',
    },
    index: {
        hot: '인기 추천 사진',
        grapher:'우수 사진사',
        image: '사진',
        video: '동영상',
    },
    news: {
        news:'비주 얼',
        publish_time: '배포된 날짜:',
    },
    package: {
        name: '패키지 이름',
        content: '전속내용권익',
        expire: '기한',
        day: '일',
        buy: '세트세트를 사다',
        between: '권한 범위',
        min: '줍니다',
        original: '놔',
        max: '화폭',
        tip1: '1500달러/지출',
        tip2:'2200달러/지출',
        tip3: '깨끗이 지불하다',
        order_sn: '주문서 번호:',
        price:'주문금액:',
        open:'열어',
        scan: '스캔 결제',
        wechatPay: '위챗 페이',
        alipay: '알리페이 결제',
        weixin: '웨이 신',
        ali:'알리페이',
        select:'정식을 선택하세요.',
        tip4: '이미지',
        tip5: '단일 동영상',
        tip6: '비디오 패키지',
    },
    grapher: {
        title: '사진사',
        home:'첫 페이지',
        grapher:'우수 사진사',
        tip1:'(성씨 서열은 선후를 구분하지 않는다.)',
        tip2:'칭다오에서 촬영 계약을 체결했다',

    },
    search_pic:{
        tip1:'비슷한 사진',
        tip2:'언약을 찾다',
        tip3:'조목 기록',
    },
    topics:{
        tip1:'관련된 특별 보도',
        home:"첫 페이지",
    },
    pic:{
        tip1:'현재 위치:',
        tip2:'설명:',
        tip3:'로열티-프리 (royalty-free, 줄임말로"rf") 라이선스, 비특정 라이선스 컨텐츠 무제한, 영구적 사용, 파일 크기에 따른 가격, 다운로드 후 워터마크가 없음.자세한 내용은 다음과 같습니다:',
        tip4:'이미지 칭다오 내용 권한 부여 협의',
        tip5:'쇼핑 카트에 추가하기',
        tip6:'낱장으로 구매하다',
        tip7:'가격문의는 0532-82825531로 전화하시거나 문의하세요',
        tip8:'온라인 고객서비스',
        tip9:'그림 id:',
        tip10:'그림 제목:',
        tip11:'그림 사양:',
        tip12:'그림 크기:',
        tip13:'그림 종류:',
        tip14:'그림 크기:',
        tip15:'그림 가격:',
        tip16:'그림 년도:',
        tip17:'그림 설명:',
        tip18:'핵심 단어',
        tip19:'비슷한 사진 추천',

    },
    video: {
        text1: '비디오 id:',
        text2: '비디오 제목:',
        text3: '비디오 형식:',
        text4: '비디오 시간:',
        text5: '비디오 크기:',
        tip1: '핵심 단어',
        tip2: '유사 동영상 추천',
        desc: '소개',
    },
    vr: {
        text1: 'vr 전시장',
        text2: '자세 한 정보',
    },
    order_detail: {
        text1: '주문서 상세정보',
        text2: '서비스 설명',
        text3: '만약 청구서를 발급해야 할 경우, 고객 서비스 직원에게 연락하십시오:',
        text4: '주문서 번호:',
        text5: '주문금액:',
        text6: '지불금액:',
        text7: '지불 상태:',
        text8: '지불을 기다리다',
        text9: '지불완료',
        text10: '지불방식:',
        text11: '세트 패키지',
        text12: '위챗 페이',
        text13: '알리페이 결제',
        text14: '잔고',
        text15: '지불시간:',
        text16: '주문주문시간:',
        text17: '그림 정보',
        text18: '미지급합계:',
        text19: '그림 미리 보기',
        text20: '그림 이름',
        text21: '그림 규격',
        text22: '그림 가격',
        text23: '지불 방식을 선택하다',
        text24: '패키지로 지불하다',
        text25: '실지지불금액:',
        text26: '주문서를 제출하다',

        text27: '계좌잔고지급',
        // text28: '账户可用余额 <span style="color:rgb(230, 0, 18);font-weight:bold;">0.00</span>元。如果您已在【价格套餐】页面购买了套餐包，请优先选择套餐包支付。',
        text29: '위챗 페이',
        text30: '위챗 간편결제 지원',
        text31: '알리페이 결제',
        text32: '알리페이 간편결제 지원',

        text33: '계정사용가능잔고',
        text34: '「 가격패키지 」 페이지에서 이미 패키지상품을 구매하셨다면, 우선 패키지상품을 선택하여 결제하시기 바랍니다.',
        text35: '지불이 완료되다',
        text36: '지불 실패',
        origin_1:'원본 그림:',
        small_1:'작은 그림:',
        big_1:'큰 그림:',
        origin:'놔',
        small:'줍니다',
        big:'화폭',
        maohao:':',
    },
    order_pay: {
        text1: '주문서 정보',
        text2: '선불 주문서',
        tip1: '그림 가격 선택해주세요',
        tip2: '단 한 가지만 고를 수 있다',
    },
    order_list: {
        text0: '주문서 리스트',
        text1: '그림 이름:',
        text2: '만든 시간:',
        text3: '검색',
        text4: '주문 id:',
        text5: '주문서 번호:',
        text6: '주문주문시간:',
        text7: '해상도:',
        text8: '주문서 상세정보',
        text9: '계약서를 다운로드하다',
        text10: '가격:',
        text11:'시작 시간',
        text12:'종료 시간',
        text13:'~',
        wait_pay:'지불을 기다리다',
        pay_success:'성공적으로 지불되었습니다.',
        paid:'지불완료',
        package:'세트 패키지',
        wechatPay:'위챗 페이',
        alipay:'알리페이 결제',
        balance:'잔고',
    },
    setting: {
        text1:'비밀번호 변경',
        text2:'사용자 정보',
        text3:'핸드폰 번호',

    },
    auth: {
        text1: '인증 목록',
        text2: '그림 이름:',
        text3: '인증 시간:',
        text4: '그림 미리 보기',
        text5: '그림 제목',
        text6: '그림 규격',
        text7: '그림 해상도',
        text8: '조작',
        text9: '다운로드',


    },
    wallet: {
        text1: '내 지갑',
        text2: '사용가능잔고',
        text3: '잔액 기록',
        text4: '유형',
        text5: '금액',
        text6: '시간',
        text7: '잔액을 기록하지 않다',
    },
    cart: {
        text1: '쇼핑 카트',
        text2: '(도합',
        text3: '하나의 상품)',
        text4: '조작',
        text5: '그림 미리 보기',
        text6: '그림 제목',
        text7: '그림 규격',
        text8: '가격',
        text9: '삭제',
        text10: '나는 이미 읽었고 동의했다',
        text11: '「 영상칭다오수권협의 」',
        text12: '「 영상칭다오프라이버시성명 」',
        text13: '선택한 상품',
        text14: '총계',
        text15: '결제',
        text16: '건',
        clearCart:'장바구니를 비우다',
        batchDel:'일괄 삭제',

    },
    activity: {
        actName: '행사에 참가하다',
        selectAct: '공모전 선택하세요',
        selectActName: '활성 이름 검색',
        category: '분류',
        select_cate:'분류 선택',
    },
    active: {
        upload: '작품을 올리다',
        pic:'사진',
        video: '동영상',
        recommend: '추천',
        tip7:'그림 설명',
        tip9:'핵심 단어',
        folder_title: '제목',
        enter_title: '제목을 입력하십시오.',
        folder_desc: '설명',
        enter_desc: '모음집 설명',
        cover_type: '표지 종류',
        folder_cover: '표지',
        cover_type_1:'최신 작품',
        cover_type_1_tip:'앨범의 최신 앨범은 자동으로 앨범 표지로 나온다',
        cover_type_2:'표지를 고정하다',
        cover_type_2_tip:'표지로 로컬 업로드 그림',
        operate_success: '작업 성공',

    },
    status: {
        all:'전부',
        reviewing: '심사 중',
        reviewed: '심사를 통과하다',
        not_review: '심사가 통과되지 못하다',
    }


}