<template>
	<PageLoading :is-loading="isLoading"></PageLoading>
	<SideComponent @scrollto="scroll"></SideComponent>
	<div class="container" ref="viewBox">
		<div class="header-container">
			<Header2 />			
			<div class="search-swiper-container">
			</div>
		</div>
		<div class="content">
			<div class="index-pic">				
				<!-- <div class="pic-wrapper">
					<search-bar-pic @refresh="getData"></search-bar-pic>
				</div> -->
			</div>
			<div class="pay-container">
				<div class="title">
					<span>{{$t('order_detail.text1')}}</span>
				</div>
				<div class="plain">
					<span>{{$t('order_detail.text2')}}</span><span>{{$t('order_detail.text3')}}0532-8282 5531</span>
				</div>
				<div class="order-container">
					<!-- <div class="caption"><span>预支付订单</span></div> -->
					<div class="order-table">
						<div class="order-table-item">
							<div class="order-item-title">{{$t('order_detail.text4')}}</div>
							<div class="order-item-value">{{orderInfo.order_sn}}</div>
						</div>
						<div class="order-table-item">
							<div class="order-item-title">{{$t('order_detail.text5')}}</div>
							<div class="order-item-value">￥{{orderInfo.total}}</div>
						</div>
						<div class="order-table-item">
							<div class="order-item-title">{{$t('order_detail.text6')}}</div>
							<div class="order-item-value">￥{{orderInfo.pay_price}}</div>
						</div>
						<div class="order-table-item">
							<div class="order-item-title">{{$t('order_detail.text7')}}</div>
							<div class="order-item-value">{{orderInfo.status==0 ? $t('order_list.wait_pay') : (orderInfo.status==1 ? $t('order_list.paid') : $t('order_detail.text8'))}}</div>
						</div>
						<div class="order-table-item" v-if="orderInfo.status!=0">
							<div class="order-item-title">{{$t('order_detail.text10')}}</div>
							<div class="order-item-value">{{orderInfo.paytype==1?$t('order_list.package'):(orderInfo.paytype==2?$t('order_list.wechatPay'):(orderInfo.paytype==3?$t('order_list.alipay'):(orderInfo.paytype==0?'':'')))}}</div>
						</div>
						<div class="order-table-item">
							<div class="order-item-title">{{$t('order_detail.text15')}}</div>
							<div class="order-item-value">{{orderInfo.pay_time?formatDate(orderInfo.pay_time):''}}</div>
						</div>
						<div class="order-table-item">
							<div class="order-item-title">{{$t('order_detail.text16')}}</div>
							<div class="order-item-value">{{orderInfo.created_at}}</div>
						</div>
						
					</div>
					<div class="order-table">
						<div class="table-caption">							
							<div>{{$t('order_detail.text17')}}（{{imageCount}}）</div>
							<div>{{$t('order_detail.text18')}}<span class="order-amount">￥{{totalAmount}}</span></div>
						</div>
						<div class="table-header">
							<div></div>
							<div>{{$t('order_detail.text19')}}</div>
							<div>{{$t('order_detail.text20')}}</div>
							<div>{{$t('order_detail.text21')}}</div>
							<div style="width:30%;">{{$t('order_detail.text22')}}</div>
							<!-- <div>订单金额</div> -->
						</div>
						<div class="table-body" v-for="(item, i) in orderInfo.order_items">
							<div></div>
							<div class="table-body-item">
								<img class="img" :src="item.image.preview" />
							</div>
							<div class="table-body-item">{{item.image.title}}</div>
							<div class="table-body-item">{{item.image.norms}}</div>
							<div class="table-body-item" style="display:flex;flex-direction: column;justify-content:flex-start;align-items: flex-start;width:30%;">
								<div style="width:100%;">
									<!-- <input type="radio" :value="value.id" :checked="value.isChecked" @change="changeInput(i, index)"/>  --><!-- v-model="value.isChecked" -->
									<div style="width:100%;">{{item.option==0?$t('order_detail.origin_1'):(item.option==1?$t('order_detail.small_1'):(item.option==2?$t('order_detail.big_1'):''))}}<span style="color:rgb(255,149,0);">￥{{item.image_price}}</span>（<span v-if="item.option==0">{{item.image.ratio_w}} x {{item.image.ratio_h}} px</span><span v-if="item.option==1">{{item.image.min_w}} x {{item.image.min_h}} px</span><span v-if="item.option==2">{{item.image.max_w}} x {{item.image.max_h}} px</span>）</div>
								</div>
								
							</div>
							<!-- <div class="table-body-item" style="color:rgb(255,149,2);">{{totalAmount}}元</div> -->
							
						</div>
						
					</div>
				</div>
				<div class="paytype-container" v-if="orderInfo.status==0">
					<div class="caption"><span>{{$t('order_detail.text23')}}</span></div>
					<div class="paytype-table">
						<div class="pay-type"				     
						     v-for="item in paytype" 
						     :key="item.id" 
						     @click="selectPayType(item)"
						    >
						    <div class="pay-type-outer" :class="[selectedPaytype.id==item.id?'selected':'']" >
								<div class="pay-item">
									<i class="iconfont icon-style" :class="item.iconClass"></i>
								</div>
								<div class="pay-item">
									<span class="pay-item-name">{{item.name}}</span>
									<span class="pay-item-html" v-html="item.text"></span>
									<div class="pay-item-package" v-if="parseInt(item.id)==1 && Object.keys(packages).length>0">
										<div class="radio-outer">{{$t('order_detail.text24')}}</div>
										<div class="radio-outer" v-for="value in packages">
											<span style="padding:5px 5px;">
												<input type="radio" :value="value.package_id" v-model="checkedPackageId" />
											</span>
											<span style="padding:5px 5px;">{{value.title}}</span>
										</div>
										
									</div>
								</div>
								<!-- <div class="pay-item">
									<span style="color:rgb(255, 149, 2);display:none;">{{totalAmount}}元</span>
								</div> -->
								
							</div>
						</div>
						
	
					</div>
				</div>
			</div>
			
		</div>
		<div class="next" v-if="orderInfo.status==0">
			<div class="first">
				<div>
					<i class="iconfont pay-name" :class="selectedPaytype.iconClass"></i>{{selectedPaytype.name}}<span style="padding:10px 20px;"></span>{{$t('order_detail.text25')}}<span class="price">￥{{totalAmount}}</span>
				</div>			
				<div></div>
				<div class="nextpay" ref="topay" @click="topay(orderInfo)" :disabled="disabled">{{$t('order_detail.text26')}}</div>
			</div>
		</div>
		<div style="height:50px;width:100%;"></div>
		<Footer/>
	</div>
</template>

<script>
	import order_detail from './order_detail_controller';
	export default order_detail;
</script>

<style src="@/assets/css/my/order/order_detail.css" scoped> </style>