export default {
    login: {
        login: 'サインイン',
        userName: '利用者ID',
        password: '暗号化',
        name: 'ユーザー名またはユニット名',
    },
    captcha: 'CAPTCHA',
    forgetPassword: 'パスワードをお忘れですか？',
    loginTip: '現在のログイン結果はランダムです。 認証コードはランダムです。',
    editpassword: 'パスワードを変更する',
    errMsg: {
        inputRequired: '入力してください{cont}',
        selectRequired: '入力お願いします{cont}',
    },
    uernameTips: '個人ユーザーはユーザー名、単位ユーザーは単位名を記入してください',

    title: '映像青島です',
    logo_small_text:'青島国際プレスセンター運営です',
    hello: 'どうもです。',
    loginOrRegister: 'ログインします',
    anonymity: '匿名のユーザーです',
    personCenter: '個人センターです',
    logout:'ログアウトします',
    toLogin: '登録をお願いします',
    mobileTip: '携帯番号',
    submitText: '提出します',
    accountTip: 'ユーザー名/携帯電話番号です',
    password: 'パスワードです',
    newPassword: '新しいパスワード',
    confirmPassword: '新しいパスワードの確認',
    noAccount: '口座番号はまだですか?',
    hasAccount: '口座番号はありますか?',
    register: '登録します',
    phoneNumber: '携帯電話番号です',
    email: 'メールボックスです',
    organizationName: '単位の名前です',
    loginNow:'すぐにログインします',
    accountLogin: 'アカウント登録です',
    loginSuccess: 'ログインできました',
    loginFailed: '上陸失敗です',
    registerSuccess: '登録できました',
    registerFailed: '登録に失敗しました',
    enterUsername: 'ユーザー名の入力をお願いします',
    enterPhoneNumber: '携帯番号の入力をお願いします。',
    enterRealname: '実名をお願いします。',
    enterCorrectUnitName: '正しい単位名をお願いします。',
    enterCorrectIdcard: '正しい住民番号をお願いします。',
    enterCorrectInstitutionName: '正しい施設番号をお願いします。',
    enterCorrectPhoneNumber: '正しい番号の入力をお願いします',
    enterCorrectEmail: '正しいメールアドレスの入力をお願いします。',
    enterCorrectUnitName: '単位名は漢字またはアルファベットまたは数字の組み合わせとすることができます。',
    enterCorrectUsernameAndPhone: 'ユーザー名/携帯番号の入力をお願いします。',
    nameContain: 'ユーザー名にはアルファベットか数字が必要です',
    passwordContain: 'パスワードには、少なくとも8桁の数字、文字、特殊記号(！#$%^&*？-)の組み合わせが必要です',
    passwordContain2: '密码需要至少8位数字、字母、特殊符号!@#$%^&*?-_组合',
    enterPassword: 'パスワードをお願いします',
    passwordLength: 'パスワード長は少なくとも8ビット',
    qrlogin: 'コード登録をします',
    updateSuccess: '更新成功です',
    retrievePassword: 'パスワードを取り戻します',
    pageUpdated: 'ページデータの更新完了しました。',

    cart1: 'ショッピングカートです',
    onlineService: 'オンライン顧客サービスです',
    newsCenter: '青島国際プレスセンター運営です',
    qdnews: '青島国際プレスセンターです',
    hostUnit: '主催します:',
    contactPhone: '電話を連絡します。',
    workTime: '労働時間です',
    footerText1: '大容量のハイビジョン素材です',
    footerText2: '著作権があります',
    aboutus: '私たちについてです',
    copyrightNotice: '著作権宣言です',
    questions: 'よくある質問です',
    terms: 'ウェブサイト規約です',
    selfRegulatory: 'ウェブサイトのプラットフォームに関する自律条約です',
    contractUs: '連絡してきます',
    friendLink:'友情のリンクです',
    followUs: '私たちに注目しています',
    copyright:'映像青島版権所有です',
    ICP: '魯ICP備2023012351番-1です',
    category:'分類します',
    pv:'総閲覧数です',
    uv:'総登録者数です。',
    qrcode:'スキャンログイン',
    loadmore: '追加のロード',
    theEnd: '終了',

    codeLogin:'認証コードログイン',
    getCode:'認証コードの取得',
    enterCode:'認証コードを入力してください',
    codetip:'認証コード',
    viewmore:'詳細を表示',

    menus: {
        home: 'トップページです',
        news: 'ビデオメッセージです',
        pic: '画像です',
        video: 'ビデオです',
        vr: 'デジタルミュージアム',
        grapher: 'カメラマンです',
        active: 'イベントです',
        more: 'より多くのサービスがあります',
        package: '価格セットです',
        subject: '特集',
    },
    contract:{
        tip1:'契約資料を追加します',
        tip2:'契約調印です',
        tip3:'契約書のダウンロードです',
        tip4:'契約の有効性と後続の保障を保証するために、真実の箇人資料を記入してください、後の購入は自働的に今回記入した資料を使用します。',
        tip5:'註文IDです',
        tip6:'オーダーIDの入力をお願いします。',
        tip7:'データタイプです',
        tip8:'個人ユーザーです',
        tip9:'企業機構です',
        tip10:'実名です',
        tip11:'実名をお願いします。',
        tip12:'住民登録番号です',
        tip13:'住民番号の入力をお願いします。',
        tip14:'組織機構です',
        tip15:'組織は力を入れています。',
        tip16:'機関番号です',
        tip17:'機関番号をお願いします。',
        tip18:'メールボックスです',
        tip19:'メールをお願いします。',
        tip20:'契約書のテンプレートを作成します',
        tip21:'下の赤い枠の部分にマウスの左ボタンを押しながら署名を手書きでお願いします。',
        tip22:'署名を生成します',
        tip23:'パネルを空にします',
        tip24:'ダウンロード契約書です',
        tip25:'ユーザーのタイプをお願いします',
        tip26:'実名をお願いします。',
        tip27:'住民番号の入力をお願いします。',
        tip28:'機関名の入力をお願いします',
        tip29:'機関番号をお願いします。',
        tip30:'メールをお願いします。',
        tip31:'契約資料の保存に失敗しました',
        tip32:'契約資料の入手に失敗しました',
        tip33:'アップロード失敗しました',

    },
    recharge:{
        tip1:'収益の概要です',
        tip2:'収入の概要です',
        tip3:'未決済金額です。',
        tip4:'決済済み金額(元)です。',
        tip5:'決済を申請します',
        tip6:'註文明細です',
        tip7:'作品名です',
        tip8:'支払い時間です',
        tip9:'ご註文金額です',
        tip10:'収益を分けます',
        tip11:'ご註文番号です。',
        tip12:'決済記録です',
        tip13:'決済申し込み期間です',
        tip14:'決済の進捗状況です',
        tip15:'入金時間です',
        tip16:'決済金額です',
        tip17:'決済待ちです',
        tip18:'決済中です',
        tip19:'決済済みです',
        
    },

    search: {
        search: '検索します',
        hotSearch: '人気検索です',
        takePic: '画像検索です',
        dragPic: 'ドラッグ&ドロップの画像はこの領域のどこにでもあります',
        or: 'ですか',
        clickUpload: 'アップロードをクリックします',
        sizeLimit: '50mb以下のjpg/pngファイルを使用します。',
        searchPic: '画像検索です',
        searchVideo: '動画検索です',
        pic: '画像です',
        video: 'ビデオです',
        trySearch:'キーワード検索をしてみるのもいいでしょう。',
        scenery: '風景です',
        building: '建築です',
        culture: '文化です',
        humanities: '人文学です',
        educate: '教育です',
        isJPG: '画像をアップロードするのはJPG、PNGのみです!',
    },
    sideMenu: {
        apply: 'カメラマンが原稿を提供します',
        account: '口座管理です',
        wallet: '私の財布です',
        cart: 'ショッピングカートです',
        authRecords: 'ライセンス記録です',
        orderlist:'註文リストです',
        collectManage: 'イベント募集',
        collect: 'イベント作品',
        folder: 'アクティブコンピレーション',
        personCenter: 'クリエイターセンターです',
        applyInfo: '入店情報です',
        workManage: '私の作品',
        recharge: '収益センターです',
    },
    person: {
        personCenter: 'クリエイターセンターです',

    },
    application: {
        settled: 'カメラマンが入ります',
        process: '入居の流れです',
        plain: 'ご入居説明です',
        apply: '申請を提出します',
        result: '結果を提出します',
        tipText1: '映像青島創作カメラマンの申し込みを歓迎します。入居の成功率を上げるために、入居情報を正しく記入してください。',
        tipText2: '3営業日以内にお申し込みの返事をいたしますので、こちらで入居の進捗状況をご覧ください。',
        apply_enter: '入居申請をします',
        base_info: '基本情報をお願いします。',
    },
    form: {
        user_type: 'ユーザータイプ',
        license: '営業許可',
        licenseTip: '捺印された電子版の単位事業免許証（または捺印された電子版の組織コード証明書）',
        type1: '関係者',
        type2: 'ユニット編成',
        unitName:'ユニット名',
        unitTip:'ユニット名を入力してください。',
        realname: '実名です',
        enterRealname: '実名をお願いします。',
        gender: '性別です',
        male: '男です',
        female: '女です',
        photo: '個人写真です',
        enterPhoto: 'プロフィール写真のアップをお願いします',
        uploadPhoto: '写真をアップロードします',
        biography: '簡単',
        enterBiography: 'プロフィールをご記入ください',
        mobile: '携帯電話番号です',
        enterPhone: '携帯番号の入力をお願いします。',
        email: 'eメールです',
        enterEmail: 'メールをお願いします。',
        company: '組織の名前',
        enterCompany: '組織名を入力してください',
        enterFeedType: '原稿の種類をお願いします。',
        ctype: '職業タイプです。',
        media: 'メディアです',
        non_media: '非メディアです',
        worktype: '原稿提供タイプです',
        image: '画像です',
        video: 'ビデオです',
        workPic: '写真は作品を表します。',
        workPicTip: '(代表作品は入居審査の参考にします。良質なオリジナル代表作品をアップロードすることで、入居成功率を高めることができます。)',
        uploadPic: '画像をアップロードします',
        againUploadPic: '画像をアップロードし続けます',
        againUploadVideo: '動画をアップロードし続けます',
        limit_pic_quantity: '(オリジナル画像を10枚お願いします)',
        workVideo: 'ビデオの代表作です',
        uploadVideo: '動画をアップロードします',
        limit_video_quantity: '(オリジナルビデオを3本お願いします)',
        submit_apply: '申請を提出します',
        tip_text_1: 'あなたの入居情報審査中です...',
        tip_text_2: '3営業日以内に入店情報をチェックします(祝日の場合は順延)',
        tip_text_3: 'このページで審査結果を見ることができます!',
        uploadPicWork: '画像作品のアップロードをお願いします。',
        uploadVideoWork: '動画のアップロードをお願いします',
        enter_limit_pic: '画像10枚アップロードお願いします。',
        enter_limit_video: '動画を3本お願いします',
        submited: '申請は提出しました',
        only_ten_pic: '画像は10枚しかアップできません',
        only_three_video: '動画は3本しかアップできません',
        idcard: '住民登録番号です',
        idcard_photo: '身分証の写真です',
        enter_idcard: '住民番号の入力をお願いします。',
        idcard_tip: '（身分証明書の顔写真をアップロードしてください）',
        bankcard: 'カード番号です',
        enter_bankcard: 'カード番号をお願いします。',
        bankcard_photo:'キャッシュカードの写真です',
        bankcard_tip:'(カード番号付きの写真をアップロードします)',
        preview:'契約書の提出とプレビューを行います。',
        enterCorrectbankcardNumber: '正しいカード番号をお願いします。',
        enterIdcardPhoto: '身分証の正面をアップロード願います',
        enterBankcardPhoto: 'カードの表をお願いします',
    },
    apply_contract:{
        info: '契約情報です',
        sign: '契約書に署名します。',
        tip1: '双方の合法的な権利を保証するために、身分情報と銀行カードの情報を入力して電子契約を生成してください。',
        tip2: '*以下の情報は契約締結と収益決済のためのものであり、契約締結後に変更することはできません。',
        tip3: '双方の合法的な権利を保証するため、契約内容をよくお読みください。',
        tip4: '契約情報をお願いします。',
        tip5: '契約書のプレビューです',
        tip6: '下の赤い枠の部分にマウスの左ボタンを押しながら署名を手書きでお願いします。',
        tip7: '署名を生成します',
        tip8: 'パネルを空にします',
        signSuccess:'署名に成功しました',
        noMatch:'アイデンティティ情報が一致しません',
        submit_success:'コミット成功',
        idcard_face:'身分証明書の正面写真をアップロード（または再アップロード）してください',
        upload_fail:'アップロードに失敗しました',

    },
    apply_info: {
        tip1:'映像青島へようこそ、あなたの作品をアップロードすることができます、審査を通過した後に収益を得るチャンスがありますよ~',
        tip2:'入店情報です',
        tip3:'契約書をクリックします',
        tip4: '電子契約書です',
        
    },

    apply_nopass: {
        tip1:'あなたの入居申請は不合格でした...',
        tip2:'原因:ご提出頂いた作品を専門家が総合的に評価した結果,当方の要求には合致しませんでした!',
        tip3:'より質の高い代表作を提出していただくことができますので、改めて総合評価を行います~',
        tip4:'再提出です',
    },
    apply_pass: {
        tip1: 'おめでとうございます,入居申し込みが通りました',
        tip2: '情報を充実させれば、作品をアップして原稿料を稼ぐことができます。',
        tip3: '投稿契約をします',
    },
    apply_passing: {
        tip1:'あなたの入居情報は審査中です…',
        tip2:'3営業日以内に入店情報をチェックします(祝日の場合は順延)',
        tip3:'このページで審査結果を見ることができます',
    },
    apply_common: {
        del:'削除します',
        addimg:'画像を添付します。',
        addvideo:'ビデオを追加します',
        batch_pics:'ロット画像統一情報記入します。',
        batch_videos:'一括記入します。',
        search_subject:'トピック名検索です',
        upload_success:'アップロードできました,審査待ちです',
        upload_pic_eight:'一度に投稿できる画像は8枚までです',
        upload_video_eight:'一度に最大8本の動画がアップされます',
        size_exceed:'大きさは50Mを超えません',
        submit_success:'提出成功です',
        agreement:'読んで同意しました',
        protocols:'『映像青島コンテンツライセンシング協定』です',
        read:'先に「映像青島コンテンツライセンシングプロトコル」を読んで同意をお願いします。',

        selectAll: '全選です',
        off_pic: 'をクリックした後に自働的に削除して、サイトから画像を削除しますか?',
        del_pic: 'をクリックした後に自働的に削除して、サイトから画像を削除しますか?確定',
        batch_off_pic: '大量撤去です',
        batch_del_pic: '一括削除です',
        season: '理由はこうです',
        edit: '編集します',
        off: '撤去します',
        del: '削除します',
        off_video: '働画をクリックした後に自働的に削除して、サイトから削除を確定しますか?',
        del_video: 'をクリックして削除します後にサイトから働画削除を確定しますか?',
        off_item: '撤去する選択肢を選んでいただきます',
        had_off: '作品は撤去されました',
        del_item: '削除する項目を選択します。',
        had_del: '作品は削除しました',
        del_tip: '削除をクリックした後に回復することはできません、削除を確定しますか?',
        edit_folder:'コンピレーション・アルバムを編集する',
        add_folder:'コンピレーションを追加します。',
        enter_folder_title: 'コンピレーション・タイトルの入力をお願いします。',
        enter_keyword_tip:'キーワードを入力するたびにエンターキーを押します',
        add_to_folder:'コンピレーションに参加します。',
        select_folder:'コンピレーションアルバムをお願いします。',
        author:'作者です',
        enter_author:'作者を入力します。',
        create_date:'製作日です',

    },
    apply_work: {
        year_limit: '年は数値のみ',
        tip0:'写真提供稿です',
        tip1:'申し訳ありませんが,画像権限の申請は',
        tip2:'ヒント:画像投稿が必要な場合は、再契約をお願いします。',
        tip3:'再契約です',
        tip4:'1枚または複数の画像を選択してください。複数の画像フォーマットに対応します。',
        tip5:'画像の名前です',
        tip6:'画像名の入力をお願いします。',
        tip7:'特集写真の説明です',
        tip8:'画像説明をお願いします。',
        tip9:'キーワードキーワードです',
        tip10:'キーワード入力をお願いします。',
        tip11:'トピック名です',
        tip12:'特集をお願いします',
        tip13:'テーマを追加します',
        tip14:'小図の状態です',
        tip15:'小図価格です',
        tip16:'小図価格の入力をお願いします。',
        tip17:'原図の状態です',
        tip18:'原図価格です',
        tip19:'原図価格を入力願います。',
        tip20:'大図状態です',
        tip21:'大図価格です',
        tip22:'大きな図の価格入力をお願いします。',
        tip23:'起用します',
        tip24:'無効です',
        tip25:'審査に提出します',

        tip26:'ビデオ原稿提供です',
        tip27:'申し訳ありませんが,動画権限の申請は',
        tip28:'ヒント:動画投稿の場合は、契約のやり直しをお願いします。',
        tip29:'1つ以上のビデオを選択してください。複数のビデオフォーマットに対応します。',
        tip30:'ビデオタイトルです',
        tip31:'ビデオのタイトルをお願いします',
        tip32:'ビデオ説明です',
        tip33:'ビデオの説明をお願いします',
        tip34:'トピック名です',
        tip35:'テーマ名の入力をお願いします',
        tip36:'テーマ別に分けます',
        tip37:'テーマ別をお願いします。',
        tip38:'特集の表紙です',
        tip39:'テーマの記述です',
        tip40:'トピックの記述をお願いします。',
        tip41:'提出します',
        tip42:'特集の表紙をお願いします',

        more:'もっとロードします',

        price_pack: 'セット価格です',
        select_price_pack: 'プライスパックをお願いします。',
        pic_limit: '最大8枚まで画像をアップします',
        video_limit: '最大8本の動画をアップします',
        topic_price_pack: '特集カバー価格セットです',
        year:'年です',
        yearTip:'年をお願いします。',
        coverTip: 'これは表紙の画像だけで、画像の原稿をもう一度転送する必要があります',
        has_similar: '画像作品の重複アップロードはご遠慮ください',
        similar: '画像の繰り返し',
    },
    no_settled: {
        tip1:'すみません,入居してから投稿しますよ~',
        tip2:'まだ公式カメラマンとして認定されていません',
        tip3:'カメラマンが入ります',
    },
    index: {
        hot: '人気のおすすめ画像です',
        grapher:'優れた写真家です',
        image: '画像です',
        video: 'ビデオです',
    },
    news: {
        news:'ビデオメッセージです',
        publish_time: '発表時期です。',
    },
    package: {
        name: 'コース名です',
        content: '排他的コンテンツ権利です',
        expire: '期限です',
        day: '天です',
        buy: 'セットを購入します。',
        between: '権限の範囲です',
        min: '小さな図です',
        original: '原図です',
        max: '大きな図です',
        tip1: '1500元/本です',
        tip2:'2200元/本です',
        tip3: '払い払いをする',
        order_sn: 'ご註文番号です。',
        price:'ご註文金額です。',
        open:'開けます',
        scan: 'コード決済です',
        wechatPay: '微信ペイです',
        alipay: 'アリペイ払いです',
        weixin: 'ウィーチャットです',
        ali:'アリペイです',
        select:'コースをお願いします。',
        tip4: '画像',
        tip5: '1 つのビデオ',
        tip6: 'ビデオパッケージ',
    },
    grapher: {
        title: 'カメラマンです',
        home:'トップページです',
        grapher:'優れた写真家です',
        tip1:'(この順位は姓の順位を問わないものです)',
        tip2:'映像青島契約カメラマンです',

    },
    search_pic:{
        tip1:'似たような写真です',
        tip2:'約を見つけます',
        tip3:'記録します',
    },
    topics:{
        tip1:'関連特集です',
        home:"トップページです",
    },
    pic:{
        tip1:'現在位置です:',
        tip2:'説明します:',
        tip3:'ロイヤリティフリー(royalty-free、略称「RF」)ライセンス、不特定使用範囲ライセンスの内容は何度でも永久的に使用することができ、ファイルサイズに応じて価格が設定されており、ダウンロード後の透かしがありません。詳しくはこちらを参考にしてみます。',
        tip4:'映像青島コンテンツライセンス契約です',
        tip5:'カートを入れます',
        tip6:'一枚ずつ購入します',
        tip7:'引合いご購入の場合は0532-82825531までお問い合わせください。',
        tip8:'オンライン顧客サービスです',
        tip9:'画像IDです。',
        tip10:'画像タイトルです。',
        tip11:'画像の仕様です。',
        tip12:'画像サイズです。',
        tip13:'画像タイプ:です。',
        tip14:'サイズ:写真です。',
        tip15:'画像価格はこちらです。',
        tip16:'写真の年です:',
        tip17:'写真説明:そうですか。',
        tip18:'キーワードです',
        tip19:'似たような画像おすすめです',

    },
    video: {
        text1: 'ビデオIDです',
        text2: 'ビデオのタイトルです',
        text3: 'ビデオタイプ:です',
        text4: 'ビデオの長さです',
        text5: 'ビデオサイズです。',
        tip1: 'キーワードです',
        tip2: '似たような動画の推薦です',
        desc: '概要',
    },
    vr: {
        text1: 'VRホールです',
        text2: '詳細です',
    },
    order_detail: {
        text1: 'ご註文の詳細です',
        text2: 'サービス説明です',
        text3: '領収書の発行が必要な場合は、お客様サービスまでご連絡ください。',
        text4: 'ご註文番号です。',
        text5: 'ご註文金額です。',
        text6: '支払い金額です。',
        text7: '支払い状況です。',
        text8: '支払い待ちです',
        text9: '支払い済みです',
        text10: '支払い方法です。',
        text11: 'セットパックです',
        text12: '微信ペイです',
        text13: 'アリペイ払いです',
        text14: '残額です',
        text15: '支払い時間です。',
        text16: '註文時間はこちらです。',
        text17: '画像情報です',
        text18: '対応合計です:',
        text19: 'プレビュー画像です',
        text20: '画像の名前です',
        text21: '画像仕様です',
        text22: '画像価格です。',
        text23: '支払い方法を選択します',
        text24: 'パッケージ支払いを選択します。',
        text25: '実払金額です。',
        text26: '注文書を提出します。',

        text27: '口座残高支払いです',
        // text28: '账户可用余额 <span style="color:rgb(230, 0, 18);font-weight:bold;">0.00</span>元。如果您已在【价格套餐】页面购买了套餐包，请优先选择套餐包支付。',
        text29: '微信ペイです',
        text30: 'wechatの迅速決済に対応しています。',
        text31: 'アリペイ払いです',
        text32: 'alipayの迅速な支払いをサポートします。',

        text33: '口座利用可能残高です',
        text34: '【価格プラン】でパッケージを購入済みの場合は、優先的にパッケージを選択してお支払いください。',
        text35: '支払い完了です',
        text36: '支払いに失敗します',
        origin_1:'原図です:',
        small_1:'小さい図です',
        big_1:'大きな図です',
        origin:'原図です',
        small:'小さな図です',
        big:'大きな図です',
        maohao:':です。',
    },
    order_pay: {
        text1: '註文情報です',
        text2: '前払い註文です',
        tip1: '画像価格をお願いします。',
        tip2: '1つしか選べません',
    },
    order_list: {
        text0: '註文リストです',
        text1: '画像名称:そうです。',
        text2: '作成時間です:',
        text3: '検索します',
        text4: 'ご註文IDです',
        text5: 'ご註文番号です。',
        text6: '註文時間はこちらです。',
        text7: '解像度です',
        text8: 'ご註文の詳細です',
        text9: 'ダウンロード契約書です',
        text10: '価格です:',
        text11:'開始時間です',
        text12:'終了時間です',
        text13:'至ります',
        wait_pay:'支払い待ちです',
        pay_success:'支払い成功です',
        paid:'支払い済みです',
        package:'セットパックです',
        wechatPay:'微信ペイです',
        alipay:'アリペイ払いです',
        balance:'残額です',
    },
    setting: {
        text1:'パスワードを変更します',
        text2:'ユーザー情報です',
        text3:'携帯番号',

    },
    auth: {
        text1: 'ライセンスリストです',
        text2: '画像名称:そうです。',
        text3: 'ライセンス期間です:',
        text4: 'プレビュー画像です',
        text5: '画像タイトルです。',
        text6: '画像仕様です',
        text7: '画像解像度です',
        text8: '操作します',
        text9: 'ダウンロードします',


    },
    wallet: {
        text1: '私の財布です',
        text2: '利用可能残高です',
        text3: '残額記録です',
        text4: 'タイプです',
        text5: '金額です',
        text6: '時間です',
        text7: '残高記録はまだありません。',
    },
    cart: {
        text1: 'ショッピングカートです',
        text2: '共です',
        text3: '商品です',
        text4: '操作します',
        text5: 'プレビュー画像です',
        text6: '画像タイトルです。',
        text7: '画像仕様です',
        text8: '値段です',
        text9: '削除します',
        text10: '読んで同意しました',
        text11: '『映像青島ライセンス契約です』',
        text12: '『映像青島プライバシー声明です』',
        text13: '選びました商品',
        text14: '総計です',
        text15: '決済します',
        text16: 'はい',
        clearCart:'ショッピングカートを空にします',
        batchDel:'一括削除です',

    },
    activity: {
        actName: '行事に参加する',
        selectAct: '募集をお願いします',
        selectActName: 'キャンペーン名検索です',
        category: '分類します',
        select_cate:'分類を選びます',
    },
    active: {
        upload: '作品をアップロードします',
        pic:'画像です',
        video: 'ビデオです',
        recommend: '推薦します',
        tip7:'画像説明です。',
        tip9:'キーワードです',
        folder_title: '見出しです',
        enter_title: 'タイトル入力をお願いします。',
        folder_desc: '記述します',
        enter_desc: 'コンピレーションアルバムの記述です',
        cover_type: '表紙タイプです',
        folder_cover: '表紙です',
        cover_type_1:'最新作です',
        cover_type_1_tip:'コンピレーションアルバムの最新作が自動的にジャケットになります',
        cover_type_2:'表紙を固定します',
        cover_type_2_tip:'定番の表紙に画像をアップロードしました',
        operate_success: '操作成功です',

    },
    status: {
        all:'全部です',
        reviewing: '審査中です',
        reviewed: '審査が通ります。',
        not_review: '審査不通過です。',
    }


}