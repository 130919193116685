import { defineComponent, ref, provide, reactive, toRefs, getCurrentInstance, nextTick, onMounted, onBeforeUpdate } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import {useStore} from 'vuex';
import PageLoading from '@/components/common/PageLoading.vue';
import Swiper from '@/components/layout/Swiper.vue';
import SearchBar2 from '@/components/layout/SearchBar2.vue';
import Header2 from '@/components/layout/header2.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';
import bus from 'vue3-eventbus'
import SideComponent from '@/components/common/SideComponent.vue';
import Loading from '@/components/common/Loading.vue';

export default defineComponent({
	components: {
		PageLoading,
		Header2,
		Menu,
	    Swiper,
	    SearchBar2,
	    Footer,
	    SideComponent,
	    Loading,
	},
	props: {

	},
	setup() {
		provide('logoColor', 'black');
		let {ctx, proxy} = getCurrentInstance();
		const router = useRouter();
	    const route = useRoute();
	    console.log(route,"route");
		const data = reactive({
			isLoading: true,
			showHeader: true,
			loginStyle: "min-width:141px;",
			mobileIconStyle: "color:rgba(223, 58, 8, 1);",
			showListIcon: true,
			newsList:[],
			pagination:{},
			banners:[

      		],
      		page:1,
      		nomore:true,
      		keyword: '',
      		maxHeight:1080,
      		isChangeData: false,
      		isPicLoading: true,
		});

		const showMenu = ref(proxy.$menus);
		provide('showMenu', showMenu);

		// const outerStyleStr = ref("position:relative;left:10%;top:40%;transform:translate(-10%,-40%);width:auto;");
		// provide('outerStyleStr', outerStyleStr);

		// const styleStr = ref("height:45px;");
		// provide('styleStr', styleStr);

		// const showSearchText = ref(false);
		// provide('showSearchText', showSearchText);

		const getImageUrl = (name) => {
		    return proxy.$utils.getImageUrl(name);
		};

		const store = useStore();
		data.banners = store.state.common.siteInfo.sliders;
        // data.newsList = store.state.common.newsList;
        // if(Object.keys(data.newsList).length > 0 && data.newsList.data.length > 0) {

        // } else {
        // 	store.dispatch("common/getNewsList").then((res) => {
	       //  	data.newsList = res
	       //  	data.pagination = data.newsList.pagination;
	       //  })
        // }

        const searchNews = (keyword='', page=1) => {
        	data.keyword = keyword;
        	store.dispatch("common/getNewsList",{
        		search: keyword,
        		page: page,
        	}).then((res) => {
	        	if(res.data.length > 0){
	        		if(page == 1){
	        			data.newsList = res.data;
	        		} else {
	        			data.newsList = data.newsList.concat(res.data);
	        		}
					
					if(res.data.length < res.pagination.pageSize){
						data.nomore = true;
					}else{
						data.nomore = false;
					}
				}else{
					data.nomore = true;
				}        
	        	// data.pagination = res.pagination;
	        	data.page += 1;
	        	console.log(data.newsList,"data.newsList");
	        	data.isPicLoading = false;
	        	bus.emit('showFooter',true);
	        }).catch(err=>{
	        	data.isPicLoading = false;
	        })
        }
        const waterfallHandler = async () => {
			await nextTick(()=>{
			    // const imgWidth = data.imgWidth + data.imgMargin * 2;
			    const contentW = document.getElementById("newslist").offsetWidth;
			    
			    setTimeout(()=>{
				    const imgList = document.getElementsByClassName("news-item");
				    if(imgList.length > 0){
				    	const imgWidth = imgList[0].offsetWidth - 5;
				    
					    // 获取图片的列数
					    const column = parseInt(contentW / imgWidth);
					    
					    // 高度数组
					    const heightArr = new Array(column).fill(0);
					    
					    for (let i = 0; i < imgList.length; i++) {
					        const item = imgList[i];
					        
					        // 当前元素的高度
					        const itemHeight = item.clientHeight - 5;
					        
					        // 高度数组最小的高度
					        const minHeight = Math.min(...heightArr);
					        // 高度数组最小的高度的索引
					        const minIndex = heightArr.indexOf(minHeight);
					        item.style.position = "absolute";
					        item.style.top = minHeight + "px";
					        item.style.left = minIndex * imgWidth + "px";
					        data.maxHeight = Math.max(...heightArr);
					        data.maxHeight += 150;
					        heightArr[minIndex] += itemHeight;
					        
					    }
				    }
				    
				},200);
			})
		}
        
        const viewBox = ref(null);
        const scroll = (val) => {
			if(val == 'top'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		       
			}else if(val == 'bottom'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		        	
		        	
		        
			}
		}

		// Make sure to reset the refs before each update.
	    // onBeforeUpdate(() => {
	    //   // divs.value = [];
	    //   // console.log(divs.value.length,"divs value");
	      
	    // });

	    onMounted(async()=>{
	    	searchNews(data.keyword, data.page);
            // await nextTick(()=>{
	    	// 	if(data.type == 2 && document.documentElement.clientWidth >= 768){
		    // 		document.getElementById("footer").style.top = (document.getElementsByTagName("body")[0].offsetHeight - document.getElementById("footer").offsetHeight*1.5)+'px';		    		
	    	// 	}else if(document.documentElement.clientWidth < 768){ 
	    	// 		console.log(data.maxHeight,'data.maxHeight');  		
	    	// 		document.getElementById("news-wrapper").style.height = (data.maxHeight + document.getElementById("footer").offsetHeight*3.2)+'px';
		    // 	}
	    	// });
        	setTimeout(() => {
    			// if(document.documentElement.clientWidth > 768){
				//     waterfallHandler();
				// }
		        data.isLoading = false;
		        viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		    }, 600);
		    console.log("页面数据更新完毕了")
        })

		const refData = toRefs(data);

		return {
			...refData,
			getImageUrl,
			searchNews,
			viewBox,
			scroll,
			waterfallHandler,
		}
	},
	created() {

	},
	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	},
	methods: {
		init() {

		},
		loadMore() {
			let that = this;
			this.isPicLoading = true;
			this.searchNews(this.keyword, this.page);
		},
		
		toDetail(id){
			// this.$router.push({ name:'news_detail', query:{ id: id } });
			let href = this.$router.resolve({ //使用resolve
		      name:'news_detail',    //这里是跳转页面的name
		      path: '/news_detail',
		      query:{ id: id },
		    })
		    window.open(href.href, '_blank')
		},
		activeStyle(item) {
			// let exif = JSON.parse(item.exif);
			// // console.log(item,"item");
			// let keys = null;
			// let key = '';
			// let data = '';
			// if(exif!=null){
			// 	keys = Object.keys(exif);
			// 	key = keys[0];
			// 	data = exif[key];
			// }
			
			// if(data.ratio_w != undefined && data.ratio_h != undefined) {
			// 	console.log(data,"data");
			// 	(async () => {
			// 		if(item.thumb){
			// 			let size = await this.$utils.getImageSize(item.thumb);
			// 		    console.log(size,"size");
			// 		    data.ratio_w = size.width;
			// 		    data.ratio_h = size.height;
			// 		}
				    
			// 	})();

			// }
			let object = {width:0};
			object.width = item.thumb_width*270/item.thumb_height+'px';
			
			return object;
		},
	},
})