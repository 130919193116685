<template>
	<PageLoading :is-loading="isLoading"></PageLoading>
	<SideComponent @scrollto="scroll"></SideComponent>
	<div class="container" ref="viewBox">
		<Header2 :show-search-bar-pic="showHeader" login-style="loginStyle" :mobile-icon-style="mobileIconStyle" :show-list-icon="showListIcon"/>
		<div class="content">
			<div style="height:100px;"></div>			
			<div class="content-outer">
				<div class="left">
					<div class="outer">	
					<Loading :is-loading="isDetailLoading"></Loading>					
						<img :src="detail.thumb" oncontextmenu="return false" />
						<div class="name"><p>{{detail.name}}</p></div>
						<div class="desc">{{detail.description}}</div>	
					</div>
				</div>	
				<div class="right">
					<div class="wrapper">
						<section class="section">
							<div class="child" :style="activeStyle(item)" v-for="item in photos" @click="toDetail(item.id)">
								<img :src="item.preview" alt="" oncontextmenu="return false" />
								<div class="title">
									<span>{{item.title}}</span>
								</div>
							</div>
							
						</section>
						
					</div>
					<div class="loadmore">
						<Loading :is-loading="isPicLoading"></Loading>
						<span class="more" @click="loadMore()" v-if="!nomore">{{$t('loadmore')}}</span>
						<span class="end" v-if="nomore">- {{$t('theEnd')}} -</span>
					</div>
				</div>	
			</div>

		</div>
		<Footer/>
	</div>
</template>

<script>
	import photo_detail from './photo_grapher_detail_controller';
	export default photo_detail;
</script>

<style src="@/assets/css/photo_grapher_detail.css" scoped></style>