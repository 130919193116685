<template>
<SideComponent @scrollto="scroll"></SideComponent>
	<div class="container" ref="viewBox">
		<Header2 :show-search-bar-pic="showHeader" login-style="loginStyle" :mobile-icon-style="mobileIconStyle" :show-list-icon="showListIcon"/>
		<div class="content">
			<div style="height:80px;"></div>
			<div class="enclosure">
				<div class="inner">
					<div class="left">
						<SideMenu />
					</div>
					<div class="right">
						<div class="caption">
							<span>{{$t('personCenter')}}</span><span class="arrow"><i class="iconfont icon-jiantou2"></i></span><span>{{$t('setting.text1')}}</span>
						</div>
						<div class="caption" style="background-color:#fff;padding:20px 20px;border-bottom: 1px solid rgb(239,239,239);">
							<span>{{username}}</span>
						</div>
						<div class="tag-outer">
							<el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
								<el-tab-pane :label="$t('setting.text2')" name="first">
							    	<div>
								    	<el-form v-if="!isMobile"
										    ref="ruleUserRef"
										    style="max-width: 600px"
										    :model="ruleUser"
										    status-icon
										    :rules="_rules"
										    label-width="auto"
										    class="demo-ruleForm"
										  >
										    <el-form-item :label="$t('form.photo')" prop="thumb">
											    <div class="list-item-img">	
													<label for="avatar_upload" class="list-item-img-outer" >
														<Loading :is-loading="isAvatarLoading"></Loading>
														<input type="file" id="avatar_upload" @change="uploadAvatar($event)" accept="image/*" style="display:none;" />
														<img v-if="!ruleUser.thumb" style="width:100%;height:100%;object-fit:contain;" src="@/assets/img/default.png" />
														<img v-if="ruleUser.thumb" style="width:100%;height:100%;object-fit:contain;" :src="ruleUser.thumb"/>
														<!-- <span class="repeat" v-if="item.hasSimilar">{{$t('apply_work.similar')}}</span> -->
														<!-- hasSimilar <div style="position:absolute;left:0;top:0;z-index:99;width:100%;height:100%;background-color:rgba(0,0,0,0.1);display:flex;justify-content: center;align-items: center;">
															<i class="iconfont icon-delete" style="color:white;font-size:18px;" @click.stop="del('image', index)"></i>
														</div> -->
													</label>
												</div>
											</el-form-item>
										    <el-form-item :label="$t('login.userName')" prop="username">
										      <el-input v-model="ruleUser.username" autocomplete="off" :placeholder="$t('login.userName')" />
										    </el-form-item>
										    <el-form-item :label="$t('setting.text3')" prop="mobile">
										      <el-input v-model="ruleUser.mobile" autocomplete="off" :placeholder="$t('form.mobile')" @change="handleChange($event)"/>
										    </el-form-item>
										    <el-form-item :label="$t('codetip')" prop="code" class="form-code">
										        <el-input v-model="ruleUser.code" autocomplete="off" :placeholder="$t('codetip')">
										        	<template #append>
												        <el-button @click="getCode(ruleUser.mobile)" :disabled="disabled">{{$t('getCode')}}</el-button>
												    </template>
										        </el-input>
										    </el-form-item>
										    <el-form-item :label="$t('form.email')" prop="email">
										      <el-input v-model="ruleUser.email" autocomplete="off" :placeholder="$t('form.email')" />
										    </el-form-item>
										    <el-form-item :label="$t('organizationName')" prop="department">
										      <el-input v-model="ruleUser.department" autocomplete="off" :placeholder="$t('organizationName')" />
										    </el-form-item>
										    
										    <el-form-item>
										      <el-button type="primary" @click="submitUser(ruleUserRef)">{{$t('submitText')}}</el-button>
										      
										    </el-form-item>
										</el-form>

										<el-form v-if="isMobile"
										    ref="ruleUserRef"
										    style="max-width: 600px"
										    :model="ruleUser"
										    status-icon
										    :rules="rules"
										    label-width="auto"
										    class="demo-ruleForm"
										  >
										    
										    <el-form-item prop="username">
										      <el-input v-model="ruleUser.username" autocomplete="off" :placeholder="$t('login.userName')" />
										    </el-form-item>
										    <el-form-item prop="mobile">
										      <el-input v-model="ruleUser.mobile" autocomplete="off" :placeholder="$t('form.mobile')"/>
										    </el-form-item>
										    <el-form-item prop="code" class="form-code">
										        <el-input v-model="ruleUser.code" autocomplete="off" :placeholder="$t('codetip')">
										        	<template #append>
												        <el-button @click="getCode(ruleUser.mobile)" :disabled="disabled">{{$t('getCode')}}</el-button>
												    </template>
										        </el-input>
										    </el-form-item>
										    <el-form-item prop="email">
										      <el-input v-model="ruleUser.email" autocomplete="off" :placeholder="$t('form.email')" />
										    </el-form-item>
										    <el-form-item prop="department">
										      <el-input v-model="ruleUser.department" autocomplete="off" :placeholder="$t('organizationName')" />
										    </el-form-item>
										    
										    <el-form-item>
										      <el-button type="primary" @click="submitUser(ruleUserRef)">{{$t('submitText')}}</el-button>
										      
										    </el-form-item>
										</el-form>
									</div>
							    </el-tab-pane>
							    <el-tab-pane :label="$t('editpassword')" name="second">
							    	<div>
								    	<el-form
										    ref="ruleFormRef"
										    style="max-width: 600px"
										    :model="ruleForm"
										    status-icon
										    :rules="rules"
										    label-width="auto"
										    class="demo-ruleForm"
										  >
										    <el-form-item :label="$t('newPassword')" prop="pass">
										      <el-input v-model="ruleForm.pass" type="password" autocomplete="off" show-password />
										    </el-form-item>
										    <el-form-item :label="$t('confirmPassword')" prop="checkPass">
										      <el-input
										        v-model="ruleForm.checkPass"
										        type="password"
										        autocomplete="off"
										        show-password
										      />
										    </el-form-item>
										    
										    <el-form-item>
										      <el-button type="primary" @click="submitForm(ruleFormRef)">{{$t('submitText')}}</el-button>
										    </el-form-item>
										</el-form>
									</div>
							    </el-tab-pane>
							    
							</el-tabs>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer/>
	</div>
</template>

<script>
	import setting from './setting_controller';
	export default setting;
</script>

<style src="@/assets/css/my/setting.css" scoped>
	/*@import url("@/assets/css/style.css");全局引用css*/
	/*@import '@/assets/css/style.css';*/
</style>