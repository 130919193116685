import { defineComponent, ref, provide, reactive, toRefs, getCurrentInstance, nextTick, onMounted, onBeforeUpdate } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import {useStore} from 'vuex';
import PageLoading from '@/components/common/PageLoading.vue';
import Loading from '@/components/common/Loading.vue';
import Swiper from '@/components/layout/Swiper.vue';
import SearchBar2 from '@/components/layout/SearchBar2.vue';
import Header2 from '@/components/layout/header2.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';
import bus from 'vue3-eventbus'
import SideComponent from '@/components/common/SideComponent.vue';
// import pdf from 'vue-pdf';
import axios from "axios";
import vueEsign from 'vue-esign'


export default defineComponent({
	components: {
		PageLoading,
		Header2,
		Menu,
	    Swiper,
	    SearchBar2,
	    Footer,
	    SideComponent,
	    vueEsign,
	    Loading,
	},
	props: {

	},
	setup() {
		provide('logoColor', 'black');
		let {ctx, proxy} = getCurrentInstance();
		const router = useRouter();
	    const route = useRoute();
	    console.log(route,"route");
	    const order_id = route.query.order_id;
	    const validate_email_rules = (rule, value, callback) => {
			if (value == "") return true;
			let email = proxy.$utils.regEmail(value);
		    if (!email) {
		    	let tip = proxy.$t('enterCorrectEmail');
		        callback(new Error(tip));
		    } else {
		        callback();
		    }
		    

		}

		const validate_realname_rules = (rule, value, callback) => {
			if (value == "") return true;
			let company = proxy.$utils.regCompany(value);
		    if (!company) {
		    	let tip = proxy.$t('enterRealname');
		        callback(new Error(tip));
		    } else {
		        callback();
		    }

		}
		const validate_company_rules = (rule, value, callback) => {
			if (value == "") return true;
			let company = proxy.$utils.regCompany(value);
		    if (!company) {
		    	let tip = proxy.$t('enterCorrectUnitName');
		        callback(new Error(tip));
		    } else {
		        callback();
		    }

		}
		const validate_idcard_rules = (rule, value, callback) => {
			if (value == "") return true;
			let company = proxy.$utils.regIDC(value);
		    if (!company) {
		    	let tip = proxy.$t('enterCorrectIdcard');
		        callback(new Error(tip));
		    } else {
		        callback();
		    }

		}
		const validate_social_number_rules = (rule, value, callback) => {
			if (value == "") return true;
			let company = proxy.$utils.validateSocialCardNumber(value);
		    if (!company) {
		    	let tip = proxy.$t('enterCorrectInstitutionName');
		        callback(new Error(tip));
		    } else {
		        callback();
		    }

		}

		const data = reactive({
			isLoading: true,
			showHeader: true,
			loginStyle: "min-width:141px;",
			mobileIconStyle: "color:rgba(223, 58, 8, 1);",
			showListIcon: true,
			newsList:[],
			pagination:{},
			banners:[

      		],
      		page:1,
      		nomore:true,
      		keyword: '',
      		maxHeight:1080,
      		order_id: 0,
  			contract_formid: 0,
  			active: 1,
  			pdfurl:"",//https://test.cainiaohd.com/storage/files/8ac99ff013c55af968405fdc4becbfa0.pdf",
  			iframeUrl:'',
  			showForm: true,
  			showPdf:false,
  			showDownload:false,
  			lineWidth: 6,
		    lineColor: '#000000',
		    bgColor: 'rgba(219,219,219,0)',
		    isCrop: false,
		    resultImg: '',
		    isPdfLoading: true,
		    showIframe: false,
		    isDownLoading: true,
		    showDownloadBtn: false,
		    contractUrl: '/api/v1/contract/download/',
		});
		data.order_id = order_id;
		const showMenu = ref(proxy.$menus);
		provide('showMenu', showMenu);


		const store = useStore();

		const ruleRef = ref()
		const ruleUser = reactive({
			order_id: data.order_id,
		    user_type:'1',
  			realname:'',
  			idcard:'',
  			organize:'',
  			organize_sn:'',
  			email: '',
		})

		const _rules = reactive({
			order_id: [
				{ required: true, message: proxy.$t('contract.tip6'), trigger: 'blur' },
			],
			user_type: [
				{ required: true, message: proxy.$t('contract.tip25'), trigger: 'change' },
			],
  			realname: [
				{ required: true, message: proxy.$t('contract.tip26'), trigger: 'blur' },
				{ validator: validate_realname_rules, trigger: "blur" },
			],
			idcard:[
				{ required: true, message: proxy.$t('contract.tip27'), trigger: 'blur' },
				{ validator: validate_idcard_rules, trigger: "blur" },
			],
			organize: [
				{ required: true, message: proxy.$t('contract.tip28'), trigger: 'blur' },
				{ validator: validate_company_rules, trigger: "blur" },
			],
			organize_sn:[
				{ required: true, message: proxy.$t('contract.tip29'), trigger: 'blur' },
				{ validator: validate_social_number_rules, trigger: "blur" },
			],
			email: [
				{ required: true, message: proxy.$t('contract.tip30'), trigger: 'blur' },
				{ validator: validate_email_rules, trigger: "blur" },
			],
		})
		let timerId = 0;

		const submitForm = (formEl) => {
			bus.emit('needRefresh', true)
			if (!formEl) return
			formEl.validate((valid) => {
			    if (valid) {
				    console.log(valid,'submit!')
				    store.dispatch("user/SubmitContract",{
				      	order_id: ruleUser.order_id,
					    user_type: ruleUser.user_type,
			  			realname: ruleUser.realname,
			  			idcard: ruleUser.idcard,
			  			organize: ruleUser.organize,
			  			organize_sn: ruleUser.organize_sn,
			  			email: ruleUser.email,
				    }).then((res)=>{
				      	// console.log("SubmitContract",res);
				      	ElMessage({
						    message: res.status_text,
						    grouping: true,
						    type: 'warning',
						})

				      	nextStep(false,true,false);
						timerId = setInterval(()=>{
							ContractDetail(data.order_id);
						},2000);
				      	
				    }).catch(err => {
			            // console.error(err)
			            ElMessage({
						    message: proxy.$t('contract.tip31'),
						    grouping: true,
						    type: 'error',
						})
			        })
			    } else {
			      console.log('error submit!')
			      return false
			    }
			})
		}

		const ContractDetail = (order_id) => {
			console.log(order_id,"order_id");
			store.dispatch("user/ContractDetail",{
		      	order_id: order_id,
			    
		    }).then((res)=>{
		      	console.log("ContractDetail",res);
		      	
	      		if(data.showPdf && res.status == 2){
		      		clearInterval(timerId);
		      		getPdfContent(res.template_path);		      		
		      	}
		      	
		      	if(data.showDownload && res.status == 1){
		      		clearInterval(timerId);
		      		data.contractUrl += order_id;
		      		// ContractDownload(order_id);
		      		data.isDownLoading = false;
		      		data.showDownloadBtn = true;
		      	}
		      	
		    }).catch(err => {
	            // console.error("ContractDetail",err)
	            ElMessage({
				    message: proxy.$t('contract.tip32'),
				    grouping: true,
				    type: 'error',
				})
	        })
		}
		const ContractFormData = () => {
			store.dispatch("user/ContractFormData",{
		      	
		    }).then((res)=>{
		      	// console.log("ContractFormData",res);
		      	if(Object.keys(res).length > 0){
		      		data.contract_formid = res.id ? res.id : 0;
				    ruleUser.user_type = res.user_type ? res.user_type+'' : '1';
		  			ruleUser.realname = res.realname ? res.realname : '';
		  			ruleUser.idcard = res.idcard ? res.idcard : '';
		  			ruleUser.organize = res.organize ? res.organize : '';
		  			ruleUser.organize_sn = res.organize_sn ? res.organize_sn : '';
		  			ruleUser.email =  res.email ? res.email : '';
					
		      	}
		      	bus.emit('showFooter',true);
		    }).catch(err => {
	            // console.error("ContractFormData",err)
	   //          ElMessage({
				//     message: '获取合同资料失败',
				//     grouping: true,
				//     type: 'error',
				// })
	        })
		}

		const nextStep = (showForm, showPdf, showDownload) => {
	        if (data.active++ > 3) data.active = 1;
	        console.log(data.active,"active");
	        data.showForm = showForm;
  			data.showPdf = showPdf;
  			data.showDownload = showDownload;
	    }

	    const getPdfContent = (pdfurl) => {
			axios({
				url:pdfurl,
				method:"get",
				responseType:"blob"
			}).then((res) =>{
				const blob = new Blob([res.data],{type: 'application/pdf'});
				data.iframeUrl = window.URL.createObjectURL(blob);
				data.showIframe = true;
				data.isPdfLoading = false;
			})

	    }

	    const UploadEsign = (order_id, esignImg) => {
	    	store.dispatch("user/UploadEsign",{
	    		order_id: order_id,
	    		base64_file: esignImg,
	    	}).then((res)=>{
	    		// console.log(res,"UploadEsign");
	    		nextStep(false,false,true);
				timerId = setInterval(()=>{
					ContractDetail(data.order_id);
				},2000);
	    	}).catch(err => {	            
	            ElMessage({
				    message: proxy.$t('contract.tip33'),
				    grouping: true,
				    type: 'error',
				})
	        })
	    }

	    const ContractDownload = (order_id) => {
	    	store.dispatch("user/ContractDownload",{
	    		order_id: order_id,
	    	}).then((res)=>{
	    		console.log(res,"download");
	    		data.contractUrl = res;
	    	}).catch(err => {	            
	            console.log("err",err);
	        })
	    }

        const viewBox = ref(null);

        const scroll = (val) => {
			if(val == 'top'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		       
			}else if(val == 'bottom'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    }); 	
		        
			}
		}

	    onMounted(async()=>{
	    	ContractFormData();
	    	
        	setTimeout(() => {
		        data.isLoading = false;
		        viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		    }, 600);
		    console.log("页面数据更新完毕了")
        })

		const refData = toRefs(data);

		return {
			...refData,
			viewBox,
			scroll,
			ruleRef,
			ruleUser,
			_rules,
			submitForm,
			ContractDetail,
			nextStep,
			getPdfContent,
			UploadEsign,
			ContractDownload,
		}
	},
	created() {

	},
	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	},
	methods: {
		init() {

		},
		loadMore() {
			let that = this;
			
		},
		
		toDetail(id){
			
		},
		activeStyle(item) {

			let object = {width:0};
			object.width = item.thumb_width*270/item.thumb_height+'px';
			
			return object;
		},
		handleReset () {
		    this.$refs.esign.reset()
		},
		handleGenerate () {
		    this.$refs.esign.generate().then(res => {
		      console.log(res,'sign');		      
		      if(res){
		      	this.resultImg = res
		      	this.UploadEsign(this.order_id, this.resultImg);
		      }
		      
		    }).catch(err => {
		      console.log(err,'sign err') // 画布没有签字时会执行这里 'Not Signned'
		    })
		}
	},
})