export default {
    login: {
        login: 'تسجيل الدخول',
        userName: 'اسم المستخدم',
        password: 'شعار',
        name: 'اسم المستخدم أو اسم الوحدة',
    },
    captcha: 'كلمة التحقق',
    forgetPassword: 'هل نسيت كلمة المرور؟',
    loginTip: 'نتيجة تسجيل الدخول الحالية عشوائية. املأ رمز التحقق كما تريد',
    editpassword: 'تغيير كلمة المرور الخاصة بك',
    errMsg: {
        inputRequired: 'الرجاء إدخال{cont}',
        selectRequired: 'يرجى الاختيار{cont}',
    },
    uernameTips: 'كل مستخدم ، يرجى ملء اسم المستخدم ، وحدة المستخدم ، يرجى ملء اسم الوحدة',

    title: 'تشينغداو',
    logo_small_text:'تشغيل وصيانة مركز تشينغداو الدولي للأخبار',
    hello: 'مرحبا',
    loginOrRegister: 'تسجيل الدخول / التسجيل',
    anonymity: 'المستخدمون المجهولون',
    personCenter: 'المركز الشخصي',
    logout:'تسجيل الخروج',
    toLogin: 'يرجى تسجيل الدخول أولا',
    mobileTip: 'رقم الجوال',
    submitText: 'إرسال',
    accountTip: 'اسم المستخدم / رقم الهاتف',
    password: 'شعار',
    newPassword: 'كلمة السر الجديدة',
    confirmPassword: 'تأكيد كلمة المرور الجديدة',
    noAccount: 'ليس لديك حساب حتى الآن؟',
    hasAccount: 'هل لديك حساب بالفعل؟',
    register: 'تسجيل',
    phoneNumber: 'رقم الهاتف المحمول',
    email: 'صندوق بريد',
    organizationName: 'اسم المنظمة',
    loginNow:'تسجيل الدخول الآن',
    accountLogin: 'تسجيل الدخول إلى الحساب',
    loginSuccess: 'تم تسجيل الدخول بنجاح',
    loginFailed: 'فشل تسجيل الدخول',
    registerSuccess: 'التسجيل الناجح',
    registerFailed: 'فشل التسجيل',
    enterUsername: 'الرجاء إدخال اسم المستخدم الخاص بك',
    enterPhoneNumber: 'الرجاء إدخال رقم هاتفك المحمول',
    enterRealname: 'يرجى كتابة اسمك الحقيقي',
    enterCorrectUnitName: 'يرجى ملء الاسم الصحيح للوحدة',
    enterCorrectIdcard: 'يرجى ملء رقم الهوية الصحيح',
    enterCorrectInstitutionName: 'يرجى ملء رقم المؤسسة الصحيح',
    enterCorrectPhoneNumber: 'الرجاء إدخال رقم الهاتف المحمول الصحيح',
    enterCorrectEmail: 'الرجاء إدخال عنوان البريد الإلكتروني الصحيح',
    enterCorrectUnitName: 'يمكن أن يكون اسم الوحدة بالحروف الصينية أو مجموعة من الحروف أو الأرقام.',
    enterCorrectUsernameAndPhone: 'يُرجى إدخال اسم المستخدم/رقم الهاتف المحمول الخاص بك',
    nameContain: 'يجب أن يحتوي اسم المستخدم على أحرف أو أرقام',
    passwordContain: 'كلمة السر يجب أن يكون على الأقل 8 أرقام ، حروف ، رموز خاصة ( ! $ ^ & * - ! )',
    passwordContain2: '密码需要至少8位数字、字母、特殊符号!@#$%^&*?-_组合',
    enterPassword: 'الرجاء إدخال كلمة المرور الخاصة بك',
    passwordLength: 'طول كلمة السر على الأقل 8 بت',
    qrlogin: 'اسحب لتسجيل الدخول',
    updateSuccess: 'تحديث ناجح',
    retrievePassword: 'استرجاع كلمة المرور',
    pageUpdated: 'تم تحديث بيانات الصفحة.',
    cart1: 'عربة',
    onlineService: 'خدمة العملاء عبر الإنترنت',
    newsCenter: 'تشغيل مركز تشينغداو الدولي للمعلومات وصيانته',
    qdnews: 'مركز تشينغداو الدولي للمعلومات',
    hostUnit: 'الاستضافة:',
    contactPhone: 'رقم الهاتف',
    workTime: 'ساعات العمل',
    footerText1: 'كمية كبيرة من المواد عالية الوضوح',
    footerText2: 'حقوق الطبع والنشر للاستخدام التجاري',
    aboutus: 'نبذة عنا',
    copyrightNotice: 'بيان حقوق الطبع والنشر',
    questions: 'المشاكل الشائعة',
    terms: 'شروط الاستخدام',
    selfRegulatory: 'اتفاقية الانضباط الذاتي لمنصات المواقع الإلكترونية',
    contractUs: 'اتصل بنا',
    friendLink:'الرابط الودي',
    followUs: 'تابعنا على',
    copyright:'صورة تشينغداو جميع الحقوق محفوظة',
    ICP: 'لو برنامج المقارنات الدولية رقم 2023012351-1',
    category:'التصنيف' ,
    pv:'عدد المشاهدات:',
    uv:'إجمالي التسجيلات:',
    qrcode:'اكتساح رمز الدخول',
    loadmore: 'تحميل المزيد',
    theEnd: 'نهاية .',

    codeLogin:'رمز التحقق من الدخول',
    getCode:'الحصول على رمز التحقق',
    enterCode:'الرجاء إدخال رمز التحقق',
    codetip:'رمز التحقق',
    viewmore:'شاهد المزيد',

    menus: {
        home: 'الصفحة الرئيسية (لموقع ويب)',
        news: 'مسار الفيديو',
        pic: 'صورة فوتوغرافية',
        video: 'فيديو',
        vr: 'المتحف الرقمي',
        grapher: 'المصورون',
        active: 'الوظيفة',
        more: 'المزيد من الخدمات',
        package: 'باقات الأسعار',
        subject: 'موضوع خاص',
    },
    contract:{
        tip1:'معلومات إضافية عن العقد',
        tip2:'توقيع العقد',
        tip3:'تحميل العقد',
        tip4:'وللحفاظ على فعالية العقد وضمانات المتابعة، يرجى ملء المعلومات الشخصية الحقيقية، ويتم شراء المعلومات التي ستملأ تلقائياً في وقت لاحق.',
        tip5:'طلب معرف',
        tip6:'يرجى إدخال معرف الطلب',
        tip7:'نوع المعلومات؟',
        tip8:'مستخدم شخصي',
        tip9:'المؤسسات',
        tip10:'الاسم الحقيقي',
        tip11:'يرجى إدخال الاسم الحقيقي',
        tip12:'رقم الهوية',
        tip13:'يرجى إدخال رقم الهوية',
        tip14:'منظمة',
        tip15:'يرجى إدخال المنظمة',
        tip16:'رقم المؤسسة',
        tip17:'يرجى إدخال رقم الوكالة',
        tip18:'صندوق البريد',
        tip19:'يرجى إدخال صندوق البريد',
        tip20:'إنشاء قالب العقد',
        tip21:'اضغط على الزر الأيسر للفأرة أسفل المربع الأحمر على توقيعك بخط اليد',
        tip22:'توليد التوقيع',
        tip23:'إفراغ اللوحة',
        tip24:'عقد التنزيل',
        tip25:'يرجى اختيار نوع المستخدم',
        tip26:'يرجى إدخال الاسم الحقيقي',
        tip27:'يرجى إدخال رقم الهوية',
        tip28:'يرجى إدخال اسم الوكالة',
        tip29:'يرجى إدخال رقم الوكالة',
        tip30:'يرجى إدخال صندوق البريد',
        tip31:'فشل حفظ معلومات العقد',
        tip32:'فشل الحصول على معلومات العقد',
        tip33:'تحميل فشل',

    },
    recharge:{
        tip1:'لمحة عامة عن الأرباح',
        tip2:'لمحة عامة عن الإيرادات',
        tip3:'المبالغ غير المصفاة (الو)',
        tip4:'المبالغ المصفاة (الو)',
        tip5:'طلب التسوية',
        tip6:'أوامر دقيقة',
        tip7:'اسم العمل',
        tip8:'وقت الدفع',
        tip9:'مقدار الطلب',
        tip10:'حصة',
        tip11:'رقم الطلب:',
        tip12:'سجلات التسوية',
        tip13:'وقت طلب التسوية',
        tip14:'التسوية',
        tip15:'وقت الحساب',
        tip16:'التسوية',
        tip17:'تنتظر التسوية',
        tip18:'جاري التسوية',
        tip19:'تم',
        
    },

    search: {
        search: 'بحث .',
        hotSearch: 'عمليات البحث الشائعة',
        takePic: 'التقط صورة بحث عن صورة',
        dragPic: 'اسحب الصورة وأفلتها في أي مكان في هذه المنطقة',
        or: 'ربما',
        clickUpload: 'انقر للتحميل',
        sizeLimit: 'استخدام ملفات jpg/png التي يقل حجمها عن 50 ميغابايت',
        searchPic: 'البحث عن صورة',
        searchVideo: 'فيديو البحث',
        pic: 'صورة فوتوغرافية',
        video: 'فيديو',
        trySearch:'حاول البحث عن الكلمات المفتاحية',
        scenery: 'أن تحظى بتقدير كبير',
        building: 'الإنشاءات',
        culture: 'الثقافات',
        humanities: 'العلوم الإنسانية',
        educate: 'التدريس',
        isJPG: 'قم بتحميل الصور بتنسيق JPG، PNG فقط!',
    },
    sideMenu: {
        apply: 'مساهمات المصورين الفوتوغرافيين',
        account: 'إدارة الحسابات',
        wallet: 'محفظتي',
        cart: 'عربة',
        authRecords: 'سجلات التفويض',
        orderlist:'قائمة الطلبات',
        collectManage: 'جمع النشاط',
        collect: 'نشاط العمل',
        folder: 'مجموعة من الأنشطة',
        personCenter: 'مركز المصورين',
        applyInfo: 'معلومات الدخول',
        workManage: 'بلدي يعمل',
        recharge: 'مركز الإيرادات',
    },
    person: {
        personCenter: 'مركز المبدعين',

    },
    application: {
        settled: 'المصورون على متن الطائرة',
        process: 'عملية الدخول',
        plain: 'تعليمات الدخول',
        apply: 'تقديم طلب',
        result: 'تقديم النتائج',
        tipText1: 'مرحبًا بك للتقديم كمصور فوتوغرافي مبدع في مسابقة Image Qingdao، من أجل تحسين معدل نجاح مشاركتك، يرجى ملء معلومات المشاركة بشكل صحيح.',
        tipText2: 'سنقوم بالرد على طلبك في غضون 3 أيام عمل، ويمكنك التحقق من التقدم المحرز هنا.',
        apply_enter: 'التقدم بطلب للحصول على وظيفة',
        base_info: 'يرجى ملء المعلومات الأساسية',
    },
    form: {
        user_type: 'نوع المستخدم',
        license: 'الرخصة التجارية',
        licenseTip: 'وحدة الرخصة التجارية ختم النسخة الإلكترونية ( أو رمز المنظمة شهادة ختم النسخة الإلكترونية )',
        type1: 'فرد .',
        type2: 'تنظيم الوحدات',
        unitName:'اسم الوحدة',
        unitTip:'من فضلك أدخل اسم الوحدة',
        realname: 'الاسم الحقيقي',
        enterRealname: 'الرجاء إدخال اسمك الحقيقي',
        gender: 'التمييز بين الجنسين',
        male: 'الذكور',
        female: 'النساء',
        photo: 'صورة فوتوغرافية شخصية',
        enterPhoto: 'يُرجى تحميل صورتك الشخصية',
        uploadPhoto: 'تحميل الصور',
        biography: 'مقدمة',
        enterBiography: 'يرجى ملء في مقدمة',
        mobile: 'رقم الهاتف المحمول',
        enterPhone: 'الرجاء إدخال رقم هاتفك المحمول',
        email: 'عنوان البريد الإلكتروني',
        enterEmail: 'الرجاء إدخال عنوان بريدك الإلكتروني',
        company: 'اسم المنظمة',
        enterCompany: 'الرجاء إدخال اسم المنظمة',
        enterFeedType: 'يرجى تحديد نوع المساهمة',
        ctype: 'نوع الممارسة',
        media: 'وسائل الإعلام، وخاصة وسائل الإعلام الإخبارية',
        non_media: 'غير وسائل الإعلام',
        worktype: 'نوع المساهمة',
        image: 'صورة فوتوغرافية',
        video: 'فيديو',
        workPic: 'صور لأعمال تمثيلية',
        workPicTip: '(الأعمال التمثيلية هي فقط للرجوع إليها في تدقيق الدخول، وسيساعد تحميل الأعمال التمثيلية الأصلية عالية الجودة على زيادة معدل نجاح الدخول)',
        uploadPic: 'تحميل صورة',
        againUploadPic: 'استمر في تحميل الصورة',
        againUploadVideo: 'متابعة تحميل الفيديو',
        limit_pic_quantity: '(يرجى تقديم 10 صور أصلية)',
        workVideo: 'عروض الفيديو',
        uploadVideo: 'تحميل الفيديو',
        limit_video_quantity: '(يرجى تقديم 3 مقاطع فيديو أصلية)',
        submit_apply: 'تقديم طلب',
        tip_text_1: 'معلومات دخولك قيد المراجعة...',
        tip_text_2: 'سنراجع معلومات دخولك في غضون 3 أيام عمل (في حالة العطلات الوطنية سيتم تأجيلها)',
        tip_text_3: 'يمكنك عرض نتائج المراجعة على هذه الصفحة!',
        uploadPicWork: 'يُرجى تحميل صورة لعملك',
        uploadVideoWork: 'يرجى تحميل إدخالات الفيديو',
        enter_limit_pic: 'يرجى تحميل 10 صور',
        enter_limit_video: 'يرجى تحميل 3 مقاطع فيديو',
        submited: 'الطلب المقدم',
        only_ten_pic: 'يمكن تحميل 10 صور فقط',
        only_three_video: 'يمكن تحميل 3 مقاطع فيديو فقط',
        idcard: 'رقم الهوية',
        idcard_photo: 'هوية الصورة',
        enter_idcard: 'الرجاء إدخال رقم هويتك',
        idcard_tip: '( يرجى تحميل صورة الهوية )',
        bankcard: 'رقم بطاقة الصراف الآلي',
        enter_bankcard: 'الرجاء إدخال رقم بطاقتك المصرفية',
        bankcard_photo:'صور البطاقة المصرفية',
        bankcard_tip:'(يرجى تحميل صورة مع رقم بطاقتك المصرفية)',
        preview:'تقديم العقد ومعاينته',
        enterCorrectbankcardNumber: 'يرجى ملء رقم البطاقة المصرفية الصحيحة',
        enterIdcardPhoto: 'يُرجى تحميل الجزء الأمامي من بطاقة هويتك',
        enterBankcardPhoto: 'يُرجى تحميل الجزء الأمامي من بطاقتك المصرفية',
    },
    apply_contract:{
        info: 'معلومات العقد',
        sign: 'توقيع العقد',
        tip1: 'من أجل ضمان الحقوق القانونية لكلا الطرفين، يرجى إدخال بيانات هويتك ومعلومات بطاقتك المصرفية من أجل إنشاء عقد إلكتروني، وسيتم العقد بنجاح بمجرد توقيعه.',
        tip2: '*المعلومات التالية تُستخدم فقط لتوقيع العقد وتسوية الأرباح، ولا يمكن تغييرها بعد توقيع العقد، لذا يرجى ملء البيانات التالية بعناية!',
        tip3: 'لضمان الحقوق القانونية لكلا الطرفين، يرجى قراءة محتوى العقد بعناية، ولن تتمكن من تعديله بعد إتمام التوقيع!',
        tip4: 'يرجى ملء معلومات العقد',
        tip5: 'معاينة العقد',
        tip6: 'يُرجى كتابة توقيعك بخط اليد عن طريق الضغط باستمرار على زر الفأرة الأيسر في المنطقة ذات الحدود الحمراء أدناه.',
        tip7: 'إنشاء التوقيع',
        tip8: 'إفراغ لوحة الرسم',
        signSuccess:'التوقيع بنجاح',
        noMatch:'عدم تطابق معلومات الهوية',
        submit_success:'يقدم بنجاح',
        idcard_face:'يرجى تحميل ( أو إعادة تحميل ) صورة إيجابية من بطاقة الهوية',
        upload_fail:'فشل تحميل',

    },
    apply_info: {
        tip1:'مرحبًا بك في Image Qingdao، يمكنك تحميل عملك والحصول على فرصة لكسب المال بعد الموافقة!',
        tip2:'معلومات الدخول',
        tip3:'انقر لعرض العقد',
        tip4: 'عقد إلكتروني',
        
    },

    apply_nopass: {
        tip1:'لم تتم الموافقة على مراجعة طلب الدخول الخاص بك...',
        tip2:'السبب:بعد إجراء تقييم شامل للتقديم الذي أجراه خبراؤنا لمشاركتك، فإنك لا تستوفي متطلبات القبول لدينا في الوقت الحالي!',
        tip3:'يمكنك محاولة تقديم تمثيل أفضل جودة وسنقوم بإجراء تقييم شامل آخر~',
        tip4:'إعادة التقديم',
    },
    apply_pass: {
        tip1: 'تهانينا، لقد تمت الموافقة على طلبك للقبول!',
        tip2: 'بعد استكمال معلوماتك، يمكنك تحميل عملك والحصول على فرصة لكسب المال!',
        tip3: 'المساهمات الموقعة',
    },
    apply_passing: {
        tip1:'معلومات دخولك قيد المراجعة...',
        tip2:'سنراجع معلومات دخولك في غضون 3 أيام عمل (في حالة العطلات الوطنية سيتم تأجيلها)',
        tip3:'يمكنك عرض نتائج المراجعة على هذه الصفحة',
    },
    apply_common: {
        del:'احذف هذا العنصر',
        addimg:'إضافة صورة',
        addvideo:'إضافة فيديو',
        batch_pics:'تعبئة معلومات موحدة الصور المجمعة',
        batch_videos:'تعبئة المعلومات الموحدة للفيديو الدفعي',
        search_subject:'البحث عن اسم الموضوع',
        upload_success:'تم التحميل بنجاح، في انتظار المراجعة',
        upload_pic_eight:'تحميل ما يصل إلى 8 صور في المرة الواحدة',
        upload_video_eight:'تحميل ما يصل إلى 8 مقاطع فيديو في المرة الواحدة',
        size_exceed:'لا يمكن أن يتجاوز الحجم 50 متر',
        submit_success:'تم التقديم بنجاح',
        agreement:'لقد قرأت ووافقت على',
        protocols:'اتفاقية الترخيص لترخيص محتوى صورة تشينغداو المصورة',
        read:'يُرجى قراءة اتفاقية ترخيص محتوى Image Qingdao والموافقة عليها أولاً!',

        selectAll: 'بالإجماع',
        off_pic: 'ستتم إزالة الصور تلقائيًا من الموقع عند النقر على إزالة، هل أنت متأكد من إزالة الصور؟',
        del_pic: 'سيتم حذف الصور تلقائيًا من الموقع بعد النقر لأسفل، هل أنت متأكد من الحذف؟',
        batch_off_pic: 'دفعة من الرفوف (من منتج ما)',
        batch_del_pic: 'حذف مجمّع',
        season: 'السبب:',
        edit: 'المترجم',
        off: 'إنزاله من الرفوف (على سبيل المثال منتج ملوث)',
        del: 'إزالة',
        off_video: 'ستتم إزالة مقاطع الفيديو تلقائيًا من الموقع بعد النقر على إزالة، هل أنت متأكد من الإزالة؟',
        del_video: 'سيتم حذف الفيديو من الموقع بعد النقر على حذف، هل أنت متأكد من الحذف؟',
        off_item: 'يرجى تحديد الخيار المراد إنزاله',
        had_off: 'تم إزالة العمل',
        del_item: 'الرجاء تحديد الخيارات المراد حذفها',
        had_del: 'تم حذف العمل',
        del_tip: 'انقر فوق حذف ثم غير قابل للاسترداد، متأكد من الحذف؟',
        edit_folder:'تجميع المحرر',
        add_folder:'إضافة تجميع',
        enter_folder_title: 'الرجاء إدخال عنوان التجميع',
        enter_keyword_tip:'اضغط على Enter لكل كلمة رئيسية تقوم بإدخالها.',
        add_to_folder:'إضافة إلى التجميع',
        select_folder:'يرجى اختيار تجميع',
        author:'المؤلف',
        enter_author:'الرجاء إدخال المؤلف',
        create_date:'تاريخ الإنشاء',

    },
    apply_work: {
        year_limit: 'السنة يمكن أن تكون رقمية فقط',
        tip0:'تغذية الصور',
        tip1:'عذرا، لم تطلب أذونات الصور',
        tip2:'نصائح: إذا كنت بحاجة إلى إرسال الصور ، فيرجى إعادة التقدم بطلب للحصول على عقد',
        tip3:'إعادة التعاقد',
        tip4:'يرجى تحديد صورة واحدة أو أكثر تدعم تنسيقات صور متعددة',
        tip5:'اسم الصورة',
        tip6:'الرجاء إدخال اسم للصورة',
        tip7:'تسميات توضيحية مميزة للصور',
        tip8:'الرجاء إدخال تعليق',
        tip9:'الكلمات الرئيسية المواضيعية',
        tip10:'الرجاء إدخال كلمة مفتاحية',
        tip11:'اسم الموضوع',
        tip12:'الرجاء اختيار موضوع',
        tip13:'تمت إضافة مواضيع جديدة',
        tip14:'حالة الرسم البياني الصغير',
        tip15:'سعر الرسم البياني الصغير',
        tip16:'الرجاء إدخال سعر الصورة الصغيرة',
        tip17:'حالة الصورة الأصلية',
        tip18:'سعر الصورة الأصلية',
        tip19:'الرجاء إدخال سعر الصورة الأصلية',
        tip20:'حالة الصورة الكبيرة',
        tip21:'سعر الصورة الكبيرة',
        tip22:'الرجاء إدخال سعر صورة أكبر',
        tip23:'تمكين',
        tip24:'تعطيل',
        tip25:'إرسال للمراجعة',

        tip26:'تغذية الفيديو',
        tip27:'عذرا، لم تطلب أذونات الفيديو',
        tip28:'نصائح: إذا كنت بحاجة إلى إرسال مقطع فيديو ، فيرجى إعادة التقدم بطلب للحصول على عقد',
        tip29:'يرجى تحديد مقطع فيديو واحد أو أكثر لدعم تنسيقات فيديو متعددة',
        tip30:'اسم الفيديو',
        tip31:'الرجاء إدخال اسم للفيديو',
        tip32:'وصف الفيديو',
        tip33:'الرجاء إدخال وصف الفيديو',
        tip34:'اسم الموضوع',
        tip35:'الرجاء إدخال اسم الموضوع',
        tip36:'التصنيف المواضيعي',
        tip37:'يرجى تحديد فئة مواضيعية',
        tip38:'الأغطية المواضيعية',
        tip39:'أوصاف الميزات',
        tip40:'الرجاء إدخال وصف للموضوع',
        tip41:'إرسال',
        tip42:'يرجى تحميل غلاف ميزة',

        more:'تحميل المزيد',

        price_pack: 'حزمة السعر حزمة',
        select_price_pack: 'يرجى تحديد حزمة الأسعار',
        pic_limit: 'تحميل ما يصل إلى 8 صور',
        video_limit: 'تحميل ما يصل إلى 8 مقاطع فيديو',
        topic_price_pack: 'حزمة أسعار التغطية المواضيعية',
        year:'السنواتالسنوات',
        yearTip:'يرجى إدخال سنة',
        coverTip: 'هذا هو مجرد صورة الغلاف ، تحتاج إلى إعادة الإرسال في صورة تغذية',
        has_similar: 'لا تكرار تحميل الصور',
        similar: 'صورة مكررة',
    },
    no_settled: {
        tip1:'عذرا ، لا يمكنك إرسال مشاركتك إلا بعد أن تستقر بنجاح~',
        tip2:'لم يتم اعتمادك بعد كمصور رسمي',
        tip3:'المصورون يستقرون في',
    },
    index: {
        hot: 'أهم الصور الموصى بها',
        grapher:'مصور ممتاز',
        image: 'صورة',
        video: 'فيديو',
    },
    news: {
        news:'فيديو',
        publish_time: 'وقت الإصدار:',
    },
    package: {
        name: 'اسم العرض',
        content: 'مزايا المحتوى الحصرية',
        expire: 'مصطلح',
        day: 'سماء',
        buy: 'شراء حزمة',
        between: 'نطاق الأذونات',
        min: 'صورة صغيرة',
        original: 'الرقم الأصلي',
        max: 'أطلس',
        tip1: '1500 يوان/قطعة رنمينبي',
        tip2:'2200 يوان صيني/قطعة',
        tip3: 'مرر للدفع',
        order_sn: 'رقم الطلب',
        price:'مبلغ الطلب:',
        open:'عرض (تذكرة)',
        scan: 'دفع رمز المسح الضوئي (الحوسبة)',
        wechatPay: 'الدفع عبر WeChat',
        alipay: 'الدفع عبر Alipay',
        weixin: 'مايكروسوفت',
        ali:'Alipay، منصة الدفع عبر الإنترنت',
        select:'يرجى اختيار الباقة',
        tip4: 'صورة',
        tip5: 'فيديو واحد',
        tip6: 'حزم الفيديو',
    },
    grapher: {
        title: 'المصورون',
        home:'الصفحة الرئيسية (لموقع ويب)',
        grapher:'مصور فوتوغرافي موهوب',
        tip1:'(الترتيب ليس بترتيب معين للألقاب)',
        tip2:'صورة مصور فوتوغرافي في تشينغداو',

    },
    search_pic:{
        tip1:'صور مماثلة',
        tip2:'ابحث عن موعد',
        tip3:'الدخول',
    },
    topics:{
        tip1:'مواضيع ذات صلة',
        home:"الصفحة الرئيسية (لموقع ويب)",
    },
    pic:{
        tip1:'الموقع الحالي:',
        tip2:'الوصف:',
        tip3:'تراخيص خالية من حقوق الملكية (”RF“)، واستخدام غير محدود ودائم للمحتوى غير المرخص من دون ترخيص من RF، والتسعير على أساس حجم الملف، وعدم وجود علامة مائية بعد التنزيل. لمزيد من المعلومات، يُرجى الرجوع إلى:',
        tip4:'اتفاقية ترخيص محتوى ImageQingdao',
        tip5:'إضافة إلى عربة التسوق',
        tip6:'الشراء الفردي',
        tip7:'للاستفسار والشراء، يُرجى الاتصال على 0532-82825531 أو استشرنا.',
        tip8:'خدمة العملاء عبر الإنترنت',
        tip9:'معرّف الصورة:',
        tip10:'الصورة التوضيحية:',
        tip11:'مواصفات الصورة:',
        tip12:'أبعاد الصورة:',
        tip13:'نوع الصورة:',
        tip14:'حجم الصورة:',
        tip15:'سعر الصورة:',
        tip16:'سنة الصورة:',
        tip17:'تعليق على الصورة:',
        tip18:'كلمة السر',
        tip19:'صور مماثلة',

    },
    video: {
        text1: 'معرّف الفيديو:',
        text2: 'عنوان الفيديو:',
        text3: 'نوع الفيديو:',
        text4: 'مدة الفيديو:',
        text5: 'حجم الفيديو:',
        tip1: 'كلمة السر',
        tip2: 'توصيات الفيديو المماثلة',
        desc: 'مقدمة',
    },
    vr: {
        text1: 'صالة عرض الواقع الافتراضي',
        text2: 'التفاصيل',
    },
    order_detail: {
        text1: 'تفاصيل الطلب',
        text2: 'وصف الخدمة',
        text3: 'بالنسبة للفواتير، يُرجى الاتصال بخدمة العملاء:',
        text4: 'رقم الطلب',
        text5: 'مبلغ الطلب:',
        text6: 'المبلغ المدفوع:',
        text7: 'حالة الدفع:',
        text8: 'في انتظار السداد',
        text9: 'مدفوعة الأجر',
        text10: 'طرق الدفع:',
        text11: 'باقة المنتج أو الخدمة (على سبيل المثال لاشتراك هاتف خلوي)',
        text12: 'الدفع عبر WeChat',
        text13: 'الدفع عبر Alipay',
        text14: 'رصيد (حساب، فاتورة، إلخ)',
        text15: 'وقت الدفع',
        text16: 'حان وقت تقديم الطلب',
        text17: 'معلومات الصورة',
        text18: 'المجموع المستحق',
        text19: 'معاينة الصورة',
        text20: 'اسم الصورة',
        text21: 'مواصفات الصورة',
        text22: 'أسعار الصور',
        text23: 'اختر طريقة الدفع',
        text24: 'اختر باقة للدفع',
        text25: 'المبلغ المدفوع من الجيب:',
        text26: 'إرسال الطلب',

        text27: 'دفع أرصدة الحسابات',
        // text28: '账户可用余额 <span style="color:rgb(230, 0, 18);font-weight:bold;">0.00</span>元。如果您已在【价格套餐】页面购买了套餐包，请优先选择套餐包支付。',
        text29: 'الدفع عبر WeChat',
        text30: 'دعم خدمة WeChat للدفع السريع',
        text31: 'الدفع عبر Alipay',
        text32: 'دعم الدفع السريع من Alipay',

        text33: 'رصيد الحساب المتاح',
        text34: 'إذا كنت قد اشتريت بالفعل باقة على صفحة [باقة الأسعار]، يُرجى إعطاء الأولوية لدفع ثمن الباقة.',
        text35: 'اكتمل الدفع',
        text36: 'فشل في الدفع',
        origin_1:'الصورة الأصلية:',
        small_1:'صورة صغيرة:',
        big_1:'الصورة الكبيرة',
        origin:'الرقم الأصلي',
        small:'صورة صغيرة',
        big:'أطلس',
        maohao:'::',
    },
    order_pay: {
        text1: 'معلومات الطلب',
        text2: 'الطلبات المدفوعة مسبقًا',
        tip1: 'يرجى اختيار سعر الصورة',
        tip2: 'اختر واحدة فقط',
    },
    order_list: {
        text0: 'قائمة الطلبات',
        text1: 'اسم الصورة:',
        text2: 'وقت الإنشاء:',
        text3: 'ابحث عن شيء.',
        text4: 'معرّف الطلب:',
        text5: 'رقم الطلب',
        text6: 'حان وقت تقديم الطلب',
        text7: 'القرار:',
        text8: 'تفاصيل الطلب',
        text9: 'تنزيل العقود',
        text10: 'السعر:',
        text11:'وقت البدء',
        text12:'وقت الانتهاء',
        text13:'حتى',
        wait_pay:'في انتظار السداد',
        pay_success:'تم الدفع بنجاح',
        paid:'مدفوعة الأجر',
        package:'باقة المنتج أو الخدمة (على سبيل المثال لاشتراك هاتف خلوي)',
        wechatPay:'الدفع عبر WeChat',
        alipay:'الدفع عبر Alipay',
        balance:'رصيد (حساب، فاتورة، إلخ)',
    },
    setting: {
        text1:'تغيير كلمة المرور الخاصة بك',
        text2:'معلومات المستخدم',
        text3:'رقم الهاتف المحمول ',

    },
    auth: {
        text1: 'قائمة التفويض',
        text2: 'اسم الصورة:',
        text3: 'وقت التفويض',
        text4: 'معاينة الصورة',
        text5: 'عنوان الصورة',
        text6: 'مواصفات الصورة',
        text7: 'دقة الصورة',
        text8: 'الحفارة',
        text9: 'التحميل',


    },
    wallet: {
        text1: 'محفظتي',
        text2: 'الرصيد المتاح',
        text3: 'سجلات الرصيد',
        text4: 'التصنيف',
        text5: 'مبلغ من المال',
        text6: 'الأوقات',
        text7: 'لا يوجد رصيد مسجل في الوقت الحالي',
    },
    cart: {
        text1: 'عربة',
        text2: '(المجموع)',
        text3: '(عنصر واحد)',
        text4: 'الحفارة',
        text5: 'معاينة الصورة',
        text6: 'عنوان الصورة',
        text7: 'مواصفات الصورة',
        text8: 'الأسعار',
        text9: 'إزالة',
        text10: 'لقد قرأت ووافقت على',
        text11: 'صورة اتفاقية ترخيص تشينغداو',
        text12: 'بيان خصوصية الصورة تشينغداو',
        text13: 'منتجات مختارة',
        text14: 'المجموع (الإجمالي)',
        text15: 'إغلاق حساب',
        text16: 'مصنف للملابس والأمتعة والزينة؛ قطعة من العمل؛ أمر، حدث',
        clearCart:'عربة فارغة',
        batchDel:'حذف مجمّع',

    },
    activity: {
        actName: 'المشاركة في نشاط ما',
        selectAct: 'يُرجى اختيار دعوة للمشاركة',
        selectActName: 'البحث عن اسم الحدث',
        category: 'التصنيف',
        select_cate:'اختر الفئة',
    },
    active: {
        upload: 'قم بتحميل عملك',
        pic:'صورة فوتوغرافية',
        video: 'فيديو',
        recommend: 'الشهادات',
        tip7:'صورة توضيحية',
        tip9:'كلمة السر',
        folder_title: 'التسمية التوضيحية',
        enter_title: 'الرجاء إدخال عنوان',
        folder_desc: 'الأوصاف',
        enter_desc: 'وصف التجميع',
        cover_type: 'نوع الغطاء',
        folder_cover: 'غلاف (منشور)',
        cover_type_1:'أحدث الأعمال',
        cover_type_1_tip:'استخدام أحدث عمل في التجميع تلقائيًا كغلاف التجميع.',
        cover_type_2:'غطاء ثابت',
        cover_type_2_tip:'تحميل الصور محلياً كغطاء ثابت',
        operate_success: 'كانت العملية ناجحة.',

    },
    status: {
        all:'كاملة',
        reviewing: 'قيد المراجعة',
        reviewed: 'معتمد',
        not_review: 'فشل التدقيق',
    }


}