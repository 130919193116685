import { onMounted,nextTick, defineComponent, ref, provide, reactive, toRefs, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import Swiper from '@/components/layout/Swiper.vue';
import SearchBar2 from '@/components/layout/SearchBar2.vue';
import Header2 from '@/components/layout/header2.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';
import SideMenu from '@/components/layout/SideMenu.vue';
import bus from 'vue3-eventbus'
import SideComponent from '@/components/common/SideComponent.vue';
import Loading from '@/components/common/Loading.vue';

export default defineComponent({
	components: {
		Header2,
		Menu,
	    Swiper,
	    SearchBar2,
	    Footer,
	    SideMenu,
	    SideComponent,
	    Loading,
	},
	props: {

	},
	setup() {
		let {ctx, proxy} = getCurrentInstance();
		const data = reactive({
			showHeader: true,
			loginStyle: "min-width:141px;",
			mobileIconStyle: "color:rgba(223, 58, 8, 1);",
			showListIcon: true,
			activeName: 'first',
			formSize: 'default',			
			pagination: {},
			page: 1,
			keywords: '',
			allCats: [],
			nomore: true,
			type: 1,
			orderlist: [],
			logpagination: {},
			logpage: 1,
			logList: [],
			disabled: false,
			rechargeInfo: {},
		});
		const showMenu = ref(proxy.$menus);
		provide('showMenu', showMenu);

		const outerStyleStr = ref("position:relative;left:10%;top:40%;transform:translate(-10%,-40%);width:auto;");
		provide('outerStyleStr', outerStyleStr);

		const styleStr = ref("height:45px;");
		provide('styleStr', styleStr);

		const showSearchText = ref(false);
		provide('showSearchText', showSearchText);

		const getImageUrl = (name) => {
		    return proxy.$utils.getImageUrl(name);
		};

		// bus.emit('needRefresh', true)
		
		const store = useStore();	
		
		// const allCategorys = () => {
			
		// 	store.dispatch("common/getAllSubjectCats",{

	 //    	}).then((res)=>{		      	
		//       	data.allCats = res;
		//       	console.log("allCategorys",data.allCats);
		//     }).catch(err => {
	 //            console.error(err)
	            
	 //        })
		// }

			
		const viewBox = ref(null);
        const scroll = (val) => {
			if(val == 'top'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		       
			}else if(val == 'bottom'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
   
			}
		}
		
		const CameraRechargeOrder = (page=1) => {
			store.dispatch("user/CameraRechargeOrder",{
				page: page,
			}).then((res)=>{
				
				console.log(res,"orderlist");
				data.orderlist = res.data;				
				data.pagination = res.pagination;
				data.page = res.pagination.current;
			}).catch(err=>{

			})
		}

		const CameraRechargeLog = (page=1) => {
			store.dispatch("user/CameraRechargeLog",{
				page: page,
			}).then((res)=>{
				console.log(res,"logList");
				data.logList = res.data;
				data.logpagination = res.pagination;
				data.logpage = res.pagination.current;
			}).catch(err=>{

			})
		}
		// 申请结算
		const CameraRecharge = () => {
			data.disabled = true;
			store.dispatch("user/CameraRecharge",{

			}).then((res)=>{
				console.log(res,"CameraRecharge");
				
			}).catch(err=>{
				ElMessage({
			        message: err.message,
			        type: "warning",
			    });	
			})
		}

		const CameraRechargeInfo = () => {
			store.dispatch("user/CameraRechargeInfo",{

			}).then((res) => {
				console.log(res,"CameraRechargeInfo");
				data.rechargeInfo = res;
				
			})
		}

		const handleClick = (tab, event) => {
		  console.log(tab.paneName, "tabs")
		  
		  if(tab.paneName == 'first'){
		  	data.page = 1;
		  	data.type = 1;
		  	// CameraRechargeOrder(data.page);
		  }else if(tab.paneName == 'second'){
		  	data.logpage = 1;
		  	data.type = 2;
		  	// CameraRechargeLog(data.logpage)
		  }
		  // CameraRechargeOrder(data.type, data.page);
		}

        onMounted(async()=>{
        	CameraRechargeOrder(data.page);
        	CameraRechargeInfo();
        	CameraRechargeLog(data.logpage)

        	// CameraWorkList(data.type, 1);
        	// allCategorys();
        	await nextTick()
        	setTimeout(() => {
		      data.isLoading = false;
		      viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		      bus.emit('showFooter',true);
		    }, 600);
		    console.log("页面数据更新完毕了")
        })

		const refData = toRefs(data);

		return {
			...refData,
			viewBox,
			scroll,	
			// allCategorys,
			CameraRechargeOrder,
			CameraRechargeLog,
			handleClick,
			CameraRecharge,
			CameraRechargeInfo,
		}
	},
	created() {

	},
	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	},
	watch:{

	},
	methods: {
		init() {
			// console.log("this.allCategorys", this.allCats);
		},
		addTopic() {
			this.showSubject = true;
		},
		close() {
			this.showSubject = false;
		},
		handleSizeChange(pageSize) {
	      this.pagination.pageSize = pageSize;
	      // 在此刷新数据
	    },
		handlePageChange(currentPage){
			this.pagination.current = currentPage;
			this.search();
		},
		search(){		
			this.CameraRechargeOrder(this.pagination.current);
		},
		handleLogSizeChange(pageSize) {
	      this.logpagination.pageSize = pageSize;
	      // 在此刷新数据
	    },
		handlelogPageChange(currentPage){
			this.logpagination.current = currentPage;
			this.logsearch();
		},
		logsearch(){		
			this.CameraRechargeLog(this.pagination.current);
		},
		// loadMore() {
		// 	this.getSubjectList(this.subtype, this.page);
		// },
		
        getVideoFirstFrame(url, file) {
			const video = document.createElement('video') // 创建video对象
			video.src = url // url地址
			const canvas = document.createElement('canvas') // 创建 canvas 对象
			const ctx = canvas.getContext('2d') // 绘制2d
			video.crossOrigin = 'anonymous' // 解决跨域问题，也就是提示污染资源无法转换视频
			video.currentTime = 1 // 第一秒帧
			video.oncanplay = () => {
			    canvas.width = 90
			    canvas.height = 90
			    // 利用canvas对象方法绘图
			    ctx.drawImage(video, 0, 0, 90, 90)

			    // 转换成base64形式
			    let base64_file = canvas.toDataURL('image/png');
			    // Vue.set(file, 'imageShow', canvas.toDataURL('image/png')) // 获取视频第一帧作为imageShow展示
			}
		},
	}
})