// 方法已在全局注册,使用方法时 this.$utils.方法名(参数) 
export function getImageUrl(name) {
    return new URL(`../assets/${name}`, import.meta.url).href;
}
export function isMobile() {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag;
}

export function round(number, precision){
  return Math.round(+number + "e" + precision) / Math.pow(10, precision);
  //same as:
  //return Number(Math.round(+number + 'e' + precision) + 'e-' + precision);
}

export function getImageInfo(url) {
    return new Promise(function (resolve, reject) {
        // // 创建FileReader对象
        // var reader = new FileReader();
        // reader.readAsBinaryString(url);
        // // 绑定load事件监听器
        // reader.onload = function(e) {
        //   console.log(e,"event");
        //   // 读取图像文件的元数据
        //   var metadata = e.target.result;
        //   // 解析元数据
        //   var exifData = EXIF.readFromBinaryFile(metadata);

        //   // 获取拍摄日期
        //   var takenDate = exifData.DateTimeOriginal;
        //   resolve();
        // };

        // 读取图像文件
        let image = new Image();
        image.src = url;
        image.onload = function () {
            resolve({
                width: image.width,
                height: image.height,             
            });
        };

        image.onerror = function () {
            reject(new Error('error'));
        };
        
    });
}
export function* handleImageArr(imagesArr=[]){
  for(let i=0;i<imagesArr.length;i++){
    yield imagesArr[i];
  }
}
export function getImageSize(url) {
    return new Promise(function (resolve, reject) {
        let image = new Image();
        image.src = url;
        image.onload = function () {
            resolve({
                width: image.width,
                height: image.height
            });
        };
        image.onerror = function () {
            reject(new Error('error'));
        };
        
    });
}

export function handleImageInfo(imagesArr={}) {
  let arr = [];
  let obj = {};
  if(imagesArr.data.length>0){
    imagesArr.data.forEach( async(item,index)=>{
      // (async () => {
        let size = await getImageSize(item.thumb);
        // console.log(size,"size");
        item.ratio_w = size.width;
        item.ratio_h = size.height;
        arr.push(item);
        if(arr.length==imagesArr.data.length){
          obj.data = arr;
          obj.pagination = imagesArr.pagination
          console.log(obj,'obj');
          return obj;
        }
      // })();
    })
    
    // resolve(data);

  }else{
    return imagesArr;
  }
}

export function reorderArray(arr=[]) {
  let newArr = [];
  if(arr.length > 0) {

    let item = {},j=0;
    for(let i=0;i<arr.length;i++){
      if(arr[i].direction == 3){ // 1:方图  2：长图  3：竖图
        j = i;
        item = arr[i];
        break;
      }
    }
    if(j!=3){
      arr.splice(j,1);
      arr.splice(3,0,item);

    }
    // console.log(arr,"arr");
    return arr;
    
  }
  

}
export function selectall(name) {
  let arr = document.getElementsByName(name);
  if (document.getElementById("checkAll").prop("checked")==false) {
    for (var i = 0; i < arr.length; i++) {
      arr[i].checked = false;
    }

    // document.getElementsByName(name).forEach((item, index, array)=>{
    //   item.checked = false;
    // } );
    
    // $("input[name='"+name+"']").each(function() {
    //   this.checked = false;
    // });
  } else {
    for (var i = 0; i < arr.length; i++) {
      arr[i].checked = true;
    }

    // document.getElementsByName(name).forEach((item, index, array)=>{
    //   item.checked = true;
    // } );
    // $("input[name='"+name+"']").each(function() {
    //   this.checked = true;
    // });
  }
}

/*判断客户端*/
export function judgeClient() {
    let u = navigator.userAgent;
    let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;   //判断是否是 android终端
    let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);     //判断是否是 iOS终端
    // console.log('是否是Android：' + isAndroid); //true,false
    // console.log('是否是iOS：' + isIOS);
    if(isAndroid){
      return 'android';
    }else if(isIOS){
      return 'ios';
    }else{
      return 'pc';
    }
}
//导出文件流
export function getExport(content,name){
  var elink = document.createElement("a");
  var blob = new Blob([content]);
  elink.download = name;
  elink.style.display = "none";
  elink.href = URL.createObjectURL(blob);
  elink.click();
}

//时间格式化
export function formattingTime(time) {
  if (typeof time !== 'number' || time < 0) {
    return time
  }

  var hour = parseInt(time / 3600)
  time = time % 3600
  var minute = parseInt(time / 60)
  time = time % 60
  var second = time

  return ([hour, minute, second]).map(function (n) {
    n = n.toString()
    return n[1] ? n : '0' + n
  }).join(':')
}
//根据格式转换时间戳
//   时间戳转化为年 月 日 时 分 秒 
//   number: 传入时间戳 
//   format：返回格式，支持自定义，但参数必须与formateArr里保持一致 如 formatTime(时间戳, "Y.M.D")
export function formatNumber(n, zero) {
  n = n.toString()
  if (zero) return n;
  return n[1] ? n : '0' + n
}
 /* 时间戳转换为时间 */
export function timestampToTime(timestamp) {
    if(!timestamp) return '';
  timestamp = timestamp ? timestamp : null;
  let date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
  let Y = date.getFullYear() + '-';
  let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
  let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
  let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
  let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
  let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
  return Y + M + D + h + m + s;
}

const formatDate = (time) => {
    if(!time) return '';
    const date = new Date(time*1000);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以加1，并用0填充
    const day = String(date.getDate()).padStart(2, '0'); // 用0填充
    const hours = String(date.getHours()).padStart(2, '0'); // 用0填充
    const minutes = String(date.getMinutes()).padStart(2, '0'); // 用0填充
    return `${year}-${month}-${day} ${hours}:${minutes}`;
}

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
    return value.toString().padStart(2, '0')
  })
  return time_str
}



export function formatTime(number, format, zero) {

  var formateArr = ['Y', 'M', 'D', 'h', 'm', 's'];
  var returnArr = [];

  var date = new Date(number * 1000);
  returnArr.push(date.getFullYear());
  returnArr.push(formatNumber(date.getMonth() + 1, zero));
  returnArr.push(formatNumber(date.getDate(), zero));

  returnArr.push(formatNumber(date.getHours()));
  returnArr.push(formatNumber(date.getMinutes()));
  returnArr.push(formatNumber(date.getSeconds()));

  for (var i in returnArr) {
    format = format.replace(formateArr[i], returnArr[i]);
  }
  return format;
}
//经纬度格式化
export function formatLocation(longitude, latitude) {
  if (typeof longitude === 'string' && typeof latitude === 'string') {
    longitude = parseFloat(longitude)
    latitude = parseFloat(latitude)
  }

  longitude = longitude.toFixed(2)
  latitude = latitude.toFixed(2)

  return {
    longitude: longitude.toString().split('.'),
    latitude: latitude.toString().split('.')
  }
}
//时间戳转换为微信朋友圈时间格式
export const dateUtils = {
  UNITS: {
    '年': 31557600000,
    '月': 2629800000,
    '天': 86400000,
    '小时': 3600000,
    '分钟': 60000,
    '秒': 1000
  },
  humanize: function (milliseconds) {
    var humanize = '';
    for (var key in this.UNITS) {
      if (milliseconds >= this.UNITS[key]) {
        humanize = Math.floor(milliseconds / this.UNITS[key]) + key + '前';
        break;
      }
    }
    return humanize || '刚刚';
  },
  format: function (dateStr) {
    var date = this.parse(dateStr)
    var diff = Date.now() - date.getTime();
    if (diff < this.UNITS['天']) {
      return this.humanize(diff);
    }
    var _format = function (number) {
      return (number < 10 ? ('0' + number) : number);
    };
    return date.getFullYear() + '/' + _format(date.getMonth() + 1) + '/' + _format(date.getDay()) + '-' +
      _format(date.getHours()) + ':' + _format(date.getMinutes());
  },
  parse: function (str) { //将"yyyy-mm-dd HH:MM:ss"格式的字符串，转化为一个Date对象
    var a = str.split(/[^0-9]/);
    return new Date(a[0], a[1] - 1, a[2], a[3], a[4], a[5]);
  }
};
//获取当前时间 年-月-日
export function nowDate() {
  let nowDate = new Date();
  let date = {
    year: nowDate.getFullYear(),
    month: nowDate.getMonth() + 1,
    date: nowDate.getDate(),
  }
  let month = ''
  let lifeday = ''
  if (date.month < 10) {
    month = '0'
  }
  if (date.date < 10) {
    lifeday = '0'
  }
  console.log(date);
  let systemDate = date.year + '-' + month + date.month + '-' + lifeday + date.date;
  return systemDate
}
//获取上月时间 年-月-日
export function LastDate() {
  let nowDate = new Date();
  let date = {
    year: nowDate.getFullYear(),
    month: nowDate.getMonth(),
    date: nowDate.getDate(),
  }
  let month = ''
  let lifeday = ''
  if (date.month == 0) {
    date.month = 12;
    data.year = data.year - 1
  }
  if (date.month < 10) {
    month = '0'
  }
  if (date.date < 10) {
    lifeday = '0'
  }
  let LastDates = date.year + '-' + month + date.month + '-' + lifeday + date.date;
  return LastDates
}

//获取当前页面以及参数
export function getUrl() {
  const pages = getCurrentPages()
  const currentPage = pages[pages.length - 1]
  const url = currentPage.route

  const options = currentPage.options
  let urlWithArgs = `/${url}?`
  for (let key in options) {
    const value = options[key]
    urlWithArgs += `${key}=${value}&`
  }
  urlWithArgs = urlWithArgs.substring(0, urlWithArgs.length - 1)
  return urlWithArgs
}
//获取两个经纬度距离(单位米)
export function getDistance(lat1, lng1, lat2, lng2) {
  var radLat1 = lat1 * Math.PI / 180.0;
  var radLat2 = lat2 * Math.PI / 180.0;
  var a = radLat1 - radLat2;
  var b = lng1 * Math.PI / 180.0 - lng2 * Math.PI / 180.0;
  var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) +
    Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
  s = s * 6378.137; // EARTH_RADIUS;
  s = Math.round(s * 10000) / 10;
  return s;
}

//----------------------------正则匹配--------------------
//判断付款金额
export function regpay(payMoney) {
  var regs = /(^0*(\.0*)$)/;
  var regs1 = /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/;
  var regs2 = /(\-)/;
  if (payMoney == "" || payMoney == "0" || !regs1.test(payMoney) || regs2.test(payMoney) || regs.test(payMoney)) {
    return true;
  } else {
    return false;
  }
}
export function regUsername(str){
  return RegExp(/^[A-Za-z0-9]{2,}$/).test(str);
}

export function regPassword(str){
  // var reg= /^(?=.*[A-Za-z])(?=.*\d)(?=.*[`~!@#$%^&*()_+<>?:"{},.\/\\;'[\]])[A-Za-z\d`~!@#$%^&*()_+<>?:"{},.\/\\;'[\]]{8,}$/ ;
  return RegExp(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*\-_?])[A-Za-z\d!@#$%^&*\-_?]{8,}$/).test(str);
  // return RegExp(/^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,30}$/).test(str);
}

//匹配帐号
export function regUid(str) {
  return RegExp(/^\w{6,12}$/).test(str);
}
//匹配密码
export function regPass(str) {
  return RegExp(/^\w{8,12}$/).test(str);
}

//匹配姓名
export function regName(str) {
  return RegExp(/^[\u4e00-\u9fa5]{2,5}$/).test(str);
}
//验证汉字和字母数字
export function regCompany(str) {
  return RegExp(/^(?!\s*$)[A-Za-z0-9._\-\(\)\u4e00-\u9fa5\（）\s-]+$/).test(str);
}

//输入是否全为空
export function regAir(str) {
  return str.split(" ").join("").length
}

//匹配身份证
export function regIDC(str) {
  return RegExp(/^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/).test(str);
}

//匹配手机号
export function isPhone(value) {
  const reg = /^((13[0-9])|(14[5-7])|(15[0-3,5-9])|(17[0,3,5-8])|(18[0-9])|166|191|198|199|(147))\d{8}$/
  return reg.test(value)
}

//手机号以 3 - 4 - 4 格式显示
export function regPhoneFormat(str) {
  return str.replace(/(\d{3})(\d{4})/, "$1 $2 "); //添加空格
}

//匹配验证码
export function regCheckNum(str) {
  return !RegExp(/^\d{6}$/).test(str);
}

//匹配邮箱格式
export function regEmail(str) {
  return RegExp(/^([0-9a-zA-Z_\.\-\])+\@([0-9a-zA-Z_\.\-\])+\.([a-zA-Z]+)$/).test(str);
  // return !RegExp(/^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/).test(str);
  // return !RegExp(/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/).test(str);
}
//判断对象是否为空
export function object_dede(params) {
  let flag = true;

  for (var key in params) {
    if (params[key] != '0' && !params[key]) {
      return false; // 终止程序
    }
  }

  return flag;
}


export function isValidBankCardNumber(bankCardNumber) {
    // 定义正则表达式模式
    const pattern = /^\d{16,19}$/;
    return pattern.test(bankCardNumber);
}


// 统一社会信用代码
export function validateSocialCardNumber(value) {
  if (value === "") {
    return false;
  } else {
    const firstarray = [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z'
    ]
    const firstkeys = [3, 7, 9, 10, 5, 8, 4, 2]
    const secondarray = [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'J',
      'K',
      'L',
      'M',
      'N',
      'P',
      'Q',
      'R',
      'T',
      'U',
      'W',
      'X',
      'Y'
    ]
    const secondkeys = [1, 3, 9, 27, 19, 26, 16, 17, 20, 29, 25, 13, 8, 24, 10, 30, 28]
 
    function calc(code, array1, array2, b) {
      let count = 0
      for (let i = 0; i < array2.length; i++) {
        const a = code[i]
        count += array2[i] * array1.indexOf(a)
      }
      const remainder = count % b
      return remainder === 0 ? 0 : b - remainder
    }
 
    const code = value.toUpperCase()
    if (code.length !== 18) {
      return false
    }
    const reg = /^\w\w\d{6}\w{9}\w$/
    if (!reg.test(code)) {
      return false
    } else {
      /*
          登记管理部门代码：使用阿拉伯数字或大写英文字母表示。​
          机构编制：1
          民政：5
          工商：9
          其他：Y
      */
      let reg1 = /^[1,5,9,Y]\w\d{6}\w{9}\w$/
      if (!reg1.test(code)) {
        return false
      } else {
        /*
          机构类别代码：使用阿拉伯数字或大写英文字母表示。
          机构编制机关：11打头
          机构编制事业单位：12打头
          机构编制中央编办直接管理机构编制的群众团体：13打头
          机构编制其他：19打头
          民政社会团体：51打头
          民政民办非企业单位：52打头
          民政基金会：53打头
          村民委员会：54打头
          民政其他：59打头
          工商企业：91打头
          工商个体工商户：92打头
          工商农民专业合作社：93打头
          其他：Y1打头
      */
        let reg2 =
          /^(11|12|13|19|21|31|32|33|34|35|41|51|52|53|54|55|61|62|59|71|72|81|91|92|93|A1|G1|J1|N1|N2|N3|Y1)\d{6}\w{9}\w$/
        if (!reg2.test(code)) {
          return false
        } else {
          /*
              登记管理机关行政区划码：只能使用阿拉伯数字表示。按照GB/T 2260编码。
              例如：四川省成都市本级就是510100；四川省自贡市自流井区就是510302。
              */
          let reg3 =
            /^(11|12|13|19|21|31|32|33|34|35|41|51|52|53|54|55|61|62|59|71|72|81|91|92|93|A1|G1|J1|N1|N2|N3|Y1)\d{6}\w{9}\w$/
          if (!reg3.test(code)) {
            return false
          } else {
            const firstkey = calc(code.substr(8), firstarray, firstkeys, 11)
            let firstword
            if (firstkey < 10) {
              firstword = firstkey
            }
            if (firstkey === 10) {
              firstword = 'X'
            } else if (firstkey === 11) {
              firstword = '0'
            }
            if (firstword !== code.substring(16, 17)) {
              return false
            } else {
              const secondkey = calc(code, secondarray, secondkeys, 31)
              const secondword = secondarray[secondkey]
              if (!secondword || secondword !== code.substring(17, 18)) {
                return false
              } else {
                const word = code.substring(0, 16) + firstword + secondword
                return code === word;
              }
            }
          }
        }
      }
    }
  }
}