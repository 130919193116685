<template>
	<PageLoading :is-loading="isLoading"></PageLoading>
	<SideComponent @scrollto="scroll"></SideComponent>
	<div class="container" ref="viewBox">
		<div class="header-container">
			<div class="search-swiper-container">				
				<Swiper :list="banners"/>
				<!-- <search-bar-pic :isNeedRedirect="false" @searchKeywords="fetchGrapher"></search-bar-pic> -->
			</div>
		</div>
		<div class="content">
			<div class="wrapper">
				<div class="tab-wrapper">
					<div class="tab-left">
						<div class="tab-item" v-for="item in tags" @click="switchTag(item)"><span :class="tagStyle(item)">{{item.name}}</span></div>
					</div>
					<div class="tab-right">
						<div class="upload-btn" @click="toUpload()">{{$t('active.upload')}}</div>
					</div>
				</div>
				<div class="pic-video-list" v-if="showList">
					<div class="item-wrapper" v-if="showList && picList.length>0">
						<div class="head">{{$t('active.pic')}}</div>
						<div class="body">
							<Loading :is-loading="isPicLoading"></Loading>
							
							<div class="img-item" v-for="item in picList" :style="activeStyle(item)" @click.stop="toPicDetail(item.id)">					
								<img class="img" :src="item.thumb"  oncontextmenu="return false" :alt="item.title"/>
						  		<div class="title">
						  			<span>{{item.title}}</span>
						  		</div>						
							</div>
							
						</div>
						<div class="loadmore">
							<span class="more" @click="loadMorePics()" v-if="!picNomore">{{$t('loadmore')}}</span>
							<span class="end" v-if="picNomore">- {{$t('theEnd')}} -</span>
						</div>
					</div>
					<div style="height:50px;width:100%;"></div>
					<div class="item-wrapper" v-if="showList && videoList.length>0">
						<div class="head">{{$t('active.video')}}</div>
						<div class="body">
							<Loading :is-loading="isVideoLoading"></Loading>
							
							<div class="img-item" v-for="item in videoList" :style="activeStyle(item)" @click.stop="toVideoDetail(item.id)">						
								<video v-if="!isMobile"
								    controls								
								    :poster="item.thumb" 
								    preload="none" 
								    muted 
								    style="object-fit:cover;width:100%;height:100%;border-radius: 8px;" 
								    controlslist="nodownload" 
								    oncontextmenu="return false"
								    :src="item.url"
								     :alt="item.title"
								    >
									<track kind="captions" />
								</video>
								<video v-if="isMobile"								
								    :poster="item.thumb" 
								    preload="none" 
								    muted 
								    :alt="item.title" 
								    style="object-fit:cover;width:100%;height:100%;border-radius: 8px;" 
								    controlslist="nodownload" 
								    oncontextmenu="return false"
							    	
							    >
								<track kind="captions" />
								<source :src="item.url" type="video/mp4">
							</video>
						  		<div class="title">
						  			<span>{{item.title}}</span>
						  		</div>						
							</div>
							
						</div>
						<div class="loadmore">
							<span class="more" @click="loadMoreVideos()" v-if="!videoNomore">{{$t('loadmore')}}</span>
							<span class="end" v-if="videoNomore">- {{$t('theEnd')}} -</span>
						</div>
					</div>
				</div>


				<div class="pic-video-list container-width" v-if="showExcellent">
					<div id="giftwork-content" v-html="activityDetail.giftwork" style="text-align: justify;"></div>
				</div>

				
				<div class="pic-video-list container-width" v-if="showRevelation">
					<div id="revelation-content" v-html="activityDetail.content" style="text-align: justify;"></div>
				</div>
			</div>
			
		</div>
		<Footer/>

	</div>
</template>

<script>
	import active from './active_controller';
	export default active;
</script>

<style src="@/assets/css/active.css" scoped>
	/*@import url("@/assets/css/style.css");全局引用css*/
	/*@import '@/assets/css/style.css';*/
</style>