<template>
	<PageLoading :is-loading="isLoading"></PageLoading>
	<SideComponent @scrollto="scroll"></SideComponent>
	<div class="container" ref="viewBox">		
		<div class="header-container">
			<HeaderVR />
			<!-- <div class="bigimg-container" v-if="banner.length>0">
				<Swiper :list="banner"/>
			</div> -->
		</div>
		<div id="content" class="content" ref="content">
			<Loading :is-loading="isListLoading"></Loading>
			
			<section class="section">
				<div class="ar-content">
					
					<div class="html-content" id="html-content" v-html="arDetail.content"></div>
					
				</div>
			</section>
			<!-- <Footer/> -->
			
		</div>
		
		<Footer/>
	</div>
	<!-- <Footer/> -->
</template>

<script>
	import subject_detail from './subject_detail_controller';
	export default subject_detail;
</script>

<style src="@/assets/css/subject_detail.css" scoped></style>