<template>
<SideComponent @scrollto="scroll"></SideComponent>
	<div class="container" ref="viewBox">
		<Header2 :show-search-bar-pic="showHeader" login-style="loginStyle" :mobile-icon-style="mobileIconStyle" :show-list-icon="showListIcon"/>
		<div class="content">
			<div style="height:80px;" v-if="!isMobile"></div>
			<div style="height:60px;" v-if="isMobile"></div>
			<div class="enclosure">
				<div class="inner">
					<div class="left">
						<SideMenu />
					</div>
					<div class="right">
						<div class="caption">
							<span>{{$t('personCenter')}}</span><span class="arrow"><i class="iconfont icon-jiantou2"></i></span><span>{{$t('order_list.text0')}}</span>
						</div>
						<div class="cart-outer">
							<!-- <div class="cart-info">
								<div class="item">
									<div class="subitem">
										<span style="color:#606266;padding:10px 0;">{{$t('order_list.text1')}}</span>
										<el-input v-model="keyword" style="width: 240px" :size="size"  />
									</div>
									<div class="subitem">
										<span style="color:#606266;padding:10px 0;">{{$t('order_list.text2')}}</span>
										<el-date-picker
										    style="width:100%;"
									        v-model="date"
									        type="daterange"
									        :range-separator="$t('order_list.text13')"
									        :start-placeholder="$t('order_list.text11')"
									        :end-placeholder="$t('order_list.text12')"
									        :size="size"
									        :shortcuts="shortcuts"
									        :editable="false"
									        format="YYYY-MM-DD"
									        value-format="YYYY-MM-DD"
									    />
									</div>
								</div>
								<div class="item">
									<span class="search btn" @click="search()">{{$t('order_list.text3')}}</span>

								</div>
							</div> -->
							<div class="cart-table">
								<div class="table-body">
									<div class="item" v-for="item in OrderList" v-if="!isMobile">
										<div class="subitem-head">
											<div class="subitem-left"><input type="checkbox" /></div>
											<div class="subitem-order">{{$t('order_list.text4')}}{{item.id}}</div>
											<div class="subitem-order">{{$t('order_list.text5')}}{{item.order_sn}}</div>
											<div class="subitem-order">{{$t('order_list.text6')}}{{parseTime(item.order_time)}}</div>
										</div>
										<div class="subitem-body">
											<div class="subitem-right images">
												<div class="subitem-item-outer" v-for="img in item.order_items">
													<div class="subitem-item">
														<img :src="img.image.preview" style="width:100%;height:100px;object-fit:contain;"/>
														<!-- <span class="subitem-item">{{img.image.title}}</span> -->
													</div>
													<div class="subitem-item">{{img.image.title}}</div>
													<div class="subitem-item norms">
														<!-- <div>尺寸：{{img.image.norms}}</div> -->
														<div>{{$t('order_list.text7')}}<span v-if="img.option==0">{{img.image.ratio_w}} x {{img.image.ratio_h}} px</span><span v-if="img.option==1">{{img.image.min_w}} x {{img.image.min_h}} px</span><span v-if="img.option==2">{{img.image.max_w}} x {{img.image.max_h}} px</span></div>
														<div class="price">{{$t('order_list.text10')}}￥{{img.image_price}}</div>
													</div>
													<!-- <div class="subitem-item price"></div> -->

												</div>
											</div>
											<div class="subitem-order price">￥{{item.pay_price}}</div>
											<div class="subitem-order">
												<span>{{item.status==0?$t('order_list.wait_pay'):(item.status==1?$t('order_list.pay_success'):'')}}</span>
												<span>{{item.paytype==1?$t('order_list.package'):(item.paytype==2?$t('order_list.wechatPay'):(item.paytype==3?$t('order_list.alipay'):(item.paytype==0?'':'')))}}</span>
											</div>
											<div class="subitem-order operation">
												<span class="detail" @click="detail(item.id)">{{$t('order_list.text8')}}</span>
												<!-- <span class="detail" @click="ContractDownload(item.id)">合同下载</span> -->
												<a v-if="item.status==1 && item.contract_link" class="detail" style="text-decoration: none;" :href="item.contract_link" download>{{$t('order_list.text9')}}</a>
												<a v-if="item.status==1 && !item.contract_link" class="detail" style="text-decoration: none;" @click.stop="toContract(item.id)">{{$t('order_list.text9')}}</a>
												<!-- <a class="del-btn blue">删除</a> -->
											</div>
										</div>
									</div>
									
									<div class="item" v-for="item in OrderList" v-if="isMobile">
										<div class="subitem-head">
											<div class="subitem-left"><input type="checkbox" /></div>
											<div class="subitem-order">{{$t('order_list.text4')}}{{item.id}}</div>
											<div class="subitem-order">{{$t('order_list.text5')}}{{item.order_sn}}</div>
											<div class="subitem-order">{{$t('order_list.text6')}}{{parseTime(item.order_time)}}</div>
										</div>
										<div class="subitem-body">
											<div class="subitem-right images">
												<div class="subitem-item-outer" v-for="img in item.order_items">
													<div class="subitem-item">
														<img :src="img.image.preview" style="width:100%;height:100px;object-fit:contain;"/>
														<!-- <span class="subitem-item">{{img.image.title}}</span> -->
													</div>
													<div class="subitem-item">{{img.image.title}}</div>
													<div class="subitem-item norms">
														<!-- <div>尺寸：{{img.image.norms}}</div> -->
														<div>{{$t('order_list.text7')}}<span v-if="img.option==0">{{img.image.ratio_w}} x {{img.image.ratio_h}} px</span><span v-if="img.option==1">{{img.image.min_w}} x {{img.image.min_h}} px</span><span v-if="img.option==2">{{img.image.max_w}} x {{img.image.max_h}} px</span></div>
														<div class="price">{{$t('order_list.text10')}}￥{{img.image_price}}</div>
													</div>
													<!-- <div class="subitem-item price"></div> -->

												</div>
											</div>
											<div class="subitem-order price">￥{{item.pay_price}}</div>
											<div class="subitem-order">
												<span>{{item.status==0?$t('order_list.wait_pay'):(item.status==1?$t('order_list.pay_success'):'')}}</span>
												<span>{{item.paytype==1?$t('order_list.package'):(item.paytype==2?$t('order_list.wechatPay'):(item.paytype==3?$t('order_list.alipay'):(item.paytype==0?'':'')))}}</span>
											</div>
											<div class="subitem-order operation">
												<span class="detail" @click="detail(item.id)">{{$t('order_list.text8')}}</span>
												<!-- <span class="detail" @click="ContractDownload(item.id)">合同下载</span> -->
												<a v-if="item.status==1" class="detail" style="text-decoration: none;" :href="item.contract_link" download>{{$t('order_list.text9')}}</a>
												<!-- <a class="del-btn blue">删除</a> -->
											</div>
										</div>
									</div>
									
								</div>
								<div class="page-wrapper">
									<Loading :is-loading="isPicLoading"></Loading>
									<el-pagination 
									    background 
									    layout="prev, pager, next" 
									    :total="pagination.total" 
									    v-model:current-page="pagination.current"
									    v-model:page-size="pagination.pageSize"	
									    @current-change="handlePageChange"			    
									/>

								</div>
							</div>

						</div>
						
					</div>
				</div>
			</div>
		</div>
		<Footer/>
	</div>
</template>

<script>
	import order_list from './order_list_controller';
	export default order_list;
</script>

<style src="@/assets/css/my/order/order_list.css" scoped></style>