<template>
	<PageLoading :is-loading="isLoading"></PageLoading>
	<SideComponent @scrollto="scroll"></SideComponent>
	<div class="container" ref="viewBox">		
		<div class="header-container">
			<HeaderVR />
			<div class="bigimg-container">
				<Swiper :list="banner"/>
			</div>
		</div>
		<div class="content">
			<article class="article">
				<div class="wrapper">
					<!-- <div class="article-title">
						<span>{{detail.title}}</span>
					</div> -->
					<!-- <div class="article-img">
						<img :src="detail.thumb" />
					</div> -->
					<div class="article-content">
						<div v-html="detail.content"></div>
					</div>
				</div>
			</article>
		</div>
		<Footer/>
	</div>
</template>

<script>
	import article_detail from './article_detail_controller';
	export default article_detail;
</script>

<style src="@/assets/css/article_detail.css" scoped></style>