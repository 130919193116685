<template>
	<div class="menu-outer">
		<div class="photo-btn-outer">
			<div class="photo-btn" @click="toApply()"><i class="iconfont icon-shangchuan3"></i><span style="margin-left:5px;">{{$t('sideMenu.apply')}}</span></div>
		</div>
		<div class="menu-item" v-for="menu in sideMenus" @click.stop="clickMenu(menu)">
			<div style="width:100%;">
				<div class="border" :class="generateClass(menu)">  <!-- @click.stop="expand(menu)" -->
					<div class="menu-name">
						<i class="iconfont iconstyle" :class="[`${menu.icon}`]"></i>
						<span>{{menu.name}}</span>
					</div>

					<i class="iconfont icon-arrowdown" style="font-size:12px;" v-if="menu.children.length > 0 && menu.is_expand"></i>
					<i class="iconfont icon-arrowon" style="transform:rotate(90deg);color:rgb(150,150,150);font-size:12px;" v-if="menu.children.length > 0 && !menu.is_expand"></i>
					
				</div>
				<!-- <Transition :duration="{ enter: 200, leave: 200 }"> -->
					<div v-if="menu.children.length > 0 && menu.is_expand" class="child">
						<div v-for="childMenu in menu.children" class="menu-child-item" @click.stop="clickMenu(childMenu)">
							<div class="child-border" :class="generateClass(childMenu)">
								<i class="iconfont iconstyle" :class="[`${childMenu.icon}`]"></i>
								<span>{{childMenu.name}}</span>
							</div>
						</div>
					</div>
				<!-- </Transition> -->
			</div>
				
		</div>
	</div>
</template>
<script>
	import { defineComponent, ref, provide,reactive, inject, toRefs, getCurrentInstance } from 'vue';
	import { useRouter, useRoute } from 'vue-router';
	import { useStore } from 'vuex';
	import { HomeFilled, Menu as IconMenu } from '@element-plus/icons-vue'
	export default defineComponent({
		components: {

		},
		props: {

		},
		setup() {
			let {proxy} = getCurrentInstance();
			const router = useRouter();
	    	const route = useRoute();
	    	const store = useStore();
			const data = reactive({
				sideMenus: [
					{
						id:1,
						name: proxy.$t('sideMenu.account'),
						url:'setting',
						icon:'icon-shezhi',
						is_expand: false,
						is_show: true,
						level:1,
						children:[]
					},
					{
						id:2,
						name: proxy.$t('sideMenu.wallet'),
						url:'wallet',
						icon:'icon-qianbao',
						is_expand: false,
						is_show: true,
						level:1,
						children:[]
					},
					{
						id:3,
						name: proxy.$t('sideMenu.cart'),
						url:'cart',
						icon:'icon-gouwuche',
						is_expand: false,
						is_show: true,
						level:1,
						children:[]
					},
					{
						id:4,
						name: proxy.$t('sideMenu.authRecords'),
						url:'auth_records',
						icon:'icon-bijijilu',
						is_expand: false,
						is_show: true,
						level:1,
						children:[]
					},
					{
						id:5,
						name: proxy.$t('sideMenu.orderlist'),
						url:'order_list',
						icon:'icon-bijijilu',
						is_expand: false,
						is_show: true,
						level:1,
						children:[]
					},
					{
						id:12,
						name: proxy.$t('sideMenu.collectManage'),
						url:'',
						icon:'icon-bijijilu',
						is_expand: false,
						is_show: true,
						level:1,
						children:[
							{
								id:10,
								name: proxy.$t('sideMenu.collect'),
								url:'collect_work',
								icon:'icon-bijijilu',
								is_expand: false,
								is_show: false,
								level:2,
								children:[]
							},
							{
								id:11,
								name: proxy.$t('sideMenu.folder'),
								url:'collect_folder',
								icon:'icon-tuceng1',
								is_expand: false,
								is_show: false,
								level:2,
								children:[]
							},
						]
					},
					
					{
						id:6,
						name: proxy.$t('sideMenu.personCenter'),
						url:'',
						icon:'icon-bijijilu',
						is_expand: false,
						is_show: true,
						level:1,
						children:[
							{
								id:7,
								name: proxy.$t('sideMenu.applyInfo'),
								url:'apply_info',
								icon:'icon-bijijilu',
								is_expand: false,
								is_show: false,
								level:2,
								children:[]
							},
							{
								id:8,
								name: proxy.$t('sideMenu.workManage'),
								url:'work_manage',
								icon:'icon-bijijilu',
								is_expand: false,
								is_show: false,
								level:2,
								children:[]
							},
							{
								id:9,
								name: proxy.$t('sideMenu.recharge'),
								url:'recharge',
								icon:'icon-bijijilu',
								is_expand: false,
								is_show: false,
								level:2,
								children:[]
							},
						]
					}
					// {
					// 	id:6,
					// 	name:'我的收藏',
					// 	url:'favorites',
					// 	icon:'icon-shouchang',
					// },
				],
				activeMenuid: 1,
			});
			const getImageUrl = (name) => {
			    return proxy.$utils.getImageUrl(name);
			};
			const toApply = () => {
				store.dispatch("user/ApplyInfo", {

				}).then((res)=>{
					// console.log(res,"toApply");
					if(Object.keys(res).length > 0){
						
						if(res.status == 0){
							router.push({name:'apply_passing'})
						}else if(res.status == 1){
							GetApplyContract().then(data=>{
								if(Object.keys(res).length > 0){
									if(data.status == 1){
										router.push({name:'apply_work'})
									}else{
										router.push({name:'apply_pass'})
									}
								}else{
									router.push({name:'apply_contract'})
								}
								
							});
							
						}else if(res.status == 2){
							router.push({name:'apply_nopass'})
						}
						
						
					}else{
						router.push({name:'no_settled'})
					}
				}).catch((err)=>{
					ElMessage({
					    message: err.message,
					    grouping: true,
					    type: 'error',
					})
					router.push({name:'no_settled'})
				})
				
			}
			// 获取合同信息
			const GetApplyContract = () => {
				return new Promise((resolve, reject) => {
			        store.dispatch("user/GetApplyContract",{}).then(res => {
			        	// console.log(res,"GetApplyContract");
			        	resolve(res);
			        }).catch(err=>{
			        	reject(err);
			        })
			    })
			}

			const expand = (menu) => {
				if(menu.children.length > 0) {
					if(menu.is_expand){
						menu.is_expand = false;
						menu.children.map((item,index)=>{
							item.is_show = false;
							return item;
						})
					}else{
						for(let i = 0; i < data.sideMenus.length;i++){
							data.sideMenus[i].is_expand = false;
						}
						menu.is_expand = true;
						menu.children.map((item,index)=>{
							item.is_show = true;
							return item;
						})
					}
				}
			}
			
			const refData = toRefs(data);

			return {
				...refData,
				getImageUrl,
				toApply,
				GetApplyContract,
				expand,
			}
		},
		mounted() {
			this.$nextTick(() => {
				this.init();
			});
		},
		methods: {
			init(){
				// console.log(this.$router.currentRoute._value.name,'router name');
				// sessionStorage.setItem("sidebar_currentMenu", JSON.stringify(menu));
				let currentMenu = JSON.parse(sessionStorage.getItem("sidebar_currentMenu"));
				if(currentMenu && currentMenu.url == this.$router.currentRoute._value.name) {
					this.activeMenuid = currentMenu.id;

					if(currentMenu.level == 2){
						Loop1:
						for(let i = 0; i < this.sideMenus.length;i++){
							if(this.sideMenus[i].children.length > 0){
								Loop2:
								for(let j = 0;j < this.sideMenus[i].children.length;j++){
									if(this.activeMenuid == this.sideMenus[i].children[j].id){
										this.sideMenus[i].is_expand = true;
										break Loop1;
									}else{
										this.sideMenus[i].is_expand = false;
									}
								}
							}else{
								continue Loop1;
							}
							
						}
						
					}
					
				}else{
					this.recursion(this.sideMenus);
				}
			},
			clickMenu(menu) {
				// console.log(menu,"menu");
		  		this.activeMenuid = menu.id;
		  		sessionStorage.setItem("sidebar_currentMenu", JSON.stringify(menu));
		  		if(menu.children.length > 0) {
					if(menu.is_expand){
						menu.is_expand = false;
						menu.children.map((item,index)=>{
							item.is_show = false;
							return item;
						})
					}else{
						for(let i = 0; i < this.sideMenus.length;i++){
							this.sideMenus[i].is_expand = false;
						}
						menu.is_expand = true;
						menu.children.map((item,index)=>{
							item.is_show = true;
							return item;
						})
					}
				}
		  		// this.$router.push(menu.url)
		  		this.$router.push({name:menu.url});
		  	},
		    generateClass(menu) {
			    if(this.activeMenuid == menu.id){
			        return ['active'];
			    }else{
			    	return [''];
			    }
			    
		    },
		    recursion(menus){
		    	for(let i=0; i < menus.length; i++){
					if(menus[i].url == this.$router.currentRoute._value.name){
						this.activeMenuid = menus[i].id;
						break;
					}else if(menus[i].children.length>0){
						this.recursion(menus[i].children);
						
					}
				}
		    }

		},
	})
</script>
<style scoped>
	*, *::before, *::after {
	    -webkit-box-sizing: border-box;
	    box-sizing: border-box;
	}
	/*@counter-style circled-digits {
	  system: fixed;
	  symbols: 😀 👻 👽 👾 🤖 🎃 😹 👋 👀 💋;
	  suffix: " ";
	}*/


	.menu-outer {
		width:100%;
		/*min-height:300px;*/
		display: flex;
		flex-direction: column;
		padding:10px 5px;

	}
	.menu-outer .photo-btn-outer {
		width:100%;
		padding:30px 10px;
		display:flex;
		justify-content: center;
		align-items: center;
	}
	.menu-outer .photo-btn-outer .photo-btn {
		width:100%;
		color:white;
		background-color:rgb(230,0,15);
		border-radius:5px;
		display:flex;
		justify-content: center;
		align-items: center;
		padding:20px 10px;
		font-size: 18px;
		cursor:pointer;
	}
	.menu-outer .menu-item {
		width:100%;
		display: flex;
		flex-direction: row;
		padding:2px 0;
		border-bottom: 0.5px solid rgb(220,221,221);
	}
	/*.menu-outer .menu-item:last {
		border-bottom: 0px solid rgb(220,221,221);
	}*/
	.menu-outer .menu-item .border {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 10px 5px;
		border-left: 4px solid rgb(255, 255, 255);
	}
	.menu-outer .menu-item .border .menu-name {
		display: flex;
		flex-direction: row;
		
		/*padding: 10px 5px;*/
		/*border-left: 4px solid rgb(255, 255, 255);*/
	}
	.menu-outer .menu-item .active {
		border-left: 4px solid rgb(230,0, 18);
		background: rgba(230,0, 18, 0.3);
		color: rgb(230,0, 18);
	}
	.menu-outer .menu-item .border span{
		padding-left: 5px;
		font-size: 14px;
		/*font-weight: bold;*/
	}
	.menu-outer .menu-item .border:hover {
		cursor: pointer;
	}
	.menu-outer ul li {
		/*list-style-type: space-counter;*/
	}
	.iconstyle {
		font-size:14px;
		display:flex;
		align-items: center;
	}
	.menu-outer .menu-item .child {
		transition: all 1s ease-in-out;
	}
	.menu-outer .menu-item .child .menu-child-item {
		width:100%;
		display: flex;
		flex-direction: row;
		padding:2px 0 2px 10px;
		border-top: 0.5px solid rgb(220,221,221);
	}
	.menu-outer .menu-item .child .menu-child-item .child-border {
		width: 100%;
		display: flex;
		flex-direction: row;
		padding: 10px 5px;
		border-left: 3px solid rgb(255, 255, 255);
	}
	.menu-outer .menu-item .child .menu-child-item .active {
		border-left: 3px solid rgb(230,0, 18);
		background: rgba(230,0, 18, 0.3);
		color: rgb(230,0, 18);
	}
	.menu-outer .menu-item .child .menu-child-item .child-border span{
		padding-left: 5px;
		font-size: 14px;
		/*font-weight: bold;*/
	}
	.menu-outer .menu-item .child .menu-child-item .child-border:hover {
		cursor: pointer;
	}
	
	.v-enter-active,
	.v-leave-active {
	  transition: opacity 0.2s ease;
	}

	.v-enter-from,
	.v-leave-to {
	  opacity: 0;
	}

</style>