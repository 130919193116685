// import Search from '@/components/Search.vue'
import { nextTick, onMounted, defineComponent, ref, provide, reactive, toRefs,getCurrentInstance } from 'vue';
import { useStore, createNamespacedHelpers } from "vuex";
import PageLoading from '@/components/common/PageLoading.vue';
import Swiper from '@/components/layout/Swiper.vue';
import SearchBarVideo from '@/components/layout/SearchBarVideo.vue';
import Header2 from '@/components/layout/header2.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';
import bus from 'vue3-eventbus'
import SideComponent from '@/components/common/SideComponent.vue';
import Loading from '@/components/common/Loading.vue';

export default defineComponent({
	components: {
		PageLoading,
		Header2,
		Menu,
	    Swiper,
	    SearchBarVideo,
	    Footer,
	    SideComponent,
	    Loading,
	},
	setup(){
	    // 写法一 用了reactive，就不需要写ref
	    // const data = reactive({
	    // 	checkList: ['selected and disabled', 'Option A']
	    // })
	    provide('logoColor', 'white');
	    let {ctx, proxy} = getCurrentInstance();

	    const data = reactive({
	    	isLoading:true,
	    	active_menuid: 0,
			activeClass: 'active',
			isMobile: false,
      		show_menu: false,
      		active_tagid: 'pic',
      		ispic: true,
      		banners:[
      			// "img/topic/video/topic_video_1.jpg",
      			// "img/banner/2.jpg",
      			// "img/banner/3.jpg",
      		],
      		videoCats: [

      		],
      		videos:[],
      		page:1,
      		nomore:true,
      		keyword:'',
      		isPicLoading: true,
	    });

	    const getImageUrl = (name) => {
            return proxy.$utils.getImageUrl(name);
        };
        const store = useStore();
       
        const getVideo = (keyword='',page=1) => {
        	store.dispatch("common/getPicLists",{
	        	page: page,
	        	filter: keyword,
	        	type: 2,
	        }).then((res) => {
	        	console.log(res.data,"video");
	        	if(res.data.length > 0){
	        		if(page == 1){
	        			data.videos = res.data;
	        		}else{
	        			data.videos = data.videos.concat(res.data);
	        		}
					
					if(res.data.length < res.pagination.pageSize){
						data.nomore = true;
					}else{
						data.nomore = false;
					}
				}else{
					data.nomore = true;
				}      
				data.isPicLoading = false;  
	        	// data.pagination = res.pagination;
	        	data.page += 1;
	        	bus.emit('showFooter',true);
	        }).catch(err=>{
	        	data.isPicLoading = false;  
	        })
        }
        
        const viewBox = ref(null);
        const scroll = (val) => {
			if(val == 'top'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		       
			}else if(val == 'bottom'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		        	
		        	
		        
			}
		}

        onMounted(async()=>{
        	 store.dispatch("common/fetchSiteInfo").then((res) => {
	            data.banners = res.sliders;
	            bus.emit('isLoading',false);
	        }).catch(err => {
	            console.error(err)
	        })
	        getVideo();
        	await nextTick(()=>{
        		bus.on("scrollTotop",e => {
		        	if(e){ 
						viewBox.value.scrollIntoView({
					        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
					        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
					        inline: "center", // 定义水平滚动方向的对齐
					    });
		        	}
		        	
		        });
		 		bus.on("scrollToBottom",e => {
		        	if(e){
						viewBox.value.scrollIntoView({
					        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
					        block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
					        inline: "center", // 定义水平滚动方向的对齐
					    });
		        	}
		        	
		        });
        	})
        	setTimeout(() => {
		      data.isLoading = false;
		      viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		    }, 600);
		    console.log("页面数据更新完毕了")
        })

	    const showMenus = ref(proxy.$menus);
		provide('showMenu', showMenus);

		const refData = toRefs(data); 
	    return {
	        ...refData,
	        getImageUrl,
	        getVideo,
	        viewBox,
	        scroll,
	    }
	},

	computed: {

	},
	watch(){

	},
	beforeCreate() {

	},
	created() {
		let that = this;
		this.$nextTick(() => {
			// SiteInfo().then((data)=>{
			// 	console.log(data, "siteinfo");	
			// 	that.banners = data.sliders;
			// 	// that.siteInfo = data.map((item)=>{
					
			// 	// 	return item;
			// 	// });
			// 	// that.banners = that.siteInfo.sliders			
				
			// }).catch((err)=>{

			// });
	    });
	},
	beforeMount() {

	},
	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	},
	beforeUpdate() {

	},
	updated() {

	},
	beforeUnmount() {

	},
	unmounted() {

	},
	methods: {
		init() {
			if(document.documentElement.clientWidth < 768){
				// document.getElementsByClassName("menu-container").display = 'none';
				this.isMobile = true;
			}else{
				// document.getElementsByClassName("menu-container").display = 'flex';
				this.isMobile = false;
			}
		},
		activeStyle(item) {		
			
			let object = {};
			object.width = item.ratio_w*200/item.ratio_h+'px';
			// object.width = '200px';
			return object;
		},
		handleMouseEnter(event) {
	      // console.log(event.target,'鼠标移入');
	      event.target.play();
	    },
	    handleMouseLeave(event) {
	      // console.log(event.target,'鼠标移出');
	      event.target.pause();
	    },
		loadMore() {
			let that = this;
			this.isPicLoading = true; 
			this.getVideo(this.keyword,this.page);
			
		},
		toDetail(id){
			// this.$router.push({ path:'/topic_video_detail',query:{id:id} });
			let href = this.$router.resolve({ //使用resolve
		      name:'topic_video_detail',    //这里是跳转页面的name
		      path: '/topic_video_detail',
		      query:{ id: id },
		    })
		    window.open(href.href, '_blank')
		},
	    clickTag(data){
	  		this.active_tagid = data.name;
	  		if(this.active_tagid == 'pic'){
	  			this.ispic = true;
	  			// this.placeholder = '搜图片';
	  		}else if(this.active_tagid == 'video'){
	  			this.ispic = false;
	  			// this.placeholder = '搜视频';
	  		}
	  	},
	    generateTagClass(data){
		    if(this.active_tagid == data.name){
		        return ['tabitem','active-tag'];
		    }
		    return ['tabitem'];
	    },
	    
	},
	beforeDestory() {

	},
	destoryed() {

	}
})