<template>
  <div class="swiperbox"  v-if="type=='img'">
    <Loading :is-loading="isLoading"></Loading>
    <Header2 v-if="showHeader"/>

    <swiper 
      :modules="modules"
      :slides-per-view="slides"    
      spaceBetween="30"
      loop    
      :pagination="{clickable: true}"
      :autoplay="{ delay: 4000,stopOnLastSlide: false, disableOnInteraction: false }"          
      class="swiperStyle"   
    >
      <swiper-slide class="slide-style" v-for="(banner,index) in list">
        <img :src="banner.thumb" v-if="!limitHeight" alt="" class="slide-img" oncontextmenu="return false"/>
        <img :src="banner.thumb" style="" v-if="limitHeight" alt="" class="slide-img long-img" oncontextmenu="return false" @click.stop="goNet(banner.url)"/>
      </swiper-slide>
    </swiper>

  </div>
  <div class="swiperbox swiperbox-expand" v-if="type=='bb'">
    <Loading :is-loading="isLoading"></Loading>
    
    <swiper
      :modules="modules"
      :slides-per-view="slides"          
      spaceBetween="0"    
      loop
      :autoplay="{ delay: 4000,stopOnLastSlide: false, disableOnInteraction: false}"
      :navigation="navigation"  
      :rewind="true"  
      :breakpoints="breakpoints"
    >
      <swiper-slide class="bbitem" v-for="item in list" @click="toArticleList(item)">
        <div class="bb-item-outer">
          <div class="bb-subitem-img">
            <img :src="item.thumb" oncontextmenu="return false" />
          </div>
          
          <div class="bb-subitem-text">             
            <div v-html="item.desc" style="text-align: justify;"></div>
          </div>
        </div>
      </swiper-slide>
      
    </swiper>
  
  </div>
</template>

<script setup>
  import { ref, provide, getCurrentInstance, inject, watch } from 'vue';
  // Import Swiper and modules
  // import Swiper from 'swiper'  //需要配合new Swiper()创建
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import { Autoplay, Navigation, Pagination, Scrollbar, A11y, EffectFade } from 'swiper/modules';
  import 'swiper/css'
  import 'swiper/css/navigation';
  import 'swiper/css/pagination';
  import 'swiper/css/effect-fade';
  import { getImageUrl } from '@/utils/utils';
  import Loading from '@/components/common/Loading.vue';
  import bus from 'vue3-eventbus';
  import { useRouter, useRoute } from 'vue-router';

  const router = useRouter();

  const props = defineProps({
    marginTop: String,
    list: {
      type:Array,
      default:[],
    },
    slides:{
      type:Number,
      default:1,
    },
    showHeader: {
      type:Boolean,
      default:true,
    },
    type: {
      type:String,
      default:'img',
    },
    limitHeight: {
      type:String,
      default:false,
    },
    // isLoading:{
    //   type:Boolean,
    //   default:false,
    // },
  })
  console.log(props.slides,"props.slides");
  // const swiperBoxStyle = inject('swiperBoxStyle','');
  const navigation = ref({
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  });
  const breakpoints = ref({
    320: {
        slidesPerView: 2,
        spaceBetween: 0
    },
    // when window width is >= 480px
    480: {
       slidesPerView: 3,
       spaceBetween: 0
    },
    // when window width is >= 640px
    640: {
        slidesPerView: 4,
        spaceBetween: 0
    },
    '@0.75': {  //当屏幕宽高比大于等于0.75
        slidesPerView: 2,
        spaceBetween: 0,
    },
    '@1.00': {  //当屏幕宽高比大于等于1
        slidesPerView: 3,
        spaceBetween: 0,
    },
    '@1.50': {  //当屏幕宽高比大于等于1.5
        slidesPerView: 4,
        spaceBetween: 0,
    }
  });

  let isLoading = ref(true);
  bus.on("isLoading",e=>isLoading=e);

  // 在modules加入要使用的模块
  const modules = [Autoplay, Pagination, Navigation, Scrollbar, A11y, EffectFade]
  // let slides = ref(1);
  //  const { proxy } = getCurrentInstance()
  // // console.log(proxy.$user)
  // if(proxy.$utils.isMobile()){
  //   slides = ref(1.5);
  // }
  const pagination = ref({
    el: '.swiper-pagination',
    clickable: true,

    // renderBullet: function (index, className) {
    //       let str = '<span class="' + className + '">' + (index + 1) + '</span>';
    //       if(index == 0){
    //         str = '<span class="' + className + ' swiper-pagination-bullet-active">' + (index + 1) + '</span>';
    //       }
    //       return str;
    //     },
  }) 
  const list = ref([]);
  const slides = ref(1);
  const type = ref('');
  watch(() => [props.list,props.slides,props.type],([newval,newSval,newTval],[oldval,oldSval,oldTval]) => {
    console.log(newval,"newval")
    list.value = newval;
    slides.value = newSval;
    type.value = newTval;
  },{
    // immediate: true
  })
  const prevEl = (item, index) => {
    console.log('上一张' + index + item)
  };
  const nextEl = () => {
    console.log('下一张')
  };
  // 更改当前活动swiper
  const onSlideChange = (swiper) => {
  // swiper是当前轮播的对象，里面可以获取到当前swiper的所有信息，当前索引是activeIndex
    console.log(swiper.activeIndex)
  }
  const slidsWidth = (showHeader) => {
    if(!showHeader) {
      return '';
    }
    return '';
  }
  const toArticleList = (item) => {
    router.push({name:'article_list', query:{id: item.id,type:item.type}});
  }
  const goNet = (picUrl) => {
    // location.href = picUrl;
    window.open(picUrl, '_blank');
  }

  const activeClass = (index) => {
    return 'swiper-pagination-bullet-active';
  };

</script>
<style scoped>
  :root {
    /*--swiper-theme-color: #f84949;*/
  }
  *, *::before, *::after {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
  }
  /*:deep(.swiper) {
    position:static;
  }*/
    
  /*background: #00b5e5;蓝色*/
  .swiperbox {
    width:100%;  
    margin:0px auto;
    position: relative;
    box-sizing: border-box;
    margin-bottom:20px;
    height:100%;
    display:flex;
    
  }
  
  @media screen and (max-width: 768px) {
    .swiperbox {
      /*height:200px;*/
      margin-bottom:0px;
      
    }
    .swiperbox-expand {
        padding:5px 0;
      }
  }
  :deep(.swiper){
    width:100%;
  }
  /*@media screen and (max-width: 576px) {
    .swiperbox {
      height:200px;
    }
  }*/
  :deep(.swiper-slide) {
    height:auto;
  }
  .swiperStyle {
    height:100%;
    /*max-height:480px;*/
    
  }
  /*.swiperStyle1 {
    height:100%;
    
  }*/
 
  :deep(.slide-img) {
    width:100%;
    min-width:375px;
    /*height:480px;*/
    height:100%;
    max-height:368px;
    min-height: 368px;
    /*overflow-clip-margin: content-box;
    overflow: clip;*/
  }
  @media screen and (max-width: 767px) {
    :deep(.slide-img) {
      height:100%;
      max-height:170px;
      min-height: 170px;
    }
  }

  :deep(.swiper-button-prev) {
    color:white!important;
  }
  :deep(.swiper-button-next) {
    color:white!important;
  }
  :deep(.swiper-pagination-bullet) {
    background:var(--swiper-pagination-bullet-inactive-color, #fff);
    opacity: 1.0;
  }
  :deep(.swiper-pagination-bullet-active) {
    opacity: 1.0;
    background: #fff;/*var(--swiper-pagination-color, var(--swiper-theme-color));*/
    width:30px;
    border-radius:10px;
  }
  
  .long-img {
    height:181px!important;max-height: auto!important;min-height:auto!important;
  }

  @media screen and (max-width: 767px) {
    .long-img {
      height:60px!important;max-height: auto!important;min-height:auto!important;
    }
  }

  .bbitem {
    display:flex;
    flex-direction:column;
    padding:0px;
    width:100%;
    height:100%;
    /*height:352px;*/
  }
  
  .bbitem .bb-item-outer {
    padding:12px 8px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .bbitem .bb-subitem-img {
    width:100%;
    height:238px;
  }
  @media screen and (max-width:768px) {
    .bbitem .bb-subitem-img {
      width:100%;
      height:150px;
    }
  }
  .bbitem .bb-subitem-img img{
    width:100%;
    height:100%;
    object-fit: cover;
  }
  .bbitem .bb-subitem-text {
    height:96px;
    padding:10px 10px;
    font-size:13px;
    box-shadow:2px 5px 8px rgb(206,204,205);
    line-height: 23px;
  }
  @media screen and (max-width:768px) {
    .bbitem .bb-subitem-text {
      /*height:100%;*/
      height:85px;
      overflow: hidden;
      text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
  }

  /* 排除移动设备 */
  @media only screen and (min-width: 768px) {
    /* 在这里编写排除移动设备的 CSS */   
    .arrow-style{
      font-size: 50px;
      color: #fff;
    }

  }
  
  /* 适用于所有移动设备 */
  @media screen and (max-width: 767px) {
    /* 在这里编写适用于移动设备的 CSS */
    .arrow-style{
      font-size: 150px;
      color: #fff;
    }

  }
</style>
