export default {
    login: {
        login: 'Login',
        userName: 'UserName',
        password: 'Password',
        name: 'Username or company name',
    },
    captcha: 'Captcha',
    forgetPassword: 'Forget Password?',
    loginTip: 'The login result is random. Just fill in the captcha',
    editpassword: 'Edit Password',
    errMsg: {
        inputRequired: 'Please Input {cont}',
        selectRequired: 'Please Select {cont}',
    },
    uernameTips: 'For individual users, please fill in the username, and for corporate users, please fill in the company name',

    title: 'Hi!Qingdao',
    logo_small_text:'Qingdao News Center',
    hello: 'Hello, ',
    loginOrRegister: 'Login / Register',
    anonymity: 'Anonymous User',
    personCenter:'Personal Center',
    logout:'Logout',
    toLogin: 'Please log in first',
    mobileTip: 'Mobile',
    submitText: 'Submit',
    accountTip: 'Username/Mobile',
    password: 'Password',
    newPassword: 'New Password',
    confirmPassword: 'Confirm new password',
    noAccount: 'Don\'t have an account yet?',
    hasAccount: 'Already have an account?',
    register: 'Register',
    phoneNumber: 'Phone Number',
    email: 'Email',
    organizationName: 'The name of the organization',
    loginNow:'Log in now',
    accountLogin: 'Account login',
    loginSuccess: 'Login Success',
    loginFailed: 'Login failed',
    registerSuccess: 'Register Success',
    registerFailed: 'Register Failed',
    enterUsername: 'Please enter username',
    enterRealname: 'Please enter real name',
    enterCorrectUnitName: 'Please enter the correct organization name',

    enterCorrectUnitName: 'Please enter the correct organization name',
    enterCorrectIdcard: 'Please fill in the correct ID number',
    enterCorrectInstitutionName: 'Please fill in the correct institution number',

    enterPhoneNumber: 'Please enter your mobile phone number',
    enterCorrectPhoneNumber: 'Please enter the correct mobile phone number',
    enterCorrectEmail: 'Please enter the correct email',
    enterCorrectUnitName: 'The name of the organization can be a combination of Chinese characters or letters or numbers',
    enterCorrectUsernameAndPhone: 'Please enter your username/phone number',
    nameContain: 'The username must contain letters or numbers',
    passwordContain: 'The password requires a combination of at least 8 digits, letters, and special symbols (! # $% ^&*? - _)',
    passwordContain2: '密码需要至少8位数字、字母、特殊符号!@#$%^&*?-_组合',
    enterPassword: 'Please enter password',
    passwordLength: 'Password length should be at least 8 digits',
    qrlogin: 'Scan the QR code to log in',
    updateSuccess: 'The update was successful',
    retrievePassword: 'Retrieve your password',
    pageUpdated: 'The page data has been updated',

    cart1: 'Shopping Cart',
    onlineService: 'OCS',
    newsCenter: 'Operated and maintained by Qingdao International News Center',//Operation and maintenance of   International
    qdnews: 'Qingdao International News Center', //International
    hostUnit: 'Sponsored by:',
    contactPhone: 'Phone',
    workTime: 'Business Hours',
    footerText1: 'Abundant high-resolution materials',
    footerText2: 'Copyright available for commercial use',
    aboutus: 'About Us',
    copyrightNotice: 'Copyright Notice',
    questions: 'FAQ',
    terms: 'Terms & Conditions',
    selfRegulatory: 'Website Platform Self-discipline',
    contractUs: 'Contact Us',
    friendLink:'Friend Links',
    followUs: 'Follow us',
    copyright:'Qingdao Picture Library',
    ICP: 'Lu ICP Bei No. 2023012351-1',
    category:'Category',
    pv:'Total Views:',
    uv:'Total Registrations:',
    qrcode:'Scan code to log in',
    loadmore: 'Load More',
    theEnd: 'The End',

    codeLogin:'Verification code login',
    getCode:'Obtain verification code',
    enterCode:'Please enter the verification code',
    codetip:'Code',
    viewmore:'View More',

    menus: {
        home: 'Home',
        news: 'Video News',
        pic: 'Photos',
        video: 'Videos',
        vr: 'Digital museum',
        grapher: 'Photographers',
        active: 'Activity',
        more: 'More Services',
        package: 'Price Package',
        subject: 'Subject',
    },
    contract:{
        tip1:'Supplementary contract information',
        tip2:'Contract signature',
        tip3:'Contract download',
        tip4:'In order to ensure the validity of the contract and subsequent protection, please fill in the real personal information, the later purchase will automatically use the information.',
        tip5:'Order ID',
        tip6:'Please enter the order ID',
        tip7:'Data type',
        tip8:'Individual user',
        tip9:'Enterprise organization',
        tip10:'Real name',
        tip11:'Please enter your real name',
        tip12:'Id number',
        tip13:'Please enter your ID number',
        tip14:'Organizational structure',
        tip15:'Please enter organization',
        tip16:'Organization number',
        tip17:'Please enter the organization number',
        tip18:'mailbox',
        tip19:'Please enter email',
        tip20:'Generate contract templateGenerate contract template',
        tip21:'Please hand-write your signature by holding down the left mouse button in the red border area below',
        tip22:'Generate signature',
        tip23:'Clear the drawing board',
        tip24:'Download contract',
        tip25:'Please select a user type',
        tip26:'Please enter your real name',
        tip27:'Please enter your ID number',
        tip28:'Please enter the organization name',
        tip29:'Please enter the organization number',
        tip30:'Please enter email',
        tip31:'Failed to save contract data',
        tip32:'Failed to obtain contract information',
        tip33:'Upload failure',

    },
    recharge:{
        tip1:'Income profile',
        tip2:'Revenue overview',
        tip3:'Unsettled amount (Yuan)',
        tip4:'Settled amount (Yuan)',
        tip5:'Apply for settlement',
        tip6:'Order details',
        tip7:'Title of work',
        tip8:'Time of payment',
        tip9:'Order amount',
        tip10:'Share income',
        tip11:'Order Number:',
        tip12:'Settlement record',
        tip13:'Claim settlement time',
        tip14:'Settlement progress',
        tip15:'Entry time',
        tip16:'Settlement amount',
        tip17:'To be settled',
        tip18:'Calculating result',
        tip19:'Have already settled',
        
    },

    search: {
        search: 'Search',
        hotSearch: 'Hot Search',
        takePic: 'Take a picture search',
        dragPic: 'Drag and drop the image anywhere in this area',
        or: 'Or',
        clickUpload: 'Click Upload',
        sizeLimit: 'Use jpg/png files that are less than 50MB in size',
        searchPic: 'Search Pic',
        searchVideo: 'Search Video',
        pic: 'picture',
        video: 'video',
        trySearch:'You can try searching for keywords',
        scenery: 'Scenery',
        building: 'Building',
        culture: 'Culture',
        humanities: 'Humanities',
        educate: 'Educate',
        isJPG:'Uploaded images can only be in JPG or PNG format!',
    },
    sideMenu: {
        apply: 'Photographer Feed',
        account: 'Account Management',
        wallet: 'My Wallet',
        cart: 'Shopping Cart',
        authRecords: 'Auth Records',
        orderlist:'Order List',
        collectManage: 'Activity Solicitation',
        collect: 'Activity Works',
        folder: 'Event Collection',
        personCenter: 'Photographer Center',
        applyInfo: 'Occupancy Information',
        workManage: 'My work',
        recharge: 'Earnings Center',
    },
    person: {
        personCenter: 'Creator Center',

    },
    application: {
        settled: 'Photographers settle in',
        process: 'Onboarding process',
        plain: 'Move-in instructions',
        apply: 'Submit an application',
        result: 'Submit the results',
        tipText1: 'Welcome to apply to become a creative photographer of Qingdao Photo Library, in order to improve your success rate, please fill in the registration information correctly.',
        tipText2: 'We will reply to your application within 3 working days, and you can check the progress here',
        apply_enter: 'Apply for entry',
        base_info: 'Please fill in the basic information',
    },
    form: {
        user_type: 'User Type',
        license: 'Business License',
        licenseTip: 'Electronic version of the stamped business license of the unit (or electronic version of the stamped organization code certificate)',
        type1: 'persons',
        type2: 'Unit organization',
        unitName:'Unit Name',
        unitTip:'Please enter the name of the organization',
        realname: 'Real name',
        enterRealname: 'Please enter your real name',
        gender: 'gender',
        male: 'male',
        female: 'female',
        photo: 'Personal photo',
        enterPhoto: 'please upload a personal photo',
        uploadPhoto: 'Upload a photo',
        biography: 'Introduction',
        enterBiography: 'Please fill in the introduction',
        mobile: 'Phone Number',
        enterPhone: 'Please enter your mobile phone number',
        email: 'Email',
        enterEmail: 'Please enter your email address',
        company: 'Unit Name',
        enterCompany: 'Please enter the name of the organization',
        ctype: 'Type of Practice',
        enterFeedType: 'Please select a feed type',
        media: 'Media',
        non_media: 'Non-media',
        worktype: 'Feed type',
        image: 'Image',
        video: 'Video',
        workPic: 'The picture represents the work',
        workPicTip: '(The representative works are only for reference in the entry review, and uploading high-quality original representative works is conducive to improving the success rate of the entry)',
        uploadPic: 'Upload an image',
        againUploadPic: 'Continue uploading pictures',
        againUploadVideo: 'Continue uploading video',
        limit_pic_quantity: '(Please provide 10 original images)',
        workVideo: 'The video represents the work',
        uploadVideo: 'Upload the video',
        limit_video_quantity: '(Please provide 3 original videos)',
        submit_apply: 'Submit an application',
        tip_text_1: 'Your onboarding information is under review...',
        tip_text_2: 'We will review your entry information within 3 working days (in case of national holidays, the time will be postponed)',
        tip_text_3: 'You can check the results of the review on this page!',
        uploadPicWork: 'Please upload a photo',
        uploadVideoWork: 'Please upload your video work',
        enter_limit_pic: 'Please upload 10 images',
        enter_limit_pic: 'Please upload 3 videos',
        submited: 'The application has been submitted',
        only_ten_pic: 'Only 10 images can be uploaded',
        only_three_video: 'Only 3 videos can be uploaded',
        idcard: 'Identification number',
        idcard_photo: 'Photo of ID card',
        enter_idcard: 'Please enter your ID number',
        idcard_tip: '(Please upload your ID card avatar)',
        bankcard: 'Bank card number',
        enter_bankcard: 'Please enter your bank card number',
        bankcard_photo:'A photo of your bank card',
        bankcard_tip:'(Please upload a photo with your bank card number)',
        preview:'Submit and preview the contract',
        enterCorrectbankcardNumber: 'Please fill in the correct card number',
        enterIdcardPhoto: 'Please upload the front of your ID card',
        enterBankcardPhoto: 'Please upload the front of your bank card',

    },
    apply_contract:{
        info: 'Contract Information',
        sign: 'Contract Signature',
        tip1: 'In order to ensure the legal rights of both parties, please enter your identity information and bank card information to generate an electronic contract, and the contract will be signed successfully after the signing is completed.',
        tip2: '*The following information is only used for contract signing and revenue settlement, and cannot be changed after the contract is signed, please fill it in carefully!',
        tip3: 'In order to ensure the legal rights of both parties, please read the content of the contract carefully, and it cannot be modified after the signing is completed',
        tip4: 'Please fill in the contract information',
        tip5: 'Contract preview',
        tip6: 'Please hold down the left mouse button in the red border area below to handwrite your signature',
        tip7: 'Generate signatures',
        tip8: 'Clear the artboard',
        signSuccess:'Signature successful',
        noMatch:'Identity information mismatch',
        submit_success:'Submitted successfully',
        idcard_face:'Please upload (or re upload) the front photo of your ID card',
        upload_fail:'Upload failed',

    },
    apply_info: {
        tip1:'You are welcome to settle in Qingdao Photo Library, you can upload your works, and you will have the opportunity to earn income after passing the review~',
        tip2:'Occupancy information',
        tip3:'Click to view contract',
        tip4: 'Electronic Contracts',
    },

    apply_nopass: {
        tip1:'Your application has not been approved...',
        tip2:'Reason: After a comprehensive evaluation of your submitted work by experts, you do not meet our entry requirements!',
        tip3:'You can try to submit a more high-quality representative work, and we will conduct a comprehensive evaluation again~',
        tip4:'Resubmit',
    },
    apply_pass: {
        tip1: 'Congratulations, your application has been approved!',
        tip2: 'After the good information, you can upload your work and have a chance to earn manuscript fees~',
        tip3: 'Sign up for submissions',
    },

    apply_passing: {
        tip1:'Your onboarding information is under review...',
        tip2:'We will review your entry information within 3 working days (in case of national holidays, the time will be postponed)',
        tip3:'You can view the results of the review on this page',
    },
    apply_common: {
        del:'Delete this item',
        addimg:'Add an image',
        addvideo:'Add a video',
        batch_pics:'Fill in the unified information of batch images',
        batch_videos:'Fill in the unified information of batch videos',
        search_subject:'Topic name search',
        upload_success:'The upload is successful and is waiting for review',
        upload_pic_eight:'Upload up to 8 images at a time',
        upload_video_eight:'Upload up to 8 videos at a time',
        size_exceed:'The size cannot exceed 50M',
        submit_success:'The submission was successful',
        agreement:'I have read and agreed to the "Qingdao Photo Library Content License Agreement".',
        protocols:'"Qingdao Photo Library Content License Agreement".',
        read:'Please read and agree to the “Qingdao Photo Library Content License Agreement” first.',

        selectAll: 'Select All',
        off_pic: 'After clicking on the removal, the image will be automatically deleted from the website, are you sure to remove it?',
        del_pic: 'After clicking Delete, the image will be deleted from the website, are you sure to delete it?',
        batch_off_pic: 'Batch off the shelves',
        batch_del_pic: 'Batch deletion',
        season: 'Reason:',
        edit: 'Edit',
        off: 'Take down',
        del: 'Delate',
        off_video: 'The video will be automatically removed from the site after clicking take down, sure about taking it down?',
        del_video: 'The video will be deleted from the website after clicking Delete, sure about deleting?',
        off_item: 'Please select the option you want to downgrade',
        had_off: 'The work has been taken down',
        del_item: 'Please select the options to be deleted',
        had_del: 'The work has been deleted.',
        del_tip: 'Click Delete and then unrecoverable, sure to delete?',
        edit_folder:'Editorial Compilation',
        add_folder:'Add Compilation',
        enter_folder_title: 'Please enter a compilation title',
        enter_keyword_tip:'For each keyword you enter, press the Enter key once',
        add_to_folder:'Add to Compilation',
        select_folder:'Please select a compilation',
        author:'Author',
        enter_author:'Please enter the author',
        create_date:'Date of creation',
    },
    apply_work: {
        year_limit: 'Year can only be a number',
        tip0:'Image feed',
        tip1:'Sorry, you didn\'t request image permissions',
        tip2:'Tips: If you need to submit pictures, please re-apply for a contract',
        tip3:'Re-contract',
        tip4:'Please select one or more images that support multiple image formats',
        tip5:'Image Name',
        tip6:'Please enter a name for the image',
        tip7:'Image Captions',
        tip8:'Please enter a caption',
        tip9:'Keywords',
        tip10:'Please enter keywords',
        tip11:'Topic name',
        tip12:'Please select a topic',
        tip13:'Add Topic',
        tip14:'The status of the small graph',
        tip15:'Small image price',
        tip16:'Please enter the price of the small image',
        tip17:'The status of the original image',
        tip18:'The price of the original image',
        tip19:'Please enter the price of the original image',
        tip20:'The status of the big picture',
        tip21:'Big picture price',
        tip22:'Please enter a larger image price',
        tip23:'enable',
        tip24:'disable',
        tip25:'Submit for review',

        tip26:'Video Feed',
        tip27:'Sorry, you didn\'t request video permissions',
        tip28:'Tips: If you need to submit a video, please re-apply for a contract',
        tip29:'Please select one or more videos to support multiple video formats',
        tip30:'Video Name',
        tip31:'Please enter a name for the video',
        tip32:'Video description',
        tip33:'Please enter a video description',
        tip34:'Topic Name',
        tip35:'Please enter a topic name',
        tip36:'Thematic classification',
        tip37:'Please select a thematic category',
        tip38:'Thematic covers',
        tip39:'Thematic descriptions',
        tip40:'Please enter a description of the topic',
        tip41:'Submit',
        tip42:'Please upload a feature cover',

        more:'Load More',

        price_pack: 'Price Package',
        select_price_pack: 'Please select a price package',
        pic_limit: 'Upload less than 8 images',
        video_limit: 'Upload less than 8 videos',
        topic_price_pack: 'Thematic cover price package',
        year:'Year',
        yearTip:'Please enter a year',
        coverTip: 'This is just a cover image, it needs to be resent in the image submission',
        has_similar: 'Please do not upload duplicate image works',
        similar: 'Duplicate images',
    },
    no_settled: {
        tip1:'Sorry, but you can only submit after you settle in successfully~',
        tip2:'You are not yet certified as an official photographer',
        tip3:'Settle In',
    },
    index: {
        hot: 'Top recommended images',
        grapher: 'Excellent photographer',
        image: 'Images',
        video: 'Videos',
    },
    news: {
        news:'News',
        publish_time: 'publish time:',
    },
    package: {
        name: 'Package name',
        content: 'Exclusive Content Benefits',
        expire: 'Term',
        day: 'day',
        buy: 'Purchase a package',
        between: 'Scope of permissions',
        min: 'Small image',
        original: 'Original image',
        max: 'Big image',
        tip1: '￥1500/branch',
        tip2:'￥2200/branch',
        tip3: 'Sweep to pay',
        order_sn: 'Order Number:',
        price:'Order Amount:',
        open:'Open',
        scan: 'Scan the QR code to pay',
        wechatPay: 'WeChat Pay',
        alipay: 'Alipay payment',
        weixin: 'Wechat',
        ali:'Alipay',
        select:'Please select a package',
        tip4: 'Image',
        tip5: 'A single video',
        tip6: 'Video packages',
    },
    grapher: {
        title: 'Photographer',
        home:'Home',
        grapher:'Excellent photographer',
        tip1:'(The ranking is in no particular order for surnames)',
        tip2:'Contracted photographer of Qingdao Photo Library',

    },
    search_pic:{
        tip1:'Similar images',
        tip2:'find about',
        tip3:'articles records',
    },
    topics:{
        tip1:'Related topics',
        home:"Home",
    },
    pic:{
        tip1:'You are here:',
        tip2:'Illustrate:',
        tip3:'Royalty-Free (RF) is licensed, and the content licensed from the non-specific use scope can be used indefinitely and permanently, priced according to the file size, and there is no watermark after downloading. For more information, please refer to:',
        tip4:'Qingdao Photo Library Content Licensing Agreement',
        tip5:'Add to cart',
        tip6:'Buy as a single sheet',
        tip7:'For inquiry, please call 0532-82825531 or consult',
        tip8:'Online Customer Service',
        tip9:'Image ID:',
        tip10:'Image Caption:',
        tip11:'Image Specifications:',
        tip12:'Image size:',
        tip13:'Image Type:',
        tip14:'Image Size:',
        tip15:'Image Price:',
        tip16:'Year of the image:',
        tip17:'Photo Captions:',
        tip18:'keywords',
        tip19:'Similar image recommendation',

    },
    video: {
        text1: 'Video ID：',
        text2: 'Video Title:',
        text3: 'Video Type:',
        text4: 'Video duration:',
        text5: 'Video size:',
        tip1: 'keyword',
        tip2: 'Similar video recommendations',
        desc: 'Introduction',
    },
    vr: {
        text1: 'VR exhibition hall',
        text2: 'Detail',
    },
    order_detail: {
        text1: 'Order Details',
        text2: 'Service Description',
        text3: 'If you need to issue an invoice, please contact customer service:',
        text4: 'Order Number:',
        text5: 'Order Amount:',
        text6: 'Payment Amount:',
        text7: 'Payment Status:',
        text8: 'To be paid',
        text9: 'Paid',
        text10: 'Payment Methods:',
        text11: 'Package',
        text12: 'WeChat Pay',
        text13: 'Alipay payment',
        text14: 'balance',
        text15: 'Payment time:',
        text16: 'Order time:',
        text17: 'Picture information',
        text18: 'Total Payable:',
        text19: 'Picture preview',
        text20: 'The name of the image',
        text21: 'Image specifications',
        text22: 'Image price',
        text23: 'Select a payment method',
        text24: 'Select Package Payment',
        text25: 'Actual Payment:',
        text26: 'Submit',
        text27: 'Account balance payment',
        // text28: '账户可用余额 <span style="color:rgb(230, 0, 18);font-weight:bold;">0.00</span>元。如果您已在【价格套餐】页面购买了套餐包，请优先选择套餐包支付。',
        text29: 'WeChat Pay',
        text30: 'Support WeChat quick payment',
        text31: 'Alipay payment',
        text32: 'Support Alipay fast payment',

        text33: 'The available balance of the account',
        text34: 'If you have already purchased a package on the Price Package page, please give priority to the payment of the package.',
        text35: 'Payment is complete',
        text36: 'The payment failed',
        origin_1:'Original:',
        small_1:'Small Image:',
        big_1:'Big Image:',
        origin:'Original',
        small:'Small Image',
        big:'Big Image',
        maohao:'：',
    },
    order_pay: {
        text1: 'Order Information',
        text2: 'Prepaid orders',
        tip1: 'Please select an image price',
        tip2: 'You can only select one',
    },
    order_list: {
        text0: 'Order List',
        text1: 'Image Name:',
        text2: 'Created:',
        text3: 'Search',
        text4: 'Order ID:',
        text5: 'Order Number:',
        text6: 'Order time:',
        text7: 'Resolution:',
        text8: 'Order Details',
        text9: 'Download the contract',
        text10: 'Price:',
        text11:'Start Time',
        text12:'End Time',
        text13:'to',
        wait_pay:'To be paid',
        pay_success:'Payment successful',
        paid:'Paid',
        package:'Package',
        wechatPay:'WeChat Pay',
        alipay:'Alipay Payment',
        balance:'Balance',
    },
    setting: {
        text1:'Modify your password',
        text2:'User Information',
        text3:'Mobile phone number',

    },
    auth: {
        text1: 'Authorization List',
        text2: 'Image Name:',
        text3: 'Authorization time:',
        text4: 'Picture preview',
        text5: 'Image caption',
        text6: 'Image specifications',
        text7: 'Picture resolution',
        text8: 'operate',
        text9: 'Download',


    },
    wallet: {
        text1: 'My wallet',
        text2: 'Available balance',
        text3: 'Balance records',
        text4: 'type',
        text5: 'amount',
        text6: 'Time',
        text7: 'There is no record of the balance',
    },
    cart: {
        text1: 'Shopping Cart',
        text2: '(Total',
        text3: 'items)',
        text4: 'Operation',
        text5: 'Preview',
        text6: 'Image caption',
        text7: 'Specifications',
        text8: 'Price',
        text9: 'Delete',
        text10: 'I have read and agreed',
        text11: 'Hi!Qingdao Licensing Agreement',
        text12: 'Hi!Qingdao Privacy Statement',
        text13: 'Selected products',
        text14: 'Total',
        text15: 'Settlement',
        text16: 'items',
        clearCart:'Empty your shopping cart',
        batchDel:'Delete in bulk',
    },

    activity: {
        actName: 'Attend Activity',
        selectAct: 'Please select an activity',
        selectActName: 'Search for the activity name',
        category: 'Category',
        select_cate:'Select a category',
    },
    active: {
        upload: 'Upload Your Work',
        pic: 'Pictures',
        video: 'Videos',
        recommend: 'Recommend',
        tip7:'Photo Caption',
        tip9:'Keywords',
        folder_title: 'Title',
        enter_title: 'Please enter a title',
        folder_desc: 'Description',
        enter_desc: 'Compilation Description',
        cover_type: 'Cover Type',
        folder_cover: 'Cover',
        cover_type_1:'Latest Works',
        cover_type_1_tip:'Automatically use the latest work in the compilation as the compilation cover.',
        cover_type_2:'Fixed cover',
        cover_type_2_tip:'Uploading images locally as a fixed cover',
        operate_success: 'The operation was successful.',
    },
    status: {
        all:'All',
        reviewing: 'Awaiting approval',
        reviewed: 'Approved',
        not_review: 'Not approved',
    }


}