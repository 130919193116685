import { onMounted,nextTick, defineComponent, ref, provide, reactive, toRefs, getCurrentInstance } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import Swiper from '@/components/layout/Swiper.vue';
import SearchBar2 from '@/components/layout/SearchBar2.vue';
import Header2 from '@/components/layout/header2.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';
import SideMenu from '@/components/layout/SideMenu.vue';
import bus from 'vue3-eventbus'
import SideComponent from '@/components/common/SideComponent.vue';
import Loading from '@/components/common/Loading.vue';

export default defineComponent({
	components: {
		Header2,
		Menu,
	    Swiper,
	    SearchBar2,
	    Footer,
	    SideMenu,
	    SideComponent,
	    Loading,
	},
	props: {

	},
	setup() {
		let {ctx, proxy} = getCurrentInstance();
		const router = useRouter();
	    const route = useRoute();
		const data = reactive({
			showHeader: true,
			loginStyle: "min-width:141px;",
			mobileIconStyle: "color:rgba(223, 58, 8, 1);",
			showListIcon: true,
			activeName:'first',
			active: 1,
			isSettleLoading: true,
			settle:'',
			showSettle: true,
			showVerify: false,
			showSuccess: false,
			picFiles:[],
			images: [],
			video: [],
			isPicLoading: false,
			isVideoLoading: false,
			isThumbLoading: false,
			step1: proxy.$t('application.plain'),
			step2: proxy.$t('application.apply'),
			step3: proxy.$t('application.result'),
			submit_disabled: false,
			thumb: '',
			license: '',
		});
		const showMenu = ref(proxy.$menus);
		provide('showMenu', showMenu);

		const outerStyleStr = ref("position:relative;left:10%;top:40%;transform:translate(-10%,-40%);width:auto;");
		provide('outerStyleStr', outerStyleStr);

		const styleStr = ref("height:45px;");
		provide('styleStr', styleStr);

		const showSearchText = ref(false);
		provide('showSearchText', showSearchText);

		const getImageUrl = (name) => {
		    return proxy.$utils.getImageUrl(name);
		};

		// bus.emit('needRefresh', true)

		const store = useStore();	

		const getSiteInfo = () => {
		    return new Promise((resolve, reject) => {
		    	store.dispatch("common/fetchSiteInfo").then((res) => {
			    data.settle = res.settle;
			    data.isSettleLoading = false;
		        resolve();
		        // bus.emit('isLoading',false);
		      }).catch(err => {
		        reject();
		      })
			  
		    })
	    
	    }
	    const nextStep = (showSettle, showVerify, showSuccess) => {
	        if (data.active++ > 3) data.active = 1;
	        data.showSettle = showSettle;
  			data.showVerify = showVerify;
  			data.showSuccess = showSuccess;
	    }

	    const toSubmit = () => {
	    	nextStep(false, true, false);
	    }
	    const validate_mobile_rules = (rule, value, callback) => {
			if (value == "") return true;
			let email = proxy.$utils.isPhone(value);
		    if (!email) {
		    	let tip = proxy.$t('enterCorrectPhoneNumber');
		        callback(new Error(tip));
		    } else {
		        callback();
		    }
		    

		}
	    const validate_email_rules = (rule, value, callback) => {
			if (value == "") return true;
			let email = proxy.$utils.regEmail(value);
		    if (!email) {
		    	let tip = proxy.$t('enterCorrectEmail');
		        callback(new Error(tip));
		    } else {
		        callback();
		    }
		    

		}

		const validate_realname_rules = (rule, value, callback) => {
			if (value == "") return true;
			let company = proxy.$utils.regCompany(value);
		    if (!company) {
		    	let tip = proxy.$t('enterRealname');
		        callback(new Error(tip));
		    } else {
		        callback();
		    }

		}
		const validate_company_rules = (rule, value, callback) => {
			if (value == "") return true;
			let company = proxy.$utils.regCompany(value);
		    if (!company) {
		    	let tip = proxy.$t('enterCorrectUnitName');
		        callback(new Error(tip));
		    } else {
		        callback();
		    }

		}
		const validate_idcard_rules = (rule, value, callback) => {
			if (value == "") return true;
			let company = proxy.$utils.regIDC(value);
		    if (!company) {
		    	let tip = proxy.$t('enterCorrectIdcard');
		        callback(new Error(tip));
		    } else {
		        callback();
		    }

		}
		const validate_social_number_rules = (rule, value, callback) => {
			if (value == "") return true;
			let company = proxy.$utils.validateSocialCardNumber(value);
		    if (!company) {
		    	let tip = proxy.$t('enterCorrectInstitutionName');
		        callback(new Error(tip));
		    } else {
		        callback();
		    }

		}

	    const ruleRef = ref()
		const ruleUser = reactive({
			user_type:'1',
			realname:'',
		    gender:'1',	
		    thumb:'',
		    description:'',		
  			mobile:'',
  			email: '',
  			company:'',
  			ctype:'1',
  			worktype: [],
  			images:[],
  			video:[],
  			license:'',
		})

		const _rules = reactive({
			user_type: [
				{ required: true },
			],
			realname: [
				{ required: true, message: proxy.$t('form.enterRealname'), trigger: 'blur' },
				{ validator: validate_realname_rules, trigger: "blur" },
			],
			gender: [
				{ required: true },
			],
			license: [
				{ required: true, message: proxy.$t('form.uploadLicense'), trigger: 'blur' },
			],
			// description: [
			// 	{ required: true, message: proxy.$t('form.enterBiography'), trigger: 'blur' },
			// ],
			mobile: [
				{ required: true, message: proxy.$t('form.enterPhone'), trigger: 'blur' },
				{ validator: validate_mobile_rules, trigger: "blur" },
			],
			email: [
				{ required: true, message: proxy.$t('form.enterEmail'), trigger: 'blur' },
				{ validator: validate_email_rules, trigger: "blur" },
			],
			company: [
				{ required: true, message: proxy.$t('form.enterCompany'), trigger: 'blur' },
				{ validator: validate_company_rules, trigger: "blur" },
			],
  			
			ctype:[
				{ required: true },
			],
			worktype:[
				{ required: true, message: proxy.$t('form.enterFeedType'), trigger: 'blur' },
			],
			images:[
				
			],
			video:[
				
			],
			
		})
		const handleCheckChange = (value) => {
			const checkedCount = value.length
			if(value.indexOf('image')!==-1){
		    	_rules.images = [
					{ required: true, message: proxy.$t('form.uploadPicWork'), trigger: 'blur' },
				]
		    }else{
		    	_rules.images = []
		    }
		    if(value.indexOf('video')!==-1){
		    	_rules.video = [
					{ required: true, message: proxy.$t('form.uploadVideoWork'), trigger: 'blur' },
				]
		    }else{
		    	_rules.video = []
		    }
		}
		

		const submitForm = (formEl) => {			
			bus.emit('needRefresh', true)
			if (!formEl) return
			data.submit_disabled = true;
			formEl.validate((valid, fields) => {
			    if (valid) {
				    console.log(ruleUser,'ruleUser！')
				    if(ruleUser.worktype.indexOf('image')!==-1 && ruleUser.images.length <= 0){
				    	ElMessage({
						    message: proxy.$t('form.uploadPicWork'),
						    grouping: true,
						    type: 'warning',
						})
						return false;
				    }else if(ruleUser.worktype.indexOf('video')!==-1 && ruleUser.video.length <= 0){
				    	ElMessage({
						    message: proxy.$t('form.uploadVideoWork'),
						    grouping: true,
						    type: 'warning',
						})
						return false;
				    }else if(ruleUser.worktype.indexOf('image')!==-1 && ruleUser.images.length > 0 && ruleUser.images.length < 10){
				    	ElMessage({
						    message: proxy.$t('form.enter_limit_pic'),
						    grouping: true,
						    type: 'warning',
						})
						return false;
				    }else if(ruleUser.worktype.indexOf('image')!==-1 && ruleUser.images.length > 10){
				    	ElMessage({
						    message: proxy.$t('form.only_ten_pic'),
						    grouping: true,
						    type: 'warning',
						})
						return false;
				    }else if(ruleUser.worktype.indexOf('video')!==-1 && ruleUser.video.length > 0 && ruleUser.video.length < 3){
				    	ElMessage({
						    message: proxy.$t('form.enter_limit_video'),
						    grouping: true,
						    type: 'warning',
						})
						return false;
				    }else if(ruleUser.worktype.indexOf('video')!==-1 && ruleUser.video.length > 3){
				    	ElMessage({
						    message: proxy.$t('form.only_three_video'),
						    grouping: true,
						    type: 'warning',
						})
						return false;
				    }else{
				    	store.dispatch("user/GrapherWorkerForm",{
				    		user_type: ruleUser.user_type,
					      	realname: ruleUser.realname,
						    gender: ruleUser.gender,
						    thumb: ruleUser.thumb?ruleUser.thumb:'',
						    description: ruleUser.description?ruleUser.description:'',
				  			mobile: ruleUser.mobile,
				  			email: ruleUser.email,
				  			company: ruleUser.company,
				  			ctype: ruleUser.ctype,
				  			worktype: ruleUser.worktype,
				  			images: ruleUser.images,
				  			video: ruleUser.video,
				  			license: ruleUser.license,
					    }).then((res)=>{
					      	console.log("SubmitContract",res);
					      	ElMessage({
							    message: proxy.$t('form.submited'),
							    grouping: true,
							    type: 'warning',
							})

					      	nextStep(false, false, true);
							
					    }).catch(err => {
				            // console.error(err)
				            ElMessage({
							    message: err.message,
							    grouping: true,
							    type: 'error',
							})
							data.submit_disabled = false;
				        })
				    }
				    
			    } else {
			      console.log('error submit!')
			      data.submit_disabled = false;
			      return false
			    }
			})
		}


		const dialogImageUrl = ref('')
		const dialogVisible = ref(false)
		const disabled = ref(false)

		const handleRemove = (file) => {
		  // console.log(file)
		}

		const handlePictureCardPreview = (file) => {
		  dialogImageUrl.value = file.url
		  dialogVisible.value = true
		}

		const handleDownload = (file) => {
		  // console.log(file)
		}

		const handleChange = (uploadFile, uploadFiles) => {
		  // console.log(uploadFile,"uploadFile")
		  // console.log(uploadFiles,"uploadFiles")

		}

		const ApplyInfo = () => {
			store.dispatch("user/ApplyInfo",{

		    }).then((res)=>{
		    	console.log(res,"applyInfo");
		    	if(Object.keys(res).length>0 ){
		    		if(res.status==2){
			    		let info = res;
			    		ruleUser.user_type = info.user_type+'';
				    	ruleUser.realname = info.realname;
				    	ruleUser.gender = info.gender+'';
				    	if(info.thumb){
				    		ruleUser.thumb = info.thumb;
				    		data.thumb = info.thumb;
				    	}
				    	 
				    	ruleUser.description = info.description;
				    	ruleUser.mobile = info.mobile;
				    	ruleUser.email = info.email;
				    	ruleUser.company = info.company;
				    	ruleUser.ctype = info.ctype+'';
				    	ruleUser.worktype = info.worktype;
				    	if(info.license){
				    		ruleUser.license = info.license;
				    		data.license = info.license;
				    	}
				    	
				    	// ruleUser.images = info.images;
				    	// ruleUser.video = info.video;
				    	if(info.images.length>0){
					    	info.images.forEach((item,index)=>{
					    		ruleUser.images.push(item.path);
					    		data.images.push(item.url);
					    	})
					    }
				    	if(info.video.length>0){
				    		info.video.forEach((item,index)=>{
					    		ruleUser.video.push(item.path);
					    		// data.video.push(item.url);
					    		getVideoFirstFrame(item.url).then(thumb=>{
					    			let obj = {
			            				thumb: thumb.url,
			            				video: item.url,
			            			};
					    			data.video.push(obj);
					    		})
					    	})
				    	}
			    	}else if(res.status==1){
			    		let info = res;
			    		console.log(res,"applyInfo-1");
			    		if(info.worktype.indexOf('image')!==-1 && info.worktype.indexOf('video')!==-1){
			    			router.push({name:'apply_pass'})
			    		}else if(info.worktype.indexOf('image')===-1 || info.worktype.indexOf('video')===-1){
			    			
			    			ruleUser.user_type = info.user_type;
					    	ruleUser.realname = info.realname;
					    	ruleUser.gender = info.gender+'';
					    	if(info.thumb){
					    		ruleUser.thumb = info.thumb;
					    		data.thumb = info.thumb;
					    	}
					    	ruleUser.description = info.description;
					    	ruleUser.mobile = info.mobile;
					    	ruleUser.email = info.email;
					    	ruleUser.company = info.company;
					    	ruleUser.ctype = info.ctype+'';
					    	ruleUser.worktype = info.worktype;
					    	if(info.license){
					    		ruleUser.license = info.license;
					    		data.license = info.license;
					    	}
					    	// ruleUser.images = info.images;
					    	// ruleUser.video = info.video;
					    	if(info.images.length>0){
						    	info.images.forEach((item,index)=>{
						    		ruleUser.images.push(item.path);
						    		data.images.push(item.url);
						    	})
						    }
					    	if(info.video.length>0){
					    		info.video.forEach((item,index)=>{
						    		ruleUser.video.push(item.path);
						    		getVideoFirstFrame(item.url).then(thumb=>{
						    			let obj = {
				            				thumb: thumb.url,
				            				video: item.url,
				            			};
						    			data.video.push(obj);
						    		})
						    		
						    	})
					    	}
			    		}
			    		
			    	}else if(res.status==0){
			    		router.push({name:'apply_passing'})
			    	}
			    	
		    	}
		    	bus.emit('showFooter',true);
		    }).catch(err=>{

		    })
		}



		const formSize = ref('large')	
		const viewBox = ref(null);
        const scroll = (val) => {
			if(val == 'top'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		       
			}else if(val == 'bottom'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });  	
		        
			}
		}

		const del = (type, index) => {
			if(type=='image'){
				data.images.splice(index,1);
				ruleUser.images.splice(index,1);
				data.submit_disabled = false;
			}else if(type=='video'){
				data.video.splice(index,1);
				ruleUser.video.splice(index,1);
				data.submit_disabled = false;
			}
		}
		const getVideoFirstFrame = (url, file='') => {
	    	return new Promise((resolve, reject) => {
				const video = document.createElement('video') // 创建video对象
				video.src = url // url地址
				const canvas = document.createElement('canvas') // 创建 canvas 对象
				const ctx = canvas.getContext('2d') // 绘制2d
				video.crossOrigin = 'anonymous' // 解决跨域问题，也就是提示污染资源无法转换视频
				video.currentTime = 1 // 第一秒帧
				// console.log(video,"video")
				video.oncanplay = (e) => {
					// console.log('wh',e.target.videoWidth,e.target.videoHeight);
    				
				    canvas.width = e.target.videoWidth
				    canvas.height = e.target.videoHeight
				    // 利用canvas对象方法绘图
				    ctx.drawImage(video, 0, 0, canvas.width, canvas.height)

				    // 转换成base64形式
				    let base64_file = canvas.toDataURL('image/png');
				    // console.log(base64_file,"base64_file");
				    proxy.$store.dispatch('user/UploadBase64File',{base64_file: base64_file}).then(res=>{
				    	resolve({
				    		base64_file: base64_file,
				    		url: res.url,
				    	});
				    });
				    
				    // Vue.set(file, 'imageShow', canvas.toDataURL('image/png')) // 获取视频第一帧作为imageShow展示
				}
			})
		}

        onMounted(async()=>{
        	getSiteInfo();
        	ApplyInfo();

        	await nextTick()
        	
        	setTimeout(() => {
		      data.isLoading = false;
		      viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		    }, 600);
		    console.log("页面数据更新完毕了")
        })

		const refData = toRefs(data);

		return {
			...refData,
			getSiteInfo,
			viewBox,
			scroll,
			nextStep,
			toSubmit,
			ruleRef,
			ruleUser,
			_rules,
			submitForm,
			formSize,
			dialogImageUrl,
			dialogVisible,
			disabled,
			handleRemove,
			handlePictureCardPreview,
			handleDownload,
			handleChange,
			handleCheckChange,
			ApplyInfo,
			del,
			getVideoFirstFrame,
		}
	},
	created() {

	},
	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	},
	methods: {
		init() {
			this.username = this.$cookies.getUsername();
		},
		uploadThumb(event,type){
			let that = this;
			that.isThumbLoading = true;
			event.preventDefault();
	        let file = event.currentTarget.files[0];
	        var formData = new FormData();
    		formData.append("file", file);
        	this.$store.dispatch('user/GrapherWorkerUpload', formData).then((res)=>{	            		
            	console.log("thumb upload",res);
            	that.isThumbLoading = false;
            	if(type=='thumb'){           		
            		// that.idcard_thumb = res.url;
            		that.ruleUser.thumb = res.path;
            		that.thumb = res.url;
            	}else if(type=='license'){
            		// that.idcard_thumb = res.url;
            		that.ruleUser.license = res.path;
            		that.license = res.url;
            	}
            	
            }).catch(err=>{
				ElMessage({
			        message: err.message,
			        type: "warning",
			    });
			})
		},
		uploadFile(event,type){
			let that = this;
			that.submit_disabled = false;
			if(type=='image' && that.images.length > 10){
				ElMessage({
			        message: proxy.$t('form.only_ten_pic'),
			        type: "warning",
			    });
			    // return false;
			}
			if(type=='video' && that.video.length > 3){
				ElMessage({
			        message: proxy.$t('form.only_three_video'),
			        type: "warning",
			    });
			    // return false;
			}else{
	        	event.preventDefault();
	            this.picFiles = event.currentTarget.files;
	            // console.log(this.picFiles,"this.picFiles");
	            if(this.picFiles.length > 0){
	            	for(let i=0;i<this.picFiles.length;i++){
	            		// console.log(this.picFiles[0],"this.picFiles-1");
	            		if( (type=='image' && i < 10 && that.images.length < 10) || (type=='video' && i < 3 && that.video.length < 3) ){
	            			if(type=='image'){
			            		this.isPicLoading = true;
			            	}
			            	if(type=='video'){
			            		this.isVideoLoading = true;
			            	}
	            			var formData = new FormData();
		            		formData.append("file", this.picFiles[i]);
			            	this.$store.dispatch('user/GrapherWorkerUpload', formData).then((res)=>{
			            		
				            	// console.log("upload",res);
				            	if(type=='image'){
				            		this.isPicLoading = false;
				            		that.images.push(res.url);
				            		that.ruleUser.images.push(res.path);
				            		that.submit_disabled = false;
				            	}else if(type=='video'){
				            		
				            		that.getVideoFirstFrame(res.url).then(thumb=>{
				            			let obj = {
				            				thumb: thumb.url,
				            				video: res.url,
				            			};
					            		that.video.push(obj);
				            			that.ruleUser.video.push(res.path);
				            			that.isVideoLoading = false;
				            			that.submit_disabled = false;
					            	})
				            		
				            	}
				            	
				            }).catch(err=>{
								ElMessage({
							        message: err.message,
							        type: "warning",
							    });
							    that.submit_disabled = false;
							})
	            		}else{
	            			that.submit_disabled = false;
	            			if(type=='image'){
			            		ElMessage({
							        message: this.$t('form.only_ten_pic'),
							        type: "warning",
							    });
			            	}
			            	if(type=='video'){
			            		ElMessage({
							        message: this.$t('form.only_three_video'),
							        type: "warning",
							    });
			            	}
	            		}
	            		
		            }
	            }
            }
            
           
        },
        
	},
})