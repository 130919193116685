import { onMounted,nextTick, defineComponent, ref, provide, reactive, toRefs, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import Swiper from '@/components/layout/Swiper.vue';
import SearchBar2 from '@/components/layout/SearchBar2.vue';
import Header2 from '@/components/layout/header2.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';
import SideMenu from '@/components/layout/SideMenu.vue';
import bus from 'vue3-eventbus'
import SideComponent from '@/components/common/SideComponent.vue';


export default defineComponent({
	components: {
		Header2,
		Menu,
	    Swiper,
	    SearchBar2,
	    Footer,
	    SideMenu,
	    SideComponent,
	},
	props: {

	},
	setup() {
		let {ctx, proxy} = getCurrentInstance();
		const data = reactive({
			showHeader: true,
			loginStyle: "min-width:141px;",
			mobileIconStyle: "color:rgba(223, 58, 8, 1);",
			showListIcon: true,
			activeName:'first',
			cartList:[],
			selectAll:[],
			isAllSelect: false,
			checkedAll: false,
		    // 设置选项复选框初始状态为未选中,由于是一个数组,所以值为[],即为空
		    checkedItem: [],
		    // 设置选中全选复选框后重新给选项复选框赋值,即选中所有的选项复选框
		    checkedAttr: [],
		    checkList: [],
		    sumPrice:0,
		    goods:[],
		    isRead:false,
		    options: [
		    	// {
		    	// 	value:'del',
		    	// 	label: '移出购物车',
		    	// },
		    	{
		    		value:'clear',
		    		label: proxy.$t('cart.clearCart'),
		    	},
		    	{
		    		value:'batchDel',
		    		label: proxy.$t('cart.batchDel'),
		    	},
		    ],
		    operation:'',
		    imgids:[],
		    isMobile: false,
		    protocal:'',
		    privacy:'',
		    showProtocol: false,
		    showPrivacy: false,
		    showDialog: false,
		    imgOptions: [],
		    disabled:false,
		});
		const showMenu = ref(proxy.$menus);
		provide('showMenu', showMenu);

		const outerStyleStr = ref("position:relative;left:10%;top:40%;transform:translate(-10%,-40%);width:auto;");
		provide('outerStyleStr', outerStyleStr);

		const styleStr = ref("height:45px;");
		provide('styleStr', styleStr);

		const showSearchText = ref(false);
		provide('showSearchText', showSearchText);

		const getImageUrl = (name) => {
		    return proxy.$utils.getImageUrl(name);
		};

		const store = useStore();	
		
		// store.dispatch("user/getCartList",{}).then((res)=>{
		// 	data.cartList = res
		// });
		// data.cartList.forEach(item => {
  //           item.checked = false
  //       })
		

		
		const ruleFormRef = ref()

		const handleClick = (tab, event) => {
		  console.log(tab, event)
		}

		const checkAll = (name) => {
			proxy.$utils.selectall(name);
		}

		onMounted(async()=>{
			
			data.cartList = JSON.parse( JSON.stringify( store.state.user.cartList.slice() ) );
        	
        	console.log(data.cartList,"cartList");
        	// for(let i=0;i<data.cartList.length;i++){
        	// 	let obj = {
        	// 		id: 0,
        	// 		price: 50,
        	// 		isChecked: false,
        	// 	}
        	// 	data.cartList[i].options = [];
        	// 	data.cartList[i].options.push(obj);
        	// }
			data.checkedAttr = data.cartList.map((item,index,array)=>{
				// item.isChecked = false;
				return item.id;
			});
			// console.log(store.state.common.siteInfo,"siteinfo");
			data.protocal = store.state.common.siteInfo['protocal'];
			data.privacy = store.state.common.siteInfo['privacy'];
        	await nextTick()
        	setTimeout(() => {
		      data.isLoading = false;
		      viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		      bus.emit('showFooter',true);
		    }, 600);
		    console.log("页面数据更新完毕了")
        })

        const viewBox = ref(null);
        const scroll = (val) => {
			if(val == 'top'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		       
			}else if(val == 'bottom'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		        	
		        	
		        
			}
		}
        
		
		const refData = toRefs(data);

		return {
			...refData,
			getImageUrl,
			handleClick,
			checkAll,
			proxy,
			viewBox,
			scroll,
		}
	},
	// watch:{
	// 	variable:{
	// 		// 此处监听variable变量，当期有变化时执行
	// 		handler(item1,item2){
	// 			// item1为新值，item2为旧值
	// 		}
	// 	}
	// },
	watch: {
	    // 监听复选框项是否全部选中
	    checkedItem() {
	    	
	    	this.sumPrice = 0;
	    	this.imgids = [];
	    	this.checkList = [];
	      	// 判断选中选项复选框的值数组的长度是否与设定的长度相等，如果相等则所有的选项复选框被选中，否则没有被选中。
	      	if (this.checkedItem.length == this.checkedAttr.length) {
	        	// 如果所有复选框项全部被选中,则全选复选框也被勾选
	        	this.checkedAll = true;
	      	} else {
	        	// 否则复选框不勾选
	        	this.checkedAll = false;
	      	}

	      	this.cartList.forEach(cart=>{
	      	  	if(this.checkedItem.indexOf(cart.id)!==-1){
	      	  		let obj = {};
	      	  		obj.image_id = cart.image_id;
	      	  		obj.option = [];
	      	  		for(let i=0;i<cart.image.option_all.length;i++){

	      	  			if(cart.image.option_all[i].isChecked){

	      	  				this.sumPrice += Number.parseFloat(cart.image.option_all[i].price)						
							obj.option.push(cart.image.option_all[i].id)
							

	      	  			}
	      	  			
	      	  		}
	　　　　　　　　	this.imgids.push(obj);
					this.checkList.push(cart.image);
	　　　　 　　}
	      	});
	      	this.sumPrice.toFixed(2);
	      	// console.log(this.imgids,"imgids")
	      	// console.log(this.checkList,"checkList")
	    },

	},


	created() {

	},
	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	},
	methods: {
		init() {
	  		if(document.documentElement.clientWidth < 890){
				this.size = 'small';
				this.isMobile = true;
			}else{
				this.size = 'default';
				this.isMobile = false;
			}

	        window.addEventListener('resize', function(event){
				if(document.documentElement.clientWidth < 890){
					this.size = 'small';
					this.isMobile = true;
				}else{
					this.size = 'default';
					this.isMobile = false;
				}
				
			});
		},
		fun_protocol() {
			this.showDialog = true;
			this.showProtocol = true;
		    this.showPrivacy = false;
		},
		fun_privacy() {
			this.showDialog = true;
			this.showProtocol = false;
		    this.showPrivacy = true;
		},
		close() {
			this.showDialog = false;
		},
		changeInput(imgindex, index){
			this.imgOptions = [];
    		this.sumPrice = 0;
	    	this.imgids = [];
	    	this.checkList = [];

	    	for (var i=0; i<this.cartList.length; i++){
			// this.cartList.forEach(cart=>{
	      	  	if(this.checkedItem.indexOf(this.cartList[i].id)!==-1){
	      	  		console.log(this.cartList[i],"cart");
	      	  		let obj = {};
	      	  		obj.image_id = this.cartList[i].image_id;
	      	  		obj.option = [];
	      	  		if(i==imgindex){
	      	  			for(let j=0;j<this.cartList[i].image.option_all.length;j++){
		      	  			if(j==index){
			        			// console.log(i,index,"index");
					          	this.cartList[i].image.option_all[j].isChecked = true
					          	this.sumPrice += Number.parseFloat(this.cartList[i].image.option_all[j].price)
					          	obj.option.push(this.cartList[i].image.option_all[j].id)
					        }else{
					        	this.cartList[i].image.option_all[j].isChecked = false
					        }
		      	  			
		      	  		}
	      	  		}else{
	      	  			for(let j=0;j<this.cartList[i].image.option_all.length;j++){
		      	  			if(j==index && this.cartList[i].image.option_all[j].isChecked){
					          	this.sumPrice += Number.parseFloat(this.cartList[i].image.option_all[j].price)
					          	obj.option.push(this.cartList[i].image.option_all[j].id)
					        }
		      	  			
		      	  		}
	      	  		}
	      	  		
	      	  		
	　　　　　　　　	this.imgids.push(obj);
					this.checkList.push(this.cartList[i].image);
	　　　　 　　}else{
					for(let j=0;j<this.cartList[i].image.option_all.length;j++){
	      	  			if(this.cartList[i].image.option_all[j].isChecked){
		        			// console.log(i,index,"index");
				          	this.cartList[i].image.option_all[j].isChecked = true
				          	
				        }else{
				        	this.cartList[i].image.option_all[j].isChecked = false
				        }
	      	  			
	      	  		}
				}
	      	}
	      	this.sumPrice.toFixed(2);
	      	console.log('imgids-1', this.imgids);
	      	console.log('checkList-1', this.checkList);
	    },
		topay() {
			bus.emit('needRefresh', true)
			if(!this.isRead){
				ElMessage({
			        message: "请先阅读协议和隐私声明",
			        type: "warning",
			    });
			}else if(this.checkedItem.length <= 0){
				ElMessage({
			        message: "请选择商品",
			        type: "warning",
			    });
			}else if(this.sumPrice <= 0){
				ElMessage({
			        message: "请选择商品价格",
			        type: "warning",
			    });
			}else{
				let sum = [];
				let isOK = true;
				this.cartList.forEach(cart=>{
		      	  	if(this.checkedItem.indexOf(cart.id)!==-1){
		      	  		sum[cart.id] = {};
		      	  		sum[cart.id].total = 0;
		      	  		for(let i=0;i<cart.image.option_all.length;i++){
		      	  			if(cart.image.option_all[i].isChecked){
								sum[cart.id].total++;

		      	  			}		      	  			
		      	  		}
		　　　　 　　}
		      	});
		      	if(sum.length > 0){
		      		let hasNoChecked = false;
		      		let arr = [];
		      		sum.forEach((item,index)=>{
		      			if(item.total<=0){
		      				hasNoChecked = true;
		      				// arr.push(3);
		      			}
		      			// else if(item.total > 1){
		      			// 	hasNoChecked = true;
		      			// 	// arr.push(2);
		      			// }
		      		});
		      	// 	if(arr.indexOf(3)!==-1){
		      	// 		ElMessage({
					    //     message: "请选择商品价格",
					    //     type: "warning",
					    // });
		      	// 	}else if(arr.indexOf(2)!==-1){
		      	// 		ElMessage({
					    //     message: "只能选择一项商品价格",
					    //     type: "warning",
					    // });
		      	// 	}
		      		if(hasNoChecked){
		      			ElMessage({
					        message: "请选择所选图片的商品价格",
					        type: "warning",
					    });
		      		}else{
			      		if(undefined === this.$cookies.getToken()){
							sessionStorage.setItem('redirectPath', this.$route.fullPath);
							// this.toLogin();
							ElMessage({
						        message: "请先登录",
						        type: "warning",
						    });
						} else {
							this.disabled = true;
							this.$store.dispatch("common/saveOrderParam",{
								source:1,
								images: this.imgids,
								imgList: this.checkList,
							}).then(()=>{
								this.disabled = false;
								this.$router.push({ path:'/my/order_pay',query:{from:'cart',cartids:this.checkedItem} });
							});

						}
			      	}
		      		
		      	}
				
			}
			
		
		},
		del(id){
			let that = this;
			this.$store.dispatch("user/deleteCartGoods",{id:id}).then((res)=>{
				console.log(res,"res");
				that.cartList = that.$store.state.user.cartList.slice();
				that.checkedAttr = that.cartList.map((item,index,array)=>{					
					return item.id;
				});
				ElMessage({
			        message: "已删除",
			        type: "success",
			    });
			});

		},
		operate() {
			let that = this;
			console.log(this.operation,"operation")
			if (this.operation == 'clear'){
				if(that.$store.state.user.cartList.length > 0){
					this.$store.dispatch("user/clearCartGoods").then((res)=>{
						// console.log(res,"res");
						that.cartList = that.$store.state.user.cartList.slice();
						that.checkedAttr = that.cartList.map((item,index,array)=>{
							// item.isChecked = false;
							return item.id;
						});
						ElMessage({
					        message: "已清空",
					        type: "success",
					    });
					});
				}else{
					ElMessage({
				        message: "购物车没有商品，操作无效",
				        type: "success",
				    });
				}
				
			} else if (this.operation == 'batchDel'){
				console.log(this.checkedItem,"batchDel")
				if(that.$store.state.user.cartList.length > 0){
					if(this.checkedItem.length <= 0){
						ElMessage({
					        message: "请选择要删除的商品",
					        type: "warning",
					    });
					}else{
						let arr = [];
						this.cartList.forEach((item,index)=>{
							if(this.checkedItem.indexOf(item.id) !== -1){
								arr.push(item.image_id)
							}
						})
						this.$store.dispatch("user/BatchDeleteCartGoods",{ids:arr}).then((res)=>{
							// console.log(res,"res");
							that.cartList = that.$store.state.user.cartList.slice();
							that.checkedAttr = that.cartList.map((item,index,array)=>{
								// item.isChecked = false;
								return item.id;
							});
							ElMessage({
						        message: "已删除",
						        type: "success",
						    });
						});
					}
					
				}else if(this.checkedItem.length <= 0){
					ElMessage({
				        message: "请选择商品",
				        type: "warning",
				    });
				}
			}
			
		},
		settlement() {
			// console.log(this.isRead,"isRead");
			if(!this.isRead){
				ElMessage({
			        message: "请先阅读协议和隐私声明",
			        type: "warning",
			    });
			}
			if(this.checkedItem.length <= 0){
				ElMessage({
			        message: "请选择商品",
			        type: "warning",
			    });
			}

		},
		// 全选复选框状态改变监听事件
	    changeAllChecked() {
	      // 判断全选复选框是否选中
	      if (this.checkedAll) {
	        // 如果全选复选框被选中,则重新给选项复选框赋值,即选中所有的选项复选框
	        this.checkedItem = this.checkedAttr;
// 	        this.cartList.forEach((cart)=>{
// 	　　　　　　if(this.checkedItem.indexOf(cart.id)==-1){
// 	　　　　　　　　this.checkedItem.push(cart.id)
// 	　　　　　　}
// 　　　　　　　})
	      } else {
	        // 如果全选复未选框被选中,则设置为空值,即未选中所有的选项复选框
	        this.checkedItem = [];
	      }
	    },
	    

	},
})