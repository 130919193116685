<template>
	<PageLoading :is-loading="isLoading"></PageLoading>
	<SideComponent @scrollto="scroll"></SideComponent>
	<div class="container" ref="viewBox">
		<!-- <Header2 :show-search-bar-pic="showHeader" login-style="loginStyle" :mobile-icon-style="mobileIconStyle" :show-list-icon="showListIcon"/> -->
		<div class="header-container">
			<div class="search-swiper-container">				
				<Swiper :list="banners"/>
				<search-bar-pic  @refresh="getData" @searchKeywords="searchKeywords" :isNeedRedirect="isNeedRedirect"></search-bar-pic>
			</div>
		</div>
		<div class="content">
			<div class="layout">
				<div class="layout-cats">
					<div class="cats-title" style="color:#999;font-size:16px;font-weight: bold;border-bottom:1px solid rgb(239,239,239);">
						<span style="border-left:5px solid rgb(230,0,18);padding-left:10px;">{{$t('topics.home')}}</span> 
						<span style="color:rgb(0, 49, 97);cursor:pointer;" @click="toSubjectCategory(subject.cate.parent.id)" v-if="subjectCate.length>0 && subjectCate.indexOf('parent')!=-1">
							<i class="iconfont icon-jiantou2"></i>{{subject.cate.parent.title}}
						</span>
						<span style="color:rgb(0, 49, 97);cursor:pointer;" @click="toSubjectCategory(subject.cate.id)" v-if="Object.keys(subject.cate).length>0">
							<i class="iconfont icon-jiantou2"></i>{{subject.cate.title}}
						</span>
						<span>
							<i class="iconfont icon-jiantou2"></i>{{subject.title}}
						</span>
						
					</div>
				</div>
				<div class="layout-content">
					<div class="layout-left">
						<div class="cats-title">
							<span>{{$t('topics.tip1')}}</span>
						</div>
						<div class="img-wrapper">
							<div class="img-content" v-for="item in subjectList" @click="fetchPics(item.id)">
								<div class="text">
									<img style="width:100%;height:180px;object-fit:cover;" :src="item.thumb" :alt="item.title" oncontextmenu="return false"/>
									<div class="name">{{item.title}}</div>
								</div>
							</div>
						</div>
					</div>
					<div class="layout-right">
						<div class="top-title">
							<span>{{subject.title}}</span>
							<i class="iconfont icon-shangchuantupian"></i>
							<span>{{pagination.total}}</span>
						</div>
						<div class="topic-desc">
							<div v-html="subject.desc"></div>
							
						</div>
						<div class="imgs">
							<section class="img-wrapper">
								<div class="imgs-item" :style="activeStyle(item)" v-for="item in pics" @click="toPicDetail(item.id)">
								
									<img :src="item.preview" oncontextmenu="return false" :alt="item.title"/>
									<div class="name">{{item.title}}</div>
								
								</div>
							</section>
							<div class="loadmore">
								<Loading :is-loading="isPicLoading"></Loading>
								<span class="more" @click="loadMore()" v-if="!nomore">{{$t('loadmore')}}</span>
								<span class="end" v-if="nomore">- {{$t('theEnd')}} -</span>
							</div>
							<!-- <div class="page-wrapper" v-if="Object.keys(pagination).length > 0">
								<el-pagination 
								    background 
								    layout="prev, pager, next" 
								    :total="pagination.total" 
								    v-model:current-page="pagination.current"
								    v-model:page-size="pagination.pageSize"				    
								/>

							</div> -->
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer />
	</div>
</template>
<script>
	import special_topics from './special_topics_controller';
	export default special_topics;
</script>

<style src="@/assets/css/special_topics.css" scoped>
	/*@import url("@/assets/css/style.css");全局引用css*/
	/*@import '@/assets/css/style.css';*/
</style>