<template>
	<PageLoading :is-loading="isLoading"></PageLoading>
	<SideComponent @scrollto="scroll"></SideComponent>
	<div class="container" ref="viewBox">
		<div class="header-container">
			<div class="search-swiper-container">				
				<Swiper :list="banners"/>
				<!-- <search-bar-pic :isNeedRedirect="false" @searchKeywords="fetchGrapher"></search-bar-pic> -->
			</div>
		</div>
		<div class="content">
			<div class="wrapper">
				
				<div class="pic-video-list" v-if="showList">
					<div class="item-wrapper">
						<div class="body">
							<Loading :is-loading="isPicLoading"></Loading>
							<div class="img-item" @click="toActive(item.id)" v-for="item in picList">						
								<img class="img" :src="item.thumb" oncontextmenu="return false" :alt="item.title"/>
						  		<!-- <div class="title">
						  			<span>{{item.title}}</span>
						  		</div>	 -->					
						  		<!-- <i class="iconfont icon-star top" v-if="item.istop==1"></i> -->
						  		<img class="top" src="@/assets/img/recommend.png" v-if="item.istop==1"/>
							</div>
							
						</div>
						<div class="loadmore">
							<span class="more" @click="loadMore()" v-if="!nomore">{{$t('loadmore')}}</span>
							<span class="end" v-if="nomore">- {{$t('theEnd')}} -</span>
						</div>
					</div>
					
				</div>

			</div>
			
		</div>
		<Footer/>
		
	</div>
</template>

<script>
	import active_list from './active_list_controller';
	export default active_list;
</script>

<style src="@/assets/css/active_list.css" scoped>
	/*@import url("@/assets/css/style.css");全局引用css*/
	/*@import '@/assets/css/style.css';*/
</style>