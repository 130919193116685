import { defineComponent, ref, provide, reactive, toRefs, getCurrentInstance, nextTick, onMounted, onUnmounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import {useStore} from 'vuex';
import PageLoading from '@/components/common/PageLoading.vue';
import Swiper from '@/components/layout/Swiper.vue';
import SearchBar2 from '@/components/layout/SearchBar2.vue';
import Header2 from '@/components/layout/header2.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';
import bus from 'vue3-eventbus'
import SideComponent from '@/components/common/SideComponent.vue';
import _ from 'lodash' //导入loadsh插件

export default defineComponent({
	components: {
		PageLoading,
		Header2,
		Menu,
	    Swiper,
	    SearchBar2,
	    Footer,
	    SideComponent,
	},
	props: {

	},
	setup() {
		let {ctx, proxy} = getCurrentInstance();
		const router = useRouter();
	    const route = useRoute();
		const data = reactive({
			isLoading: true,
			showHeader: true,
			loginStyle: "min-width:141px;",
			mobileIconStyle: "color:rgba(223, 58, 8, 1);",
			showListIcon: true,		
      		pics:{},
      		orderInfo:{},
      		payInfo:{},
      		paytype:[
      			{
      				id: 1,
      				name: proxy.$t('order_detail.text27'),
      				iconClass:'icon-zhanghuyue6',
      				text: proxy.$t('order_detail.text33')+' <span style="color:rgb(230, 0, 18);font-weight:bold;">￥0.00</span>。'+proxy.$t('order_detail.text34'),
      				package_id:0,
      			},
      			{
      				id: 2,
      				name: proxy.$t('order_detail.text29'),
      				iconClass:'icon-zhanghuyue7',
      				text: proxy.$t('order_detail.text30'),
      				
      			},
      			{
      				id: 3,
      				name: proxy.$t('order_detail.text31'),
      				iconClass:'icon-zhanghuyue7',
      				text: proxy.$t('order_detail.text32'),
      				
      			},
      		],
      		selectedPaytype:{
      			id: 1,
  				name: proxy.$t('order_detail.text27'),
  				iconClass:'icon-zhanghuyue6',
  				text: proxy.$t('order_detail.text33')+' <span style="color:rgb(230, 0, 18);font-weight:bold;">￥0.00</span>。'+proxy.$t('order_detail.text34'),
  				package_id:0,
      		},
      		orderParam:{},
      		imgs:[],
      		totalAmount:0,
      		checkedOption: 0,
      		packages:{},
      		checkedPackageId: 0,
      		money:0,
      		checkedValue: 0,
      		from: '',
      		order_id: 0,
      		images:[],
      		imageCount:0,
      		disabled: false,
		});
		const showMenu = ref(proxy.$menus);
		provide('showMenu', showMenu);
		data.order_id = route.query.order_id;
		console.log(data.order_id,"order_id");
		const outerStyleStr = ref("position:relative;left:10%;top:40%;transform:translate(-10%,-40%);width:auto;");
		provide('outerStyleStr', outerStyleStr);

		const styleStr = ref("height:45px;");
		provide('styleStr', styleStr);

		const showSearchText = ref(false);
		provide('showSearchText', showSearchText);

		const getImageUrl = (name) => {
		    return proxy.$utils.getImageUrl(name);
		};
		
		const store = useStore();
		
		
		bus.emit('needRefresh', true)

		
        const OrderInfo = (order_id) => {
        	store.dispatch("common/getOrderInfo",{
				id: order_id
			}).then((res)=>{
				console.log("orderinfo", res);
				data.orderInfo = res;
				data.totalAmount = res.total;
				data.imageCount = res.order_items.length;
				res.order_items.forEach((item,index)=>{
					let obj = {
						image_id: item.image_id,
						option:[item.option]
					}
					data.images.push(obj);
				})
				if(res.status == 0){
					PreCreate(data.images,data.selectedPaytype)
				}
				bus.emit('showFooter',true);
			})
        }
        const formatDate = (time) => {
        	if(!time) return '';
			const date = new Date(time*1000);
			const year = date.getFullYear();
			const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以加1，并用0填充
			const day = String(date.getDate()).padStart(2, '0'); // 用0填充
			const hours = String(date.getHours()).padStart(2, '0'); // 用0填充
			const minutes = String(date.getMinutes()).padStart(2, '0'); // 用0填充
			return `${year}-${month}-${day} ${hours}:${minutes}`;
		}

		const PreCreate = (images,selectedPaytype) =>{
			console.log(images,"images-pre");			
			store.dispatch("user/PreCreate",{
				images: images,
			}).then((res)=>{
				console.log(res,"preOrder");
				// console.log(selectedPaytype.id,"selectedPaytype-id");
				if(selectedPaytype.id == 1){
					if(Object.keys(res.payInfo.wallet.package).length > 0){
						data.packages = JSON.parse(JSON.stringify(res.payInfo.wallet.package));
						data.payInfo = JSON.parse(JSON.stringify(res.payInfo));
						console.log(data.packages,"packages");
						if(parseInt(data.checkedPackageId)>0){
							data.totalAmount = data.packages[data.checkedPackageId].price;
														
						}else{

							data.totalAmount = res.payInfo.wallet.balance.price;
							
						}

					}else{
						data.payInfo = JSON.parse(JSON.stringify(res.payInfo));
						data.totalAmount = res.payInfo.wallet.balance.price;
						
					}
					
				}else if(selectedPaytype.id == 2){
					data.totalAmount = res.payInfo.wechat;
				}else if(selectedPaytype.id == 3){
					data.totalAmount = res.payInfo.alipay;
				}
			}).catch(err=>{
				ElMessage({
			        message: err.message,
			        type: "warning",
			    });
			})
		
        }
        const Wallet = () => {
			store.dispatch("user/Wallet",{

			}).then((res)=>{
				// console.log(res,"wallet");
				data.money = res.wallet.money
				data.paytype = data.paytype.map((item)=>{
					if(item.id == 1){
						item.text = proxy.$t('order_detail.text33')+' <span style="color:rgb(230, 0, 18);font-weight:bold;">￥'+res.wallet.money+'</span>。'+proxy.$t('order_detail.text34');
					}
					return item;
				})
				// console.log();
			}).catch(err=>{
				ElMessage({
			        message: err.message,
			        type: "error",
			    });
			})
		}


	    onMounted(async()=>{
	    	Wallet()
	    	OrderInfo(data.order_id)
	    	
        	await nextTick()
        	setTimeout(() => {
		      data.isLoading = false;
		    }, 600);
		    console.log("页面数据更新完毕了")
        })

        onUnmounted(() => {
		    window.addEventListener("beforeunload", (e) => {
		    	
		    });
		    window.addEventListener("unload", (e) => {
		    	
		    });
		})

		const viewBox = ref(null);
        const scroll = (val) => {
			if(val == 'top'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		       
			}else if(val == 'bottom'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
   
			}
		}


		const refData = toRefs(data);

		return {
			...refData,		
			proxy,
			viewBox,
			scroll,
			OrderInfo,
			formatDate,
			PreCreate,
			Wallet,
		}
	},
	created() {

	},
	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	},
	watch:{
		
		checkedPackageId:{
			handler(value) {
				// console.log(value,"checkedPackageId")
				// console.log(this.packages,"packages");
				let checkedItem = {};
				for(let i in this.packages){
					if(this.packages[i].package_id == value){

						// checkedItem = this.packages[i];
						if(this.packages[i].status == -1){
							ElMessage({
						        message: this.packages[i].text,
						        type: "warning",
						    });	
						    
						}else{
							this.totalAmount = this.packages[i].price;
							
						}
						
						break;
					}
				}
				
				
			}
		}
	},
	methods: {
		init() {
			
		},
		
		topay(orderInfo){
			let that = this;
			
			if(undefined === this.$cookies.getToken()){
				sessionStorage.setItem('redirectPath', this.$route.fullPath);
				bus.emit('showLoginDialog', true)
				bus.emit('showLoginDialogMobile', false)
        		bus.emit('showLoginDialogQrcode', true)
			} else {
				this.disabled = true;
				if(this.selectedPaytype.id == 1){
	        		this.$store.dispatch("user/getPay",{
						oid: this.orderInfo.id,
						ptype: this.selectedPaytype.id,//1钱包支付 2微信支付 3支付宝支付
						package_id: this.checkedPackageId,
					}).then((res2)=>{	
						this.disabled = false;
						console.log(res2,"balance pay");	
						if(res2.status == 1){
							ElMessage({
						        message: that.$t('order_detail.text35'),
						        type: "success",
						    });	
						    
						    that.$router.push({ path:'/my/contract',query:{order_id: that.orderInfo.id} });
						}else{
							ElMessage({
						        message: that.$t('order_detail.text36'),
						        type: "error",
						    });	
						}	
											
					}).catch(err=>{
						ElMessage({
					        message: err.message,
					        type: "error",
					    });	
					})
	        	}else if(this.selectedPaytype.id != 1){
	        		that.$router.push({ name:'pay_qrcode', query:{ oid: this.orderInfo.id,selectedtype: this.selectedPaytype.id } });
	        		
	        	}
				
			}
			
		},
		toPicDetail(id){
			this.$router.push({ name:'topic_pic_detail', query:{ id: id } });
		},
		selectPayType(item) {
			this.selectedPaytype = item;
			this.PreCreate(this.images, this.selectedPaytype);
		},
		activeIcon(name) {
			console.log(name,"name");
			return [name];
		},
	},
})