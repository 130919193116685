export default {
    login: {
        login: 'sich anmelden',
        userName: 'Benutzer-ID',
        password: 'kryptographisch',
        name: 'Benutzername oder Firmenname',
    },
    captcha: 'CAPTCHA',
    forgetPassword: 'Haben Sie Ihr Passwort vergessen?',
    loginTip: 'Das aktuelle Anmeldeergebnis ist zufällig. Der Verifizierungscode ist zufällig.',
    editpassword: 'Ihr Passwort ändern',
    errMsg: {
        inputRequired: 'Bitte eingeben{cont}',
        selectRequired: 'bitte auswählen{cont}',
    },
    uernameTips: 'Für einzelne Benutzer geben Sie bitte den Benutzernamen und für Firmenkunden den Firmennamen ein.',

    title: 'Bild Qingdao',
    logo_small_text:'Betrieb und Wartung des internationalen Informationszentrums von Qingdao',
    hello: 'Hallo.',
    loginOrRegister: 'Anmeldung / Registrierung',
    anonymity: 'anonymer Benutzer',
    personCenter: 'persönliches Zentrum',
    logout:'Abmelden',
    toLogin: 'Bitte melden Sie sich zuerst an',
    mobileTip: 'Mobiltelefonnummer',
    submitText: 'vorlegen (einen Bericht usw.)',
    accountTip: 'Benutzername/Mobiltelefonnummer',
    password: 'kryptographisch',
    newPassword: 'Neues Passwort',
    confirmPassword: 'Neues Passwort bestätigen',
    noAccount: 'Sie haben noch kein Konto?',
    hasAccount: 'Sie haben bereits ein Konto?',
    register: 'Einschreibung',
    phoneNumber: 'Mobiltelefonnummer',
    email: 'Posteingang',
    organizationName: 'Name der Einheit',
    loginNow:'Jetzt anmelden',
    accountLogin: 'Konto-Anmeldung',
    loginSuccess: 'Anmeldung erfolgreich',
    loginFailed: 'Anmeldung fehlgeschlagen',
    registerSuccess: 'Erfolgreiche Registrierung',
    registerFailed: 'Registrierungsfehler',
    enterUsername: 'Bitte geben Sie Ihren Benutzernamen ein',
    enterPhoneNumber: 'Bitte geben Sie Ihre Mobiltelefonnummer ein',
    enterRealname: 'Bitte geben Sie Ihren richtigen Namen an',
    enterCorrectUnitName: 'Bitte geben Sie den korrekten Namen der Einheit an',
    enterCorrectIdcard: 'Bitte geben Sie die richtige ID-Nummer ein',
    enterCorrectInstitutionName: 'Bitte geben Sie die richtige Organisationsnummer ein',
    enterCorrectPhoneNumber: 'Bitte geben Sie die richtige Handynummer ein',
    enterCorrectEmail: 'Bitte geben Sie die korrekte E-Mail Adresse ein',
    enterCorrectUnitName: 'Der Name des Geräts kann aus chinesischen Zeichen oder einer Kombination aus Buchstaben und Zahlen bestehen.',
    enterCorrectUsernameAndPhone: 'Bitte geben Sie Ihren Benutzernamen/Ihre Handynummer ein',
    nameContain: 'Der Benutzername muss Buchstaben oder Zahlen enthalten',
    passwordContain: 'Das Passwort erfordert eine Kombination von mindestens 8-Ziffern, Buchstaben und Sondersymbolen (# $% ^&*? _)',
    passwordContain2: '密码需要至少8位数字、字母、特殊符号!@#$%^&*?-_组合',
    enterPassword: 'Bitte geben Sie Ihr Passwort ein',
    passwordLength: 'Passwortlänge sollte mindestens 8-stellig sein',
    qrlogin: 'Zum Einloggen wischen',
    updateSuccess: 'Erfolgreiche Aktualisierung',
    retrievePassword: 'Passwort abrufen',
    pageUpdated: 'Die Seitendaten wurden aktualisiert.',
    cart1: 'Wagen',
    onlineService: 'Online-Kundendienst',
    newsCenter: 'Betrieb und Wartung des internationalen Informationszentrums von Qingdao',
    qdnews: 'Internationales Informationszentrum Qingdao',
    hostUnit: 'Hosting:',
    contactPhone: 'Rufnummer',
    workTime: 'Arbeitszeiten',
    footerText1: 'Große Menge an hochauflösendem Material',
    footerText2: 'Für die kommerzielle Nutzung urheberrechtlich geschützt',
    aboutus: 'Über uns',
    copyrightNotice: 'urheberrechtliche Erklärung',
    questions: 'allgemeine Probleme',
    terms: 'Nutzungsbedingungen',
    selfRegulatory: 'Konvention zur Selbstdisziplinierung für Website-Plattformen',
    contractUs: 'Kontakt',
    friendLink:'Freundlicher Link',
    followUs: 'Folgen Sie uns auf',
    copyright:'Bild Qingdao Alle Rechte vorbehalten',
    ICP: 'Lu ICP Nr. 2023012351-1',
    category:'Kategorisierung',
    pv:'Ansichten insgesamt:',
    uv:'Einschreibungen insgesamt:',
    qrcode:'Code zum Einloggen scannen',
    loadmore: 'Mehr laden',
    theEnd: 'Ende',

    codeLogin:'Anmeldung zum Verifizierungscode',
    getCode:'Überprüfungscode erhalten',
    enterCode:'Bitte geben Sie den Bestätigungscode ein',
    codetip:'Prüfcode',
    viewmore:'Mehr anzeigen',

    menus: {
        home: 'Startseite',
        news: 'Videospur',
        pic: 'Fotos',
        video: 'Videos',
        vr: 'Digitales Museum',
        grapher: 'Kameraleute',
        active: 'Funktion',
        more: 'Weitere Dienstleistungen',
        package: 'Preispakete',
        subject: 'Spezial',
    },
    contract:{
        tip1:'Details zum vertrag.',
        tip2:'Unterzeichne den vertrag.',
        tip3:'Download vertrag',
        tip4:'Um die wirksamkeit und den folgeschutz des vertrags zu garantieren, werden bei späteren aufkäufe automatisch die angaben angaben über die person ausgegeben, die sie besitzen.',
        tip5:'ID, bestellung.',
        tip6:'Bitte geben sie eine bestellung ein',
        tip7:'Welche art Von profil?',
        tip8:'Ein persönlicher anwender.',
        tip9:'Die agenturen.',
        tip10:'Ihren echten.',
        tip11:'Bitte geben sie ihren richtigen namen ein',
        tip12:'Dienstnummer, bitte.',
        tip13:'Bitte geben sie eine identifizierungsnummer ein',
        tip14:'Organisationen.',
        tip15:'Bitte geben sie eine gewebestruktur ein',
        tip16:'Agentin nummer.',
        tip17:'Geben sie die institut-nummer ein',
        tip18:'Briefkasten.',
        tip19:'Bitte geben sie ihren briefkasten ein.',
        tip20:'Erstellung der vertragvorlage',
        tip21:'Halten sie die linke maustaste gedrückt, um ihre unterschrift im unteren bereich des roten umrandes zu schreiben',
        tip22:'Unterschrift erstellen',
        tip23:'Leere die brett',
        tip24:'Download abgeschlossen.',
        tip25:'Bitte wählen sie meinen verwender',
        tip26:'Bitte geben sie ihren richtigen namen ein',
        tip27:'Bitte geben sie eine identifizierungsnummer ein',
        tip28:'Bitte geben sie den namen der einrichtung ein',
        tip29:'Geben sie die institut-nummer ein',
        tip30:'Bitte geben sie ihren briefkasten ein.',
        tip31:'Die erhaltung der vertragsdaten ist fehlgeschlagen',
        tip32:'Die beschaffung der informationen zum vertrag ist fehlgeschlagen',
        tip33:'Download fehlgeschlagen',

    },
    recharge:{
        tip1:'Fakten über das einkommen',
        tip2:'Eine übersicht der einnahmen',
        tip3:'Unberechnungsbetrag $1.000',
        tip4:'Getilgte summe (in dollar)',
        tip5:'Antrag abgerechnet.',
        tip6:'Es ist befehlen.',
        tip7:'Name des buches?',
        tip8:'Ich bezahle für zeit.',
        tip9:'Der umfang der bestellung',
        tip10:'Anteil an den einnahmen:',
        tip11:'Bestellung nr. 1:',
        tip12:'Abrechnungen bezahlt.',
        tip13:'Antrag auf zahlungsfrist.',
        tip14:'Stand der abrechnung',
        tip15:'Das buch zu schreiben?',
        tip16:'Getilgt Von der rechnung.',
        tip17:'In kürze.',
        tip18:'Ln der buchhaltung',
        tip19:'Buchhaltung abgeschlossen.',
        
    },

    search: {
        search: 'Suche',
        hotSearch: 'Beliebte Suchen',
        takePic: 'Bildersuche durchführen',
        dragPic: 'Ziehen Sie das Bild in diesen Bereich und legen Sie es dort ab.',
        or: 'vielleicht',
        clickUpload: 'Zum Hochladen klicken',
        sizeLimit: 'Verwenden Sie jpg/png-Dateien mit einer Größe von weniger als 50 MB',
        searchPic: 'Suche nach einem Bild',
        searchVideo: 'Suchvideo',
        pic: 'Foto',
        video: 'Video',
        trySearch:'Versuchen Sie, nach Schlüsselwörtern zu suchen',
        scenery: 'hoch geachtet sein',
        building: 'Konstruktionen',
        culture: 'Kulturen',
        humanities: 'Geisteswissenschaften',
        educate: 'unterrichten',
        isJPG: 'Laden Sie Bilder nur im JPG- oder PNG-Format hoch!',
    },
    sideMenu: {
        apply: 'Beiträge von Fotografen',
        account: 'Kontoverwaltung',
        wallet: 'Mein Portemonnaie.',
        cart: 'Wagen',
        authRecords: 'Berechtigungsnachweise',
        orderlist:'Liste bestellen',
        collectManage: 'Aufforderung zu Aktivitäten',
        collect: 'Tätigkeiten',
        folder: 'Ereignissammlung',
        personCenter: 'Photographer Center',
        applyInfo: 'Informationen zum Eintrag',
        workManage: 'Meine Arbeit',
        recharge: 'Umsatzzentrum',
    },
    person: {
        personCenter: 'Schöpferisches Zentrum',

    },
    application: {
        settled: 'Fotografen an Bord',
        process: 'Eingabeprozess',
        plain: 'Anweisungen zur Eingabe',
        apply: 'Bewerbung einreichen',
        result: 'Einreichung der Ergebnisse',
        tipText1: 'Wir heißen Sie herzlich willkommen, sich als kreativer Fotograf in Qingdao zu bewerben. Um die Erfolgsquote Ihrer Bewerbung zu erhöhen, geben Sie bitte die korrekten Daten an.',
        tipText2: 'Wir werden Ihren Antrag innerhalb von 3 Arbeitstagen beantworten. Sie können den Fortschritt hier überprüfen.',
        apply_enter: 'Bewerbung für Emplacement',
        base_info: 'Bitte füllen Sie die grundlegenden Informationen aus',
    },
    form: {
        user_type: 'Kundentyp',
        license: 'Gewerbeerlaubnis',
        licenseTip: 'Elektronische Version der gestempelten Geschäftslizenz der Einheit (oder elektronische Version des gestempelten Organisationscodezertifikats)',
        type1: 'persönlich',
        type2: 'Organisation der Einheit',
        unitName:'Name der Einheit',
        unitTip:'Bitte geben Sie den Namen der Organisation ein',
        realname: 'Echter Name',
        enterRealname: 'Bitte geben Sie Ihren richtigen Namen ein',
        gender: 'Unterscheidung zwischen den Geschlechtern',
        male: 'männlich',
        female: 'Tochter',
        photo: 'persönliches Foto',
        enterPhoto: 'Bitte laden Sie Ihr persönliches Foto hoch',
        uploadPhoto: 'Fotos hochladen',
        biography: 'kurze Einführung',
        enterBiography: 'Bitte füllen Sie die Einleitung aus',
        mobile: 'Mobiltelefonnummer',
        enterPhone: 'Bitte geben Sie Ihre Mobiltelefonnummer ein',
        email: 'E-Mail Adresse',
        enterEmail: 'Bitte geben Sie Ihre E-Mail Adresse ein',
        company: 'Name der Einheit',
        enterCompany: 'Bitte geben Sie den Namen der Organisation ein',
        enterFeedType: 'Bitte wählen Sie die Art des Beitrags',
        ctype: 'Art der Praxis',
        media: 'Medien, insbesondere Nachrichtenmedien',
        non_media: 'Nicht-Medien',
        worktype: 'Art des Beitrags',
        image: 'Foto',
        video: 'Video',
        workPic: 'Bilder von repräsentativen Werken',
        workPicTip: '(Die repräsentativen Arbeiten dienen nur als Referenz für die Eingangsprüfung; das Hochladen von qualitativ hochwertigen repräsentativen Arbeiten wird dazu beitragen, die Erfolgsquote des Eintrags zu erhöhen)',
        uploadPic: 'Ein Bild hochladen',
        againUploadPic: 'Bilder weiter hochladen',
        againUploadVideo: 'Videos weiter hochladen',
        limit_pic_quantity: '(Bitte legen Sie 10 Originalbilder vor)',
        workVideo: 'Video-Darstellungen',
        uploadVideo: 'Video hochladen',
        limit_video_quantity: '(Bitte stellen Sie 3 Originalvideos zur Verfügung)',
        submit_apply: 'Bewerbung einreichen',
        tip_text_1: 'Ihre Eintragsinformationen werden gerade überprüft...',
        tip_text_2: 'Wir werden Ihre Angaben innerhalb von 3 Arbeitstagen überprüfen (bei nationalen Feiertagen wird der Termin verschoben)',
        tip_text_3: 'Die Ergebnisse der Überprüfung können Sie auf dieser Seite einsehen!',
        uploadPicWork: 'Bitte laden Sie ein Bild Ihrer Arbeit hoch',
        uploadVideoWork: 'Bitte Videobeiträge hochladen',
        enter_limit_pic: 'Bitte 10 Bilder hochladen',
        enter_limit_video: 'Bitte laden Sie 3 Videos hoch',
        submited: 'Antrag eingereicht',
        only_ten_pic: 'Es können nur 10 Bilder hochgeladen werden',
        only_three_video: 'Es können nur 3 Videos hochgeladen werden',
        idcard: 'Ausweisnummer',
        idcard_photo: 'Foto-ID',
        enter_idcard: 'Bitte geben Sie Ihre ID-Nummer ein',
        idcard_tip: '(Bitte laden Sie Ihren Personalausweis Avatar hoch)',
        bankcard: 'ATM-Kartennummer',
        enter_bankcard: 'Bitte geben Sie Ihre Bankkartennummer ein',
        bankcard_photo:'Fotos von Bankkarten',
        bankcard_tip:'(Bitte laden Sie ein Foto mit Ihrer Bankkartennummer hoch)',
        preview:'Vertrag einreichen und ansehen',
        enterCorrectbankcardNumber: 'Bitte geben Sie die korrekte Bankkartennummer ein',
        enterIdcardPhoto: 'Bitte laden Sie die Vorderseite Ihres Personalausweises hoch',
        enterBankcardPhoto: 'Bitte laden Sie die Vorderseite Ihrer Bankkarte hoch',
    },
    apply_contract:{
        info: 'Informationen zum Vertrag',
        sign: 'Unterzeichnung des Vertrags',
        tip1: 'Um die Rechte beider Parteien zu gewährleisten, geben Sie bitte Ihre Identitätsdaten und Ihre Bankkarteninformationen ein, um einen elektronischen Vertrag zu erstellen, der nach seiner Unterzeichnung zustande kommt.',
        tip2: '*Die folgenden Angaben werden nur für den Vertragsabschluss und die Verdienstabrechnung verwendet und können nach Vertragsabschluss nicht mehr geändert werden. Bitte füllen Sie diese Angaben sorgfältig aus!',
        tip3: 'Um die gesetzlichen Rechte beider Parteien zu gewährleisten, lesen Sie bitte den Inhalt des Vertrages sorgfältig durch und können ihn nach Abschluss der Unterzeichnung nicht mehr ändern!',
        tip4: 'Bitte füllen Sie die Vertragsinformationen aus',
        tip5: 'Vertragsvorschau',
        tip6: 'Bitte schreiben Sie Ihre Unterschrift handschriftlich, indem Sie die linke Maustaste in dem rot umrandeten Bereich unten gedrückt halten.',
        tip7: 'Signatur generieren',
        tip8: 'Leeren Sie das Reißbrett',
        signSuccess:'Unterschrift erfolgreich',
        noMatch:'Unterschiedliche Übereinstimmung mit Identitätsinformationen',
        submit_success:'Erfolgreich eingereicht',
        idcard_face:'Bitte laden Sie das vordere Foto Ihres Personalausweises hoch (oder laden Sie es erneut hoch)',
        upload_fail:'Hochladen fehlgeschlagen',

    },
    apply_info: {
        tip1:'Willkommen bei Image Qingdao, Sie können Ihre Arbeit hochladen und haben die Chance, nach der Genehmigung Geld zu verdienen!',
        tip2:'Informationen zum Eintrag',
        tip3:'Klicken Sie hier, um den Vertrag zu sehen',
        tip4: 'elektronischer Vertrag',
        
    },

    apply_nopass: {
        tip1:'Ihr Antrag auf Zulassung wurde nicht genehmigt...',
        tip2:'Nach einer umfassenden Bewertung Ihrer Bewerbung durch unsere Experten erfüllen Sie derzeit nicht die Zulassungsbedingungen!!',
        tip3:'Sie können versuchen, eine qualitativ bessere Darstellung einzureichen, und wir werden sie erneut umfassend bewerten.~',
        tip4:'Wiedervorlage',
    },
    apply_pass: {
        tip1: 'Herzlichen Glückwunsch, Ihre Bewerbung wurde genehmigt!!',
        tip2: 'Nachdem Sie die Informationen ausgefüllt haben, können Sie Ihre Arbeit hochladen und haben so die Chance, Geld zu verdienen!~',
        tip3: 'Unterzeichnete Beiträge',
    },
    apply_passing: {
        tip1:'Ihre Eintragsinformationen werden gerade überprüft...',
        tip2:'Wir werden Ihre Angaben innerhalb von 3 Arbeitstagen überprüfen (bei nationalen Feiertagen wird der Termin verschoben)',
        tip3:'Die Ergebnisse der Überprüfung können Sie auf dieser Seite einsehen',
    },
    apply_common: {
        del:'Diesen Artikel löschen',
        addimg:'Bild hinzufügen',
        addvideo:'Video hinzufügen',
        batch_pics:'Ausfüllen des Chargenbildes mit einheitlichen Informationen',
        batch_videos:'Batch Video einheitliche Informationen ausfüllen',
        search_subject:'Suche nach Themennamen',
        upload_success:'Erfolgreich hochgeladen, wartet auf Überprüfung',
        upload_pic_eight:'Hochladen von bis zu 8 Bildern auf einmal',
        upload_video_eight:'Bis zu 8 Videos gleichzeitig hochladen',
        size_exceed:'Größe darf 50M nicht überschreiten',
        submit_success:'Erfolgreich abgeschickt',
        agreement:'Ich habe gelesen und stimme zu',
        protocols:'Lizenzvereinbarung für die Lizenzierung von Image Qingdao-Inhalten',
        read:'Bitte lesen Sie zuerst den Image Qingdao Content Licence Agreement und stimmen Sie ihm zu!',

        selectAll: 'einstimmig',
        off_pic: 'Die Bilder werden automatisch von der Website entfernt, wenn Sie auf „Entfernen“ klicken. Sind Sie sicher, dass Sie die Bilder entfernen wollen?',
        del_pic: 'Die Bilder werden nach dem Herunterklicken automatisch von der Website gelöscht, sind Sie sicher, dass sie gelöscht werden?',
        batch_off_pic: 'Charge aus den Regalen (eines Produkts)',
        batch_del_pic: 'massenhaftes Löschen',
        season: 'Der Grund:',
        edit: 'Compiler',
        off: 'aus den Regalen zu nehmen (z. B. ein kontaminiertes Produkt)',
        del: 'entfernen',
        off_video: 'Die Videos werden automatisch von der Website entfernt, wenn Sie auf „Herunternehmen“ klicken. Sind Sie sicher, dass Sie die Videos herunterladen können?',
        del_video: 'Das Video wird von der Website gelöscht, nachdem Sie auf Löschen geklickt haben. Sind Sie sicher, dass das Video gelöscht wird?',
        off_item: 'Bitte wählen Sie die Option, die entfernt werden soll',
        had_off: 'Das Werk wurde abgenommen',
        del_item: 'Bitte wählen Sie die zu löschenden Optionen aus',
        had_del: 'Die Arbeit wurde gelöscht',
        del_tip: 'Klicken Sie auf Löschen und dann auf Nicht wiederherstellbar, sicher löschen?',
        edit_folder:'Editorische Zusammenstellung',
        add_folder:'Kompilierung hinzufügen',
        enter_folder_title: 'Bitte geben Sie einen Kompilationstitel ein',
        enter_keyword_tip:'Drücken Sie für jedes eingegebene Stichwort die Eingabetaste.',
        add_to_folder:'Zur Kompilation hinzufügen',
        select_folder:'Bitte wählen Sie eine Zusammenstellung',
        author:'Autor',
        enter_author:'Bitte geben Sie den Autor ein',
        create_date:'Datum der Erstellung',

    },
    apply_work: {
        year_limit: 'Jahr kann nur eine Zahl sein',
        tip0:'Bildnachweis',
        tip1:'Entschuldigung, Sie haben keine Bildrechte beantragt',
        tip2:'Tipp: Wenn Sie Bilder einreichen müssen, bewerben Sie sich bitte erneut um einen Vertrag!',
        tip3:'neu unterzeichnen',
        tip4:'Bitte wählen Sie ein oder mehrere Bilder aus, es werden mehrere Bildformate unterstützt',
        tip5:'Bild Name',
        tip6:'Bitte geben Sie einen Bildnamen ein',
        tip7:'Ausgewählte Bildunterschriften',
        tip8:'Bitte geben Sie eine Bildunterschrift ein',
        tip9:'Thematische Schlüsselwörter',
        tip10:'Bitte geben Sie Stichworte ein',
        tip11:'Titel des Themas',
        tip12:'Bitte wählen Sie ein Thema',
        tip13:'Neue Themen hinzugefügt',
        tip14:'Kleines Bild Status',
        tip15:'Kleiner Bildpreis',
        tip16:'Bitte geben Sie den Preis für das kleine Bild ein',
        tip17:'Originalzustand',
        tip18:'Ursprünglicher Preis',
        tip19:'Bitte geben Sie den Preis des Originalbildes ein',
        tip20:'Big Picture Status',
        tip21:'Big Picture Preise',
        tip22:'Bitte geben Sie den Preis für das große Bild ein',
        tip23:'(Computing) aktivieren (eine Funktion)',
        tip24:'die Verwendung von etw. untersagen',
        tip25:'Zur Überprüfung einreichen',

        tip26:'Videobeitrag',
        tip27:'Entschuldigung, Sie haben keinen Videozugang beantragt',
        tip28:'Warmer Tipp: Wenn Sie einen Videobeitrag benötigen, bewerben Sie sich bitte erneut um einen Vertrag',
        tip29:'Bitte wählen Sie ein oder mehrere Videos aus, um eine Vielzahl von Videoformaten zu unterstützen',
        tip30:'Video Name',
        tip31:'Bitte geben Sie einen Videonamen ein',
        tip32:'Video-Beschreibung',
        tip33:'Bitte geben Sie eine Videobeschreibung ein',
        tip34:'Titel des Themas',
        tip35:'Bitte geben Sie einen Themennamen ein',
        tip36:'Thematische Einordnung',
        tip37:'Bitte wählen Sie eine thematische Kategorie',
        tip38:'Thematische Abdeckung',
        tip39:'Thematische Beschreibung',
        tip40:'Bitte geben Sie eine Beschreibung des Themas ein',
        tip41:'vorlegen',
        tip42:'Bitte laden Sie ein Titelbild hoch',

        more:'Mehr laden',

        price_pack: 'Preispakete',
        select_price_pack: 'Bitte wählen Sie ein Preispaket',
        pic_limit: 'Bis zu 8 Bilder hochladen',
        video_limit: 'Bis zu 8 Videos hochladen',
        topic_price_pack: 'Feature Cover Preispaket',
        year:'Jahr',
        yearTip:'Bitte geben Sie ein Jahr ein',
        coverTip: 'Dies ist nur ein Titelbild, es muss in der Bildübermittlung erneut gesendet werden',
        has_similar: 'Bitte keine doppelten Bildarbeiten hochladen',
        similar: 'Bilder duplizieren',
    },
    no_settled: {
        tip1:'Es tut mir leid, aber Sie können keinen Beitrag leisten, solange Sie nicht dabei sind ~',
        tip2:'Sie sind noch nicht als offizieller Fotograf zertifiziert',
        tip3:'Fotografen an Bord',
    },
    index: {
        hot: 'Top-Bilder',
        grapher:'talentierte Fotografin',
        image: 'Foto',
        video: 'Video',
    },
    news: {
        news:'Videospur',
        publish_time: 'Zeit der Veröffentlichung:',
    },
    package: {
        name: 'Name des Pakets',
        content: 'Exklusive Inhaltsrechte',
        expire: 'Fristen',
        day: 'Tag',
        buy: 'Pakete kaufen',
        between: 'Umfang der Befugnisse',
        min: 'kleines Bild',
        original: 'Originalfigur',
        max: 'atlas',
        tip1: 'RMB 1500/Stück',
        tip2:'RMB 2200/Stück',
        tip3: 'Wischen Sie zum Bezahlen',
        order_sn: 'Bestellnummer:',
        price:'Betrag bestellen:',
        open:'zeigen (eine Eintrittskarte)',
        scan: 'Scannen von Codes zur Bezahlung',
        wechatPay: 'WeChat Zahlung',
        alipay: 'Alipay-Zahlung',
        weixin: 'microsoft',
        ali:'Alipay',
        select:'Bitte wählen Sie ein Paket',
        tip4: 'Bild',
        tip5: 'Ein einzelnes Video',
        tip6: 'Video-Pakete',
    },
    grapher: {
        title: 'Kameraleute',
        home:'Startseite',
        grapher:'talentierte Fotografin',
        tip1:'(Die Rangfolge ist nicht nach Familiennamen geordnet)',
        tip2:'Bild Qingdao-Fotograf',

    },
    search_pic:{
        tip1:'Ähnliche Bilder',
        tip2:'einen Termin finden',
        tip3:'Eintrag',
    },
    topics:{
        tip1:'Verwandte Themen',
        home:"Startseite",
    },
    pic:{
        tip1:'Derzeitiger Standort:',
        tip2:'Beschreibung:',
        tip3:'Lizenzgebührenfreie ("RF") Lizenzen, unbegrenzte und unbefristete Nutzung von nicht-RF-lizenzierten Inhalten, Preisgestaltung auf der Grundlage der Dateigröße und kein Wasserzeichen nach dem Download. Weitere Informationen finden Sie unter:',
        tip4:'ImageQingdao Lizenzvertrag für Inhalte',
        tip5:'Zum Warenkorb hinzufügen',
        tip6:'Einzelkauf',
        tip7:'Für Anfragen und Bestellungen rufen Sie bitte 0532-82825531 an oder konsultieren Sie uns.',
        tip8:'Online-Kundendienst',
        tip9:'Bild-ID:',
        tip10:'Bildunterschrift:',
        tip11:'Bildspezifikationen:',
        tip12:'Bild Abmessungen:',
        tip13:'Bildtyp:',
        tip14:'Bildgröße:',
        tip15:'Bildpreis:',
        tip16:'Bild Jahr:',
        tip17:'Bildunterschrift:',
        tip18:'Stichwort',
        tip19:'Ähnliche Bilder',

    },
    video: {
        text1: 'Video-ID:',
        text2: 'Video Titel:',
        text3: 'Video-Typ:',
        text4: 'Dauer des Videos:',
        text5: 'Video Größe:',
        tip1: 'Stichwort',
        tip2: 'Ähnliche Video-Empfehlungen',
        desc: 'kurze Einführung',
    },
    vr: {
        text1: 'VR-Ausstellungsraum',
        text2: 'Einzelheiten',
    },
    order_detail: {
        text1: 'Details zur Bestellung',
        text2: 'Beschreibung der Dienstleistung',
        text3: 'Für die Rechnungsstellung wenden Sie sich bitte an den Kundendienst:',
        text4: 'Bestellnummer:',
        text5: 'Betrag bestellen:',
        text6: 'Gezahlter Betrag:',
        text7: 'Zahlungsstatus:',
        text8: 'in Erwartung der Zahlung',
        text9: 'bezahlt',
        text10: 'Zahlungsarten:',
        text11: 'Produkt- oder Dienstleistungspaket (z. B. für ein Handy-Abo)',
        text12: 'WeChat Zahlung',
        text13: 'Alipay-Zahlung',
        text14: 'Saldo (eines Kontos, einer Rechnung usw.)',
        text15: 'Zahlungsfrist:',
        text16: 'Zeit, eine Bestellung aufzugeben:',
        text17: 'Bild-Informationen',
        text18: 'Insgesamt fällig:',
        text19: 'Bildvorschau',
        text20: 'Bild Name',
        text21: 'Bild-Spezifikationen',
        text22: 'Bild Preise',
        text23: 'Zahlungsmethode auswählen',
        text24: 'Wählen Sie ein zu bezahlendes Paket',
        text25: 'Betrag aus eigener Tasche:',
        text26: 'Bestellung abschicken',

        text27: 'Auszahlung der Kontosalden',
        // text28: '账户可用余额 <span style="color:rgb(230, 0, 18);font-weight:bold;">0.00</span>元。如果您已在【价格套餐】页面购买了套餐包，请优先选择套餐包支付。',
        text29: 'WeChat Zahlung',
        text30: 'Unterstützung von WeChat Quick Pay',
        text31: 'Alipay-Zahlung',
        text32: 'Unterstützung von Alipay Quick Pay',

        text33: 'Verfügbarer Kontostand',
        text34: 'Wenn Sie bereits ein Paket auf der Seite [Preispaket] gekauft haben, zahlen Sie bitte vorrangig für das Paket.',
        text35: 'Zahlung abgeschlossen',
        text36: 'Fehlgeschlagene Zahlung',
        origin_1:'Originalbild:',
        small_1:'Kleines Bild:',
        big_1:'Das große Bild:',
        origin:'Originalfigur',
        small:'kleines Bild',
        big:'atlas',
        maohao:'::',
    },
    order_pay: {
        text1: 'Informationen bestellen',
        text2: 'Vorausbezahlte Bestellungen',
        tip1: 'Bitte wählen Sie den Preis des Bildes',
        tip2: 'Wählen Sie nur eine',
    },
    order_list: {
        text0: 'Liste der bestellungen.',
        text1: 'Bildname:',
        text2: 'Erstellungszeit:',
        text3: 'nach etw. Ausschau halten',
        text4: 'Bestell-ID:',
        text5: 'Bestellnummer:',
        text6: 'Zeit, eine Bestellung aufzugeben:',
        text7: 'Auflösung:',
        text8: 'Details zur Bestellung',
        text9: 'Download Verträge',
        text10: 'Preis:',
        text11:'Startzeit',
        text12:'Endzeitpunkt',
        text13:'bis',
        wait_pay:'in Erwartung der Zahlung',
        pay_success:'Zahlung erfolgreich',
        paid:'bezahlt',
        package:'Produkt- oder Dienstleistungspaket',
        wechatPay:'WeChat Zahlung',
        alipay:'Alipay-Zahlung',
        balance:'Saldo',
    },
    setting: {
        text1:'Ihr Passwort ändern',
        text2:'Benutzerinformationen',
        text3:'Mobiltelefonnummer',

    },
    auth: {
        text1: 'Zulassungsliste',
        text2: 'Bildname:',
        text3: 'Zeit der Autorisierung:',
        text4: 'Bildvorschau',
        text5: 'Bild Titel',
        text6: 'Bild-Spezifikationen',
        text7: 'Bildauflösung',
        text8: 'Rigg',
        text9: 'Herunterladen von',


    },
    wallet: {
        text1: 'Mein Portemonnaie.',
        text2: 'verfügbares Guthaben',
        text3: 'Balance-Sätze',
        text4: 'Typologie',
        text5: 'Geldsumme',
        text6: 'mal',
        text7: 'Kein Saldo zu diesem Zeitpunkt verzeichnet',
    },
    cart: {
        text1: 'Wagen',
        text2: '(gesamt',
        text3: 'eine Position)',
        text4: 'Rigg',
        text5: 'Bildvorschau',
        text6: 'Bild Titel',
        text7: 'Bild-Spezifikationen',
        text8: 'Preise',
        text9: 'entfernen',
        text10: 'Ich habe gelesen und stimme zu',
        text11: 'Bild Qingdao Lizenzvereinbarung',
        text12: 'Image Qingdao Datenschutzerklärung',
        text13: 'Ausgewählte Produkte',
        text14: '(Gesamt-)Summe',
        text15: 'ein Konto schließen',
        text16: 'ein Ereignis',
        clearCart:'Warenkorb leeren',
        batchDel:'massenhaftes Löschen',

    },
    activity: {
        actName: 'an einer Aktivität teilnehmen',
        selectAct: 'Bitte wählen Sie den Aufruf zur Einreichung von Beiträgen',
        selectActName: 'Suche nach Ereignisnamen',
        category: 'Kategorisierung',
        select_cate:'Kategorie auswählen',
    },
    active: {
        upload: 'Ihre Arbeit hochladen',
        pic:'Foto',
        video: 'Video',
        recommend: 'Empfehlungsschreiben',
        tip7:'Bildunterschrift',
        tip9:'Stichwort',
        folder_title: 'Bildunterschrift',
        enter_title: 'Bitte geben Sie einen Titel ein',
        folder_desc: 'Beschreibungen',
        enter_desc: 'Kompilation Beschreibung',
        cover_type: 'Abdeckung Typ',
        folder_cover: 'Umschlag',
        cover_type_1:'Neueste Werke',
        cover_type_1_tip:'Verwenden Sie automatisch das letzte Werk in der Zusammenstellung als Cover der Zusammenstellung.',
        cover_type_2:'Feste Abdeckung',
        cover_type_2_tip:'Bilder lokal als festes Cover hochladen',
        operate_success: 'Die Operation war erfolgreich.',

    },
    status: {
        all:'vollständig',
        reviewing: 'in Prüfung',
        reviewed: 'Genehmigt',
        not_review: 'Fehlgeschlagene Prüfung',
    }


}