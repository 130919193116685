<template>
<SideComponent @scrollto="scroll"></SideComponent>
	<div class="container" ref="viewBox">
		<Header2 :show-search-bar-pic="showHeader" login-style="loginStyle" :mobile-icon-style="mobileIconStyle" :show-list-icon="showListIcon"/>
		<div class="content">
			<div style="height:80px;"></div>
			<div class="enclosure">
				<div class="inner">
					<div class="left">
						<SideMenu />
					</div>
					<div class="right">
						<div class="caption">
							<span>{{$t('person.personCenter')}}</span><span class="arrow"><i class="iconfont icon-jiantou2"></i></span><span>{{$t('sideMenu.apply')}}</span>
						</div>
						
						<div class="tag-outer">
							<div class="tag-item">
								<img src="@/assets/img/grapher_settled/no_settled.png"/>
							</div>
							<div class="tag-item">
								<span class="text-1">{{$t('no_settled.tip1')}}</span>
							</div>
							<div class="tag-item">
								<span class="text-2">{{$t('no_settled.tip2')}}</span>
							</div>
							<div class="tag-item">
								<div class="settled-btn" @click="toApplication()">{{$t('no_settled.tip3')}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer/>
	</div>
</template>

<script>
	import no_settled from './no_settled_controller';
	export default no_settled;
</script>

<style src="@/assets/css/my/grapher/no_settled.css" scoped>
	/*@import url("@/assets/css/style.css");全局引用css*/
	/*@import '@/assets/css/style.css';*/
</style>