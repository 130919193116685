<template>
<SideComponent @scrollto="scroll"></SideComponent>
	<div class="container" ref="viewBox">
		<Header2 :show-search-bar-pic="showHeader" login-style="loginStyle" :mobile-icon-style="mobileIconStyle" :show-list-icon="showListIcon"/>
		<div class="content">
			<div style="height:80px;"></div>
			<div class="enclosure">
				<div class="inner">
					<div class="top">
						<div class="item">
							<img src="@/assets/img/index/12.jpg"/>
						</div>
						<div class="item">
							<div class="subitem">{{username}}</div>
							<div class="subitem"></div>
						</div>
						<div class="item">
							<i class="iconfont icon-shezhi2"></i>
						</div>
					</div>
					<div class="left">
						<SideMenu />
					</div>
					<div class="exit">
						<span @click.stop="Exit()">退出登录</span>
					</div>
				</div>
			</div>
		</div>
		<Footer/>
	</div>
	
</template>

<script>
	import home from './home_controller';
	export default home;
</script>

<style src="@/assets/css/my/home.css" scoped></style>
<style src="@/assets/css/style_black.css" scoped></style>