import { createStore } from 'vuex';
import { 
  cameraList,
  cameraWork,
  cameraAlbum,
  cameraExcelent,
} from '@/api/common';

// import {PHOTO_CATS} from '@/store/mutation-types';
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

const state = {
  	token:'2f68dbbf-519d-4f01-9636-e2421668f379',
}
// 相当于事件
const mutations = {

}

const actions = { 
    getCameraList(context, param={}) {
      return new Promise((resolve, reject) => {
        cameraList(param).then((data)=>{        
          data.data.map((item)=>{
            if(item.description){
              item.description = item.description.slice(0,57);
            }
            
            return item;
          });
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err)
        });
      })
    },
    getCameraWork(context, param={}) {
      return new Promise((resolve, reject) => {
        cameraWork(param).then((data)=>{        
          // data.data.map((item)=>{
          //   item.url = '#/photo_grapher_detail/'+item.id;
          //   return item;
          // });
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err)
        });
      })
    },
    getCameraAlbum(context, param={}){
      return new Promise((resolve, reject) => {
        cameraAlbum(param).then((data)=>{        
          // data.data.map((item)=>{
          //   item.url = '#/photo_grapher_detail/'+item.id;
          //   return item;
          // });
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err)
        });
      })
    },
    getCameraExcelent(context, param={}){
      return new Promise((resolve, reject) => {
        cameraExcelent(param).then((data)=>{    
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err)
        });
      })
    },

}

const getters = { 
	  
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

