// import Search from '@/components/Search.vue'
import { nextTick, onMounted, ref, provide, reactive, toRefs, getCurrentInstance, onBeforeUpdate } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore, createNamespacedHelpers } from "vuex";
import PageLoading from '@/components/common/PageLoading.vue';
import Loading from '@/components/common/Loading.vue';
import Swiper from '@/components/layout/Swiper.vue';
import SearchBarPic from '@/components/layout/SearchBarPic.vue';
import Header2 from '@/components/layout/header2.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';
import axios from 'axios';
import bus from 'vue3-eventbus'
import SideComponent from '@/components/common/SideComponent.vue';

export default {
	name: 'photo_grapher',
	components: {
		Header2,
		Menu,
	    Swiper,
	    SearchBarPic,
	    Footer,
	    PageLoading,
	    SideComponent,
	    Loading,
	},
	setup(){	    
	    // 写法一 用了reactive，就不需要写ref
	    let {ctx, proxy} = getCurrentInstance();
      	const router = useRouter();
      	const route = useRoute();
      	const actid = route.query.id;
      	provide('logoColor', 'white');
	    const data = reactive({
	    	isLoading:true,
	    	active_menuid: 0,
			activeClass: 'active',
			isMobile: false,
      		show_menu: false,
      		active_tagid: 'pic',
      		ispic: true,
      		banners:[
      			
      		],
      		
      		picList: [],
      		videoList: [],
      		pagination: {},
      		currentTag: 0,
      		catid: 0,
      		picPage: 1,
      		videoPage: 1,
      		picNomore: true,
      		videoNomore: true,
      		keyword: '',
      		tag: 1,
      		tags:[
      			// {
      			// 	id: 1,
      			// 	name: "征集作品展示"
      			// },
      			// {
      			// 	id: 2,
      			// 	name: "征集启示"
      			// },
      		],
      		dialogTagId: 1,
      		dialogTags:[
      			{
      				id: 1,
      				name: "图片"
      			},
      			{
      				id: 2,
      				name: "视频"
      			},
      		],
      		showList: false,
      		showExcellent: false,
      		showRevelation: false,
      		isPicLoading: true,
      		isVideoLoading: true,
      		uploadVisible: false,
      		
      		actid: 0,
      		activityDetail: {},
	    });
	    data.actid = actid;
	    console.log(data.actid,"catid");
        const store = useStore();
        
        const fetchDetail = (id) =>{
	        store.dispatch("user/ActivityDetail",{
	        	id: id
	        }).then((res) => {
	        	console.log(res,"act detail");
	        	data.activityDetail = res;
	        	let obj1 = { id: 1, name: res.work_label }
	        	let obj2 = { id: 2, name: res.gift_label }
	        	let obj3 = { id: 3, name: res.content_label }
	        	if(res.work_state==1){
		        	data.tags.push(obj1);
		        	
		        }
	        	if(res.gift_state==1){
	        		data.tags.push(obj2);
	        		
	        	}
	        	if(res.content_state==1){
		        	data.tags.push(obj3);
		        	
		        }
		        if(res.work_state==1){
		        	data.showList = true;
		      		data.showExcellent = false;		      		
		      		data.showRevelation = false;
		      		data.tag = 1;
		        }else if(res.work_state!=1&&res.gift_state==1){
		        	data.showList = false;
		      		data.showExcellent = true;		      		
		      		data.showRevelation = false;
		      		data.tag = 2;
		      		bus.emit('showFooter',true);
		        }else if(res.work_state!=1 && res.gift_state!=1 && res.content_state==1){
		        	data.showList = false;
		      		data.showExcellent = false;		      		
		      		data.showRevelation = true;
		      		data.tag = 3;
		      		bus.emit('showFooter',true);
		        }

		        if(res.work_state==1){
		        	data.showList = true;
		      		// data.showExcellent = false;		      		
		      		// data.showRevelation = false;
		        }else if(res.work_state==0){
		        	data.showList = false;
		        }

		        if(res.gift_state==1){
		        	data.showExcellent = true;
		        }else if(res.gift_state==0){
		        	data.showExcellent = false;
		        }

		        if(res.gift_state==1){
		        	data.showExcellent = true;
		        }else if(res.gift_state==0){
		        	data.showExcellent = false;
		        }

		        bus.emit('showFooter',true);

	        })

        }
        
        const viewBox = ref(null);
        const scroll = (val) => {
			if(val == 'top'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		       
			}else if(val == 'bottom'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
 
			}
		}

		const getOneCollects = (actid,type=1,page=1) => {
			// data.showList = false;
			store.dispatch("user/getOneCollects",{
				actid: actid,
				data:{
					page: page,
					type: type,
				}
				
			}).then(res=>{
				if(type==1){
	        		console.log(res,"pic getOneCollects");
	        	}else if(type==2){
	        		console.log(res,"video getOneCollects");
	        	}
				
				let list = res.data;
	        	if(type==1){
	        		if(list.length > 0) {
		            	if(page == 1){
		            		data.picList = list;
		            	}else{
		            		data.picList = data.picList.concat(list);
		            	}
						
						if(list.length < res.pagination.pageSize){
							data.picNomore = true;
						}else{
							data.picNomore = false;
						}

					}else{
						data.picNomore = true;
					}     
            	}else if(type==2) {
            		if(list.length > 0) {
		            	if(page == 1){
		            		data.videoList = list;
		            	}else{
		            		data.videoList = data.videoList.concat(list);
		            	}
						
						if(list.length < res.pagination.pageSize){
							data.videoNomore = true;
						}else{
							data.videoNomore = false;
						}

					}else{
						data.videoNomore = true;
					} 
            	}
	               
	        	// data.pagination = res.pagination;
	        	
	        	if(type==1){
	        		data.picPage += 1;
	        		data.isPicLoading = false;
	        	}else if(type==2){
	        		data.videoPage += 1;
	        		data.isVideoLoading = false;
	        	}
	        	
	        	// data.showList = true;
	        	
	        	let outer = document.getElementById("giftwork-content");
	        	if(outer){
					if(document.documentElement.clientWidth < 768){

						let tags = outer.getElementsByTagName("img");
						console.log(tags,"tags");
						for (var i = 0; i<tags.length; i++) {			        
					        tags[i].style.width = '100%'; 
					        tags[i].style.height = 'auto';    
					        tags[i].width = '100%'; 
					        tags[i].height = 'auto';
					        tags[i].parentNode.style.textIndent = '0rem';
					    }
						
					}else{
						
					    let tags = outer.getElementsByTagName("img");
						console.log(tags,"tags");
						for (var i = 0; i<tags.length; i++) {			        
					        tags[i].style.maxWidth = '800px'; 
					        tags[i].style.width = '100%'; 
					        tags[i].style.height = 'auto';    
					        tags[i].width = '100%'; 
					        tags[i].height = 'auto';
					        tags[i].parentNode.style.textIndent = '0rem';
					        
					    }
					}
				}
				let outer1 = document.getElementById("revelation-content");
				if(outer1){
					if(document.documentElement.clientWidth < 768){

						let tags = outer1.getElementsByTagName("img");
						console.log(tags,"tags");
						for (var i = 0; i<tags.length; i++) {			        
					        tags[i].style.width = '100%'; 
					        tags[i].style.height = 'auto';    
					        tags[i].width = '100%'; 
					        tags[i].height = 'auto';
					        tags[i].parentNode.style.textIndent = '0rem';
					    }
						
					}else{
						
					    let tags = outer1.getElementsByTagName("img");
						console.log(tags,"tags");
						for (var i = 0; i<tags.length; i++) {			        
					        tags[i].style.maxWidth = '800px'; 
					        tags[i].style.width = '100%'; 
					        tags[i].style.height = 'auto';    
					        tags[i].width = '100%'; 
					        tags[i].height = 'auto';
					        tags[i].parentNode.style.textIndent = '0rem';
					        
					    }

					}
				}

	        	bus.emit('showFooter',true);
			}).catch(err=>{
				if(type==1){
	        		data.isPicLoading = false;
	        	}else if(type==2){
	        		data.isVideoLoading = false;
	        	}
	        	bus.emit('showFooter',true);
			})
		}
		onBeforeUpdate(() => {
			let outer = document.getElementById("giftwork-content");
			if(outer){
				if(document.documentElement.clientWidth < 768){

					let tags = outer.getElementsByTagName("img");
					console.log(tags,"tags");
					for (var i = 0; i<tags.length; i++) {	
						if(tags[i].width > 375)	{		        
					        tags[i].style.width = '100%'; 
					        tags[i].style.height = 'auto';    
					        tags[i].width = '100%'; 
					        tags[i].height = 'auto';
					        tags[i].parentNode.style.textIndent = '0rem';
					    }
				    }

				    let videos = outer.getElementsByTagName("video");
					console.log(videos,"videos");
					for (var i = 0; i<videos.length; i++) {	
						if(videos[i].width > 375)	{		        
					        videos[i].style.width = '100%'; 
					        videos[i].style.height = 'auto';    
					        videos[i].width = '100%'; 
					        videos[i].height = 'auto';
					        videos[i].parentNode.style.textIndent = '0rem';
					        videos[i].style.margin = '0 auto';
					    }
				    }
					
				}else{
					
				    let tags = outer.getElementsByTagName("img");
					console.log(tags,"tags");
					for (var i = 0; i<tags.length; i++) {
						if(tags[i].width > 800)	{			        
					        tags[i].style.maxWidth = '800px'; 
					        tags[i].style.width = '100%'; 
					        tags[i].style.height = 'auto';    
					        tags[i].width = '100%'; 
					        tags[i].height = 'auto';
					        tags[i].parentNode.style.textIndent = '0rem';
				        }
				    }

				    let videos = outer.getElementsByTagName("video");
					console.log(videos,"videos");
					for (var i = 0; i<videos.length; i++) {	
						if(videos[i].width > 800)	{	
							videos[i].style.maxWidth = '800px';	        
					        videos[i].style.width = '100%'; 
					        videos[i].style.height = 'auto';    
					        videos[i].width = '100%'; 
					        videos[i].height = 'auto';
					        videos[i].parentNode.style.textIndent = '0rem';
					        videos[i].style.margin = '0 auto';
					    }
				    }

				}
			}
			
			let outer1 = document.getElementById("revelation-content");
			if(outer1){
				if(document.documentElement.clientWidth < 768){

					let tags = outer1.getElementsByTagName("img");
					console.log(tags,"tags");
					for (var i = 0; i<tags.length; i++) {	
						if(tags[i].width > 375)	{
					        tags[i].style.width = '100%'; 
					        tags[i].style.height = 'auto';    
					        tags[i].width = '100%'; 
					        tags[i].height = 'auto';
					        tags[i].parentNode.style.textIndent = '0rem';
					    }
				    }
					
				}else{
					
				    let tags = outer1.getElementsByTagName("img");
					console.log(tags,"tags");
					for (var i = 0; i<tags.length; i++) {	
						if(tags[i].width > 800)	{		        
					        tags[i].style.maxWidth = '800px'; 
					        tags[i].style.width = '100%'; 
					        tags[i].style.height = 'auto';    
					        tags[i].width = '100%'; 
					        tags[i].height = 'auto';
					        tags[i].parentNode.style.textIndent = '0rem';
				        }
				    }
				}
			}
		});

        onMounted(async()=>{
        	store.dispatch("common/fetchSiteInfo").then((res) => {
	              data.banners = res.activity_sliders  
	              bus.emit('isLoading',false);
	        }).catch(err => {
	              console.error(err)
	        })
	        fetchDetail(data.actid);
	        getOneCollects(data.actid, 1, data.picPage);//获取图片
	        getOneCollects(data.actid, 2, data.videoPage);//获取视频
        	// fetchGrapher(data.keyword,data.catid,0,data.page);
        	await nextTick(()=>{
        		// bus.emit('showFooter',true);
        	})
        	setTimeout(() => {
		        data.isLoading = false;
		        viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		    }, 600);
		    console.log("页面数据更新完毕了")
        })   

		const showMenus = ref(proxy.$menus);
		provide('showMenu', showMenus);

		const handleSizeChange = (val) => {
		  console.log(`${val} items per page`)
		}
		const handleCurrentChange = (val) => {
		  console.log(`current page: ${val}`)
		  data.page = val;
		}

        const refData = toRefs(data);
	    return {
	        ...refData,
	        handleSizeChange,
	        handleCurrentChange,
	        fetchDetail,
	        viewBox,
	        scroll,
	        getOneCollects,
	    }
	},
	computed: {

	},

	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	},

	methods: {
		init() {
			if(document.documentElement.clientWidth < 768){
				// document.getElementsByClassName("menu-container").display = 'none';
				this.isMobile = true;
			}else{
				// document.getElementsByClassName("menu-container").display = 'flex';
				this.isMobile = false;
			}
			
		},	
		toPicDetail(id){
			// this.$router.push({ name:'active_collect_detail',query:{ id: id } });
			let href = this.$router.resolve({ //使用resolve
		      name:'active_collect_detail',    //这里是跳转页面的name
		      path: '/active_collect_detail',
		      query:{ id: id },
		    })
		    window.open(href.href, '_blank')
		},
		toVideoDetail(id){
			// this.$router.push({ path:'/active_collect_video_detail',query:{id: id} });
			let href = this.$router.resolve({ //使用resolve
		      name:'active_collect_video_detail',    //这里是跳转页面的name
		      path: '/active_collect_video_detail',
		      query:{ id: id },
		    })
		    window.open(href.href, '_blank')
		},
		switchTag(item) {
			this.tag = item.id;
			if(item.id == 1){
				this.showList = true;
				this.showExcellent = false;
      			this.showRevelation = false;
			}else if(item.id == 2){
				this.showList = false;
				this.showExcellent = true;
      			this.showRevelation = false;
			}else if(item.id == 3){
				this.showList = false;
				this.showExcellent = false;
      			this.showRevelation = true;
			}
		},
		activeStyle(item) {		
			let object = {};
			object.width = item.ratio_w*260/item.ratio_h+'px !important';
			object.minWidth = '190px';
			// object.height = '260px';
			// object.maxHeight = '300px';
			return object;
			
		},
		uploadThumb(event){
	    	let that = this;
        	event.preventDefault();
            let file = event.currentTarget.files[0];
            console.log(file,"file");
            var formData = new FormData();
    		formData.append("file", file);
        	this.$store.dispatch('user/GrapherWorkerUpload', formData).then((res)=>{	            		
            	console.log("upload-1",res);
            	// fileInfo.url = res.url;
            	// that.subjectForm.thumb = res.url;
            }).catch(err=>{
				ElMessage({
			        message: err.message,
			        type: "warning",
			    });
			})
	    },
		loadMorePics() {			
			this.getOneCollects(this.actid,1,this.picPage);
		},
		loadMoreVideos() {			
			this.getOneCollects(this.actid,2,this.videoPage);
		},
	    toDetail(id){
	      	// this.$router.push({ path:'/photo_grapher_detail', query:{ id: id } });
	    },
	    toUpload() {

	    	this.$router.push( {name: 'active_detail', query: { actid: this.actid }} );
	    },
	    tagStyle(item) {
	    	if(this.tag == item.id){
	    		return ['active'];
	    	}
	    	return '';
	    },
	},
	beforeDestory() {

	},
	destoryed() {

	}
}