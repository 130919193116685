// import Search from '@/components/Search.vue'
import { ref, provide, reactive, h, render, toRefs, getCurrentInstance,nextTick, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import PageLoading from '@/components/common/PageLoading.vue';
import Swiper from '@/components/layout/Swiper.vue'
import SearchBar2 from '@/components/layout/SearchBar2.vue'
import Header2 from '@/components/layout/header2.vue'
import Footer from '@/components/layout/footer.vue'
import Menu from '@/components/layout/menu.vue'
import Loading from '@/components/common/Loading.vue';
import bus from 'vue3-eventbus'
import SideComponent from '@/components/common/SideComponent.vue';

export default {
	components: {
		PageLoading,
		Header2,
		Menu,
	    Swiper,
	    SearchBar2,
	    Footer,
	    Loading,
	    SideComponent,
	},
	// inject: ['picFile'],
	setup(){
		provide('logoColor', 'black');
	    const { proxy } = getCurrentInstance();
	    const route = useRoute();    

		const data = reactive({
			isLoading:true,
			isPicLoading:true,
			picFile:'',
			currentPage:1,
			pageSize: 15,
			total: 0,
			picList:[],
			pagination:{},
			page:1,
      		nomore:true,
      		keyword:'',
      		isNeedRedirect:false,
		});
		
		const store = useStore();
	
		const postFile = (picfile) => {
			store.dispatch("common/postPic",{
				file: picfile
			}).then((res)=>{
				// console.log(res,"file to file");
				data.picList = res;//store.state.common.postPics;
				// data.pagination = res.pagination;
				data.total = data.picList.length;
				data.isPicLoading = false;
			})
		}
		const getPicList = (keyword='',page=1) => {
			data.keyword = keyword;
			store.dispatch("common/getPicLists", {
	        	page: page,
	        	filter: keyword,
	        	cid: 0,
	        	lid: 0,
	        	type: 1,
	        	day: 0,
	        	ratio: 0,
	        	subject_id: 0,
	        }).then((res) => {	  
	        	// console.log(res,"res")      	
	        	if(res.data.length > 0){
	        		if(page == 1){
	        			data.picList = res.data.slice();
	        		}else{
	        			data.picList = data.picList.concat(res.data.slice());
	        		}
					
					if(res.data.length < res.pagination.pageSize){
						data.nomore = true;
					}else{
						data.nomore = false;
					}
				}else{
					data.nomore = true;
				}        
	        	// data.pagination = data.videos.pagination;
	        	
	        	data.pagination = res.pagination;
	        	data.total = res.pagination.total;
	        	if(!data.nomore){
	        		data.page = page+1;
	        	}
	        	// data.page += 1;
	        	data.isPicLoading = false;
	        	bus.emit('showFooter',true);
	        })
		}

		const from = store.state.common.from;
		const type = store.state.common.type;
		if(from == 'other'){
			if(type == 'file'){
				const stateFile = store.state.common.uploadPic;
				if(stateFile.size > 0){
					postFile(stateFile);
				}else{
					data.picList = store.state.common.postPics;
					data.total = data.picList.length;
				}
			}else if(type == 'keyword'){
				const keywords = store.state.common.keywords;
				// console.log(keywords,"keywords");
				data.keyword = keywords.keyword;
				getPicList(keywords.keyword);
			}
			
			
		}else{
			data.picList = store.state.common.postPics;
			data.total = data.picList.length;
		}

		const viewBox = ref(null);
        const scroll = (val) => {
			if(val == 'top'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		       
			}else if(val == 'bottom'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });   	
		        
			}
		}
		
		onMounted(async()=>{
        	await nextTick()
        	setTimeout(() => {
		        data.isLoading = false;
		        viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		    }, 600);
		    console.log("页面数据更新完毕了")
        })

		const showMenu = ref(proxy.$menus);
		provide('showMenu', showMenu);

		const refData = toRefs(data);
	    
	    return {
	        ...refData,
	        postFile,
	        getPicList,
	        viewBox,
	        scroll,
	    }
	},
	
	computed: {

	},
	watch(){

	},
	beforeCreate() {

	},
	created() {

	},
	beforeMount() {

	},
	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	},
	beforeUpdate() {

	},
	updated() {

	},
	beforeUnmount() {

	},
	unmounted() {

	},
	methods: {
		init() {
			let info = this.$store.state.common.siteInfo;
		},
		activeStyle(item) {		
			// if(item.ratio_w == null || item.ratio_h == null) {
			// 	(async () => {
			// 	    let size = await this.$utils.getImageSize(item.preview);
			// 	    console.log(size,"size");
			// 	    item.ratio_w = size.width;
			// 	    item.ratio_h = size.height;
			// 	})();

			// }
			let object = {};
			object.width = item.ratio_w*180/item.ratio_h+'px';

			return object;
		},
		
		loadMore() {
			this.getPicList(this.keyword,this.page);
		},
		getData(file) {
			this.picFile = file;//event.currentTarget.files[0];
	        this.$store.dispatch('common/uploadPic', {
            	uploadPic: this.picFile,
            	from: 'child',
            });
	        this.postFile(this.picFile);
		},
		searchKeywords(keyword){
			this.getPicList(keyword,1);
		},
		toPicDetail(id){
			// this.$router.push({ name:'topic_pic_detail', query:{ id: id } });
			let href = this.$router.resolve({ //使用resolve
		      name:'topic_pic_detail',    //这里是跳转页面的name
		      path: '/topic_pic_detail',
		      query:{ id: id },
		    })
		    window.open(href.href, '_blank')
		},
		// postFile(picfile) {
		// 	this.$store.dispatch("common/postPic",{
		// 		file: picfile
		// 	}).then((res)=>{
		// 		console.log(res,"post pic");
		// 		this.picList = res.data;
		// 		this.pagination = res.pagination;
		// 	})
		// },
	    
	},
	beforeDestory() {

	},
	destoryed() {

	}
}