import { onMounted,nextTick, defineComponent, ref, provide, reactive, toRefs, getCurrentInstance } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import Swiper from '@/components/layout/Swiper.vue';
import SearchBar2 from '@/components/layout/SearchBar2.vue';
import Header2 from '@/components/layout/header2.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';
import SideMenu from '@/components/layout/SideMenu.vue';
import bus from 'vue3-eventbus'
import SideComponent from '@/components/common/SideComponent.vue';
import Loading from '@/components/common/Loading.vue';

export default defineComponent({
	components: {
		Header2,
		Menu,
	    Swiper,
	    SearchBar2,
	    Footer,
	    SideMenu,
	    SideComponent,
	    Loading,
	},
	props: {

	},
	setup() {
		let {ctx, proxy} = getCurrentInstance();
		const router = useRouter();
	    const route = useRoute();
		const data = reactive({
			showHeader: true,
			loginStyle: "min-width:141px;",
			mobileIconStyle: "color:rgba(223, 58, 8, 1);",
			showListIcon: true,
			activeName:'first',
			active: 1,
			isSettleLoading: true,
			settle:'',
			showSettle: true,
			showVerify: false,
			showSuccess: false,
			picFiles:[],
			images: [],
			video: [],
			isPicLoading: false,
			isVideoLoading: false,
			pdfurl: '',
		});
		const showMenu = ref(proxy.$menus);
		provide('showMenu', showMenu);

		const outerStyleStr = ref("position:relative;left:10%;top:40%;transform:translate(-10%,-40%);width:auto;");
		provide('outerStyleStr', outerStyleStr);

		const styleStr = ref("height:45px;");
		provide('styleStr', styleStr);

		const showSearchText = ref(false);
		provide('showSearchText', showSearchText);

		const getImageUrl = (name) => {
		    return proxy.$utils.getImageUrl(name);
		};

		// bus.emit('needRefresh', true)

		const store = useStore();

		const getSiteInfo = () => {
		    return new Promise((resolve, reject) => {
		    	store.dispatch("common/fetchSiteInfo").then((res) => {
			    data.settle = res.settle;
			    data.isSettleLoading = false;
		        resolve();
		        // bus.emit('isLoading',false);
		      }).catch(err => {
		        reject();
		      })
			  
		    })
	    
	    }
	    const nextStep = (showSettle, showVerify, showSuccess) => {
	        if (data.active++ > 3) data.active = 1;
	        data.showSettle = showSettle;
  			data.showVerify = showVerify;
  			data.showSuccess = showSuccess;
	    }

	    const toSubmit = () => {
	    	nextStep(false, true, false);
	    }
	    const validate_mobile_rules = (rule, value, callback) => {
			if (value == "") return true;
			let email = proxy.$utils.isPhone(value);
		    if (!email) {
		        callback(new Error("请填写正确手机号"));
		    } else {
		        callback();
		    }
		    

		}
	    const validate_email_rules = (rule, value, callback) => {
			if (value == "") return true;
			let email = proxy.$utils.regEmail(value);
		    if (!email) {
		        callback(new Error("请填写正确邮箱"));
		    } else {
		        callback();
		    }
		    

		}

		const validate_realname_rules = (rule, value, callback) => {
			if (value == "") return true;
			let company = proxy.$utils.regCompany(value);
		    if (!company) {
		        callback(new Error("请填写真实姓名"));
		    } else {
		        callback();
		    }

		}
		const validate_company_rules = (rule, value, callback) => {
			if (value == "") return true;
			let company = proxy.$utils.regCompany(value);
		    if (!company) {
		        callback(new Error("请填写正确单位名称"));
		    } else {
		        callback();
		    }

		}
		const validate_idcard_rules = (rule, value, callback) => {
			if (value == "") return true;
			let company = proxy.$utils.regIDC(value);
		    if (!company) {
		        callback(new Error("请填写正确身份证号"));
		    } else {
		        callback();
		    }

		}
		

	    const ruleRef = ref()
		const ruleUser = reactive({
			user_type:'',
			realname:'',			
  			mobile:'',
  			email: '',
  			company:'',
  			worktype: [],	
		})

		const _rules = reactive({
			// realname: [
			// 	{ required: true, message: '请输入真实姓名', trigger: 'blur' },
			// 	{ validator: validate_realname_rules, trigger: "blur" },
			// ],
			// mobile: [
			// 	{ required: true, message: '请输入手机号', trigger: 'blur' },
			// 	{ validator: validate_mobile_rules, trigger: "blur" },
			// ],
			// email: [
			// 	{ required: true, message: '请输入邮箱', trigger: 'blur' },
			// 	{ validator: validate_email_rules, trigger: "blur" },
			// ],
			// company: [
			// 	{ required: true, message: '请输入机构名称', trigger: 'blur' },
			// 	{ validator: validate_company_rules, trigger: "blur" },
			// ],
			// worktype:[
			// 	{ required: true, message: '请选择供稿类型', trigger: 'blur' },
			// ],
			
		})
		// const handleCheckChange = (value) => {
		// 	const checkedCount = value.length
		// 	if(value.indexOf('image')!==-1){
		//     	_rules.images = [
		// 			{ required: true, message: '请上传图片作品', trigger: 'blur' },
		// 		]
		//     }else{
		//     	_rules.images = []
		//     }
		//     if(value.indexOf('video')!==-1){
		//     	_rules.video = [
		// 			{ required: true, message: '请上传视频作品', trigger: 'blur' },
		// 		]
		//     }else{
		//     	_rules.video = []
		//     }
		// }
		const ApplyInfo = () => {
			store.dispatch("user/ApplyInfo",{

			}).then((res) => {
				console.log(res,"ApplyInfo");
				if(Object.keys(res).length > 0){
					ruleUser.user_type = res.user_type+'';
					ruleUser.realname = res.realname;
		  			ruleUser.mobile = res.mobile;
		  			ruleUser.email = res.email;
		  			ruleUser.company = res.company;
		  			ruleUser.worktype = res.worktype;
					if(res.status == 1){
						// ruleUser.user_type = res.user_type+'';
						// ruleUser.realname = res.realname;
			  	// 		ruleUser.mobile = res.mobile;
			  	// 		ruleUser.email = res.email;
			  	// 		ruleUser.company = res.company;
			  	// 		ruleUser.worktype = res.worktype;
				  		
					}else if(res.status == 0){
						router.push({name:'apply_passing'});
					}else if(res.status == 2){
						router.push({name:'apply_nopass'});
					}

				}else{
					router.push({name:'no_settled'});
				}
				
			})
		}

		const GetApplyContract = () => {
			store.dispatch("user/GetApplyContract",{
	  			
		    }).then((res)=>{
		      	console.log("GetApplyContract",res);
		      	// nextStep(false, true, false);
		      	if(res.status == 1){
		      		data.pdfurl = res.contract_path;
		      	}
				bus.emit('showFooter',true);
		    }).catch(err => {
	            // console.error(err)
	            ElMessage({
				    message: err.message,
				    grouping: true,
				    type: 'error',
				})
	        })
		}
		

		const submitForm = (formEl) => {
			console.log(ruleUser,"ruleUser");
			
			bus.emit('needRefresh', true)
			if (!formEl) return
			formEl.validate((valid, fields) => {
			    if (valid) {
				    console.log(valid,'submit!')
				    
			    	store.dispatch("user/GrapherWorkerForm",{
				      	realname: ruleUser.realname,
					    gender: ruleUser.gender,
			  			mobile: ruleUser.mobile,
			  			email: ruleUser.email,
			  			company: ruleUser.company,
			  			worktype: ruleUser.worktype,
			  			
				    }).then((res)=>{
				      	// console.log("SubmitContract",res);
				      	ElMessage({
						    message: '申请已提交',
						    grouping: true,
						    type: 'warning',
						})

				      	nextStep(false, false, true);
						
				    }).catch(err => {
			            // console.error(err)
			            ElMessage({
						    message: err.message,
						    grouping: true,
						    type: 'error',
						})
			        })
				    
				    
			    } else {
			      console.log('error submit!')
			      return false
			    }
			})
		}


		const dialogImageUrl = ref('')
		const dialogVisible = ref(false)
		const disabled = ref(false)

		const handleRemove = (file) => {
		  console.log(file)
		}

		const handlePictureCardPreview = (file) => {
		  dialogImageUrl.value = file.url
		  dialogVisible.value = true
		}

		const handleDownload = (file) => {
		  console.log(file)
		}

		const handleChange = (uploadFile, uploadFiles) => {
		  console.log(uploadFile,"uploadFile")
		  console.log(uploadFiles,"uploadFiles")

		}




		const formSize = ref('large')	
		const viewBox = ref(null);
        const scroll = (val) => {
			if(val == 'top'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		       
			}else if(val == 'bottom'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });  	
		        
			}
		}

		

        onMounted(async()=>{
        	getSiteInfo();
        	ApplyInfo();
        	GetApplyContract();
        	await nextTick()
        	setTimeout(() => {
		      data.isLoading = false;
		      viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		    }, 600);
		    console.log("页面数据更新完毕了")
        })

		const refData = toRefs(data);

		return {
			...refData,
			getSiteInfo,
			viewBox,
			scroll,
			nextStep,
			toSubmit,
			ruleRef,
			ruleUser,
			_rules,
			submitForm,
			formSize,
			dialogImageUrl,
			dialogVisible,
			disabled,
			handleRemove,
			handlePictureCardPreview,
			handleDownload,
			handleChange,
			// handleCheckChange,
			ApplyInfo,
			GetApplyContract,
		}
	},
	created() {

	},
	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	},
	methods: {
		init() {
			this.username = this.$cookies.getUsername();
		},
		uploadFile(event,type){
			let that = this;

        	event.preventDefault();
            this.picFiles = event.currentTarget.files;
            // console.log(this.picFiles,"this.picFiles");
            if(this.picFiles.length > 0){
            	for(let i=0;i<this.picFiles.length;i++){
            		if(type=='image'){
	            		this.isPicLoading = true;
	            	}else if(type=='video'){
	            		this.isVideoLoading = true;
	            	}
            		// console.log(this.picFiles[0],"this.picFiles-1");
            		var formData = new FormData();
            		formData.append("file", this.picFiles[i]);
	            	this.$store.dispatch('user/GrapherWorkerUpload', formData).then((res)=>{
	            		
		            	console.log("upload",res);
		            	if(type=='image'){
		            		this.isPicLoading = false;
		            		that.images.push(res.url);
		            		that.ruleUser.images.push(res.path);
		            	}else if(type=='video'){
		            		this.isVideoLoading = false;
		            		that.video.push(res.url);
		            		that.ruleUser.video.push(res.path);
		            	}
		            	
		            }).catch(err=>{
						ElMessage({
					        message: err.message,
					        type: "warning",
					    });
					})
	            }
            }
            
           
        },
	},
})