import { defineComponent, ref, provide, reactive, toRefs, getCurrentInstance, nextTick, onMounted, onUnmounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import {useStore} from 'vuex';
import PageLoading from '@/components/common/PageLoading.vue';
import Swiper from '@/components/layout/Swiper.vue';
import SearchBar2 from '@/components/layout/SearchBar2.vue';
import Header2 from '@/components/layout/header2.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';
import bus from 'vue3-eventbus'
import SideComponent from '@/components/common/SideComponent.vue';
import _ from 'lodash' //导入loadsh插件

export default defineComponent({
	components: {
		PageLoading,
		Header2,
		Menu,
	    Swiper,
	    SearchBar2,
	    Footer,
	    SideComponent,
	},
	props: {

	},
	setup() {
		let {ctx, proxy} = getCurrentInstance();
		const router = useRouter();
	    const route = useRoute();
	    const id = route.query.catid;
		const data = reactive({
			isLoading: true,
			showHeader: true,
			loginStyle: "min-width:141px;",
			mobileIconStyle: "color:rgba(223, 58, 8, 1);",
			showListIcon: true,		
      		pics:{},
      		orderInfo:{},
      		payInfo:{},
      		paytype:[
      			{
      				id: 1,
      				name: proxy.$t('order_detail.text27'),
      				iconClass:'icon-zhanghuyue6',
      				text: proxy.$t('order_detail.text33')+' <span style="color:rgb(230, 0, 18);font-weight:bold;">￥0.00</span>。'+proxy.$t('order_detail.text34'),
      				package_id:0,
      			},
      			{
      				id: 2,
      				name: proxy.$t('order_detail.text29'),
      				iconClass:'icon-zhanghuyue7',
      				text: proxy.$t('order_detail.text30'),
      				
      			},
      			{
      				id: 3,
      				name: proxy.$t('order_detail.text31'),
      				iconClass:'icon-zhanghuyue7',
      				text: proxy.$t('order_detail.text32'),
      				
      			},
      		],
      		selectedPaytype:{
      			id: 1,
  				name: proxy.$t('order_detail.text27'),
  				iconClass:'icon-zhanghuyue6',
  				text: proxy.$t('order_detail.text33')+' <span style="color:rgb(230, 0, 18);font-weight:bold;">￥0.00</span>。'+proxy.$t('order_detail.text34'),
  				package_id:0,
      		},
      		orderParam:{},
      		imgs:[],
      		totalAmount:0,
      		checkedOption: 0,
      		packages:{},
      			// {
		       //    "package_id": 1,
		       //    "balance": "string",
		       //    "price": "999",
		       //    "status": "-1",
		       //    "text": "OK",
		       //    "title": "标准版999/年",
		       //    "isChecked": false,
		       //  },
		       //  {
		       //    "package_id": 2,
		       //    "balance": "string",
		       //    "price": "2999",
		       //    "status": "1",
		       //    "text": "OK",
		       //    "title": "标准版2999/年",
		       //    "isChecked": false,
		       //  }
      		// ],
      		checkedPackageId: 0,
      		money:0,
      		checkedValue: 0,
      		from: '',
      		cartids:[],
      		disabled: false,
		});
		const showMenu = ref(proxy.$menus);
		provide('showMenu', showMenu);
		data.from = route.query.from;
		if(route.query.from == 'cart'){
			data.cartids = route.query.cartids;
			// console.log(data.cartids,"data.cartids");
		}
		const outerStyleStr = ref("position:relative;left:10%;top:40%;transform:translate(-10%,-40%);width:auto;");
		provide('outerStyleStr', outerStyleStr);

		const styleStr = ref("height:45px;");
		provide('styleStr', styleStr);

		const showSearchText = ref(false);
		provide('showSearchText', showSearchText);

		const getImageUrl = (name) => {
		    return proxy.$utils.getImageUrl(name);
		};
		
		const store = useStore();
		
		
		bus.emit('needRefresh', true)

		const delCartGoods = (cartids) => {
        	store.dispatch("user/BatchDeleteCartGoods",{ids: cartids}).then((res)=>{
				// console.log(res,"delCartGoods");
				
			});
        }
		
		const createOrder = (selectedPaytype, orderParam) =>{
			console.log(orderParam,"orderParam");
			store.dispatch("common/CreateOrder",{
				source: orderParam.source,
				images: orderParam.images,
			}).then((res)=>{			
	        	data.orderInfo = res;
	        	data.disabled = false;
	        	// console.log(data.orderInfo,"orderInfo");
	        	if(selectedPaytype == 1){
	        		store.dispatch("user/getPay",{
						oid: data.orderInfo.id,
						ptype: selectedPaytype,//1钱包支付 2微信支付 3支付宝支付
						package_id: data.checkedPackageId,
					}).then((res2)=>{	
						// console.log(res2,"balance pay");	
						if(res2.status == 1){
							store.commit("common/saveOrderParam", {});
							ElMessage({
						        message: proxy.$t('order_detail.text35'),
						        type: "success",
						    });	
						    localStorage.setItem('order_id', data.orderInfo.id);
						    router.replace({ path:'/my/contract',query:{order_id: data.orderInfo.id} });
						}else{
							ElMessage({
						        message: proxy.$t('order_detail.text36'),
						        type: "error",
						    });	
						}	
											
					});
	        	}else if(selectedPaytype != 1){
	        		router.replace({ name:'pay_qrcode', query:{ oid: data.orderInfo.id,selectedtype:selectedPaytype } });
	        		localStorage.setItem('order_id', data.orderInfo.id);
	        	}
	        	if(data.from == 'cart'){
	        		delCartGoods(data.cartids);
	        	}
	        }).catch(err=>{
				ElMessage({
			        message: err.message,
			        type: "warning",
			    });
			})
		}

		const Wallet = () => {
			store.dispatch("user/Wallet",{

			}).then((res)=>{
				// console.log(res,"wallet");
				data.money = res.wallet.money
				data.paytype = data.paytype.map((item)=>{
					if(item.id == 1){
						item.text = proxy.$t('order_detail.text33')+' <span style="color:rgb(230, 0, 18);font-weight:bold;">'+res.wallet.money+'</span>元。'+proxy.$t('order_detail.text34')
					}
					return item;
				})
				// console.log();
			}).catch(err=>{
				ElMessage({
			        message: err.message,
			        type: "error",
			    });
			})
		}
		
		const PreCreate = (orderParam,selectedPaytype) =>{
			console.log(orderParam,"orderParam-pre");			
			store.dispatch("user/PreCreate",{
				images: orderParam.images,
			}).then((res)=>{
				console.log(res,"preOrder");
				// console.log(selectedPaytype.id,"selectedPaytype-id");
				if(selectedPaytype.id == 1){
					if(Object.keys(res.payInfo.wallet.package).length > 0){
						data.packages = JSON.parse(JSON.stringify(res.payInfo.wallet.package));
						data.payInfo = JSON.parse(JSON.stringify(res.payInfo));
						console.log(data.packages,"packages");
						console.log(data.payInfo,"payInfo-1");
						if(parseInt(data.checkedPackageId)>0){
							data.totalAmount = data.packages[data.checkedPackageId].price;
														
						}else{

							data.totalAmount = res.payInfo.wallet.balance.price;
							
						}

					}else{
						data.payInfo = JSON.parse(JSON.stringify(res.payInfo));
						console.log(data.payInfo,"payInfo-2");
						data.totalAmount = res.payInfo.wallet.balance.price;
						
					}
					
				}else if(selectedPaytype.id == 2){
					data.totalAmount = res.payInfo.wechat;
				}else if(selectedPaytype.id == 3){
					data.totalAmount = res.payInfo.alipay;
				}
			}).catch(err=>{
				ElMessage({
			        message: err.message,
			        type: "warning",
			    });
			})
		
        }
        const OrderInfo = (order_id) => {
   //      	store.dispatch("user/OrderInfo",{
			// 	id: order_id
			// }).then((res)=>{

			// })
        }
        

	    onMounted(async()=>{
	    	let payedOrderid = localStorage.getItem('order_id');
	    	if(payedOrderid){
	    		localStorage.setItem('order_id',0);
	    		localStorage.removeItem("order_id");
	    		router.replace({ name:'order_detail', query:{ order_id: payedOrderid } });
	    	}else{
	    		Wallet();
		    	// vue报错：Error: [vuex] Do not mutate vuex store state outside mutation handlers.
		    	// 如果报以上错误就需要对store中的数据进行深度拷贝
		    	// 方法一
		    	let orderParam =  JSON.parse(JSON.stringify(store.state.common.createOrderParam));
		    	// 方法二
		    	// let orderParam = _.cloneDeep(store.state.common.createOrderParam);
				
				data.orderParam = orderParam;
				PreCreate(orderParam, data.selectedPaytype);

				// console.log(data.orderParam,"data.orderParam");
				// data.imgs = data.orderParam.imgList;
				let imgs = [];
				if( Object.keys(orderParam).length > 0 ){
					for(let i=0;i<orderParam.imgList.length;i++){
						data.imgs.push(orderParam.imgList[i]);
					}
				}

				// console.log(data.imgs,'imgs');
	    	}
	    	
			
			
			

        	await nextTick()
        	setTimeout(() => {
		      data.isLoading = false;
		      bus.emit('showFooter',true);
		    }, 600);
		    console.log("页面数据更新完毕了")
        })

        onUnmounted(() => {
		    window.addEventListener("beforeunload", (e) => {
		    	store.commit('saveOrderParam',{})
		    	store.commit('saveOrderInfo', {})
		    });
		    window.addEventListener("unload", (e) => {
		    	store.commit('saveOrderParam',{})
		    	store.commit('saveOrderInfo', {})
		    });
		})

		const viewBox = ref(null);
        const scroll = (val) => {
			if(val == 'top'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		       
			}else if(val == 'bottom'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
   
			}
		}


		const refData = toRefs(data);

		return {
			...refData,
			getImageUrl,
			proxy,
			PreCreate,
			createOrder,
			viewBox,
			scroll,
		}
	},
	created() {

	},
	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	},
	watch:{
		// imgs:{
		// 	handler(value) {
		// 		// console.log(value,"value");
		// 		// if(this.selectedPaytype == 1 && this.checkedPackageId==0) {
		// 		// 	this.totalAmount = 0;
		// 		// }
		// 		let sumchecked = 0;
	 //            let options = {};
	 //            for (var i=0; i<value.length; i++){
	 //            	options.image_id = value[i].id;
	 //            	options.option = [];
	 //            	for(let j=0;j<value[i].option_all.length;j++){
	 //            		if (value[i].option_all[j].isChecked == true){
		//                     // if(this.selectedPaytype == 1 && this.checkedPackageId == 0) {
		//                     // 	this.totalAmount += value[i].option_all[j].price;
		//                     // }
		//                     options.option.push(value[i].option_all[j].id);
		//                     sumchecked++;
		//                 }
	 //            	}
	                
	 //            }

	            
	 //            this.orderParam.images = [];
	 //            this.orderParam.images.push(options);
	 //            // console.log(this.orderParam,"orderParam");
	 //            // if(this.selectedPaytype == 1 && this.checkedPackageId == 0) {

	 //            // }else{
	 //            this.PreCreate(this.orderParam, this.selectedPaytype);
	 //            // }
	            
	 //        },
	 //        deep: true

		// },
		checkedPackageId:{
			handler(value) {
				// console.log(value,"checkedPackageId")
				// console.log(this.packages,"packages");
				let checkedItem = {};
				for(let i in this.packages){
					if(this.packages[i].package_id == value){

						// checkedItem = this.packages[i];
						if(this.packages[i].status == -1){
							ElMessage({
						        message: this.packages[i].text,
						        type: "warning",
						    });	
						    
						}else{
							this.totalAmount = this.packages[i].price;
							
						}
						
						break;
					}
				}
				
				
			}
		}
	},
	methods: {
		init() {
			
		},
		changeInput(imgindex, index){
			let options = {};
			for (var i=0; i<this.imgs.length; i++){
		    // this.imgs = this.imgs.map((v,i)=>{ 
		        
		        options.image_id = this.imgs[i].id;
	        	options.option = [];
	        	// console.log(i,index,"index");
	        	for(let j=0;j<this.imgs[i].option_all.length;j++){
	        		if(i==imgindex && j==index){
	        			// console.log(i,index,"index");
			          	this.imgs[i].option_all[j].isChecked = true
			          	options.option.push(this.imgs[i].option_all[j].id);
			        }else{
			          	this.imgs[i].option_all[j].isChecked = false
			        }
	        		// if (v.option_all[j].isChecked == true){
	                    
	          //           options.option.push(v.option_all[j].id);
	                    
	          //       }
	        	}
	        	// return v;
		    // })
			}
		    this.orderParam.images = [];
            this.orderParam.images.push(options);
            // console.log(this.orderParam,"orderParam---2");
            
            this.PreCreate(this.orderParam, this.selectedPaytype);
	    },
		topay(orderInfo){
			let that = this;
			
			if(undefined === this.$cookies.getToken()){
				sessionStorage.setItem('redirectPath', this.$route.fullPath);
				bus.emit('showLoginDialog', true)
				bus.emit('showLoginDialogMobile', false)
        		bus.emit('showLoginDialogQrcode', true)
			} else {

				console.log("images",this.orderParam.images);
				let hasNull = 1;
				let arr = [];
				this.orderParam.images.forEach( (item, index) => {
					if(item.option.length <= 0){
						// hasNull = 0;
						arr.push(0);
					}else if(item.option.length > 1){
						// hasNull = 2;
						arr.push(2);
					}else if(item.option.length == 1){
						// hasNull = 1;
						arr.push(1);
					}

				})
				if(arr.indexOf(0) !== -1){
					ElMessage({
				        message: that.$t('order_pay.tip1'),
				        type: "warning",
				    });
				}else if(arr.indexOf(2) !== -1){
					ElMessage({
				        message: that.$t('order_pay.tip2'),
				        type: "warning",
				    });
				}else{
					this.disabled = true;
					if(this.selectedPaytype.id == 1){
						if(Object.keys(this.packages).length > 0){
							if(parseInt(this.checkedPackageId)>0){
								if(this.packages[this.checkedPackageId].status==-1){
									ElMessage({
								        message: this.packages[data.checkedPackageId].text,
								        type: "warning",
								    });	
								}else{
									this.createOrder(this.selectedPaytype.id, this.orderParam);
								}

							}else{
								if(this.payInfo.wallet.balance.status == -1){
									ElMessage({
								        message: this.payInfo.wallet.balance.text,
								        type: "warning",
								    });		
								}else{
									this.createOrder(this.selectedPaytype.id, this.orderParam);
								}
								
							}

						}else{
							if(this.payInfo.wallet.balance.status == -1){
								ElMessage({
							        message: this.payInfo.wallet.balance.text,
							        type: "warning",
							    });		
							}else{
								this.createOrder(this.selectedPaytype.id, this.orderParam);
							}
							
						}
						
					}else{
						this.createOrder(this.selectedPaytype.id, this.orderParam);
					}
					
				}
				
				// if(this.selectedPaytype.id == 2){
				// 	this.$router.push({ name:'pay_qrcode', query:{ oid: orderInfo.id } });
				// }
				
				// this.$store.dispatch("user/getPay",{
				// 	oid: orderInfo.id,
				// 	ptype:this.selectedPaytype.id,
				// 	otype:1,
				// }).then((res)=>{
				// 	console.log(res,"pay");
				// });
			}
			
		},
		toPicDetail(id){
			this.$router.push({ name:'topic_pic_detail', query:{ id: id } });
		},
		selectPayType(item) {
			this.selectedPaytype = item;
			this.PreCreate(this.orderParam, this.selectedPaytype);
		},
		activeIcon(name) {
			console.log(name,"name");
			return [name];
		},
	},
})