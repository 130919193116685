<template>
<SideComponent @scrollto="scroll"></SideComponent>
	<div class="container" ref="viewBox">
		<Header2 :show-search-bar-pic="showHeader" login-style="loginStyle" :mobile-icon-style="mobileIconStyle" :show-list-icon="showListIcon"/>
		<div class="content">
			<div style="height:80px;"></div>
			<div class="enclosure">
				<div class="inner">
					<div class="left">
						<SideMenu />
					</div>
					<div class="right">
						<div class="caption">
							<span>{{$t('person.personCenter')}}</span><span class="arrow"><i class="iconfont icon-jiantou2"></i></span><span>{{$t('sideMenu.apply')}}</span>
						</div>
						
						<div class="tag-outer">
							<div class="tag-item">
								<img src="@/assets/img/grapher_settled/passing.png"/>
							</div>
							<div class="tag-item">
								<span class="text-1">{{$t('apply_passing.tip1')}}</span>
							</div>
							<div class="tag-item reason">
								<span class="text-2">{{$t('apply_passing.tip2')}}</span>
							</div>
							<div class="tag-item reason">
								<span class="text-2">{{$t('apply_passing.tip3')}}</span>
							</div>
							<div class="tag-item">
								
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer/>
	</div>
</template>

<script>
	import apply_passing from './apply_passing_controller';
	export default apply_passing;
</script>

<style src="@/assets/css/my/grapher/apply_passing.css" scoped> </style>