<template>
	<div class="search-container" :style="outerStyleStr">
		<div class="swrapper">			
			<div class="search-bar search-bar_border" :style="styleStr">
				<!-- <el-select
				    v-model="menuValue"
				    class="m-2 search-select"
				    placeholder="Select"
				    size="large"
				    style="min-width: 100px"
				    v-show="ispic">
				    <el-option
				      v-for="item in options"
				      :key="item.value"
				      :label="item.label"
				      :value="item.value"
				    />
				</el-select> -->
				<!-- <span class="splitLine" style="color:#d9d9d9;" v-show="ispic">|</span> -->
				<el-input v-model="input" :placeholder="placeholder" @keyup.enter.native="searchKeywords(input,'other')"/>
				<!-- <i class="color-img" v-if="ispic"></i> -->
				<!-- <img src="@/assets/img/colorimg.png" class="color-img" v-if="ispic" @click="dialogVisible = true"/> -->
				<i class="iconfont icon-zhaoxiangji icon-style" v-if="ispic" @click="uploadVisible=true"/>
				<button type="primary" class="search-btn search-btn_s" @click="searchKeywords(input,'other')">
					<i class="iconfont icon-sousuo1 icon-style1" /><span v-if="showSearchText" class="stext">{{$t('search.search')}}</span>
				</button>

			</div>
			<div class="hot-search">
				<!-- <span>{{$t('search.hotSearch')}}</span><span v-for="item in hot" @click="searchKeywords(item)">{{item}}</span> -->
			</div>
		</div>
	</div>
	<div class="colorDialog-container" v-show="dialogVisible">
		<div class="color-dialog">
			<div class="close" @click="dialogVisible = false"><i class="iconfont icon-chahao1 close-style"></i></div>
			<div class="dialog-title">
				<img src="@/assets/img/colorimg.png" class="color-img"/><span class="title-text">色彩搜索</span>
			</div>
			<div class="slider-content">
			    <div class="color-block">
			    	<div class="slider-colors">
				    	<div class="color-box first" style="width:100%;">
				    		<el-color-picker v-model="color" popper-class="selected_color"/>
				    		<div role="button" class="changebtn" @mousedown="handleMouseMove" style="display:none;">
				    			<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M7.999 11L4 15l3.999 4v-3h6.999V14h-7v-3zM22 9L17.998 5v3H11v2.002h7v3l4.001-4z"></path></svg>
				    		</div>
				    	</div>

				    </div>

			    </div>
			    <div class="color-selected">
			    	<div class="color-cutshot">
			    		<div class="caption">流行色彩</div>
			    		<div class="color-area">
			    			<div ref="p" class="color-item" @click="handleColorChange" data-color="#DC143C" style="background-color: #DC143C;"></div>
			    			<div class="color-item" @click="handleColorChange" data-color="#C71585" style="background-color: #C71585;"></div>
			    			<div class="color-item" @click="handleColorChange" data-color="#FF00FF" style="background-color: #FF00FF;"></div>
			    			<div class="color-item" @click="handleColorChange" data-color="#9400D3" style="background-color: #9400D3;"></div>
			    			<div class="color-item" @click="handleColorChange" data-color="#0000CD" style="background-color: #0000CD;"></div>
			    			<div class="color-item" @click="handleColorChange" data-color="#708090" style="background-color: #708090;"></div>
			    			<div class="color-item" @click="handleColorChange" data-color="#4682B4" style="background-color: #4682B4;"></div>
			    			<div class="color-item" @click="handleColorChange" data-color="#5F9EA0" style="background-color: #5F9EA0;"></div>
			    			<div class="color-item" @click="handleColorChange" data-color="#00FFFF" style="background-color: #00FFFF;"></div>
			    			<div class="color-item" @click="handleColorChange" data-color="#008B8B" style="background-color: #008B8B;"></div>
			    		</div>
			    	</div>
			    	<div class="color-cutshot">
			    		<div class="caption">最近色彩</div>
			    		<div class="color-area">
			    			<div class="color-item" @click="handleColorChange" data-color="#20B2AA" style="background-color: #20B2AA;"></div>
			    			<div class="color-item" @click="handleColorChange" data-color="#00FA9A" style="background-color: #00FA9A;"></div>
			    			<div class="color-item" @click="handleColorChange" data-color="#32CD32" style="background-color: #32CD32;"></div>
			    			<div class="color-item" @click="handleColorChange" data-color="#228B22" style="background-color: #228B22;"></div>
			    			<div class="color-item" @click="handleColorChange" data-color="#FAFAD2" style="background-color: #FAFAD2;"></div>
			    			<div class="color-item" @click="handleColorChange" data-color="#FFFF00" style="background-color: #FFFF00;"></div>
			    			<div class="color-item" @click="handleColorChange" data-color="#F0E68C" style="background-color: #F0E68C;"></div>
			    			
			    		</div>
			    	</div>
			    	<div style="font-size: 14px;line-height: 30px;color: #6b6b6b;margin: 5px 0;">点击色块，可以快捷添加颜色哦～</div>

			    </div>
			    <div class="color-btn">
			    	<button type="button" class="operation-btn sure-btn">搜索</button>
			    	<button type="button" class="operation-btn cancel-btn">取消</button>
			    </div>
			</div>
		</div>
	</div>
	<!-- 上传图片 -->
	<div class="upload-container" v-show="uploadVisible">
		<div class="upload-dialog">
			<div class="close" @click="uploadVisible = false"><i class="iconfont icon-chahao1 close-style"></i></div>
			<div class="dialog-title">
				<!-- <img src="@/assets/img/colorimg.png" class="color-img"/> --><span class="title-text">{{$t('search.takePic')}}</span>
			</div>
			<div class="upload-content">
			    <label for="image_uploads" @drop="handleDrop" @dragover="handleDragOver" style="position:relative;width:100%;height:300px;background-color: #eee;border: 1px dashed #eee;display:flex;flex-direction: column;justify-content: space-evenly;align-items:center;">
					<input type="file" id="image_uploads" @change="getFile($event)" accept="image/*" style="display:none;" />
					<div class="qufbO">
						<svg width="75" height="75">
							<g fill="rgba(223, 58, 8, 1)">
								<path d="M19.145 69.224h12.648v5.577H19.145zm0-45.61h6.324v5.577h-6.324zm26.02 38.388v7.164h-7.001v5.577h6.266a6.382 6.382 0 006.324-6.394v-6.347h-5.59zM12.82 69.166h-7v-7.164H.172v6.394a6.382 6.382 0 006.325 6.394h6.324v-5.624zM5.691 36.414V29.33h6.931l.199-5.717H6.497a6.382 6.382 0 00-6.325 6.394v6.394l5.52.012zM.172 42.808h5.694v12.8H.172zm45.996 5.355h5.694v7.433h-5.694zM34.92 56.95a2.859 2.859 0 00-2.754-2.953l-9.217-.303L46.997 30.86a2.905 2.905 0 00.128-4.073 2.824 2.824 0 00-4.026-.128L19.051 49.47l.304-9.334a2.847 2.847 0 10-5.683-.14l-.525 16.335a2.894 2.894 0 00.77 2.065 2.824 2.824 0 001.984.91l16.09.525a2.859 2.859 0 002.93-2.882z"></path>
								<path d="M74.813 3.008A2.859 2.859 0 0071.966.126H25.994a2.859 2.859 0 00-2.87 2.882v26.451a2.847 2.847 0 105.694 0l-.035-23.58h40.383v39.437l-24.865.21a2.882 2.882 0 000 5.764l27.712-.21a2.859 2.859 0 002.835-2.917l-.035-45.155z"></path>
							</g>
						</svg>
					</div>
					<p class="upload-tiptext">{{$t('search.dragPic')}}</p>
					<p class="upload-tiptext">{{$t('search.or')}}</p>
					<p class="upload-tiptext">{{$t('search.clickUpload')}}</p>
				    <i class="iconfont icon-upload1" style="font-size:50px!important;color:rgba(223, 58, 8, 1);"></i>
				</label>
				<div class="el-upload__tip">
			        * {{$t('search.sizeLimit')}}
			    </div>

			</div>
		</div>
	</div>
</template>
<script>
	import { defineEmits,ref, provide, inject, reactive, h, render, toRefs, getCurrentInstance } from 'vue';
	import { useRouter, useRoute } from 'vue-router';
	import { useStore } from 'vuex';
	import ColorThief from "colorthief";

	export default {
		name:"SearchBarPic",
		props: {
            typeName: Array,
            isHome: Boolean,
            isTopicPic: {
            	type: Boolean,
            	default: false,
            },
            isNeedRedirect: {
            	type: Boolean,
            	default: false,
            }
        },
		emits:['refresh','searchKeywords'],
		// setup(props, { attrs, slots, emit, expose }) {
		    
		// },
		// setup(props, context) {
		//     // 透传 Attributes（非响应式的对象，等价于 $attrs）
		//     console.log(context.attrs)

		//     // 插槽（非响应式的对象，等价于 $slots）
		//     console.log(context.slots)

		//     // 触发事件（函数，等价于 $emit）
		//     console.log(context.emit)

		//     // 暴露公共属性（函数）
		//     console.log(context.expose)
		// },
		setup() {
			// const instance = getCurrentInstance();
		    // 获取实例上下文
		    const { proxy } = getCurrentInstance();
			const data = reactive({
				active_menuid: 'pic',
				menuValue: { value: 'all', label: '全部' },
				options: [
				  {
				    value: 'all',
				    label: '全部',
				  },
				  {
				    value: '1',
				    label: proxy.$t('search.searchPic'),
				  },
				  {
				    value: '2',
				    label: proxy.$t('search.searchVideo'),
				  },
				  
				],
				input: '',
				ispic: true,
				placeholder: proxy.$t('search.trySearch'),
				dialogTableVisible: false,
				dialogVisible: false,
				uploadVisible: false,
				formLabelWidth: '140px',
				form: {
				    name: '',
				    region: '',
				    date1: '',
				    date2: '',
				    delivery: false,
				    type: [],
				    resource: '',
				    desc: '',
				},
				value: [4, 8],
				color: '#f84949',
				color1: '#f84949',
				color2: '#f84949',
				itemRefs: [],
				isDragging: false,
				change_color: '#ff4500',
				bannerimg: import("@/assets/img/1.jpg"),
				imageUrl: new URL('@/assets/img/1.jpg', import.meta.url).href,
				outerStyleStr: inject('outerStyleStr', '', false),
				styleStr: inject('styleStr', '', false),
				showSearchText: inject('showSearchText', true, false),
				picFile:'',
				hot:[proxy.$t('search.scenery'), proxy.$t('search.building'), proxy.$t('search.culture'), proxy.$t('search.humanities'), proxy.$t('search.educate')],
			})
			

			const handleUploadSuccess = (res, file) => {
		        data.imageUrl = URL.createObjectURL(file.raw);
		        // console.log(this.imageUrl,"this.imageUrl")
		    }
		    const beforeUpload = (file) => {
		        const isJPG = file.type === 'image/jpeg';
		        const isPNG = file.type === 'image/png';
		        const isLt2M = file.size / 1024 / 1024 < 50;

		       if (!isJPG && !isPNG) {
		          this.$message.error(proxy.$t('search.isJPG'));
		        }
		        if (!isLt2M) {
		          this.$message.error(proxy.$t('search.sizeLimit'));
		        }
		        return isJPG && isPNG && isLt2M;
		    }
		    const store = useStore();

		    // store.dispatch("common/getSubjectCats").then((res) => {
	     //    	data.hot = res
	     //    })

		    // const emit = defineEmits(['refresh'])
		    // const getFile = (event) => {
	     //        data.picFile = event.currentTarget.files[0];
	     //        console.log(data.picFile, "this.picFile1");
	     //        proxy.emit('refresh',data.picFile);
	            
	     //        // store.dispatch("common/uploadPic", { thefile: data.picfile } );
	     //        // this.$router.push('search_pic');

	     //        // this.postFile(this.picFile);
	     //    }


		    const refData = toRefs(data);
		    return {
		        ...refData,
		        handleUploadSuccess,
		        beforeUpload,
		        // getFile,
		    }
		},
		mounted() {
			let that = this;

			this.$nextTick(() => {
				if(document.documentElement.clientWidth < 768){
					if(document.querySelector(".stext")){
						document.querySelector(".stext").style.display = "none";
					}
					if(document.querySelector(".search-btn")){
						document.querySelector(".search-btn").style.width = "50px";
						document.querySelector(".search-btn").style.minWidth = "50px";
					}
					
				}else{
					if(document.querySelector(".stext")){
						document.querySelector(".stext").style.display = "block";
					}
					if(document.querySelector(".search-btn")){
						// document.querySelector(".search-btn").style.width = "70px";
						// document.querySelector(".search-btn").style.minWidth = "70px";
					}
				}

				if(document.documentElement.clientWidth < 948){
					// document.querySelector(".color-dialog").style.padding = "12px 12px";	
					document.querySelector(".slider-content").style.padding = "0 5px";				
				}else{
					// document.querySelector(".color-dialog").style.padding = "32px 24px";
					document.querySelector(".slider-content").style.padding = "0 80px";
				}

				window.addEventListener('resize', function(event){
					if(document.documentElement.clientWidth < 768){
						if(document.querySelector(".stext")){
							document.querySelector(".stext").style.display = "none";
						}
						if(document.querySelector(".search-btn")){
							document.querySelector(".search-btn").style.width = "50px";
							document.querySelector(".search-btn").style.minWidth = "50px";
						}
						
					}else{
						if(document.querySelector(".stext")){
							document.querySelector(".stext").style.display = "block";
						}
						if(document.querySelector(".search-btn")){
							// document.querySelector(".search-btn").style.width = "70px";
							// document.querySelector(".search-btn").style.minWidth = "70px";
						}
					}

					if(document.documentElement.clientWidth < 948){
						// document.querySelector(".color-dialog").style.padding = "12px 12px";	
						document.querySelector(".slider-content").style.padding = "0 5px";				
					}else{
						// document.querySelector(".color-dialog").style.padding = "32px 24px";
						document.querySelector(".slider-content").style.padding = "0 80px";
					}

				});
				
				// let allmovebtn = document.getElementsByClassName("color-box");
				// document.querySelector(".changebtn").style.display = "none";
				// // let arr = [];
				// if(allmovebtn.length > 0){
				// 	for(let i = 0;i < allmovebtn.length; i++){
				// 		console.log(i-1,"i")
				// 		if( i-1 > -1 ){
				// 			// arr[i] = i-1;
				// 			document.querySelector(".changebtn").style.display = "-ms-flexbox";
				// 			document.querySelector(".changebtn").style.display = "flex";

				// 		}					
				// 	}
				// }

			});	

			
		},
		watch(){

		},
		computed:{

		},
		methods:{
			toSubjectCategory(id){
				this.$router.push({ path:'/topic_pic_category',query:{catid:id} });
			},
			ImgColor() {
				// console.log(this.bannerimg, "bannerimg");
	            let domImg = document.querySelector('#pickimg'); // 获取图片dom节点
	            let colorthief = new ColorThief();
	            domImg.addEventListener('load', () => { // 图片加载
	                this.colors = colorthief.getPalette(domImg).map(e => {
	                    let hex = this.rgbaToHex(e)
	                    return hex
	                }); // 调色板
	                this.zcolor = this.rgbaToHex(colorthief.getColor(domImg)) // 图片主色调
	                console.log('调色盘', this.colors);//getPalette(domImg, 2)第二个参数可选(1~10)
	                console.log('主色调', this.zcolor);//第二个参数可选(1~10)
	            })
	        },
	        searchKeywords(keyword,type='other'){
	        	console.log(keyword,"keyword");
	        	this.input = keyword;
	        	if(this.isNeedRedirect){
		        	this.$store.dispatch('common/searchKeywords', {
		            	keyword: keyword,
		            	from: type,
		            	type:'keyword',
		            });
		            this.$router.push({ path:'/search_pic' });
		        } else {
		        	this.input = keyword;
		        	this.$emit('searchKeywords', keyword);
		        }
	            
	        },
	        handleDrop(e) {
			    e.preventDefault();
			    // let files = e.dataTransfer.files;
			    this.picFile = e.dataTransfer.files[0];
	            this.$store.dispatch('common/uploadPic', {
	            	uploadPic: this.picFile,
	            	from: 'other',
	            	type:'file',
	            });
	            this.$router.push({ name:'search_pic',params:{ where:'other' } });
		    },
		    handleDragOver(e) {
		      e.preventDefault();
		    },
	        getFile(event){
	        	this.picFile = event.currentTarget.files[0];
	        	console.log(this.isNeedRedirect,"isNeedRedirect");
	        	if(this.isNeedRedirect){
	        		this.$store.dispatch('common/uploadPic', {
		            	uploadPic: this.picFile,
		            	from: 'other',
		            	type:'file',
		            });
		            this.$router.push({ name:'search_pic',params:{ where:'other' } });
	        	} else {
		            console.log(this.picFile,"this.picFile");
		            this.$emit('refresh', this.picFile);
	        	}
	            
	            // this.$store.dispatch("common/uploadPic",{file: this.picfile});
	            // this.$router.push('search_pic');

	            // this.postFile(this.picFile);
	        },
	        rgbaToHex(rgba) { // rgba转16进制
	            let hex = '#';
	            for (const i of rgba) {
	                hex += i.toString(16).padStart(2, '0');
	            }
	            return hex;
	        },
	        loadImage() {
	        	console.log(this.bannerimg, "bannerimg");
                let domImg = document.querySelector('#pickimg');
                let colorthief = new ColorThief();
                this.bacolor = colorthief.getPalette(domImg)[0];
                console.log(this.bacolor,"this.bacolor"); //主颜色
            },
			// handleUploadSuccess(res, file) {
			// 	console.log(file,"file");
		 //        this.imageUrl = URL.createObjectURL(file.raw);
		 //        console.log(this.imageUrl,"this.imageUrl")
		 //    },
		 //    beforeUpload(file) {
		 //        const isJPG = file.type === 'image/jpeg';
		 //        const isPNG = file.type === 'image/png';
		 //        const isLt2M = file.size / 1024 / 1024 < 50;

		 //        if (!isJPG && !isPNG) {
		 //          this.$message.error('上传头像图片只能是 JPG 格式!');
		 //        }
		 //        if (!isLt2M) {
		 //          this.$message.error('上传头像图片大小不能超过 2MB!');
		 //        }
		 //        return isJPG && isPNG && isLt2M;
		 //    },
			handleColorChange(event) {
				console.log(event,"event");
			    // const reg = /[0-9]\d+/g
			    // const getArr = value.match(reg)
			    // let hexStr = '#'+((getArr[0] << 16) | (getArr[1]  << 8) | getArr[2] ).toString(16);
			    // console.log(hexStr,"hexStr")
			    this.color = event.target.dataset.color;

			},
			handleMouseMove(event) {
				// console.log(event,"event");
				let outerWidth = event.currentTarget.parentElement.parentElement.clientWidth;
				// console.log(event.currentTarget.parentElement.clientWidth,"parentElement");
    			// console.log(event.currentTarget.parentElement.parentElement.clientWidth,"pparentElement");
				// 处理移动事件的函数
    			const moveHandler = (e) => {
    				// console.log(e.movementX,"mousemove");
    				if (!this.isDragging) return;
					// 设置拖拽状态和鼠标样式 将拖拽状态重置
					let innerWidth = e.currentTarget.parentElement.clientWidth;							
					let percent = this.circumference(innerWidth / outerWidth);

					let nextInnerWidth = e.currentTarget.parentElement.nextElementSibling.clientWidth;
					let nextPercent = this.circumference(nextInnerWidth / outerWidth);

					if(e.movementX > 0){ //左加  右减
						percent = (innerWidth + Math.abs(e.movementX)) / outerWidth;
						percent = this.circumference(percent);

						nextPercent = (nextInnerWidth - Math.abs(e.movementX)) / outerWidth;
						nextPercent = this.circumference(nextPercent);

					} else { //左减  右加

						percent = (innerWidth - Math.abs(e.movementX)) / outerWidth;
						percent = this.circumference(percent);

						nextPercent = (nextInnerWidth + Math.abs(e.movementX)) / outerWidth;
						nextPercent = this.circumference(nextPercent);
					}

					percent += '%';
					nextPercent += '%';
					e.currentTarget.parentElement.style.width = percent;
					e.currentTarget.parentElement.nextElementSibling.style.width = nextPercent;
    			};
    			// 处理鼠标松开事件的函数
    			const upHandler = () => {
    				// console.log("onmouseup");
    				this.isDragging = false;
    				document.removeEventListener('mousemove', moveHandler);
        			document.removeEventListener('mouseup', upHandler);
    			};
    			this.isDragging = true;
    			// 监听移动和松开事件
			    event.currentTarget.addEventListener('mousemove', moveHandler);
			    document.addEventListener('mouseup', upHandler);
			},
			circumference(x) {
			  if ( Number.isNaN( Number.parseFloat(x) ) ) {
			    return 0;
			  }
			  return Number.parseFloat(x*100).toFixed(2);
			},
			setItemRefs(e) {
				// console.log(e,"e");
			},
			clickMenu(data){
		  		this.active_menuid = data.name;
		  		if(this.active_menuid == 'pic'){
		  			this.ispic = true;
		  			// this.placeholder = '可以尝试搜索关键词';
		  		}else if(this.active_menuid == 'video'){
		  			this.ispic = false;
		  			// this.placeholder = '可以尝试搜索关键词';
		  		}
		  	},
		    generateClass(data){
			    if(this.active_menuid == data.name){
			        return ['tabitem','active'];
			    }
			    return ['tabitem'];
		    },
		    addColor() {
		  		// if(allmovebtn.length > 0){
				// 	for(let i = 0;i < allmovebtn.length; i++){
				// 		if( i-1 > -1 ){
							
				// 			document.querySelector(".changebtn").style.display = "-ms-flexbox";
				// 			document.querySelector(".changebtn").style.display = "flex";

				// 		}					
				// 	}
				// }
		  		// let allmovebtn = document.getElementsByClassName("changebtn");
		    },
		    addClass(){
		    	return "add-text";
		    },

		},
	}
</script>

<style src="@/assets/css/search-bar.css" scoped></style>