
import instance from "@/utils/request";


/**
 * 专题详情
 */
export function GetSubjectDetail(param = {}) {
  return instance.get("v1/topic/detail/"+param.id);
}
/**
 * 专题列表
 */
export function GetSubjectList(param = {}) {
  return instance.get("v1/topic/list",{
    params: param
  });
}
/**
 * 首页滚动图
 */
export function GetIndexSlider(param = {}) {
  return instance.get("v1/news/ad/slider",{
    params: param
  });
}
/**
 * 首页资讯
 */
export function GetIndexNews(param = {}) {
  return instance.get("v1/news/index",{
    params: param
  });
}
/**
 * 系统信息
 */
export function SiteInfo(param = {}) {
  return instance.get("v1/siteinfo",{
    params: param
  });
  // return instance.request({
  //   method: "GET",
  //   url: "v1/siteinfo",
  //   data: param,
  // });
}

/**
 * 权益套餐
 */
export function PackList(param = {}) {
  return instance.get("v1/wallet/package/image",{
    params: param
  });
}
/**
 * 权益包内容
 */
export function PackPrevileges(param = {}) {
  return instance.get("v1/previleges",{
    params: param
  });
}
/**
 * 广告列表
 */
export function ArList(param = {}) {
  return instance.get("v1/news/ad/list",{
    params: param
  });
}
/**
 * 广告详情
 */
export function ArDetail(param = {}) {
  return instance.get("v1/news/ad/"+param.id);
}
/**
 * 新闻详情
 */
export function ArNewsDetail(param = {}) {
  return instance.get("v1/news/"+param.id);
}
/**
 * 图片搜索
 */
export function PicVideoDetail(param = {}) {
  return instance.get("v1/image/"+param.id);
}
/**
 * 图片分类
 */
export function PicCats(param = {}) {
  return instance.get("v1/image/cates",{
    params: param
  });
}
/**
 * 专题分类
 */
export function SubjectCats(param = {}) {
  return instance.get("v1/subject/cates",{
    params: param
  });
}
/**
 * 所有专题分类
 */
export function AllSubjectCats(param = {}) {
  return instance.get("v1/subject/cate/tree",{
    params: param
  });
}
/**
 * 获取专题列表
 */
export function SubjectList(param = {}) {
  return instance.get("v1/subject/list",{
    params: param
  });
}
/**
 * 专题图片
 */
export function SubjectPic(param = {}) {
  return instance.get("v1/subject/"+param.id+"/thumb",{
    params: param
  });
}
/**
 * 相似图片
 */
export function SimilarPicDetail(param = {}) {
  return instance.get("v1/image/similar/"+param.id,{
    params: param
  });
}
/**
 * 图片标签
 */
export function PicLabels(param = {}) {
  return instance.get("v1/image/labels",{
    params: param
  });
}
/**
 * 摄影师列表
 */
export function cameraList(param = {}) {
  return instance.get("v1/camera/list",{
    params: param
  });
}
/**
 * 摄影师专辑列表
 */
export function cameraAlbum(param = {}) {
  return instance.get("v1/camera/cthumbs",{
    params: param
  });
}
/**
 * 摄影师作品列表
 */
export function cameraWork(param = {}) {
  return instance.get("v1/camera/thumb",{
    params: param
  });
}
/**
 * 优秀摄影师列表
 */
export function cameraExcelent(param = {}) {
  return instance.get("v1/camera/excelent",{
    params: param
  });
}
/**
 * 图片搜索
 */
export function PicLists(param = {}) {
  return instance.get("v1/image/list",{
    params: param
  });
}

/**
 * 以图搜图
 */
export function postPic(param = {}) {
  return instance.request({
    method: "POST",
    url: "v1/image/list",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: param,
  });
}
/**
 * 摄影师进驻
 */
export function PhotoCats(param = {}) {
  return instance.get("v1/camera/cates",{
    params: param
  });
  
}
/**
 * 图片搜索
 */
export function vrList(param = {}) {
  return instance.get("v1/vr/list",{
    params: param
  });
}

/**
 * 视讯列表
 */
export function NewsList(param = {}) {
  return instance.get("v1/news/list",{
    params: param
  });
}

/**
 * 视讯详情
 */
export function NewsDetail(param = {}) {
  return instance.get("v1/news/detail/"+param.id,{
    params: param
  });
}
/**
 * 视讯详情
 */
export function OrderInfo(param = {}) {
  return instance.get("v1/order/detail/"+param.id);
}

/**
 * 创建订单
 */
export function CreateOrder(param = {}) {
  return instance.request({
    method: "POST",
    url: "v1/order/create",
    headers: {
      // "Content-Type": "multipart/form-data",
    },
    data: param,
  });
}


