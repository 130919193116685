<template>
	<PageLoading :is-loading="isLoading"></PageLoading>
	<div class="container">
		<!-- <Header2 :show-search-bar-pic="showHeader" login-style="loginStyle" :mobile-icon-style="mobileIconStyle" :show-list-icon="showListIcon"/> -->
		<!-- <div class="header-container">
			<div class="search-swiper-container">				
				<Swiper :list="banners"/>
				<search-bar-pic></search-bar-pic>
			</div>
		</div> -->
		<div class="header-container">
			<Header2 />			
			<div class="search-swiper-container">
			</div>
		</div>
		<div class="content">
			<div class="index-pic">				
				<!-- <div class="pic-wrapper">
					<search-bar-pic @refresh="getData"></search-bar-pic>
				</div> -->
			</div>
			<div class="tag-container">
				<div class="cats-wrapper">
					<div style="padding:5px 0;">
						<div class="cats-item">
							<div class="text">分类</div>	
						</div>
						
						<div class="cats-subitems" v-for="item in cats">
							<div class="cats-first" :class="[selectedCat==item.id?'active':'']" @click="selectCat(item.id)">{{item.title}}</div>
							<div class="cats-second">
								<div class="cats-subitem" :class="[selectedCat==tag.id?'active-tag':'']"  @click="selectCat(tag.id)" v-for="tag in item.children" :key="tag.id">{{tag.title}}</div>
							</div>
							
						</div>
					</div>
					<div style="padding:5px 0;">
						<div class="cats-item">
							<div class="text">标签</div>	
						</div>
						<div class="cats-subitems">
							<div class="cats-subitem" :class="[selectedLabel==tag.id?'active-tag':'']" v-for="tag in labels" @click="selectLabel(tag.id)">{{tag.title}}</div>
						</div>
					</div>
					<div style="padding:5px 0;">
						<div class="cats-item">
							<div class="text">上线时间</div>	
						</div>
						<div class="cats-subitems">
							<div class="cats-subitem" :class="[selectedOnlineTime==tag.type?'active-tag':'']" v-for="tag in onlineTime" @click="selectTime(tag.type)">{{tag.title}}</div>
						</div>
					</div>
					<div style="padding:5px 0;">
						<div class="cats-item">
							<div class="text">分辨率</div>	
						</div>
						<div class="cats-subitems">
							<div class="cats-subitem" :class="[selectedDpi==tag.type?'active-tag':'']" v-for="tag in dpi" @click="selectDpi(tag.type)">{{tag.title}}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="pic-content">
				<div class="enclosure" style="">
					<section class="section">
						<div class="_1Aw4E" v-for="item in pics.data">
							<a @click="toPicDetail(item.id)">
								<img :src="item.preview" oncontextmenu="return false" />
								<!-- <span class="category-name">{{item.catname}}</span> -->
							</a>
						</div>
					</section>
				</div>
			</div>
			
		</div>
		<Footer/>
	</div>
</template>

<script>
	import pic_category from './topic_pic_category_controller';
	export default pic_category;
</script>

<style src="@/assets/css/topic_pic_category.css" scoped></style>