import { onMounted,nextTick, defineComponent, ref, provide, reactive, toRefs, getCurrentInstance } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import Swiper from '@/components/layout/Swiper.vue';
import SearchBar2 from '@/components/layout/SearchBar2.vue';
import Header2 from '@/components/layout/header2.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';
import SideMenu from '@/components/layout/SideMenu.vue';
import bus from 'vue3-eventbus'
import SideComponent from '@/components/common/SideComponent.vue';
import Loading from '@/components/common/Loading.vue';
// import { timestampToTime } from '@/utils/utils';

export default defineComponent({
	components: {
		Header2,
		Menu,
	    Swiper,
	    SearchBar2,
	    Footer,
	    SideMenu,
	    SideComponent,
	    Loading,
	},
	props: {

	},
	setup() {
		let {ctx, proxy} = getCurrentInstance();
		const router = useRouter();
	    const route = useRoute();
		const data = reactive({
			showHeader: true,
			loginStyle: "min-width:141px;",
			mobileIconStyle: "color:rgba(223, 58, 8, 1);",
			showListIcon: true,
			activeName:'first',
			size: 'default',
			date: [],
			input: '',
			OrderList:[],
			pagination:{},
			isMobile:false,
			keyword: '',
			start: '',
			end: '',
			shortcuts:[],
			page:1,
			isPicLoading:true,
		});
		const showMenu = ref(proxy.$menus);
		provide('showMenu', showMenu);

		const outerStyleStr = ref("position:relative;left:10%;top:40%;transform:translate(-10%,-40%);width:auto;");
		provide('outerStyleStr', outerStyleStr);

		const styleStr = ref("height:45px;");
		provide('styleStr', styleStr);

		const showSearchText = ref(false);
		provide('showSearchText', showSearchText);

		const getImageUrl = (name) => {
		    return proxy.$utils.getImageUrl(name);
		};

		const store = useStore();	

		const ruleFormRef = ref()

		const handleClick = (tab, event) => {
		  console.log(tab, event)
		}
		const getOrderList = (page=1,keyword='',start='',end='') => {
			store.dispatch("user/OrderList",{
				page:page,
				search: keyword,
				start: start,
				end: end,
			}).then((res)=>{
				console.log(res,"OrderList");
				data.OrderList = res.data;
				data.pagination = res.pagination;
				data.page = res.pagination.current;
				data.isPicLoading = false;
				bus.emit('showFooter',true);
			}).catch(err=>{
				data.isPicLoading = false;
			})
		}
		const detail = (order_id) => {
			router.push({name:'order_detail',query:{order_id:order_id}});
		}
		const ContractDownload = (order_id) => {
	    	store.dispatch("user/ContractDownload",{
	    		order_id: order_id,
	    	}).then((res)=>{
	    		console.log(res,"download");
	    		data.contractUrl = res;
	    		// location.href = res;
	    	}).catch(err => {	            
	            console.log("err",err);
	        })
	    }
		
		data.shortcuts = [
		  {
		    text: '最近一个周',
		    value: () => {
		      const end = new Date()
		      const start = new Date()
		      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
		      return [start, end]
		    },
		  },
		  {
		    text: '最近一个月',
		    value: () => {
		      const end = new Date()
		      const start = new Date()
		      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
		      return [start, end]
		    },
		  },
		  {
		    text: '最近3个月',
		    value: () => {
		      const end = new Date()
		      const start = new Date()
		      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
		      return [start, end]
		    },
		  },
		];

		onMounted(async()=>{
        	getOrderList();
        	await nextTick()
        	setTimeout(() => {
		      data.isLoading = false;
		    }, 600);
		    console.log("页面数据更新完毕了")
        })

        const viewBox = ref(null);
        const scroll = (val) => {
			if(val == 'top'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		       
			}else if(val == 'bottom'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });		        	
		        
			}
		}
        

		const refData = toRefs(data);

		return {
			...refData,
			getImageUrl,
			handleClick,
			getOrderList,
			viewBox,
			scroll,
			detail,
			ContractDownload,
		}
	},
	created() {

	},
	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	},
	methods: {
		init() {
			if(document.documentElement.clientWidth < 890){
				this.size = 'default';
				this.isMobile = true;
			}else{
				this.size = 'default';
				this.isMobile = false;
			}

	        window.addEventListener('resize', function(event){
				if(document.documentElement.clientWidth < 890){
					this.size = 'default';
					this.isMobile = true;
				}else{
					this.size = 'default';
					this.isMobile = false;
				}
				
			});
		},
		toContract(order_id){
			this.$router.push({ path:'/my/contract',query:{order_id: order_id} });
		},
		handleSizeChange(pageSize) {
	      this.pagination.pageSize = pageSize;
	      // 在此刷新数据
	    },
		handlePageChange(currentPage){
			this.pagination.current = currentPage;
			this.search();
		},
		search(){		
			if(this.date.length > 0){
				this.start = this.date[0];
				this.end = this.date[1];
			}
			this.isPicLoading = true;
			this.getOrderList(this.pagination.current, this.keyword, this.start, this.end);
		},
		parseTime(time, cFormat) {
		  if (arguments.length === 0) {
		    return null
		  }
		  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
		  let date
		  if (typeof time === 'object') {
		    date = time
		  } else {
		    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
		      time = parseInt(time)
		    }
		    if ((typeof time === 'number') && (time.toString().length === 10)) {
		      time = time * 1000
		    }
		    date = new Date(time)
		  }
		  const formatObj = {
		    y: date.getFullYear(),
		    m: date.getMonth() + 1,
		    d: date.getDate(),
		    h: date.getHours(),
		    i: date.getMinutes(),
		    s: date.getSeconds(),
		    a: date.getDay()
		  }
		  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
		    const value = formatObj[key]
		    // Note: getDay() returns 0 on Sunday
		    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
		    return value.toString().padStart(2, '0')
		  })
		  return time_str
		},
		timestampToTime(timestamp) {
		  timestamp = timestamp ? timestamp : null;
		  let date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
		  let Y = date.getFullYear() + '-';
		  let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
		  let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
		  let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
		  let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
		  let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
		  return Y + M + D + h + m + s;
		},
	},
})