<template>
	<!-- 动画节点 -->
	<div id="loading-mask" v-if="isLoading">
	  <div class="loading-wrapper">
	     <span class="loading-dot loading-dot-spin">
	     	<i></i>
	     	<i></i>
	     	<i></i>
	     	<i></i>
	     </span>
	  </div>
	  <div class="animate-text">
	  	  <span class="animate__animated animate__fadeInUp ani-text">{{$t('title')}}</span>
	  </div>
	</div>

</template>
<script>
	import { ref, provide,reactive, inject, toRefs } from 'vue';
	export default {
		props:{
			isLoading: {
				type: Boolean,
				default: true
			},
		}
	}
</script>
<style>
#loading-mask .animate-text {
	position:absolute;
	left:0;top:0;
	width:100%;height:100%;
	display:flex;
	justify-content: center;
	align-items: center;
}
#loading-mask .animate-text .ani-text {
	margin-top: 100px;
	padding:10px 20px;
	/*position: absolute;
	top: 50%;
	left: 43%;
	transform: translate(-43%,-90%);*/
	font-size: 36px;
	padding:5px 10px;
	color:rgba(223, 58, 8, 1); 
	text-indent: 30px;
	line-height: 50px;
	font-size: 40px;
	font-weight: bolder;
	background-image: -webkit-linear-gradient(bottom, red, #fd8403, yellow); 
	-webkit-background-clip: text; 
	-webkit-text-fill-color: transparent;
	animation-delay:0.1s;
}
/*.text span {
	font-size: 36px;
	padding:5px 10px;
	color:rgba(223, 58, 8, 1); 
	text-indent: 30px;
	line-height: 50px;
	font-size: 40px;
	font-weight: bolder;
	background-image: -webkit-linear-gradient(bottom, red, #fd8403, yellow); 
	-webkit-background-clip: text; 
	-webkit-text-fill-color: transparent;
}	*/

.box1 {
	 position: relative;
	 text-align: left;
	 text-indent: 30px;
	 line-height: 50px;
	 font-size: 40px;
	 font-weight: bolder;
	 background-image: -webkit-linear-gradient(bottom, red, #fd8403, yellow); 
	 -webkit-background-clip: text; 
	 -webkit-text-fill-color: transparent;
}

.box { 
	position: relative; 
	text-align: left;
	text-indent:30px;
	line-height: 50px; 
	font-size: 40px;
	font-weight: bolder;
	color: red;
	-webkit-mask-image: -webkit-gradient(linear, 0 0, 0 bottom, from(yellow), to(rgba(0, 0, 255, 0))); 
}

#loading-mask {
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background: #fff;
	user-select: none;
	z-index: 9999;
	overflow: hidden
}

.loading-wrapper {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-100%)
}

.loading-dot {
	animation: antRotate 1.2s infinite linear;
	transform: rotate(45deg);
	position: relative;
	display: inline-block;
	font-size: 64px;
	width: 64px;
	height: 64px;
	box-sizing: border-box
}

.loading-dot i {
	width: 22px;
	height: 22px;
	position: absolute;
	display: block;
	background-color: rgba(223, 58, 8, 1);
	/*background-color: #1890ff;*/
	border-radius: 100%;
	transform: scale(.75);
	transform-origin: 50% 50%;
	opacity: .3;
	animation: antSpinMove 1s infinite linear alternate
}

.loading-dot i:nth-child(1) {
	top: 0;
	left: 0
}

.loading-dot i:nth-child(2) {
	top: 0;
	right: 0;
	-webkit-animation-delay: .4s;
	animation-delay: .4s
}

.loading-dot i:nth-child(3) {
	right: 0;
	bottom: 0;
	-webkit-animation-delay: .8s;
	animation-delay: .8s
}

.loading-dot i:nth-child(4) {
	bottom: 0;
	left: 0;
	-webkit-animation-delay: 1.2s;
	animation-delay: 1.2s
}

@keyframes antRotate {
	to {
		-webkit-transform: rotate(405deg);
		transform: rotate(405deg)
	}
}

@-webkit-keyframes antRotate {
	to {
		-webkit-transform: rotate(405deg);
		transform: rotate(405deg)
	}
}

@keyframes antSpinMove {
	to {
		opacity: 1
	}
}

@-webkit-keyframes antSpinMove {
	to {
		opacity: 1
	}
}
</style>
