import { createI18n } from 'vue-i18n'

import zh from './zh'
import en from './en'
import zhCHT from './zhCHT'
import ar from './ar'
import de from './de'
import ja from './ja'
import ko from './ko'
import ru from './ru'


const messages = {
    en,
    zh,
    zhCHT,
    // 'zh-CHT':{
    //     ...zhCHT,
    // },
    ar,
    de,
    ja,
    ko,
    ru,
}

const language = (navigator.language || 'zh').toLocaleLowerCase() // 这是获取浏览器的语言
// 创建 i18n
const i18n = createI18n({
    locale: localStorage.getItem('lang') || language.split('-')[0] || 'zh', // 首先从缓存里拿，没有的话就用浏览器语言，
    fallbackLocale: 'zh', // 设置备用语言
    messages,
    legacy: false, // 如果要支持compositionAPI，此项必须设置为false;
  	globalInjection: true, // 全局注册$t方法
})
 
export default i18n
