<template>
	<PageLoading :is-loading="isLoading"></PageLoading>
	<SideComponent @scrollto="scroll"></SideComponent>
	<div class="container" ref="viewBox">
		<div class="header-container">
			<div class="search-swiper-container">				
				<Swiper :list="banners"/>
				<search-bar-pic :isNeedRedirect="false" @searchKeywords="fetchGrapher"></search-bar-pic>
			</div>
		</div>
		<div class="content">
			<div class="wrapper">
				<div class="left" style="display:none;">
					<div class="caption">
						{{$t('grapher.title')}}
					</div>
					<div class="cats">
						<div class="item" v-for="item in categorys" @click="changeTag(item)">
							<div class="border" :class="{ active: item.id == currentTag }">
								<div class="subitem">
									{{item.name}}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="right">
					<div class="outer">
						<div class="caption">
							<span class="">{{$t('grapher.home')}}</span>
							<span style="padding: 0 10px;">></span>
							<span class="">{{$t('grapher.grapher')}}</span> <!-- {{currentTagName}} -->
							<span>{{$t('grapher.tip1')}}</span>
						</div>
						<div class="main">
							<div class="item" v-for="item in photoGraphers" @click="toDetail(item.id)">
								<div class="subitem">
									<img :src="item.thumb" oncontextmenu="return false"/>
									<div class="name">{{item.name}}</div>
									<div class="title">{{$t('grapher.tip2')}}</div>
									<div class="desc">{{item.description}}</div>
								</div>
								
							</div>
						</div>
						<!-- <div class="loadmore">
							<span class="more" @click="loadMore()" v-if="!nomore">{{$t('loadmore')}}</span>
							<span class="end" v-if="nomore">- {{$t('theEnd')}} -</span>
						</div> -->
						<div class="page-wrapper">
							<Loading :is-loading="isPicLoading"></Loading>
							<el-pagination 
							    background 
							    layout="prev, pager, next" 
							    :total="parseInt(pagination.total)" 
							    v-model:current-page="pagination.current"
							    v-model:page-size="pagination.pageSize"
							    @size-change="handleSizeChange"
      							@current-change="handleCurrentChange"
							/>
						</div>
					</div>
					
				</div>
			</div>
		</div>
		<Footer/>
	</div>
</template>

<script>
	import photo_grapher from './photo_grapher_controller';
	export default photo_grapher;
</script>

<style src="@/assets/css/photo_grapher.css" scoped>
	/*@import url("@/assets/css/style.css");全局引用css*/
	/*@import '@/assets/css/style.css';*/
</style>