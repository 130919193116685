<template>
<SideComponent @scrollto="scroll"></SideComponent>
	<div class="container" ref="viewBox">
		<Header2 :show-search-bar-pic="showHeader" login-style="loginStyle" :mobile-icon-style="mobileIconStyle" :show-list-icon="showListIcon"/>
		<div class="content">
			<div style="height:80px;" v-if="!isMobile"></div>
			<div style="height:60px;" v-if="isMobile"></div>
			<div class="enclosure">
				<div class="inner">
					<div class="left">
						<SideMenu />
					</div>
					<div class="right">
						<div class="caption">
							<span>{{$t('personCenter')}}</span><span class="arrow"><i class="iconfont icon-jiantou2"></i></span><span>{{$t('cart.text1')}}</span>
						</div>
						<div class="cart-outer">
							<div class="cart-info">
								<div class="item">
									<i class="iconfont icon-gouwuche"></i>
									<div>{{$t('cart.text1')}}{{$t('cart.text2')}}<span>{{cartList.length}}</span>{{$t('cart.text3')}}</div>
								</div>
								<div class="item">
									<el-select v-model="operation" :placeholder="$t('cart.text4')" style="width:100px;" @change="operate()" v-if="!isMobile">
								      <el-option
								        v-for="item in options"
								        :key="item.value"
								        :label="item.label"
								        :value="item.value"
								      />
								    </el-select>
								    <el-select v-model="operation" size="small" :placeholder="$t('cart.text4')" style="width:80px;" @change="operate()" v-if="isMobile">
								      <el-option
								        v-for="item in options"
								        :key="item.value"
								        :label="item.label"
								        :value="item.value"
								      />
								    </el-select>
								</div>
							</div>
							<div class="cart-table">
								<div class="table-head" v-if="!isMobile">
									<!--watch -->
									<div class="item"><input type="checkbox" v-model="checkedAll" @change="changeAllChecked()" /></div>
									<!--computed -->
									<!-- <div class="item"><input type="checkbox" v-model="checkedAll"/></div> -->
									<div class="item">{{$t('cart.text5')}}</div>
									<div class="item">{{$t('cart.text6')}}</div>
									<div class="item">{{$t('cart.text7')}}</div>
									<div class="item">{{$t('cart.text8')}}</div>
									<div class="item">{{$t('cart.text4')}}</div>
									
								</div>
								<div class="table-body">
									
									<div class="item" v-for="(item,index) in cartList" v-if="!isMobile">
										<!--computed -->
										<!-- <div class="subitem"><input type="checkbox" v-model="item.checked"/></div> -->
										<!--watch -->
										<div class="subitem"><input type="checkbox" :value="item.id" v-model="checkedItem"/></div>
										<div class="subitem">
											<img :src="item.image.preview"/>
										</div>
										<div class="subitem">{{item.image.title}}</div>
										<div class="subitem">{{item.image.norms}}</div>
										<div class="subitem " style="flex-direction: column;align-items: flex-start;padding:0 10px;">
											<div style="width:100%;" v-for="(value,oindex) in item.image.option_all">
												<div v-if="value.isChecked">
													{{value.id==0?'原图：':(value.id==1?'小图：':(value.id==2?'大图：':''))}}<span style="color:rgb(255,149,0);">￥{{value.price}}</span><span>（{{value.width}} x {{value.height}}）px</span>
												</div>
												<!-- <input type="radio" :value="value.id" :checked="value.isChecked" @change="changeInput(index, oindex)" /> 
												{{value.id==0?'原图：':(value.id==1?'小图：':(value.id==2?'大图：':''))}}<span style="color:rgb(255,149,0);">￥{{value.price}}</span><span>（{{value.width}} x {{value.height}}）px</span> -->
											</div>
										</div>
										<!-- <div class="subitem price">
											<span><input type="checkbox" :value="" v-model=""></span>
										</div> -->
										<div class="subitem"><span class="del-btn" @click="del(item.image_id)">{{$t('cart.text9')}}</span></div>
									</div>

									<div class="item" v-for="(item,index) in cartList" v-if="isMobile">
										<!--computed -->
										<!-- <div class="subitem"><input type="checkbox" v-model="item.checked"/></div> -->
										<!--watch -->
										<div class="subitem"><input type="checkbox" :value="item.id" v-model="checkedItem"/></div>
										<div class="subitem">
											<img :src="item.image.preview" />
										</div>
										<div class="subitem mobilecart">
											<div class="child">{{item.image.title}}</div>
											<div class="child dpi">{{item.image.norms}}</div>
											<div class="child price">￥{{item.image.price}}</div>
										</div>
										
										<div class="subitem operaton">
											<i class="iconfont icon-delete" @click="del(item.id)"></i>
											<!-- <span class="del-btn" @click="del(item.id)">删除</span> -->
										</div>
									</div>
									
								</div>
							</div>

						</div>
						<div class="calc-outer">
							<div class="item" v-if="!isMobile">
								<input type="checkbox" v-model="isRead"/>
								<span class="subitem">{{$t('cart.text10')}}</span>
								<span class="subitem blue" @click="fun_protocol()">{{$t('cart.text11')}}</span>
								<span class="subitem blue" @click="fun_privacy()">{{$t('cart.text12')}}</span>
							</div>
							<div class="item">
								<div class="subitem">
									{{$t('cart.text13')}}<span>{{checkedItem.length}}</span>{{$t('cart.text16')}}
								</div>
								<div class="subitem">
									<span>{{$t('cart.text14')}}</span><span class="money-type">￥</span><span class="price">{{sumPrice}}</span>
								</div>
								<div class="subitem">
									<span class="settlement" @click="topay()" :disabled="disabled">{{$t('cart.text15')}}</span>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
		<Footer/>
	</div>
	<div class="agreement" v-if="showDialog">
		<div class="agreement-title" @click.stop="close()"><i class="iconfont icon-chahao2"></i></div>
		<div class="agreement-wrapper">
			<div v-html="protocal" v-if="showProtocol"></div>
			<div v-html="privacy" v-if="showPrivacy"></div>
		</div>
	</div>
</template>

<script>
	import cart from './cart_controller';
	export default cart;
</script>

<style src="@/assets/css/my/cart.css" scoped></style>