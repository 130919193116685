<template>
<SideComponent @scrollto="scroll"></SideComponent>
	<div class="container" ref="viewBox">
		<Header2 :show-search-bar-pic="showHeader" login-style="loginStyle" :mobile-icon-style="mobileIconStyle" :show-list-icon="showListIcon"/>
		<div class="content">
			<div style="height:80px;"></div>
			<div class="enclosure">
				<div class="inner">
					<div class="left">
						<SideMenu />
					</div>
					<div class="right">
						<div class="caption">
							<span>{{$t('person.personCenter')}}</span><span class="arrow"><i class="iconfont icon-jiantou2"></i></span><span>{{$t('application.settled')}}</span>
						</div>

						<div class="tag-outer">
							<div class="tag-item">
								
								<!-- <span style="font-size:18px;color:rgb(113,113,113);font-weight:bold;">入驻流程</span> -->
								<el-steps style="width: 90%;font-size:18px;" :active="active" align-center>
								    <el-step :title="$t('apply_contract.info')"/>
								    <el-step :title="$t('apply_contract.sign')"/>
								    
								</el-steps>
							</div>
							
							<div class="settle-outer">
								<div class="info-item">
									<div class="tip-text" v-if="showInfo">
										<span>{{$t('apply_contract.tip1')}}</span>
										<span>{{$t('apply_contract.tip2')}}</span>
									</div>
									<div class="tip-text" v-if="showPdf">
										<span>{{$t('apply_contract.tip3')}}</span>
									</div>
								</div>
								
								<div class="line"></div>
								
								<div class="head" v-if="showInfo">
									<i class="iconfont icon-bianji4 edit"></i><span style="padding:0 10px;">{{$t('apply_contract.tip4')}}</span>
								</div>
								<div class="head" v-if="showPdf">
									<div style="width:100%;padding:0 10px;text-align: center;">{{$t('apply_contract.tip5')}}</div>
								</div>

								<div class="sign-form" v-if="showInfo">
									<el-form ref="ruleRef" :model="ruleUser" :rules="_rules" label-width="120px" :size="formSize" style="width:80%;" label-position="top">
										<el-form-item prop="realname" :label="$t('form.realname')">
										    <el-input v-model="ruleUser.realname" :size="formSize" autocomplete="off" :placeholder="$t('enterRealname')"></el-input>
										</el-form-item>
										
										<el-form-item prop="idcard" :label="$t('form.idcard')">
										    <el-input v-model="ruleUser.idcard" autocomplete="off" :placeholder="$t('form.enter_idcard')"></el-input>
										</el-form-item>
										
										<el-form-item :label="$t('form.idcard_photo')" prop="idcard_thumb" class="images">
											<div class="images-item btn-pic common">
												<label for="idcard_upload" class="upload-btn">
													<input type="file" id="idcard_upload" @change="UploadIdcardFile($event,'idcard')" accept="image/*" style="display:none;" />
													
													<p class="upload-tiptext">{{$t('form.uploadPic')}}</p>
												    
												</label>
												<span style="color:rgb(113,113,113);font-size:14px;padding:0 20px;">{{$t('form.idcard_tip')}}</span>
											</div>
											<div class="images-item common">
												<Loading :is-loading="isIdcardLoading"></Loading>
												<div class="img-outer">													
													<div class="img-wrapper">
														<img v-if="!ruleUser.idcard_thumb" src="@/assets/img/default.png" />
														<img v-if="ruleUser.idcard_thumb" :src="ruleUser.idcard_thumb" />
													</div>
												</div>
												
											</div>
											
										</el-form-item>
										<el-form-item prop="bank_card" :label="$t('form.bankcard')">
										    <el-input v-model="ruleUser.bank_card" autocomplete="off" :placeholder="$t('form.enter_bankcard')"></el-input>
										</el-form-item>
										<el-form-item :label="$t('form.bankcard_photo')" prop="bank_card_thumb" class="images">
											<div class="images-item btn-pic common">
												<label for="bankcard_upload" class="upload-btn">
													<input type="file" id="bankcard_upload" @change="uploadFile($event,'bankcard')" accept="image/*" style="display:none;" />
													
													<p class="upload-tiptext">{{$t('form.uploadPic')}}</p>
												    
												</label>
												<span style="color:rgb(113,113,113);font-size:14px;padding:0 20px;">{{$t('form.bankcard_tip')}}</span>
											</div>
											<div class="images-item common">
												<Loading :is-loading="isBankLoading"></Loading>
												<div class="img-outer">													
													<div class="img-wrapper">
														<img v-if="!ruleUser.bank_card_thumb" src="@/assets/img/default.png" />
														<img v-if="ruleUser.bank_card_thumb" :src="ruleUser.bank_card_thumb" />
													</div>
												</div>
												
											</div>
											
										</el-form-item>
										
										<el-form-item style="position:relative;">
											<Loading :is-loading="isPreviewLoading"></Loading>
										    <el-button type="primary" class="el-button-1" @click="submitForm(ruleRef)" :disabled="previewDisabled">{{$t('form.preview')}}</el-button>
										   
										</el-form-item>
									</el-form>
								</div>
								<div class="pdf" v-if="showPdf">    <!--  -->
									<Loading :is-loading="isPdfLoading"></Loading>
									<div class="pdf-outer" v-if="showIframe">  <!--  -->
										<iframe :src="iframeUrl" style="width:100%;height:1200px;"></iframe>
										<div class="esign-outer">
											<div style="padding:20px 0;width:100%;">
												<span style="color:red;">*</span><span style="color:green;">{{$t('apply_contract.tip6')}}</span>
											</div>
											<vue-esign ref="esign" :width="800" :height="300" :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor" v-model:bgColor="bgColor" style="border:1px solid rgba(230,0,15,1);"/>
											<div class="esign-btn">
												<Loading :is-loading="isSignLoading"></Loading>
												<button class="el-button el-button--primary" @click="handleGenerate" :disabled="signDisabled">{{$t('apply_contract.tip7')}}</button>
												<button class="el-button el-button--info is-plain" @click="handleReset">{{$t('apply_contract.tip8')}}</button>										
											</div>
										</div>
									</div>
									
								</div>
								<!-- <div class="pdf" v-if="showEsign">
									<div class="pdf-outer" v-if="showIframe">
										<iframe :src="iframeUrl" style="width:100%;height:1200px;"></iframe>
										<div class="esign-outer">
											<div style="padding:20px 0;width:100%;">
												<span style="color:red;">*</span><span style="color:green;">请在下边红色边框区域按住鼠标左键手写您的签名</span>
											</div>
											<vue-esign ref="esign" :width="800" :height="300" :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor" v-model:bgColor="bgColor" style="border:1px solid rgba(230,0,15,1);"/>
											<div class="esign-btn">
												<button class="el-button el-button--primary" @click="handleGenerate">生成签名</button>
												<button class="el-button el-button--info is-plain" @click="handleReset">清空画板</button>										
											</div>
										</div>
									</div>
									
								</div> -->
							</div>
							
							
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer/>
	</div>
</template>

<script>
	import apply_contract from './apply_contract_controller';
	export default apply_contract;
</script>

<style src="@/assets/css/my/grapher/apply_contract.css" scoped>
	/*@import url("@/assets/css/style.css");全局引用css*/
	/*@import '@/assets/css/style.css';*/
</style>