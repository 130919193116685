import { onMounted,nextTick, defineComponent, ref, provide, reactive, toRefs, getCurrentInstance } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import Swiper from '@/components/layout/Swiper.vue';
import SearchBar2 from '@/components/layout/SearchBar2.vue';
import Header2 from '@/components/layout/header2.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';
import SideMenu from '@/components/layout/SideMenu.vue';
import bus from 'vue3-eventbus'
import SideComponent from '@/components/common/SideComponent.vue';

export default defineComponent({
	components: {
		Header2,
		Menu,
	    Swiper,
	    SearchBar2,
	    Footer,
	    SideMenu,
	    SideComponent,
	},
	props: {

	},
	setup() {
		let {ctx, proxy} = getCurrentInstance();
		const router = useRouter();
	    const route = useRoute();
		const data = reactive({
			showHeader: true,
			loginStyle: "min-width:141px;",
			mobileIconStyle: "color:rgba(223, 58, 8, 1);",
			showListIcon: true,
			activeName:'first',
			username:'',
			personInfo:{},
		});
		const showMenu = ref(proxy.$menus);
		provide('showMenu', showMenu);

		const outerStyleStr = ref("position:relative;left:10%;top:40%;transform:translate(-10%,-40%);width:auto;");
		provide('outerStyleStr', outerStyleStr);

		const styleStr = ref("height:45px;");
		provide('styleStr', styleStr);

		const showSearchText = ref(false);
		provide('showSearchText', showSearchText);

		// bus.emit('needRefresh', true)

		const store = useStore();	

		const toApplication = () => {
			router.push({name:'application'});
		}
			
		const viewBox = ref(null);
        const scroll = (val) => {
			if(val == 'top'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		       
			}else if(val == 'bottom'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });  	
		        
			}
		}

		

        onMounted(async()=>{
        	await nextTick()
        	setTimeout(() => {
		      data.isLoading = false;
		      viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		      bus.emit('showFooter',true);
		    }, 600);
		    console.log("页面数据更新完毕了")
        })

		const refData = toRefs(data);

		return {
			...refData,
			viewBox,
			scroll,
			toApplication,
		}
	},
	created() {

	},
	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	},
	methods: {
		init() {
			this.username = this.$cookies.getUsername();
			
		},
	},
})