import { defineComponent, ref, provide, reactive, toRefs, getCurrentInstance, nextTick, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import {useStore} from 'vuex';
import PageLoading from '@/components/common/PageLoading.vue';
import Swiper from '@/components/layout/Swiper.vue';
import SearchBar2 from '@/components/layout/SearchBar2.vue';
import SearchBarPic from '@/components/layout/SearchBarPic.vue';
import Header2 from '@/components/layout/header2.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';
import bus from 'vue3-eventbus'
import SideComponent from '@/components/common/SideComponent.vue';
import Loading from '@/components/common/Loading.vue';

export default defineComponent({
	components: {
		PageLoading,
		Header2,
		Menu,
	    Swiper,
	    // SearchBar2,
	    SearchBarPic,
	    Footer,
	    SideComponent,
	    Loading,
	},
	props: {

	},
	setup() {
		provide('logoColor', 'white');
		let {ctx, proxy} = getCurrentInstance();
		const router = useRouter();
	    const route = useRoute();
	    const id = route.query.catid;
		const data = reactive({
			isLoading: true,
			showHeader: true,
			loginStyle: "min-width:141px;",
			mobileIconStyle: "color:rgba(223, 58, 8, 1);",
			showListIcon: true,
			ispic: true,
			banners:[],			
      		pics:[],
      		pagination:{
      			total:0,
      		},
      		subject:{
      			cate:{
      				parent:{

      				}
      			}
      		},
      		page:1,
      		nomore:true,
      		isNeedRedirect:true,
      		keyword:'',
      		picFile:'',
      		subjectCate:[],
      		isPicLoading: true,
      		isTopicLoading:true,
		});
		const showMenu = ref(proxy.$menus);
		provide('showMenu', showMenu);

		const getImageUrl = (name) => {
		    return proxy.$utils.getImageUrl(name);
		};
		data.selectedCat = id;
		const store = useStore();
		
		const viewBox = ref(null);
        const scroll = (val) => {
			if(val == 'top'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		       
			}else if(val == 'bottom'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });  
			}
		}

        const postFile = (picfile) => {
			store.dispatch("common/postPic",{
				file: picfile
			}).then((res)=>{
				console.log(res,"file to file");
				data.picList = res;//store.state.common.postPics;
				// data.pagination = res.pagination;
				data.total = data.picList.length;
			})
		}
		const getPicList = (keyword='',page=1) => {
			data.keyword = keyword;
			store.dispatch("common/getPicLists", {
	        	page: page,
	        	filter: keyword,
	        	cid: 0,
	        	lid: 0,
	        	type: 1,
	        	day: 0,
	        	ratio: 0,
	        	subject_id: 0,
	        }).then((res) => {	  
	        	console.log(res,"piclist")      	
	        	if(res.data.length > 0){
	        		if(page == 1){
	        			data.picList = res.data;
	        		}else{
	        			data.picList = data.picList.concat(res.data);
	        		}
					
					if(res.data.length < res.pagination.pageSize){
						data.nomore = true;
					}else{
						data.nomore = false;
					}
				}else{
					data.nomore = true;
				}        
	        	// data.pagination = data.videos.pagination;
	        	data.isTopicLoading = false;
	        	data.pagination = res.pagination;
	        	data.total = res.pagination.total;
	        	data.page += 1;
	        	bus.emit('showFooter',true);
	        }).catch(err=>{
	        	data.isTopicLoading = false;
	        })
		}

        const getPics = (selectedCat=0,page=1) => {
        	store.dispatch("common/getSubjectPic", {
	        	id: selectedCat,
	        	page: page,
	        }).then((res) => {
	        	console.log(res,"pics");
	        	
	        	data.pagination = res.pagination;
	        	data.subject = res.subject;
	        	data.subjectCate = res.subject.cate;
	        	if(res.data.length > 0){
	        		if(page==1){
	        			data.pics = res.data;
	        		}else{
	        			data.pics = data.pics.concat(res.data);
	        		}
					
					if(res.data.length < res.pagination.pageSize){
						data.nomore = true;
					}else{
						data.nomore = false;
					}
				}else{
					data.nomore = true;
				}        
	        	// data.pagination = data.videos.pagination;
	        	data.page += 1;
	        	data.isPicLoading = false;
	        	bus.emit('showFooter',true);
	        }).catch(err=>{
	        	data.isPicLoading = false;
	        })
        };
        

	    onMounted(async()=>{ 

	    	data.banners = store.state.common.siteInfo.sliders;       
        	data.subjectList = store.state.common.subjectList;
        	bus.emit('isLoading',false);
        	getPics(id,data.page);
        	await nextTick()
        	setTimeout(() => {
		        data.isLoading = false;
		        viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		    }, 600);
		    console.log("页面数据更新完毕了")
        })

		const refData = toRefs(data);

		return {
			...refData,
			getImageUrl,
			getPics,
			postFile,
			getPicList,
			viewBox,
			scroll,
		}
	},
	created() {

	},
	mounted() {

	},
	methods: {
		init() {

		},
		activeStyle(item) {			
			if(item.ratio_w == null || item.ratio_h == null) {
				(async () => {
				    let size = await this.$utils.getImageSize(item.preview);
				    item.ratio_w = size.width;
				    item.ratio_h = size.height;
				})();

			}
			let object = {};
			object.width = item.ratio_w*180/item.ratio_h+'px';
			return object;
		},
		loadMore() {
			this.isPicLoading = true;
			this.getPics(this.selectedCat, this.page);
		},
		getData(file) {
			this.picFile = file;//event.currentTarget.files[0];
	        this.$store.dispatch('common/uploadPic', {
            	uploadPic: this.picFile,
            	from: 'child',
            });
	        this.postFile(this.picFile);
		},
		searchKeywords(keyword){
			this.getPicList(keyword,1);
		},
		fetchPics(id){
			let that = this;
			this.$store.dispatch("common/getSubjectPic", {
	        	id: id,
	        }).then((res) => {
	        	that.pics = res.data;
	        	that.pagination = res.pagination;
	        	that.subject = res.subject;
	        	that.page += 1;
	        })
		},
		toPicDetail(id){
			// this.$router.push({ name:'topic_pic_detail', query:{ id: id } });
			let href = this.$router.resolve({ //使用resolve
	            name:'topic_pic_detail',    //这里是跳转页面的name
	            path: '/topic_pic_detail',
	            query:{ id: id },
	        })
	        window.open(href.href, '_blank')
		},
		toSubjectCategory(id){
			this.$router.push({ path:'/special_topics_category',query:{catid:id} });
		},
	},
})