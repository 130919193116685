import { onMounted,nextTick, defineComponent, ref, provide, reactive, toRefs, getCurrentInstance } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import Swiper from '@/components/layout/Swiper.vue';
import SearchBar2 from '@/components/layout/SearchBar2.vue';
import Header2 from '@/components/layout/header2.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';
import SideMenu from '@/components/layout/SideMenu.vue';
import bus from 'vue3-eventbus'
import SideComponent from '@/components/common/SideComponent.vue';
import Loading from '@/components/common/Loading.vue';

export default defineComponent({
	components: {
		Header2,
		Menu,
	    Swiper,
	    SearchBar2,
	    Footer,
	    SideMenu,
	    SideComponent,
	    Loading,
	},
	props: {

	},
	setup() {
		let {ctx, proxy} = getCurrentInstance();
		const route = useRoute();
		let type = route.query.type ? route.query.type : 0;
		let folder_id = route.query.folder_id ? route.query.folder_id : 0;
		const data = reactive({
			showHeader: true,
			loginStyle: "min-width:141px;",
			mobileIconStyle: "color:rgba(223, 58, 8, 1);",
			showListIcon: true,
			activeName: 'first',
			formSize: 'default',			
			pagination: {},
			page: 1,
			keywords: '',
			allCats: [],
			nomore: true,
			type: 1,
			worklist: [],
			workVideoList: [],
			actid:0,
			folder_id: 0,
			statusList: [
				{
					id:-1,
					name: proxy.$t('status.all'),
				},
				{
					id:0,
					name: proxy.$t('status.reviewing'),
				},
				{
					id:1,
					name: proxy.$t('status.reviewed'),
				},
				{
					id:2,
					name: proxy.$t('status.not_review'),
				},
			],
			selectedStatus: -1,
			isPicLoading:true,
			checkedItem:[],
		});
		if(type > 0){
			data.type = type;
			data.folder_id = folder_id;
			if(type==1){
				data.activeName = 'first';
			}else if(type==2){
				data.activeName = 'second';
			}
		}
		const showMenu = ref(proxy.$menus);
		provide('showMenu', showMenu);

		const outerStyleStr = ref("position:relative;left:10%;top:40%;transform:translate(-10%,-40%);width:auto;");
		provide('outerStyleStr', outerStyleStr);

		const styleStr = ref("height:45px;");
		provide('styleStr', styleStr);

		const showSearchText = ref(false);
		provide('showSearchText', showSearchText);

		const getImageUrl = (name) => {
		    return proxy.$utils.getImageUrl(name);
		};

		// bus.emit('needRefresh', true)
		
		const store = useStore();	
			
		const viewBox = ref(null);
        const scroll = (val) => {
			if(val == 'top'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		       
			}else if(val == 'bottom'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
   
			}
		}
		
		const CollectWorkList = (type=1, page=1,status=-1,folder_id=0) => {
			data.isPicLoading = true;
			store.dispatch("user/CollectList",{
				type: type,
				page: page,
				status: status,
				folder_id: folder_id,
			}).then((res)=>{
				
				if(type == 1){
					console.log(res,"worklist");
					data.worklist = res.data;
				}else if(type == 2){
					console.log(res,"workVideoList");
					data.workVideoList = res.data;
				}
				data.pagination = res.pagination;
				data.page = res.pagination.current+1;
				data.isPicLoading = false;
				bus.emit('showFooter',true);				
			}).catch(err=>{
				data.isPicLoading = false;
			})
		}
		const GetListByStatus = (status) => {
			data.selectedStatus = status;
			data.page = 1;
			console.log(data.type,"data.type")
			console.log(data.page,"data.page")
			console.log(data.selectedStatus,"data.selectedStatus")
			console.log(data.folder_id,"data.folder_id")
			
			CollectWorkList(data.type, data.page, data.selectedStatus, data.folder_id);
		}

		const handleClick = (tab, event) => {
		  console.log(tab.paneName, "tabs")
		  data.page = 1;
		  data.folder_id = 0;
		  if(tab.paneName == 'first'){
		  	data.type = 1;
		  }else if(tab.paneName == 'second'){
		  	data.type = 2;
		  }

		  CollectWorkList(data.type, data.page, -1, data.folder_id);
		}

        onMounted(async()=>{
        	console.log(data.type,"data.type")
			console.log(data.page,"data.page")
			console.log(data.selectedStatus,"data.selectedStatus")
			console.log(data.folder_id,"data.folder_id")
        	CollectWorkList(data.type, data.page, -1, data.folder_id);
        	// CameraWorkList(data.type, 1);
        	// allCategorys();
        	await nextTick()
        	setTimeout(() => {
		      data.isLoading = false;
		      viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		    }, 600);
		    console.log("页面数据更新完毕了")
        })

		const refData = toRefs(data);

		return {
			...refData,
			viewBox,
			scroll,	
			CollectWorkList,
			handleClick,
			GetListByStatus,
		}
	},
	created() {

	},
	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	},
	watch:{

	},
	methods: {
		init() {
			// console.log("this.allCategorys", this.allCats);
		},
		activeClass(id){
			if(this.selectedStatus == id){
				return 'active';
			}
			return '';
		},
		Edit(id,actid){
			console.log(this.type,"type");
			this.$router.push({name:'active_detail',query:{id: id, type: this.type, actid: actid}});
		},
		OffSale(id){
			// this.$store.dispatch("user/CameraWorkOffsale",{

			// }).then(res=>{

			// }).catch(err=>{
			// 	ElMessage({
			//         message: err.message,
			//         type: "warning",
			//     });
			// })
		},

		DeleteWork(id){
			let that = this;
			this.$store.dispatch('user/DeleteCollect',{id:id}).then(res=>{
				ElMessage({
				    message: that.$t('active.operate_success'),
				    type: 'success',
				})
				that.CollectWorkList(that.type, that.pagination.current, that.selectedStatus, that.folder_id);
			}).catch(err=>{
				ElMessage({
				    message: err.message,
				    type: 'error',
				})
			})
		},
		addTopic() {
			this.showSubject = true;
		},
		close() {
			this.showSubject = false;
		},
		handleSizeChange(pageSize) {
	      this.pagination.pageSize = pageSize;
	      // 在此刷新数据
	    },
		handlePageChange(currentPage){
			this.pagination.current = currentPage;
			this.search();
		},
		search(){		
			this.CollectWorkList(this.type, this.pagination.current,this.selectedStatus,this.folder_id);
		},
		// loadMore() {
		// 	this.getSubjectList(this.subtype, this.page);
		// },
		
        getVideoFirstFrame(url, file) {
			const video = document.createElement('video') // 创建video对象
			video.src = url // url地址
			const canvas = document.createElement('canvas') // 创建 canvas 对象
			const ctx = canvas.getContext('2d') // 绘制2d
			video.crossOrigin = 'anonymous' // 解决跨域问题，也就是提示污染资源无法转换视频
			video.currentTime = 1 // 第一秒帧
			video.oncanplay = () => {
			    canvas.width = 90
			    canvas.height = 90
			    // 利用canvas对象方法绘图
			    ctx.drawImage(video, 0, 0, 90, 90)

			    // 转换成base64形式
			    let base64_file = canvas.toDataURL('image/png');
			    // Vue.set(file, 'imageShow', canvas.toDataURL('image/png')) // 获取视频第一帧作为imageShow展示
			}
		},
	}
})