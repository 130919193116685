<template>
	<PageLoading :is-loading="isLoading"></PageLoading>
	<SideComponent @scrollto="scroll"></SideComponent>
	<div class="container" ref="viewBox">
		<!-- 以下代码与index-pic是一套 -->
		<div class="header-container">
			<Header2 />			
		</div>
		
		<div class="content">
			<div class="index-pic">				
				<div class="pic-wrapper">
					<search-bar-pic :isNeedRedirect="false" @searchKeywords="searchNews"></search-bar-pic>
				</div>
			</div>
			<div class="news-container">
				<section id="news-wrapper" class="news-wrapper">
					<div class="news-left">
						<span class="text-style">{{$t('news.news')}}</span>
					</div>
					<div class="news-right" id="newslist">
						<div class="news-item" v-for="item in newsList" @click="toDetail(item.id)">  
							<div class="news-item-outer">
								<div class="img-outer">
									<img :src="item.thumb" oncontextmenu="return false" :alt="item.title"/>
								</div>
								<div class="news-title">
									<span>{{item.title}}</span>
								</div>
							</div>
						</div>	
					</div>
					
					<!-- <div class="page-wrapper">
						<el-pagination 
						    background 
						    layout="prev, pager, next" 
						    :total="pagination.total" 
						    v-model:current-page="pagination.current"
						    v-model:page-size="pagination.pageSize"	    
						/>
					</div> -->
				</section>
				<div class="loadmore">
					<Loading :is-loading="isPicLoading"></Loading>
					<span class="more" @click="loadMore()" v-if="!nomore">{{$t('loadmore')}}</span>
					<span class="end" v-if="nomore">- {{$t('theEnd')}} -</span>
				</div>
			</div>

		</div>
		<Footer/>
	</div>
</template>

<script>
	import news from './news_controller';
	export default news;
</script>

<style src="@/assets/css/news.css" scoped></style>