<template>
	<PageLoading :is-loading="isLoading"></PageLoading>
	<SideComponent @scrollto="scroll"></SideComponent>
	<!-- <Search msg="search" search-wraper-width="auto" select-width="auto!important" margin-top="0px"/> -->
	<div class="container" ref="viewBox">
		<div class="header-container">
			<div class="search-swiper-container">				
				<Swiper :list="banners"/>
				<search-bar-video @search="getVideo"></search-bar-video>
			</div>
		</div>
		<div class="content">
			<div class="enclosure" style="">
				<section class="section">
					<!-- <Loading :is-loading="isPicLoading"></Loading>  :style="activeStyle(item)" -->
					<div class="_1Aw4E"  v-for="item in videos">
						<div @click="toDetail(item.id)" style="width:100%;height:100%;">
							<video  v-if="isMobile"								
							    :poster="item.thumb" 
							    preload="none" 
							    muted 
							    :alt="item.title" 
							    style="object-fit:cover;width:100%;height:100%" 
							    controlslist="nodownload" 
							    oncontextmenu="return false"
							    
							    >
								<track kind="captions" />
								<source :src="item.url" type="video/mp4">
							</video>
							<video  v-if="!isMobile"
							    controls								
							    :poster="item.thumb" 
							    preload="none" 
							    muted 
							    :alt="item.title" 
							    style="object-fit:cover;width:100%;height:100%;" 
							    controlslist="nodownload" 
							    oncontextmenu="return false"
							    :src="item.url"
							   
							    >
							     <!-- @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave" -->
								<track kind="captions" />
								<!-- <source :src="item.url" type="video/mp4"> -->
							</video>
						</div>
						<div class="video_name">
							<span>{{item.title}}</span>
						</div>
					</div>

				</section>
				<div class="loadmore">
					<Loading :is-loading="isPicLoading"></Loading>
					<span class="more" @click="loadMore()" v-if="!nomore">{{$t('loadmore')}}</span>
					<span class="end" v-if="nomore">- {{$t('theEnd')}} -</span>
				</div>
			</div>
		</div>
		<Footer/>
		
	</div>
</template>

<script>
	import topic_video from './topic_video_controller';
	export default topic_video;
</script>

<style src="@/assets/css/topic_video.css" scoped>
	/*@import url("@/assets/css/style.css");全局引用css*/
	/*@import '@/assets/css/style.css';*/
</style>