<template>
<SideComponent @scrollto="scroll"></SideComponent>
	<div class="container" ref="viewBox">
		<Header2 :show-search-bar-pic="showHeader" login-style="loginStyle" :mobile-icon-style="mobileIconStyle" :show-list-icon="showListIcon"/>
		<div class="content">
			<div style="height:80px;"></div>
			<div class="enclosure">
				<div class="inner">
					<div class="left">
						<SideMenu />
					</div>
					<div class="right">
						<div class="caption">
							<span>{{$t('person.personCenter')}}</span><span class="arrow"><i class="iconfont icon-jiantou2"></i></span><span>{{$t('application.settled')}}</span>
						</div>
						
						<div class="tag-outer">
							
							<div class="settle-outer">
								<div class="tag-item">
									<div class="tip-text">
										{{$t('apply_info.tip1')}}
									</div>
								</div>
								<div style="border-top:2px solid rgb(207,207,207);width:100%;height:20px;"></div>
								
								<div class="head">
									<i class="iconfont icon-bianji4 edit"></i><span style="padding:0 10px;">{{$t('apply_info.tip2')}}</span>
								</div>

								<div class="verify-form">
									<el-form ref="ruleRef" :model="ruleUser" :rules="_rules" label-width="200px" :size="formSize" class="form-style" label-position="top">
										<el-form-item prop="user_type" :label="$t('form.user_type')">
										    <el-radio v-model="ruleUser.user_type" label="1"  disabled>{{$t('form.type1')}}</el-radio>
										    <el-radio v-model="ruleUser.user_type" label="2"  disabled>{{$t('form.type2')}}</el-radio>
										</el-form-item>
										<el-form-item prop="realname" :label="$t('form.realname')" v-if="ruleUser.user_type==1">
										    <el-input v-model="ruleUser.realname" :size="formSize" autocomplete="off" :placeholder="$t('form.enterRealname')" readonly></el-input>
										</el-form-item>
										<el-form-item prop="company" :label="$t('form.company')" v-if="ruleUser.user_type==2">
										    <el-input v-model="ruleUser.company" autocomplete="off" :placeholder="$t('form.enterCompany')" readonly></el-input>
										</el-form-item>
										<el-form-item prop="mobile" :label="$t('phoneNumber')">
										    <el-input v-model="ruleUser.mobile" autocomplete="off" :placeholder="$t('enterPhoneNumber')" readonly></el-input>
										</el-form-item>
										<el-form-item prop="email" :label="$t('form.email')">
										    <el-input v-model="ruleUser.email" autocomplete="off" :placeholder="$t('form.enterEmail')" readonly></el-input>
										</el-form-item>
										
										
										<el-form-item :label="$t('form.worktype')" prop="worktype">	
											<el-checkbox-group v-model="ruleUser.worktype">								    
											    <el-checkbox label="image" value="image" disabled>{{$t('form.image')}}</el-checkbox>
											    <el-checkbox label="video" value="video" disabled>{{$t('form.video')}}</el-checkbox>		
										    </el-checkbox-group>	
										</el-form-item>
										<el-form-item prop="contract" :label="$t('apply_info.tip4')">
										    <a :href="pdfurl" class="view-contract" download v-if="pdfurl">{{$t('apply_info.tip3')}}</a>
										</el-form-item>										
										<!-- <el-form-item>
										    <el-button type="primary" class="el-button-1" @click="submitForm(ruleRef)">提交申请</el-button>	   
										</el-form-item> -->
									</el-form>
								</div>
							</div>
							
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer/>
	</div>
</template>

<script>
	import apply_info from './apply_info_controller';
	export default apply_info;
</script>

<style src="@/assets/css/my/grapher/apply_info.css" scoped> </style>