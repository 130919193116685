import { defineComponent, ref, provide, reactive, toRefs, getCurrentInstance, nextTick, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import {useStore} from 'vuex';
import PageLoading from '@/components/common/PageLoading.vue';
import Swiper from '@/components/layout/Swiper.vue';
import SearchBar2 from '@/components/layout/SearchBar2.vue';
import Header2 from '@/components/layout/header2.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';

export default defineComponent({
	components: {
		PageLoading,
		Header2,
		Menu,
	    Swiper,
	    SearchBar2,
	    Footer
	},
	props: {

	},
	setup() {
		let {ctx, proxy} = getCurrentInstance();
		const router = useRouter();
	    const route = useRoute();
	    const id = route.query.catid;
		const data = reactive({
			isLoading: true,
			showHeader: true,
			loginStyle: "min-width:141px;",
			mobileIconStyle: "color:rgba(223, 58, 8, 1);",
			showListIcon: true,
			cats: [
				{
					title: '政务活动',
					url:'',
				},
				{
					title: '国际交流',
					url:'',
				},
				{
					title: '节会活动',
					url:'',
				},
				{
					title: '经济发展',
					url:'',
				},
				{
					title: '自然风光',
					url:'',
				},
				{
					title: '城市风光',
					url:'',
				},
				{
					title: '文化体育',
					url:'',
				},
				{
					title: '影像档案',
					url:'',
				},
				{
					title: '生活时光',
					url:'',
				},
				{
					title: '民生福祉',
					url:'',
				},
				{
					title: '华夏掠影',
					url:'',
				},
				{
					title: '全球风光',
					url:'',
				},
			],
			labels: [
				{
					title: '浮山湾',
					url:'',
				},
				{
					title: '灯光秀',
					url:'',
				},
				{
					title: '栈桥',
					url:'',
				},
				{
					title: '崂山',
					url:'',
				},
				{
					title: '文化',
					url:'',
				},
				{
					title: '影视',
					url:'',
				},
				// {
				// 	title: '全部',
				// 	url:'',
				// },
				
			],
			onlineTime: [
				{
					type: '7',
					title: '最近一周',
					url:'',
				},
				{
					type: '30',
					title: '最近一个月',
					url:'',
				},
				{
					type: '180',
					title: '最近半年',
					url:'',
				},
				{
					type: '365',
					title: '最近一年',
					url:'',
				},
				// {
				// 	title: '全部',
				// 	url:'',
				// },
				
			],
			dpi: [
				{
					type: '2',
					title: '2k(2048px)',
					url:'',
				},
				{
					type: '4',
					title: '4k(4096px)',
					url:'',
				},
				{
					type: '8',
					title: '8k(8096px)',
					url:'',
				},
				// {
				// 	title: '全部',
				// 	url:'',
				// },
				
			],
      		pics:{},
      		selectedCat: 0,
      		selectedLabel: 0,
      		selectedOnlineTime: 0,
      		selectedDpi: 0,
		});
		
		const showMenu = ref(proxy.$menus);
		provide('showMenu', showMenu);

		const outerStyleStr = ref("position:relative;left:10%;top:40%;transform:translate(-10%,-40%);width:auto;");
		provide('outerStyleStr', outerStyleStr);

		const styleStr = ref("height:45px;");
		provide('styleStr', styleStr);

		const showSearchText = ref(false);
		provide('showSearchText', showSearchText);

		const getImageUrl = (name) => {
		    return proxy.$utils.getImageUrl(name);
		};

		data.selectedCat = id;
		const store = useStore();

        const getPics = (selectedCat=0,selectedLabel=0,selectedOnlineTime=0,selectedDpi=0) => {
        	store.dispatch("common/getPicLists", {
	        	page: 1,
	        	filter: '',
	        	cid: selectedCat,
	        	lid: selectedLabel,
	        	type: 1,
	        	day: selectedOnlineTime,
	        	ratio: selectedDpi,
	        }).then((res) => {
	        	data.pics = res;
	        })
        };
        

	    onMounted(async()=>{
	    	store.dispatch("common/getPicCats").then((res) => {
	        	data.cats = res
	        })

	        store.dispatch("common/getPicLabels").then((res) => {
	        	data.labels = res
	        })
	        getPics(data.selectedCat);
        	await nextTick()
        	setTimeout(() => {
		      data.isLoading = false;
		    }, 600);
		    console.log("页面数据更新完毕了")
        })

		const refData = toRefs(data);

		return {
			...refData,
			getImageUrl,
			getPics,
		}
	},
	created() {

	},
	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	},
	methods: {
		init() {

		},
		selectCat(id){
			if(this.selectedCat == 0 || this.selectedCat != id){
				this.selectedCat = id;
			}else{
				this.selectedCat = 0;
			}   
			this.getPics(this.selectedCat,this.selectedLabel,this.selectedOnlineTime,this.selectedDpi);		
			
		},
		selectLabel(id){
			if(this.selectedLabel == 0 || this.selectedLabel != id){
				this.selectedLabel = id;
			}else{
				this.selectedLabel = 0;
			} 
			
			this.getPics(this.selectedCat,this.selectedLabel,this.selectedOnlineTime,this.selectedDpi);
		},
		selectTime(type){
			if(this.selectedOnlineTime == 0 || this.selectedOnlineTime != type){
				this.selectedOnlineTime = type;
			}else{
				this.selectedOnlineTime = 0;
			} 
			
			this.getPics(this.selectedCat,this.selectedLabel,this.selectedOnlineTime,this.selectedDpi);
		},
		selectDpi(type){
			if(this.selectedDpi == 0 || this.selectedDpi != type){
				this.selectedDpi = type;
			}else{
				this.selectedDpi = 0;
			} 
      		
      		this.getPics(this.selectedCat,this.selectedLabel,this.selectedOnlineTime,this.selectedDpi);
		},
		toPicDetail(id){
			this.$router.push({ name:'topic_pic_detail', query:{ id: id } });
		},
	},
})