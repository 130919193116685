<template>
<SideComponent @scrollto="scroll"></SideComponent>
	<div class="container" ref="viewBox">
		<Header2 :show-search-bar-pic="showHeader" login-style="loginStyle" :mobile-icon-style="mobileIconStyle" :show-list-icon="showListIcon"/>
		<div class="content">
			<div style="height:80px;"></div>
			<div class="enclosure">
				<div class="inner">
					<div class="left">
						<SideMenu />
					</div>
					<div class="right">
						<div class="caption">
							<span>{{$t('person.personCenter')}}</span><span class="arrow"><i class="iconfont icon-jiantou2"></i></span><span>{{$t('application.settled')}}</span>
						</div>
						
						<div class="tag-outer">
							<div class="tag-item">
								<span style="font-size:18px;color:rgb(113,113,113);font-weight:bold;">{{$t('application.process')}}</span>
								<el-steps style="width: 90%;font-size:18px;" :active="active" align-center>
								    <el-step :title="$t('application.plain')"/>
								    <el-step :title="$t('application.apply')"/>
								    <el-step :title="$t('application.result')"/>
								</el-steps>
							</div>
							
							<div class="settle-outer" v-if="showSettle">
								<div class="tag-item">
									<div class="tip-text">
										{{$t('application.tipText1')}}<br>{{$t('application.tipText2')}}
									</div>
								</div>
								<Loading :is-loading="isSettleLoading"></Loading>
								<div class="settle" v-if="settle">								
									<div v-html="settle" class="html"></div>
								</div>
								<div class="tag-item" v-if="settle">
									<div class="application-btn" @click="toSubmit()">
										{{$t('application.apply_enter')}}
									</div>
								</div>
							</div>
							<div class="settle-outer" v-if="showVerify">
								<div class="tag-item">
									<div class="tip-text">
										{{$t('application.tipText1')}}<br>{{$t('application.tipText2')}}
									</div>
								</div>
								<div style="border-top:2px solid rgb(207,207,207);width:100%;height:20px;"></div>
								
								<div class="head">
									<i class="iconfont icon-bianji4 edit"></i><span style="padding:0 10px;">{{$t('application.base_info')}}</span>
								</div>

								<div class="verify-form">
									<el-form class="elform-class" ref="ruleRef" :model="ruleUser" :rules="_rules" label-width="200px" :size="formSize" style="" label-position="top">
										<el-form-item prop="user_type" :label="$t('form.user_type')">
										    <el-radio v-model="ruleUser.user_type" label="1">{{$t('form.type1')}}</el-radio>
										    <el-radio v-model="ruleUser.user_type" label="2">{{$t('form.type2')}}</el-radio>
										</el-form-item>
										<el-form-item prop="realname" :label="$t('form.realname')" v-if="ruleUser.user_type==1">
										    <el-input v-model="ruleUser.realname" :size="formSize" autocomplete="off" :placeholder="$t('form.enterRealname')"></el-input>
										</el-form-item>
										<el-form-item prop="company" :label="$t('form.company')" v-if="ruleUser.user_type==2">
										    <el-input v-model="ruleUser.company" autocomplete="off" :placeholder="$t('form.enterCompany')"></el-input>
										</el-form-item>
										<!-- <el-form-item prop="realname" :label="$t('form.unitName')" v-if="ruleUser.user_type==2">
										    <el-input v-model="ruleUser.realname" :size="formSize" autocomplete="off" :placeholder="$t('form.unitTip')"></el-input>
										</el-form-item> -->
										<el-form-item :label="$t('form.gender')" prop="gender" v-if="ruleUser.user_type==1">									    
										    <el-radio v-model="ruleUser.gender" label="1">{{$t('form.male')}}</el-radio>
										    <el-radio v-model="ruleUser.gender" label="2">{{$t('form.female')}}</el-radio>			
										</el-form-item>
										<el-form-item :label="$t('form.photo')" prop="thumb" class="images" v-if="ruleUser.user_type==1">
											<div class="images-item btn-pic common">
												<label for="thumb_upload" class="upload-button">
													<input type="file" id="thumb_upload" @change="uploadThumb($event,'thumb')" accept="image/*" style="display:none;" />
													
													<p class="upload-tiptext">{{$t('form.uploadPhoto')}}</p>
												    
												</label>
												<!-- <span style="color:rgb(113,113,113);font-size:14px;padding:0 20px;">（请上传身份证正面）</span> -->
											</div>
											<div class="images-item common">
												<Loading :is-loading="isThumbLoading"></Loading>
												<div class="img-outer">													
													<div class="img-wrapper">
														<img v-if="!thumb" src="@/assets/img/default.png" />
														<img v-if="thumb" :src="thumb" />
													</div>
												</div>
												
											</div>
										</el-form-item>
										<el-form-item :label="$t('form.license')" prop="license" class="images" v-if="ruleUser.user_type==2">
											<div class="images-item btn-pic common">
												<label for="license_upload" class="upload-button">
													<input type="file" id="license_upload" @change="uploadThumb($event,'license')" accept="image/*" style="display:none;" />
													
													<p class="upload-tiptext">{{$t('form.uploadLicense')}}</p>
												    
												</label>
												<span style="color:rgb(113,113,113);font-size:14px;padding:0 20px;">{{$t('form.licenseTip')}}</span>
											</div>
											<div class="images-item common">
												<Loading :is-loading="isThumbLoading"></Loading>
												<div class="img-outer">													
													<div class="img-wrapper">
														<img v-if="!license" src="@/assets/img/default.png" />
														<img v-if="license" :src="license" />
													</div>
												</div>
												
											</div>
										</el-form-item>
										<el-form-item prop="description" :label="$t('form.biography')">
										    <el-input type="textarea" rows="5" v-model="ruleUser.description" autocomplete="off" :placeholder="$t('form.enterBiography')"></el-input>
										</el-form-item>
										<el-form-item prop="mobile" :label="$t('form.mobile')">
										    <el-input v-model="ruleUser.mobile" autocomplete="off" :placeholder="$t('form.enterPhone')"></el-input>
										</el-form-item>
										<el-form-item prop="email" :label="$t('form.email')">
										    <el-input v-model="ruleUser.email" autocomplete="off" :placeholder="$t('form.enterEmail')"></el-input>
										</el-form-item>
										
										<el-form-item :label="$t('form.ctype')" prop="ctype">									    
										    <el-radio v-model="ruleUser.ctype" label="1">{{$t('form.media')}}</el-radio>
										    <el-radio v-model="ruleUser.ctype" label="2">{{$t('form.non_media')}}</el-radio>			
										</el-form-item>
										<el-form-item :label="$t('form.worktype')" prop="worktype">	
											<el-checkbox-group v-model="ruleUser.worktype" @change="handleCheckChange">								    
											    <el-checkbox label="image" value="image">{{$t('form.image')}}</el-checkbox>
											    <el-checkbox label="video" value="video">{{$t('form.video')}}</el-checkbox>		
										    </el-checkbox-group>	
										</el-form-item>
										<el-form-item :label="$t('form.workPic')" prop="images" class="images">
											<div class="images-item" style="color:rgb(113,113,113);font-size:14px;align-items: flex-start;">{{$t('form.workPicTip')}}</div>
											<div class="images-item btn-pic common">
												<label for="image_upload" class="upload-button">
													<input type="file" id="image_upload" multiple @change="uploadFile($event,'image')" accept="image/*" style="display:none;" />
													
													<p class="upload-tiptext">{{$t('form.uploadPic')}}</p>
												    
												</label>
												<span style="color:rgb(113,113,113);font-size:14px;padding:0 20px;">{{$t('form.limit_pic_quantity')}}</span>
											</div>
											<div class="images-item common">
												<Loading :is-loading="isPicLoading"></Loading>
												<div class="img-outer" v-for="(item,index) in images">
													
													<div class="img-wrapper">
														<!-- <i class="iconfont icon-morentupian1"></i> -->
														<img :src="item"/>
														<div style="position:absolute;left:0;top:0;z-index:99;width:100%;height:100%;background-color:rgba(0,0,0,0.1);display:flex;justify-content: center;align-items: center;">
															<i class="iconfont icon-delete" style="color:white;font-size:18px;" @click="del('image', index)"></i>
														</div>
													</div>
												</div>
												
											</div>
											
										</el-form-item>
										<el-form-item :label="$t('form.workVideo')" prop="video" class="images">
											<div class="images-item" style="color:rgb(113,113,113);font-size:14px;align-items: flex-start;">{{$t('form.workPicTip')}}</div>
											
											<div class="images-item btn-pic common">
												<label for="video_upload" class="upload-button">
													<input type="file" id="video_upload" multiple @change="uploadFile($event,'video')" accept="video/*" style="display:none;" />
													<p class="upload-tiptext">{{$t('form.uploadVideo')}}</p>												    
												</label>
												<span style="color:rgb(113,113,113);font-size:14px;padding:0 20px;">{{$t('form.limit_video_quantity')}}</span>
											</div>
											<div class="images-item common">
												<Loading :is-loading="isVideoLoading"></Loading>
												<div class="img-outer" v-for="(item,index) in video">

													<div class="img-wrapper">
														<!-- :poster="item"  poster="@/assets/img/play.png"	 -->
														<video 	
															controls			    
														    preload="none" 
														    muted 
														    style="object-fit:contain;width:100%;height:100%;" 
														    controlslist="nodownload" 
														    oncontextmenu="return false"
														    :src="item.video"
														    :poster="item.thumb"
														    >
															<track kind="captions" />
															<!-- <source :src="item" type="video/mp4"> -->
														</video>
														<div style="position:absolute;left:0;top:0;z-index:99;width:100%;height:100%;background-color:rgba(0,0,0,0.1);display:flex;justify-content: center;align-items: center;">
															<i class="iconfont icon-delete" style="color:white;font-size:18px;" @click="del('video', index)"></i>
														</div>
													</div>
												</div>
												
												
											</div>
										</el-form-item>
										
										<el-form-item>
										    <el-button :disabled="submit_disabled" type="primary" class="el-button-1" @click="submitForm(ruleRef)">{{$t('form.submit_apply')}}</el-button>
										   
										</el-form-item>
									</el-form>
								</div>
							</div>
							<div class="settle-outer" v-if="showSuccess">
								<div class="tag-success">
									<img src="@/assets/img/grapher_settled/passing.png"/>
								</div>
								<div class="tag-success">
									<span class="text-1">{{$t('form.tip_text_1')}}</span>
								</div>
								<div class="tag-success second">
									<span class="text-2">{{$t('form.tip_text_2')}}</span>
								</div>
								<div class="tag-success second">
									<span class="text-2">{{$t('form.tip_text_3')}}</span>
								</div>
								
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer/>
	</div>
</template>

<script>
	import application from './application_controller';
	export default application;
</script>

<style src="@/assets/css/my/grapher/application.css" scoped>
	/*@import url("@/assets/css/style.css");全局引用css*/
	/*@import '@/assets/css/style.css';*/
</style>