// import Search from '@/components/Search.vue'
import { nextTick, onMounted, ref, provide, reactive, toRefs, getCurrentInstance } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore, createNamespacedHelpers } from "vuex";
import PageLoading from '@/components/common/PageLoading.vue';
import Swiper from '@/components/layout/Swiper.vue';
import SearchBarPic from '@/components/layout/SearchBarPic.vue';
import Header2 from '@/components/layout/header2.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';
import axios from 'axios';
import bus from 'vue3-eventbus'
import SideComponent from '@/components/common/SideComponent.vue';
import Loading from '@/components/common/Loading.vue';

export default {
	name: 'photo_grapher',
	components: {
		Header2,
		Menu,
	    Swiper,
	    SearchBarPic,
	    Footer,
	    PageLoading,
	    SideComponent,
	    Loading,
	},
	setup(){	    
	    // 写法一 用了reactive，就不需要写ref
	    let {ctx, proxy} = getCurrentInstance();
      	const router = useRouter();
      	const route = useRoute();
      	// console.log(route,"route");
      	provide('logoColor', 'white');
	    const data = reactive({
	    	isLoading:true,
	    	active_menuid: 0,
			activeClass: 'active',
			isMobile: false,
      		show_menu: false,
      		active_tagid: 'pic',
      		ispic: true,
      		banners:[
      			"img/topic/pic/topic_banner_1.jpg",
      			"img/banner/2.jpg",
      			"img/banner/3.jpg",
      		],
      		categorys:[
      			{
      				id: 0,
      				name: '全部',

      			},
      			{
      				id: 1,
      				name: '签约摄影师',

      			},
      			{
      				id: 2,
      				name: '特约摄影师',

      			},
      		],
      		photoGraphers: [],
      		pagination: {},
      		currentTag: 0,
      		catid: 0,
      		currentTagName: '全部',
      		page:1,
      		nomore:true,
      		keyword:'',
      		type:'tag',
      		isPicLoading:true,
	    });
        const store = useStore();
        data.page = 1;//localStorage.getItem( 'pageCurrent' ) ? localStorage.getItem( 'pageCurrent' ) : 1;
        data.catid = parseInt(route.query.catid)>0 ? route.query.catid : 0;
        const fetchGrapher = (keyword='',catid=0,tag=0,page=1,type='tag') =>{
        	console.log(page,"page");
	        store.dispatch("cameraGrapher/getCameraList",{
	        	page: page,
	            cids: catid,
	            tag: tag,
	            search: keyword,
	        }).then((res) => {
	        	// console.log(res,"photoGraphers");
	            if(res.data.length > 0){
	            	if(type=='tag' || page == 1){
	            		data.photoGraphers = res.data;
	            	}else if(type=='page'){
	            		data.photoGraphers = data.photoGraphers.concat(res.data);
	            	}
					
					if(res.data.length < res.pagination.pageSize){
						data.nomore = true;
					}else{
						data.nomore = false;
					}

				}else{
					data.nomore = true;
				}        
	        	data.pagination = res.pagination;
	        	data.page = res.pagination.current+1;
	        	data.isPicLoading = false;
	        	// localStorage.setItem( 'pageCurrent', res.pagination.current );
	        	bus.emit('showFooter',true);
	        }).catch(err=>{
	        	data.isPicLoading = false;
	        })

        }
        
        const viewBox = ref(null);
        const scroll = (val) => {
			if(val == 'top'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		       
			}else if(val == 'bottom'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
   
			}
		}

        onMounted(async()=>{
        	store.dispatch("common/fetchSiteInfo").then((res) => {
	              data.banners = res.camera_man_sliders 
	              bus.emit('isLoading',false);
	        }).catch(err => {
	              console.error(err)
	        })
        	fetchGrapher(data.keyword,data.catid,0,data.page);
        	await nextTick()
        	setTimeout(() => {
		        data.isLoading = false;
		        viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
			    
		    }, 600);

		    console.log("页面数据更新完毕了")
        })
	    

		const showMenus = ref(proxy.$menus);
		provide('showMenu', showMenus);

		const getImageUrl = (name) => {
		    return proxy.$utils.getImageUrl(name);
		};

		const handleSizeChange = (pageSize) => {
		  data.pagination.pageSize = pageSize;
		}
		const handleCurrentChange = (currentPage) => {
		  data.pagination.current = currentPage;
		  data.isPicLoading = true; 
		  fetchGrapher(data.keyword,data.catid,0,currentPage);
		}

        const refData = toRefs(data);
	    return {
	        ...refData,
	        getImageUrl,
	        handleSizeChange,
	        handleCurrentChange,
	        fetchGrapher,
	        viewBox,
	        scroll,
	    }
	},
	computed: {

	},
	watch(){

	},
	beforeCreate() {

	},
	created() {

	},
	beforeMount() {

	},
	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	},
	beforeUpdate() {

	},
	updated() {

	},
	beforeUnmount() {

	},
	unmounted() {

	},
	methods: {
		init() {

		},
		changeTag(item) {
			this.currentTag = item.id;
			this.type = 'tag';
			if(item.id == 0){
				this.currentTagName = '全部';
			}else if(item.id == 1){
				this.currentTagName = '签约';
			}else if(item.id == 2){
				this.currentTagName = '特约';
			}
			this.isPicLoading = true;
			this.fetchGrapher('',this.catid, item.id,1,this.type);
		},

		loadMore() {
			this.type = 'page';
			this.isPicLoading = true;
			this.fetchGrapher(this.keyword,this.catid, this.currentTag,this.page,this.type);
		},
	    toDetail(id){	    	
	      	// this.$router.push({ path:'/photo_grapher_detail', query:{ id: id } });
	      	let href = this.$router.resolve({ //使用resolve
		      name:'photo_grapher_detail',    //这里是跳转页面的name
		      path: '/photo_grapher_detail',
		      query:{ id: id },
		    })
		    window.open(href.href, '_blank')
	    },
	    clickTag(data){
	  		this.active_tagid = data.name;
	  		if(this.active_tagid == 'pic'){
	  			this.ispic = true;
	  			// this.placeholder = '搜图片';
	  		}else if(this.active_tagid == 'video'){
	  			this.ispic = false;
	  			// this.placeholder = '搜视频';
	  		}
	  	},
	    generateTagClass(data){
		    if(this.active_tagid == data.name){
		        return ['tabitem','active-tag'];
		    }
		    return ['tabitem'];
	    },
	 //    getImageUrl(name) {
		//     return new URL(`../assets/${name}`, import.meta.url).href;
		// },
	},
	beforeDestory() {

	},
	destoryed() {

	}
}