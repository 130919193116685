import instance from "@/utils/request";

/**
 * 以图搜图
 */
export function checkSimilarImage(param = {}) {
  return instance.request({
    method: "POST",
    url: "v1/upload/user/similar/image",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: param,
  });
}
/**
 * 获取手机验证码
 */
export function MobileCode(param = {}) {
  return instance.post("v1/auth/code", param);
}
/**
 * 手机验证码登录
 */
export function MobileCodeLogin(param = {}) {
  return instance.post("v1/auth/code/login", param);
}
/**
 * 验证身份证
 */
export function UploadIdcardFile(param = {}) {
  return instance.request({
    method: "POST",
    url: "v1/upload/user/idcard",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: param,
  });
}
/**
 * pv数据上传
 */
export function PostPV(param = {}) {
  return instance.post("v1/pv", param);
}
/**
 * 获取PV数据
 */
export function GetPV(param = {}) {
  return instance.get("v1/pv",{
    params: param
  });
  
}
/**
 * 注册
 */
export function register(param = {}) {
  return instance.post("v1/auth/register", param);
}
export function UploadBase64File(param = {}) {
  return instance.request({
    method: "POST",
    url: "v1/upload/base64",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: param,
  });
}
/**
 * 删除活动作品
 */
export function DeleteCollect(param = {}) {
  return instance.delete("v1/collect/"+param.id);
  
}
/**
 * 删除合辑
 */
export function DeleteFolder(param = {}) {
  return instance.delete("v1/collect/folder/"+param.id);
  
}
/**
 * 新增合辑
 */
export function EditFolder(param = {}) {
  return instance.put("v1/collect/folder/"+param.id,param.body);
  
}
/**
 * 新增合辑
 */
export function AddFolder(param = {}) {
  return instance.post("v1/collect/folder",param);
  
}
/**
 * 合辑详情
 */
export function FolderDetail(param = {}) {
  return instance.get("v1/collect/folder/"+param.id);
  
}
/**
 * 合辑列表
 */
export function FolderList(param = {}) {
  return instance.get("v1/collect/folder",{
    params: param
  });
  
}
/**
 * 用户上传征集作品列表
 */
export function CollectList(param = {}) {
  return instance.get("v1/collect/list",{
    params: param
  });
  
}
/**
 * 用户上传征集作品
 */
export function getOneCollects(param = {}) {
  return instance.get("v1/activity/"+param.actid+"/collect",{
    params: param.data
  });
  
}
/**
 * 用户上传征集作品更新
 */
export function getCollectUpdate(param = {}) {
  return instance.put("v1/collect/"+param.id, param.body);
  
}
/**
 * 用户上传征集作品
 */
export function PostCollect(param = {}) {
  return instance.post("v1/collect", param,
      {
        // headers: {'content-type': 'application/x-www-form-urlencoded'}
      }
    );
  
}

/**
 * 用户上传征集作品详情
 */
export function getCollectDetail(param = {}) {
  return instance.get("v1/collect/"+param.id);
  
}
/**
 * 征集活动列表
 */
export function ActivityList(param = {}) {
  return instance.get("v1/activity/list", {
    params: param
  });
}
/**
 * 征集活动详情
 */
export function ActivityDetail(param = {}) {
  return instance.get("v1/activity/"+param.id);

}

/**
 * 可选套餐包
 */
export function ImagePackages(param = {}) {
  return instance.get("v1/image/packages", {
    params: param
  });
}

/**
 * 摄影师作品删除
 */
export function CameraWorkDelete(param = {}) {
  return instance.post("v1/camera/work/delete", param);
  
}
/**
 * 摄影师作品下架
 */
export function CameraWorkOffsale(param = {}) {
  return instance.post("v1/camera/work/offsale", param);
  
}
/**
 * 结算信息
 */
export function CameraRechargeInfo(param = {}) {
  return instance.get("v1/camera/recharge/info", {
    params: param
  });
}

/**
 * 申请结算
 */
export function CameraRecharge(param = {}) {
  return instance.post("v1/camera/recharge", param);
  
}
/**
 * 结算记录
 */
export function CameraRechargeLog(param = {}) {
  return instance.get("v1/camera/recharge/log", {
    params: param
  });
}
/**
 * 结算订单
 */
export function CameraRechargeOrder(param = {}) {
  return instance.get("v1/camera/recharge/order", {
    params: param
  });
}

/**
 * 用户专题列表
 */
export function UserSubjectList(param = {}) {
  return instance.get("v1/camera/subject_list", {
    params: param
  });
}
/**
 * 更新作品详情
 */
export function CameraWorkUpdate(param = {}) {
  return instance.put("v1/camera/work/resource/"+param.id, param.body);
}
/**
 * 上传作品详情
 */
export function CameraWorkDetail(param = {}) {
  return instance.get("v1/camera/work/"+param.id);
}
/**
 * 上传作品列表
 */
export function CameraWorkList(param = {}) {
  return instance.get("v1/camera/work/list", {
    params: param
  });
}
/**
 * 供稿合同签约
 */
export function AddSubject(param = {}) {
  return instance.post("v1/camera/subject", param);
}
/**
 * 上传视频作品
 */
export function PostVideoData(param = []) {
  return instance.post("v1/camera/work/video", param);
}
/**
 * 上传图片作品
 */
export function PostImgdata(param = []) {
  return instance.post("v1/camera/work/image", param);
}
/**
 * 供稿合同签约
 */
export function UploadCameraEsign(param = {}) {
  return instance.post("v1/camera/sign", param);
}
/**
 * 获取合同信息
 */
export function GetApplyContract(param = {}) {
  return instance.get("v1/camera/contract", {
    params: param
  });
}
/**
 * 供稿合同签约
 */
export function PostApplyContract(param = {}) {
  return instance.post("v1/camera/contract", param);
}
/**
 * 历史合同表单
 */
export function ApplyInfo(param = {}) {
  return instance.get("v1/camera/apply/info", {
    params: param
  });
}
/**
 * 摄影师入驻数据提交
 */
export function GrapherWorkerForm(param = {}) {
  // return instance.post("v1/camera/apply", param);
  return instance.request({
    method: "POST",
    url: "v1/camera/apply",
    // headers: {
    //   "Content-Type": "multipart/form-data",
    // },
    data: param,
  });
}

/**
 * 摄影师作品上传 (上传图片需要带上 headers: {"Content-Type": "multipart/form-data",})
 */

export function GrapherWorkerUpload(param = {}) {
  return instance.request({
    method: "POST",
    url: "v1/upload/user/file",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: param,
  });
}

/**
 * 提交合同资料，生成合同模板
 */
export function SubmitContract(param = {}) {
  return instance.post("v1/contract/form", param);
}
/**
 * 历史合同表单
 */
export function ContractFormData(param = {}) {
  return instance.get("v1/contract/form", {
    params: param
  });
}
/**
 * 合同详情
 */
export function ContractDetail(param = {}) {
  return instance.get("v1/contract/detail", {
    params: param
  });
}
/**
 * 上传合同签名
 */
export function UploadEsign(param = {}) {
  return instance.post("v1/contract/sign", param);
}
/**
 * 下载合同
 */
export function ContractDownload(param = {}) {
  return "/api/v1/contract/download/"+param.order_id;
  // return instance.get("v1/contract/download/"+param.order_id);
}
/**
 * 获取微信登录二维码
 */
export function loginQrcode(param = {}) {
  return instance.get("v1/auth/qrcode", {
    params: param
  });
}

/**
 * 获取微信登录状态
 */
export function QrcodeTicket(param = {}) {
  return instance.post("v1/auth/ticket", param);
}
/**
 * 登录
 */
export function login(param = {}) {
  return instance.request({
    method: "POST",
    url: "v1/auth/login",
    headers: {
      // "Content-Type": "multipart/form-data",
    },
    data: param,
  });
  // return instance.post("/v1/auth/login",param);
}

/**
 * 刷新token
 */
export function refreshToken(param = {}) {
  return instance.get("v1/auth/refresh", {
    params: param
  });
  
}

/**
 * 获取个人信息
 */
export function PersonInfo(param = {}) {
  return instance.get("v1/auth/me", {
    params: param
  });
  
}

/**
 * 添加购物车
 */
export function addCart(param = {}) {
  return instance.request({
    method: "POST",
    url: "v1/cart/add",
    headers: {
      // "Content-Type": "multipart/form-data",
    },
    data: param,
  });
}
/**
 * 修改密码
 */
export function modifyPassword(param = {}) {
  return instance.post("v1/auth/update", param);
}
/**
 * 预生成订单
 */
export function PreCreate(param = {}) {
  return instance.post("v1/order/preCreate", param);
}
// export function CreateOrder(param = {}) {
//   return instance.request({
//     method: "POST",
//     url: "v1/order/create",
//     headers: {
//       // "Content-Type": "multipart/form-data",
//     },
//     data: param,
//   });
// }
/**
 * 订单列表
 */
export function OrderList(param = {}) {
  return instance.get("v1/order/list", {
    params: param
  });
}
/**
 * 购物车列表
 */
export function CartList(param = {}) {
  return instance.get("v1/cart/list", {
    params: param
  });
}
/**
 * 移出购物车
 */
export function deleteCartGoods(param = {}) {
  return instance.delete("v1/cart/"+param.id);
}
/**
 * 清空购物车
 */
export function BatchDeleteCartGoods(param = {}) {
  return instance.post("v1/cart/delete", param);
}
/**
 * 清空购物车
 */
export function clearCartGoods(param = {}) {
  return instance.get("v1/cart/clear");
}
/**
 * 购物车列表
 */
export function AuthList(param = {}) {
  return instance.get("v1/authrozie/list", {
    params: param
  });
}
/**
 * 支付
 */
export function Pay(param = {}) {
  return instance.request({
    method: "POST",
    url: "v1/order/pay",
    headers: {
      // "Content-Type": "multipart/form-data",
    },
    data: param,
  });
}
/**
 * 我的钱包
 */
export function Wallet(param = {}) {
  return instance.get("v1/wallet/info", {
    params: param
  });
}
/**
 * 余额记录
 */
export function BalanceRecords(param = {}) {
  return instance.get("v1/wallet/record", {
    params: param
  });
}

/**
 * 充值订单详情
 */
export function BalanceRecordInfo(param = {}) {
  return instance.get("v1/wallet/record/"+param.id);
}
/**
 * 充值订单
 */
export function RechargeOrder(param = {}) {
  return instance.post("v1/wallet/charge", param);
}

/**
 * 充值订单支付
 */
export function ChargePay(param = {}) {
  return instance.post("v1/wallet/chargePay", param);
}




