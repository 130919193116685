<template>
<SideComponent @scrollto="scroll"></SideComponent>
	<div class="container" ref="viewBox">
		<Header2 :show-search-bar-pic="showHeader" login-style="loginStyle" :mobile-icon-style="mobileIconStyle" :show-list-icon="showListIcon"/>
		<div class="content">
			<div style="height:80px;"></div>
			<div class="enclosure">
				<div class="inner">
					<div class="left">
						<SideMenu />
					</div>
					<div class="right">
						
						<div class="tag-outer">
							<el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
								<el-tab-pane :label="$t('apply_work.tip0')" name="first">
									<div class="permission" v-if="showImgUpload && !hasImagePermissions">
										<div class="tag-item">
											<img src="@/assets/img/grapher_settled/wqx.png"/>
										</div>
										<div class="tag-item">
											<span class="text-1">{{$t('apply_work.tip1')}}</span>
										</div>
										<div class="tag-item">
											<span class="text-2">{{$t('apply_work.tip2')}}</span>
										</div>
										<div class="tag-item">
											<div class="settled-btn" @click="toApplication()">{{$t('apply_work.tip3')}}</div>
										</div>
									</div>
							    	<div class="upload-content" v-if="showImgUpload && hasImagePermissions">
							    		<div class="union_input">{{$t('apply_common.batch_pics')}}</div>
							    		<el-form ref="picOneRef" :model="picOneForm" label-width="120px" :size="formSize" style="width:90%;" label-position="top">
							    			
											<el-form-item prop="subject_id" :label="$t('apply_work.tip11')">
											    <el-select
												    v-model="picOneForm.subject_id"
												    filterable="true"
												    :placeholder="$t('apply_work.tip12')"
												    style="width: 240px"
												    :size="formSize"
												    @change="getSubjectData($event)"
												  >
												    <template #header>
												      <div class="add-topic" @click="addTopic()">{{$t('apply_work.tip13')}}</div>
												      <!-- <div style="width:100%;border-top:1px solid rgb(230,230,230);height:5px;"></div> -->
												      <el-input style="margin-top:10px;" v-model="keywords" autocomplete="off" :placeholder="$t('apply_common.search_subject')" ></el-input>
												      <div class="act-search" @click="UserSubjectList()">{{$t('search.search')}}</div>
												    </template>
												    
												    <el-option
												      v-for="topic in subjectList"
												      :key="topic.id"
												      :label="topic.title"
												      :value="topic.id"
												    />

												    <template #footer>
												      <el-button text bg size="small" @click="loadMore()">
												        {{$t('apply_work.more')}}
												      </el-button>
												      
												    </template>
												  </el-select>
											</el-form-item>
											<el-form-item prop="description" :label="$t('apply_work.tip7')">
											    <el-input type="textarea" :rows="3" v-model="picOneForm.description" :size="formSize" autocomplete="off" :placeholder="$t('apply_work.tip8')" ></el-input>
											</el-form-item>
											<el-form-item prop="keywords" :label="$t('apply_work.tip9')">
											    <!-- <el-input v-model="picOneForm.keywords" :size="formSize" autocomplete="off" :placeholder="$t('apply_work.tip10')" ></el-input> -->
											    <label for="pic_keywords" class="keyword-label">
											    	<span v-for="(tag,index) in picOneForm.keywords" class="keyword-tag">{{tag}}<i class="iconfont icon-chahao4 chahao" @click.stop="delTag(index,'image')"></i></span>
											    	<input class="input" type="text" v-model="pic_keyword" id="pic_keywords" autocomplete="off" :placeholder="$t('apply_work.tip10')" @keyup.enter="handleKeyword('image')" @blur="handleKeyword('image')"/>
											    </label>
											    <div style="display:flex;padding:5px 0;">
											    	<span style="color:red;font-size:14px;margin-right:5px;">*</span>
											    	<span style="color:#aab2bd;">{{$t('apply_common.enter_keyword_tip')}}</span>
											    </div>
											</el-form-item>
											<el-form-item prop="package_id" :label="$t('apply_work.price_pack')">
											    <el-select
												    v-model="picOneForm.package_id"
												    filterable="true"
												    :placeholder="$t('apply_work.select_price_pack')"
												    style="width: 240px"
												    :size="formSize"
												  >
												    <!-- <template #header>
												        <el-input v-model="search" autocomplete="off" placeholder="价格套餐包搜索" ></el-input>
									      				<div class="act-search" @click="searchAct()">搜索</div>
												    </template> -->
												    
												    <el-option
												      v-for="pack in ImagePackages"
												      :key="pack.id"
												      :label="pack.title"
												      :value="pack.id"
												    />

												    <!-- <template #footer>
												      <el-button text bg size="small" @click="loadMore()">
												        {{$t('apply_work.more')}}
												      </el-button>
												      
												    </template> -->
												  </el-select>
											</el-form-item>
											<el-form-item prop="year" :label="$t('apply_work.year')">
											    <el-input type="number" v-model="picOneForm.year" :size="formSize" autocomplete="off" :placeholder="$t('apply_work.yearTip')" ></el-input>
											    <span style="color:#aab2bd;"><span style="color:red;margin-right:5px;">*</span>{{$t('apply_work.year_limit')}}</span>
											</el-form-item>
							    		</el-form>
										<label for="image_camera_upload" @drop="handleCameraDrop" @dragover="handleCameraDragOver" class="upload-label">
											<input type="file" id="image_camera_upload" multiple @change="uploadCameraFile($event,'image')" accept="image/*" style="display:none;" />
											
										    <i class="iconfont icon-shangchuan5 upicon"></i>
										    <p class="upload-tiptext">{{$t('apply_work.tip4')}}</p>
										    <p class="upload-tiptext">{{$t('apply_work.pic_limit')}}</p>
										</label>
										<Loading :is-loading="isPicLoading"></Loading>
									</div>
									<div class="pic-form" v-if="showPicForm">   <!--  -->
										<el-form ref="ruleRef" :model="ruleForm" :rules="_rules" label-width="200px" :size="formSize" style="width:90%;" label-position="top">
											<div v-for="(item,index) in ruleForm.tableData" class="list-item">
												<div class="list-item-content">
													<div class="list-item-img">	
														<label :for="'image_single_upload_'+index" class="list-item-img-outer" >
															<Loading :is-loading="isImgSingleLoading"></Loading>
															<input type="file" :id="'image_single_upload_'+index" @change="uploadSingleFile($event,index,'image')" accept="image/*" style="display:none;" />
															<img v-if="!item.thumb" src="@/assets/img/default.png" />
															<img v-if="item.thumb" style="width:100%;height:100%;object-fit:contain;" :src="item.thumb"/>
															<span class="repeat" v-if="item.hasSimilar">{{$t('apply_work.similar')}}</span>
															
														</label>
													</div>
													<div class="list-item-form">
														<el-form-item :prop="'tableData.' + index + '.title'" :rules="_rules.title" :label="$t('apply_work.tip5')">
														    <el-input v-model="item.title" :size="formSize" autocomplete="off" :placeholder="$t('apply_work.tip6')" ></el-input>
														</el-form-item>
														
														<el-form-item :prop="'tableData.' + index + '.description'" :label="$t('apply_work.tip7')">
														    <el-input type="textarea" :rows="3" v-model="item.description" :size="formSize" autocomplete="off" :placeholder="$t('apply_work.tip8')" ></el-input>
														</el-form-item>
														<el-form-item  :prop="'tableData.' + index + '.keywords'" :label="$t('apply_work.tip9')">
														    <!-- <el-input v-model="item.keywords" :size="formSize" autocomplete="off" :placeholder="$t('apply_work.tip10')" ></el-input> -->
														    <label :for="'pic_keywords_'+index" class="keyword-label">
														    	<span v-for="(tag,idx) in item.keywords" class="keyword-tag">{{tag}}<i class="iconfont icon-chahao4 chahao" @click.stop="delTag(idx,'image',index)"></i></span>
														    	<input class="input" type="text" v-model="item.pic_keyword" :id="'pic_keywords_'+index" autocomplete="off" :placeholder="$t('apply_work.tip10')" @keyup.enter="handleKeyword('image',index)" @blur="handleKeyword('image',index)"/>
														    </label>
														    <div style="display:flex;padding:5px 0;">
														    	<span style="color:red;font-size:14px;margin-right:5px;">*</span>
														    	<span style="color:#aab2bd;">{{$t('apply_common.enter_keyword_tip')}}</span>
														    </div>
														</el-form-item>
														<el-form-item :prop="'tableData.' + index + '.subject_id'" :rules="_rules.subject_id" :label="$t('apply_work.tip11')">
														    <el-select
															    v-model="item.subject_id"
															    filterable="true"
															    :placeholder="$t('apply_work.tip12')"
															    style="width: 240px"
															     :size="formSize"
															  >
															    <template #header>
															      <div class="add-topic" @click="addTopic()">{{$t('apply_work.tip13')}}</div>
															      <!-- <div style="width:100%;border-top:1px solid rgb(230,230,230);height:5px;"></div> -->
															      <el-input style="margin-top:10px;" v-model="keywords" autocomplete="off" :placeholder="$t('apply_common.search_subject')" ></el-input>
															      <div class="act-search" @click="UserSubjectList()">{{$t('search.search')}}</div>
															    </template>
															    
															    <el-option
															      v-for="topic in subjectList"
															      :key="topic.id"
															      :label="topic.title"
															      :value="topic.id"
															    />

															    <template #footer>
															      <el-button text bg size="small" @click="loadMore()">
															        {{$t('apply_work.more')}}
															      </el-button>
															      
															    </template>
															  </el-select>
														</el-form-item>
														<el-form-item :prop="'tableData.' + index + '.package_id'" :rules="_rules.package_id" :label="$t('apply_work.price_pack')">
														    <el-select
															    v-model="item.package_id"
															    filterable="true"
															    :placeholder="$t('apply_work.select_price_pack')"
															    style="width: 240px"
															    :size="formSize"
															  >
															    <!-- <template #header>
															        <el-input v-model="search" autocomplete="off" placeholder="价格套餐包搜索" ></el-input>
												      				<div class="act-search" @click="searchAct()">搜索</div>
															    </template> -->
															    
															    <el-option
															      v-for="pack in ImagePackages"
															      :key="pack.id"
															      :label="pack.title"
															      :value="pack.id"
															    />

															    <!-- <template #footer>
															      <el-button text bg size="small" @click="loadMore()">
															        {{$t('apply_work.more')}}
															      </el-button>
															      
															    </template> -->
															  </el-select>
														</el-form-item>
														<el-form-item :prop="'tableData.' + index + '.year'" :label="$t('apply_work.year')">
														    <el-input type="number" v-model="item.year" :size="formSize" autocomplete="off" :placeholder="$t('apply_work.yearTip')" ></el-input>
														</el-form-item>

													</div>
												</div>
												<div class="list-item-operate">
													<span class="item-del" @click="delItem(index)" v-if="id==0"><i class="iconfont icon-delete icon-dell"></i>{{$t('apply_common.del')}}</span>
												</div>
											</div>									
											<el-form-item style="padding:20px;display:flex;justify-content: center;">
											    <el-button type="primary" class="el-button-1" @click="submitForm(ruleRef)" :disabled="pic_disabled">{{$t('apply_work.tip25')}}</el-button>	
											    <!-- <el-button v-if="id==0" type="primary" class="el-button-1" @click="addItem()">{{$t('apply_common.addimg')}}</el-button>   -->
											    <label for="again_image_upload" class="upload-button">
													<input type="file" id="again_image_upload" multiple @change="uploadCameraFile($event,'image',true)" accept="image/*" style="display:none;" />
													
													<p class="upload-tiptext color-white">{{$t('form.againUploadPic')}}</p>
												    
												</label> 
											</el-form-item>
										</el-form>
									</div>
							    </el-tab-pane>
							    <el-tab-pane :label="$t('apply_work.tip26')" name="second">
							    	<div class="permission" v-if="showVideoUpload && !hasVideoPermissions">
										<div class="tag-item">
											<img src="@/assets/img/grapher_settled/wqx.png"/>
										</div>
										<div class="tag-item">
											<span class="text-1">{{$t('apply_work.tip27')}}</span>
										</div>
										<div class="tag-item">
											<span class="text-2">{{$t('apply_work.tip28')}}</span>
										</div>
										<div class="tag-item">
											<div class="settled-btn" @click="toApplication()">{{$t('apply_work.tip3')}}</div>
										</div>
									</div>
							    	<div class="upload-content" v-if="showVideoUpload && hasVideoPermissions">
							    		<div class="union_input">{{$t('apply_common.batch_videos')}}</div>
							    		<el-form ref="videoOneRef" :model="videoOneForm" label-width="120px" :size="formSize" style="width:90%;" label-position="top">
							    			<el-form-item prop="description" :label="$t('apply_work.tip32')">
											    <el-input :rows="3" type="textarea" v-model="videoOneForm.description" :size="formSize" autocomplete="off" :placeholder="$t('apply_work.tip33')" ></el-input>
											</el-form-item>
											<el-form-item prop="keywords" :label="$t('apply_work.tip9')">
											    <!-- <el-input v-model="videoOneForm.keywords" :size="formSize" autocomplete="off" :placeholder="$t('apply_work.tip10')" ></el-input> -->
											    <label for="video_keywords_x" class="keyword-label">
											    	<span v-for="(tag,index) in videoOneForm.keywords" class="keyword-tag">{{tag}}<i class="iconfont icon-chahao4 chahao" @click.stop="delTag(index,'video')"></i></span>
											    	<input class="input" type="text" v-model="video_keyword" id="video_keywords_x" autocomplete="off" :placeholder="$t('apply_work.tip10')" @keyup.enter="handleKeyword('video')" @blur="handleKeyword('video')"/>
											    </label>
											    <!-- 防止按回车键时刷新页面 -->
											    <label for="v_keywords" class="keyword-label" style="display:none;">
											    	<span v-for="(tag,index) in videoOneForm.keywords" class="keyword-tag">{{tag}}<i class="iconfont icon-chahao4 chahao" @click.stop="delTag(index,'image')"></i></span>
											    	<input class="input" type="text" v-model="video_keyword" id="v_keywords" autocomplete="off" :placeholder="$t('apply_work.tip10')" @keyup.enter="handleKeyword('video')" @blur="handleKeyword('video')"/>
											    </label>
											    <div style="display:flex;padding:5px 0;">
											    	<span style="color:red;font-size:14px;margin-right:5px;">*</span>
											    	<span style="color:#aab2bd;">{{$t('apply_common.enter_keyword_tip')}}</span>
											    </div>
											</el-form-item>

							    		</el-form>
										<label for="video_camera_upload" @drop="handleCameraDrop" @dragover="handleCameraDragOver" class="upload-label">
											<input type="file" id="video_camera_upload" multiple @change="uploadCameraFile($event,'video')" accept="video/*" style="display:none;" />
											
										    <i class="iconfont icon-shangchuan5 upicon"></i>
										    <p class="upload-tiptext">{{$t('apply_work.tip29')}}</p>
										    <p class="upload-tiptext">{{$t('apply_work.video_limit')}}</p>
										</label>
										<Loading :is-loading="isVideoLoading"></Loading>
									</div>
									<div class="pic-form" v-if="showVideoForm">  <!--  -->

										<el-form ref="ruleVideoRef" :model="ruleVideoForm" :rules="video_rules" label-width="200px" :size="formSize" style="width:90%;" label-position="top">

											<div v-for="(item,index) in ruleVideoForm.tableData" class="list-item">
												<div class="list-item-content">
													<div class="list-item-img">
														
														<label :for="'video_single_upload_'+index" class="list-item-img-outer">
															<Loading :is-loading="isVideoSingleLoading"></Loading>
															<input type="file" :id="'video_single_upload_'+index" @change="uploadSingleFile($event,index,'video')" accept="video/*" style="display:none;" />
															<img v-if="!item.url" src="@/assets/img/default.png" />
															<video v-if="item.url" id="video+'0'"
																controls																			    
															    preload="none" 
															    muted 
															    style="object-fit:contain;width:100%;height:100%;" 
															    controlslist="nodownload" 
															    oncontextmenu="return false"
															    :src="item.url"
															    :poster="item.thumb"
															    >
																<track kind="captions" />
																<!--:poster="item.thumb" <source :src="item" type="video/mp4"> -->
															</video>
															<!-- <div style="position:absolute;left:0;top:0;z-index:99;width:100%;height:100%;background-color:rgba(0,0,0,0.1);display:flex;justify-content: center;align-items: center;">
																<i class="iconfont icon-delete" style="color:white;font-size:18px;" @click="del('video', index)"></i>
															</div> -->
														</label>
													</div>
													<div class="list-item-form">
														<el-form-item :prop="'tableData.' + index + '.title'" :rules="_rules.title" :label="$t('apply_work.tip30')">
														    <el-input v-model="item.title" :size="formSize" autocomplete="off" :placeholder="$t('apply_work.tip31')" ></el-input>
														</el-form-item>
														
														<el-form-item :prop="'tableData.' + index + '.description'" :label="$t('apply_work.tip32')">
														    <el-input type="textarea" :rows="3" v-model="item.description" :size="formSize" autocomplete="off" :placeholder="$t('apply_work.tip33')" ></el-input>
														</el-form-item>
														<el-form-item  :prop="'tableData.' + index + '.keywords'" :label="$t('apply_work.tip9')">
														    <!-- <el-input v-model="item.keywords" :size="formSize" autocomplete="off" :placeholder="$t('apply_work.tip10')" ></el-input> -->
														    <label :for="'video_keywords_'+index" class="keyword-label">
														    	<span v-for="(tag,idx) in item.keywords" class="keyword-tag">{{tag}}<i class="iconfont icon-chahao4 chahao" @click.stop="delTag(idx,'video',index)"></i></span>
														    	<input class="input" type="text" v-model="item.video_keyword" :id="'video_keywords_'+index" autocomplete="off" :placeholder="$t('apply_work.tip10')" @keydown.enter.native="handleKeyword('video',index)" @blur="handleKeyword('video',index)"/>
														    </label>
														    <div style="display:flex;padding:5px 0;">
														    	<span style="color:red;font-size:14px;margin-right:5px;">*</span>
														    	<span style="color:#aab2bd;">{{$t('apply_common.enter_keyword_tip')}}</span>
														    </div>
														</el-form-item>
														
													</div>
												</div>
												<div class="list-item-operate">
													<span class="item-del" @click="delItem(index)" v-if="id==0"><i class="iconfont icon-delete icon-dell"></i>{{$t('apply_common.del')}}</span>
												</div>
											</div>									
											<el-form-item style="padding:20px;display:flex;justify-content: center;">
											    <el-button type="primary" class="el-button-1" @click="submitVideoForm(ruleVideoRef)" :disabled="video_disabled">{{$t('apply_work.tip25')}}</el-button>	  
											    <!-- <el-button v-if="id==0" type="primary" class="el-button-1" @click="addItem()">{{$t('apply_common.addvideo')}}</el-button>  --> 
											    <label for="again_video_upload" class="upload-button">
													<input type="file" id="again_video_upload" multiple @change="uploadCameraFile($event,'video',true)" accept="video/*" style="display:none;" />
													
													<p class="upload-tiptext color-white">{{$t('form.againUploadVideo')}}</p>
												    
												</label> 
											</el-form-item>
										</el-form>
									</div>
							    </el-tab-pane>

							</el-tabs>
							<div class="subject-wrapper" v-if="showSubject">
								<div class="head">
									<div class="head-item">
										<span>{{$t('apply_work.tip13')}}</span>
									</div>
									<div class="head-item">
										<span @click="close()"><i class="iconfont icon-fork"></i></span>
									</div>
								</div>
								<div class="body">
									<el-form ref="subjectRef" :model="subjectForm" :rules="subject_rules" label-width="200px" :size="formSize" style="width:90%;">
										<el-form-item prop="title" :rules="_rules.title" :label="$t('apply_work.tip34')">
										    <el-input v-model="subjectForm.title" :size="formSize" autocomplete="off" :placeholder="$t('apply_work.tip35')" ></el-input>
										</el-form-item>										
										<el-form-item prop="subject_cate" :label="$t('apply_work.tip36')">
										    <el-select
											    v-model="subjectForm.subject_cate"
											    filterable="true"
											    :placeholder="$t('apply_work.tip37')"
											    style="width:100%;"
											    >
											    <div v-for="cat in allCats">
												    <el-option												      
												      :key="cat.id"
												      :label="cat.title"
												      :value="cat.id"
												    >	
												    </el-option>
												    <el-option v-if="cat.children.length>0"
												      v-for="child in cat.children"
												      :key="child.id"
												      :label="'|---'+child.title"
												      :value="child.id"
												    >
											    	</el-option>
												</div>
											  </el-select>
										</el-form-item>
										<el-form-item prop="thumb" :label="$t('apply_work.tip38')" style="display:flex;">
											<div class="images-item btn-pic common">
												<label for="thumb_upload" class="upload-btn">
													<input type="file" id="thumb_upload" @change="uploadThumb($event)" accept="image/*" style="display:none;" />
													
													<p class="thumb-btn">{{$t('form.uploadPic')}}</p>
												    
												</label>
												<span style="margin-left:20px;color:rgb(230,0,15);">{{$t('apply_work.coverTip')}}</span>
											</div>
											<div class="images-item common">
												<div class="img-outer">													
													<div class="img-wrapper">
														<img v-if="!subjectForm.thumb" src="@/assets/img/default.png" />
														<img v-if="subjectForm.thumb" :src="subjectForm.thumb" />
													</div>
												</div>
												
											</div>
										</el-form-item>
										<el-form-item prop="year" :rules="_rules.year" :label="$t('apply_work.year')">
										    <el-input v-model="subjectForm.year" :size="formSize" autocomplete="off" :placeholder="$t('apply_work.yearTip')" ></el-input>
										</el-form-item>	
										<el-form-item prop="package_id" :label="$t('apply_work.topic_price_pack')">
										    <el-select
											    v-model="subjectForm.package_id"
											    filterable="true"
											    :placeholder="$t('apply_work.select_price_pack')"
											    style="width: 240px"
											    :size="formSize"
											  >
											    <!-- <template #header>
											        <el-input v-model="search" autocomplete="off" placeholder="价格套餐包搜索" ></el-input>
								      				<div class="act-search" @click="searchAct()">搜索</div>
											    </template> -->
											    
											    <el-option
											      v-for="pack in ImagePackages"
											      :key="pack.id"
											      :label="pack.title"
											      :value="pack.id"
											    />

											    <!-- <template #footer>
											      <el-button text bg size="small" @click="loadMore()">
											        {{$t('apply_work.more')}}
											      </el-button>
											      
											    </template> -->
											  </el-select>
										</el-form-item>
										<el-form-item prop="desc" :label="$t('apply_work.tip39')">
										    <el-input :rows="8"
	    											  type="textarea" 
	    											  v-model="subjectForm.desc" 
	    											  autocomplete="off" 
	    											  :placeholder="$t('apply_work.tip40')">
	    											  	
	    									</el-input>

										</el-form-item>
										<el-form-item style="padding:20px;display:flex;justify-content: center;">
										    <el-button type="primary" class="el-button-1" :disabled="disabled" @click="submitSubjectForm(subjectRef)">{{$t('apply_work.tip41')}}</el-button>	   
										</el-form-item>
									</el-form>
									
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer/>
	</div>
</template>

<script>
	import apply_work from './apply_work_controller';
	export default apply_work;
</script>

<style src="@/assets/css/my/grapher/apply_work.css" scoped></style>