import { onMounted,nextTick, defineComponent, ref, provide, reactive, toRefs, getCurrentInstance } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import Swiper from '@/components/layout/Swiper.vue';
import SearchBar2 from '@/components/layout/SearchBar2.vue';
import Header2 from '@/components/layout/header2.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';
import SideMenu from '@/components/layout/SideMenu.vue';
import bus from 'vue3-eventbus'
import SideComponent from '@/components/common/SideComponent.vue';
import Loading from '@/components/common/Loading.vue';
import axios from "axios";
import vueEsign from 'vue-esign';

export default defineComponent({
	components: {
		Header2,
		Menu,
	    Swiper,
	    SearchBar2,
	    Footer,
	    SideMenu,
	    SideComponent,
	    Loading,
	    vueEsign,
	},
	props: {

	},
	setup() {
		let {ctx, proxy} = getCurrentInstance();
		const router = useRouter();
	    const route = useRoute();
		const data = reactive({
			showHeader: true,
			loginStyle: "min-width:141px;",
			mobileIconStyle: "color:rgba(223, 58, 8, 1);",
			showListIcon: true,
			activeName:'first',
			active: 1,
			isSettleLoading: true,
			settle:'',
			
			picFiles:[],
			idcard_thumb: '',
			bankcard_thumb: '',
			isIdcardLoading: false,
			isBankLoading: false,
			iframeUrl:'',
			showInfo: true,
			showIframe: false,
			showEsign: false,
			showPdf: false,
			isPdfLoading: false,

			lineWidth: 6,
		    lineColor: '#000000',
		    bgColor: 'rgba(219,219,219,0)',
		    isCrop: false,
		    resultImg: '',
		    toApplyInfo: false,
		    isEsign: false,
		    idcardData: {},
		    isPreviewLoading: false,
		    isSignLoading: false,
		    previewDisabled: false,
		    signDisabled: false,
		});
		const showMenu = ref(proxy.$menus);
		provide('showMenu', showMenu);

		const outerStyleStr = ref("position:relative;left:10%;top:40%;transform:translate(-10%,-40%);width:auto;");
		provide('outerStyleStr', outerStyleStr);

		const styleStr = ref("height:45px;");
		provide('styleStr', styleStr);

		const showSearchText = ref(false);
		provide('showSearchText', showSearchText);

		const getImageUrl = (name) => {
		    return proxy.$utils.getImageUrl(name);
		};

		// bus.emit('needRefresh', true)

		const store = useStore();	

		const getSiteInfo = () => {
		    return new Promise((resolve, reject) => {
		    	store.dispatch("common/fetchSiteInfo").then((res) => {
			    data.settle = res.settle;
			    data.isSettleLoading = false;
		        resolve();
		        // bus.emit('isLoading',false);
		      }).catch(err => {
		        reject();
		      })
			  
		    })
	    
	    }
	    const nextStep = (showInfo, showPdf, showEsign) => {
	        if (data.active++ > 3) data.active = 1;
	        data.showInfo = showInfo;
  			data.showPdf = showPdf;
  			data.showEsign = showEsign;
	    }

	    const toSubmit = () => {
	    	nextStep(false, true, false);
	    }
	    const validate_mobile_rules = (rule, value, callback) => {
			if (value == "") return true;
			let email = proxy.$utils.isPhone(value);
		    if (!email) {
		    	let tip = proxy.$t('enterCorrectPhoneNumber');
		        callback(new Error(tip));
		    } else {
		        callback();
		    }
		    

		}
	    const validate_email_rules = (rule, value, callback) => {
			if (value == "") return true;
			let email = proxy.$utils.regEmail(value);
		    if (!email) {
		    	let tip = proxy.$t('enterCorrectEmail');
		        callback(new Error(tip));
		    } else {
		        callback();
		    }
		    

		}

		const validate_realname_rules = (rule, value, callback) => {
			if (value == "") return true;
			let company = proxy.$utils.regCompany(value);
		    if (!company) {
		    	let tip = proxy.$t('enterRealname');
		        callback(new Error(tip));
		    } else {
		        callback();
		    }

		}
		const validate_company_rules = (rule, value, callback) => {
			if (value == "") return true;
			let company = proxy.$utils.regCompany(value);
		    if (!company) {
		    	let tip = proxy.$t('enterCorrectUnitName');
		        callback(new Error(tip));
		    } else {
		        callback();
		    }

		}
		const validate_idcard_rules = (rule, value, callback) => {
			if (value == "") return true;
			let company = proxy.$utils.regIDC(value);
		    if (!company) {
		    	let tip = proxy.$t('enterCorrectIdcard');
		        callback(new Error(tip));
		    } else {
		        callback();
		    }

		}
		const validate_social_number_rules = (rule, value, callback) => {
			if (value == "") return true;
			let company = proxy.$utils.validateSocialCardNumber(value);
		    if (!company) {
		    	let tip = proxy.$t('enterCorrectInstitutionName');
		        callback(new Error(tip));
		    } else {
		        callback();
		    }

		}

		const validate_bankcard_number_rules = (rule, value, callback) => {
			if (value == "") return true;
			let company = proxy.$utils.isValidBankCardNumber(value);
		    if (!company) {
		    	let tip = proxy.$t('form.enterCorrectbankcardNumber');
		        callback(new Error(tip));
		    } else {
		        callback();
		    }

		}

	    const ruleRef = ref()
		const ruleUser = reactive({
			realname:'',
		    idcard:'',			
  			idcard_thumb:'',
  			bank_card: '',
  			bank_card_thumb:'',
  			
  			
		})

		const _rules = reactive({
			realname: [
				{ required: true, message: proxy.$t('form.enterRealname'), trigger: 'blur' },
				{ validator: validate_realname_rules, trigger: "blur" },
			],
			
			idcard: [
				{ required: true, message: proxy.$t('form.enter_idcard'), trigger: 'blur' },
				{ validator: validate_idcard_rules, trigger: "blur" },
			],
			idcard_thumb: [
				{ required: true, message: proxy.$t('form.enterIdcardPhoto'), trigger: 'blur' },
			],
			bank_card: [
				{ required: true, message: proxy.$t('form.enter_bankcard'), trigger: 'blur' },
				{ validator: validate_bankcard_number_rules, trigger: "blur" },
			],
			bank_card_thumb: [
				{ required: true, message: proxy.$t('form.enterBankcardPhoto'), trigger: 'blur' },
			],
  			
			
			
		})

		// const handleCheckChange = (value) => {
		// 	const checkedCount = value.length
		// 	if(value.indexOf('image')!==-1){
		//     	_rules.images = [
		// 			{ required: true, message: '请上传图片作品', trigger: 'blur' },
		// 		]
		//     }else{
		//     	_rules.images = []
		//     }
		//     if(value.indexOf('video')!==-1){
		//     	_rules.video = [
		// 			{ required: true, message: '请上传视频作品', trigger: 'blur' },
		// 		]
		//     }else{
		//     	_rules.video = []
		//     }
		// }
		let timerId = 0;

		const GetApplyContract = () => {
			store.dispatch("user/GetApplyContract",{
	  			
		    }).then((res)=>{
		      	console.log("GetApplyContract",res);
		      	// nextStep(false, true, false);
		      	if(!data.isEsign && res.status == '2'){
		      		clearInterval(timerId);
		      		let pdfurl = res.contract_path;
		      		getPdfContent(pdfurl);
		      	}else if(data.isEsign && res.status == '1'){
		      		clearInterval(timerId);
		      		data.isEsign = false;
		      		ElMessage({
					    message: proxy.$t('apply_contract.signSuccess'),
					    grouping: true,
					    type: 'success',
					})
					data.isSignLoading = false;
		    		data.signDisabled = false;
					router.push({name:'apply_info'});
		      	}
				
		    }).catch(err => {
		    	data.isSignLoading = false;
		    	data.signDisabled = false;
	            // console.error(err)
	            ElMessage({
				    message: err.message,
				    grouping: true,
				    type: 'error',
				})
	        })
		}
		const StartApplyContract = () => {
			store.dispatch("user/GetApplyContract",{
	  			
		    }).then((res)=>{
		      	console.log(res,"StartApplyContract");
		      	if(res.status == 1){
		      		
		      		router.push({name:'apply_info'});
		      	} else if ( (res.status == 2) || (res.status == 0) ) {
		      		ruleUser.realname = res.realname;
				    ruleUser.idcard = res.idcard;		
		  			ruleUser.idcard_thumb = res.idcard_thumb;
		  			data.idcard_thumb = res.idcard_thumb
		  			ruleUser.bank_card = res.bank_card;
		  			ruleUser.bank_card_thumb = res.bank_card_thumb;
		  			data.bankcard_thumb = res.bank_card_thumb;
		      	}
				bus.emit('showFooter',true);
		    }).catch(err => {
	            // console.error(err)
	            ElMessage({
				    message: err.message,
				    grouping: true,
				    type: 'error',
				})
	        })
		}

		const getPdfContent = (pdfurl) => {
			axios({
				url:pdfurl,
				method:"get",
				responseType:"blob"
			}).then((res) =>{
				const blob = new Blob([res.data],{type: 'application/pdf'});
				data.iframeUrl = window.URL.createObjectURL(blob);
				data.showIframe = true;
				data.isPdfLoading = false;
			})

	    }

		const submitForm = (formEl) => {
			console.log(ruleUser,"ruleUser");
			data.isPreviewLoading = true;
			data.previewDisabled = true;
			bus.emit('needRefresh', true)
			if (!formEl) return
			formEl.validate((valid, fields) => {
			    if (valid) {
				    console.log(valid,'submit!')
			    	if(Object.keys(data.idcardData).length > 0){
			    		if(data.idcardData.name != ruleUser.realname){
			    			ElMessage({
							    message: proxy.$t('apply_contract.noMatch'),
							    grouping: true,
							    type: 'warning',
							})
							data.isPreviewLoading = false;
							data.previewDisabled = false;
			    		} else if(data.idcardData.idNumber != ruleUser.idcard){
			    			ElMessage({
							    message: proxy.$t('apply_contract.noMatch'),
							    grouping: true,
							    type: 'warning',
							})
							data.isPreviewLoading = false;
							data.previewDisabled = false;
			    		} else {
			    			store.dispatch("user/PostApplyContract",{
						      	realname: ruleUser.realname,
							    idcard: ruleUser.idcard,
					  			idcard_thumb: ruleUser.idcard_thumb,
					  			bank_card: ruleUser.bank_card,
					  			bank_card_thumb: ruleUser.bank_card_thumb,
					  			
						    }).then((res)=>{
						      	// console.log("SubmitContract",res);
						      	ElMessage({
								    message: proxy.$t('apply_contract.submit_success'),
								    grouping: true,
								    type: 'success',
								})
								data.isPreviewLoading = false;
								data.previewDisabled = false;
						      	data.isPdfLoading = true;
						      	data.fetchPdf = true;
						      	nextStep(false, true, false);
								timerId = setInterval(()=>{
									GetApplyContract();
								},2000);
						    }).catch(err => {
					            // console.error(err)
					            ElMessage({
								    message: err.message,
								    grouping: true,
								    type: 'error',
								})
								data.isPreviewLoading = false;
								data.previewDisabled = false;
					        })
			    		}
			    		
			    	} else {
			    		ElMessage({
						    message: proxy.$t('apply_contract.idcard_face'),
						    grouping: true,
						    type: 'warning',
						})
						data.isPreviewLoading = false;
						data.previewDisabled = false;
			    	}

			    } else {
			    	data.isPreviewLoading = false;
			    	data.previewDisabled = false;
			     	console.log('error submit!')
			      	return false
			    }
			})
		}


		const dialogImageUrl = ref('')
		const dialogVisible = ref(false)
		const disabled = ref(false)

		const handleRemove = (file) => {
		  console.log(file)
		}

		const handlePictureCardPreview = (file) => {
		  dialogImageUrl.value = file.url
		  dialogVisible.value = true
		}

		const handleDownload = (file) => {
		  console.log(file)
		}

		const handleChange = (uploadFile, uploadFiles) => {
		  console.log(uploadFile,"uploadFile")
		  console.log(uploadFiles,"uploadFiles")

		}

		const UploadEsign = (esignImg) => {
	    	store.dispatch("user/UploadCameraEsign",{
	    		base64_file: esignImg,
	    	}).then((res)=>{
	    		console.log(res,"UploadEsign");
	    		// nextStep(false,false,true);
				timerId = setInterval(()=>{
					GetApplyContract();
				},2000);
	    	}).catch(err => {	            
	            ElMessage({
				    message: proxy.$t('apply_contract.upload_fail'),
				    grouping: true,
				    type: 'error',
				})
	        })
	    }


		const formSize = ref('large')	
		const viewBox = ref(null);
        const scroll = (val) => {
			if(val == 'top'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		       
			}else if(val == 'bottom'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });  	
		        
			}
		}

		

        onMounted(async()=>{
        	getSiteInfo();
        	StartApplyContract();
        	// GetApplyContract();
        	await nextTick()
        	setTimeout(() => {
		      data.isLoading = false;
		      viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		    }, 600);
		    console.log("页面数据更新完毕了")
        })

		const refData = toRefs(data);

		return {
			...refData,
			getSiteInfo,
			viewBox,
			scroll,
			nextStep,
			toSubmit,
			ruleRef,
			ruleUser,
			_rules,
			submitForm,
			formSize,
			dialogImageUrl,
			dialogVisible,
			disabled,
			handleRemove,
			handlePictureCardPreview,
			handleDownload,
			handleChange,
			// handleCheckChange,
			getPdfContent,
			GetApplyContract,
			getPdfContent,
			StartApplyContract,
			UploadEsign,

		}
	},
	created() {

	},
	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	},
	methods: {
		init() {
			this.username = this.$cookies.getUsername();
		},
		UploadIdcardFile(event,type){
			let that = this;
			that.isIdcardLoading = true;
        	event.preventDefault();
            this.picFiles = event.currentTarget.files;
            if(this.picFiles.length > 0){
            	var formData = new FormData();
        		formData.append("file", this.picFiles[0]);
            	this.$store.dispatch('user/UploadIdcardFile', formData).then((res)=>{	            		
	            	console.log("upload",res);
	            	if(res.code==0){
	            		if(type=='idcard'){
	       //      			if(res.data.name != that.ruleUser.realname){
	       //      				ElMessage({
							 //        message: this.$t('apply_contract.noMatch'),
							 //        type: "warning",
							 //    });
	       //      			} else if(res.data.idNumber != that.ruleUser.idcard){
				    // 			ElMessage({
								//     message: this.$t('apply_contract.noMatch'),
								//     grouping: true,
								//     type: 'warning',
								// })
								
				    // 		}
				    		
            				that.isIdcardLoading = false;
		            		// that.idcard_thumb = res.url;
		            		
							that.ruleUser.realname = res.data.name;
							that.ruleUser.idcard = res.data.idNumber;
		            		that.ruleUser.idcard_thumb = res.thumb.url;
		            		that.idcardData = res.data;

		            	}
	            	}else{
	            		console.log(err,"err1")
	            		that.isIdcardLoading = false;
	            		ElMessage({
					        message: res.msg,
					        type: "warning",
					    });
	            	}
	            	
	            	
	            }).catch(err=>{
	            	that.isIdcardLoading = false;
	            	console.log(err,"err2")
					ElMessage({
				        message: err.message,
				        type: "warning",
				    });
				})
            }
		},
		uploadFile(event,type){
			let that = this;
			that.isBankLoading = true;
        	event.preventDefault();
            this.picFiles = event.currentTarget.files;
            // console.log(this.picFiles,"this.picFiles");
            if(this.picFiles.length > 0){
            	for(let i=0;i<this.picFiles.length;i++){
            		
            		// console.log(this.picFiles[0],"this.picFiles-1");
            		var formData = new FormData();
            		formData.append("file", this.picFiles[i]);
	            	this.$store.dispatch('user/GrapherWorkerUpload', formData).then((res)=>{	            		
		            	console.log("upload",res);
		            	if(type=='idcard'){
		            		that.isBankLoading = false;
		            		// that.idcard_thumb = res.url;
		            		that.ruleUser.idcard_thumb = res.url;
		            	}else if(type=='bankcard'){
		            		that.isBankLoading = false;
		            		// that.bankcard_thumb = res.url;
		            		that.ruleUser.bank_card_thumb = res.url;
		            	}
		            	
		            }).catch(err=>{
		            	that.isBankLoading = false;
						ElMessage({
					        message: err.message,
					        type: "warning",
					    });
					})
	            }
            }
            
           
        },
        handleReset () {
		    this.$refs.esign.reset()
		},
		handleGenerate () {
			this.isSignLoading = true;
			this.signDisabled = true;
		    this.$refs.esign.generate().then(res => {
		      // console.log(res,'sign');		      
		      if(res){
		      	this.resultImg = res
		      	this.isEsign = true;
		      	this.UploadEsign(res);
		      }
		      
		    }).catch(err => {
		    	this.isSignLoading = false;
		    	this.signDisabled = false;
		      	console.log(err,'sign err') // 画布没有签字时会执行这里 'Not Signned'
		    })
		},
	},
})