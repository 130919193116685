<template>
	<PageLoading :is-loading="isLoading"></PageLoading>
	<SideComponent @scrollto="scroll"></SideComponent>
	<div class="container" ref="viewBox">
		<!-- <Header2 :show-search-bar-pic="showHeader" login-style="loginStyle" :mobile-icon-style="mobileIconStyle" :show-list-icon="showListIcon"/> -->
		<!-- <search-bar-pic></search-bar-pic> -->

		<!-- <div class="header-container">
			<div class="search-swiper-container">				
				<Swiper :list="banners"/>
				<search-bar-pic></search-bar-pic>
			</div>
		</div> -->

		<!-- 以下代码与index-pic是一套 -->
		<div class="header-container">
			<Header2 />			
			<!-- <div class="search-swiper-container">
			</div> -->
		</div>
		
		<div class="content-container">
			<div class="index-pic">				
				<!-- <div class="pic-wrapper">
					<search-bar-pic :isNeedRedirect="false" @searchKeywords="searchNews"></search-bar-pic>
				</div> -->
				
			</div>
			<div class="news-container">
				<div class="enclosure">
					<!-- text-shadow:-1px -1px #fff,1px 1px #333; -->
					<div style="width:100%;text-align:center;font-size:18px;padding:10px 0px;font-weight:bold;">{{title}}</div>
					<section class="news-wrapper" >
						<div class="content" v-html="content"></div>
					</section>
				</div>
			</div>

		</div>
		<Footer/>
	</div>
</template>

<script>
	import content from './content_controller';
	export default content;
</script>

<style src="@/assets/css/content.css" scoped></style>