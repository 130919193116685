import { onMounted,nextTick, defineComponent, ref, provide, reactive, toRefs, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import Swiper from '@/components/layout/Swiper.vue';
import SearchBar2 from '@/components/layout/SearchBar2.vue';
import Header2 from '@/components/layout/header2.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';
import SideMenu from '@/components/layout/SideMenu.vue';
import bus from 'vue3-eventbus'
import SideComponent from '@/components/common/SideComponent.vue';

export default defineComponent({
	components: {
		Header2,
		Menu,
	    Swiper,
	    SearchBar2,
	    Footer,
	    SideMenu,
	    SideComponent,
	},
	props: {

	},
	setup() {
		let {ctx, proxy} = getCurrentInstance();
		const data = reactive({
			showHeader: true,
			loginStyle: "min-width:141px;",
			mobileIconStyle: "color:rgba(223, 58, 8, 1);",
			showListIcon: true,
			activeName: 'first',
			username: '',
			personInfo: {},
			isMobile: false,
			disabled: false,
			picFiles: [],
			isAvatarLoading: false,
			thumb: '',
		});
		const showMenu = ref(proxy.$menus);
		provide('showMenu', showMenu);

		const outerStyleStr = ref("position:relative;left:10%;top:40%;transform:translate(-10%,-40%);width:auto;");
		provide('outerStyleStr', outerStyleStr);

		const styleStr = ref("height:45px;");
		provide('styleStr', styleStr);

		const showSearchText = ref(false);
		provide('showSearchText', showSearchText);

		const getImageUrl = (name) => {
		    return proxy.$utils.getImageUrl(name);
		};

		// bus.emit('needRefresh', true)

		const store = useStore();	

		const ruleFormRef = ref()
		const ruleUserRef = ref()
		const handleClick = (tab, event) => {
		  console.log(tab, event)
		}

		const ruleUser = reactive({
			thumb:'',
		    username: '',
		    mobile: '',
		  	email: '',
		  	department: '',
		  	code:'',
		})

		const validate_mobile_rules = (rule, value, callback) => {
			let regMobile = proxy.$utils.isPhone(value);
		    if (value === "") {
		        callback(new Error(proxy.$t('enterPhoneNumber')));
		    } else if (!regMobile) {
		        callback(new Error(proxy.$t('enterCorrectPhoneNumber')));
		    } else {
		        callback();
		    }

		}

		const validate_email_rules = (rule, value, callback) => {
			let email = proxy.$utils.regEmail(value);
		    if (!email) {
		        callback(new Error( proxy.$t('enterCorrectEmail') ));
		    } else {
		        callback();
		    }

		}
		const validate_company_rules = (rule, value, callback) => {
			let company = proxy.$utils.regCompany(value);
		    if (!company) {
		        callback(new Error( proxy.$t('enterCorrectUnitName') ));
		    } else {
		        callback();
		    }

		}

		const _rules = reactive({
			// username: [
			// 	{ required: true, message: '请输入用户名', trigger: 'blur' },
			// 	{
			// 		pattern: /^[a-z0-9]{6,12}$/,
			// 		message: '用户名必须包含字母和数字', 
			// 		trigger: 'blur'
			// 	}
			// ],
			mobile: [
				{ required: true, message: proxy.$t('enterPhoneNumber'), trigger: 'blur' },				
			],
			code: [
				{ required: true, message: proxy.$t('enterCode'), trigger: 'blur' },				
			],
			email: [
				{ validator: validate_email_rules, trigger: "blur" },				
			],
			department: [
				{ validator: validate_company_rules, trigger: "blur" },
			],
		})

		const handleChange = (event) => {
			console.log(event, 'event');
		}

		const submitUser = (formEl=undefined) => {
			data.disabled = true;
			bus.emit('needRefresh', true)
			if (!formEl) return
			formEl.validate((valid) => {
			    if (valid) {
			      console.log(valid,'submit!')
			      store.dispatch("user/modifyPassword",{
			      	username: ruleUser.username,
			      	email: ruleUser.email,
			      	department: ruleUser.department,
			      	mobile: ruleUser.mobile,
			      	code: ruleUser.code,
			      	thumb: ruleUser.thumb,
			      }).then((res)=>{
			      	console.log(res,"modify");
			      	ElMessage({
					    message: res.message,
					    grouping: true,
					    type: 'success',
					})
					
			      }).catch(err => {
			            // console.error(err)
			            ElMessage({
						    message: err.message,
						    grouping: true,
						    type: 'error',
						})
						data.disabled = false;
			        })
			    } else {
			    	data.disabled = false;
			      	console.log('error submit!')
			      	return false
			    }
			})
		}

		const ruleForm = reactive({
			// oldpass: '',
		    pass: '',
		    checkPass: '',
		  
		})

		const validatePass = (rule, value, callback) => {
		  if (value === '') {
		    callback(new Error('请输入新密码'))
		  } else {
		    if (ruleForm.checkPass !== '') {
		      if (!ruleFormRef.value) return
		      // ruleFormRef.value.validateField('checkPass', () => null)
		    }
		    callback()
		  }
		}
		const validatePass2 = (rule, value, callback) => {
		  if (value === '') {
		    callback(new Error('请确认新密码'))
		  } else if (value !== ruleForm.pass) {
		    callback(new Error("两次输入不一致!"))
		  } else {
		    callback()
		  }
		}

		

		const rules = reactive({
		    pass: [
		    	{ required: true, message: '请输入新密码', trigger: 'blur' },
		    	{ min: 8, message: proxy.$t('passwordLength'), trigger: 'blur' },
		    ],
		    checkPass: [
		    	{ required: true, message: '请确认新密码', trigger: 'blur' },
		    	{ validator: validatePass2, trigger: 'blur' }
		    ],
		  
		})

		const submitForm = (formEl=undefined) => {
			bus.emit('needRefresh', true)
			if (!formEl) return
			formEl.validate((valid) => {
			    if (valid) {
			      console.log(valid,'submit!')
			      store.dispatch("user/modifyPassword",{
			      	password: ruleForm.pass,
			      	password_confirm: ruleForm.checkPass,
			      }).then((res)=>{
			      	console.log(res,"modify");
			      	ElMessage({
					    message: proxy.$t('active.operate_success'),
					    grouping: true,
					    type: 'success',
					})
			      }).catch(err => {
			            // console.error(err)
			            ElMessage({
						    message: err.message,
						    grouping: true,
						    type: 'error',
						})
			        })
			    } else {
			      console.log('error submit!')
			      return false
			    }
			})
		}
		const getPersonInfo = () => {
			store.dispatch("user/getPersonInfo",{}).then((res)=>{
				console.log(res,"personInfo");
				data.personInfo = res;
				ruleUser.thumb = res.thumb;
				ruleUser.username = res.username;
				ruleUser.mobile = res.mobile;
				ruleUser.email = res.email;
				ruleUser.department = res.department;
			}).catch(err => {
	            console.error(err)
	            ElMessage({
				    message: err.message,
				    grouping: true,
				    type: 'error',
				})
	        }) 
		}
			
		const viewBox = ref(null);
        const scroll = (val) => {
			if(val == 'top'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		       
			}else if(val == 'bottom'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		        	
		        	
		        
			}
		}

		const PcOrMobile=()=>{
			if(document.documentElement.clientWidth >= 768){
				data.isMobile = false;
			}else{
				data.isMobile = true;
			}
		}

		const getCode = (mobile) => {
			console.log(mobile,"mobile");
			if(!mobile){
				ElMessage({
			        message: proxy.$t('enterPhoneNumber'),
			        type: "warning",
			        
			    });
			}else{
				store.dispatch('user/MobileCode',{
					mobile: mobile,
				}).then(res=>{
					
					console.log(res,"mobilecode-res");
					ElMessage({
				        message: res.message,
				        type: "success",
				        
				    });
				    data.disabled = true;
				}).catch(err=>{
					
					ElMessage({
				        message: err.message,
				        type: "error",
				        
				    });
				    data.disabled = false;
				})
			}
			
		}

        onMounted(async()=>{
        	// PcOrMobile();
        	await nextTick()
        	setTimeout(() => {
		        data.isLoading = false;
		        viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
			    bus.emit('showFooter',true);
		    }, 600);
		    console.log("页面数据更新完毕了")
        })

		const refData = toRefs(data);

		return {
			...refData,
			getImageUrl,
			handleClick,
			ruleFormRef,
			ruleForm,
			rules,
			submitForm,
			ruleUserRef,
			ruleUser,
			_rules,
			getPersonInfo,
			submitUser,
			viewBox,
			scroll,
			PcOrMobile,
			getCode,
		}
	},
	created() {

	},
	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	},
	methods: {
		init() {
			this.username = this.$cookies.getUsername()!="undefined" ? this.$cookies.getUsername() : this.$cookies.getMobile();
			this.getPersonInfo();
			if(document.documentElement.clientWidth >= 768){
				this.isMobile = false;
			}else{
				this.isMobile = true;
			}
			window.addEventListener('resize', function(event){
				if(document.documentElement.clientWidth >= 768){
					this.isMobile = false;
				}else{
					this.isMobile = true;
				}
			});
		},
		uploadAvatar(event){
			let that = this;
			this.isAvatarLoading = true;
        	event.preventDefault();
        	this.picFiles = event.currentTarget.files;
        	console.log(event,"event");
       		var formData = new FormData();
    		formData.append("file", this.picFiles[0]);
        	this.$store.dispatch('user/GrapherWorkerUpload', formData).then((res)=>{
        		console.log(res,'avatar');
         		that.ruleUser.thumb = res.url;
         		// that.thumb = res.url;	
        		that.isAvatarLoading = false;
        	})            		
            	
		}
	},
})