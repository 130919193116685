import { nextTick, onMounted, defineComponent, ref, provide, reactive, toRefs, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import PageLoading from '@/components/common/PageLoading.vue';
import Swiper from '@/components/layout/Swiper.vue';
import SearchBar2 from '@/components/layout/SearchBar2.vue';
import Header2 from '@/components/layout/header2.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';

export default defineComponent({
	components: {
		PageLoading,
		Header2,
		Menu,
	    Swiper,
	    SearchBar2,
	    Footer
	},
	props: {

	},
	setup() {
		let {ctx, proxy} = getCurrentInstance();
		const data = reactive({
			isLoading:true,
			showHeader: true,
			loginStyle: "min-width:141px;",
			mobileIconStyle: "color:rgba(223, 58, 8, 1);",
			showListIcon: true,

			catTags: [
				
			],
      		pics:{},
      		selectedCat: 0,
		});
		const showMenu = ref(proxy.$menus);
		provide('showMenu', showMenu);

		const outerStyleStr = ref("position:relative;left:10%;top:40%;transform:translate(-10%,-40%);width:auto;");
		provide('outerStyleStr', outerStyleStr);

		const styleStr = ref("height:45px;");
		provide('styleStr', styleStr);

		const showSearchText = ref(false);
		provide('showSearchText', showSearchText);

		const getImageUrl = (name) => {
		    return proxy.$utils.getImageUrl(name);
		};

		const store = useStore();

        const getPics = (selectedCat=0) => {
        	store.dispatch("common/getPicLists", {
	        	page: 1,
	        	filter: '',
	        	cid: selectedCat,
	        	lid: 0,
	        	type: 1,
	        	day: 0,
	        	ratio: 0,
	        }).then((res) => {
	        	data.pics = res;
	        })
        };
        

	    onMounted(async()=>{
	    	store.dispatch("common/getPicCats").then((res) => {
	        	data.catTags = res
	        })
	        getPics();
        	await nextTick()
        	setTimeout(() => {
		      data.isLoading = false;
		    }, 600);
		    console.log("页面数据更新完毕了")
        })

		const refData = toRefs(data);

		return {
			...refData,
			getImageUrl,
			getPics,
		}
	},
	created() {

	},
	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	},
	methods: {
		init() {

		},
		selectCat(id){
			if(this.selectedCat == 0 || this.selectedCat != id){
				this.selectedCat = id;
			}else{
				this.selectedCat = 0;
			}   
			this.getPics(this.selectedCat,this.selectedLabel,this.selectedOnlineTime,this.selectedDpi);		
			
		},
		toPicDetail(id){
			this.$router.push({ path:'/topic_pic_detail', query:{ id: id } });
		},
	},
})