<template>
	<div class="search-container">
		<div class="swrapper">		
			<div class="search-bar search-bar_border">
				<el-input v-model="input" :placeholder="placeholder" @keyup.enter.native="searchVideo(input)"/>
				<button type="primary" class="search-btn search-btn_s" @click="searchVideo(input)">
					<i class="iconfont icon-sousuo1 icon-style1" /><span class="stext">{{$t('search.search')}}</span>
				</button>
			</div>
		</div>
	</div>

</template>
<script>
	import { ref, provide, reactive, h, render, toRefs, getCurrentInstance } from 'vue';
	import React, { useState } from 'react';
	import ColorThief from "colorthief";
	import { UploadFilled } from '@element-plus/icons-vue';
	// import ColorPicker from '@/components/ColorPicker.vue'
	// import { UploadFilled } from '@element-plus/icons-vue';
	// import Hello from "./color/Hello.vue"


	export default {
		name:"SearchBarVideo",
		props: {
            typeName: Array,
            isHome: Boolean,
            isTopicPic: Boolean,
        },
        emits:['search'],
		// setup(props, { attrs, slots, emit, expose }) {
		    
		// },
		// setup(props, context) {
		//     // 透传 Attributes（非响应式的对象，等价于 $attrs）
		//     console.log(context.attrs)

		//     // 插槽（非响应式的对象，等价于 $slots）
		//     console.log(context.slots)

		//     // 触发事件（函数，等价于 $emit）
		//     console.log(context.emit)

		//     // 暴露公共属性（函数）
		//     console.log(context.expose)
		// },
		setup() {
			// const instance = getCurrentInstance();
		    // 获取实例上下文
		    const { proxy } = getCurrentInstance();
			const data = reactive({
				input: '',
				ispic: true,
				placeholder: proxy.$t('search.trySearch'),
				dialogTableVisible: false,
				dialogVisible: false,
				uploadVisible: false,
				formLabelWidth: '140px',
				form: {
				    name: '',
				    region: '',
				    date1: '',
				    date2: '',
				    delivery: false,
				    type: [],
				    resource: '',
				    desc: '',
				},
				value: [4, 8],
				color: '#f84949',
				color1: '#f84949',
				color2: '#f84949',
				itemRefs: [],
				isDragging: false,
				change_color: '#ff4500',
				bannerimg: import("@/assets/img/1.jpg"), // "@/assets/img/1.jpg", //
				imageUrl: new URL('@/assets/img/1.jpg', import.meta.url).href
			})
			
			const refData = toRefs(data);
		    
		    return {
		        ...refData,
		    }
		},
		mounted() {
			let that = this;
			this.$nextTick(() => {
				if(document.documentElement.clientWidth < 768){
					if(document.querySelector(".stext")){
						document.querySelector(".stext").style.display = "none";
					}
					document.querySelector(".search-btn").style.width = "50px";
					document.querySelector(".search-btn").style.minWidth = "50px";
				}else{
					if(document.querySelector(".stext")){
						document.querySelector(".stext").style.display = "block";
					}
					// document.querySelector(".search-btn").style.width = "70px";
					// document.querySelector(".search-btn").style.minWidth = "70px";
				}
				

				window.addEventListener('resize', function(event){
					if(document.documentElement.clientWidth < 768){
						if(document.querySelector(".stext")){
							document.querySelector(".stext").style.display = "none";
						}
						document.querySelector(".search-btn").style.width = "50px";
						document.querySelector(".search-btn").style.minWidth = "50px";
					}else{
						if(document.querySelector(".stext")){
							document.querySelector(".stext").style.display = "block";
						}
						// document.querySelector(".search-btn").style.width = "70px";
						// document.querySelector(".search-btn").style.minWidth = "70px";
					}

					
				});

			});	

			
		},
		watch(){

		},
		computed:{

		},
		methods:{
			searchVideo(keyword) {
				this.$emit("search",keyword);
			},
			ImgColor() {
				// console.log(this.bannerimg, "bannerimg");
	            let domImg = document.querySelector('#pickimg'); // 获取图片dom节点
	            let colorthief = new ColorThief();
	            domImg.addEventListener('load', () => { // 图片加载
	                this.colors = colorthief.getPalette(domImg).map(e => {
	                    let hex = this.rgbaToHex(e)
	                    return hex
	                }); // 调色板
	                this.zcolor = this.rgbaToHex(colorthief.getColor(domImg)) // 图片主色调
	                console.log('调色盘', this.colors);//getPalette(domImg, 2)第二个参数可选(1~10)
	                console.log('主色调', this.zcolor);//第二个参数可选(1~10)
	            })
	        },
	        rgbaToHex(rgba) { // rgba转16进制
	            let hex = '#';
	            for (const i of rgba) {
	                hex += i.toString(16).padStart(2, '0');
	            }
	            return hex;
	        },
	        loadImage() {
	        	console.log(this.bannerimg, "bannerimg");
                let domImg = document.querySelector('#pickimg');
                let colorthief = new ColorThief();
                this.bacolor = colorthief.getPalette(domImg)[0];
                console.log(this.bacolor,"this.bacolor"); //主颜色
            },
			handleUploadSuccess(res, file) {
				console.log(file,"file");
		        this.imageUrl = URL.createObjectURL(file.raw);
		        console.log(this.imageUrl,"this.imageUrl")
		    },
		    beforeUpload(file) {
		        const isJPG = file.type === 'image/jpeg';
		        const isPNG = file.type === 'image/png';
		        const isLt2M = file.size / 1024 / 1024 < 50;

		        if (!isJPG && !isPNG) {
		          this.$message.error(proxy.$t('search.isJPG'));
		        }
		        if (!isLt2M) {
		          this.$message.error(proxy.$t('search.sizeLimit'));
		        }
		        return isJPG && isPNG && isLt2M;
		    },
			handleColorChange(event) {
				console.log(event,"event");
			    // const reg = /[0-9]\d+/g
			    // const getArr = value.match(reg)
			    // let hexStr = '#'+((getArr[0] << 16) | (getArr[1]  << 8) | getArr[2] ).toString(16);
			    // console.log(hexStr,"hexStr")
			    this.color = event.target.dataset.color;

			},
			handleMouseMove(event) {
				// console.log(event,"event");
				let outerWidth = event.currentTarget.parentElement.parentElement.clientWidth;
				// console.log(event.currentTarget.parentElement.clientWidth,"parentElement");
    			// console.log(event.currentTarget.parentElement.parentElement.clientWidth,"pparentElement");
				// 处理移动事件的函数
    			const moveHandler = (e) => {
    				// console.log(e.movementX,"mousemove");
    				if (!this.isDragging) return;
					// 设置拖拽状态和鼠标样式 将拖拽状态重置
					let innerWidth = e.currentTarget.parentElement.clientWidth;							
					let percent = this.circumference(innerWidth / outerWidth);

					let nextInnerWidth = e.currentTarget.parentElement.nextElementSibling.clientWidth;
					let nextPercent = this.circumference(nextInnerWidth / outerWidth);

					if(e.movementX > 0){ //左加  右减
						percent = (innerWidth + Math.abs(e.movementX)) / outerWidth;
						percent = this.circumference(percent);

						nextPercent = (nextInnerWidth - Math.abs(e.movementX)) / outerWidth;
						nextPercent = this.circumference(nextPercent);

					} else { //左减  右加

						percent = (innerWidth - Math.abs(e.movementX)) / outerWidth;
						percent = this.circumference(percent);

						nextPercent = (nextInnerWidth + Math.abs(e.movementX)) / outerWidth;
						nextPercent = this.circumference(nextPercent);
					}

					percent += '%';
					nextPercent += '%';
					e.currentTarget.parentElement.style.width = percent;
					e.currentTarget.parentElement.nextElementSibling.style.width = nextPercent;
    			};
    			// 处理鼠标松开事件的函数
    			const upHandler = () => {
    				// console.log("onmouseup");
    				this.isDragging = false;
    				document.removeEventListener('mousemove', moveHandler);
        			document.removeEventListener('mouseup', upHandler);
    			};
    			this.isDragging = true;
    			// 监听移动和松开事件
			    event.currentTarget.addEventListener('mousemove', moveHandler);
			    document.addEventListener('mouseup', upHandler);
			},
			circumference(x) {
			  if ( Number.isNaN( Number.parseFloat(x) ) ) {
			    return 0;
			  }
			  return Number.parseFloat(x*100).toFixed(2);
			},
			setItemRefs(e) {
				// console.log(e,"e");
			},
			clickMenu(data){
		  		this.active_menuid = data.name;
		  		if(this.active_menuid == 'pic'){
		  			this.ispic = true;
		  			// this.placeholder = '可以尝试搜索关键词';
		  		}else if(this.active_menuid == 'video'){
		  			this.ispic = false;
		  			// this.placeholder = '可以尝试搜索关键词';
		  		}
		  	},
		    generateClass(data){
			    if(this.active_menuid == data.name){
			        return ['tabitem','active'];
			    }
			    return ['tabitem'];
		    },
		    addColor() {

		    },
		    addClass(){
		    	return "add-text";
		    },

		},
	}
</script>

<style src="@/assets/css/search-bar.css" scoped></style>