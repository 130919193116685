import { defineComponent, ref, provide, reactive, toRefs, getCurrentInstance,nextTick, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import PageLoading from '@/components/common/PageLoading.vue';
import { useStore, createNamespacedHelpers } from "vuex";
import Swiper from '@/components/layout/Swiper.vue';
import SearchBar2 from '@/components/layout/SearchBar2.vue';
import Header2 from '@/components/layout/header2.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';
import bus from 'vue3-eventbus'
import Loading from '@/components/common/Loading.vue';

export default defineComponent({
      components: {
          Header2,
          Menu,
          Swiper,
          SearchBar2,
          PageLoading,
          Footer,
          Loading,
      },
      props: {

      },
      setup() {
            let {ctx, proxy} = getCurrentInstance();
            const router = useRouter();
            const route = useRoute();

            const data = reactive({
                isLoading:true,
                showHeader: true,
                loginStyle: "min-width:141px;",
                mobileIconStyle: "color:rgba(223, 58, 8, 1);",
                showListIcon: true,
                detail:{
                    // img:'img/photo/2.jpg',
                    // name:'王勇森',
                    // desc: '山东省摄影家协会会员，青岛国际新闻中心《走向世界一帆船之都杂志》《时尚青岛官网》《青岛图片库》摄影记者、图片编辑。摄影师感言:从事新闻采访工作20余年，拍摄记录了青岛二十多年来发生的翻天覆地的变化。我始终认为，摄影的主要功能是记录，记录大自然的壮美;记录社会的进步;也记录发生在人们身边的故事。让世间正能量得以传播;让人们欢乐和感动;也让城市和人们记住历史，我觉得这是我作为一名摄影人应有的责任和使命感。',
                },
                picWorks:{},
                pagination:{},
                teams:[],
                mobile: false,
                photos:[],
                style: {
                    height: '100px'
                },
                page:1,
                nomore:true,
                isPicLoading: true,
                isDetailLoading: true,
                id: 0,
            });
            const showMenu = ref(proxy.$menus);
            provide('showMenu', showMenu);

            const outerStyleStr = ref("position:relative;left:10%;top:40%;transform:translate(-10%,-40%);width:auto;");
            provide('outerStyleStr', outerStyleStr);

            const styleStr = ref("height:45px;");
            provide('styleStr', styleStr);

            const showSearchText = ref(false);
            provide('showSearchText', showSearchText);

            const getImageUrl = (name) => {
                return proxy.$utils.getImageUrl(name);
            };
            const store = useStore();
            const id = route.query.id;

            data.cameraid = id;
            const getCameraWork=(id=0, page=1)=>{
                store.dispatch("cameraGrapher/getCameraWork",{
                    camera_id: id,
                    page: page,
                }).then((res) => {
                    console.log(res,"works");
                    data.detail = res.camera_men;
                    data.isDetailLoading = false;
                    if(res.data.length > 0){
                        if(page == 1){
                            data.photos = res.data;
                        }else{
                            data.photos = data.photos.concat(res.data);
                        }
                        
                        if(res.data.length < res.pagination.pageSize){
                            data.nomore = true;
                        }else{
                            data.nomore = false;
                        }
                    }else{
                        data.nomore = true;
                    }        
                    // data.pagination = data.videos.pagination;
                    
                    data.pagination = res.pagination;
                    data.total = res.pagination.total;
                    if(!data.nomore){
                        data.page = page+1;
                    }
                    // data.page += 1;
                    data.isPicLoading = false;
                    bus.emit('showFooter',true);
                    
                }).catch(err=>{
                  data.isDetailLoading = false;
                  data.isPicLoading = false;
                })
            }
            
            const viewBox = ref(null);
            const scroll = (val) => {
                if(val == 'top'){
                    
                    viewBox.value.scrollIntoView({
                        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
                        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
                        inline: "center", // 定义水平滚动方向的对齐
                    });
                   
                }else if(val == 'bottom'){
                    
                    viewBox.value.scrollIntoView({
                        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
                        block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
                        inline: "center", // 定义水平滚动方向的对齐
                    });
                        
                        
                    
                }
            }

            onMounted(async()=>{
              getCameraWork(id)
              await nextTick(()=>{
                bus.on("scrollTotop",e => {
                    if(e){ 
                        viewBox.value.scrollIntoView({
                            behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
                            block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
                            inline: "center", // 定义水平滚动方向的对齐
                        });
                    }
                        
                });
                bus.on("scrollToBottom",e => {
                    if(e){
                        viewBox.value.scrollIntoView({
                            behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
                            block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
                            inline: "center", // 定义水平滚动方向的对齐
                        });
                    }
                        
                    });
                })
                setTimeout(() => {
                data.isLoading = false;
                viewBox.value.scrollIntoView({
                    behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
                    block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
                    inline: "center", // 定义水平滚动方向的对齐
                });
              }, 600);
              console.log("页面数据更新完毕了")
            })

            const refData = toRefs(data);

            return {
                  ...refData,
                  getImageUrl,
                  viewBox,
                  scroll,
                  getCameraWork,
            }
      },
      created() {

      },
      mounted() {
        this.$nextTick(() => {
              this.init();
        });
      },
      methods: {
        init() {
          if(document.documentElement.clientWidth < 890){
            this.mobile = true;
          }else{
            this.mobile = false;
          }

          window.addEventListener('resize', function(event){
            if(document.documentElement.clientWidth < 890){
              this.mobile = true;
            }else{
              this.mobile = false;
            }
          
          });
        },
        loadMore() {
            this.isPicLoading = true;
            this.getCameraWork(this.cameraid, this.page);
        },
        activeStyle(item) {   

          let object = {};
          object.width = item.ratio_w*200/item.ratio_h+'px';
          // if(item.ratio_w/item.ratio_h<2){
          //   object.maxWidth = '300px';
          // }
          // object.maxHeight = '300px';
          return object;
        },
        toDetail(id) {
          // this.$router.push({ path:'/topic_pic_detail',query:{id:id} });
          let href = this.$router.resolve({ //使用resolve
            name:'topic_pic_detail',    //这里是跳转页面的name
            path: '/topic_pic_detail',
            query:{ id: id },
          })
          window.open(href.href, '_blank')
        },
        itemStyle(index){
          return [index%3===0 ? 'style_2' : ''];
        },
        
      },
})