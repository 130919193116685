<template>
	<div class="component-container">
		<div class="cart-wrapper" @click="toCart()">
			<span class="count">{{state.cartListCount}}</span>
			<i class="iconfont icon-gouwuche"></i>
			<span class="text">{{$t('cart1')}}</span>
		</div>
		<div ref="message" class="totop" @click="openMessage()">
			<i class="iconfont icon-zaixiankefu9 mykefu"></i>
			<span class="text">{{$t('onlineService')}}</span>
		</div>
		<div ref="totop" class="totop" @click="scrollToTop()">
			<i class="iconfont icon-fanhuidingbu"></i>
		</div>
		<div ref="tobottom" class="tobottom" @click="scrollToBottom()">
			<i class="iconfont icon-huidaodibu"></i>
		</div>
		
	</div>
</template>
<script setup>
	import { ref, provide, reactive, toRefs,
		     onBeforeMount,onMounted,onBeforeUpdate,onUpdated,onBeforeUnmount,
		     onUnmounted,onRenderTracked,onRenderTriggered,watch,computed, 
		     getCurrentInstance
		   } from 'vue';
	import { useRouter, useRoute } from 'vue-router';
    import { useStore } from 'vuex';
    import bus from 'vue3-eventbus'

    let {ctx, proxy} = getCurrentInstance();
    const router = useRouter();
	const route = useRoute();
	// console.log(route,"route-aal");
    const props = defineProps({
	    cartCount: {
			type: Number,
			default: 0
		},
		dom:{
			type: Object,
			default: null,
		}
	})
	const emits = defineEmits(["scrollto"])

	const totop = ref();
	 
	const scrollToTop = () => {
		emits("scrollto",'top');
	};
	const scrollToBottom = () => {
		emits("scrollto",'bottom');
	};
	//划动回到顶部
    const toTop = (scrollDiv) => {
        let scrollElem = scrollDiv.value;
        scrollElem.scrollTo({ top: 0, behavior: 'smooth' });
    };
    
	const onStepChange = (scrollDiv) =>{
	    scrollDiv.value.scrollIntoView({
	        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
	        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
	        inline: "center", // 定义水平滚动方向的对齐
	    });
	};

	const store = useStore();
	// console.log(store.state.user.cartList.length,"cart count");
	const isAuthenticated = proxy.$cookies.getAuthKey();
	let cartCount = 0;
	if(isAuthenticated){
		cartCount = store.state.user.cartList.length;
	}
	// 创建一个响应式的对象
	const state = reactive({
	    cartListCount: cartCount,
	    mobile: false,
	    siteInfo: store.state.common.siteInfo?store.state.common.siteInfo:{},
	});
	// console.log(store.state.user.cartList,"cartList");
	const count = ref(0)
	// 监听state.count的变化  
	watch(() => store.state.user.cartList.length, (newValue) => {
	    state.cartListCount = newValue;
	}
	// ,{
	// 	deep:true, //是否开启深度监听    
	// 	immediate: true, //是否立即调用一次 
	// }
	);
	
	onMounted(async () => {
		if(Object.keys(state.siteInfo).length<=0){
			store.dispatch("common/fetchSiteInfo").then((res) => {
	            state.siteInfo = res
	            console.log(res,"site-info");
	        }).catch(err => {
	            console.error(err)
	        })
		}
	  	
	});

	const toCart = () => {
		if(undefined === proxy.$cookies.getToken()){
			sessionStorage.setItem('redirectPath', route.fullPath);
			bus.emit('showLoginDialog', true)
			bus.emit('showLoginDialogMobile', false)
        	bus.emit('showLoginDialogQrcode', false)
        	bus.emit('showLoginDialogLogin', true)
		} else {
			router.push('/my/cart');
		}
	}

	const openMessage = function() {
		let width = '100%';
		if(document.documentElement.clientWidth > 768 && document.documentElement.clientWidth < 1080){
			width = '50%';
		}else if(document.documentElement.clientWidth >= 1080){
			width = '30%';
		}
	    layer.open({
	        type: 2,
	        title: "", // 映像青岛在线客服
	        offset: 'rb',
	        area: [width,'70%'],
	        content: state.siteInfo.kefu
	    })
	}

</script>
<style scoped>
	.component-container {
		position:fixed;
		top:50%;
		right:50px;
		transform:translateY(-50%);
		padding:10px;
		z-index:999;
		display:flex;
		flex-direction: column;

	}
	@media screen and (max-width: 890px) {
		.component-container {
			top: 90%;
			right: 5px;
			transform:translateY(-96%);
		}
	}
	@media screen and (max-width: 767px) {
		.component-container {
			top: 90%;
			right: 5px;
			transform:translateY(-96%);
		}
	}
	.cart-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 10px;
		color: white;
		background-color: rgba(215,0,15,1.0);
		/*background-color: rgba(0,49,97,1.0);*/
		position: relative;

	}
	.cart-wrapper:hover{
		cursor:pointer;
	}
	.cart-wrapper .count {
		position: absolute;
		top: -3px;
		right: -3px;
		width: 20px;
		height: 20px;
		/*padding:5px;*/
		border-radius: 10px;
		color: rgba(215,0,15,1.0);
		background-color: rgb(239,239,239); 
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.text {
		writing-mode:vertical-rl;
		padding-top:5px;
		font-size:14px;
		/*word-break: break-all;*/
	}
	@media screen and (max-width: 768px) {
		.text {
			display:none;
		}
	}
	.totop, .tobottom{
		margin-top:20px;
		background-color:rgb(239,239,239);
		display:flex;
		flex-direction: column;
		align-items: center;
		padding:5px 0;
	}
	.tobottom{
		margin-top:0px;
	}
	.totop:hover,.tobottom:hover{
		cursor:pointer;
	}
	.icon-fanhuidingbu, .icon-huidaodibu, .mykefu {
		display: flex;
		justify-content: center;
		align-items: center;
		padding:10px;
		font-weight: bold;

	}
	.kefu {
		margin-top:20px;
		background-color: rgba(215,0,15,1.0);
	}
	.mykefu {
		/*color:white;*/
		padding:10px 10px 2px 10px;
		font-size:24px;
		font-weight: normal;
	}
	
</style>