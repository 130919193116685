import { defineComponent, ref, provide, reactive, toRefs, getCurrentInstance, nextTick, onMounted, onUnmounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import {useStore} from 'vuex';
import PageLoading from '@/components/common/PageLoading.vue';
import Swiper from '@/components/layout/Swiper.vue';
import SearchBar2 from '@/components/layout/SearchBar2.vue';
import Header2 from '@/components/layout/header2.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';
import bus from 'vue3-eventbus';
import QRCode from 'qrcode'
import SideComponent from '@/components/common/SideComponent.vue';

export default defineComponent({
	components: {
		PageLoading,
		Header2,
		Menu,
	    Swiper,
	    SearchBar2,
	    Footer,
	    SideComponent,
	},
	props: {

	},
	setup() {
		let {ctx, proxy} = getCurrentInstance();
		const router = useRouter();
	    const route = useRoute();
	    
	    const selectedtype = route.query.selectedtype;
		const data = reactive({
			isLoading: true,
			showHeader: true,
			loginStyle: "min-width:141px;",
			mobileIconStyle: "color:rgba(223, 58, 8, 1);",
			showListIcon: true,		
      		pics:{},
      		orderInfo:{},
      // 		paytype:[
      // 			{
      // 				id: 1,
      // 				name:'账户余额支付',
      // 				iconClass:'icon-zhanghuyue6',
      // 				text:'账户可用余额 <span style="color:rgb(230, 0, 18);font-weight:bold;">0.00</span>元，余额不支付，您也可以 对公汇款 充值后在订单管理页进行支付',

      // 			},
      // 			{
      // 				id: 2,
      // 				name:'在线支付',
      // 				iconClass:'icon-zhanghuyue7',
      // 				text:'支持 微信 快捷支付',
      				
      // 			},
      // 		],
      // 		selectedPaytype:{
      // 			id: 2,
  				// name:'在线支付',
  				// iconClass:'icon-zhanghuyue7',
  				// text:'支持 微信 快捷支付',
      // 		},
      		qrCodeImgUrl:'',
      		order_id: 0,
      		payType:[
		    	{
		    		id: 2,
		    		title: proxy.$t('order_detail.text12'),
		    	},
		    	{
		    		id: 3,
		    		title: proxy.$t('order_detail.text13'),
		    	}
		    ],
		    checkedPaytypeId: 2,
		    payTypeName: proxy.$t('package.weixin'),
		});
		const oid = route.query.oid;
		data.order_id = route.query.oid;
		data.checkedPaytypeId = selectedtype;

		const showMenu = ref(proxy.$menus);
		provide('showMenu', showMenu);

		const outerStyleStr = ref("position:relative;left:10%;top:40%;transform:translate(-10%,-40%);width:auto;");
		provide('outerStyleStr', outerStyleStr);

		const styleStr = ref("height:45px;");
		provide('styleStr', styleStr);

		const showSearchText = ref(false);
		provide('showSearchText', showSearchText);

		const getImageUrl = (name) => {
		    return proxy.$utils.getImageUrl(name);
		};
		
		const store = useStore();

		bus.emit('needRefresh', true)
		let timerId = 0;
		const createQrcode = (order_id, checkedPaytypeId) =>{
			store.dispatch("common/getOrderInfo",{id: order_id}).then((res)=>{
				console.log(res,"info");			
	        	data.orderInfo = res;
	        	store.dispatch("user/getPay",{
					oid: data.orderInfo.id,
					ptype: checkedPaytypeId,
					otype: 1,
				}).then((res2)=>{	
					// console.log(res2,"pay");	
					if(checkedPaytypeId == 2){
						if(res2.return_msg=='OK'&&res2.return_code=='SUCCESS'&&res2.result_code=='SUCCESS'){
							console.log("weixin");
							// 使用toDataURL方法 返回的是promise对象 通过.then取值
						    let img = QRCode.toDataURL(res2.code_url).then(t => {
						      data.qrCodeImgUrl = t
						    })
						    timerId = setInterval(function(){
						    	store.dispatch("common/getOrderInfo",{id:order_id}).then((res)=>{
						    		if(res.status == 1){
										clearInterval(timerId);
										// router.push({name:'auth_records'});
										ElMessage({
									        message: "支付成功",
									        type: "success",
									        duration: 3000,
									    });
									    router.push({ path:'/my/contract',query:{order_id: order_id} });
									}
						    	});
						    },2000);
						    
						}
					}else if(checkedPaytypeId == 3){
						if(res2.msg=='Success'){
							console.log("Alipay");
							// 使用toDataURL方法 返回的是promise对象 通过.then取值
						    let img = QRCode.toDataURL(res2.code_url).then(t => {
						      data.qrCodeImgUrl = t
						    })
						    timerId = setInterval(function(){
						    	store.dispatch("common/getOrderInfo",{id:order_id}).then((res)=>{
						    		if(res.status == 1){
										clearInterval(timerId);
										// router.push({name:'auth_records'});
										ElMessage({
									        message: "支付成功",
									        type: "success",
									        duration: 3000,
									    });
									    router.push({ path:'/my/contract',query:{order_id: order_id} });
									}
						    	});
						    },2000);
						    
						}
					}
					bus.emit('showFooter',true);
					
				});
	        })
		}
		
        
	    onMounted(async()=>{
	    	if(data.checkedPaytypeId == 2){
				data.payTypeName = proxy.$t('package.weixin');
			}else if(data.checkedPaytypeId==3){
				data.payTypeName = proxy.$t('package.ali');
			}
	    	createQrcode(data.order_id, data.checkedPaytypeId);
	    	
        	await nextTick()
        	setTimeout(() => {
		      data.isLoading = false;
		    }, 600);
		    console.log("页面数据更新完毕了")
        })

        onUnmounted(() => {
        	clearInterval(timerId);
		    window.addEventListener("beforeunload", (e) => {
		    	store.commit('saveOrderParam',{})
		    	store.commit('saveOrderInfo', {})
		    });
		    window.addEventListener("unload", (e) => {
		    	store.commit('saveOrderParam',{})
		    	store.commit('saveOrderInfo', {})
		    });
		})

		const viewBox = ref(null);
        const scroll = (val) => {
			if(val == 'top'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		       
			}else if(val == 'bottom'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		        	
		        	
		        
			}
		}

		const refData = toRefs(data);

		return {
			...refData,
			getImageUrl,
			proxy,
			createQrcode,
			viewBox,
			scroll,
		}
	},
	created() {

	},
	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	},
	// watch:{
	// 	checkedPaytypeId:{
	// 		handler(value) {
	// 			if(value==2){
	// 				this.payTypeName = '微信';
	// 			}else if(value==3){
	// 				this.payTypeName = '支付宝';
	// 			}
				
	// 		}
	// 	}
	// },
	methods: {
		init() {
			
		},
		topay(orderInfo){
			let that = this;
			
			if(undefined === this.$cookies.getToken()){
				sessionStorage.setItem('redirectPath', this.$route.fullPath);
				bus.emit('showLoginDialog', true)
				bus.emit('showLoginDialogMobile', false)
        		bus.emit('showLoginDialogQrcode', true)
				// ElMessage({
			 //        message: "请先登录",
			 //        type: "warning",
			 //    });
			} else {
				this.$store.dispatch("user/getPay",{
					oid: orderInfo.id,
					ptype:this.selectedPaytype.id,
					otype:1,
				}).then((res)=>{
					console.log(res,"pay");
				});
			}
			
		},
		toPicDetail(id){
			this.$router.push({ name:'topic_pic_detail', query:{ id: id } });
		},
		selectPayType(item) {
			this.selectedPaytype = item;
		},
		activeIcon(name) {
			console.log(name,"name");
			return [name];
		},
	},
})
