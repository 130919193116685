import Cookies from "js-cookie";
 
//变量
const tokenKey = "token";
const usernameKey = "username";
const tokenTypeKey = 'tokenType';
const authKey = "isAuthenticated";
const mobileKey = "mobile";
//Qrcode变量
const urlKey = 'url';
const expireSecondsKey = 'expire_seconds';
const keyKey = 'key';
const ticketKey = 'ticket';
const qrcodeAuthKey = "isQrcodeAuthenticated";
const menuKey = "expand_menu";

//获取urlKey
export function getMenu() {
  return Cookies.get(menuKey);
}
 
//写入urlKey
export function setMenu(data) {
  return Cookies.set(menuKey, data, {expires: 1});
}
 
//删除urlKey
export function removeMenu() {
  return Cookies.remove(menuKey);
}

//获取urlKey
export function getUrl() {
  return Cookies.get(urlKey);
}
 
//写入urlKey
export function setUrl(data) {
  return Cookies.set(urlKey, data.url, {expires: data.expire_seconds/3600/24});
}
 
//删除urlKey
export function removeUrl() {
  return Cookies.remove(urlKey);
}

//获取ExpireSeconds
export function getExpireSeconds() {
  return Cookies.get(expireSecondsKey);
}
 
//写入ExpireSeconds
export function setExpireSeconds(data) {
  return Cookies.set(expireSecondsKey, data.expire_seconds, {expires: data.expire_seconds/3600/24});
}
 
//删除ExpireSeconds
export function removeExpireSeconds() {
  return Cookies.remove(expireSecondsKey);
}

//获取keyKey
export function getKey() {
  return Cookies.get(keyKey);
}
 
//写入keyKey
export function setKey(data) {
  return Cookies.set(keyKey, data.key, {expires: data.expire_seconds/3600/24});
}
 
//删除keyKey
export function removeKey() {
  return Cookies.remove(keyKey);
}

//获取TicketKey
export function getTicketKey() {
  return Cookies.get(ticketKey);
}
 
//写入TicketKey
export function setTicketKey(data) {
  return Cookies.set(ticketKey, data.ticket, {expires: data.expire_seconds/3600/24});
}
 
//删除keyKey
export function removeTicketKey() {
  return Cookies.remove(ticketKey);
}


//获取 qrcodeAuthKey
export function getQrcodeLoginAuthKey() {
  return Cookies.get(qrcodeAuthKey);
}
//写入 qrcodeAuthKey
export function setQrcodeLoginAuthKey() {
  return Cookies.set(qrcodeAuthKey, data.ticket, {expires: data.expire_seconds/3600/24});
}
//删除 qrcodeAuthKey
export function removeQrcodeLoginAuthKey() {
  return Cookies.remove(qrcodeAuthKey);
}

//获取token
export function getToken() {
  return Cookies.get(tokenKey);
}
 
//写入token
export function setToken(data) {
  return Cookies.set(tokenKey, data.access_token, {expires: data.expires_in/3600/24});
  // return Cookies.set(tokenKey, data.access_token, {expires: 300/3600/24});
}
 
//删除token
export function removeToken() {
  return Cookies.remove(tokenKey);
}
 
//写入 username
export function setUsername(data) {
  return Cookies.set(usernameKey, data.username, {expires: data.expires_in/3600/24});
  // return Cookies.set(usernameKey, data.username, {expires: 300/3600/24});
}
 
//获取 username
export function getUsername() {
  return Cookies.get(usernameKey);
}
 
//删除 username
export function removeUsername() {
  return Cookies.remove(usernameKey);
}

//写入 mobile
export function setMobile(data) {
  return Cookies.set(mobileKey, data.mobile, {expires: data.expires_in/3600/24});
  // return Cookies.set(mobileKey, data.mobile, {expires: 300/3600/24});
}
 
//获取 mobile
export function getMobile() {
  return Cookies.get(mobileKey);
}
 
//删除 mobile
export function removeMobile() {
  return Cookies.remove(mobileKey);
}

//写入 isAuthenticated
export function setAuthKey(value,data) {
  return Cookies.set(authKey, value, {expires: data.expires_in/3600/24});
  // return Cookies.set(authKey, value, {expires: 300/3600/24});
}
 
//获取 isAuthenticated
export function getAuthKey() {
  return Cookies.get(authKey);
}
 
//删除 isAuthenticated
export function removeAuthKey() {
  return Cookies.remove(authKey);
}

//写入 tokenType
export function setTokentype(data) {
  return Cookies.set(tokenTypeKey, data.token_type, {expires: data.expires_in/3600/24});
  // return Cookies.set(tokenTypeKey, data.token_type, {expires: 300/3600/24});
}
 
//获取 tokenType
export function getTokentype() {
  return Cookies.get(tokenTypeKey);
}
 
//删除 tokenType
export function removeTokentype() {
  return Cookies.remove(tokenTypeKey);
}