<template>
	<PageLoading :is-loading="isLoading"></PageLoading>
	<div class="container">
		<Header2 :show-search-bar-pic="showHeader" login-style="loginStyle" :mobile-icon-style="mobileIconStyle" :show-list-icon="showListIcon"/>
		<div class="content">
			<div style="height:100px;"></div>			
			<div class="content-outer">
				<div class="left">
					<div class="outer">						
						<img :src="detail.thumb" oncontextmenu="return false" />
						<div class="name"><p>{{detail.name}}</p></div>
						<div class="desc">{{detail.description}}</div>	
					</div>
				</div>	
				<div class="right">
					<div class="wrapper">
						<section class="section">
							<div class="child" v-for="item in albums" @click="toDetail(item.id)">
								<img :src="item.thumb" :alt="item.title" oncontextmenu="return false" />
								<!-- <span class="title">轮渡</span> -->
								<div class="title">
									<span>{{item.title}}</span>
								</div>
							</div>
							<!-- <div class="child" >
								<img src="@/assets/img/photo/4.jpg" alt="" />
								<div class="title">
									<span>轮渡</span>
								</div>
							</div>
							<div class="child" >
								<img src="@/assets/img/photo/5.jpg" alt="" />
								<div class="title">
									<span>轮渡</span>
								</div>
							</div> -->
							<!-- <div class="child" >
								<img src="@/assets/img/photo/6.jpg" alt="" />
								<div class="title">
									<span>轮渡</span>
								</div>
							</div>
							<div class="child" >
								<img src="@/assets/img/photo/7.jpg" alt="" />
								<div class="title">
									<span>轮渡</span>
								</div>
							</div>
							<div class="child" >
								<img src="@/assets/img/photo/8.jpg" alt="" />
								<div class="title">
									<span>轮渡</span>
								</div>
							</div>
							<div class="child" >
								<img src="@/assets/img/photo/9.jpg" alt="" />
								<div class="title">
									<span>轮渡</span>
								</div>
							</div>
							<div class="child" >
								<img src="@/assets/img/photo/9.jpg" alt="" />
								<div class="title">
									<span>轮渡</span>
								</div>
							</div> -->
						</section>
						<!-- <div class="child" >
							<img src="@/assets/img/photo/5.jpg" alt="" />
							<span class="title">轮渡</span>
						</div> -->
						<!-- <div class="team1" v-if="!mobile">
							<div class="item style_1" v-for="item in picWorks.team1" @click="toDetail(item.id)">
								<img :src="item.preview" alt="" />
							</div>
						</div>
						<div class="team2" v-if="!mobile">
							<div class="item" v-for="(item,index) in picWorks.team2" :class="itemStyle(index)"  @click="toDetail(item.id)">
								<img :src="item.preview" alt="" oncontextmenu="return false" />
							</div>
							
						</div>

						<div class="team1" v-if="mobile">
							<div class="item style_1" v-for="item in teams" @click="toDetail(item.id)">
								<img :src="item.preview" alt="" oncontextmenu="return false" />
							</div>
						</div> -->

					</div>
					
				</div>	
			</div>

		</div>
		<Footer/>
	</div>
</template>

<script>
	import photo_album from './photo_grapher_album_controller';
	export default photo_album;
</script>

<style src="@/assets/css/photo_grapher_album.css" scoped></style>