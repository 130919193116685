import { nextTick,defineComponent, ref, provide, reactive, toRefs, getCurrentInstance, onMounted, onBeforeUnmount, onBeforeUpdate } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import PageLoading from '@/components/common/PageLoading.vue';
import HeaderVR from '@/components/layout/header_vr.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';
import Swiper from '@/components/layout/Swiper.vue';
import bus from 'vue3-eventbus'
import SideComponent from '@/components/common/SideComponent.vue';
import Loading from '@/components/common/Loading.vue';

export default defineComponent({
	components: {
		HeaderVR,
		Menu,
	    Footer,
	    PageLoading,
	    Swiper,
	    SideComponent,
	},
	props: {

	},
	setup() {
		provide('logoColor', 'black');
		let {ctx, proxy} = getCurrentInstance();
		console.log(proxy,"proxy");
		const router = useRouter();
	    const route = useRoute();
	    const id = route.query.id;
	    const type = route.query.type;
		const data = reactive({
			isLoading:true,
			showHeader: true,
			loginStyle: "min-width:141px;",
			mobileIconStyle: "color:rgba(223, 58, 8, 1);",
			showListIcon: true,
			imageSrc: '',
			//定义一个imageNew变量来接收裁剪之后的图片
			imageNew: '',		
      		cropperOption: {
		        img: "",
		        size: 1,
		        full: false,
		        outputType: "png",
		        canMove: false,
		        fixedBox: false,
		        original: false,
		        canMoveBox: true,
		        autoCrop: true,
		        // 只有自动截图开启 宽度高度才生效
		        autoCropWidth: 160,
		        autoCropHeight: 150,
		        centerBox: true,
		        high: true,
		        max: 99999,
		        fixed: true,
		        fixedNumber: [1, 2],
		    },
		    newslist:[],
		    banner:[
		    	
		    ],
		    type: 1,
		    id: 0,
		    arDetail:{},
		    pagination: {},
		    page: 1,
		    isListLoading: true,
		    nomore:true,
		    column: 3,
		    heightArr: [],
		    imgWidth: 387,
		    imgMargin: 5,
		    minHeight: [],
          	arr: [],
          	maxHeight:1080,
          	typeStr:'img',
          	showHeader:false,
		});
		
		const showMenus = ref(proxy.$menus);
		provide('showMenu', showMenus);
		provide('menuTextStyle', {color:'#fff'});
		provide('showHeader',false);
		
		data.id = id;
		data.type = type;

		

		const getImageUrl = (name) => {
		    return proxy.$utils.getImageUrl(name);
		};

		const store = useStore();
		const getArticleList=(id=0,page=1)=>{
			store.dispatch("common/ArDetail",{
				id: id,
				page: page,
	        }).then((res) => {
	        	console.log(res,"article res");
	        	if(res.sliders.length>0){
	        		data.banner = res.sliders.map(item=>{
		        		let obj = {};
		        		obj.thumb = item;
		        		item = obj;
		        		return item;
		        	})
		        	console.log(data.banner,"data.banner");
	        	}

		        if(res.type == 1){
		        	data.arDetail = res;
		        }else if(res.type == 2){
		        	let list = res.list.data;
	        		
	        		if(list.length > 0){
		        		if(page == 1){
		        			data.newslist = list;
		        		}else{
		        			data.newslist = data.newslist.concat(list);
		        		}
						
						if(list.length < res.list.pagination.pageSize){
							data.nomore = true;
						}else{
							data.nomore = false;
						}
					}else{
						data.nomore = true;
					}        
		        	// data.pagination = data.videos.pagination;
		        	
		        	data.pagination = res.list.pagination;
		        	data.total = res.list.pagination.total;
		        	if(!data.nomore){
		        		data.page = page+1;
		        	}
		        	// data.page += 1;
		        	
		        }	        	
	        	data.isListLoading = false;
	        	bus.emit('showFooter',true);
				setTimeout(()=>{
					let outer = document.getElementById("html-content");
					if(outer!=null){
					  	if(document.documentElement.clientWidth < 768){
					  		// if(outer.getElementsByTagName("img")!=null){
							let tags = outer.getElementsByTagName("img");
							if(tags.length > 0){
								console.log(tags,"tags");
								for (var i = 0; i<tags.length; i++) {
									if(tags[i].width > 375)	{			        
								        tags[i].style.width = '100%'; 
								        tags[i].style.height = 'auto';    
								        tags[i].width = '100%'; 
								        tags[i].height = 'auto';
								        tags[i].parentNode.style.textIndent = '0rem';
								        tags[i].style.margin = '0 auto';
								    }
							    }
							}

						    let videos = outer.getElementsByTagName("video");
							console.log(videos,"videos");
							for (var i = 0; i<videos.length; i++) {	
								if(videos[i].width > 375)	{		        
							        videos[i].style.width = '100%'; 
							        videos[i].style.height = 'auto';    
							        videos[i].width = '100%'; 
							        videos[i].height = 'auto';
							        videos[i].parentNode.style.textIndent = '0rem';
							        videos[i].style.margin = '0 auto';
							    }
						    }
							
						}else{
							console.log(outer,'outer');
							// if(outer!=null){
							// if(outer.getElementsByTagName("img")!=null){
							let tags = outer.getElementsByTagName("img");
							if(tags.length>0){
								console.log(tags,"tags");
								for (var i = 0; i<tags.length; i++) {	
									if(tags[i].width > 800)	{
										 tags[i].style.maxWidth = '800px'; 
								        tags[i].style.width = 'auto';
								        tags[i].style.height = 'auto';
								        tags[i].width = 'auto'; 
								        tags[i].height = 'auto';
								        tags[i].parentNode.style.textIndent = '0rem';
								        tags[i].style.margin = '0 auto';
									}	        					        
							    }
							}

						    let videos = outer.getElementsByTagName("video");
							console.log(videos,"videos");
							for (var i = 0; i<videos.length; i++) {	
								if(videos[i].width > 800)	{		        
							        videos[i].style.maxWidth = '800px'; 
							        videos[i].style.width = 'auto';
							        videos[i].style.height = 'auto';
							        videos[i].width = 'auto'; 
							        videos[i].height = 'auto';
							        videos[i].parentNode.style.textIndent = '0rem';
							        videos[i].style.margin = '0 auto';
							    }
						        
						    }
						}
					}
				},200);
				
	        })
		}
		
		const viewBox = ref(null);
        const scroll = (val) => {
			if(val == 'top'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		       
			}else if(val == 'bottom'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
    
			}
		}
		// const getimg = () => {
		// 	return new Promise(function (resolve, reject) {
		//     	const imgList = document.getElementsByClassName("item");
		//     	resolve(imgList);
		//     })
		// }
		// const handleData = (imgList,heightArr) => {
		// 	return new Promise(function(resolve,reject){
		// 		console.log(imgList,"imgList");
		//     	console.log(imgList.length,"imgList length");
		//     	for (let i = 0; i < imgList.length; i++) {
		// 	        const item = imgList[i];
		// 	        // 当前元素的高度
		// 	        const itemHeight = item.offsetHeight;
		// 	        // 高度数组最小的高度
		// 	        const minHeight = Math.min(...heightArr);
		// 	        // 高度数组最小的高度的索引
		// 	        const minIndex = heightArr.indexOf(minHeight);
		// 	        item.style.top = minHeight + "px";
		// 	        item.style.left = minIndex * imgWidth + "px";
		// 	        heightArr[minIndex] += itemHeight;
		// 	    }
		// 	    resolve();
		// 	});
		// }

		const waterfallHandler = async () => {
			await nextTick(()=>{
			    // const imgWidth = data.imgWidth + data.imgMargin * 2;
			    const contentW = document.getElementById("arlist").offsetWidth;
			    
			    setTimeout(()=>{
				    const imgList = document.getElementsByClassName("item");
				    if(imgList.length > 0){
				    	const imgWidth = imgList[0].offsetWidth - 5;
				    
					    // 获取图片的列数
					    const column = parseInt(contentW / imgWidth);
					    
					    // 高度数组
					    const heightArr = new Array(column).fill(0);
					    
					    for (let i = 0; i < imgList.length; i++) {
					        const item = imgList[i];
					        
					        // 当前元素的高度
					        const itemHeight = item.clientHeight - 5;
					        
					        // 高度数组最小的高度
					        const minHeight = Math.min(...heightArr);
					        // 高度数组最小的高度的索引
					        const minIndex = heightArr.indexOf(minHeight);
					        item.style.position = "absolute";
					        item.style.top = minHeight + "px";
					        item.style.left = minIndex * imgWidth + "px";
					        data.maxHeight = Math.max(...heightArr);
					        heightArr[minIndex] += itemHeight;
					        
					    }
				    }
				    
				},200);
			})
		}
		const divs = ref([]);

	    // Make sure to reset the refs before each update.
	    onBeforeUpdate(() => {
	      	// divs.value = [];
	      	// console.log(divs.value.length,"divs value");
	      	if(data.type == 2 && document.documentElement.clientWidth > 768){
		  	    waterfallHandler();
		  	}
		  	let outer = document.getElementById("html-content");
			if(outer!=null){
			  	if(document.documentElement.clientWidth < 768){
			  		// if(outer.getElementsByTagName("img")!=null){
					let tags = outer.getElementsByTagName("img");
					if(tags.length > 0){
						console.log(tags,"tags");
						for (var i = 0; i<tags.length; i++) {
							if(tags[i].width > 375)	{			        
						        tags[i].style.width = '100%'; 
						        tags[i].style.height = 'auto';    
						        tags[i].width = '100%'; 
						        tags[i].height = 'auto';
						        tags[i].parentNode.style.textIndent = '0rem';
						        tags[i].style.margin = '0 auto';
						    }
					    }
					}

				    let videos = outer.getElementsByTagName("video");
					console.log(videos,"videos");
					for (var i = 0; i<videos.length; i++) {	
						if(videos[i].width > 375)	{		        
					        videos[i].style.width = '100%'; 
					        videos[i].style.height = 'auto';    
					        videos[i].width = '100%'; 
					        videos[i].height = 'auto';
					        videos[i].parentNode.style.textIndent = '0rem';
					        videos[i].style.margin = '0 auto';
					    }
				    }
					
				}else{
					console.log(outer,'outer');
					// if(outer!=null){
					// if(outer.getElementsByTagName("img")!=null){
					let tags = outer.getElementsByTagName("img");
					if(tags.length>0){
						console.log(tags,"tags");
						for (var i = 0; i<tags.length; i++) {	
							if(tags[i].width > 800)	{
								tags[i].style.maxWidth = '800px'; 
						        tags[i].style.width = 'auto';
						        tags[i].style.height = 'auto';
						        tags[i].width = 'auto'; 
						        tags[i].height = 'auto';
						        tags[i].parentNode.style.textIndent = '0rem';
						        tags[i].style.margin = '0 auto';
							}	        					        
					    }
					}

				    let videos = outer.getElementsByTagName("video");
					console.log(videos,"videos");
					for (var i = 0; i<videos.length; i++) {	
						if(videos[i].width > 800)	{		        
					        videos[i].style.maxWidth = '800px'; 
					        videos[i].style.width = 'auto';
					        videos[i].style.height = 'auto';
					        videos[i].width = 'auto'; 
					        videos[i].height = 'auto';
					        videos[i].parentNode.style.textIndent = '0rem';
					        videos[i].style.margin = '0 auto';
					    }
				        
				    }
				}
			}
	    });


	    onMounted(async()=>{
	    	// data.banner = store.state.common.siteInfo.ad_sliders;
	    	getArticleList(data.id)

	    	// console.log(proxy.$refs,"divs");
	    	await nextTick(()=>{
	    		if(data.type == 2 && document.documentElement.clientWidth >= 768){
	    			if(document.getElementById("footer") != null)
		    		document.getElementById("footer").style.top = (document.getElementsByTagName("body")[0].offsetHeight - document.getElementById("footer").offsetHeight*1.5)+'px';
		    		
	    		}else if(data.type == 2 && document.documentElement.clientWidth < 768){
	    			// document.getElementById("loadmore").style.position = "absolute";	  
	    			console.log(data.maxHeight,'data.maxHeight');  	
	    			if(document.getElementById("content")!= null && document.getElementById("footer")!=null)	
	    			document.getElementById("content").style.height = (data.maxHeight + document.getElementById("footer").offsetHeight*3.2)+'px';
		    		// document.getElementById("loadmore").style.top = (data.maxHeight + document.getElementById("footer").offsetHeight*2)+'px';
		    		// document.getElementById("footer").style.top = (document.getElementsByTagName("body")[0].offsetHeight - document.getElementById("footer").offsetHeight*1.5)+'px';
	    		}
	    	});
        	
        	let timer = setTimeout(() => {
		        data.isLoading = false;
		   		// if(data.type == 2 && document.documentElement.clientWidth > 768){
			    //     waterfallHandler();
			    // }
			    viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		      clearTimeout(timer);
		    }, 500);
		    console.log("页面数据更新完毕了")
        })

		// onMounted(() => {
			// console.log(proxy.$router === router) // true
	  //     	console.log(route) // {path, params, query...}

		    // // 访问组件的 DOM 元素
		    //   element.value = ctx.$el

		    //   // 发送网络请求
		    //   axios.get('/api/data').then(response => {
		    //     ctx.data = response.data
		    //   })

		    //   // 监听数据变化
		    //   ctx.$watch('data', () => {
		    //     console.log('data changed')
		    //   })

		    //   // 在组件销毁时取消异步操作
		    //   ctx.$once('hook:beforeUnmount', () => {
		    //     cancelAsyncTask()
		    //   })
		    
		// });

		const refData = toRefs(data);

		return {
			...refData,
			getImageUrl,
			viewBox,
			scroll,
			getArticleList,
			waterfallHandler,
			divs,
			
		}
	},
	created() {

	},
	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	},
	methods: {
		init() {
			
			let outer = document.getElementById("html-content");
			
			if(outer!=null){
				// let spantags = outer.getElementsByTagName("span");
				// if(spantags.length > 0){
				// 	console.log(spantags,"spantags");
				// 	for (var i = 0; i<spantags.length; i++) {
				// 		spantags[i].style.textAlign = 'justify';
				//     }
				// }
			  	if(document.documentElement.clientWidth < 768){
			  		// if(outer.getElementsByTagName("img")!=null){
					let tags = outer.getElementsByTagName("img");
					if(tags.length > 0){
						console.log(tags,"tags");
						for (var i = 0; i<tags.length; i++) {
							if(tags[i].width > 375)	{			        
						        tags[i].style.width = '100%'; 
						        tags[i].style.height = 'auto';    
						        tags[i].width = '100%'; 
						        tags[i].height = 'auto';
						        tags[i].parentNode.style.textIndent = '0rem';
						        tags[i].style.margin = '0 auto';
						    }
					    }
					}

				    let videos = outer.getElementsByTagName("video");
					console.log(videos,"videos");
					for (var i = 0; i<videos.length; i++) {	
						if(videos[i].width > 375)	{		        
					        videos[i].style.width = '100%'; 
					        videos[i].style.height = 'auto';    
					        videos[i].width = '100%'; 
					        videos[i].height = 'auto';
					        videos[i].parentNode.style.textIndent = '0rem';
					        videos[i].style.margin = '0 auto';
					    }
				    }
					
				}else{
					console.log(outer,'outer');
					// if(outer!=null){
					// if(outer.getElementsByTagName("img")!=null){
					let tags = outer.getElementsByTagName("img");
					if(tags.length>0){
						console.log(tags,"tags");
						for (var i = 0; i<tags.length; i++) {	
							if(tags[i].width > 800)	{
								 tags[i].style.maxWidth = '800px'; 
						        tags[i].style.width = 'auto';
						        tags[i].style.height = 'auto';
						        tags[i].width = 'auto'; 
						        tags[i].height = 'auto';
						        tags[i].parentNode.style.textIndent = '0rem';
						        tags[i].style.margin = '0 auto';
							}	        					        
					    }
					}

				    let videos = outer.getElementsByTagName("video");
					console.log(videos,"videos");
					for (var i = 0; i<videos.length; i++) {	
						if(videos[i].width > 800)	{		        
					        videos[i].style.maxWidth = '800px'; 
					        videos[i].style.width = 'auto';
					        videos[i].style.height = 'auto';
					        videos[i].width = 'auto'; 
					        videos[i].height = 'auto';
					        videos[i].parentNode.style.textIndent = '0rem';
					        videos[i].style.margin = '0 auto';
					    }
				        
				    }
				}
			}

	        window.addEventListener('resize', function(event){
				// let outer1 = document.getElementById("html-content");
				// if(outer!=null){
				//   	if(document.documentElement.clientWidth < 768){

				// 		let tags = outer.getElementsByTagName("img");
				// 		if(tags.length>0){
				// 			console.log(tags,"tags");
				// 			for (var i = 0; i<tags.length; i++) {			        
				// 		        tags[i].style.width = '100%'; 
				// 		        tags[i].style.height = 'auto';    
				// 		        tags[i].width = '100%'; 
				// 		        tags[i].height = 'auto';
				// 		        tags[i].parentNode.style.textIndent = '0rem';
				// 		        tags[i].style.margin = '0 auto';
				// 		    }
				// 		}

				// 	    let videos = outer.getElementsByTagName("video");
				// 		console.log(videos,"videos");
				// 		for (var i = 0; i<videos.length; i++) {			        
				// 	        videos[i].style.width = '100%'; 
				// 	        videos[i].style.height = 'auto';    
				// 	        videos[i].width = '100%'; 
				// 	        videos[i].height = 'auto';
				// 	        videos[i].parentNode.style.textIndent = '0rem';
				// 	        videos[i].style.margin = '0 auto';
				// 	    }
						
				// 	}else{
						
				// 	    let tags = outer.getElementsByTagName("img");
				// 		console.log(tags,"tags");
				// 		for (var i = 0; i<tags.length; i++) {			        
				// 	        tags[i].style.maxWidth = '800px'; 
				// 	        tags[i].style.width = 'auto';
				// 	        tags[i].style.height = 'auto';
				// 	        tags[i].width = 'auto'; 
				// 	        tags[i].height = 'auto';
				// 	        tags[i].parentNode.style.textIndent = '0rem';
				// 	        tags[i].style.margin = '0 auto';
					        
				// 	    }

				// 	    let videos = outer.getElementsByTagName("video");
				// 		console.log(videos,"videos");
				// 		for (var i = 0; i<videos.length; i++) {			        
				// 	        videos[i].style.maxWidth = '800px'; 
				// 	        videos[i].style.width = 'auto';
				// 	        videos[i].style.height = 'auto';
				// 	        videos[i].width = 'auto'; 
				// 	        videos[i].height = 'auto';
				// 	        videos[i].parentNode.style.textIndent = '0rem';
				// 	        videos[i].style.margin = '0 auto';
					        
				// 	    }
				// 	}
				// }
				
			});
			
		},
		createImg() {
			
	        const ul = document.getElementById("j-water-fall-content");
	        let trueWidth = Math.floor(
	            (100 - this.column * this.imgMargin * 2) / this.column
	        );
	        
	        for (let i = 0; i < this.column; i++) {
	            let li = document.createElement("li");
	            li.style.listStyle = "none";
	            li.style.float = "left";
	            li.style.width = `${trueWidth}%`;
	            li.style.margin = `0 ${this.imgMargin}%`;
	            ul.appendChild(li);
	            this.arr.push(li);
	            this.minHeight.push(0);
	        }
	        let img = new Image();
	        img.num = 0;
	        img.src = this.newslist[img.num].thumb;
	        img.style.width = "100%";
	        // 当图片加载完后
	        img.onload = this.loadHandler;
	    },
	    loadHandler(that) {
	        const img = that.path[0];
	        const minHeight = this.minHeight;
	        const arr = this.arr;
	        // 高度数组的最小值
	        const min = Math.min.apply(null, minHeight);
	        // 高度数组的最小值索引
	        const minIndex = minHeight.indexOf(min);
	        // 克隆一份图片
	        const im = img.cloneNode(true);
	        // 将图片假如对应最小值索引的容器中
	        arr[minIndex].appendChild(im);
	        // 更新最小值索引的容器的高度
	        minHeight[minIndex] += im.height;
	        img.num++;
	        img.src = this.newslist[img.num].thumb;
	    },
		
		
		loadMore() {
			this.getArticleList(this.id,this.page);
		},
		toDetail(id){
			this.$router.push({name:'article_detail',query: {id: id}});
		},
		realTime(data) {
			console.log(data,"data");
  			var previews = data;
  			this.previews = data
  		},
  		tovr(url) {
	        // window.location.href = url;
	        window.open(url,"_blank")
	    },
	},
})