import { defineComponent, ref, provide,inject, reactive, toRefs, getCurrentInstance, onMounted,nextTick, onBeforeUnmount } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import PageLoading from '@/components/common/PageLoading.vue';
import Swiper from '@/components/layout/Swiper.vue';
import SearchBar2 from '@/components/layout/SearchBar2.vue';
import Header2 from '@/components/layout/header2.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';
// import Login2 from '@/components/common/login2.vue';
import { VueCropper }  from "vue-cropper";
// import SideComponent from '@/components/common/SideComponent.vue';
// import CropperImg from "@/components/common/CropperImg.vue";
// import 'vue-cropper/dist/index.css'
import bus from 'vue3-eventbus'
import SideComponent from '@/components/common/SideComponent.vue';
import Loading from '@/components/common/Loading.vue';

export default defineComponent({
	components: {
		PageLoading,
		Header2,
		Menu,
	    Swiper,
	    SearchBar2,
	    Footer,
	    VueCropper,
	    SideComponent,
	    Loading,
	},
	props: {

	},
	inject: ['showLogin'],
	setup() {
		let {ctx, proxy} = getCurrentInstance();
		const router = useRouter();
	    const route = useRoute();
	    // console.log(proxy.$cookies.getToken(),"getToken");
		const data = reactive({
			showDialog: false,
			isLoading:true,
			showSearchbar: false,
			loginStyle: "min-width:141px;",
			mobileIconStyle: "color:rgba(223, 58, 8, 1);",
			showListIcon: true,
			imageSrc: '',
			//定义一个imageNew变量来接收裁剪之后的图片
			imageNew: '',
			pics: [
      			
      		],
      		cropperOption: {
		        img: "",
		        size: 1,
		        full: false,
		        outputType: "png",
		        canMove: false,
		        fixedBox: false,
		        original: false,
		        canMoveBox: true,
		        autoCrop: true,
		        // 只有自动截图开启 宽度高度才生效
		        autoCropWidth: 160,
		        autoCropHeight: 150,
		        centerBox: true,
		        high: true,
		        max: 99999,
		        fixed: true,
		        fixedNumber: [1, 2],
		    },
		    picDetail:{
		    	subject:{}
		    },
		    page:1,
      		nomore:true,
      		checkedItem: 0,
      		isChecked: true,
      		disabled: false,
      		isPicLoading: true,
		});
		const showMenu = ref(proxy.$menus);
		provide('showMenu', showMenu);

		const outerStyleStr = ref("position:relative;left:10%;top:40%;transform:translate(-10%,-40%);width:auto;");
		provide('outerStyleStr', outerStyleStr);

		const styleStr = ref("height:45px;");
		provide('styleStr', styleStr);

		const showSearchText = ref(false);
		provide('showSearchText', showSearchText);

		const getImageUrl = (name) => {
		    return proxy.$utils.getImageUrl(name);
		};

		//点击裁剪按钮
		const updateImageSrc = (updateImageSrc) => {
		  	imageNew.value = updateImageSrc
		}

		const childClick = (value) => {
			data.showDialog = value.value;
		}
		const toLogin = () => {
			data.showDialog = true;
		}

	    const store = useStore();
     	const id = route.query.id;
     	const getPicVideoDetail = (id=0) =>{
     		store.dispatch("common/getPicVideoDetail",{
	        	id: id,
	        }).then((res) => {
	        	// console.log(res,"pic");
	        	data.picDetail = res
	        	data.picDetail.size = proxy.$utils.round(data.picDetail.size /1024/1024, 2);
	        	bus.emit('showFooter',true);
	        })
     	}
	    
     	const getSimilarPicDetail = (id=0) =>{
	        store.dispatch("common/getSimilarPicDetail",{
	        	id: id,
	        }).then((res) => {
	        	// console.log(res,"similar");
	        	// res.map((item)=>{
	        	// 	item.size = Math.round(item.size / 1024);
	        	// 	return item;
	        	// });
	        	data.pics = res;
	        	data.isPicLoading = false;
	        }).catch(err=>{
	        	data.isPicLoading = false;
	        })
	    }

	    const viewBox = ref(null);
        const scroll = (val) => {
			if(val == 'top'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		       
			}else if(val == 'bottom'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		        	
		        	
		        
			}
		}

        onMounted(async()=>{
        	getPicVideoDetail(id);
        	getSimilarPicDetail(id);
        	await nextTick()
        	setTimeout(() => {
		        data.isLoading = false;
		        viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		    }, 600);
		    console.log("页面数据更新完毕了")
        })


		const refData = toRefs(data);

		return {
			...refData,
			getImageUrl,
			updateImageSrc,
			proxy,
			childClick,
			toLogin,
			viewBox,
			scroll,
		}
	},
	created() {

	},
	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	},
	watch: {
		checkedItem: {
			handler(value){
				console.log(value,"checkedItem-1");
				// if(value.length > 1){
				// 	ElMessage({
				//         message: '只能选择一项',
				//         type: "warning",
				//     });
				// }else if(value.length <= 0){
				// 	ElMessage({
				//         message: '请选择图片价格',
				//         type: "warning",
				//     });
				// }
			}
		}
	},
	methods: {
		init() {
			// console.log(this.$route,'this.$route')
		},
		activeStyle(item) {
			let object = {width:0};
			object.width = item.ratio_w*160/item.ratio_h+'px';
			
			return object;
		},
		loadMore() {
			// this.getPicList(this.keyword,this.page);
		},
		addCart(imgid) {
			let that = this;
			if(undefined === this.proxy.$cookies.getToken()){
				sessionStorage.setItem('redirectPath', this.$route.fullPath);
				// this.toLogin();
				bus.emit('showLoginDialog', true)
				bus.emit('showLoginDialogMobile', false)
        		bus.emit('showLoginDialogQrcode', true)
			} else {
				this.disabled = true;
				// console.log(this.checkedItem,"checkedItem");
				// if(this.checkedItem.length == 1){
					// bus.emit('needRefresh', true)
					this.$store.dispatch("user/addCart",{
						image_id: imgid,
						option_id: [this.checkedItem],
					}).then((data)=>{
						// console.log(data,"data");
						this.$store.dispatch("user/getCartList",{}).then((data)=>{
							// console.log(data,"cart list");
						});
						this.disabled = false;
						ElMessage({
					        message: '已添加购物车',
					        type: "success",
					    });
					}).catch((err)=>{
						console.log("err",err)
					});
				// }else if(this.checkedItem.length > 1){
				// 	ElMessage({
				//         message: '只能选择一项',
				//         type: "warning",
				//     });
				// }else if(this.checkedItem.length == 0){
				// 	ElMessage({
				//         message: '请选择图片价格',
				//         type: "warning",
				//     });
				// }
				
			}
		},
		topay(imgid, option) {
			if(undefined === this.proxy.$cookies.getToken()){
				sessionStorage.setItem('redirectPath', this.$route.fullPath);
				// this.toLogin();
				bus.emit('showLoginDialog', true)
				bus.emit('showLoginDialogMobile', false)
        		bus.emit('showLoginDialogQrcode', true)
			} else {
				this.disabled = true;
				let options = [];
				option.forEach((item,index)=>{
					options.push(index);
				})
				// if(this.checkedItem.length == 1){
					this.$store.dispatch("common/saveOrderParam",{
						source:1,
						images:[{
							image_id: imgid,
							option: [this.checkedItem],//options,
						}],
						imgList:[this.picDetail]
					}).then(()=>{
						this.disabled = false;
						this.$router.push({ path:'/my/order_pay', query:{from:'detail'} });
					});

				// }else if(this.checkedItem.length > 1){
				// 	ElMessage({
				//         message: '只能选择一项',
				//         type: "warning",
				//     });
				// }else if(this.checkedItem.length == 0){
				// 	ElMessage({
				//         message: '请选择图片价格',
				//         type: "warning",
				//     });
				// }

			}
		
		},
		detail(id) {
			let that = this;
			this.$store.dispatch("common/getPicVideoDetail",{
	        	id: id,
	        }).then((res) => {
	        	console.log(res,"pic");
	        	that.picDetail = res
	        	that.picDetail.size = Math.round(that.picDetail.size / 1024);
	        })
		},
		realTime(data) {
			// console.log(data,"data");
  			var previews = data;
  			this.previews = data
  		},
	},
})