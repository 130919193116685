import { onBeforeMount, onMounted, ref, provide, reactive, toRefs, nextTick, onBeforeUpdate,getCurrentInstance } from 'vue';
import { useStore, createNamespacedHelpers } from "vuex";
import PageLoading from '@/components/common/PageLoading.vue';
import Swiper from '@/components/layout/Swiper.vue';
import SearchBar2 from '@/components/layout/SearchBar2.vue';
import Header2 from '@/components/layout/header2.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';
import Loading from '@/components/common/Loading.vue';
import bus from 'vue3-eventbus'
import SideComponent from '@/components/common/SideComponent.vue';
import { onBeforeRouteUpdate } from "vue-router";
// import SwiperAd from '@/components/layout/SwiperAd.vue';

// import { useCookies } from "vue3-cookies";
// const { cookies } = useCookies();

// 这个组件见：8、对setup中使用辅助函数进行封装
// import vuexMap from "@/hook/vuexMap"
/**
 * 获取当前模块的 mapState, mapGetters, mapMutations, mapActions
 * 可以把createNamespacedHelpers也封装进vuexMap，但是 我感觉这样太麻烦
 */
// const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers("common");

export default {
	name: 'index',
	components: {
		Header2,
		Menu,
		// SwiperAd,
	    Swiper,
	    SearchBar2,
	    Footer,
	    PageLoading,
	    Loading,
	    SideComponent,
	    
	},
	setup(){
	    // 写法一 用了reactive，就不需要写ref
	    // const data = reactive({
	    // 	checkList: ['selected and disabled', 'Option A']
	    // })
	    let {ctx, proxy} = getCurrentInstance();
	    provide('logoColor', 'white');
	    const showMenu = ref(proxy.$menus);
		provide('showMenu', showMenu);
	    const data = reactive({
	    	isLoading:true,
	    	active_menuid: 0,
			activeClass: 'active',
			isMobile: false,
      		show_menu: false,
      		active_tagid: 'pic',
      		ispic: true,
      		siteInfo:[],
      		showHeader:false,
      		slides: 4,
      		type: 'bb',
      		banners:[

      		],
      		picCats:[
      			
      		],
      		subjectCats:[
      			
      		],
      		videoCats: [

      		],
      		recommendList_1:[
    			
      		],
      		recommendList_2:[

      		],
      		tabs:[
				{
					name: 'pic',
					text: proxy.$t('index.image'),
				},
				{
					name: 'video',
					text: proxy.$t('index.video'),
				}
			],

			photographerList:[
				
			],
			selectedOption:[],
			videos:{},
			hot:[],
			page:1,
			nomore:false,
			pagination:{},
			isSwiperLoading:true,
			isPicLoading:true,
			isVideoLoading:false,
			articleList:[],
			isArLoading:true,
			indexNews:[],
			indexSlider:[],
			limitHeight:true,
	    });
	    
        const store = useStore();
        const getSiteInfo = () => {
		    return new Promise((resolve, reject) => {
		    	setTimeout(()=>{
			      store.dispatch("common/fetchSiteInfo").then((res) => {
			        data.siteInfo = res;
		        	
		        	data.recommendList_1 = data.siteInfo.hot1;
			        data.recommendList_2 = data.siteInfo.hot2;
			        			        
			        console.log(data.recommendList_2,"data.recommendList_2");
			        resolve();
			        bus.emit('isLoading',false);
			      }).catch(err => {
			        reject();
			      })
			  },200)
		    })
	    
	    }

        const viewBox = ref(null);
        const scroll = (val) => {
			if(val == 'top'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		       
			}else if(val == 'bottom'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		        	   
			}
		}
        
        const getArList = () => {
        	 store.dispatch("common/ArList").then((res) => {
	        	data.articleList = res
	        	if(data.articleList.length > 0){
	        		for(let i = 0;i<data.articleList.length;i++){
	        			if(data.articleList[i].desc){
	        				data.articleList[i].desc = data.articleList[i].desc.substr(0, 62);
	        			}
		        		
		        	}
	        	}
	        	
	        	data.isArLoading = false;
	        })
        }

        const GetIndexSlider = () => {
        	store.dispatch("common/GetIndexSlider").then((res) => {
        		console.log(res, "GetIndexSlider");
	        	data.indexSlider = res;
	        	
	        })
        }
        const GetIndexNews = () => {
        	store.dispatch("common/GetIndexNews").then((res) => {
        		console.log(res, "GetIndexNews");
	        	data.indexNews = res;
	        	
	        })
        }
        const getSubjectCats = () => {
        	store.dispatch("common/getSubjectCats",{pid:0}).then((res) => {
	        	data.subjectCats = res
	        	data.isPicLoading = false;
	        })
        }
        const getPhotoCats = () => {
        	store.dispatch("common/getPhotoCats").then((res) => {
	        	data.photographerList = res
	        })
        }
        const getPicLists = () => {
	        store.dispatch("common/getPicLists",{
	        	page:1,
	        	// filter:'',
	        	// cid: 0,
	        	// lid: 0,
	        	type:2,
	        }).then((res) => {
	        	// console.log(res,"videos-2");
	        	data.videos = res
	        	data.isVideoLoading = false;
	        })
	    }
	    const fetchGrapher = () =>{
	    	store.dispatch("cameraGrapher/getCameraExcelent").then((res) => {
	        	data.photographerList = res;
	        	bus.emit('showFooter',true);
	        })

        }
		onBeforeMount(() => {
		  console.log("组件挂载前");
		  
		});
        onBeforeUpdate(() => {
	        
	    });
        onMounted(async()=>{

        	// await nextTick(async()=>{
    		await getSiteInfo()
        	await getArList()
        	await GetIndexSlider();
        	await GetIndexNews();
	        await getSubjectCats();
	        // getPhotoCats()
	        await getPicLists()
	        await fetchGrapher()
        	// })
        	
        	setTimeout(() => {
		        data.isLoading = false;

		        viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		    }, 500);
		    
		    console.log("页面数据更新完毕了")
        })

 		 //JSON.stringify(showMenu)
		
		const refData = toRefs(data);    
	    return {
	        ...refData,
	        getSiteInfo,
	        getSubjectCats,
	        getArList,
	        getPicLists,
	        fetchGrapher,
	        viewBox,
	        scroll,
	        GetIndexNews,
	        GetIndexSlider,
	        // goto,
	        // ...state, ...getters, ...mutations, ...actions
	    }
	},
	computed: {

	},
	watch(){

	},
	beforeCreate() {

	},
	created() {
		let that = this;

	    this.$nextTick(() => {

	    });
	},
	beforeMount() {

	},
	mounted() {

		this.$nextTick(() => {
			this.init();

		});
	},
	beforeUpdate() {

	},
	updated() {

	},
	beforeUnmount() {

	},
	unmounted() {

	},
	methods: {
		init() {
			let that = this;
			let curr = {id:0,url:'/'};
			sessionStorage.setItem("currentMenu", JSON.stringify(curr));
			// window.scrollTo(0,0);
			if(document.documentElement.clientWidth < 768){
				// document.getElementsByClassName("menu-container").display = 'none';
				this.isMobile = true;
			}else{
				// document.getElementsByClassName("menu-container").display = 'flex';
				this.isMobile = false;
			}

		},
		parentStyle(){
			let obj = {height:''};
			let per = 1270/(26.5+9.4);
			let firstW = 26.5*per;
    		let h = firstW*16/26.5;
    		obj.height = h+'px';
    		if(this.isMobile){
    			obj.width = '100%';
    			obj.height = 'auto';
    		}
    		return obj;
		},
		hotStyle1(item,index){
			let obj = {width:'',height:''};
			let per = 1270/(26.5+9.4);
			
			let w = 9.4*per;
        	if(index==1){
        		let h = w*16/9.4;
        		obj.width = w+'px';
        		obj.height = h+'px';
        	}else if(index==0){
        		let firstW = 26.5*per;
        		obj.width = firstW+'px';
        		let h = firstW*16/26.5;
        		obj.height = h+'px';
        	}
        	if(this.isMobile){
    			obj.width = '100%';
    			obj.height = 'auto';
    		}

	        return obj;
		},
		hotStyle(item,index){
			let obj = {width:'33.333%'};

			let per = 1270/(26.5+9.4);
			let firstW = 9.4*per;

			let secondW = 26.5*per;
			let h = secondW*16/26.5;
			let w = 12.8*(h/2)/7.6;

        	if(index==0){
        		obj.width = firstW+'px';
        	}else{	 
        		let ww = ( 1270-firstW ) / 2    		
        		obj.width = ww+'px';
        	}
        	// else if(index==3 || index==4){
        	// 	obj.width = (1270-w-firstW)+'px';
        	// }
	       	if(this.isMobile){
    			obj.width = '100%';
    			obj.height = 'auto';
    		}
	        return obj;
		},
		goNews() {
        	this.$router.push({name:'news'});
        },
        goNewsDetail(id){
			this.$router.push({ name:'news_detail', query:{ id: id } });
		},
		toArticleList(item){
			this.$router.push({name:'article_list', query:{id: item.id,type:item.type}});
		},
		activeStyle(item) {		
			if(item.ratio_w == null || item.ratio_h == null || item.ratio_w == "" || item.ratio_h == "") {
				(async () => {
				    let size = await this.$utils.getImageSize(item.thumb);
				    // console.log(size,"size");
				    item.ratio_w = size.width;
				    item.ratio_h = size.height;
				})();

			}
			let object = {};
			object.width = item.ratio_w*200/item.ratio_h+'px';

			return object;
		},
		handleMouseEnter(event) {
	      // console.log(event.target,'鼠标移入');
	      event.target.play();
	    },
	    handleMouseLeave(event) {
	      // console.log(event.target,'鼠标移出');
	      event.target.pause();
	    },
		toVideoDetail(id){
			this.$router.push({ path:'/topic_video_detail',query:{id:id} });
		},
		toPicCategory(id){
			this.$router.push({ path:'/topic_pic_category',query:{catid:id} });
		},
		toSubjectCategory(id){
			this.$router.push({ path:'/special_topics_category',query:{catid:id} });
		},
		toGrapherDetail(id){
			this.$router.push({ path: '/photo_grapher_detail', query: { id: id } })
		},
		goto(catid) {
			if(!catid) return false;			
			this.selectedOption.push(catid);			
			let a = this.selectedOption.join(',');
			this.$router.push({ path: '/photo_grapher', query: { catid: a } })
		},
		toPicDetail(id){
			this.$router.push({ name:'topic_pic_detail',query:{ id: id } });
		},

	    clickTag(data){
	  		this.active_tagid = data.name;
	  		if(this.active_tagid == 'pic'){
	  			this.ispic = true;
	  			// this.placeholder = '搜图片';
	  		}else if(this.active_tagid == 'video'){
	  			this.ispic = false;
	  			// this.placeholder = '搜视频';
	  		}
	  	},
	    generateTagClass(data){
		    if(this.active_tagid == data.name){
		        return ['tabitem','active-tag'];
		    }
		    return ['tabitem'];
	    },
	    getImageUrl(name) {
		    return new URL(`../assets/${name}`, import.meta.url).href;
		},
	},
	beforeDestory() {

	},
	destoryed() {

	}
}