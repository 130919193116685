import { nextTick,defineComponent, ref, provide, reactive, toRefs, getCurrentInstance, onMounted, onBeforeUnmount } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import PageLoading from '@/components/common/PageLoading.vue';
import HeaderVR from '@/components/layout/header_vr.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';
import Swiper from '@/components/layout/Swiper.vue';
import bus from 'vue3-eventbus'
import SideComponent from '@/components/common/SideComponent.vue';
import Loading2 from '@/components/common/Loading-2.vue';
import Loading from '@/components/common/Loading.vue';

export default defineComponent({
	components: {
		HeaderVR,
		Menu,
	    Footer,
	    PageLoading,
	    Swiper,
	    SideComponent,
	    Loading,
	},
	props: {

	},
	setup() {
		let {ctx, proxy} = getCurrentInstance();
		const router = useRouter();
	    const route = useRoute();
		const data = reactive({
			isLoading:true,
			showHeader: true,
			loginStyle: "min-width:141px;",
			mobileIconStyle: "color:rgba(223, 58, 8, 1);",
			showListIcon: true,
			imageSrc: '',
			//定义一个imageNew变量来接收裁剪之后的图片
			imageNew: '',		
      		cropperOption: {
		        img: "",
		        size: 1,
		        full: false,
		        outputType: "png",
		        canMove: false,
		        fixedBox: false,
		        original: false,
		        canMoveBox: true,
		        autoCrop: true,
		        // 只有自动截图开启 宽度高度才生效
		        autoCropWidth: 160,
		        autoCropHeight: 150,
		        centerBox: true,
		        high: true,
		        max: 99999,
		        fixed: true,
		        fixedNumber: [1, 2],
		    },
		    vrlist: [],
		    vrbanner: '',
		    isVrLoading: false,
		    isSwiperLoading: true,
		});
		
		const showMenus = ref(proxy.$menus);
		provide('showMenu', showMenus);
		provide('menuTextStyle', {color:'#fff'});
		provide('showHeader',false);
		// provide('swiperBoxStyle', {height:'800px'});
		

		const getImageUrl = (name) => {
		    return proxy.$utils.getImageUrl(name);
		};

		const store = useStore();
		const getVRList=()=>{
			data.isVrLoading = true;
			store.dispatch("common/getVRList",{

	        }).then((res) => {
	        	
	        	data.vrbanner = res.sliders;
	        	data.vrlist = res.list;
	        	data.isVrLoading = false;
	        	data.isSwiperLoading = false;
	        	bus.emit("isLoading", false);
	        	bus.emit('showFooter',true);
	        }).catch(err=>{
	        	data.isVrLoading = false;
	        	data.isSwiperLoading = false;
	        })
		}
		
		const viewBox = ref(null);
        const scroll = (val) => {
			if(val == 'top'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		       
			}else if(val == 'bottom'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });  	
		        
			}
		}

	    onMounted(async()=>{
	    	getVRList()
	    	await nextTick();
        	// nextTick()
        	setTimeout(() => {
		        data.isLoading = false;
		        viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		    }, 500);
		    console.log("页面数据更新完毕了")
        })

		// onMounted(() => {
			// console.log(proxy.$router === router) // true
	  //     	console.log(route) // {path, params, query...}

		    // // 访问组件的 DOM 元素
		    //   element.value = ctx.$el

		    //   // 发送网络请求
		    //   axios.get('/api/data').then(response => {
		    //     ctx.data = response.data
		    //   })

		    //   // 监听数据变化
		    //   ctx.$watch('data', () => {
		    //     console.log('data changed')
		    //   })

		    //   // 在组件销毁时取消异步操作
		    //   ctx.$once('hook:beforeUnmount', () => {
		    //     cancelAsyncTask()
		    //   })
		    
		// });

		const refData = toRefs(data);

		return {
			...refData,
			getImageUrl,
			viewBox,
			scroll,
		}
	},
	created() {

	},
	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	},
	methods: {
		init() {
			// this.imageSrc = ref(this.getImageUrl('img/index/i.jpg'));
		},
		realTime(data) {
			console.log(data,"data");
  			var previews = data;
  			this.previews = data
  		},
  		tovr(url) {
	        // window.location.href = url;
	        window.open(url,"_blank")
	    },
	},
})