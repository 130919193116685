<template>
	<PageLoading :is-loading="isLoading"></PageLoading>
	<SideComponent @scrollto="scroll"></SideComponent>
	<div class="container" ref="viewBox">
		<Header2 :show-search-bar-pic="showSearchbar" login-style="loginStyle" :mobile-icon-style="mobileIconStyle" :show-list-icon="showListIcon"/>
		<div class="content">
			<div style="height:60px;" class="other">
				<!-- <search-bar-pic :is-need-redirect="isNeedRedirect"></search-bar-pic> -->
			</div>
			<div class="detail-container">
				<div class="outer">
					<div class="cats">
						<div>
							<span>{{$t('pic.tip1')}}</span><span>{{picDetail.subject.title}}</span><i class="iconfont icon-jiantou"></i><span>{{picDetail.title}}</span>
						</div>
						<div>
							<!-- <i class="iconfont icon-shoucang2"></i> -->
						</div>
					</div>
					<div class="a1">
						<div class="detail-left">
							<div class="img-outer">
								<img :src="picDetail.watermark" oncontextmenu="return false"/>
							</div>

						</div>
						<div class="detail-right">
							<div class="b1" style="text-align:justify;">
								   <span>{{$t('pic.tip2')}}</span>{{$t('pic.tip3')}}<span>{{$t('pic.tip4')}}</span>
							</div>
							<div class="b2" v-if="picDetail.enable_cart && (picDetail.option.length>0)">
								<div class="btn-cart" @click="addCart(picDetail.id)" :disabled="disabled">{{$t('pic.tip5')}}</div>
								<div class="btn-buy" @click="topay(picDetail.id, picDetail.option)" :disabled="disabled">{{$t('pic.tip6')}}</div>
							</div>
							<div class="b3">
								{{$t('pic.tip7')}}<span style="color:rgb(230,0,18);text-decoration: underline">{{$t('pic.tip8')}}</span>
							</div>
							<div class="b4">
								<div class="pic-detail">
									<div class="detail-item" v-if="picDetail.id">
										<span class="title">{{$t('pic.tip9')}}</span><span class="item-content">{{picDetail.id}}</span>
									</div>
									<div class="detail-item" v-if="picDetail.title">
										<span class="title">{{$t('pic.tip10')}}</span><span class="item-content">{{picDetail.title}}</span>
									</div>
									<div class="detail-item" v-if="parseFloat(picDetail.ratio_w)>0 && parseFloat(picDetail.ratio_h)>0">
										<span class="title">{{$t('pic.tip11')}}</span><span class="item-content">{{picDetail.ratio_w}} X {{picDetail.ratio_h}} px</span>
									</div>
									<div class="detail-item" v-if="picDetail.norms">
										<span class="title">{{$t('pic.tip12')}}</span><span class="item-content">{{picDetail.norms}}</span>
									</div>
									<div class="detail-item" v-if="picDetail.extension">
										<span class="title">{{$t('pic.tip13')}}</span><span class="item-content">{{picDetail.extension}}</span>
									</div>
									<div class="detail-item" v-if="picDetail.size>0">
										<span class="title">{{$t('pic.tip14')}}</span><span class="item-content">{{picDetail.size}}MB</span>
									</div>
									<div class="detail-item" v-if="picDetail.option">
										<span class="title">{{$t('pic.tip15')}}</span>
										<span class="item-content">
											<span style="padding:0;display:flex;flex-direction: column;" v-for="(item,index) in picDetail.option">
												<span>
													<input type="radio" :value="index" v-model="checkedItem"/>&nbsp;[ {{index==0?$t('order_detail.origin_1'):(index==1?$t('order_detail.small_1'):(index==2?$t('order_detail.big_1'):''))}}<span style="color:rgb(255,149,0);">￥{{item.price}}</span>（ {{item.width}} x {{item.height}} px ）]
												</span>

											</span>
										</span>
									</div>
									<div class="detail-item" v-if="picDetail.year">
										<span class="title">{{$t('pic.tip16')}}</span><span class="item-content">{{picDetail.year!=null?picDetail.year:''}}</span>
									</div>
									<div class="detail-item" v-if="picDetail.description">
										<span class="title">{{$t('pic.tip17')}}</span><span class="item-content">{{picDetail.description}}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="middle-content">
				<div class="middle-wrapper">
					<!-- <div class="middle-first">
						<span class="ftip">这张素材包含的地点信息:</span>
						<span class="fposition">中国</span>
						<span class="fposition">山东省</span>
						<span class="fposition">青岛</span>
					</div> -->
					<div class="middle-second">
						<div class="mkeywords">
							<span class="kw">{{$t('pic.tip18')}}</span>
						</div>
						<div class="mtag">
							<span class="mt" v-for="item in picDetail.keywords">{{item}}</span>
							
						</div>
					</div>
				</div>
			</div>
			<div class="pic-title">
				<span class="span">{{$t('pic.tip19')}}</span>
			</div>
			<div class="pic-content">
				<div class="enclosure" style="">
					<section class="section">
						<div class="_1Aw4E" v-for="item in pics" :style="activeStyle(item)" @click="detail(item.id)">
							<a class="imgwraper">
								<img :src="item.preview"  oncontextmenu="return false" :alt="item.title"/>							
							</a>
							<span class="category-name">{{item.title}}</span>
						</div>
					</section>
					<div class="loadmore">
						<Loading :is-loading="isPicLoading"></Loading>
						<span class="more" @click="loadMore()" v-if="!nomore">{{$t('loadmore')}}</span>
						<span class="end" v-if="nomore">- {{$t('theEnd')}} -</span>
					</div>
				</div>
			</div>
			
		</div>
		<Footer/>
	</div>
	<!-- <SideComponent></SideComponent> -->
	<Login2 :show-dialog="showDialog" ref="login" @update:show-dialog="childClick"/>
</template>

<script>
	import pic_detail from './topic_pic_detail_controller';
	export default pic_detail;
</script>

<style src="@/assets/css/topic_pic_detail.css" scoped> </style>