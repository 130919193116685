import { defineComponent, ref, provide, reactive, toRefs, getCurrentInstance, nextTick, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import {useStore} from 'vuex';
import PageLoading from '@/components/common/PageLoading.vue';
import Swiper from '@/components/layout/Swiper.vue';
import SearchBar2 from '@/components/layout/SearchBar2.vue';
import Header2 from '@/components/layout/header2.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';
import bus from 'vue3-eventbus'

export default defineComponent({
	components: {
		PageLoading,
		Header2,
		Menu,
	    Swiper,
	    SearchBar2,
	    Footer
	},
	props: {

	},
	setup() {
		provide('logoColor', 'black');
		let {ctx, proxy} = getCurrentInstance();
		const router = useRouter();
	    const route = useRoute();
	    console.log(route,"route");
		const data = reactive({
			isLoading: true,
			showHeader: true,
			loginStyle: "min-width:141px;",
			mobileIconStyle: "color:rgba(223, 58, 8, 1);",
			showListIcon: true,
			newsList:[],
			pagination:{},
			banners:[

      		],
      		page:1,
      		nomore:true,
      		keyword: '',
      		type:'',
      		title: '',
      		content: '',
      		businfo:{},
		});

		const showMenu = ref(proxy.$menus);
		provide('showMenu', showMenu);

		// const outerStyleStr = ref("position:relative;left:10%;top:40%;transform:translate(-10%,-40%);width:auto;");
		// provide('outerStyleStr', outerStyleStr);

		// const styleStr = ref("height:45px;");
		// provide('styleStr', styleStr);

		// const showSearchText = ref(false);
		// provide('showSearchText', showSearchText);

		const getImageUrl = (name) => {
		    return proxy.$utils.getImageUrl(name);
		};
		const store = useStore();
		data.title = route.query.title;
		let type = route.query.type;
		

		bus.on('*', (key, e) => {
			data.title = e.title;
			let type = e.type;
			data.content = store.state.common.siteInfo[type];
			// console.log(e,"e");
		});

		const viewBox = ref(null);
        
	    onMounted(async()=>{
	    	data.content = store.state.common.siteInfo[type];
        	await nextTick(()=>{
        		bus.on("scrollTotop",e => {
		        	if(e){ 
						viewBox.value.scrollIntoView({
					        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
					        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
					        inline: "center", // 定义水平滚动方向的对齐
					    });
		        	}
		        	
		        });
		 		bus.on("scrollToBottom",e => {
		        	if(e){
						viewBox.value.scrollIntoView({
					        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
					        block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
					        inline: "center", // 定义水平滚动方向的对齐
					    });
		        	}
		        	
		        });
        	})
        	setTimeout(() => {
		        data.isLoading = false;
		        viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		    }, 600);
		    console.log("页面数据更新完毕了")
        })

		const refData = toRefs(data);

		return {
			...refData,
			getImageUrl,
			viewBox,
		}
	},
	created() {

	},
	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	},
	methods: {
		init() {
			let that = this;
			// bus.on('*', (key, e) => {
			// 	data.title = route.query.title ? route.query.title : e.title;
			// 	let type = route.query.type ? route.query.type : e.type;
			// 	data.content = store.state.common.siteInfo[type];
			// 	console.log(e,"e");
			// });
		},
		loadMore() {
			let that = this;
			// this.searchNews(this.keyword, this.page);
			
		},
		
		toDetail(id){
			this.$router.push({ name:'news_detail', query:{ id: id } });
		},
	},
})