<template>
	<div class="menu-container" v-if="show_menu">
		<i class="iconfont icon-chahao2" @click="hideMenu()"></i>	
		<div class="menu-dialog">
			<div v-for="menu in filterMenus" class="menu-wrapper">
				<div class="popover">		
					<div :class="generateClass(menu)" @click.stop="clickMenu(menu)" :key="menu.id">	
						<div class="mhover" :style="menuTextColor">
							<span>{{menu.name}}</span>
							<i class="iconfont icon-2701jiantou3 icon-arrow" v-if="menu.children.length > 0"></i>
						</div>
					</div>
					<div class="dropdown-menu" v-if="menu.children.length > 0">
						<a class="dropdown-menu-item" v-for="child in menu.children" :href="`${child.url}`">
							<div class="border">
								<span>{{child.name}}</span>
							</div>
						</a>
					</div>	
				</div>
			</div>
		</div>	 
	</div>
	<!-- <div class="menu-container">
		<div v-for="menu in menus" class="menu-wrapper">
			<div :class="generateClass(menu)" @click="clickMenu(menu)" :key="menu.id" >
				<div class="mhover" :style="menuTextColor">
					<span>{{menu.name}}</span>
					<i class="iconfont icon-2701jiantou3 icon-arrow" v-if="menu.children.length > 0"></i>
				</div>
			</div>
		</div>
	</div> -->
</template>
<script>
	import { ref, provide,reactive, inject, toRefs, getCurrentInstance } from 'vue';
	import { HomeFilled, Menu as IconMenu } from '@element-plus/icons-vue'
	import bus from 'vue3-eventbus'

	// import { useRouter } from 'vue-router'
	// const router = useRouter()

	export default {
		name: 'Menu',
		props:{
			showSearchBarPic: {
				type: Boolean,
				default: false
			},
		},
		setup() {
			const { proxy } = getCurrentInstance();
			const data = reactive({
				active_menuid: 0,
				activeClass: 'active',
				isMobile: false,
	      		show_menu: false,
	      		menuTextColor: inject('menuTextStyle', {color:'#000'}),
	      		styleObject: {
				  color: '#000'
				},
	      		menus:[
			        {
			            id: 0,
			            name: proxy.$t('menus.home'),
			            url: "/",
			            show: true,
			            children:[]
			        },
			      	{
			      		id: 10,
			      		name: proxy.$t('menus.news'),
			      		url: "/news",
			      		show: true,
			      		children:[]
			      	},
			      	{
			      		id: 1,
			      		name: proxy.$t('menus.pic'),
			      		url: "/topic_pic",
			      		show: true,
			      		children:[]
			      	},
			      	{
			      		id: 2,
			      		name: proxy.$t('menus.video'),
			      		url: "/topic_video",
			      		show: true,
			      		children:[]
			      	},
			      	{
			      		id: 7,
			      		name: proxy.$t('menus.active'),
			      		url: "/active_list",
			      		show: false,
			      		children:[]
			      	},
			      	{
			      		id: 8,
			      		name: proxy.$t('menus.subject'),
			      		url: "/subject_list",
			      		show: false,
			      		children:[]
			      	},
			      	{
			      		id: 5,
			      		name: proxy.$t('menus.vr'),
			      		url: "/fvr",
			      		show: false,
			      		children:[
			      			// {
					      	// 	id: 17,
					      	// 	name: "70周年青岛成就展",
					      	// 	url: "/vr?id=16",
					      	// 	show: false,
					      	// 	children:[]
					      	// },
					      	// {
					      	// 	id: 18,
					      	// 	name: "建党100周年",
					      	// 	url: "/vr?id=17",
					      	// 	show: false,
					      	// 	children:[]
					      	// },
			      		]
			      	},
			      	{
			      		id: 6,
			      		name: proxy.$t('menus.grapher'),
			      		url: "/photo_grapher",
			      		show: false,
			      		children:[]
			      	},
			      	

			      	{
			      		id: 11,
			      		name: proxy.$t('menus.more'),
			      		url: "",
			      		show: true,
			      		children:[
			      			{
					      		id: 12,
					      		name: proxy.$t('menus.package'),
					      		url: "/price_package",
					      		show: false,
					      		children:[]
					      	},
					      	
			      		]
			      	},
			      	
			      	
			      	
			    ],
			    showMenus: inject('showMenu',[]),
				filterMenus: [],
			});

			bus.on('showMobileMenu', e => data.show_menu = e )


			const refData = toRefs(data);
		    
		    return {
		        ...refData,
		    }
		},
		
		mounted() {
			this.$nextTick(() => {
				this.init();
				this.filterMenu();
			});
		},
		methods: {
			init() {
				// console.log(this.$router.currentRoute,'routepath');
				let currentMenu = JSON.parse(sessionStorage.getItem("currentMenu"));
				let pathname = this.$router.currentRoute._value.name;
				if(currentMenu && currentMenu.url == this.$router.currentRoute._value.path) {
					this.active_menuid = currentMenu.id;
					
				}else if(pathname=='search_pic'||
					     pathname=='topic_pic'||
					     pathname=='special_topics'||
					     pathname=='special_topics_category'||
					     pathname=='topic_pic_category'||
					     pathname=='topic_pic_subcategory'||
					     pathname=='topic_pic_detail'
					    ){
					this.active_menuid = 1;

				}else if(pathname=='topic_video_detail'){
					this.active_menuid = 2;
				}else if(pathname=='photo_grapher' || pathname=='photo_grapher_album' || pathname=='photo_grapher_detail'){
					this.active_menuid = 6;
				}else if(pathname=='news_detail'){
					this.active_menuid = 10;
				}else if(pathname.includes('active')){
					this.active_menuid = 7;
				}else if(pathname.includes('price_package')){
					this.active_menuid = 11;
				}else if(pathname.includes('subject_detail')){
					this.active_menuid = 8;
				}


				
				if(document.documentElement.clientWidth < 890){
					// document.getElementsByClassName("menu-container").display = 'none';
					this.show_menu = false;
				}else{
					// document.getElementsByClassName("menu-container").display = 'flex';
					this.show_menu = true;
				}

		        window.addEventListener('resize', function(event){
					if(document.documentElement.clientWidth < 890){
						// document.getElementsByClassName("menu-container").display = 'none';
						this.show_menu = false;
					}else{
						// document.getElementsByClassName("menu-container").display = 'flex';
						this.show_menu = true;
					}
					
				});
			},
			filterMenu(){
				let that = this;
				this.menus.forEach((item)=>{
					if(this.showMenus.indexOf(item.id)!=-1){
						that.filterMenus.push(item);
					}
				})
			},
			clickMenu(menu){
		  		this.active_menuid = menu.id;
		  		sessionStorage.setItem("currentMenu", JSON.stringify(menu));
		  		this.$router.push(menu.url)
		  		// router.push({path:menu.url});
		  	},
		    generateClass(menu){
			    if(this.active_menuid == menu.id){
			        return ['menu-item','active'];
			    }
			    return ['menu-item'];
		    },
		    showMenu(){
		      	// console.log(menu,"menu");
		      	this.show_menu = true;
		    },
		    hideMenu(){
		      	// console.log(menu,"menu");
		      	this.show_menu = false;
		    },
		}
	}
</script>
<style scoped>
	*, *::before, *::after {
	    -webkit-box-sizing: border-box;
	    box-sizing: border-box;
	}
	.menu-container {
		height:inherit;
		background-color: rgba(22,119,255,0.0);
		display:flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items:center;
		flex-wrap: wrap;
		min-width:600px;
		/*margin-left:20px;*/
	}
	.icon-chahao2 {
		display:none;
	}
	@media screen and (max-width: 890px) {
		.menu-container {
			position:fixed;
			top:50%;
			left:50%;
			transform:translate(-50%,-50%);
			z-index:9999999;
			width:100%;
			height:100%;
			background-color: rgba(22,119,255,0.8);
			display:flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items:center;
			flex-wrap: nowrap;
			padding:80px 0;
			min-width:20px;
			margin-left:0px;
		}
		.icon-chahao2 {
			display:flex;
			justify-content: center;
			align-items: center;
			position:absolute;
			top:50px;
			right:10px;
			font-size:20px;
			color:white;
		}
	}
	.menu-dialog {
		width:100%;
		display:flex;
		align-items: center;
	}
	@media screen and (max-width: 890px) {
		.menu-dialog {
			width:90%;
			border-radius:10px;
			display:flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding:20px 20px;
			background-color: rgba(22,119,255,0.3);
		}
	}
	.menu-wrapper {
		position:relative;
		height:80%;
		display:flex;
		align-items: center;
	}
	@media screen and (max-width: 890px) {
		.menu-wrapper {
			/*position:relative;*/
			width:80%;
			height:auto;
			padding:10px 0;
			display:flex;
			align-items: center;
			background-color: rgba(22,119,255,0.0);

		}
	}
	.popover {
		position:relative;

	}

	.menu-item {
		height:50px;
		/*color:white;*/
		padding:5px 0px;
		margin-right:20px;
		display:flex;flex-direction: row;justify-content: center;align-items: center;
		transition: all 1.0s ease-in-out;
		-webkit-transition: all ease-in-out 500ms;
	    -o-transition: all ease-in-out 500ms;
	    background-color: rgba(22,119,255,0.0);
	    font-size: 14px;
	}
	@media screen and (max-width: 890px) {
		.menu-item {
			height:auto;
			/*color:white;*/
			padding:10px 20px;
			margin:0 0px;
			display:flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			transition: all 1.0s ease-in-out;
			-webkit-transition: all ease-in-out 500ms;
		    -o-transition: all ease-in-out 500ms;
		    background-color: rgba(22,119,255,0.0);
		    /*color:#000;*/
		}
	}

	.dropdown-menu {
		position:absolute;
		z-index:999;
		/*height:300px;*/
		min-width:150px;
		min-height:40px;
		background:rgba(46, 47, 52, 0.7); /*rgba(255, 255, 255, 1.0);*/
		left:0;
		top:90%;
		/*transform:translateY(-90%);*/
		display:none;
		flex-direction: column;
		justify-content: flex-start;
		align-items:flex-start;
		border-radius: 8px;
		/*box-shadow:0 0 10px #000;*/
		/*box-shadow:0px 12px 8px -12px #000;*/
		/*box-shadow:15px 0 15px -15px #000, 
		           -15px 0 15px -15px #000;*/
		box-shadow:0px 2px 5px rgb(206,204,205);
		transition: all 1.0s ease-in-out;
		-webkit-transition: all ease-in-out 500ms;
	    -o-transition: all ease-in-out 500ms;
	}
	.dropdown-menu-item {
		padding:5px 5px;
		border-bottom:2px double rgba(46, 47, 52, 0.3);/*rgb(220,220,220);*/
		display:flex;
		width:100%;
		text-decoration: none;
	}
	.dropdown-menu-item:last-child {
		border-bottom:0px double rgba(46, 47, 52, 0.3);
	}
	.dropdown-menu-item:first-child {
		border-bottom:2px double rgba(46, 47, 52, 0.3);
	}
	.dropdown-menu-item .border{
		width:100%;
		/*padding:5px 10px;*/
		/*background:rgba(230, 0, 18, 0.5);*/
		display:flex;
	}
	.dropdown-menu-item .border span{
		width: 100%;
		padding: 5px 10px;
		display: flex;
		flex-direction: row;
		color: white;
		/*color:rgba(10, 10, 10, 1);*/
		/*background:rgba(230, 0, 18, 0.3);*/
	}
	.dropdown-menu-item .border span:hover{
		width:100%;
		padding:5px 10px;
		display:flex;
		flex-direction: row;
		color:rgba(230,230,230,1.0);
		/*color:rgba(230, 0, 18, 0.9);*/
		/*background:rgba(230, 0, 18, 0.3);*/
	}
	.popover:hover .dropdown-menu{
        display: flex;
    }
    /*情况2.改变兄弟元素（兄弟1:hover+兄弟2）*/
    .brother1:hover+.brother2{
        display: block;
    }
    /*情况3.改变兄弟元素下子元素（兄弟1:hover+兄弟2>兄弟2的儿子）*/
    .brother1:hover+.brother2>.brother2-son{
        display: block;
    }
	.mhover {
		display:flex;flex-direction: row;justify-content: center;align-items:center;
		color: #fff!important;
		transition: all 0.3s ease-in-out;
		-webkit-transition: all ease-in-out 180ms;
	    -o-transition: all ease-in-out 180ms;
	    transition: all ease-in-out 180ms;
	    outline: none;
	    cursor: pointer;
	    font-size:14px;
	}
	.mhover:hover {
		display:flex;
		/*justify-content: center;*/
		align-items: center;
		/*color: #fafafa;
		border:1px solid rgba(22,119,255,0.2);border-radius:5px;
		background-color: rgba(22,119,255,0.2);*/
	}

	.menu-container .active {
		border-bottom:1px solid #fff;
		font-weight:bold;
	}
	@media screen and (max-width: 890px) {
		.mhover:hover {
			color: rgba(0,49,97,1.0);
			/*border:1px solid rgba(22,119,255,0.2);border-radius:5px;
			background-color: rgba(22,119,255,0.2);*/
		}

		.menu-container .menu-wrapper .menu-item.active {
			color: rgba(0,49,97,1.0)!important;
			border-bottom:1px solid #fff;/*rgba(22,119,255,1.0);*/
			font-weight:bold;
		}
	}
	.menu-container .icon-arrow {
		font-size:14px!important;
		color:#fff;
		display:flex;
		justify-content: center;
		align-items: center;
	}
</style>