<template>
	<PageLoading :is-loading="isLoading"></PageLoading>
	<SideComponent @scrollto="scroll"></SideComponent>
	<div class="container" ref="viewBox" id="container" style="overflow: auto;">
		<div class="header-container">
			<div class="search-swiper-container">				
				<Swiper :list="siteInfo.sliders"/>
				<search-bar2></search-bar2>
			</div>
		</div>
		<div class="content">
			<div class="content-outer" style="">
				
				<div class="articlement">
					<Swiper :show-header="showHeader" :slides="slides" :type="type" :list="articleList" ></Swiper>
				</div>
				<div style="padding:9px;">
					<Swiper :list="indexSlider" :show-header="showHeader" :limit-height="limitHeight"/>
				</div>
				<div class="video-article">
					<div class="video-article-title">
						<span style="font-size:18px;font-weight:bold;"><span style="border-left:5px solid rgb(230,0,15);margin-right:10px;"></span>{{$t('news.news')}}</span>
						<span style="" class="videomore" @click.stop="goNews()"><span style="margin-right:5px;">{{$t('viewmore')}}</span>>></span>
					</div>
					<div class="video-article-content">

						<div class="video-article-item" v-for="item in indexNews" @click.stop="goNewsDetail(item.id)">
							<div class="article-subitem">
								<img :src="item.thumb" style="width:100%;height:254px;"  oncontextmenu="return false" :alt="item.title"/>
							</div>
							<div class="article-subitem">
								<div class="article-subitem-text">
									{{item.title}}
								</div>
							</div>
						</div>
						
					</div>
				</div>
				<div class="content-first">
					<div class="tag-wrapper">
						<div class="aa">
							<div v-for="(tab,index) in tabs" :class="generateTagClass(tab)" @click.stop="clickTag(tab)" :key="index">{{tab.text}}</div>
						</div>
					</div>
					
					<div class="scroll-outer">
						<div class="category-list" v-show="ispic">
							<Loading :is-loading="isPicLoading"></Loading>
							<div class="list-item" v-for="item in subjectCats" @click="toSubjectCategory(item.id)">
								<img :src="item.thumb" oncontextmenu="return false" :alt="item.title"/>
								<div class="title">
									<span class="category-name">{{item.title}}</span>
								</div>
							</div>
						</div>

						<div class="video-list" v-show="!ispic">
							<div class="video-item" v-for="item in videos.data">  <!-- :style="activeStyle(item)" -->
								<Loading :is-loading="isVideoLoading"></Loading>
								<div @click="toVideoDetail(item.id)" style="width:100%;height:100%;">
									<video v-if="isMobile"
									    :poster="item.thumb"
									    preload="none" 
									    muted=""
									    :alt="item.title" 									    
									    controlslist="nodownload"
									    oncontextmenu="return false">
										<track kind="captions" />
										<source :src="item.url" type="video/mp4">
									</video>
									<video v-if="!isMobile"
										controls
									    :poster="item.thumb"
									    preload="none" 
									    muted=""
									    :alt="item.title" 									   
									    controlslist="nodownload"
									    oncontextmenu="return false"
									    :src="item.url"
									    
									    >
									    <!-- @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave" -->
										<track kind="captions" />
										<!-- <source :src="item.url" type="video/mp4"> -->
									</video>
								</div>
								<span class="video_name">{{item.title}}</span>
							</div>
							
						</div>
					</div>
				</div>
				<div class="content-second">
					<div class="tag-outer">
						<div class="tag-wrapper">
							<div class="item-title">{{$t('index.hot')}}</div>
						</div>
					</div>
					
					<div class="scroll-outer">
						<div class="pic-list">
							<div class="pic-item-1" :style="parentStyle()">
								<div class="item-1-img" :class="[item.first ? 'first' : '']" :style="hotStyle1(item,index)" v-for="(item,index) in recommendList_1" >
									<img :src="item.preview" oncontextmenu="return false" @click.stop="toPicDetail(item.id)" :alt="item.title"/>									
									<!-- <div class="title">
										<span>{{item.title}}</span>
									</div> -->
								</div>

							</div>
							<div class="pic-item-2" :style="parentStyle()">
								<div class="item-1-img" :class="[item.first ? 'first' : '']" :style="hotStyle(item,index)" v-for="(item,index) in recommendList_2"  @click.stop="toPicDetail(item.id)">							
									<img :src="item.preview" oncontextmenu="return false" :alt="item.title"/>
									<!-- <div class="title">
										<span>{{item.title}}</span>
									</div> -->
								</div>

							</div>
						</div>
					</div>
				</div>
				<div class="content-photographer">
					<div class="caption-title">
						<div class="caption-text">{{$t('index.grapher')}}</div>
					</div>
					<div class="photographer-list">
						<div class="photographer-item" v-for="item in photographerList" :key="item.id" @click="toGrapherDetail(item.id)">
							<div class="item-left">
								<img :src="item.thumb" oncontextmenu="return false" :alt="item.title"/>
								<span class="photographer-cat">{{item.title}}</span>
							</div>
							<div class="item-right">
								<div class="item-right-outer">
									<div class="item-btn-wrapper">
										<div class="search-btn">
											{{item.name}}
										</div>
									</div>
									<div class="subitem">
										<span class="subitem-text" v-for="cate in item.cate">{{cate.title}}</span>
									</div>
								</div>
								
								<!-- <el-checkbox-group v-model="selectedOption">
								    <el-checkbox v-for="subitem in item.children" :key="subitem.id" :label="subitem.id" :value="subitem.id">{{subitem.title}}</el-checkbox>
								</el-checkbox-group> -->
								
								
							</div>
						</div>
						
					</div>
				</div>
			</div>
		</div>
		<Footer/>
		
	</div>
</template>

<script>
	import index from './index_controller';
	export default index;
</script>

<style src="@/assets/css/index.css" scoped>
	/*@import url("@/assets/css/style.css");全局引用css*/
	/*@import '@/assets/css/style.css';*/
</style>