<template>
	<div class="footer" id="footer" v-if="showFooter">
		<div class="footer-content">
			<div class="footer-content-left">
				<div class="footer-logo">
					<div class="logo-wrapper" @click="tohome()">
						<img :src="siteInfo.logo_red"/>
						<!-- <div class="logo-text">
							<span>青岛图片库</span>
							<span class="small-text">青岛国际新闻中心运维</span>
						</div> -->
					</div>
					<div style="height:20px;"></div>
					<div class="version-right" style="text-align: center;">
						<span style="width:100%;font-size:14px;word-spacing:1px;text-align: center;" v-if="lang=='en'">{{$t('newsCenter')}}</span>
						<span style="width:100%;font-size:14px;letter-spacing:11px;text-align: center;padding-left:11px;" v-if="lang=='zh'">{{$t('newsCenter')}}</span>
					</div>
					<div class="footer-mobile">
						<span class="mobile">{{$t('contactPhone')}}：{{siteInfo.contact_mobile}}</span>
						<span class="mobile">{{$t('workTime')}}：{{siteInfo.service_time}}</span>
					</div>
					<div v-if="lang=='zh'" class="version-right" style="letter-spacing:10px;font-size:12px;text-align: center;padding-left:4px;">
						<span style="letter-spacing:8px;font-weight: normal;">{{$t('footerText1')}}</span>
						<span style="letter-spacing:8px;font-weight: normal;">{{$t('footerText2')}}</span>
					</div>
					<div v-if="lang=='en'" class="version-right-en" style="font-size:12px;text-align: center;padding-left:2px;">
						<span style="word-spacing:2px;font-weight: normal;">{{$t('footerText1')}}</span>
						<span style="word-spacing:2px;font-weight: normal;">{{$t('footerText2')}}</span>
					</div>
					<!-- <div class="footer-qrcode">
						<div class="tip-text">关于我们</div>
						<div class="qrcode">
							<img src="@/assets/img/qrcode.jpg"/>
						</div>
					</div> -->
				</div>
			</div>
			<div class="footer-content-right">
				<div class="subitem">
					<div class="item-text caption">{{$t('aboutus')}}</div>				
					<div class="item-text title" @click="toContent('copyright', $t('copyrightNotice') )">{{$t('copyrightNotice')}}</div>
					<div class="item-text title" @click="toContent('aboutus', $t('aboutus') )">{{$t('aboutus')}}</div>
					<!-- <div class="item-text title" @click="toContent('protocal','服务协议')">服务协议</div>
					<div class="item-text title" @click="toContent('privacy','隐私说明')">隐私说明</div> -->
				</div>
				<div class="subitem">
					<div class="item-text caption">{{$t('questions')}}</div>
					<div class="item-text title" @click="toContent('protocal', $t('terms') )">{{$t('terms')}}</div>
					<!-- <div class="item-text title" @click="toContent('common_problem','常见问题')">网站条款</div> -->
					<div class="item-text title" @click="toContent('help', $t('selfRegulatory') )">{{$t('selfRegulatory')}}</div>
					<div class="item-text title" @click="toContent('aftersale', $t('contractUs') )">{{$t('contractUs')}}</div>
					
				</div>
				<div class="subitem">
					<div class="item-text caption">{{$t('friendLink')}}</div>
					<a class="item-text title" v-for="item in siteInfo.flinks" :href="item.link" target="_blank">{{item.title}}</a>
					<!-- <a class="item-text">青岛国际新闻中心</a>
					<a class="item-text">青岛政务网</a> -->
					
				</div>
			</div>
			<div class="footer-content-qrcode">
				<div class="footer-logo">
					<div class="footer-qrcode">
						<div class="tip-text">{{$t('followUs')}}</div>
						<div class="qrcode">
							<img :src="siteInfo.qrcode"/>
						</div>
					</div>
				</div>
			</div>
			
		</div>
		<div class="footer-mark">
			<div class="mark-copyright"><span style="padding-left:10px;">Copyright@2024 {{$t('copyright')}}</span> <span style="padding-right:10px;">| {{$t('ICP')}}</span></div>
			<div class="mark-unit">{{$t('hostUnit')}} {{$t('qdnews')}}</div>
			<div class="mark-unit">
				<span>{{$t('pv')}}{{pv}}，</span>
				<span>{{$t('uv')}}{{uv}}</span>
			</div>
		</div>
	</div>
</template>
<script>
	import { onMounted, ref, provide, reactive, toRefs } from 'vue';
	import { useStore, mapGetters, createNamespacedHelpers  } from "vuex";
	import { useRouter, useRoute } from 'vue-router';
	import bus from 'vue3-eventbus'
	import i18n from "@/lang";
	export default {
		props: {

		},
		setup() {
			const router = useRouter();
	    	const route = useRoute();
	    	// console.log(route,"route-aal");
			const data = reactive({
		        siteInfo: {},
		        lang: i18n.global.locale.value,
		        showFooter: false,
		        pv: 0,
		        uv: 0,
		    })

		    const store = useStore();
		    const getSiteInfo = () => {
		    	// return new Promise((resolve, reject) => {
					store.dispatch("common/fetchSiteInfo").then((res) => {
				        data.siteInfo = res;
				        
				    }).catch(err => {
				        
				    })
			    // });

			}

			const PostPV = () => {
				return new Promise((resolve, reject) => {
					store.dispatch('user/PostPV',{
						path: route.fullPath,
						symbol: route.name,
					}).then(res=>{
						// console.log(res,"post");
						resolve(res);
					}).catch(err=>{
						reject(err);
					})
				});
			}
			const GetPV = () => {
				return new Promise((resolve, reject) => {
					store.dispatch('user/GetPV').then(res=>{
						// console.log(res,"get");
						data.pv = res.pv;
						data.uv = res.uv;
						resolve(res);
					}).catch(err=>{
						reject(err);
					})
				})
			}
			
	        onMounted(async() => {
	        	// data.siteInfo = store.state.common.siteInfo;
	        	// if(Object.keys(data.siteInfo)<=0){
	            getSiteInfo();
	            bus.on('showFooter', e => data.showFooter = e )
	            // }
	            await PostPV();
	            await GetPV();

	            // store.dispatch("common/fetchSiteInfo").then((res) => {
	            //     data.siteInfo = res
	                
	            // }).catch(err => {
	            //     console.error(err)
	            // })
	            // store.dispatch("common/getPicCats").then((res) => {
	            // 	data.picCats = res
	            // })
	        });

	        const toContent = (type,title) => {
				// bus.emit('type', type);
				// bus.emit('title', title);
				bus.emit('content',{type:type,title:title});
				router.push({ name:'content', query:{type:type,title:title} });
			}

		    const refData = toRefs(data)
			return {
				...refData,
				toContent,
				getSiteInfo,
			}
		},
		methods: {
			tohome(){
				this.$router.push('/')
			}
			// toContent(type,title){
			// 	bus.emit('content',{type:type,title:title});
			// 	this.$router.push({ name:'content', query:{} });
			// },
		},
	}
</script>
<style src="@/assets/css/footer.css" scoped>
	/*@import url("@/assets/css/style.css");全局引用css*/
	/*@import '@/assets/css/style.css';*/
</style>