<template>
	<div class="header" id="header" ref="header" v-if="isShow">
		<div class="logo-container">
			<!-- <div class="mobile-menu-icon-1" v-show="showListIcon">
				<i class="iconfont icon-listo list-iconstyle-black"></i>
			</div> -->
			<div class="header-logo">
				<div class="logo-outer" @click="goHome()" :alt="siteInfo.title">
					<img v-if="logoColor=='black'" :src="siteInfo.logo" alt="logo">
					<img v-if="logoColor=='white'" :src="siteInfo.logo_white" alt="logo">
					<img v-if="logoColor=='red'" :src="siteInfo.logo_red" alt="logo">
					<!-- <div class="logo-text">
						<span class="header-big-text">{{siteInfo.title}}</span>
						<span class="header-small-text">{{$t('logo_small_text')}}</span>
					</div> -->
				</div>
			</div>
			<div class="mobile-menu-icon" @click="showMobileMenu()">
				<i class="iconfont icon-listo list-iconstyle"></i>
			</div>
			<span class="space"></span>
			<Menu />
			<search-bar-pic :is-need-redirect="isNeedRedirect" v-if="showSearchBarPic"></search-bar-pic>
		</div>
		<div style="display:flex;justify-content: flex-end;">
			<ChangeLang></ChangeLang>	
			<div v-if="!isLogin" class="header-login" :style="loginStyle">
				<button ref="ref1" :title="username" class="mobile-login" @click.stop="toUser()"><i class="iconfont icon-icontouxiang login-iconstyle" :style="mobileIconStyle"></i>{{username}}</button>
				<div class="popover-outer">
					<span role="button" tabindex="0" class="span-login"><i class="iconfont icon-icontouxiang login-iconstyle" :style="mobileIconStyle"></i>{{username}}</span>
					<div class="dropdown-menu">
						<div class="bottom"></div>
						<a class="dropdown-menu-item" v-for="child in popoverMenu" @click.stop="Exit(child)">
							<div class="border">
								<span>{{child.name}}</span>
							</div>
						</a>
					</div>
				</div>
			</div>
			<div v-if="isLogin" class="header-login" @click.stop="toLogin" :style="loginStyle">
				<button title="{{$t('loginOrRegister')}}" class="mobile-login"><i class="iconfont icon-icontouxiang login-iconstyle" :style="mobileIconStyle"></i></button>
				<span role="button" tabindex="0" class="span-login add-style">{{$t('loginOrRegister')}}</span>
			</div>
		</div>
	</div>
	<Login2 :show-dialog="showDialog" :show-login="showLogin" :show-mobile="showMobile" ref="login" @update:show-dialog="childClick"/>  <!-- @update-post="onGetCode();" :show-mobile="showMobile" :show-qrcode="showQrcode" -->

</template>

<script>
	import { onMounted, ref, provide, reactive, toRefs,inject,getCurrentInstance,nextTick } from 'vue';
	import { useRouter, useRoute } from 'vue-router';
	import Menu from '@/components/layout/menu.vue';
	// import Login2 from '@/components/common/login2.vue';
	//引入接口模块
	import { login } from "@/api/user";
	import { useStore, mapGetters, createNamespacedHelpers  } from "vuex";
	import SearchBarPic from '@/components/layout/SearchBarPic.vue';
	import bus from 'vue3-eventbus';
	import Cookies from "js-cookie";
	import { onBeforeRouteUpdate } from "vue-router";
	import ChangeLang from '@/components/common/ChangeLang.vue';

	export default {
		name: 'Menu',
		props: {
			showSearchBarPic: {
				type: Boolean,
				default: false,
			},
			loginStyle: {
				type: String,
				default: "",
			},
			mobileIconStyle: {
				type: String,
				default: "",
			},
			showListIcon: {
				type: Boolean,
				default: false,
			},
			imgsrc: {
				type: String,
				default: '',
			},
		},
		components: {
			Menu,
			ChangeLang,
			// Login2
		},
		setup(props) {
			let {ctx, proxy} = getCurrentInstance();
			const router = useRouter();
	    	const route = useRoute();
	    	console.log(route.fullPath,"route");
			const data = reactive({
		        isMobile: false,// 这样创建的响应式数据就不用ref了，写起来是不是要方便点
		        showDialog: false,
		        showLogin: false,
		        showMobile: false,
		        showQrcode: false,
		        siteInfo: {},
		        isNeedRedirect:true,
		        siteInfo:{},
		        logoColor: inject('logoColor','black'),
		        isShow: inject('showHeader',true),
		        isLogin:true,
		        username: proxy.$t('anonymity'),
		        mobile:'',
		        isMobileMenu: false,
		        popover: false,
		        popoverMenu:[
		        	{
			        	id:1,
			        	name: proxy.$t('personCenter'),
			        	type:'user',
			        },
			        {
			        	id:2,
			        	name: proxy.$t('logout'),
			        	type:'exit',
			        },
		        ],
		        open: true,
		        opened: false,

		    })
		    const ref1 = ref();
			const childClick = (value) => {
				data.showDialog = value.value;
			}
			const toLogin = () => {
				data.showDialog = true;
				data.showMobile = false;
		        data.showQrcode = false;
		        data.showLogin = true;
			}


 			const header = ref(null);
			// const onStepChange = () =>{
			//     header.value.scrollIntoView({
			//         behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			//         block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			//         inline: "center", // 定义水平滚动方向的对齐
			//     });
			// };
			

			const propdata = toRefs(props);
			const store = useStore();
			// data.siteInfo = store.state.common.siteInfo;
			const getSiteInfo = () => {
				setTimeout(()=>{
					store.dispatch("common/fetchSiteInfo").then((res) => {
				        data.siteInfo = res;
				        
				    }).catch(err => {
				        
				    })
				},200);

			}

            // console.log(data.open,"data.open");
            onMounted(async()=>{
            	bus.on('showLoginDialog', e => data.showDialog = e )
	            bus.on('showLoginDialogMobile', e => data.showMobile = e )
	            bus.on('showLoginDialogQrcode', e => data.showQrcode = e )
            	bus.on('showLoginDialogLogin', e => data.showLogin = e )
            	
        		let opened = Cookies.get("opened");
        		data.siteInfo = store.state.common.siteInfo;
            	if(Object.keys(data.siteInfo)<=0){
	            	getSiteInfo();
	            }

            	// data.siteInfo = store.state.common.siteInfo;
            	const isAuthenticated = proxy.$cookies.getAuthKey();
            	if(isAuthenticated && 
            		proxy.$cookies.getToken()!==undefined && 
            		proxy.$cookies.getToken()!='' && 
            		proxy.$cookies.getToken()!=null
            	){
            		console.log(store.state.user.userInfo, "userInfo");
					data.isLogin = false;
					data.username = store.state.user.userInfo.username!="undefined" ? store.state.user.userInfo.username : store.state.user.userInfo.mobile;
					data.mobile = store.state.user.userInfo.mobile;
					if(opened=='true'){
		            	data.open = false;
		            }else{
						data.open = true;	
		            }
				}
				
            })
	       
            

			const refData = toRefs(data)
			return {
				childClick,
				toLogin,
				...refData,
				ref1,
				header,
				getSiteInfo,
			}
		},
		mounted() {
			this.$nextTick(() => {
				this.init();
			});
		},
		computed: {
		    ...mapGetters(["testInfo"]),
		},
		created() {
			this.$nextTick(() => {
				sessionStorage.setItem('redirectPath', this.$route.fullPath);
		    });
		},
		methods: {
			init() {
				if(document.documentElement.clientWidth < 768){
					this.isMobile = true;
				}else{
					this.isMobile = false;
				}
				// if(this.$utils.isMobile()){
		  //         this.isMobile = true;           
		  //       } else {
		  //         this.isMobile = false;
		  //       }

				if(document.documentElement.clientWidth < 890){

					// document.querySelector(".menu-container").style.display = "none";
					document.querySelector(".mobile-menu-icon").style.display = "flex";
				} else {
					// document.querySelector(".menu-container").style.display = "flex";
					document.querySelector(".mobile-menu-icon").style.display = "none";
				}
				if(document.documentElement.clientWidth < 768){
					document.querySelector(".mobile-login").style.display = "block";
					document.querySelector(".span-login").style.display = "none";
					
				} else {
					document.querySelector(".mobile-login").style.display = "none";
					document.querySelector(".span-login").style.display = "block";
					
				}
		        window.addEventListener('resize', function(event){
					if(document.documentElement.clientWidth < 890){
						// document.querySelector(".menu-container").style.display = "none";
						document.querySelector(".mobile-menu-icon").style.display = "flex";
					} else {
						// document.querySelector(".menu-container").style.display = "flex";
						document.querySelector(".mobile-menu-icon").style.display = "none";
					}
					if(document.documentElement.clientWidth < 768){
						document.querySelector(".mobile-login").style.display = "block";
						document.querySelector(".span-login").style.display = "none";
						
					} else {
						document.querySelector(".mobile-login").style.display = "none";
						document.querySelector(".span-login").style.display = "block";
						
					}
					
				});
			},
			know() {				
				Cookies.set('opened', 'true', {expires: 1});
				this.open = false;
				
			},
			mouseOver() {
				
			},
			mouseLeave() {

			},
			showMobileMenu() {
				bus.emit('showMobileMenu', true);
			},
			onGetCode() {
				console.log('onGetCode');
			},
			onRegister() {

			},
			onLogin() {

			},
			Exit(item){
				if(item.type=='exit'){
					this.$cookies.removeToken();
					this.$cookies.removeUsername();
					// this.$cookies.removeMobile();
					this.$cookies.removeTokentype();
					this.$cookies.removeAuthKey();
					location.reload();
				} else if(item.type=='user'){
					this.$router.push('/my/setting');
				}
				
			},
			goHome(){
				this.$router.push('/');
			},
			toUser(){
				if(this.isMobile) {
					this.$router.push('/my/home');
				}else{
					this.$router.push('/my/setting');
				}
				
			},
			
		},
	}
</script>

<style src="@/assets/css/header.css" scoped> </style>