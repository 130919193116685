import { defineComponent, ref, provide, reactive, toRefs, getCurrentInstance,nextTick, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import PageLoading from '@/components/common/PageLoading.vue';
import { useStore, createNamespacedHelpers } from "vuex";
import Swiper from '@/components/layout/Swiper.vue';
import SearchBar2 from '@/components/layout/SearchBar2.vue';
import Header2 from '@/components/layout/header2.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';

export default defineComponent({
      components: {
          Header2,
          Menu,
          Swiper,
          SearchBar2,
          PageLoading,
          Footer
      },
      props: {

      },
      setup() {
            let {ctx, proxy} = getCurrentInstance();
            const router = useRouter();
            const route = useRoute();

            const data = reactive({
                  isLoading:true,
                  showHeader: true,
                  loginStyle: "min-width:141px;",
                  mobileIconStyle: "color:rgba(223, 58, 8, 1);",
                  showListIcon: true,
                  detail:{
                        // img:'img/photo/2.jpg',
                        // name:'王勇森',
                        // desc: '山东省摄影家协会会员，青岛国际新闻中心《走向世界一帆船之都杂志》《时尚青岛官网》《青岛图片库》摄影记者、图片编辑。摄影师感言:从事新闻采访工作20余年，拍摄记录了青岛二十多年来发生的翻天覆地的变化。我始终认为，摄影的主要功能是记录，记录大自然的壮美;记录社会的进步;也记录发生在人们身边的故事。让世间正能量得以传播;让人们欢乐和感动;也让城市和人们记住历史，我觉得这是我作为一名摄影人应有的责任和使命感。',
                  },
                  picWorks:{},
                  pagination:{},
                  teams:[],
                  mobile: false,
                  albums:[],
            });
            const showMenu = ref([0,10,1,2,5,6]);
            provide('showMenu', showMenu);

            const outerStyleStr = ref("position:relative;left:10%;top:40%;transform:translate(-10%,-40%);width:auto;");
            provide('outerStyleStr', outerStyleStr);

            const styleStr = ref("height:45px;");
            provide('styleStr', styleStr);

            const showSearchText = ref(false);
            provide('showSearchText', showSearchText);

            const getImageUrl = (name) => {
                return proxy.$utils.getImageUrl(name);
            };
            const store = useStore();
            

            onMounted(async()=>{
              store.dispatch("cameraGrapher/getCameraAlbum",{
                  camera_id: route.query.id
              }).then((res) => {
                  
                  // let team1 = [];
                  // let team2 = [];
                  // let teams = [];

                  // for(let i = 0; i < res.data.length; i++){

                  //   if(i < 3){
                  //     team1.push(res.data[i]);
                  //   }else{
                  //     team2.push(res.data[i]);
                  //   }
                    
                  // }
                  // data.picWorks.team1 = team1;
                  // data.picWorks.team2 = team2;
                  // data.teams = team1.concat(team2);
                  data.albums = res.data;
                  console.log(data.albums,"photo album");
                  data.pagination = res.pagination;
                  data.detail = res.camera_men;
                  
              })
              await nextTick()
                setTimeout(() => {
                data.isLoading = false;
                viewBox.value.scrollIntoView({
                    behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
                    block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
                    inline: "center", // 定义水平滚动方向的对齐
                });
              }, 600);
              console.log("页面数据更新完毕了")
            })

            const refData = toRefs(data);

            return {
                  ...refData,
                  getImageUrl,
            }
      },
      created() {

      },
      mounted() {
        this.$nextTick(() => {
              this.init();
        });
      },
      methods: {
        init() {
          if(document.documentElement.clientWidth < 890){
            this.mobile = true;
          }else{
            this.mobile = false;
          }

          window.addEventListener('resize', function(event){
            if(document.documentElement.clientWidth < 890){
              this.mobile = true;
            }else{
              this.mobile = false;
            }
          
          });
        },
        toDetail(id) {
          this.$router.push({ path:'/photo_grapher_detail',query:{id:id} });
        },
        itemStyle(index){
          return [index%3===0 ? 'style_2' : ''];
        },
      },
})