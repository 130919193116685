<template>
	<PageLoading :is-loading="isLoading"></PageLoading>
	<SideComponent @scrollto="scroll"></SideComponent>
	<div class="container" ref="viewBox">		
		<div class="header-container">
			<HeaderVR />
			<div class="bigimg-container">
				<!-- <Swiper :list="banner"/> -->
			</div>
		</div>
		<div class="content">
			<!-- <vue-esign ref="esign" :width="800" :height="300" :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor" v-model:bgColor="bgColor" />
			<button @click="handleReset">清空画板</button>
			<button @click="handleGenerate">生成图片</button>
			<div class="esigh-result">
		      <img v-if="resultImg" :src="resultImg" alt="">
		    </div> -->
			<section class="pack-table">
				<div class="table-thead">
					<div class="head-item item0">
						
					</div>
					<div class="head-item item1">
						{{$t('package.name')}}
					</div>
					<div class="head-item item2">
						{{$t('package.content')}}
					</div>
					<div class="head-item item3">
						{{$t('package.expire')}}
					</div>

				</div>
				<div class="table-tbody">
					<Loading :is-loading="isListLoading"></Loading>
					<div class="body-items" v-for="(item,index) in packList">
						<div class="body-item item0">
							<input type="radio" :value="item.id" v-model="pickedItem"/>
						</div>
						<div class="body-item item1">
							{{item.title}}
						</div>
						<div class="body-item item2">
							{{item.desc}}
						</div>
						<div class="body-item item3">
							{{item.expire}}{{$t('package.day')}}
						</div>
					</div>
				</div>
				<div class="table-footer">
					<div class="pay-type">
						<div class="pay-type-item" v-for="item in payType">
							<span style="padding:5px 5px;">
								<input type="radio" :value="item.id" v-model="checkedPaytypeId" />
							</span>
							<span style="padding:5px 5px;">{{item.title}}</span>
						</div>
					</div>
					<div class="buyBtn" @click="submit()">
						{{$t('package.buy')}}
					</div>
				</div>
			</section>
			<section class="pack-table2">
				<div class="table-thead">
					<div class="head-item item0">
						{{$t('package.between')}}
					</div>
					<div class="head-item item1">
						{{$t('package.min')}}
					</div>
					<div class="head-item item2">
						{{$t('package.max')}}
					</div>
					<div class="head-item item3">
						{{$t('package.original')}}
					</div>

				</div>
				<div class="table-tbody">
					<Loading :is-loading="isPicLoading"></Loading>
					<div class="body-items" v-for="item in picPrevileges">
						<div class="body-item-left item0">
							{{item.name}}
						</div>
						<div class="body-item-right">
							<div class="body-subitem-outer" v-for="(subitem,type) in item.children">
								<div class="body-subitem-left">
									{{type}}
								</div>
								<div class="body-subitem-right">
									<div class="body-subitem-right-outer" v-for="value in subitem">
										<div class="subitem subitem-0">
											{{value.content}}
										</div>
										<div class="subitem subitem-1">
											<i class="iconfont icon-duihao4 duihao" v-if="value.package.indexOf('1')!=-1"></i>
											<i class="iconfont icon-chahao4 cuohao" v-if="value.package.indexOf('1')==-1"></i>
										</div>
										<div class="subitem subitem-1">
											<i class="iconfont icon-duihao4 duihao" v-if="value.package.includes('2')"></i>
											<i class="iconfont icon-chahao4 cuohao" v-if="!value.package.includes('2')"></i>
										</div>
										<div class="subitem subitem-1">
											<i class="iconfont icon-duihao4 duihao" v-if="value.package.includes('3')"></i>
											<i class="iconfont icon-chahao4 cuohao" v-if="!value.package.includes('3')"></i>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section class="pack-table2">
				<div class="table-thead">
					<div class="head-item item0">
						{{$t('package.between')}}
					</div>
					<div class="head-item item1">
						{{$t('package.tip1')}}
					</div>
					<div class="head-item item2">
						{{$t('package.tip2')}}
					</div>
					<div class="head-item item3">
						
					</div>

				</div>
				<div class="table-tbody">
					<Loading :is-loading="isPicLoading"></Loading>
					<div class="body-items" v-for="item in singleVideoPrevileges">
						<div class="body-item-left item0">
							{{item.name}}
						</div>
						<div class="body-item-right">
							<div class="body-subitem-outer" v-for="(subitem,type) in item.children">
								<div class="body-subitem-left">
									{{type}}
								</div>
								<div class="body-subitem-right">
									<div class="body-subitem-right-outer" v-for="value in subitem">
										<div class="subitem subitem-0">
											{{value.content}}
										</div>
										<div class="subitem subitem-1">
											<i class="iconfont icon-duihao4 duihao" v-if="value.package.hasOwnProperty('1')"></i>
											<i class="iconfont icon-chahao4 cuohao" v-if="!value.package.hasOwnProperty('1')"></i>
										</div>
										<div class="subitem subitem-1">
											<i class="iconfont icon-duihao4 duihao" v-if="value.package.hasOwnProperty('2')"></i>
											<i class="iconfont icon-chahao4 cuohao" v-if="!value.package.hasOwnProperty('2')"></i>
										</div>
										<div class="subitem subitem-1">
											
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section class="pack-table2">
				<div class="table-thead">
					<div class="head-item item0">
						{{$t('package.between')}}
					</div>
					<div class="head-item item1">
						
					</div>
					<div class="head-item item2">
						
					</div>
					<div class="head-item item3">
						
					</div>

				</div>
				<div class="table-tbody">
					<div class="body-items" v-for="item in packVideoPrevileges">
						<div class="body-item-left item0">
							{{item.name}}
						</div>
						<div class="body-item-right">
							<div class="body-subitem-outer" v-for="(subitem,type) in item.children">
								<div class="body-subitem-left">
									{{type}}
								</div>
								<div class="body-subitem-right">
									<div class="body-subitem-right-outer" v-for="value in subitem">
										<div class="subitem subitem-0">
											{{value.content}}
										</div>
										<div class="subitem subitem-1">
											
										</div>
										<div class="subitem subitem-1">
											
										</div>
										<div class="subitem subitem-1">
											
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

		</div>
		<Footer/>
	</div>
	<div class="qrcode" v-if="showQrcode">
		<div class="close" @click.stop="closeQrcode()"><i class="iconfont icon-chahao2"></i></div>
		<div class="title">
			<span>{{payTypeName}}{{$t('package.tip3')}}</span>
		</div>
		<div class="pay-type">
			<!-- <div class="pay-type-item" v-for="item in payType">
				<span style="padding:5px 5px;">
					<input type="radio" :value="item.id" v-model="checkedPaytypeId" />
				</span>
				<span style="padding:5px 5px;">{{item.title}}</span>
			</div> -->
			<!-- <div class="pay-type-item">
				<span style="padding:5px 5px;">
					<input type="radio" :value="value.package_id" v-model="checkedPackageId" />
				</span>
				<span style="padding:5px 5px;">{{value.title}}</span>
			</div> -->
		</div>
		<div class="info">
			<div class="item">
				<span>{{$t('package.order_sn')}}</span>
				<span>{{orderInfo.order_sn}}</span>
			</div>
			<div class="item">
				<span>{{$t('package.price')}}</span>
				<span style="color:rgb(255,149,2);font-size:14px;">￥</span>
				<span style="color:rgb(255,149,2);font-size:16px;">{{orderInfo.price}}</span>
			</div>
			<div class="item">
				<!-- <img src="@/assets/img/qrcode.jpg"/> -->
				<img :src="qrCodeImgUrl"/>
				<span>{{$t('package.open')}}{{payTypeName}}{{$t('package.scan')}}</span>
			</div>
		</div>
	</div>
</template>

<script>
	import price_package from './package_controller';
	export default price_package;
</script>

<style src="@/assets/css/package.css" scoped></style>