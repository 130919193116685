<template>
	<PageLoading :is-loading="isLoading"></PageLoading>
	<div class="container">
		<Header2 :show-search-bar-pic="showHeader" login-style="loginStyle" :mobile-icon-style="mobileIconStyle" :show-list-icon="showListIcon"/>
		<div class="content">
			<div style="height:60px;"></div>
			<div class="tag-container">
				<div class="cats-wrapper">
					<div style="padding:5px 0;" >
						<div class="cats-item">
							<div class="text">图片分类</div>	
						</div>
						<div class="cats-subitems" v-for="item in catTags">
							<div class="cats-first">{{item.title}}</div>
							<div class="cats-second">
								<div class="cats-subitem" :class="[selectedCat==tag.id?'active-tag':'']"  @click="selectCat(tag.id)" v-for="tag in item.children">{{tag.title}}</div>
							</div>
							
						</div>
					</div>

				</div>
			</div>
			<div class="pic-content">
				<div class="enclosure" style="">
					<section class="section">
						<div class="_1Aw4E" v-for="item in pics.data">
							<a @click="toPicDetail(item.id)">
								<img :src="item.preview" oncontextmenu="return false" />
								<!-- <span class="category-name">{{item.catname}}</span> -->
							</a>
						</div>
					</section>
				</div>
			</div>

		</div>
		<Footer/>
	</div>
</template>

<script>
	import pic_category from './topic_pic_subcategory_controller';
	export default pic_category;
</script>

<style src="@/assets/css/topic_pic_subcategory.css" scoped>
	/*@import url("@/assets/css/style.css");全局引用css*/
	/*@import '@/assets/css/style.css';*/
</style>