<template>
	<PageLoading :is-loading="isLoading"></PageLoading>
	<SideComponent @scrollto="scroll"></SideComponent>
	<div class="container" ref="viewBox">		
		<div class="header-container">
			<HeaderVR />
			<div class="bigimg-container">
				<Loading :is-loading="isSwiperLoading"></Loading>
				<Swiper :list="vrbanner"/>
			</div>
		</div>

		<div class="content" v-for="item in vrlist">
			<Loading :is-loading="isVrLoading"></Loading>
			<div class="aa">
				<div class="aa-left">
					<div class="vr-content">
						<div class="t1">{{$t('vr.text1')}}</div>
						<div v-html="item.content" style="text-align: justify;"></div>

						<div class="detail-btn"><a :href="item.url" target="_blank" :title="item.title" class="btn solid"><span>{{$t('vr.text2')}}</span></a></div>
					</div>

				</div>
				<div class="aa-right">
					<img :src="item.thumb" @click="tovr(item.url)" oncontextmenu="return false"/>
				</div>
			</div>

		</div>
		<Footer/>
	</div>
</template>

<script>
	import vr from './vr_controller';
	export default vr;
</script>

<style src="@/assets/css/vr/vr.css" scoped>
	/*@import url("@/assets/css/style.css");全局引用css*/
	/*@import '@/assets/css/style.css';*/
</style>