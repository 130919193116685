import { onMounted,nextTick, defineComponent, ref, provide, reactive, toRefs, getCurrentInstance } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import Swiper from '@/components/layout/Swiper.vue';
import SearchBar2 from '@/components/layout/SearchBar2.vue';
import Header2 from '@/components/layout/header2.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';
import SideMenu from '@/components/layout/SideMenu.vue';
import bus from 'vue3-eventbus'
import SideComponent from '@/components/common/SideComponent.vue';
import Loading from '@/components/common/Loading.vue';

export default defineComponent({
	components: {
		Header2,
		Menu,
	    Swiper,
	    SearchBar2,
	    Footer,
	    SideMenu,
	    SideComponent,
	    Loading,
	},
	props: {

	},
	setup() {
		let {ctx, proxy} = getCurrentInstance();
		const route = useRoute();
		let type = route.query.type?route.query.type:0;
		const data = reactive({
			showHeader: true,
			loginStyle: "min-width:141px;",
			mobileIconStyle: "color:rgba(223, 58, 8, 1);",
			showListIcon: true,
			activeName: 'first',
			formSize: 'default',			
			pagination: {},
			page: 1,
			keywords: '',
			allCats: [],
			nomore: true,
			type: 1,
			folderList: [],
			folderVideoList: [],
			actid:0,
			showFolder: false,
			detail:{},
			coverType:[{
				id: 1,
				name: proxy.$t('active.cover_type_1'),
				tip: proxy.$t('active.cover_type_1_tip'),
			},{
				id: 2,
				name: proxy.$t('active.cover_type_2'),
				tip: proxy.$t('active.cover_type_2_tip'),
			}],
			checkedCoverType: 1,
			folderThumbLoading: false,
			folder_disabled:false,
			folder_id: 0,
			pic_icon_disabled: false,
			video_icon_disabled: false,
			statusList: [
				{
					id:-1,
					name: proxy.$t('status.all'),
				},
				{
					id:0,
					name: proxy.$t('status.reviewing'),
				},
				{
					id:1,
					name: proxy.$t('status.reviewed'),
				},
				{
					id:2,
					name: proxy.$t('status.not_review'),
				},
			],
			selectedStatus: -1,
			isPicLoading: true,
		});
		if(type > 0){
			data.type = type;
			if(type==1){
				data.activeName = 'first';
			}else if(type==2){
				data.activeName = 'second';
			}
		}
		const showMenu = ref(proxy.$menus);
		provide('showMenu', showMenu);

		const outerStyleStr = ref("position:relative;left:10%;top:40%;transform:translate(-10%,-40%);width:auto;");
		provide('outerStyleStr', outerStyleStr);

		const styleStr = ref("height:45px;");
		provide('styleStr', styleStr);

		const showSearchText = ref(false);
		provide('showSearchText', showSearchText);

		const getImageUrl = (name) => {
		    return proxy.$utils.getImageUrl(name);
		};

		// bus.emit('needRefresh', true)
		
		const store = useStore();	
			
		const viewBox = ref(null);
        const scroll = (val) => {
			if(val == 'top'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		       
			}else if(val == 'bottom'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
   
			}
		}
		
		const CollectFolderList = (type=1, page=1,status=-1) => {
			data.isPicLoading = true;
			data.selectedStatus = status;
			data.page = 1;
			store.dispatch("user/FolderList",{
				type: type,
				page: page,
				status: status,
			}).then((res)=>{
				
				if(type == 1){
					console.log(res,"folderlist");
					data.folderList = res.data;
				}else if(type == 2){
					console.log(res,"folderVideoList");
					data.folderVideoList = res.data;
				}
				data.pagination = res.pagination;
				data.page = res.pagination.current+1;
				data.isPicLoading = false;
				bus.emit('showFooter',true);				
			}).catch(err=>{
				data.isPicLoading = false;
			})
		}
		const folderRef = ref()
		const folderForm = reactive({	
			title:'',	
  			description:'',
  			cover_type:1,
  			thumb: '',	
  			type: data.type,
		})

		const folder_rules = reactive({
			title: [
				{ required: true, message: proxy.$t('apply_common.enter_folder_title'), trigger: 'blur' },	
			],
			
		})

		const submitFolderForm = (formEl) => {
			bus.emit('needRefresh', true)
			if (!formEl) return
			data.folder_disabled = true;
			data.folderFormLoading = true;
			formEl.validate((valid, fields) => {
			    if (valid) {
				    store.dispatch('user/EditFolder',{
				    	id: data.folder_id,
				    	body: folderForm
				    }).then(res=>{
				    	console.log(res,"folder edit");
				    	CollectFolderList(data.type,-1);
				    	ElMessage({
						    message: proxy.$t('active.operate_success'),
						    type: 'success',
						})
						data.folderFormLoading = false;
						data.showFolder = false;
				    }).catch(err=>{
				    	ElMessage({
						    message: err.message,
						    type: 'error',
						})
						data.folder_disabled = false;
						data.folderFormLoading = false;
				    })
				    
			    } else {
			      console.log('error submit!')
			      data.folder_disabled = false;
			      data.folderFormLoading = false;
			      return false
			    }
			})
		}
		const delFolder = (id) => {
			store.dispatch('user/DeleteFolder',{id:id}).then(res=>{
				ElMessage({
				    message: proxy.$t('active.operate_success'),
				    type: 'success',
				})
				CollectFolderList(1,-1);
			}).catch(err=>{
				ElMessage({
				    message: err.message,
				    type: 'error',
				})
			})
		}
		const cancelEvent = () => {

		}


		const handleClick = (tab, event) => {
		  console.log(tab.paneName, "tabs")
		  data.page = 1;
		  data.selectedStatus = -1;
		  if(tab.paneName == 'first'){
		  	data.type = 1;
		  }else if(tab.paneName == 'second'){
		  	data.type = 2;
		  }
		  CollectFolderList(data.type, data.page,data.selectedStatus);
		}
		const GetListByStatus = (status) => {
			data.selectedStatus = status;
			data.page = 1;
			console.log(data.type,"data.type")
			console.log(data.page,"data.page")
			console.log(data.selectedStatus,"data.selectedStatus")
			
			CollectFolderList(data.type, data.page, data.selectedStatus);
		}

        onMounted(async()=>{
        	CollectFolderList(data.type, data.page,data.selectedStatus);
        	// CameraWorkList(data.type, 1);
        	// allCategorys();
        	await nextTick()
        	setTimeout(() => {
		      data.isLoading = false;
		      viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		    }, 600);
		    console.log("页面数据更新完毕了")
        })

		const refData = toRefs(data);

		return {
			...refData,
			viewBox,
			scroll,	
			CollectFolderList,
			handleClick,
			folderRef,
			folderForm,
			folder_rules,
			submitFolderForm,
			delFolder,
			cancelEvent,
			GetListByStatus,
		}
	},
	created() {

	},
	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	},
	watch:{

	},
	methods: {
		init() {
			// console.log("this.allCategorys", this.allCats);
		},
		activeClass(id){
			if(this.selectedStatus == id){
				return 'active';
			}
			return '';
		},
		toCollectWork(id){
			this.$router.push({name:'collect_work',query:{folder_id:id,type:this.type}});
		},
		editFolder(id) {
			let that = this;
			this.showFolder = true;
			this.$store.dispatch('user/FolderDetail',{
				id:id				
			}).then(res=>{
				console.log(res,"folder detail");
				that.folder_id = res.id;
				that.folderForm.title = res.title;	
	  			that.folderForm.description = res.description;
	  			that.folderForm.cover_type = res.cover_type;
	  			that.folderForm.thumb = res.thumb;
			})

		},
		
		closeFolder() {
			this.showFolder = false;
		},
		uploadThumb(event){
	    	let that = this;
        	event.preventDefault();
        	this.folderThumbLoading = true;
            let file = event.currentTarget.files[0];
            // console.log(file,"file");
            var formData = new FormData();
    		formData.append("file", file);
        	this.$store.dispatch('user/GrapherWorkerUpload', formData).then((res)=>{	            		
            	console.log("upload-cover",res);
            	that.folderForm.thumb = res.url;
            	this.folderThumbLoading = false;
            }).catch(err=>{
				ElMessage({
			        message: err.message,
			        type: "warning",
			    });
			    this.folderThumbLoading = false;
			})
	    },
		Edit(id,actid){
			console.log(this.type,"type");
			this.$router.push({name:'active_detail',query:{id: id, type: this.type, actid: actid}});
		},
		OffSale(id){
			this.$store("user/CameraWorkOffsale",{

			}).then(res=>{

			}).catch(err=>{
				ElMessage({
			        message: err.message,
			        type: "warning",
			    });
			})
		},
		DeleteWork(id){

		},
		addTopic() {
			this.showSubject = true;
		},
		close() {
			this.showSubject = false;
		},
		handleSizeChange(pageSize) {
	      this.pagination.pageSize = pageSize;
	      // 在此刷新数据
	    },
		handlePageChange(currentPage){
			this.pagination.current = currentPage;
			this.search();
		},
		search(){		
			this.CollectFolderList(this.type, this.pagination.current);
		},
		// loadMore() {
		// 	this.getSubjectList(this.subtype, this.page);
		// },
		
        getVideoFirstFrame(url, file) {
			const video = document.createElement('video') // 创建video对象
			video.src = url // url地址
			const canvas = document.createElement('canvas') // 创建 canvas 对象
			const ctx = canvas.getContext('2d') // 绘制2d
			video.crossOrigin = 'anonymous' // 解决跨域问题，也就是提示污染资源无法转换视频
			video.currentTime = 1 // 第一秒帧
			video.oncanplay = () => {
			    canvas.width = 90
			    canvas.height = 90
			    // 利用canvas对象方法绘图
			    ctx.drawImage(video, 0, 0, 90, 90)

			    // 转换成base64形式
			    let base64_file = canvas.toDataURL('image/png');
			    // Vue.set(file, 'imageShow', canvas.toDataURL('image/png')) // 获取视频第一帧作为imageShow展示
			}
		},
	}
})