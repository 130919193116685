import { onMounted, nextTick,defineComponent, ref, provide, reactive, toRefs, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import Swiper from '@/components/layout/Swiper.vue';
import SearchBar2 from '@/components/layout/SearchBar2.vue';
import Header2 from '@/components/layout/header2.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';
import SideMenu from '@/components/layout/SideMenu.vue';
import bus from 'vue3-eventbus'
import SideComponent from '@/components/common/SideComponent.vue';

export default defineComponent({
	components: {
		Header2,
		Menu,
	    Swiper,
	    SearchBar2,
	    Footer,
	    SideMenu,
	    SideComponent,
	},
	props: {

	},
	setup() {
		let {ctx, proxy} = getCurrentInstance();
		const data = reactive({
			showHeader: true,
			loginStyle: "min-width:141px;",
			mobileIconStyle: "color:rgba(223, 58, 8, 1);",
			showListIcon: true,
			activeName:'first',
			username:'',
			isMobile: false,
			thumb: '',
		});
		const showMenu = ref(proxy.$menus);
		provide('showMenu', showMenu);

		const outerStyleStr = ref("position:relative;left:10%;top:40%;transform:translate(-10%,-40%);width:auto;");
		provide('outerStyleStr', outerStyleStr);

		const styleStr = ref("height:45px;");
		provide('styleStr', styleStr);

		const showSearchText = ref(false);
		provide('showSearchText', showSearchText);

		const getImageUrl = (name) => {
		    return proxy.$utils.getImageUrl(name);
		};

		// bus.emit('needRefresh', true)

		const store = useStore();	

		const ruleFormRef = ref()

		const handleClick = (tab, event) => {
		  console.log(tab, event)
		}

		const validateOldPass = (rule, value, callback) => {
		  if (value === '') {
		    callback(new Error('请输入旧密码'))
		  } else {
		    if (ruleForm.oldpass !== '') {
		        if (!ruleFormRef.value) return
		        
		      // ruleFormRef.value.validateField('oldpass', (val) => {
		      // 	console.log(val,"val");
		      // })
		    }
		    callback()
		  }
		}

		const validatePass = (rule, value, callback) => {
		  if (value === '') {
		    callback(new Error('请输入新密码'))
		  } else {
		    if (ruleForm.checkPass !== '') {
		      if (!ruleFormRef.value) return
		      // ruleFormRef.value.validateField('checkPass', () => null)
		    }
		    callback()
		  }
		}
		const validatePass2 = (rule, value, callback) => {
		  if (value === '') {
		    callback(new Error('请确认新密码'))
		  } else if (value !== ruleForm.pass) {
		    callback(new Error("两次输入不一致!"))
		  } else {
		    callback()
		  }
		}

		const ruleForm = reactive({
			oldpass: '',
		    pass: '',
		    checkPass: '',
		  
		})

		const rules = reactive({
			oldpass: [
				{ required: true, message: '请输入旧密码', trigger: 'blur' },
				{ validator: validateOldPass, trigger: 'blur' }
			],
		    pass: [
		    	{ required: true, message: '请输入新密码', trigger: 'blur' },
		    	{ validator: validatePass, trigger: 'blur' }
		    ],
		    checkPass: [
		    	{ required: true, message: '请确认新密码', trigger: 'blur' },
		    	{ validator: validatePass2, trigger: 'blur' }
		    ],
		  
		})

		const submitForm = (formEl=undefined) => {
			bus.emit('needRefresh', true)
		  if (!formEl) return
		  formEl.validate((valid) => {
		    if (valid) {
		      console.log('submit!')
		    } else {
		      console.log('error submit!')
		      return false
		    }
		  })
		}

		const viewBox = ref(null);
		const scroll = (val) => {
			if(val == 'top'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		       
			}else if(val == 'bottom'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		        	
		        	
		        
			}
		}
        onMounted(async()=>{
        	getPersonInfo();
        	await nextTick()
        	setTimeout(() => {
		        data.isLoading = false;
		        viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
			    bus.emit('showFooter',true);
		    }, 600);
		    console.log("页面数据更新完毕了")
        })

        const getPersonInfo = () => {
			store.dispatch("user/getPersonInfo",{}).then((res)=>{
				console.log(res,"personInfo");
				// data.personInfo = res;
				data.username = res.username?res.username:res.mobile;
				data.thumb = res.thumb;
			}).catch(err => {
	            console.error(err)
	            ElMessage({
				    message: '获取用户信息失败',
				    grouping: true,
				    type: 'error',
				})
	        }) 
		}

		const refData = toRefs(data);

		return {
			...refData,
			getImageUrl,
			handleClick,
			ruleFormRef,
			ruleForm,
			rules,
			submitForm,
			viewBox,
			scroll,
			getPersonInfo,
			// resetForm,
		}
	},
	created() {

	},
	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	},
	methods: {
		init() {
			if(this.$utils.isMobile()){
	          	this.isMobile = true;           
	        } else {
	          	this.isMobile = false;
	        }
			// this.username = this.$cookies.getUsername()!="undefined" && this.$cookies.getUsername()?this.$cookies.getUsername():this.$cookies.getMobile();
		},
		Exit(){
			this.$cookies.removeToken();
			this.$cookies.removeUsername();
			// this.$cookies.removeMobile();
			this.$cookies.removeTokentype();
			this.$cookies.removeAuthKey();
			location.reload();
		},
	},
})