<template>
<SideComponent @scrollto="scroll"></SideComponent>
	<div class="container" ref="viewBox">
		<Header2 :show-search-bar-pic="showHeader" login-style="loginStyle" :mobile-icon-style="mobileIconStyle" :show-list-icon="showListIcon"/>
		<div class="content">
			<div style="height:80px;"></div>
			<div class="enclosure">
				<div class="inner">
					<div class="left">
						<SideMenu />
					</div>
					<div class="right">
						
						<div class="tag-outer">
							<el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
								<el-tab-pane :label="$t('active.pic')" name="first">
									<div class="work-status">
										<span class="work-status-span" :class="activeClass(item.id)" v-for="item in statusList" @click="GetListByStatus(item.id)">{{item.name}}</span>
									</div>
									<div class="work-list">
								    	<div class="work-list-items" v-for="(item,index) in worklist">
								    		<div class="work-list-item">
								    			<div class="img-outer">
								    				<img v-if="!item.thumb" src="@/assets/img/default.png" />
								    				<img v-if="item.thumb" :src="item.thumb" />
								    			</div>
								    		</div>
								    		<div class="work-list-item">
								    			<div class="work-info-title">{{item.title}}</div>
	
								    		</div>
								    		<div class="work-list-item">
								    			<div class="work-info-title">{{item.activity.title}}</div>
	
								    		</div>
								    		<div class="work-list-item">
								    			<span v-if="item.status==0" class="info-status info-warning">{{$t('status.reviewing')}}</span>
								    			<span v-if="item.status==1" class="info-status info-success">{{$t('status.reviewed')}}</span>
								    			<span v-if="item.status==2" class="info-status info-error">{{$t('status.not_review')}}</span>
								    			<span v-if="item.status==2" class="info-status-error">{{$t('apply_common.season')}}{{item.message}}</span>
								    		</div>
								    		<div class="work-list-item">
								    			<span v-if="item.status==2" class="info-status info-primary" @click="Edit(item.id,item.actid)">{{$t('apply_common.edit')}}</span>
								    			<!-- <span v-if="item.status==1&&item.onsale==1" class="info-status info-primary" @click="OffSale(item.id)">下架</span> -->
								    			<!-- <span class="info-status info-primary" @click="DeleteWork(item.id)">删除</span> -->
								    			<el-popconfirm 
								    				class="info-border-status info-border-green"
												    confirm-button-text="Yes"
												    cancel-button-text="No"
												    :icon="InfoFilled"
												    icon-color="#626AEF"
												    :title="$t('apply_common.del_tip')"
												    @confirm="DeleteWork(item.id)"
												    @cancel="cancelEvent"
												    v-if="item.status!=1"
												>
												    <template #reference>
												      <el-button>{{$t('apply_common.del')}}</el-button>
												    </template>
												</el-popconfirm>
								    		</div>
								    	</div>

							    	</div>
							    	<div class="page-wrapper">
							    		<Loading :is-loading="isPicLoading"></Loading>
										<el-pagination 
										    background 
										    layout="prev, pager, next" 
										    :total="pagination.total" 
										    v-model:current-page="pagination.current"
										    v-model:page-size="pagination.pageSize"	
										    @current-change="handlePageChange"			    
										/>

									</div>
							    </el-tab-pane>
							    <el-tab-pane :label="$t('active.video')" name="second">
							    	<div class="work-status">
										<span class="work-status-span" :class="activeClass(item.id)" v-for="item in statusList" @click="GetListByStatus(item.id)">{{item.name}}</span>
										
									</div>
							    	<div class="work-list">
								    	<div class="work-list-items" v-for="(item,index) in workVideoList">
								    		<div class="work-list-item">
								    			<div class="img-outer">
								    				<img v-if="!item.url" src="@/assets/img/default.png" />
								    				<video v-if="item.url" id="video+'0'"
														controls				    
													    preload="none" 
													    muted 
													    style="object-fit:contain;width:100%;height:100%;" 
													    controlslist="nodownload" 
													    oncontextmenu="return false"
													    :poster="item.thumb"
													    :src="item.url" >
														<track kind="captions" />
														
													</video>
								    			</div>
								    		</div>
								    		<div class="work-list-item">
								    			<div class="work-info-title">{{item.title}}</div>

								    		</div>
								    		<div class="work-list-item">
								    			<div class="work-info-title">{{item.activity.title}}</div>
	
								    		</div>
								    		<div class="work-list-item">
								    			<span v-if="item.status==0" class="info-status info-warning">{{$t('status.reviewing')}}</span>
								    			<span v-if="item.status==1" class="info-status info-success">{{$t('status.reviewed')}}</span>
								    			<span v-if="item.status==2" class="info-status info-error">{{$t('status.not_review')}}</span>
								    			<span v-if="item.status==2" class="info-status-error">{{$t('apply_common.season')}}{{item.message}}</span>
								    		</div>
								    		<div class="work-list-item">
								    			<span v-if="item.status==2" class="info-status info-primary" @click="Edit(item.id,item.actid)">{{$t('apply_common.edit')}}</span>
								    			<el-popconfirm 
								    				class="info-border-status info-border-green"
												    confirm-button-text="Yes"
												    cancel-button-text="No"
												    :icon="InfoFilled"
												    icon-color="#626AEF"
												    :title="$t('apply_common.del_tip')"
												    @confirm="DeleteWork(item.id)"
												    @cancel="cancelEvent"
												    v-if="item.status!=1"
												>
												    <template #reference>
												      <el-button>{{$t('apply_common.del')}}</el-button>
												    </template>
												</el-popconfirm>
								    		</div>
								    	</div>

							    	</div>
							    	<div class="page-wrapper" v-if="Object.keys(pagination).length > 0">
										<el-pagination 
										    background 
										    layout="prev, pager, next" 
										    :total="pagination.total" 
										    v-model:current-page="pagination.current"
										    v-model:page-size="pagination.pageSize"	
										    @current-change="handlePageChange"			    
										/>

									</div>
							    </el-tab-pane>

							</el-tabs>
							
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer/>
	</div>
</template>

<script>
	import collect_work from './collect_work_controller';
	export default collect_work;
</script>

<style src="@/assets/css/my/collect_work.css" scoped></style>