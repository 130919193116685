<template>
	<PageLoading :is-loading="isLoading"></PageLoading>
	<SideComponent @scrollto="scroll"></SideComponent>
	<div class="container" ref="viewBox">
		<div class="header-container">
			<Header2 />			
			<div class="search-swiper-container">
				<!-- <search-bar2></search-bar2> -->
			</div>
		</div>
		<div class="content">
			<div class="index-pic">				
				<div class="pic-wrapper">
					<search-bar-pic @refresh="getData" @searchKeywords="searchKeywords" :isNeedRedirect="isNeedRedirect"></search-bar-pic>
				</div>
			</div>
			<div class="pic-overview">
				<div class="view-text">
					<span class="text-1">{{$t('search_pic.tip1')}}</span>
					<div class="pic-count">
						{{$t('search_pic.tip2')}}<span class="pic-total">{{total}}</span>{{$t('search_pic.tip3')}}
					</div>
				</div>
			</div>
			<div class="img-content">
				<div class="enclosure">
					<Loading :is-loading="isPicLoading"></Loading>
					<div class="img-item"  v-for="item in picList" :style="activeStyle(item)" @click="toPicDetail(item.id)">						
						<img class="img" :src="item.preview" :alt="item.title" oncontextmenu="return false"/> <!-- style="width:{{item.ratio_w*300/item.ratio_h}}px;" -->
				  		<div class="title">
				  			<span>{{item.title}}</span>
				  		</div>						
					</div>
				</div>
				<!-- <section class="gallery-wrapper" v-if="picList.length>0">
					<div class="gallery-inner">
						<figure class="gallery-item" v-for="item in picList">
							<a class="img-wrapper" @click="toPicDetail(item.id)">
								<img class="ll_loaded" :src="item.watermark" :alt="item.title"  oncontextmenu="return false"/>
						  		<div class="title">{{item.title}}</div>
							</a>
						  	
						</figure>
						
					</div>
					
				</section> -->
				<!-- <div v-else style="width:100%;height:300px;display:flex;justify-content: center;align-items: center;font-size: 16px;">
					<span style="padding:10px;">没有搜索到相关图片</span>
				</div> -->

				
			</div>
			<div class="loadmore">
				<span class="more" @click="loadMore()" v-if="!nomore">{{$t('loadmore')}}</span>
				<span class="end" v-if="nomore">- {{$t('theEnd')}} -</span>
			</div>
			<!-- <div class="page-wrapper" v-if="Object.keys(pagination).length > 0">
				<el-pagination 
				    background 
				    layout="prev, pager, next" 
				    :total="pagination.total" 
				    v-model:current-page="pagination.current"
				    v-model:page-size="pagination.pageSize"				    
				/>

			</div> -->

		</div>
		<Footer/>
	</div>
</template>
<script>
	import searchPic from './search_pic_controller';
	export default searchPic;
</script>

<style src="@/assets/css/search-pic.css" scoped>
	/*@import url("@/assets/css/style.css");全局引用css*/
	/*@import '@/assets/css/style.css';*/
</style>