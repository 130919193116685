export default {
  "login": {
    "login": "залогин",
    "userName": "Имя пользователя.",
    "password": "код",
    name: 'Имя пользователя или наименование подразделения',
  },
  uernameTips: 'Индивидуальный пользователь, пожалуйста, заполните имя пользователя, пользователь подразделения, пожалуйста, заполните имя подразделения',
  "captcha": "Код подтверждения.",
  "forgetPassword": "Забыл пароль?",
  "loginTip": "Текущие результаты регистрации случайны.Заполните любой код",
  "editpassword": "Измени код.",
  "errMsg": {
    "inputRequired": "Введите.",
    "selectRequired": "Выбирай."
  },
  "title": "Похож на циндао",
  "logo_small_text": "Международный пресс-центр циндао",
  "hello": "Здравствуйте.",
  "loginOrRegister": "Вход/регистрация",
  "anonymity": "Анонимный пользователь",
  "personCenter": "Центр творчества",
  "logout": "Выход из системы.",
  "toLogin": "Заходите.",
  "mobileTip": "Номер телефона",
  "submitText": "представ",
  "accountTip": "Имя пользователя/номер телефона",
  "password": "Измени код.",
  newPassword: 'Новый пароль',
  confirmPassword: 'Подтвердить новый пароль',
  "noAccount": "Номера счетов еще нет?",
  "hasAccount": "Номер счета?",
  "register": "зарегистрирова",
  "phoneNumber": "Номер телефона.",
  "email": "Почтов ящик",
  "organizationName": "Единичное имя",
  "loginNow": "Залогинись немедленно.",
  "accountLogin": "Вход на аккаунт",
  "loginSuccess": "Получилось.",
  "loginFailed": "Система не работает.",
  "registerSuccess": "Регистрация прошла успешно.",
  "registerFailed": "Регистрация провалилась.",
  "enterUsername": "Введите имя пользователя",
  "enterPhoneNumber": "Пожалуйста, введите номер телефона",
  "enterRealname": "Пожалуйста, напишите настоящее имя",
  "enterCorrectUnitName": "Название единицы можно назвать комбинацией иероглифов или букв или цифр",
  "enterCorrectIdcard": "Пожалуйста, заполните идентификационный номер",
  "enterCorrectInstitutionName": "Пожалуйста, заполните правильный номер",
  "enterCorrectPhoneNumber": "Пожалуйста, введите правильный номер телефона",
  "enterCorrectEmail": "Пожалуйста, введите правильный почтовый ящик",
  "enterCorrectUsernameAndPhone": "Пожалуйста, введите имя пользователя/номер телефона",
  "nameContain": "Имя пользователя должно содержать буквы или цифры",
  passwordContain: 'Пароль требует комбинации не менее 8 цифр, букв, специальных символов (! $% ^ & * -)',
  passwordContain2: '密码需要至少8位数字、字母、特殊符号!@#$%^&*?-_组合',
  "enterPassword": "Введите пароль.",
  "passwordLength": "Длина пароля не менее 8 бит.",
  "qrlogin": "Логин по коду",
  "updateSuccess": "Обновление успешно.",
  "retrievePassword": "Найди пароль.",
  "pageUpdated": "Обновление данных страницы завершено",
  "cart1": "Тележка для покупок",
  "onlineService": "Служба поддержки.",
  "newsCenter": "Международный пресс-центр циндао",
  "qdnews": "Международный пресс-центр циндао",
  "hostUnit": "Прием:",
  "contactPhone": "Позвони.",
  "workTime": "Время работы.",
  "footerText1": "Много четкого материала",
  "footerText2": "Это коммерческое право",
  "aboutus": "О нас.",
  "copyrightNotice": "Заявление об авторском праве",
  "questions": "Общий вопрос",
  "terms": "Условия сайта",
  "selfRegulatory": "Конвенция об автономии на веб-платформе",
  "contractUs": "Свяжись с нами.",
  "friendLink": "Связь дружбы",
  "followUs": "Следите за нами.",
  "copyright": "Похоже на права циндао",
  "ICP": "Люип готов к 202301212351 - му",
  "category": "классификац",
  "pv": "Полный браузер:",
  "uv": "Общее число зарегистрированных:",
  qrcode:'Запись по коду',
  loadmore: 'Загрузить больше',
  theEnd: 'Конец',

  codeLogin:'Код проверки',
  getCode:'Получение кода проверки',
  enterCode:'Введите код проверки.',
  codetip:'Код проверки',
  viewmore:'Узнать больше',

  
  "menus": {
    "home": "Главн страниц",
    "news": "VCDS",
    "pic": "картинк",
    "video": "виде",
    "vr": "Цифровой музей",
    "grapher": "Оператор.",
    "active": "деятельн",
    "more": "Больше услуг.",
    "package": "Пакет цен",
    subject: 'Тематические вопросы',
  },
  contract:{
        tip1:'Дополнительный информация о контракте',
        tip2:'Подпись контракта',
        tip3:'Загрузка контракта',
        tip4:'Для обеспечения эффективности контракта и последующей гарантии, пожалуйста, заполните реальные документы, которые будут приобретены в более поздних периодах, которые автоматически будут использоваться в этом документе.',
        tip5:'Удостоверение.',
        tip6:'Введите идентификатор, пожалуйста',
        tip7:'Тип данных',
        tip8:'Личный пользователь',
        tip9:'Корпоративный орган',
        tip10:'Настоящее имя.',
        tip11:'Пожалуйста, введите настоящее имя',
        tip12:'Идентификационный номер',
        tip13:'Введите идентификационный номер',
        tip14:'Организационный орган',
        tip15:'Введите организационный орган',
        tip16:'Номер учреждения',
        tip17:'Введите номер агентства',
        tip18:'Почтов ящик',
        tip19:'Пожалуйста, введите почтовый ящик.',
        tip20:'Создать шаблон контракта',
        tip21:'Пожалуйста, прижмите мышку к левой клавише и напишите свою подпись',
        tip22:'Создавать подпись',
        tip23:'Очистить экран.',
        tip24:'Скачать контракт',
        tip25:'Пожалуйста, выберите тип пользователя',
        tip26:'Пожалуйста, введите настоящее имя',
        tip27:'Введите идентификационный номер',
        tip28:'Введите имя агентства',
        tip29:'Введите номер агентства',
        tip30:'Пожалуйста, введите почтовый ящик.',
        tip31:'Информация по контракту не сохранилась',
        tip32:'Получение информации по контракту не удалось',
        tip33:'Загрузка завершена.',

    },
    recharge:{
        tip1:'Сводка прибыли',
        tip2:'Обзор доходов',
        tip3:'Непогашенная сумма',
        tip4:'Расчетная сумма (юань)',
        tip5:'Заявка на расчет',
        tip6:'Порядок.',
        tip7:'Название работы',
        tip8:'Время оплаты.',
        tip9:'Сумма заказа',
        tip10:'Разделить прибыль',
        tip11:'Номер заказа:',
        tip12:'Запись расчета',
        tip13:'Время подачи заявки на урегулирование',
        tip14:'Ход расчета',
        tip15:'Время счета.',
        tip16:'Расчетная сумма',
        tip17:'Оставайся на месте.',
        tip18:'В расчете.',
        tip19:'Уже сделано.',
        
    },

  "search": {
    "search": "поиск",
    "hotSearch": "Поиск.",
    "takePic": "Поиск снимков",
    "dragPic": "Буксир размещает фотографии в любом месте в этой области",
    "or": "ил",
    "clickUpload": "Нажми на загрузку.",
    "sizeLimit": "Файлы jpg/png, которые меньше 50 мб, используются",
    "searchPic": "Ищи фотографии.",
    "searchVideo": "Проверь видео.",
    "pic": "картинк",
    "video": "виде",
    "trySearch": "Можно попытаться найти ключевые слова",
    "scenery": ".",
    "building": "здан",
    "culture": "культурн",
    "humanities": "гуманитарн",
    "educate": "образован",
    "isJPG": "Загрузка изображений может быть только в форматах JPG, PNG!"
  },
  "sideMenu": {
    "apply": "Проект фотографа",
    "account": "Управление счетом",
    "wallet": "Мой бумажник.",
    "cart": "Тележка для покупок",
    "authRecords": "Авторизованная запись.",
    "orderlist": "Список заказов.",
    collectManage: 'Сбор мероприятий',
    "collect": "Мероприятия",
    "folder": "Коллекция мероприятий",
    "personCenter": "Фотоцентр",
    "applyInfo": "Информация о посещении",
    "workManage": "Мои работы.",
    "recharge": "Центр прибыли"
  },
  "person": {
    "personCenter": "Центр творчества"
  },
  "application": {
    "settled": "Место фотографа",
    "process": "Войти в процесс",
    "plain": "Инструкция по вступлению.",
    "apply": "Подать заявление",
    "result": "Представлять результаты",
    "tipText1": "Добро пожаловать на то, чтобы стать фотографом, отображающим аосиму, и чтобы повысить шансы вашего вступления в должность, пожалуйста, заполните соответствующую информацию.",
    "tipText2": "Мы ответим на ваше заявление в течение трех рабочих дней, и вы сможете посмотреть, как идет процесс вступления",
    "apply_enter": "Заявка на въезд",
    "base_info": "Пожалуйста, заполните базовую информацию"
  },
  "form": {
    user_type: 'Тип пользователя',
    license: 'лицензия на ведение бизнеса',
    licenseTip: 'Лицензия на ведение деятельности подразделения, заверенная печатью электронная версия (или сертификат кода организации, заверенный печатью электронная версия)',
    type1: 'лица',
    type2: 'Организация подразделения',
    unitName:'Название подразделения',
    unitTip:'Пожалуйста, введите название устройства',
    "realname": "Настоящее имя.",
    "enterRealname": "Пожалуйста, введите настоящее имя",
    "gender": "гендерн",
    "male": "мужск",
    "female": "женщин",
    "photo": "Личные фотографии.",
    "enterPhoto": "Пожалуйста, загрузите личные фотографии",
    "uploadPhoto": "Загрузи фото.",
    biography: 'краткое содержание',
    enterBiography: 'Пожалуйста, заполните профиль',
    "mobile": "Номер телефона.",
    "enterPhone": "Пожалуйста, введите номер телефона",
    "email": "Электронная почта.",
    "enterEmail": "Пожалуйста, введите почтовый ящик.",
    company: 'Название подразделения',
    enterCompany: 'Пожалуйста, введите название устройства',
    "enterFeedType": "Пожалуйста, выберите тип донора",
    "ctype": "Тип профессии",
    "media": "Средств массов информац",
    "non_media": "Не сми.",
    "worktype": "Тип предложения",
    "image": "картинк",
    "video": "виде",
    "workPic": "Изображение представляет собой работу",
    "workPicTip": "(размещение качественных оригинальных работ в качестве справочных отсылки для размещения на местах)",
    "uploadPic": "Загрузи фото.",
    "againUploadPic": "Продолжайте загружать фотографии.",
    "againUploadVideo": "Продолжайте загружать видео.",
    "limit_pic_quantity": "10 оригинальных снимков, пожалуйста.",
    "workVideo": "Видео представляет собой работу",
    "uploadVideo": "Загрузи видео.",
    "limit_video_quantity": "Пожалуйста, предоставьте три оригинальных видео.",
    "submit_apply": "Подать заявление",
    "tip_text_1": "Ваш запрос на получение информации...",
    "tip_text_2": "Мы будем проверять информацию о вашем прибытии в течение трех рабочих дней.",
    "tip_text_3": "На этой странице вы можете увидеть результаты проверки!",
    "uploadPicWork": "Пожалуйста, загрузите фото",
    "uploadVideoWork": "Пожалуйста, загрузите видео",
    "enter_limit_pic": "Пожалуйста, загрузите 10 фотографий",
    "enter_limit_video": "Пожалуйста, загрузите три видео",
    "submited": "Заявка подана",
    "only_ten_pic": "Можно загрузить только 10 снимков",
    "only_three_video": "Только три видео загружено",
    "idcard": "Идентификационный номер",
    "idcard_photo": "Фото для опознания",
    "enter_idcard": "Введите идентификационный номер",
    "idcard_tip": "(Пожалуйста, загрузите лицо удостоверения личности)",
    "bankcard": "Номер банковской карточки",
    "enter_bankcard": "Введите номер банковской карточки",
    "bankcard_photo": "Фото с банковской карточки",
    "bankcard_tip": "Загрузите фотографии с номерами банковских карточек.",
    "preview": "Подайте и просмотрите контракт",
    "enterCorrectbankcardNumber": "Пожалуйста, заполните правильный номер банковской карточки",
    "enterIdcardPhoto": "Пожалуйста, загрузите идентификационные данные",
    "enterBankcardPhoto": "Пожалуйста, загрузите банковские карточки спереди"
  },
  "apply_contract": {
    "info": "Информация по контракту",
    "sign": "Подпись контракта",
    "tip1": "Для обеспечения законных прав обеих сторон, пожалуйста, введите идентификационную и банковскую информацию для создания электронного контракта, который будет успешным после подписания.",
    "tip2": "* информация, используемая только для подписания контракта и расчета прибыли, не может быть изменена после подписания контракта, пожалуйста, заполните ее!",
    "tip3": "Для обеспечения законных прав обеих сторон, я прошу вас внимательно прочесть содержание договора, который не будет изменен после подписания",
    "tip4": "Пожалуйста, заполните анкету",
    "tip5": "Предварительный просмотр контракта",
    "tip6": "Пожалуйста, прижмите мышку к левой клавише и напишите свою подпись",
    "tip7": "Создавать подпись",
    "tip8": "Очистить экран.",
    signSuccess:'Подпись удалась',
    noMatch:'Идентификационные данные не совпадают',
    submit_success:'Успешное представление',
    idcard_face:'Пожалуйста, загрузите (или перезагрузите) положительные фотографии удостоверения личности',
    upload_fail:'Ошибка загрузки',
  },
  "apply_info": {
    "tip1": "Добро пожаловать в кино, в аосиму, где вы можете загрузить свои работы и оценить возможность получения прибыли после принятия",
    "tip2": "Информация о посещении",
    "tip3": "Проверь контракт.",
    "tip4": "Электронный контракт"
  },
  "apply_nopass": {
    "tip1": "Ваш запрос на въезд не прошел...",
    "tip2": "Причина: после всесторонней оценки представленных вами работ экспертами, вы временно не соответствуете нашим требованиям на въезд!",
    "tip3": "Вы можете попытаться представить более качественные работы делегатов, и мы снова проведем комплексную оценку",
    "tip4": "Повторно представить"
  },
  "apply_pass": {
    "tip1": "Поздравляю, ваше заявление на въезд одобрено!",
    "tip2": "После того, как мы доработаем информацию, мы сможем загрузить работы и заработать на них деньги",
    "tip3": "Подписаться на проект"
  },
  "apply_passing": {
    "tip1": "Ваш запрос на получение информации...",
    "tip2": "Мы будем проверять информацию о вашем прибытии в течение трех рабочих дней.",
    "tip3": "На этой странице вы можете увидеть результаты проверки"
  },
  "apply_common": {
    "del": "удал",
    "addimg": "Добавьте изображение.",
    "addvideo": "Добавьте видео.",
    "batch_pics": "Множество фотографий для заполнения информации",
    "batch_videos": "Серия видео, заполненная единой информацией",
    "search_subject": "Поиск названия предмета",
    "upload_success": "Загрузка прошла успешно, ждем аудита",
    "upload_pic_eight": "Загрузка максимум 8 снимков за раз",
    "upload_video_eight": "Загрузка максимум 8 видео за раз",
    "size_exceed": "Размер не может превышать 50 м",
    "submit_success": "Представлять успех",
    "agreement": "Я прочитал и согласился",
    "protocols": "Соглашение о лицензировании контента, изображающее аосиму",
    "read": "Пожалуйста, прочитайте и одобрите соглашение о лицензировании контента, аналогичного аосиме.",
    "selectAll": "выбира",
    "off_pic": "После того, как вы нажмете на кнопку, изображение автоматически удаляется с сайта.",
    "del_pic": "После того, как вы нажмете на кнопку, изображение автоматически удаляется с сайта.",
    "batch_off_pic": "рассредоточиться",
    "batch_del_pic": "Массовое удаление",
    "season": "Причина:",
    "edit": "редактор",
    "off": "С полок",
    "off_video": "После того, как вы нажмете на кнопку, видео автоматически удаляется с сайта.",
    "del_video": "После удаления видео будет удалено с сайта.",
    "off_item": "Пожалуйста, выберите вариант, который вы выберете",
    "had_off": "Работа вышла из-под контроля",
    "del_item": "Пожалуйста, выберите вариант удаления",
    "had_del": "Работа удалена.",
    "del_tip": "После удаления не восстановиться.",
    "edit_folder": "Монтажный сборник",
    "add_folder": "Добавить сборник",
    "enter_folder_title": "Введите, пожалуйста, название набора",
    "enter_keyword_tip": "С каждым введенным словом нажмите \"enter\"",
    "add_to_folder": "Добавь сборник.",
    "select_folder": "Пожалуйста, выберите сборник",
    "author": "автор",
    "enter_author": "Введите автора.",
    "create_date": "Творческий день."
  },
  "apply_work": {
    year_limit: 'Год может быть только цифрой.',
    "tip0": "Проект фото",
    "tip1": "Простите, но вы не получили доступ к фотографиям",
    "tip2": "Совет: если вам нужна фотография для презентации, пожалуйста, перезвоните",
    "tip3": "Возобновить контракт.",
    "tip4": "Пожалуйста, выберите одну или несколько фотографий, поддерживающих несколько форматов изображений",
    "tip5": "Имя изображения",
    "tip6": "Введите имя, пожалуйста",
    "tip7": "Описание изображения",
    "tip8": "Введите инструкцию",
    "tip9": "Ключевое слово.",
    "tip10": "Введите ключевое слово",
    "tip11": "Название темы",
    "tip12": "Пожалуйста, выберите тему",
    "tip13": "Новая тема",
    "tip14": "Режим малой диаграммы",
    "tip15": "Малая цена",
    "tip16": "Введите маленькую сумму",
    "tip17": "Исходный режим карты",
    "tip18": "Первоначальная цена",
    "tip19": "Введите первоначальную цену",
    "tip20": "Режим большой картины",
    "tip21": "Большая цена",
    "tip22": "Пожалуйста, введите большую цену",
    "tip23": "открыт",
    "tip24": "отключ",
    "tip25": "Представлять на рассмотрение",
    "tip26": "Видео-поддержка.",
    "tip27": "Простите, но вы не запрашивали доступ к видео",
    "tip28": "Совет: если вам нужно видео для презентации, пожалуйста, перезвоните",
    "tip29": "Пожалуйста, выберите одно или несколько видео для поддержки нескольких форматов",
    "tip30": "Имя видео",
    "tip31": "Пожалуйста, введите имя видео",
    "tip32": "видеоинструкция",
    "tip33": "Пожалуйста, введите видео инструкцию",
    "tip34": "Название темы",
    "tip35": "Пожалуйста, введите название предмета",
    "tip36": "Классификация по теме",
    "tip37": "Пожалуйста, выберите тему для сортировки",
    "tip38": "Обложка темы",
    "tip39": "Описание темы",
    "tip40": "Введите описание, пожалуйста",
    "tip41": "представ",
    "tip42": "Пожалуйста, загрузите обложку",
    "more": "Загружай.",
    "price_pack": "Пакет по цене",
    "select_price_pack": "Пожалуйста, выберите пакет",
    "pic_limit": "Максимум 8 снимков",
    "video_limit": "Загрузи максимум 8 видео",
    "topic_price_pack": "Пакет с ценами на обложку",
    "year": "год",
    "yearTip": "Введите год.",
    coverTip: 'Это всего лишь обложка, которую нужно переслать еще раз, пока не будет готов снимок.',
    has_similar: 'Не повторяйте изображения.',
    similar: 'Изображения повторяются',
  },
  "no_settled": {
    "tip1": "Прости, но я не могу писать, пока не добьюсь успеха",
    "tip2": "Вы не сертифицированы как официальный фотограф",
    "tip3": "Место фотографа"
  },
  "index": {
    "hot": "Горячие рекомендации.",
    "grapher": "Хороший фотограф",
    "image": "картинк",
    "video": "виде"
  },
  "news": {
    "news": "VCDS",
    "publish_time": "Время релиза:"
  },
  "package": {
    name: 'Комплексное название',
    content: 'Право на эксклюзивное содержание',
    expire: 'срок',
    day: 'дне',
    buy: 'Покупать пакет',
    between: 'Доступ.',
    min: 'маленьк',
    original: 'оригина',
    max: 'больш',
    tip1: '1500 Доллар/сетка',
    tip2:'2200 Доллар/сетка',
    tip3: 'Подметай и плати',
    order_sn: 'Номер заказа:',
    price:'Сумма заказа:',
    open:'откр',
    scan: 'Плата за чистку.',
    wechatPay: 'Микровера.',
    alipay: 'Плати, детка.',
    weixin: 'wechat',
    ali:'Плати.',
    select:'Пожалуйста, выберите пакет',
    tip4: 'картинк',
    tip5: 'Одно видео.',
    tip6: 'Видеопакет.',
  },
  "grapher": {
    "title": "Оператор.",
    "home": "Главн страниц",
    "grapher": "Хороший фотограф",
    "tip1": "В списке фамилий, без разбору.",
    "tip2": "Похож на аосиму, подписавшего фотографа"
  },
  "search_pic": {
    "tip1": "Схожее изображение",
    "tip2": "Найди Джо.",
    "tip3": "Запись."
  },
  "topics": {
    "tip1": "Актуальная тема",
    "home": "Главн страниц"
  },
  "pic": {
    "tip1": "Местонахождение:",
    "tip2": "Инструкции:",
    "tip3": "Лицензия на определённый диапазон использования (Royalty-Free, сокращенное «RF») позволяет неограниченное и постоянное использование неопределённого диапазона лицензионного содержания, которое можно использовать в зависимости от размера файла, без водяного знака после загрузки.Подробности:",
    "tip4": "Протокол авторизации контента аосимы",
    "tip5": "Присоединяйся к тележке",
    "tip6": "Покупайте по одному.",
    "tip7": "Если требуется запрос на закупку, звоните по номеру 05328825531 или консультируйтесь",
    "tip8": "Служба поддержки.",
    "tip9": "Фото:",
    "tip10": "Изображение:",
    "tip11": "Фото:",
    "tip12": "Размер изображения:",
    "tip13": "Тип изображения:",
    "tip14": "Размер изображения:",
    "tip15": "Цена изображения:",
    "tip16": "Год фотографии:",
    "tip17": "Изображение:",
    "tip18": "Ключевое слово.",
    "tip19": "Аналогичная фотография"
  },
  "video": {
      text1: 'Идентификатор видео:',
      text2: 'Заголовок видео:',
      text3: 'Тип видео:',
      text4: 'Продолжительность видео:',
      text5: 'Размер видео:',
      tip1: 'Ключевые слова',
      tip2: 'Похожие видео рекомендации',
      desc: 'Введение',
  },
  "vr": {
    "text1": "Выставочный зал",
    "text2": "подробн"
  },
  "order_detail": {
    "text1": "Подробности приказа",
    "text2": "Инструкция по обслуживанию",
    "text3": "Если вам нужно выписать счет, обратитесь в службу поддержки клиентов:",
    "text4": "Номер заказа:",
    "text5": "Сумма заказа:",
    "text6": "Сумма выплаты:",
    "text7": "Статус платежа:",
    "text8": "Оплата.",
    "text9": "Оплачено.",
    "text10": "Способ оплаты:",
    "text11": "Пакет.",
    "text12": "Микровера.",
    "text13": "Плати, детка.",
    "text14": "баланс",
    "text15": "Время оплаты:",
    "text16": "Следующее время:",
    "text17": "Изображение.",
    "text18": "Сгруппироваться:",
    "text19": "Предварительный просмотр фотографии",
    "text20": "Имя изображения",
    "text21": "Спецификация изображения",
    "text22": "Цена изображения",
    "text23": "Выбрать способ оплаты",
    "text24": "Выберите пакет оплаты",
    "text25": "Действительная сумма платежа:",
    "text26": "Представлять приказ",
    "text27": "Баланс счета оплачен",
    "text29": "Микровера.",
    "text30": "Поддержка минимальных платежей",
    "text31": "Плати, детка.",
    "text32": "Поддержка выплаты по paw",
    "text33": "Доступный баланс счета",
    "text34": "Если вы уже приобрели пакет на своей странице в \"праймериз\", пожалуйста, выберите пакет в первую очередь для оплаты.",
    "text35": "Оплата завершена.",
    "text36": "Неудачная выплата.",
    "origin_1": "Оригинальная картина:",
    "small_1": "Рисунок:",
    "big_1": "Картина:",
    "origin": "оригина",
    "small": "маленьк",
    "big": "больш",
    "maohao": ":"
  },
  "order_pay": {
    "text1": "Приказ.",
    "text2": "Предварительный заказ",
    "tip1": "Пожалуйста, выберите цену фотографии",
    "tip2": "Есть только Один вариант"
  },
  "order_list": {
    text0: 'Список заказов.',
    "text1": "Имя изображения:",
    "text2": "Создание времени:",
    "text3": "поиск",
    "text4": "Приказ:",
    "text5": "Номер заказа:",
    "text6": "Следующее время:",
    "text7": "Разрешение:",
    "text8": "Подробности приказа",
    "text9": "Скачать контракт",
    "text10": "Цена:",
    "text11": "Время начинать.",
    "text12": "Конец.",
    "text13": "по",
    "wait_pay": "Оплата.",
    "pay_success": "Оплата прошла успешно.",
    "paid": "Оплачено.",
    "package": "Пакет.",
    "wechatPay": "Микровера.",
    "alipay": "Плати, детка.",
    "balance": "баланс"
  },
  "setting": {
    "text1": "Измени код.",
    "text2": "Информация пользователей.",
    "text3": "Номер телефона"
  },
  "auth": {
    "text1": "Список авторизации",
    "text2": "Имя изображения:",
    "text3": "Время авторизации:",
    "text4": "Предварительный просмотр фотографии",
    "text5": "Заголовок изображения",
    "text6": "Спецификация изображения",
    "text7": "Разрешение изображения",
    "text8": "операцион",
    "text9": "загрузк"
  },
  "wallet": {
    "text1": "Мой бумажник.",
    "text2": "Располагаемый баланс",
    "text3": "Запись баланса",
    "text4": "тип",
    "text5": "сумм",
    "text6": "врем",
    "text7": "На данный момент никаких записей о балансе"
  },
  "cart": {
    "text1": "Тележка для покупок",
    "text2": "(",
    "text3": "товар",
    "text4": "операцион",
    "text5": "Предварительный просмотр фотографии",
    "text6": "Заголовок изображения",
    "text7": "Спецификация изображения",
    "text8": "цен",
    "text9": "удал",
    "text10": "Я прочитал и согласился",
    "text11": "Соглашение о лицензировании киноострова",
    "text12": "\"Заявление о неприкосновенности частной жизни\"",
    "text13": "Выбрать товар",
    "text14": "Общ сумм",
    "text15": "расчетн",
    "text16": "-",
    "clearCart": "Освободите тележку",
    "batchDel": "Массовое удаление"
  },
  "activity": {
    "actName": "На мероприятие.",
    "selectAct": "Пожалуйста, выберите занятие",
    "selectActName": "Поиск названия деятельности",
    "category": "классификац",
    "select_cate": "Избирательная классификация"
  },
  "active": {
    "upload": "Загрузка.",
    "pic": "картинк",
    "video": "виде",
    "recommend": "рекомендова",
    "tip7": "Описание изображения",
    "tip9": "Ключевое слово.",
    "folder_title": "заголовк",
    "enter_title": "Введите название.",
    "folder_desc": "описыва",
    "enter_desc": "Составное описание",
    "cover_type": "Тип обложки.",
    "folder_cover": "обложк",
    "cover_type_1": "Последняя работа.",
    "cover_type_1_tip": "Автоматическое включение последних работ в сборник на обложку",
    "cover_type_2": "Стационарная обложка.",
    "cover_type_2_tip": "Локальная загрузка изображений как фиксированной обложки",
    "operate_success": "Операция выполнена."
  },
  "status": {
    "all": "все",
    "reviewing": "Проверка.",
    "reviewed": "Одобрено.",
    "not_review": "Проверка не прошла"
  },
  
}