<template>
<SideComponent @scrollto="scroll"></SideComponent>
	<div class="container" ref="viewBox">
		<Header2 :show-search-bar-pic="showHeader" login-style="loginStyle" :mobile-icon-style="mobileIconStyle" :show-list-icon="showListIcon"/>
		<div class="content">
			<div style="height:80px;"></div>
			<div class="enclosure">
				<div class="inner">
					<div class="left">
						<SideMenu />
					</div>
					<div class="right">
						
						<div class="tag-outer">
							<el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
								<el-tab-pane :label="$t('recharge.tip1')" name="first" class="tab-pane-style">
									<div class="work-list">
										<div class="work-head">
											<span>{{$t('recharge.tip2')}}</span>
										</div>
								    	<div class="work-list-items">
								    		<div class="work-list-item">
								    			<span style="font-size:12px;">{{$t('recharge.tip3')}}</span>
								    			<span style="font-size:18px;font-weight:700;color:rgb(230,0,15);">{{rechargeInfo.已结算金额}}</span>
								    		</div>
								    		<div class="work-list-item">
								    			<span style="font-size:12px;">{{$t('recharge.tip4')}}</span>
								    			<span style="font-size:16px;font-weight:700;">{{rechargeInfo.已结算金额}}</span>
								    		</div>
								    		<div class="work-list-item-1">
								    			
								    		</div>
								    		<div class="work-list-item-1">
								    			<span class="income-detail" :disabled="disabled" @click="CameraRecharge()">{{$t('recharge.tip5')}}</span>
								    			
								    		</div>
								    	</div>

							    	</div>
							    	<div class="work-list" style="margin-top:50px;">
										<div class="work-head">
											<span style="padding:10px 0;">{{$t('recharge.tip6')}}</span>
										</div>
								    	<div class="order-list-items">
								    		<div class="order-head">
								    			<div class="item0">{{$t('recharge.tip7')}}</div>
								    			<div class="item1">{{$t('recharge.tip8')}}</div>
								    			<div class="item2">{{$t('recharge.tip9')}}</div>
								    			<div class="item3">{{$t('recharge.tip10')}}</div>
								    		</div>
								    		<div class="order-item" v-for="item in orderlist" v-if="orderlist.length>0">
								    			<div class="order-item-head">
								    				<span>{{$t('recharge.tip11')}}</span><span>{{item.order_sn}}</span>
								    			</div>
								    			<div class="order-item-body">
								    				<div class="body-item item0">
								    					<div class="subitem-1 img-outer">
								    						<img v-if="!item.thumb" src="@/assets/img/default.png" />
								    						<img v-if="item.thumb" :src="item.thumb" />
								    					</div>
								    					<div class="subitem-1">
								    						<span style="word-break: break-all;">{{item.title}}</span>
								    					</div>
								    				</div>
								    				<div class="body-item item1">
								    					<div class="subitem">{{item.updated_at}}</div>
								    				</div>
								    				<div class="body-item item2">
								    					<div class="subitem">{{item.pay_price}}</div>
								    				</div>
								    				<div class="body-item item3">
								    					<div class="subitem">{{item.income}}</div>
								    				</div>
								    			</div>
								    		</div>
								    	</div>

							    	</div>
							    	<div class="page-wrapper" v-if="Object.keys(pagination).length > 0">
										<el-pagination 
										    background 
										    layout="prev, pager, next" 
										    :total="pagination.total" 
										    v-model:current-page="pagination.current"
										    v-model:page-size="pagination.pageSize"	
										    @current-change="handlePageChange"			    
										/>

									</div>
							    </el-tab-pane>
							    <el-tab-pane :label="$t('recharge.tip12')" name="second" class="tab-pane-style">
							    	<!-- <div class="work-list">
										<div class="work-head">
											<span>收入概览</span>
										</div>
								    	<div class="work-list-items">
								    		<div class="work-list-item">
								    			<span style="font-size:10px;">累计收入（元）</span>
								    			<span style="font-size:18px;font-weight:700;color:rgb(230,0,15);">0.00</span>
								    		</div>
								    		<div class="work-list-item">
								    			<span style="font-size:10px;">昨日收入（元）</span>
								    			<span style="font-size:16px;font-weight:700;">0.00</span>
								    		</div>
								    		<div class="work-list-item">
								    			<span style="font-size:10px;">近7日收入（元）</span>
								    			<span style="font-size:18px;font-weight:700;">0.00</span>
								    		</div>
								    		<div class="work-list-item">
								    			<span style="font-size:10px;">近30日收入（元）</span>
								    			<span style="font-size:18px;font-weight:700;">0.00</span>
								    		</div>
								    	</div>

							    	</div> -->
							    	
							    	<div class="work-list" style="margin-top:50px;">
										<div class="work-head">
											<span style="padding:10px 0;">{{$t('recharge.tip12')}}</span>
										</div>
								    	<div class="order-list-items">
								    		<div class="order-head">
								    			<div style="width:30%;">{{$t('recharge.tip13')}}</div>
								    			<div style="width:20%;">{{$t('recharge.tip14')}}</div>
								    			<div style="width:30%;">{{$t('recharge.tip15')}}</div>
								    			<div style="width:20%;">{{$t('recharge.tip16')}}</div>
								    		</div>
								    		<div class="order-item">
								    			<div class="order-item-body" v-for="item in logList" v-if="logList.length>0">
								    				<div class="body-item" style="width:30%;">
								    					<div class="subitem">{{item.created_at}}</div>
								    				</div>
								    				<div class="body-item" style="width:20%;">
								    					<div class="subitem">{{item.status==0?$t('recharge.tip17'):(item.status==1?$t('recharge.tip18'):(item.status==2?$t('recharge.tip19'):''))}}</div>
								    				</div>
								    				<div class="body-item" style="width:30%;">
								    					<div class="subitem">{{item.updated_at}}</div>
								    				</div>
								    				<div class="body-item" style="width:20%;">
								    					<div class="subitem">{{item.money}}</div>
								    				</div>
								    			</div>
								    		</div>
								    	</div>

							    	</div>
							    	<div class="page-wrapper" v-if="Object.keys(logpagination).length > 0">
										<el-pagination 
										    background 
										    layout="prev, pager, next" 
										    :total="logpagination.total" 
										    v-model:current-page="logpagination.current"
										    v-model:page-size="logpagination.pageSize"	
										    @current-change="handlelogPageChange"			    
										/>

									</div>
							    </el-tab-pane>

							</el-tabs>
							
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer/>
	</div>
</template>

<script>
	import recharge from './recharge_controller';
	export default recharge;
</script>

<style src="@/assets/css/my/grapher/recharge.css" scoped></style>