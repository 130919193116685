import { onMounted,nextTick, defineComponent, ref, provide, reactive, toRefs, getCurrentInstance } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import Swiper from '@/components/layout/Swiper.vue';
import SearchBar2 from '@/components/layout/SearchBar2.vue';
import Header2 from '@/components/layout/header2.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';
import SideMenu from '@/components/layout/SideMenu.vue';
import bus from 'vue3-eventbus'
import SideComponent from '@/components/common/SideComponent.vue';
import Loading from '@/components/common/Loading.vue';
import { InfoFilled } from '@element-plus/icons-vue'

export default defineComponent({
	components: {
		Header2,
		Menu,
	    Swiper,
	    SearchBar2,
	    Footer,
	    SideMenu,
	    SideComponent,
	    Loading,
	},
	props: {

	},
	setup() {
		let {ctx, proxy} = getCurrentInstance();
		const route = useRoute();
		let type = route.query.type?route.query.type:0;
		const data = reactive({
			showHeader: true,
			loginStyle: "min-width:141px;",
			mobileIconStyle: "color:rgba(223, 58, 8, 1);",
			showListIcon: true,
			activeName: 'first',
			formSize: 'default',			
			pagination: {},
			page: 1,
			keywords: '',
			allCats: [],
			nomore: true,
			type: 1,
			worklist: [],
			workVideoList: [],

			checkedAll: false,
			checkedAllPics: false,
			checkedAllVideos:false,
		    // 设置选项复选框初始状态为未选中,由于是一个数组,所以值为[],即为空
		    checkedItem: [],
		    // 设置选中全选复选框后重新给选项复选框赋值,即选中所有的选项复选框
		    checkedAttr: [],
		    statusList: [
				{
					id:-1,
					name: proxy.$t('status.all'),
					total: 0,
				},
				{
					id:0,
					name: proxy.$t('status.reviewing'),
					total: 0,
				},
				{
					id:1,
					name: proxy.$t('status.reviewed'),
					total: 0,
				},
				{
					id:2,
					name: proxy.$t('status.not_review'),
					total: 0,
				},
			],
			selectedStatus: -1,
		    all_total: 0,
		    passing_total: 0,
		    nopass_total: 0,
		    passed_total: 0,
		});
		if(type > 0){
			data.type = type;
			if(type==1){
				data.activeName = 'first';
			}else if(type==2){
				data.activeName = 'second';
			}
		}
		const showMenu = ref(proxy.$menus);
		provide('showMenu', showMenu);

		const outerStyleStr = ref("position:relative;left:10%;top:40%;transform:translate(-10%,-40%);width:auto;");
		provide('outerStyleStr', outerStyleStr);

		const styleStr = ref("height:45px;");
		provide('styleStr', styleStr);

		const showSearchText = ref(false);
		provide('showSearchText', showSearchText);

		const getImageUrl = (name) => {
		    return proxy.$utils.getImageUrl(name);
		};

		// bus.emit('needRefresh', true)
		
		const store = useStore();	
		
		const allCategorys = () => {
			
			store.dispatch("common/getAllSubjectCats",{

	    	}).then((res)=>{		      	
		      	data.allCats = res;
		      	console.log("allCategorys",data.allCats);
		    }).catch(err => {
	            console.error(err)
	            
	        })
		}

			
		const viewBox = ref(null);
        const scroll = (val) => {
			if(val == 'top'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		       
			}else if(val == 'bottom'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
   
			}
		}
		
		const CameraWorkList = (type=1, page=1,status=-1,needChangeData=false) => {
			store.dispatch("user/CameraWorkList",{
				type: type,
				page: page,
				status: status,
			}).then((res)=>{
				data.checkedItem = [];
				data.checkedAttr = [];
				data.checkedAllPics = false;
				data.checkedAllVideos = false;
				data.checkedAttr = res.data.map((item,index,array)=>{
					// item.isChecked = false;
					return item.id;
				});

				if(type == 1){
					console.log(res,"worklist");
					data.worklist = res.data;
				}else if(type == 2){
					console.log(res,"workVideoList");
					data.workVideoList = res.data;
				}
				data.pagination = res.pagination;
				data.page = res.pagination.current;
				
				for(let i=0; i<data.statusList.length; i++){
					if( parseInt(data.statusList[i].id) == -1 ){
						data.statusList[i].total = res.stat.all;
					}else if( data.statusList[i].id == 1 ){
						data.statusList[i].total = res.stat.success;
					}else if( data.statusList[i].id == 2 ){
						data.statusList[i].total = res.stat.failed;
					}else if( data.statusList[i].id == 0 ){
						data.statusList[i].total = res.stat.pending;
					}
				}
				
				bus.emit('showFooter',true);
			}).catch(err=>{

			})
		}

		const GetListByStatus = (status) => {
			data.selectedStatus = status;
			data.page = 1;
			// console.log(data.type,"data.type")
			// console.log(data.page,"data.page")
			// console.log(data.selectedStatus,"data.selectedStatus")
			// console.log(data.folder_id,"data.folder_id")
			// for(let i = 0;i < data.statusList.length;i++){
			// 	data.statusList[i].total = 0;
		 //    }
		 	
			CameraWorkList(data.type, data.page, data.selectedStatus);
		}

		const handleClick = (tab, event) => {
		  console.log(tab.paneName, "tabs")

		  data.checkedItem = [];
		  data.checkedAttr = [];
		  data.checkedAllPics = false;
		  data.checkedAllVideos = false;

		  data.page = 1;
		  data.selectedStatus = -1;
		  if(tab.paneName == 'first'){
		  	data.type = 1;
		  }else if(tab.paneName == 'second'){
		  	data.type = 2;
		  }
		  for(let i = 0;i < data.statusList.length;i++){
				data.statusList[i].total = 0;
		  }
		  CameraWorkList(data.type, data.page, data.selectedStatus,true);
		}

        onMounted(async()=>{
        	CameraWorkList(data.type, data.page, data.selectedStatus,true);
        	// CameraWorkList(data.type, 1);
        	// allCategorys();
        	await nextTick()
        	setTimeout(() => {
		      data.isLoading = false;
		      viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		    }, 600);
		    console.log("页面数据更新完毕了")
        })

		const refData = toRefs(data);

		return {
			...refData,
			viewBox,
			scroll,	
			allCategorys,
			CameraWorkList,
			handleClick,
			GetListByStatus,
		}
	},
	created() {

	},
	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	},
	watch: {
	    // 监听复选框项是否全部选中
	    checkedItem() {
	    	
	    	this.sumPrice = 0;
	    	this.imgids = [];
	    	this.checkList = [];
	    	console.log(this.checkedItem,"checkedItem");
	      	// 判断选中选项复选框的值数组的长度是否与设定的长度相等，如果相等则所有的选项复选框被选中，否则没有被选中。
	      	if (this.checkedItem.length == this.checkedAttr.length) {
	        	// 如果所有复选框项全部被选中,则全选复选框也被勾选
	        	this.checkedAll = true;
	      	} else {
	        	// 否则复选框不勾选
	        	this.checkedAll = false;
	      	}

	    },

	},
	methods: {
		init() {
			// console.log("this.allCategorys", this.allCats);
		},
		activeClass(id){
			if(this.selectedStatus == id){
				return 'active';
			}
			return '';
		},
		// 全选复选框状态改变监听事件
	    changeAllChecked() {
	        // 判断全选复选框是否选中
	        if (this.checkedAllPics) {
	            // 如果全选复选框被选中,则重新给选项复选框赋值,即选中所有的选项复选框
	            this.checkedItem = this.checkedAttr;
	        } else {
	            // 如果全选复未选框被选中,则设置为空值,即未选中所有的选项复选框
	            this.checkedItem = [];
	        }
	    },
	    changeVideoAllChecked() {
	        // 判断全选复选框是否选中
	        if (this.checkedAllVideos) {
	            // 如果全选复选框被选中,则重新给选项复选框赋值,即选中所有的选项复选框
	            this.checkedItem = this.checkedAttr;
	        } else {
	            // 如果全选复未选框被选中,则设置为空值,即未选中所有的选项复选框
	            this.checkedItem = [];
	        }
	    },
		Edit(id){
			this.$router.push({name:'apply_work',query:{id:id,type:this.type}});
		},
		OffSale(id=0){
			let that = this;
			let ids = [];
			ids = (id instanceof Array) ? id : [id];
			if(id==0) {
				ids = this.checkedItem;
			}
			if(ids.length==0){
				ElMessage({
			        message: that.$t('apply_common.off_item'),
			        type: "warning",
			    });
			}else{
				this.$store.dispatch("user/CameraWorkOffsale",{
					ids: ids
				}).then(res=>{
					ElMessage({
				        message: that.$t('apply_common.had_off'),
				        type: "success",
				    });
				    // location.reload();
				    that.CameraWorkList(that.type,that.page);
				}).catch(err=>{
					ElMessage({
				        message: err.message,
				        type: "warning",
				    });
				})
			}
			
		},
		confirmEvent(id=0){
			let that = this;
			let ids = [];
			ids = (id instanceof Array) ? id : [id];
			if(id==0) {
				ids = this.checkedItem;
			}
			if(ids.length==0){
				ElMessage({
			        message: that.$t('apply_common.off_item'),
			        type: "warning",
			    });
			}else{
				this.$store.dispatch("user/CameraWorkOffsale",{
					ids: ids
				}).then(res=>{
					ElMessage({
				        message: that.$t('apply_common.had_off'),
				        type: "success",
				    });
				    // location.reload();
				    that.CameraWorkList(that.type,that.page);
				}).catch(err=>{
					ElMessage({
				        message: err.message,
				        type: "warning",
				    });
				})
			}
			
		},
		cancelEvent(){

		},
		DeleteWork(id=0){
			let that = this;
			let ids = [];
			ids = (id instanceof Array) ? id : [id];
			if(id==0) {
				ids = this.checkedItem;
			}
			if(ids.length==0){
				ElMessage({
			        message: that.$t('apply_common.del_item'),
			        type: "warning",
			    });
			}else{
				this.$store.dispatch("user/CameraWorkDelete",{
					ids: ids
				}).then(res=>{
					console.log(res,"res");
					ElMessage({
				        message: that.$t('apply_common.had_del'),
				        type: "success",
				    });
				    // location.reload();
				    that.CameraWorkList(that.type,that.page);
				}).catch(err=>{
					ElMessage({
				        message: err.message,
				        type: "warning",
				    });
				})
			}
		},
		addTopic() {
			this.showSubject = true;
		},
		close() {
			this.showSubject = false;
		},
		handleSizeChange(pageSize) {
	      this.pagination.pageSize = pageSize;
	      // 在此刷新数据
	    },
		handlePageChange(currentPage){
			this.pagination.current = currentPage;
			this.search();
		},
		search(){		
			this.CameraWorkList(this.type, this.pagination.current);
		},
		// loadMore() {
		// 	this.getSubjectList(this.subtype, this.page);
		// },
		
        getVideoFirstFrame(url, file) {
			const video = document.createElement('video') // 创建video对象
			video.src = url // url地址
			const canvas = document.createElement('canvas') // 创建 canvas 对象
			const ctx = canvas.getContext('2d') // 绘制2d
			video.crossOrigin = 'anonymous' // 解决跨域问题，也就是提示污染资源无法转换视频
			video.currentTime = 1 // 第一秒帧
			video.oncanplay = () => {
			    canvas.width = 90
			    canvas.height = 90
			    // 利用canvas对象方法绘图
			    ctx.drawImage(video, 0, 0, 90, 90)

			    // 转换成base64形式
			    let base64_file = canvas.toDataURL('image/png');
			    // Vue.set(file, 'imageShow', canvas.toDataURL('image/png')) // 获取视频第一帧作为imageShow展示
			}
		},
	}
})