<template>
	<PageLoading :is-loading="isLoading"></PageLoading>
	<SideComponent @scrollto="scroll"></SideComponent>
	<div class="container" ref="viewBox">
		<Header2 :show-search-bar-pic="showHeader" login-style="loginStyle" :mobile-icon-style="mobileIconStyle" :show-list-icon="showListIcon"/>
		<div class="content">
			<div style="height:60px;" class="other"></div>
			<div class="detail-container">
				<div class="outer">
					<div class="cats">
						<!-- <span>当前位置：</span><span>创意图片</span><i class="iconfont icon-jiantou"></i><span>青岛风光</span> -->
					</div>
					<div class="a1">
						<div class="detail-left">
							<div class="img-outer">
								<video 
								       controls 
								       autoplay 
								       :poster="videoDetail.thumb" 
								       :alt="videoDetail.title"
								       muted=""
								       style="object-fit:cover;width:100%;height:100%;" 
								       controlslist="nodownload" 
								       :src="videoDetail.url"
								       >
									<!-- oncontextmenu="return false" oncanplaythrough="canPlay()" -->
									<track default kind="captions" />
									<!-- <source :src="videoDetail.url" type="video/mp4"> -->
								</video>

							</div>
							
						</div>
						<div class="detail-right">
							<div class="b1">
								<span>{{videoDetail.title}}</span>
							</div>
							<div class="b3">
								<i class="iconfont icon-qizhi1"></i><span>征集活动 | {{activity.title}}</span>							
							</div>
							<div class="b4">
								<div class="pic-detail">
									<div class="detail-item">
										<span class="title">{{$t('video.text1')}}</span><span class="item-content">{{videoDetail.id}}</span>
									</div>
									<div class="detail-item">
										<span class="title">{{$t('video.text2')}}</span><span class="item-content">{{videoDetail.title}}</span>
									</div>
									<div class="detail-item">
										<span class="title">{{$t('video.text3')}}</span><span class="item-content">{{videoDetail.extension}}</span>
									</div>
									<div class="detail-item">
										<span class="title">{{$t('video.text4')}}</span><span class="item-content">{{videoDetail.duration}}</span>
									</div>
									<div class="detail-item">
										<span class="title">{{$t('video.text5')}}</span><span class="item-content">{{videoDetail.size?videoDetail.size+'k':''}}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
			</div>
			<div class="middle-content">
				<div class="middle-wrapper">
					<!-- <div class="middle-first">
						<span class="ftip">此素材包含的地点信息:</span>
						<span class="fposition">中国</span>
						<span class="fposition">山东省</span>
						<span class="fposition">青岛</span>
					</div> -->
					<div class="middle-second">
						<div class="mkeywords">
							<span class="kw">{{$t('video.tip1')}}</span>
						</div>
						<div class="mtag">
							<span class="mt" v-for="item in keywords">{{item}}</span>
							
						</div>
					</div>
				</div>
			</div>
			<div class="pic-title">
				<span class="span">{{$t('active.recommend')}}</span>
			</div>
			<div class="enclosure" style="">
				<section class="section">
					<div class="_1Aw4E" :style="activeStyle(item)" v-for="item in videos" @click="changeVideoinfo(item.id)">   <!--  -->
						<div style="width:100%;height:100%;">
							<video  v-if="isMobile"								
							    :poster="item.thumb" 
							    preload="none" 
							    muted 
							    :alt="item.title" 
							    style="object-fit:cover;width:100%;height:100%;border-radius: 8px;" 
							    controlslist="nodownload" 
							    oncontextmenu="return false"
							    
							    >
								<track kind="captions" />
								<source :src="item.url" type="video/mp4">
							</video>
							<video  v-if="!isMobile"
							    controls								
							    :poster="item.thumb" 
							    preload="none" 
							    muted 
							    :alt="item.title" 
							    style="object-fit:cover;width:100%;height:100%;border-radius: 8px;" 
							    controlslist="nodownload" 
							    oncontextmenu="return false"
							    :src="item.url"
							    
							    >
							    <!-- @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave" -->
								<track kind="captions" />
								<!-- <source :src="item.url" type="video/mp4"> -->
							</video>
							<!-- <video 
							      controls 
							      :poster="item.thumb" 
							      preload="none" 
							      muted="" 
							      :alt="item.title" 
							      style="object-fit:cover;width:100%;height:100%;" 
							      controlslist="nodownload"
							      oncontextmenu="return false"
							      @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
								<source :src="item.url" type="video/mp4">
							</video> -->
						</div>
						<div class="video_name">{{item.title}}</div>
					</div>
					
				</section>
				<div class="loadmore">
					<span class="more" @click="loadMore()" v-if="!nomore">{{$t('loadmore')}}</span>
					<span class="end" v-if="nomore">- {{$t('theEnd')}} -</span>
				</div>
			</div>

		</div>
		<Footer/>
	</div>
</template>

<script>
	import active_collect_video_detail from './active_collect_video_detail_controller';
	export default active_collect_video_detail;
</script>

<style src="@/assets/css/active_collect_video_detail.css" scoped> </style>