<template>
	<PageLoading :is-loading="isLoading"></PageLoading>
	<SideComponent @scrollto="scroll"></SideComponent>
	<div class="container" ref="viewBox">
		<!-- <Header2 :show-search-bar-pic="showHeader" login-style="loginStyle" :mobile-icon-style="mobileIconStyle" :show-list-icon="showListIcon"/> -->
		<div class="header-container">
			<div class="search-swiper-container">				
				<Swiper :list="banners"/>
				<search-bar-pic @refresh="getData" @searchKeywords="searchKeywords" :isNeedRedirect="isNeedRedirect"></search-bar-pic>
			</div>
		</div>
		<div class="content">
			<div class="tag-container">
				<div class="cats-wrapper">
					<div style="padding:5px 0;">
						<div class="cats-item">
							<div class="text">{{$t('category')}}</div>	
						</div>
						<div class="cats-subitems" v-for="item in cats">
							<div class="cats-first" :class="[selectedCat==item.id?'active':'']" @click="selectCat(item.id)">{{item.title}}</div>
							<div class="cats-second">
								<div class="cats-subitem" :class="[selectedCat==tag.id?'active-tag':'']"  @click="selectCat(tag.id)" v-for="tag in item.children">{{tag.title}}</div>
							</div>
							
						</div>
					</div>
					
				</div>
			</div>
			<div class="pic-content">
				
				<div class="enclosure" style="">
					<section class="section" v-if="!isChangeData">
						<div class="_1Aw4E" v-for="item in subject" :style="activeStyle(item)" @click="toPicDetail(item.id)">
							<a class="a-outer" :title="item.title">
								<img src="@/assets/img/default.png" v-if="!item.thumb"/>
								<img :src="item.thumb" v-if="item.thumb" oncontextmenu="return false" :alt="item.title"/>
								<div class="amount">
									<div class="category-name">{{item.title}}</div>
									<div class="right">
										<i class="iconfont icon-shangchuantupian"></i>
										<span class="count">{{item.img_cnt}}</span>
									</div>
								</div>
							</a>							
						</div>
					</section>

					<div class="loadmore">
						<Loading :is-loading="isPicLoading"></Loading>
						<span class="more" @click="loadMore()" v-if="!nomore" :disabled="nomore_disabled">
							{{$t('loadmore')}}
						</span>
						<span class="end" v-if="nomore">- {{$t('theEnd')}} -</span>
					</div>

				</div>
				<!-- <Loading2 :is-loading="isPicLoading"></Loading2> -->

			</div>
			
		</div>
		<Footer/>
	</div>
</template>

<script>
	import special_topics_category from './special_topics_category_controller';
	export default special_topics_category;
</script>

<style src="@/assets/css/special_topics_category.css" scoped>
	/*@import url("@/assets/css/style.css");全局引用css*/
	/*@import '@/assets/css/style.css';*/
</style>