import { onMounted,nextTick, defineComponent, ref, provide, reactive, toRefs, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import Swiper from '@/components/layout/Swiper.vue';
import SearchBar2 from '@/components/layout/SearchBar2.vue';
import Header2 from '@/components/layout/header2.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';
import SideMenu from '@/components/layout/SideMenu.vue';
import bus from 'vue3-eventbus'
import QRCode from 'qrcode'
import SideComponent from '@/components/common/SideComponent.vue';
import Loading from '@/components/common/Loading.vue';

export default defineComponent({
	components: {
		Header2,
		Menu,
	    Swiper,
	    SearchBar2,
	    Footer,
	    SideMenu,
	    SideComponent,
	    Loading,
	},
	props: {

	},
	setup() {
		let {ctx, proxy} = getCurrentInstance();
		const data = reactive({
			showHeader: true,
			loginStyle: "min-width:141px;",
			mobileIconStyle: "color:rgba(223, 58, 8, 1);",
			showListIcon: true,
			activeName:'first',
			showRecharge:false,
			price:0.00,
			info:{
				// info:{},
				// wallet:{},
			},
			list:[],
			pagination:{},
			wallet:{
				money:0.00,
			},
			qrCodeImgUrl:'',
			orderInfo:{},
			showQrcode:false,
			isPicLoading: true,
		});
		const showMenu = ref(proxy.$menus);
		provide('showMenu', showMenu);

		const outerStyleStr = ref("position:relative;left:10%;top:40%;transform:translate(-10%,-40%);width:auto;");
		provide('outerStyleStr', outerStyleStr);

		const styleStr = ref("height:45px;");
		provide('styleStr', styleStr);

		const showSearchText = ref(false);
		provide('showSearchText', showSearchText);


		const store = useStore();	


		// bus.emit('needRefresh', true)

		const info = () => {
			store.dispatch("user/Wallet",{}).then((res)=>{
				console.log(res,"info");
				data.wallet = res.wallet;
			});
			// .catch(error => {
			//   	ElMessage({
			//         message: "网络异常，请重试",
			//         type: "error",
			//     });
			// });
		}

		
		const records = () => {
			data.isPicLoading = true;
			store.dispatch("user/BalanceRecords",{status:1}).then((res)=>{
				data.list = res.data;
				data.pagination = res.pagination;
				data.isPicLoading = false;
				bus.emit('showFooter',true);
			}).catch(error => {
			  	data.isPicLoading = false;
			});
		}
		const order = (price) => {
			store.dispatch("user/RechargeOrder",{price:price}).then((res)=>{				
				// data.wallet.money = data.price;
				console.log(res,"RechargeOrder");
				data.orderInfo = res;
				chargePay(res.id);
				// ElMessage({
			 //        message: "充值成功",
			 //        type: "success",
			 //    });
			}).catch(error => {
			  	ElMessage({
			        message: "网络异常，请重试",
			        type: "error",
			    });
			});
		}
		const chargePay = (oid) => {
			store.dispatch("user/ChargePay",{id:oid}).then((res)=>{
				console.log(res,"ChargePay-2");
				data.showQrcode = true;
				
				if(res.return_msg=='OK'&&res.return_code=='SUCCESS'&&res.result_code=='SUCCESS'){
					console.log(res,"pay");
					// 使用toDataURL方法 返回的是promise对象 通过.then取值
				    let img = QRCode.toDataURL(res.code_url).then(t => {
				      data.qrCodeImgUrl = t;
				      data.showRecharge = false;
				    })
				    loopOrderStatus(oid)
				}

			}).catch(error => {
			  	ElMessage({
			        message: "网络异常，请重试",
			        type: "error",
			    });
			});
		}

		const loopOrderStatus=(oid)=>{
			let timerId = setInterval(function(){
				store.dispatch("user/BalanceRecordInfo",{id:oid}).then((res)=>{
					console.log(res,"loop");
					if(res.status == 1){
						info();
						clearInterval(timerId);
						data.showQrcode = false;
						ElMessage({
					        message: "充值成功",
					        type: "success",
					        duration: 10000,
					        offset:16,
					    });
					}
				});
			},3000);
		}

		const viewBox = ref(null);
        const scroll = (val) => {
			if(val == 'top'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		       
			}else if(val == 'bottom'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		        	
		        	
		        
			}
		}

		onMounted(async()=>{
        	await nextTick()
        	setTimeout(() => {
		        data.isLoading = false;
		        viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		    }, 600);
		    console.log("页面数据更新完毕了")
        })
		

		const refData = toRefs(data);

		return {
			...refData,
			info,
			records,
			order,
			viewBox,
			scroll,
		}
	},
	created() {

	},
	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	},
	methods: {
		init() {
			// console.log(this.$cookies.getToken(),"ttoken");
			this.info();
			this.records();
		},
		show() {
			this.showRecharge = true;
		},
		closeQrcode() {
			this.showQrcode = false;
		},
		recharge(){

			bus.emit('needRefresh', true)
			if(undefined !== this.$cookies.getToken()){
				this.order(this.price);
			}else{
				setTimeout(this.order(),500);
			}

		},
		close(){
			this.showRecharge = false;
		},
	},
})