import { defineComponent, ref, provide, reactive, toRefs, getCurrentInstance, nextTick, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import {useStore} from 'vuex';
import PageLoading from '@/components/common/PageLoading.vue';
import Swiper from '@/components/layout/Swiper.vue';
import SearchBar2 from '@/components/layout/SearchBar2.vue';
import SearchBarPic from '@/components/layout/SearchBarPic.vue';
import Header2 from '@/components/layout/header2.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';
import bus from 'vue3-eventbus'
import Loading from '@/components/common/Loading.vue';
import SideComponent from '@/components/common/SideComponent.vue';

export default defineComponent({
	components: {
		PageLoading,
		Header2,
		Menu,
	    Swiper,
	    // SearchBar2,
	    SearchBarPic,
	    Footer,
	    Loading,
	    SideComponent,
	},
	props: {

	},
	setup() {
		provide('logoColor', 'white');
		let {ctx, proxy} = getCurrentInstance();
		const router = useRouter();
	    const route = useRoute();
	    const id = route.query.catid;
		const data = reactive({
			isLoading: true,
			showHeader: true,
			loginStyle: "min-width:141px;",
			mobileIconStyle: "color:rgba(223, 58, 8, 1);",
			showListIcon: true,
			isNeedRedirect:true,
			ispic: true,
			banners:[

      		],
			cats: [

			],
			subcats: {},
			labels: [
				{
					title: '浮山湾',
					url:'',
				},
				{
					title: '灯光秀',
					url:'',
				},
				{
					title: '栈桥',
					url:'',
				},
				{
					title: '崂山',
					url:'',
				},
				{
					title: '文化',
					url:'',
				},
				{
					title: '影视',
					url:'',
				},
				// {
				// 	title: '全部',
				// 	url:'',
				// },
				
			],
			onlineTime: [
				{
					type: '7',
					title: '最近一周',
					url:'',
				},
				{
					type: '30',
					title: '最近一个月',
					url:'',
				},
				{
					type: '180',
					title: '最近半年',
					url:'',
				},
				{
					type: '365',
					title: '最近一年',
					url:'',
				},
				// {
				// 	title: '全部',
				// 	url:'',
				// },
				
			],
			dpi: [
				{
					type: '2',
					title: '2k(2048px)',
					url:'',
				},
				{
					type: '4',
					title: '4k(4096px)',
					url:'',
				},
				{
					type: '8',
					title: '8k(8096px)',
					url:'',
				},
				// {
				// 	title: '全部',
				// 	url:'',
				// },
				
			],
      		subject:[],
      		selectedCat: 0,
      		selectedLabel: 0,
      		selectedOnlineTime: 0,
      		selectedDpi: 0,
      		selectSubjectid: 0,
      		isNeedRedirect:true,
      		keyword:'',
      		picFile:'',
      		isPicLoading:true,
      		pagination:{},
			page:1,
      		nomore:true,
      		styleObject: {},
      		nomore_disabled:false,
      		isChangeData: false,
		});
		const showMenu = ref(proxy.$menus);
		provide('showMenu', showMenu);

		const getImageUrl = (name) => {
		    return proxy.$utils.getImageUrl(name);
		};
		data.selectedCat = id;
		const store = useStore();
		

		const getAllSubjectCats=()=>{
			store.dispatch("common/getAllSubjectCats").then((res) => {
	        	data.cats = res
	        })
		}
        
        const getSubject = (selectedCat=0,page=1) => {
        	console.log(selectedCat,"selectedCat")
        	console.log(page,"page");

        	store.dispatch("common/getSubjectList", {
	        	subtype: 1,
	        	subject_cate: parseInt(selectedCat),
	        	page: page,
	        }).then((res) => {
	        	console.log(res,'subject');
	        	let subjectData = JSON.parse(JSON.stringify(res.data));  
	        	
	        	if(subjectData.length > 0){
	        		
	        		if(page == 1){
	        			data.subject = subjectData;
	        		}else{
	        			data.subject = data.subject.concat(subjectData);
	        		}
					
					if(subjectData.length < res.pagination.pageSize){
						data.nomore = true;
					}else{
						data.nomore = false;
					}
				}else{
					data.nomore = true;
				}        
	        	// data.pagination = data.videos.pagination;
	        	
	        	data.pagination = res.pagination;
	        	data.total = res.pagination.total;
	        	if(!data.nomore){
		        	data.page = page+1;
		        }
	        	data.isPicLoading = false;
	        	data.nomore_disabled = false;
	        	data.isChangeData = false;
	        	bus.emit('showFooter',true);
	        }).catch(err=>{
	        	data.isPicLoading = false;
	        	data.nomore_disabled = false;
	        })
        };
        


        const postFile = (picfile) => {
			store.dispatch("common/postPic",{
				file: picfile
			}).then((res)=>{
				data.picList = res;
				data.total = data.picList.length;
			})
		}

		
		

		const viewBox = ref(null);
        const scroll = (val) => {
			if(val == 'top'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		       
			}else if(val == 'bottom'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		        	
		        	
		        
			}
		}

		const loadMore = () => {
			bus.emit('showFooter',false);
			// data.nomore_disabled = true;
			data.isPicLoading = true;
			data.isChangeData = false;
			// console.log(this.page, "this.page");
			getSubject(data.selectedCat,data.page);
		}

	    onMounted(async()=>{

	    	data.banners = store.state.common.siteInfo.sliders;
	    	bus.emit('isLoading',false);
	    	getAllSubjectCats();
	    	getSubject(data.selectedCat);
        	await nextTick()
        	setTimeout(() => {
		        data.isLoading = false;
		        viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		    }, 600);
		    console.log("页面数据更新完毕了")
        })

		const refData = toRefs(data);

		return {
			...refData,
			getImageUrl,
			getSubject,
			postFile,
			// getPicList,
			viewBox,
			scroll,
			loadMore,
		}
	},
	created() {

	},
	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	},
	methods: {
		init() {

		},
		activeStyle(data) {
			let object = {width:0};
			object.width = data.ratio_w*230/data.ratio_h+'px';
			// object.height = '230px';
			return object;
		},

		getData(file) {
			this.picFile = file;//event.currentTarget.files[0];
	        this.$store.dispatch('common/uploadPic', {
            	uploadPic: this.picFile,
            	from: 'child',
            });
	        this.postFile(this.picFile);
		},
		searchKeywords(keyword){
			// this.getPicList(keyword,1);
		},
		selectCat(id){
			let that = this;
			that.isPicLoading = true;
			that.isChangeData = true;
			that.nomore = true;
			if(this.selectedCat == 0 || this.selectedCat != id){
				this.selectedCat = id;
			}else{
				this.selectedCat = 0;
			}   
			//替换路由参数
			this.$router.replace({ path:'/special_topics_category',query:{catid: this.selectedCat} });
			//获取数据
			this.getSubject(this.selectedCat);

		},
		
		toPicDetail(id){
			let href = this.$router.resolve({ //使用resolve
		      name:'special_topics',    //这里是跳转页面的name
		      path: '/special_topics',
		      query:{ catid: id },
		    })
		    window.open(href.href, '_blank')
			// this.$router.push({ name:'special_topics', query:{ catid: id } },
			//   () => {}, // 成功回调
			//   () => {} //失败回调);
			//   );
		},
	},
})