<template>  
  <!-- <label for="language" class="upload-button"> -->
    <el-select id="language" v-model="value" placeholder="Select" @change="getLang(value)">
  	    <el-option
  	      v-for="item in options"
  	      :key="item.value"
  	      :label="item.label"
  	      :value="item.value"
  	    />
        <p class="upload-tiptext"></p>
  	</el-select>
  <!-- </label> -->
  <!-- <div class="menu">
    <div @click="changeLang('en')">English</div>
    <div @click="changeLang('zh')">中文</div>
  </div> -->
</template>
<script setup>
import { useRouter, useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n'
import { ref, computed } from "vue";
import i18n from "@/lang/index";

const router = useRouter();
const route = useRoute();
// console.log(route.fullPath,"route");
const { t, locale } = useI18n()

 
const getLang = (lang) => {
	   // console.log(lang,"lang");
    locale.value = lang
    localStorage.setItem('lang', lang)
    let baseUrl = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
    baseUrl += route.fullPath;
    // console.log(baseUrl);
    location.href=baseUrl;
    // router.push();
}

const getCurrentLang = computed(() => {
    return locale.value;
});
// console.log("getCurrentLang",getCurrentLang);
// console.log(t("message.hello"));

const value = ref(i18n.global.locale.value)
console.log(value,"lang value");
// value.value = i18n.global.locale.value
const options = [
  {
    value: 'zh',
    label: '中文简体',
  },
  {
    value: 'zhCHT',
    label: '中文繁体',
  },
  {
    value: 'en',
    label: 'English',
  },
  {
    value: 'ja',
    label: '日本語',
  },
  {
    value: 'ko',
    label: '한국어',
  },
  {
    value: 'ru',
    label: 'Русский язык',
  },
  {
    value: 'de',
    label: 'Deutsche Sprache',
  },
  {
    value: 'ar',
    label: 'اللغة العربية',
  },
  
  
  
]

</script>
<style scoped>
  .upload-button{
    border: 1px solid rgb(230,0,15);
    background-color: rgb(230,0,15);
  }
  :deep(.el-select){
    --el-select-border-color-hover: rgba(255,255,255,1);
  }
  :deep(.el-select__wrapper){
    background-color: rgba(255,255,255,0);
    box-shadow: 0 0 0 0px rgba(255,255,255,0) inset;
    color: white;
    padding: 4px 4px 0px;
    min-width:100px;
    font-size:14px;
  }
  :deep(.el-select__wrapper.is-hovering:not(.is-focused)){
    box-shadow: 0 0 0 0px rgba(255,255,255,0) inset;
  }
  :deep(.el-select__wrapper:hover) {
    box-shadow: 0 0 0 0px rgba(255,255,255,0) inset;
  }
  :deep(.el-select__placeholder){
    color: white;
    top:40%;
    transform: translateY(-40%);
  }
  :deep(.el-select__suffix){
    color: white;
  }
  :deep(.el-select__caret){
    color: white;
  }
  :deep(.el-select__placeholder.is-transparent){
    color: white;
  }
  :deep(.el-icon){
    width:12px;
    height:12px;
    line-height: 12px;
    padding-top:4px;
  }
  :deep(.el-icon svg){
    width:12px;
    height:12px;
  }
</style>