<template>
<SideComponent @scrollto="scroll"></SideComponent>
	<div class="container" ref="viewBox">
		<Header2 :show-search-bar-pic="showHeader" login-style="loginStyle" :mobile-icon-style="mobileIconStyle" :show-list-icon="showListIcon"/>
		<div class="content">
			<div style="height:80px;"></div>
			<div class="enclosure">
				<div class="inner">
					<div class="left">
						<SideMenu />
					</div>
					<div class="right">
						<div class="caption">
							<span class="title">{{$t('wallet.text1')}}</span><!-- <span class="arrow">></span><span></span> -->
						</div>
						<div class="wallet-container">
							<div class="pack">
								<div class="pack-left">
									<span>{{$t('wallet.text2')}}</span>
									<span>￥{{wallet.money?wallet.money:'0.00'}}</span>
									<!-- <span @click="show()">+充值</span> -->
								</div>
								<div class="pack-right">
									<i class="iconfont icon-zhanghuyue5"></i>
								</div>
							</div>
							<div class="records">
								<div class="tip">
									<span class="title">{{$t('wallet.text3')}}</span>
								</div>
								<div class="records-table">
									<div class="head">
										<div class="item">{{$t('wallet.text4')}}</div>
										<div class="item">{{$t('wallet.text5')}}</div>
										<div class="item">{{$t('wallet.text6')}}</div>
									</div>
									<div class="body">
										<div class="item"  v-if="list.length>0" v-for="(item,index) in list">
											<div class="subitem">
												{{item.message}}
											</div>
											<div class="subitem price">
												{{item.price}}
											</div>
											<div class="subitem">
												{{item.updated_at}}
											</div>
										</div>
										<div class="page-wrapper">
											<Loading :is-loading="isPicLoading"></Loading>
											<el-pagination 
											    background 
											    layout="prev, pager, next" 
											    :total="pagination.total" 
											    v-model:current-page="pagination.current"
											    v-model:page-size="pagination.pageSize"				    
											/>

										</div>
										<div class="item" v-if="list.length<=0">
											<span class="no-record">{{$t('wallet.text7')}}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="dialog" v-if="showRecharge">
					<div class="item"><span class="title">充值</span></div>
					<div class="item">
						<div>
							<el-input v-model="price" size="large" style="width: 240px" placeholder="请输入充值金额" />
						</div>
						
					</div>
					<div class="item">
						<span class="btn sure" @click="recharge()">充值</span>
						<span class="btn close" @click="close()">关闭</span>
					</div>
				</div>
				<div class="qrcode" v-if="showQrcode">
					<div class="close" @click.stop="closeQrcode()"><i class="iconfont icon-chahao2"></i></div>
					<div class="title">
						<span>微信扫一扫支付</span>
					</div>
					<div class="info">
						<div class="item">
							<span>订单编号：</span>
							<span>{{orderInfo.order_sn}}</span>
						</div>
						<div class="item">
							<span>订单金额：</span>
							<span style="color:rgb(255,149,2);font-size:14px;">￥</span>
							<span style="color:rgb(255,149,2);font-size:16px;">{{orderInfo.price}}</span>
						</div>
						<div class="item">
							<!-- <img src="@/assets/img/qrcode.jpg"/> -->
							<img :src="qrCodeImgUrl"/>
							<span>打开微信扫码支付</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer/>
		
	</div>
</template>

<script>
	import wallet from './wallet_controller';
	export default wallet;
</script>

<style src="@/assets/css/my/wallet.css" scoped>
	/*@import url("@/assets/css/style.css");全局引用css*/
	/*@import '@/assets/css/style.css';*/
</style>