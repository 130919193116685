import { onMounted,nextTick, defineComponent, ref, provide, reactive, toRefs, getCurrentInstance } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import Swiper from '@/components/layout/Swiper.vue';
import SearchBar2 from '@/components/layout/SearchBar2.vue';
import Header2 from '@/components/layout/header2.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';
import SideMenu from '@/components/layout/SideMenu.vue';
import bus from 'vue3-eventbus'
import SideComponent from '@/components/common/SideComponent.vue';

export default defineComponent({
	components: {
		Header2,
		Menu,
	    Swiper,
	    SearchBar2,
	    Footer,
	    SideMenu,
	    SideComponent,
	},
	props: {

	},
	setup() {
		let {ctx, proxy} = getCurrentInstance();
		const router = useRouter();
	    const route = useRoute();
		const data = reactive({
			showHeader: true,
			loginStyle: "min-width:141px;",
			mobileIconStyle: "color:rgba(223, 58, 8, 1);",
			showListIcon: true,
			activeName:'first',
			username:'',
			personInfo:{},
		});
		const showMenu = ref(proxy.$menus);
		provide('showMenu', showMenu);

		const outerStyleStr = ref("position:relative;left:10%;top:40%;transform:translate(-10%,-40%);width:auto;");
		provide('outerStyleStr', outerStyleStr);

		const styleStr = ref("height:45px;");
		provide('styleStr', styleStr);

		const showSearchText = ref(false);
		provide('showSearchText', showSearchText);

		const getImageUrl = (name) => {
		    return proxy.$utils.getImageUrl(name);
		};

		// bus.emit('needRefresh', true)

		const store = useStore();	

		
		const getPersonInfo = () => {
			store.dispatch("user/getPersonInfo",{}).then((res)=>{
				console.log(res,"personInfo");
				// data.personInfo = res;
				ruleUser.username = res.username;
				ruleUser.mobile = res.mobile;
				ruleUser.email = res.email;
				ruleUser.department = res.department;
			}).catch(err => {
	            console.error(err)
	            ElMessage({
				    message: '获取用户信息失败',
				    grouping: true,
				    type: 'error',
				})
	        }) 
		}
			
		const viewBox = ref(null);
        const scroll = (val) => {
			if(val == 'top'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		       
			}else if(val == 'bottom'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		        	
		        	
		        
			}
		}

		const ApplyInfo = () => {
			store.dispatch("user/ApplyInfo",{

			}).then((res) => {
				console.log(res,"ApplyInfo");
				if(Object.keys(res).length > 0){
					if(res.status==0){// 审核中
						router.push({name:'apply_passing'});
					}else if(res.status==2){// 审核未通过
						router.push({name:'apply_nopass'});
					}else if(res.status==1){// 审核通过
						GetApplyContract().then(contract=>{
							if(Object.keys(res).length > 0){
								if(contract.status == 1){
									router.replace({name:'apply_work'})
								}else{
									router.push({name:'apply_pass'})
								}
							}else{
								router.push({name:'apply_contract'})
							}
							
						});
						
					}
					
					
				}else{
					router.push({name:'no_settled'});
				}
				// bus.emit('showFooter',true);
			})
		}

		// 获取合同信息
		const GetApplyContract = () => {
			return new Promise((resolve, reject) => {
		        store.dispatch("user/GetApplyContract",{}).then(res => {
		        	console.log(res,"GetApplyContract");
		        	resolve(res);
		        }).catch(err=>{
		        	reject(err);
		        })
		    })
		}

        onMounted(async()=>{
        	await ApplyInfo();
        	await nextTick()
        	setTimeout(() => {
		      data.isLoading = false;
		      viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		      bus.emit('showFooter',true);
		    }, 600);
		    console.log("页面数据更新完毕了")
        })

		const refData = toRefs(data);

		return {
			...refData,
			getImageUrl,
			getPersonInfo,
			viewBox,
			scroll,
			ApplyInfo,
		}
	},
	created() {

	},
	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	},
	methods: {
		init() {
			
		},
	},
})