export default {
    login: {
        login: '登錄',
        userName: '使用者名',
        password: '密碼',
        name: '用戶名或單位名稱',
    },
    captcha: '驗證碼',
    forgetPassword: '忘記密碼',
    loginTip: '當前登錄結果隨機。 驗證碼隨便填',
    editpassword: '修改密碼',
    errMsg: {
        inputRequired: '請輸入{cont}',
        selectRequired: '請選擇{cont}',
    },
    uernameTips: '個人用戶請填寫用戶名，單位使用者請填寫單位名稱',

    title: '映像青島',
    logo_small_text:'青島國際新聞中心運維',
    hello: '您好，',
    loginOrRegister: '登錄 / 註冊',
    anonymity: '匿名使用者',
    personCenter: '個人中心',
    logout:'退出登錄',
    toLogin: '請先登錄',
    mobileTip: '手機號',
    submitText: '提交',
    accountTip: '使用者名/手機號',
    password: '密碼',
    newPassword: '新密碼',
    confirmPassword: '確認新密碼',
    noAccount: '還沒有帳號？',
    hasAccount: '已有帳號？',
    register: '註冊',
    phoneNumber: '手機號',
    email: '郵箱',
    organizationName: '單位名稱',
    loginNow:'立即登錄',
    accountLogin: '賬號登錄',
    loginSuccess: '登錄成功',
    loginFailed: '登錄失敗',
    registerSuccess: '註冊成功',
    registerFailed: '註冊失敗',
    enterUsername: '請輸入使用者名',
    enterPhoneNumber: '請輸入手機號',
    enterRealname: '請填寫真實姓名',
    enterCorrectUnitName: '請填寫正確單位名稱',
    enterCorrectIdcard: '請填寫正確身份證號',
    enterCorrectInstitutionName: '請填寫正確機構編號',
    enterCorrectPhoneNumber: '請輸入正確手機號',
    enterCorrectEmail: '請輸入正確郵箱',
    enterCorrectUnitName: '單位名稱可為漢字或字母或數字的組合',
    enterCorrectUsernameAndPhone: '請輸入使用者名/手機號',
    nameContain: '使用者名必須包含字母或數位',
    passwordContain: '密碼需要至少8比特數位、字母、特殊符號（！#$%^&*？-_）組合',
    passwordContain2: '密码需要至少8位数字、字母、特殊符号!@#$%^&*?-_组合',
    enterPassword: '請輸入密碼',
    passwordLength: '密碼長度至少8比特',
    qrlogin: '掃碼登錄',
    updateSuccess: '更新成功',
    retrievePassword: '找回密碼',
    pageUpdated: '頁面數據更新完畢了',

    cart1: '購物車',
    onlineService: '在線客服',
    newsCenter: '青島國際新聞中心運維',
    qdnews: '青島國際新聞中心',
    hostUnit: '主辦：',
    contactPhone: '聯繫電話',
    workTime: '工作時間',
    footerText1: '海量高清素材',
    footerText2: '有版權商用',
    aboutus: '關於我們',
    copyrightNotice: '版權聲明',
    questions: '常見問題',
    terms: '網站條款',
    selfRegulatory: '網站平臺自律公約',
    contractUs: '聯繫我們',
    friendLink:'友情連結',
    followUs: '關注我們',
    copyright:'映像青島版權所有',
    ICP: '魯ICP備2023012351號-1',
    category:'分類',
    pv:'總流覽量：',
    uv:'總註冊人數：',
    qrcode:'掃碼登入',
    loadmore: '加載更多',
    theEnd: '結束',

    codeLogin:'驗證碼登入',
    getCode:'獲取驗證碼',
    enterCode:'請輸入驗證碼',
    codetip:'驗證碼',
    viewmore:'查看更多',

    menus: {
        home: '首頁',
        news: '視訊',
        pic: '圖片',
        video: '視頻',
        vr: '數位博物館',
        grapher: '攝影師',
        active: '活動',
        more: '更多服務',
        package: '價格套餐',
        subject: '專題',
    },
    contract:{
        tip1:'補充合同資料',
        tip2:'合同簽字',
        tip3:'合同下載',
        tip4:'為了保證合同的有效性及後續保障，請填寫真實的個人資料，後期購買將自動使用本次填寫的資料。',
        tip5:'訂單ID',
        tip6:'請輸入訂單ID',
        tip7:'資料類型',
        tip8:'個人使用者',
        tip9:'企業機構',
        tip10:'真實姓名',
        tip11:'請輸入真實姓名',
        tip12:'身份證號',
        tip13:'請輸入身份證號',
        tip14:'組織機構',
        tip15:'請輸入組織機構',
        tip16:'機構編號',
        tip17:'請輸入機構編號',
        tip18:'郵箱',
        tip19:'請輸入郵箱',
        tip20:'生成合同範本',
        tip21:'請在下邊紅色邊框區域按住滑鼠左鍵手寫您的簽名',
        tip22:'生成簽名',
        tip23:'清空畫板',
        tip24:'下載合同',
        tip25:'請選擇使用者類型',
        tip26:'請輸入真實姓名',
        tip27:'請輸入身份證號',
        tip28:'請輸入機構名稱',
        tip29:'請輸入機構編號',
        tip30:'請輸入郵箱',
        tip31:'合同資料保存失敗',
        tip32:'獲取合同資料失敗',
        tip33:'上傳失敗',

    },
    recharge:{
        tip1:'收益概況',
        tip2:'收入概覽',
        tip3:'未結算金額（元）',
        tip4:'已結算金額（元）',
        tip5:'申請結算',
        tip6:'訂單明細',
        tip7:'作品名稱',
        tip8:'支付時間',
        tip9:'訂單金額',
        tip10:'分成收益',
        tip11:'訂單編號：',
        tip12:'結算記錄',
        tip13:'申請結算時間',
        tip14:'結算進度',
        tip15:'入帳時間',
        tip16:'結算金額',
        tip17:'待結算',
        tip18:'結算中',
        tip19:'已結算',
        
    },

    search: {
        search: '搜索',
        hotSearch: '熱門搜索',
        takePic: '拍圖片搜索',
        dragPic: '拖放圖片在此區域的任何位置',
        or: '或',
        clickUpload: '點擊上傳',
        sizeLimit: '使用尺寸小於50mb的jpg/png檔',
        searchPic: '搜圖片',
        searchVideo: '搜視頻',
        pic: '圖片',
        video: '視頻',
        trySearch:'可以嘗試搜索關鍵詞',
        scenery: '風光',
        building: '建築',
        culture: '文化',
        humanities: '人文',
        educate: '教育',
        isJPG: '上傳圖片只能是 JPG、PNG 格式！',
    },
    sideMenu: {
        apply: '攝影師供稿',
        account: '帳戶管理',
        wallet: '我的錢包',
        cart: '購物車',
        authRecords: '授權記錄',
        orderlist:'訂單清單',
        collectManage: '活動徵集',
        collect: '活動作品',
        folder: '活動合輯',
        personCenter: '攝影師中心',
        applyInfo: '入駐資訊',
        workManage: '我的作品',
        recharge: '收益中心',
    },
    person: {
        personCenter: '創作者中心',

    },
    application: {
        settled: '攝影師入駐',
        process: '入駐流程',
        plain: '入駐說明',
        apply: '提交申請',
        result: '提交結果',
        tipText1: '歡迎申請成為映像青島創作攝影師，為了提升您入駐的成功率，請正確填寫入駐資訊。',
        tipText2: '我們將會3個工作日內回復您的申請，您可以在此查看入駐進度',
        apply_enter: '申請入駐',
        base_info: '請填寫基本資訊',
    },
    form: {
        user_type: '用戶類型',
        license: '營業執照',
        licenseTip: '組織營業執照蓋章電子版（或組織機構代碼證蓋章電子版）',
        type1: '個人',
        type2: '單位組織',
        unitName:'單位名稱',
        unitTip:'請輸入單位名稱',
        realname: '真實姓名',
        enterRealname: '請輸入真實姓名',
        gender: '性别',
        male: '男',
        female: '女',
        photo: '個人照片',
        enterPhoto: '請上傳個人照片',
        uploadPhoto: '上傳照片',
        biography: '簡介',
        enterBiography: '請填寫簡介',
        mobile: '手機號',
        enterPhone: '請輸入手機號',
        email: '電子郵箱',
        enterEmail: '請輸入郵箱',
        company: '單位名稱',
        enterCompany: '請輸入單位名稱',
        enterFeedType: '請選擇供稿類型',
        ctype: '從業類型',
        media: '媒體',
        non_media: '非媒體',
        worktype: '供稿類型',
        image: '圖片',
        video: '視頻',
        workPic: '圖片代表作品',
        workPicTip: '（代表作品僅作入駐審核參考，上傳優質原創代表作品有利於提高入駐成功率）',
        uploadPic: '上傳圖片',
        againUploadPic: '繼續上傳圖片',
        againUploadVideo: '繼續上傳視頻',
        limit_pic_quantity: '（請提供10張原創圖片）',
        workVideo: '視頻代表作品',
        uploadVideo: '上傳視頻',
        limit_video_quantity: '（請提供3個原創視頻）',
        submit_apply: '提交申請',
        tip_text_1: '你的入駐信息審核中...',
        tip_text_2: '我們將在3個工作日內對您的入駐資訊進行審核（如遇國定公休日將順延時間）',
        tip_text_3: '您可以在該頁面查看審核結果！',
        uploadPicWork: '請上傳圖片作品',
        uploadVideoWork: '請上傳視頻作品',
        enter_limit_pic: '請上傳10張圖片',
        enter_limit_video: '請上傳3個視頻',
        submited: '申請已提交',
        only_ten_pic: '只能上傳10張圖片',
        only_three_video: '只能上傳3個視頻',
        idcard: '身份證號',
        idcard_photo: '身份證照片',
        enter_idcard: '請輸入身份證號',
        idcard_tip: '（請上傳身份證頭像面）',
        bankcard: '銀行卡號',
        enter_bankcard: '請輸入銀行卡號',
        bankcard_photo:'銀行卡照片',
        bankcard_tip:'（請上傳帶有銀行卡號面的照片）',
        preview:'提交並預覽合同',
        enterCorrectbankcardNumber: '請填寫正確銀行卡號',
        enterIdcardPhoto: '請上傳身份證正面',
        enterBankcardPhoto: '請上傳銀行卡正面',
    },
    apply_contract:{
        info: '合同資訊',
        sign: '合同簽名',
        tip1: '為保證雙方合法權利，請錄入身份資訊和銀行卡資訊以便生成電子合同，簽署完成後即簽約成功。',
        tip2: '*以下資訊僅用於合同簽署和收益結算，合同簽署后無法更改，請認真填寫!',
        tip3: '為保證雙方合法權利，請您仔細閱讀合同內容，簽署完成後將無法修改',
        tip4: '請填寫合同資訊',
        tip5: '合同預覽',
        tip6: '請在下邊紅色邊框區域按住滑鼠左鍵手寫您的簽名',
        tip7: '生成簽名',
        tip8: '清空畫板',
        signSuccess:'簽名成功',
        noMatch:'身份資訊不匹配',
        submit_success:'提交成功',
        idcard_face:'請上傳（或者重新上傳）身份證正面照片',
        upload_fail:'上傳失敗',

    },
    apply_info: {
        tip1:'歡迎您入駐映像青島，您可以上傳您的作品，審核通過後即有機會賺取收益哦~',
        tip2:'入駐資訊',
        tip3:'點擊查看合同',
        tip4: '電子合同',
        
    },

    apply_nopass: {
        tip1:'你的入駐申請審核未通過...',
        tip2:'原因:經專家對您提交的作品進行綜合評估，您暫不符合我們的入駐要求!',
        tip3:'您可以嘗試提交更加優質的代表作品，我們將再次進行綜合評估~',
        tip4:'重新提交',
    },
    apply_pass: {
        tip1: '恭喜，您的入駐申請已通過!',
        tip2: '完善資訊后就可以上傳作品，有機會賺取稿費啦~',
        tip3: '簽約投稿',
    },
    apply_passing: {
        tip1:'你的入駐信息審核中...',
        tip2:'我們將在3個工作日內對您的入駐資訊進行審核（如遇國定公休日將順延時間）',
        tip3:'您可以在該頁面查看審核結果',
    },
    apply_common: {
        del:'刪除此項',
        addimg:'添加圖片',
        addvideo:'添加視頻',
        batch_pics:'批量圖片統一資訊填寫',
        batch_videos:'批量視頻統一資訊填寫',
        search_subject:'專題名稱搜索',
        upload_success:'上傳成功，等待審核',
        upload_pic_eight:'一次最多上傳8張圖片',
        upload_video_eight:'一次最多上傳8個視頻',
        size_exceed:'大小不能超過50M',
        submit_success:'提交成功',
        agreement:'我已閱讀並同意',
        protocols:'《映像青島內容授權許可協定》',
        read:'請先閱讀並同意《映像青島內容授權許可協定》',
        selectAll: '全選',
        off_pic: '點擊下架后圖片將從網站自動刪除，確定下架？',
        del_pic: '點擊下架后圖片將從網站自動刪除，確定刪除？',
        batch_off_pic: '批量下架',
        batch_del_pic: '批量刪除',
        season: '原因：',
        edit: '編輯',
        off: '下架',
        del: '刪除',
        off_video: '點擊下架后視頻將從網站自動刪除，確定下架？',
        del_video: '點擊刪除後視頻將從網站刪除，確定刪除？',
        off_item: '請選擇要下架的選項',
        had_off: '作品已下架',
        del_item: '請選擇要刪除的選項',
        had_del: '作品已刪除',
        del_tip: '點擊刪除后不可恢復，確定刪除？',
        edit_folder:'編輯合輯',
        add_folder:'添加合輯',
        enter_folder_title: '請輸入合輯標題',
        enter_keyword_tip:'每輸入一個關鍵詞，按一次回車鍵',
        add_to_folder:'加入合輯',
        select_folder:'請選擇合輯',
        author:'作者',
        enter_author:'請輸入作者',
        create_date:'創作日期',

    },
    apply_work: {
        year_limit: '年份只能是數位',
        tip0:'圖片供稿',
        tip1:'抱歉，您未申請圖片許可權',
        tip2:'溫馨提示：如需圖片投稿，請重新申請簽約',
        tip3:'重新簽約',
        tip4:'請選擇一張或多張圖片，支持多種圖片格式',
        tip5:'圖片名稱',
        tip6:'請輸入圖片名稱',
        tip7:'圖片說明',
        tip8:'請輸入圖片說明',
        tip9:'關鍵詞',
        tip10:'請輸入關鍵詞',
        tip11:'專題名稱',
        tip12:'請選擇專題',
        tip13:'新增專題',
        tip14:'小圖狀態',
        tip15:'小圖價格',
        tip16:'請輸入小圖價格',
        tip17:'原圖狀態',
        tip18:'原圖價格',
        tip19:'請輸入原圖價格',
        tip20:'大圖狀態',
        tip21:'大圖價格',
        tip22:'請輸入大圖價格',
        tip23:'啟用',
        tip24:'禁用',
        tip25:'提交審核',
        tip26:'視頻供稿',
        tip27:'抱歉，您未申請視頻許可權',
        tip28:'溫馨提示：如需視頻投稿，請重新申請簽約',
        tip29:'請選擇一個或多個視頻，支援多種視頻格式',
        tip30:'視頻名稱',
        tip31:'請輸入視頻名稱',
        tip32:'視頻說明',
        tip33:'請輸入視頻說明',
        tip34:'專題名稱',
        tip35:'請輸入專題名稱',
        tip36:'專題分類',
        tip37:'請選擇專題分類',
        tip38:'專題封面',
        tip39:'專題描述',
        tip40:'請輸入專題描述',
        tip41:'提交',
        tip42:'請上傳專題封面',

        more:'載入更多',

        price_pack: '價格套餐包',
        select_price_pack: '請選擇價格套餐包',
        pic_limit: '最多上傳8張圖片',
        video_limit: '最多上傳8個視頻',
        topic_price_pack: '專題封面價格套餐包',
        year:'年份',
        yearTip:'請輸入年份',
        coverTip: '這只是封面圖片，需要在圖片供稿再重新傳一次',
        has_similar: '請勿重複上傳圖片作品',
        similar: '圖片重複',
    },
    no_settled: {
        tip1:'抱歉，入駐成功后才能投稿哦~',
        tip2:'您還未認證為官方攝影師',
        tip3:'攝影師入駐',
    },
    index: {
        hot: '熱門推薦圖片',
        grapher:'優秀攝影師',
        image: '圖片',
        video: '視頻',
    },
    news: {
        news:'視訊',
        publish_time: '發佈時間：',
    },
    package: {
        name: '套餐名稱',
        content: '專屬內容權益',
        expire: '期限',
        day: '天',
        buy: '購買套餐',
        between: '許可權範圍',
        min: '小圖',
        original: '原圖',
        max: '大圖',
        tip1: '1500元/支',
        tip2:'2200元/支',
        tip3: '掃一掃支付',
        order_sn: '掃一掃支付',
        price:'訂單金額：',
        open:'打開',
        scan: '掃碼支付',
        wechatPay: '微信支付',
        alipay: '支付寶支付',
        weixin: '微信',
        ali:'支付寶',
        select:'請選擇套餐',
        tip4: '圖片',
        tip5: '單支視頻',
        tip6: '視頻套餐',
    },
    grapher: {
        title: '攝影師',
        home:'首頁',
        grapher:'優秀攝影師',
        tip1:'（該排名為姓氏排名不分先後）',
        tip2:'映射青島簽約攝影師',

    },
    search_pic:{
        tip1:'相似圖片',
        tip2:'找到約',
        tip3:'條記錄',
    },
    topics:{
        tip1:'相關專題',
        home:"首頁",
    },
    pic:{
        tip1:'目前位置：',
        tip2:'說明：',
        tip3:'特定使用範圍（Royalty-Free，簡稱“RF”）授權，非特定使用範圍許可內容可以無限次和永久性使用，根據檔尺寸定價，下載後無浮水印。 詳情參考：',
        tip4:'映像青島內容授權協定',
        tip5:'加入購物車',
        tip6:'單張購買',
        tip7:'如需詢價購買請撥打0532-82825531 或諮詢',
        tip8:'在線客服',
        tip9:'圖片ID：',
        tip10:'圖片標題：',
        tip11:'圖片規格：',
        tip12:'圖片尺寸：',
        tip13:'圖片類型：',
        tip14:'圖片大小：',
        tip15:'圖片價格：',
        tip16:'圖片年份：',
        tip17:'圖片說明：',
        tip18:'關鍵詞',
        tip19:'相似圖片推薦',

    },
    video: {
        text1: '視訊ID：',
        text2: '視訊標題：',
        text3: '視訊類型：',
        text4: '視頻時長：',
        text5: '視訊大小：',
        tip1: '關鍵詞',
        tip2: '相似視頻推薦',
        desc: '簡介',
    },
    vr: {
        text1: 'VR展廳',
        text2: '詳情',
    },
    order_detail: {
        text1: '訂單詳情',
        text2: '服務說明',
        text3: '如需開具發票，請聯繫客服：',
        text4: '訂單編號：',
        text5: '訂單金額：',
        text6: '支付金額：',
        text7: '支付狀態：',
        text8: '待支付',
        text9: '已支付',
        text10: '支付方式：',
        text11: '套餐包',
        text12: '微信支付',
        text13: '支付寶支付',
        text14: '餘額',
        text15: '支付時間：',
        text16: '下單時間：',
        text17: '圖片資訊',
        text18: '應付合計：',
        text19: '圖片預覽',
        text20: '圖片名稱',
        text21: '圖片規格',
        text22: '圖片價格',
        text23: '選擇支付方式',
        text24: '選擇套餐包支付',
        text25: '實付金額：',
        text26: '提交訂單',

        text27: '賬戶餘額支付',
        // text28: '账户可用余额 <span style="color:rgb(230, 0, 18);font-weight:bold;">0.00</span>元。如果您已在【价格套餐】页面购买了套餐包，请优先选择套餐包支付。',
        text29: '微信支付',
        text30: '支援 微信快捷支付',
        text31: '支付寶支付',
        text32: '支援 支付寶快捷支付',

        text33: '帳戶可用餘額',
        text34: '如果您已在【價格套餐】頁面購買了套餐包，請優先選擇套餐包支付。',
        text35: '支付完成',
        text36: '支付失敗',
        origin_1:'原圖：',
        small_1:'小圖：',
        big_1:'大圖：',
        origin:'原圖',
        small:'小圖',
        big:'大圖',
        maohao:'：',
    },
    order_pay: {
        text1: '訂單資訊',
        text2: '預支付訂單',
        tip1: '請選擇圖片價格',
        tip2: '只能選擇一項',
    },
    order_list: {
        text0: '訂單清單',
        text1: '圖片名稱：',
        text2: '下單時間：',
        text3: '搜索',
        text4: '訂單ID：',
        text5: '訂單編號：',
        text6: '下單時間：',
        text7: '解析度：',
        text8: '訂單詳情',
        text9: '下載合同',
        text10: '價格：',
        text11:'開始時間',
        text12:'結束時間',
        text13:'至',
        wait_pay:'待支付',
        pay_success:'支付成功',
        paid:'已支付',
        package:'套餐包',
        wechatPay:'微信支付',
        alipay:'支付寶支付',
        balance:'餘額',
    },
    setting: {
        text1:'修改密碼',
        text2:'用戶資訊',
        text3:'手機號（不可修改）',

    },
    auth: {
        text1: '授權清單',
        text2: '圖片名稱：',
        text3: '授權時間：',
        text4: '圖片預覽',
        text5: '圖片標題',
        text6: '圖片規格',
        text7: '圖片解析度',
        text8: '操作',
        text9: '下載',


    },
    wallet: {
        text1: '我的錢包',
        text2: '可用餘額',
        text3: '餘額記錄',
        text4: '類型',
        text5: '金額',
        text6: '時間',
        text7: '暫無餘額記錄',
    },
    cart: {
        text1: '購物車',
        text2: '（共',
        text3: '件商品）',
        text4: '操作',
        text5: '圖片預覽',
        text6: '圖片標題',
        text7: '圖片規格',
        text8: '價格',
        text9: '刪除',
        text10: '我已閱讀並同意',
        text11: '《映像青島授權協定》',
        text12: '《映像青島隱私聲明》',
        text13: '已選商品',
        text14: '總計',
        text15: '結算',
        text16: '件',
        clearCart:'清空購物車',
        batchDel:'批量刪除',

    },
    activity: {
        actName: '參加活動',
        selectAct: '請選擇徵集活動',
        selectActName: '活動名稱搜索',
        category: '分類',
        select_cate:'選擇分類',
    },
    active: {
        upload: '上傳作品',
        pic:'圖片',
        video: '視頻',
        recommend: '推薦',
        tip7:'圖片說明',
        tip9:'關鍵詞',
        folder_title: '標題',
        enter_title: '請輸入標題',
        folder_desc: '描述',
        enter_desc: '合輯描述',
        cover_type: '封面類型',
        folder_cover: '封面',
        cover_type_1:'最新作品',
        cover_type_1_tip:'自動將合輯中的最新作品作為合輯封面',
        cover_type_2:'固定封面',
        cover_type_2_tip:'本地上傳圖片作為固定封面',
        operate_success: '操作成功',

    },
    status: {
        all:'全部',
        reviewing: '審核中',
        reviewed: '審核通過',
        not_review: '審核未通過',
    }


}