<template>
	<PageLoading :is-loading="isLoading"></PageLoading>
	<SideComponent @scrollto="scroll"></SideComponent>
	<div class="container" ref="viewBox">
		<div class="header-container">
			<Header2 />			
			<div class="search-swiper-container">
			</div>
		</div>
		<div class="content">
			<div class="index-pic">				
				<!-- <div class="pic-wrapper">
					<search-bar-pic @refresh="getData"></search-bar-pic>
				</div> -->
			</div>
			<div class="pay-container">
				<div class="title">
					<span>{{$t('order_pay.text1')}}</span>
				</div>
				<div class="plain">
					<span>{{$t('order_detail.text2')}}</span><span>{{$t('order_detail.text3')}}0532-8282 5531</span>
				</div>
				<div class="order-container">
					<div class="caption"><span>{{$t('order_pay.text2')}}</span></div>
					<div class="order-table">
						<div class="table-caption">							
							<div>{{$t('order_detail.text17')}}（{{imgs.length}}）</div>
							<div>{{$t('order_detail.text18')}} <span class="order-amount">￥{{totalAmount}}</span></div>
						</div>
						<div class="table-header">
							<div></div>
							<div>{{$t('order_detail.text19')}}</div>
							<div>{{$t('order_detail.text20')}}</div>
							<div>{{$t('order_detail.text21')}}</div>
							<div style="width:30%;">{{$t('order_detail.text22')}}</div>
							
						</div>
						<div class="table-body" v-for="(item, i) in imgs">
							<div></div>
							<div class="table-body-item">
								<img class="img" :src="item.preview" />
							</div>
							<div class="table-body-item">{{item.title}}</div>
							<div class="table-body-item">{{item.norms}}</div>
							<div class="table-body-item" style="display:flex;flex-direction: column;justify-content:flex-start;align-items: flex-start;width:30%;">
								<div style="width:100%;" v-for="(value, index) in item.option_all">
									<!-- <input type="radio" :value="value.id" :checked="value.isChecked" @change="changeInput(i, index)"/>  --><!-- v-model="value.isChecked" -->
									<div style="width:100%;" v-if="value.isChecked">{{value.id==0?$t('order_detail.origin_1'):(value.id==1?$t('order_detail.small_1'):(value.id==2?$t('order_detail.big_1'):''))}}<span style="color:rgb(255,149,0);">￥{{value.price}}</span><span>（{{value.width}} x {{value.height}}）px</span></div>
								</div>
								
							</div>
							<!-- <div class="table-body-item" style="color:rgb(255,149,2);">{{totalAmount}}元</div> -->
							
						</div>
						
					</div>
				</div>
				<div class="paytype-container">
					<div class="caption"><span>{{$t('order_detail.text23')}}</span></div>
					<div class="paytype-table">
						<div class="pay-type"				     
						     v-for="item in paytype" 
						     :key="item.id" 
						     @click="selectPayType(item)"
						    >
						    <div class="pay-type-outer" :class="[selectedPaytype.id==item.id?'selected':'']" >
								<div class="pay-item">
									<i class="iconfont icon-style" :class="item.iconClass"></i>
								</div>
								<div class="pay-item">
									<span class="pay-item-name">{{item.name}}</span>
									<span class="pay-item-html" v-html="item.text"></span>
									<div class="pay-item-package" v-if="parseInt(item.id)==1 && Object.keys(packages).length>0">
										<div class="radio-outer">{{$t('order_detail.text24')}}</div>
										<div class="radio-outer" v-for="value in packages">
											<span style="padding:5px 5px;">
												<input type="radio" :value="value.package_id" v-model="checkedPackageId" />
											</span>
											<span style="padding:5px 5px;">{{value.title}}</span>
										</div>
										
									</div>
								</div>
								<!-- <div class="pay-item">
									<span style="color:rgb(255, 149, 2);display:none;">{{totalAmount}}元</span>
								</div> -->
								
							</div>
						</div>
						
	
					</div>
				</div>
			</div>
			
		</div>
		<div class="next">
			<div class="first">
				<div class="pay-text">
					<span><i class="iconfont pay-name" :class="selectedPaytype.iconClass"></i>{{selectedPaytype.name}}</span>
					<span style="padding:10px 20px;"></span>
					<span>{{$t('order_detail.text25')}}<span class="price">￥{{totalAmount}}</span></span>
					
				</div>			
				<div></div>
				<div class="nextpay" @click="topay(orderInfo)" :disabled="disabled">{{$t('order_detail.text26')}}</div>
			</div>
		</div>
		<Footer/>
	</div>
</template>

<script>
	import order_pay from './order_pay_controller';
	export default order_pay;
</script>

<style src="@/assets/css/my/order/order_pay.css" scoped>
	/*@import url("@/assets/css/style.css");全局引用css*/
	/*@import '@/assets/css/style.css';*/
</style>